import axios from "axios"
import i18n from "@/i18n"
import store from "@/store"

const {t} = i18n.global
import router from "../router";

const apiClient = axios.create({
    baseURL: "/api/"
});

let abortController = new AbortController();

export function resetAbortController() {
    abortController = new AbortController();
}

apiClient.interceptors.request.use(function (config) {
    if (abortController) {
        config.signal = abortController.signal;
    }

    // Do something before request is sent
    if (
        typeof config.headers['Content-Type'] === 'undefined'
        || config.headers['Content-Type'].length === 0
    ) {
        config.headers['Content-Type'] = "application/json";
    }
    config.headers["X-LANG"] = i18n.global.locale.toString();

    // Set CSRF Header
    if (process.env.VUE_APP_ENABLE_CSRF === "true") {
        let cookie_val = `; ${document.cookie}`;
        let cookie_parts = cookie_val.split("; GRST-CLIENT-CSRF=");
        if (cookie_parts.length === 2) {
            config.headers["X-GRST-CLIENT-CSRF"] = cookie_parts.pop().split(";").shift();
        } else {
            config.headers["X-GRST-CLIENT-CSRF"] = null;
        }
    }

    return config;
});

apiClient.interceptors.response.use(
    (response) => {
        if (response.data.error_key && response.data.error_key === "sessionExpired") {
            if (abortController) {
                abortController.abort();
            }
            store.dispatch("showToast", {
                "message": t("general.errors.sessionExpired"),
                "toastType": "error"
            });
            router.push("/");
        }

        return response;
    },
    (error) => {
        if ((error.message === "canceled") ||
            (
                typeof (error.response) !== "undefined" &&
                typeof (error.response.status) !== "undefined" &&
                error.response.status === 403 &&
                typeof (error.response.data.error_key) !== "undefined" &&
                error.response.data.error_key === "csrfInvalid"
            )
        ) {
            if (store.getters["isPageLoading"]) {
                store.commit("hidePageLoader")
            }
            if (!store.getters["isCsrfFailed"]) {
                store.commit("setCsrfFailed");
                if (error.message !== "canceled") {
                    store.dispatch("showToast", {
                        "message": t("general.errors.csrf_error"),
                        "toastType": "error"
                    });
                }
            }
        }
        return Promise.reject(error);
    });

export default apiClient;