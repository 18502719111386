<template>
  <section class="Step_SectionPropertyAgriculture">
    <fieldset>
      <div class="Step_SectionPropertyAgriculture">
        <InfoTextHeading
          :headingText="$t('newProperty.propertyAgriculture.headline')"
          :infoText="$t('infoTextDisabledFields')"
        >
        </InfoTextHeading>

        <div class="form-group">
          <div class="row_setp_main">
            <!-- Heading With Border -->
            <div class="col-md-7">
              <h2 class="subheading_title">{{ $t('newProperty.propertyAgriculture.liberation') }}</h2>
            </div>

            <!-- Befreiung gesamt -->
            <div class="col-md-7">
              <div
                class="data-form"
                @mouseenter="setInfoTooltip('propertyAgricultureTotalExemption')"
              >
                <label class="label-title">
                  {{ $t('newProperty.propertyAgriculture.total_liberation') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyAgricultureTotalExemption' && !exemptions_activated"
                    :isDisabled="!exemptions_activated"
                  />
                </label>
                <select
                  v-model="form.totalExemption"
                  :disabled="isPropertyReadOnly || !exemptions_activated"
                  ref="total_exemption"
                  class="hover-tooltip"
                  @focusin="setInfoTooltip('propertyAgricultureTotalExemption')"
                >
                  <option
                    v-for="option in exemptionList"
                    v-bind:key="option.code"
                    :value="option.code"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Befreiung nicht abgrenzbarer Teil -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip('propertyAgricultureExemptionNonDelimitablePart')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyAgriculture.non_delimitable_part') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyAgricultureExemptionNonDelimitablePart' && !exemptions_activated"
                    :isDisabled="!exemptions_activated"
                  />
                </label>
                <select
                  v-model="form.exemptionNonDelimitablePart"
                  :disabled="isPropertyReadOnly || !exemptions_activated"
                  class="hover-tooltip"
                  @focusin="setInfoTooltip('propertyAgricultureExemptionNonDelimitablePart')"
                >
                  <option
                    v-for="option in exemptionList"
                    v-bind:key="option.code"
                    :value="option.code"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Gemeinde 1 -->
            <div
              class="Gemeinde"
              v-for="(gemeinde, index) in form.gemeindes"
              :key="index"
            >
              <div class="col-md-7">
                <div
                  class="deleteIcon delete_"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <h2 class="subheading_title">
                    {{ $t('newProperty.propertyAgriculture.community') }} {{ index + 1 }}
                  </h2>
                  <Svgall
                    @click="removeGemeindes(index)"
                    :name="'delete'"
                    :color="primaryColor"
                    v-show="!isPropertyReadOnly"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip(0)"
                  />
                </div>
                <hr class="subheading_devide" />
              </div>

              <!-- Bundesland -->
              <div class="col-md-7">
                <div
                  class="data-form"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyAgriculture.state') }}&nbsp;<span class="nf_starcolor">*</span>
                  </label>
                  <select
                    v-model="form.gemeindes[index].federal_state_uid"
                    :disabled="isPropertyReadOnly"
                    @change="onChangeFederalState()"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip(0)"
                  >
                    <option
                      v-for="option in federal_list"
                      v-bind:key="option.name"
                      :value="option.code"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                  <span
                    class="error"
                    v-if="form.errors().has('gemeindes_' + index + '_federal_state_uid')"
                  >
                    {{ form.errors().get('gemeindes_' + index + '_federal_state_uid') }}
                  </span>
                </div>
              </div>

              <!-- Gemeinde -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyAgricultureCommunity_' + index)"
              >
                <div
                  class="data-form"
                  @mouseenter="setInfoTooltip('propertyAgricultureCommunity_' + index)"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyAgriculture.community') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyAgricultureCommunity_' + index"
                      :content="$t('newProperty.infoTooltip.agriculture.community.content')"
                      :isDisabled="isFederalStateEmpty(index)"
                    />
                  </label>
                  <AutoComplete
                    v-model="form.gemeindes[index].community"
                    :multiple="false"
                    :suggestions="suggestionsCommunity(index)"
                    :auto-highlight="true"
                    :disabled="isPropertyReadOnly || isFederalStateEmpty(index)"
                    inputClass="input-field"
                    class="hover-tooltip"
                    :minLength="1"
                    @item-select="onItemSelectCommunity($event, index)"
                    @complete="searchCommunity($event, index)"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyAgricultureCommunity_' + index)"
                  />
                </div>
              </div>

              <!-- Bezeichnung -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyAgricultureGemeindeDescription_' + index)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyAgriculture.description') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyAgricultureGemeindeDescription_' + index"
                      :content="$t('newProperty.infoTooltip.agriculture.gemeindeDescription.content')"
                    />
                  </label>
                  <input
                    type="text"
                    v-model="form.gemeindes[index].description"
                    :disabled="isPropertyReadOnly"
                    class="input-field hover-tooltip"
                    autocomplete="off"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyAgricultureGemeindeDescription_' + index)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('gemeindes_' + index + '_description')"
                  >
                    {{ form.errors().get('gemeindes_' + index + '_description') }}
                  </span>
                </div>
              </div>

              <!-- Flurstück 1 -->
              <div
                class="Flurstück"
                v-for="(parcel, parcelindex) in gemeinde.parcels"
                :key="parcelindex"
              >
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="deleteIcon delete_">
                    <h2 class="subheading_title_inner">
                      {{ $t('newProperty.propertyAgriculture.parcel') }} {{ parcelindex + 1 }}
                    </h2>
                    <Svgall
                      @click="removeItemParcel(index, parcelindex)"
                      :name="'delete'"
                      :color="primaryColor"
                      v-show="!isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    />
                  </div>
                  <hr class="subheading_devide" />
                </div>

                <!-- Gemarkung -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip('propertyAgricultureParcel_' + index + '_' + parcelindex)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyAgriculture.district') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyAgricultureParcel_' + index + '_' + parcelindex"
                        :content="$t('newProperty.infoTooltip.agriculture.parcel.content')"
                        :url="$t('newProperty.infoTooltip.agriculture.parcel.url')"
                      />
                    </label>
                    <AutoComplete
                      v-model="form.gemeindes[index].parcels[parcelindex].district"
                      :multiple="false"
                      :suggestions="suggestionsGemarkungenName(index, parcelindex)"
                      :auto-highlight="true"
                      :disabled="isPropertyReadOnly"
                      class="hover-tooltip"
                      inputClass="input-field"
                      :completeOnFocus="true"
                      @complete="searchGemarkungenName($event, index, parcelindex)"
                      @item-select="onItemSelectGemarkungenName($event, index, parcelindex)"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyAgricultureParcel_' + index + '_' + parcelindex)"
                    >
                      <template #item="slotProps">
                        <div>
                          {{ slotProps.item }} ({{
                            findGemarkungNumberForGemarkungName(slotProps.item, index, parcelindex)
                          }})
                        </div>
                      </template>
                    </AutoComplete>
                  </div>
                </div>

                <!-- Gemarkungsnummer -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip('propertyAgricultureParcelNumber_' + index + '_' + parcelindex)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyAgriculture.parcelno') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyAgricultureParcelNumber_' + index + '_' + parcelindex"
                        :content="$t('newProperty.infoTooltip.agriculture.parcelNumber.content')"
                        :url="$t('newProperty.infoTooltip.agriculture.parcelNumber.url')"
                      />
                    </label>
                    <AutoComplete
                      type="number"
                      @keypress="onlyNumber"
                      v-model.number="form.gemeindes[index].parcels[parcelindex].district_number"
                      :multiple="false"
                      :suggestions="suggestionsGemarkungenNumber(index, parcelindex)"
                      :auto-highlight="true"
                      :disabled="isPropertyReadOnly"
                      class="hover-tooltip"
                      inputClass="input-field"
                      :completeOnFocus="true"
                      @complete="searchGemarkungenNumber($event, index, parcelindex)"
                      @item-select="onItemSelectGemarkungenNumber($event, index, parcelindex)"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyAgricultureParcelNumber_' + index + '_' + parcelindex)"
                    >
                      <template #item="slotProps">
                        <div>
                          {{ slotProps.item }} ({{
                            findGemarkungNameForGemarkungNumber(slotProps.item, index, parcelindex)
                          }})
                        </div>
                      </template>
                    </AutoComplete>
                  </div>
                </div>

                <!-- Flur -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip('propertyAgricultureParcelCorridor_' + index + '_' + parcelindex)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyAgriculture.corridor') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyAgricultureParcelCorridor_' + index + '_' + parcelindex"
                        :content="$t('newProperty.infoTooltip.agriculture.corridor.content')"
                        :url="$t('newProperty.infoTooltip.agriculture.corridor.url')"
                        :imagePath="require('@/assets/img/infotooltip/property-more-details-corridor.png')"
                      />
                    </label>
                    <input
                      type="number"
                      @keypress="onlyNumber"
                      v-model.number="form.gemeindes[index].parcels[parcelindex].corridor"
                      :disabled="isPropertyReadOnly"
                      class="input-field hover-tooltip"
                      ref="calcIdAndRef('corridor_', index, parcelindex)"
                      autocomplete="off"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyAgricultureParcelCorridor_' + index + '_' + parcelindex)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('gemeindes_' + index + '_parcel_' + parcelindex + '_corridor')"
                    >
                      {{ form.errors().get('gemeindes_' + index + '_parcel_' + parcelindex + '_corridor') }}
                    </span>
                  </div>
                </div>

                <!-- Grundbuchblatt -->
                <div
                  class="col-md-7"
                  @mouseenter="
                    setInfoTooltip('propertyAgricultureParcelLandRegisterSheet_' + index + '_' + parcelindex)
                  "
                >
                  <div
                    class="data-form"
                    @mouseenter="
                      setInfoTooltip('propertyAgricultureParcelLandRegisterSheet_' + index + '_' + parcelindex)
                    "
                  >
                    <label class="label-title tooltip-icon">
                      {{ $t('newProperty.propertyAgriculture.land_register_sheet') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyAgricultureParcelLandRegisterSheet_' + index + '_' + parcelindex"
                        :content="$t('newProperty.infoTooltip.agriculture.landRegisterSheet.content')"
                        :url="$t('newProperty.infoTooltip.agriculture.landRegisterSheet.url')"
                        :imagePath="require('@/assets/img/infotooltip/property-more-details-lrs.png')"
                        :isDisabled="isLandRegisterSheetDisabled(index)"
                      />
                    </label>
                    <input
                      type="text"
                      :maxlength="6"
                      v-model="form.gemeindes[index].parcels[parcelindex].landRegisterSheet"
                      :disabled="isPropertyReadOnly || isLandRegisterSheetDisabled(index)"
                      class="input-field hover-tooltip"
                      ref="calcIdAndRef('landRegisterSheet_', index, parcelindex)"
                      autocomplete="off"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="
                        setInfoTooltip('propertyAgricultureParcelLandRegisterSheet_' + index + '_' + parcelindex)
                      "
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('gemeindes_' + index + '_parcel_' + parcelindex + '_landRegisterSheet')"
                    >
                      {{ form.errors().get('gemeindes_' + index + '_parcel_' + parcelindex + '_landRegisterSheet') }}
                    </span>
                  </div>
                </div>

                <!-- Flurstück: Zähler -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip('propertyAgricultureParcelCounter_' + index + '_' + parcelindex)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyAgriculture.parcel_data_counter') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyAgricultureParcelCounter_' + index + '_' + parcelindex"
                        :content="$t('newProperty.infoTooltip.agriculture.parcelDataCounter.content')"
                        :url="$t('newProperty.infoTooltip.agriculture.parcelDataCounter.url')"
                        :imagePath="require('@/assets/img/infotooltip/property-more-details-counter.png')"
                      />
                    </label>
                    <input
                      type="number"
                      @keypress="onlyNumber"
                      :maxlength="5"
                      v-model.number="form.gemeindes[index].parcels[parcelindex].counter"
                      :disabled="isPropertyReadOnly"
                      autocomplete="off"
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyAgricultureParcelCounter_' + index + '_' + parcelindex)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('gemeindes_' + index + '_parcel_' + parcelindex + '_counter')"
                    >
                      {{ form.errors().get('gemeindes_' + index + '_parcel_' + parcelindex + '_counter') }}
                    </span>
                  </div>
                </div>

                <!-- Flurstück: Nenner -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip('propertyAgricultureParcelDenominator_' + index + '_' + parcelindex)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyAgriculture.parcel_data_denominator') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyAgricultureParcelDenominator_' + index + '_' + parcelindex"
                        :content="$t('newProperty.infoTooltip.agriculture.parcelDataDenominator.content')"
                        :url="$t('newProperty.infoTooltip.agriculture.parcelDataDenominator.url')"
                        :imagePath="require('@/assets/img/infotooltip/property-more-details-counter.png')"
                      />
                    </label>
                    <input
                      type="text"
                      :maxlength="getMaxLength('denominator')"
                      v-model="form.gemeindes[index].parcels[parcelindex].denominator"
                      :disabled="isPropertyReadOnly"
                      class="input-field eee hover-tooltip"
                      autocomplete="off"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyAgricultureParcelDenominator_' + index + '_' + parcelindex)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('gemeindes_' + index + '_parcel_' + parcelindex + '_denominator')"
                    >
                      {{ form.errors().get('gemeindes_' + index + '_parcel_' + parcelindex + '_denominator') }}
                    </span>
                  </div>
                </div>

                <!-- Amtliche FLäche -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip('propertyAgricultureParcelAreaOfTheLand_' + index + '_' + parcelindex)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyAgriculture.area_of_the_land') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyAgricultureParcelAreaOfTheLand_' + index + '_' + parcelindex"
                        :content="$t('newProperty.infoTooltip.agriculture.areaOfTheLand.content')"
                      />
                    </label>
                    <input
                      type="number"
                      @keypress="onlyNumber"
                      :maxlength="8"
                      v-model.number="form.gemeindes[index].parcels[parcelindex].areaOfTheLand"
                      :disabled="isPropertyReadOnly"
                      class="input-field hover-tooltip"
                      autocomplete="off"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyAgricultureParcelAreaOfTheLand_' + index + '_' + parcelindex)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('gemeindes_' + index + '_parcel_' + parcelindex + '_areaOfTheLand')"
                    >
                      {{ form.errors().get('gemeindes_' + index + '_parcel_' + parcelindex + '_areaOfTheLand') }}
                    </span>
                  </div>
                </div>

                <!-- Flurstück als Referenzgrundstück verwenden -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip('propertyAgricultureParcelIsReference_' + index + '_' + parcelindex)"
                >
                  <div
                    class="data-form"
                    @mouseenter="setInfoTooltip('propertyAgricultureParcelIsReference_' + index + '_' + parcelindex)"
                  >
                    <span class="checkbox_input form_padding">
                      <input
                        class="form-check-input hover-tooltip"
                        type="checkbox"
                        v-model="form.gemeindes[index].parcels[parcelindex].isReference"
                        true-value="1"
                        false-value="0"
                        :disabled="isDisabledReferenceCheckbox(index, parcelindex)"
                        @focusin="setInfoTooltip('propertyAgricultureParcelIsReference_' + index + '_' + parcelindex)"
                      />
                      <span class="checkbox_title">
                        {{ $t('newProperty.propertyAgriculture.is_reference') }}
                        <InfoTooltip
                          v-if="
                            infoTooltip === 'propertyAgricultureParcelIsReference_' + index + '_' + parcelindex &&
                            isDisabledReferenceCheckbox(index, parcelindex)
                          "
                          :numberOfLinesOfLabel="0"
                          :isDisabled="isDisabledReferenceCheckbox(index, parcelindex)"
                        />
                      </span>
                    </span>
                  </div>
                </div>

                <div
                  class="uses_type"
                  v-for="(usageType, usageTypeindex) in parcel.usageTypes"
                  :key="usageTypeindex"
                >
                  <!-- Art der Nutzung 1 -->
                  <div v-if="usageType">
                    <div
                      class="col-md-7"
                      @mouseenter="setInfoTooltip(0)"
                    >
                      <div class="deleteIcon delete_">
                        <h5 class="subheading_title_inner">
                          {{ $t('newProperty.propertyAgriculture.usage_type_title') }} {{ usageTypeindex + 1 }}
                        </h5>
                        <Svgall
                          :name="'delete'"
                          @click="removeItemUsageType(index, parcelindex, usageTypeindex)"
                          :color="primaryColor"
                          v-show="!isPropertyReadOnly"
                          class="hover-tooltip"
                          @focusin="setInfoTooltip(0)"
                        />
                      </div>

                      <!-- Nutzung -->
                      <div
                        class="data-form"
                        @mouseenter="setInfoTooltip(0)"
                      >
                        <label class="label-title">
                          {{ $t('newProperty.propertyAgriculture.usage_type') }}
                        </label>
                        <select
                          v-model="form.gemeindes[index].parcels[parcelindex].usageTypes[usageTypeindex].usageType"
                          :disabled="isPropertyReadOnly"
                          class="hover-tooltip"
                          @focusin="setInfoTooltip(0)"
                        >
                          <option
                            v-for="option in usage_type_options"
                            v-bind:key="option.code"
                            :value="option.code"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!--  Fläche der Nutzung -->
                    <div
                      class="col-md-7"
                      @mouseenter="setInfoTooltip(0)"
                    >
                      <div class="data-form">
                        <label class="label-title">
                          {{ $t('newProperty.propertyAgriculture.area_of_use') }}
                        </label>
                        <input
                          type="number"
                          @keypress="onlyNumber"
                          v-model.number="
                            form.gemeindes[index].parcels[parcelindex].usageTypes[usageTypeindex].areaOfTheLand
                          "
                          :disabled="isPropertyReadOnly"
                          placeholder=""
                          autocomplete="off"
                          class="input-field hover-tooltip"
                          @keyup.esc="cancel"
                          @keyup.enter="callForNextStep"
                          @focusin="setInfoTooltip(0)"
                        />
                        <span
                          class="error"
                          v-if="form.errors().has('gemeindes_' + index + '_use_' + usageTypeindex + '_areaOfTheLand')"
                        >
                          {{ form.errors().get('gemeindes_' + index + '_use_' + usageTypeindex + '_areaOfTheLand') }}
                        </span>
                      </div>
                    </div>

                    <!--  Ertragsmesszahl -->
                    <div
                      class="col-md-7"
                      @mouseenter="
                        setInfoTooltip(
                          'propertyAgricultureYieldMeasurementNumber_' +
                            index +
                            '_' +
                            parcelindex +
                            '_' +
                            usageTypeindex,
                        )
                      "
                    >
                      <div
                        class="data-form"
                        @mouseenter="
                          setInfoTooltip(
                            'propertyAgricultureYieldMeasurementNumber_' +
                              index +
                              '_' +
                              parcelindex +
                              '_' +
                              usageTypeindex,
                          )
                        "
                      >
                        <label class="label-title">
                          {{ $t('newProperty.propertyAgriculture.earnings_metric') }}
                          <InfoTooltip
                            v-if="
                              infoTooltip ===
                                'propertyAgricultureYieldMeasurementNumber_' +
                                  index +
                                  '_' +
                                  parcelindex +
                                  '_' +
                                  usageTypeindex && isYieldMeasurementNumberDisabled(index, parcelindex, usageTypeindex)
                            "
                            :isDisabled="isYieldMeasurementNumberDisabled(index, parcelindex, usageTypeindex)"
                          />
                        </label>
                        <input
                          type="number"
                          @keypress="onlyNumber"
                          v-model.number="
                            form.gemeindes[index].parcels[parcelindex].usageTypes[usageTypeindex].yieldMeasurementNumber
                          "
                          :disabled="
                            isPropertyReadOnly || isYieldMeasurementNumberDisabled(index, parcelindex, usageTypeindex)
                          "
                          placeholder=""
                          class="input-field dff hover-tooltip"
                          autocomplete="off"
                          @keyup.esc="cancel"
                          @keyup.enter="callForNextStep"
                          @focusin="
                            setInfoTooltip(
                              'propertyAgricultureYieldMeasurementNumber_' +
                                index +
                                '_' +
                                parcelindex +
                                '_' +
                                usageTypeindex,
                            )
                          "
                        />
                        <span
                          class="error"
                          v-if="
                            form
                              .errors()
                              .has('gemeindes_' + index + '_use_' + usageTypeindex + '_yieldMeasurementNumber')
                          "
                        >
                          {{
                            form
                              .errors()
                              .get('gemeindes_' + index + '_use_' + usageTypeindex + '_yieldMeasurementNumber')
                          }}
                        </span>
                      </div>
                    </div>

                    <!--  Bruttogrundfläche der Wirtschaftsgebäude -->
                    <div
                      class="col-md-7"
                      @mouseenter="
                        setInfoTooltip(
                          'propertyAgricultureAreaOfFarmBuildings_' + index + '_' + parcelindex + '_' + usageTypeindex,
                        )
                      "
                    >
                      <div
                        class="data-form"
                        @mouseenter="
                          setInfoTooltip(
                            'propertyAgricultureAreaOfFarmBuildings_' +
                              index +
                              '_' +
                              parcelindex +
                              '_' +
                              usageTypeindex,
                          )
                        "
                      >
                        <label class="label-title">
                          {{ $t('newProperty.propertyAgriculture.floor_area') }}
                          <InfoTooltip
                            v-if="
                              infoTooltip ===
                                'propertyAgricultureAreaOfFarmBuildings_' +
                                  index +
                                  '_' +
                                  parcelindex +
                                  '_' +
                                  usageTypeindex && isAreaOfFarmBuildingDisabled(index, parcelindex, usageTypeindex)
                            "
                            :isDisabled="isAreaOfFarmBuildingDisabled(index, parcelindex, usageTypeindex)"
                          />
                        </label>
                        <input
                          type="number"
                          @keypress="onlyNumber"
                          v-model.number="
                            form.gemeindes[index].parcels[parcelindex].usageTypes[usageTypeindex].areaOfFarmBuildings
                          "
                          :disabled="
                            isPropertyReadOnly || isAreaOfFarmBuildingDisabled(index, parcelindex, usageTypeindex)
                          "
                          placeholder=""
                          autocomplete="off"
                          class="input-field hover-tooltip"
                          @keyup.esc="cancel"
                          @keyup.enter="callForNextStep"
                          @focusin="
                            setInfoTooltip(
                              'propertyAgricultureAreaOfFarmBuildings_' +
                                index +
                                '_' +
                                parcelindex +
                                '_' +
                                usageTypeindex,
                            )
                          "
                        />
                        <span
                          class="error"
                          v-if="
                            form.errors().has('gemeindes_' + index + '_use_' + usageTypeindex + '_areaOfFarmBuildings')
                          "
                        >
                          {{
                            form.errors().get('gemeindes_' + index + '_use_' + usageTypeindex + '_areaOfFarmBuildings')
                          }}
                        </span>
                      </div>
                    </div>

                    <!--  Durchflussmenge in l/s -->
                    <div
                      class="col-md-7"
                      @mouseenter="
                        setInfoTooltip(
                          'propertyAgricultureFlowRate_' + index + '_' + parcelindex + '_' + usageTypeindex,
                        )
                      "
                    >
                      <div
                        class="data-form"
                        @mouseenter="
                          setInfoTooltip(
                            'propertyAgricultureFlowRate_' + index + '_' + parcelindex + '_' + usageTypeindex,
                          )
                        "
                      >
                        <label class="label-title">
                          {{ $t('newProperty.propertyAgriculture.floor_rate') }}
                          <InfoTooltip
                            v-if="
                              infoTooltip ===
                                'propertyAgricultureFlowRate_' + index + '_' + parcelindex + '_' + usageTypeindex &&
                              isFlowRateDisabled(index, parcelindex, usageTypeindex)
                            "
                            :isDisabled="isFlowRateDisabled(index, parcelindex, usageTypeindex)"
                          />
                        </label>
                        <input
                          type="number"
                          @keypress="onlyNumber"
                          v-model.number="
                            form.gemeindes[index].parcels[parcelindex].usageTypes[usageTypeindex].flowRate
                          "
                          placeholder=""
                          :disabled="isPropertyReadOnly || isFlowRateDisabled(index, parcelindex, usageTypeindex)"
                          class="input-field hover-tooltip"
                          ref="lifting_rate"
                          autocomplete="off"
                          @keyup.esc="cancel"
                          @keyup.enter="callForNextStep"
                          @focusin="
                            setInfoTooltip(
                              'propertyAgricultureFlowRate_' + index + '_' + parcelindex + '_' + usageTypeindex,
                            )
                          "
                        />
                        <span
                          class="error"
                          v-if="form.errors().has('gemeindes_' + index + '_use_' + usageTypeindex + '_flowRate')"
                        >
                          {{ form.errors().get('gemeindes_' + index + '_use_' + usageTypeindex + '_flowRate') }}
                        </span>
                      </div>
                    </div>

                    <!--  Befreiung -->
                    <div
                      class="col-md-7"
                      @mouseenter="
                        setInfoTooltip(
                          'propertyAgricultureExemption_' + index + '_' + parcelindex + '_' + usageTypeindex,
                        )
                      "
                    >
                      <div
                        class="data-form"
                        @mouseenter="
                          setInfoTooltip(
                            'propertyAgricultureExemption_' + index + '_' + parcelindex + '_' + usageTypeindex,
                          )
                        "
                      >
                        <label class="label-title">
                          {{ $t('newProperty.propertyAgriculture.liberation') }}
                          <InfoTooltip
                            v-if="
                              infoTooltip ===
                                'propertyAgricultureExemption_' + index + '_' + parcelindex + '_' + usageTypeindex &&
                              !exemptions_activated
                            "
                            :isDisabled="!exemptions_activated"
                          />
                        </label>
                        <select
                          v-model="form.gemeindes[index].parcels[parcelindex].usageTypes[usageTypeindex].exemption"
                          :disabled="isPropertyReadOnly || !exemptions_activated"
                          class="hover-tooltip"
                          @focusin="
                            setInfoTooltip(
                              'propertyAgricultureExemption_' + index + '_' + parcelindex + '_' + usageTypeindex,
                            )
                          "
                        >
                          <option
                            v-for="option in exemptionList"
                            v-bind:key="option.code"
                            :value="option.code"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!--  Begünstigte Fläche in m² -->
                    <div
                      class="col-md-7"
                      @mouseenter="
                        setInfoTooltip(
                          'propertyAgricultureExemptionArea_' + index + '_' + parcelindex + '_' + usageTypeindex,
                        )
                      "
                    >
                      <div
                        class="data-form"
                        @mouseenter="
                          setInfoTooltip(
                            'propertyAgricultureExemptionArea_' + index + '_' + parcelindex + '_' + usageTypeindex,
                          )
                        "
                      >
                        <label class="label-title">
                          {{ $t('newProperty.propertyAgriculture.favoredArea') }}
                          <InfoTooltip
                            v-if="
                              infoTooltip ===
                                'propertyAgricultureExemptionArea_' +
                                  index +
                                  '_' +
                                  parcelindex +
                                  '_' +
                                  usageTypeindex && !exemptions_activated
                            "
                            :isDisabled="!exemptions_activated"
                          />
                        </label>
                        <input
                          type="number"
                          @keypress="onlyNumber"
                          v-model.number="
                            form.gemeindes[index].parcels[parcelindex].usageTypes[usageTypeindex].exemption_area
                          "
                          :disabled="isPropertyReadOnly || !exemptions_activated"
                          placeholder=""
                          class="input-field hover-tooltip"
                          autocomplete="off"
                          @keyup.esc="cancel"
                          @keyup.enter="callForNextStep"
                          @focusin="
                            setInfoTooltip(
                              'propertyAgricultureExemptionArea_' + index + '_' + parcelindex + '_' + usageTypeindex,
                            )
                          "
                        />
                        <span
                          class="error"
                          v-if="form.errors().has('gemeindes_' + index + '_use_' + usageTypeindex + '_exemption_area')"
                        >
                          {{ form.errors().get('gemeindes_' + index + '_use_' + usageTypeindex + '_exemption_area') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="add-field no_space">
                    <button
                      class="add-field-button hover-tooltip"
                      @click="addItemUsageTypeMore(index, parcelindex)"
                      :disabled="isPropertyReadOnly"
                      @focusin="setInfoTooltip(0)"
                    >
                      <Svgall
                        :name="'add_more'"
                        :color="primaryColor"
                      />
                      <span>{{ $t('newProperty.propertyAgriculture.addUses') }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="add-field __space">
                  <button
                    class="add-field-button hover-tooltip"
                    :disabled="isPropertyReadOnly"
                    @click="addItemParcel(index)"
                    @focusin="setInfoTooltip(0)"
                  >
                    <Svgall
                      :name="'add_more'"
                      :color="primaryColor"
                    />
                    <span>{{ $t('newProperty.propertyAgriculture.addParcel') }}</span>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="add-field __space">
                <button
                  class="add-field-button hover-tooltip"
                  :disabled="isPropertyReadOnly"
                  @click="addGemeindes()"
                  @focusin="setInfoTooltip(0)"
                >
                  <Svgall
                    :name="'add_more'"
                    :color="primaryColor"
                  />
                  <span>{{ $t('newProperty.propertyAgriculture.addGemeinde') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text_left step-button-section"
          @mouseenter="setInfoTooltip(0)"
        >
          <div>
            <button
              type="button"
              @click.prevent="cancel"
              class="step-button-style border-button-style hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.abort') }}
            </button>
            <button
              type="button"
              @click.prevent="save"
              class="step-button-style border-button-style hover-tooltip"
              v-show="!isPropertyReadOnly"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.save') }}
            </button>
            <button
              type="button"
              v-show="back_button_activated"
              @click.prevent="callForPrecedingStep"
              class="step-button-style border-button-style"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.back') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              class="step-button-style"
              :style="[primaryColor ? { background: primaryColor } : '']"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              class="step-button-enter hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue_enter') }}
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </section>
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import InfoTooltip from '@/components/helper/InfoTooltip'
import ShowHideTooltip from '@/mixins/ShowHideTooltip'
import { mapGetters, mapActions } from 'vuex'
import { defaultPropertyAgriculture } from '@/store/defaults/properties/DefaultPropertyAgriculture'
import AutoComplete from 'primevue/autocomplete'
import InfoTextHeading from '@/components/helper/InfoTextHeading'

export default {
  name: 'PropertyAgriculture',
  components: { InfoTextHeading, AutoComplete, InfoTooltip },
  mixins: [ShowHideTooltip],
  emits: ['callForNextStep', 'callForPrecedingStep', 'callForSaveProperty', 'cancelPropVisible'],
  data() {
    return {
      clearSearch: '',
      primaryColor: '',
      emptyList: [],
      gemeindesHelper: [],
      form: form({
        totalExemption: -1,
        exemptionNonDelimitablePart: -1,
        gemeindes: [
          {
            id: 0,
            federal_state_uid: '0',
            community: '',
            description: '',
            parcels: [
              {
                corridor: '',
                LandRegisterSheet: '',
                id: 0,
                counter: '',
                denominator: '',
                areaOfTheLand: '',
                district: '',
                district_number: '',
                isReference: 0,
                usageTypes: [
                  {
                    usageType: '0',
                    id: 0,
                    areaOfTheLand: '',
                    yieldMeasurementNumber: '',
                    areaOfFarmBuildings: '',
                    flowRate: '',
                    exemption: '',
                    exemption_area: '',
                  },
                ],
              },
            ],
          },
        ],
      }),
      land_register_sheet_activated: true,
      parcel_is_reference_activated: true,
      exemptions_activated: true,
      back_button_activated: false,
    }
  },
  mounted() {
    this.initForm()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : ''
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.primaryColor : ''
    this.addPositiveRule()
    this.showHide()
  },

  beforeUnmount() {
    this.saveForm()
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getFormPropertyAgriculture',
      'isPropertyReadOnly',
      'getArrFederalStatesCommunities',
      'getArrGemarkungenCollection',
      'infoTooltip',
    ]),

    isNewProperty() {
      return this.$parent.isNewProperty
    },

    exemptionList() {
      let list = []
      let resultArray = []
      list = this.$tm('newProperty.propertyAgriculture.exemption_befreiung')
      for (let k of list) {
        resultArray.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }

      return resultArray
    },

    federal_list() {
      let list = []
      let resultArray = []
      list = this.$tm('newProperty.propertyAgriculture.federal_states')
      for (let k of list) {
        resultArray.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }

      return resultArray
    },
    data_list() {
      let list = []
      let resultArray = []
      list = this.$tm('newProperty.propertyAgriculture.data_list')
      for (let k of list) {
        resultArray.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }

      return resultArray
    },
    usage_type_options() {
      let list = []
      let resultArray = []
      list = this.$tm('newProperty.propertyAgriculture.usage_type_options')
      for (let k of list) {
        resultArray.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }

      return resultArray
    },
    isShowNextStepButton() {
      let isShow = true
      if (this.$parent.step === this.$parent.maxStep && this.isPropertyReadOnly) {
        isShow = false
      }
      return isShow
    },
  },
  watch: {
    getFormPropertyAgriculture() {
      this.initForm()
    },
    getFederalStates() {
      this.federal_List = this.getFederalStates
    },
    getArrFederalStatesCommunities: {
      handler() {
        this.initGemeindesHelper()
      },
      deep: true,
    },
    getArrGemarkungenCollection: {
      handler() {
        this.updateParcelsHelper()
      },
      deep: true,
    },
    parcel_is_reference_activated(newValue) {
      if (newValue === false) {
        this.unsetAllParcelIsReference()
      }
    },
    exemptions_activated(newValue) {
      if (newValue === false) {
        this.resetAllExemptionFormFields()
      }
    },
  },
  methods: {
    ...mapActions([
      'resetFormPropertyAgriculture',
      'apiGetCommunitiesForFederalStatesPoolOnly',
      'apiGetGemarkungenCollection',
      'changeInfoTooltip',
    ]),

    setInfoTooltip(val) {
      this.changeInfoTooltip(val)
    },

    resetAllExemptionFormFields() {
      this.form.totalExemption = '0'
      this.form.exemptionNonDelimitablePart = '0'
      this.form.gemeindes.forEach((gemeinde) => {
        gemeinde.parcels.forEach((parcel) => {
          parcel.usageTypes.forEach((usageType) => {
            usageType.exemption = defaultPropertyAgriculture.gemeindes[0].parcels[0].usageTypes[0].exemption
            usageType.exemption_area = defaultPropertyAgriculture.gemeindes[0].parcels[0].usageTypes[0].exemption_area
          }, this)
        }, this)
      }, this)
      this.resetFormErrors()
    },

    resetFormErrors() {
      if (this.form.validate().errors().any()) {
        this.form.errors().forget()
      }
    },

    isLandRegisterSheetDisabled(gemeindeIndex) {
      let isDisabled = true

      if (
        typeof gemeindeIndex === 'number' &&
        gemeindeIndex >= 0 &&
        Array.isArray(this.form.gemeindes) &&
        gemeindeIndex < this.form.gemeindes.length &&
        typeof this.form.gemeindes[gemeindeIndex].federal_state_uid !== 'undefined' &&
        this.form.gemeindes[gemeindeIndex].federal_state_uid > 0
      ) {
        isDisabled =
          ['2', '6', '7', '9'].indexOf(this.form.gemeindes[gemeindeIndex].federal_state_uid.toString()) === -1
        if (isDisabled) {
          this.form.gemeindes[gemeindeIndex].parcels.forEach((parcel) => {
            parcel.landRegisterSheet = ''
          }, this)
        }
      }

      return isDisabled
    },

    suggestionsCommunity(gemeindesIndex) {
      let myResult = []

      if (
        typeof gemeindesIndex === 'number' &&
        gemeindesIndex >= 0 &&
        typeof this.form.gemeindes === 'object' &&
        gemeindesIndex < this.form.gemeindes.length &&
        typeof this.gemeindesHelper === 'object' &&
        gemeindesIndex < this.gemeindesHelper.length
      ) {
        myResult = this.gemeindesHelper[gemeindesIndex].filteredCommunityNames
      }

      return myResult
    },

    suggestionsGemarkungenName(gemeindesIndex, parcelsIndex) {
      let myResult = []

      if (
        typeof gemeindesIndex === 'number' &&
        gemeindesIndex >= 0 &&
        typeof this.form.gemeindes === 'object' &&
        gemeindesIndex < this.form.gemeindes.length &&
        typeof this.gemeindesHelper === 'object' &&
        gemeindesIndex < this.gemeindesHelper.length &&
        typeof parcelsIndex === 'number' &&
        parcelsIndex >= 0 &&
        typeof this.form.gemeindes[gemeindesIndex].parcels === 'object' &&
        parcelsIndex < this.form.gemeindes[gemeindesIndex].parcels.length &&
        typeof this.gemeindesHelper[gemeindesIndex].parcelsHelper === 'object' &&
        parcelsIndex < this.gemeindesHelper[gemeindesIndex].parcelsHelper.length
      ) {
        myResult = this.gemeindesHelper[gemeindesIndex].parcelsHelper[parcelsIndex].gemarkungenFilteredByName
      }

      return myResult
    },

    suggestionsGemarkungenNumber(gemeindesIndex, parcelsIndex) {
      let myResult = []

      if (
        typeof gemeindesIndex === 'number' &&
        gemeindesIndex >= 0 &&
        typeof this.form.gemeindes === 'object' &&
        gemeindesIndex < this.form.gemeindes.length &&
        typeof this.gemeindesHelper === 'object' &&
        gemeindesIndex < this.gemeindesHelper.length &&
        typeof parcelsIndex === 'number' &&
        parcelsIndex >= 0 &&
        typeof this.form.gemeindes[gemeindesIndex].parcels === 'object' &&
        parcelsIndex < this.form.gemeindes[gemeindesIndex].parcels.length &&
        typeof this.gemeindesHelper[gemeindesIndex].parcelsHelper === 'object' &&
        parcelsIndex < this.gemeindesHelper[gemeindesIndex].parcelsHelper.length
      ) {
        myResult = this.gemeindesHelper[gemeindesIndex].parcelsHelper[parcelsIndex].gemarkungenFilteredByCode
      }

      return myResult
    },

    isFederalStateEmpty(gemeindesIndex) {
      let myResult = true

      if (
        typeof gemeindesIndex === 'number' &&
        typeof this.form.gemeindes === 'object' &&
        gemeindesIndex >= 0 &&
        gemeindesIndex < this.form.gemeindes.length &&
        typeof this.form.gemeindes[gemeindesIndex].federal_state_uid !== 'undefined' &&
        this.form.gemeindes[gemeindesIndex].federal_state_uid.toString() !== '' &&
        this.form.gemeindes[gemeindesIndex].federal_state_uid.toString() !== '0'
      ) {
        myResult = false
      }

      return myResult
    },

    calcIdAndRef(prefix, gemeindesIndex, parcelsIndex, usageTypesIndex) {
      let result = ''
      if (typeof prefix === 'string' && typeof gemeindesIndex === 'number') {
        result = prefix + gemeindesIndex.toString()
        if (typeof parcelsIndex === 'number') {
          result = result + '_' + parcelsIndex.toString()
          if (typeof usageTypesIndex === 'number') {
            result = result + '_' + usageTypesIndex.toString()
          }
        }
      }
      return result
    },

    searchCommunity(event, gemeindesIndex) {
      if (!event.query.trim().length) {
        this.gemeindesHelper[gemeindesIndex].filteredCommunityNames = [
          ...this.gemeindesHelper[gemeindesIndex].communityNames,
        ]
      } else {
        this.gemeindesHelper[gemeindesIndex].filteredCommunityNames = this.gemeindesHelper[
          gemeindesIndex
        ].communityNames.filter((o) => {
          return o.toLowerCase().includes(event.query.toLowerCase())
        })
      }
    },

    searchGemarkungenName(event, gemeindesIndex, parcelsIndex) {
      if (!event.query.trim().length) {
        this.gemeindesHelper[gemeindesIndex].parcelsHelper[parcelsIndex].gemarkungenFilteredByName =
          this.gemeindesHelper[gemeindesIndex].parcelsHelper[parcelsIndex].gemarkungen.map(function (objGemarkung) {
            return objGemarkung.name
          })
      } else {
        this.gemeindesHelper[gemeindesIndex].parcelsHelper[parcelsIndex].gemarkungenFilteredByName =
          this.gemeindesHelper[gemeindesIndex].parcelsHelper[parcelsIndex].gemarkungen
            .filter(function (objGemarkung) {
              return objGemarkung.name.toLowerCase().includes(event.query.toLowerCase())
            })
            .map(function (objGemarkung) {
              return objGemarkung.name
            })
      }
    },

    searchGemarkungenNumber(event, gemeindesIndex, parcelsIndex) {
      if (!event.query.trim().length) {
        this.gemeindesHelper[gemeindesIndex].parcelsHelper[parcelsIndex].gemarkungenFilteredByCode =
          this.gemeindesHelper[gemeindesIndex].parcelsHelper[parcelsIndex].gemarkungen.map(function (objGemarkung) {
            return objGemarkung.code
          })
      } else {
        this.gemeindesHelper[gemeindesIndex].parcelsHelper[parcelsIndex].gemarkungenFilteredByCode =
          this.gemeindesHelper[gemeindesIndex].parcelsHelper[parcelsIndex].gemarkungen
            .filter(function (objGemarkung) {
              return objGemarkung.code.toLowerCase().includes(event.query.toLowerCase())
            })
            .map(function (objGemarkung) {
              return objGemarkung.code
            })
      }
    },

    onChangeFederalState() {
      this.initGemeindesHelper()
    },

    onItemSelectCommunity(event, gemeindeIndex) {
      this.initParcelsHelper(gemeindeIndex)
    },

    findGemarkungNumberForGemarkungName(gemarkungName, gemeindesIndex, parcelsIndex) {
      let gemarkungNumber = ''

      if (
        typeof gemarkungName !== 'undefined' &&
        typeof gemeindesIndex === 'number' &&
        gemeindesIndex >= 0 &&
        typeof this.form.gemeindes === 'object' &&
        gemeindesIndex < this.form.gemeindes.length &&
        typeof this.gemeindesHelper === 'object' &&
        gemeindesIndex < this.gemeindesHelper.length &&
        typeof parcelsIndex === 'number' &&
        parcelsIndex >= 0 &&
        typeof this.form.gemeindes[gemeindesIndex].parcels === 'object' &&
        parcelsIndex < this.form.gemeindes[gemeindesIndex].parcels.length &&
        typeof this.gemeindesHelper[gemeindesIndex].parcelsHelper === 'object' &&
        parcelsIndex < this.gemeindesHelper[gemeindesIndex].parcelsHelper.length
      ) {
        gemarkungName = gemarkungName.toString().trim()
        let objGemarkung = this.gemeindesHelper[gemeindesIndex].parcelsHelper[parcelsIndex].gemarkungen.find(function (
          objGemarkung,
        ) {
          return objGemarkung.name === gemarkungName
        }, this)
        if (typeof objGemarkung === 'object' && typeof objGemarkung.code !== 'undefined') {
          gemarkungNumber = objGemarkung.code.toString()
        }
        if (gemarkungNumber !== '') {
          let parsedGemarkungNumber = parseInt(gemarkungNumber)
          if (isNaN(parsedGemarkungNumber)) {
            gemarkungNumber = ''
          }
        }
      }
      return gemarkungNumber
    },

    findGemarkungNameForGemarkungNumber(gemarkungNumber, gemeindesIndex, parcelsIndex) {
      let gemarkungName = ''

      if (
        typeof gemarkungNumber !== 'undefined' &&
        typeof gemeindesIndex === 'number' &&
        gemeindesIndex >= 0 &&
        typeof this.form.gemeindes === 'object' &&
        gemeindesIndex < this.form.gemeindes.length &&
        typeof this.gemeindesHelper === 'object' &&
        gemeindesIndex < this.gemeindesHelper.length &&
        typeof parcelsIndex === 'number' &&
        parcelsIndex >= 0 &&
        typeof this.form.gemeindes[gemeindesIndex].parcels === 'object' &&
        parcelsIndex < this.form.gemeindes[gemeindesIndex].parcels.length &&
        typeof this.gemeindesHelper[gemeindesIndex].parcelsHelper === 'object' &&
        parcelsIndex < this.gemeindesHelper[gemeindesIndex].parcelsHelper.length
      ) {
        gemarkungNumber = gemarkungNumber.toString().trim()
        let objGemarkung = this.gemeindesHelper[gemeindesIndex].parcelsHelper[parcelsIndex].gemarkungen.find(function (
          objGemarkung,
        ) {
          return objGemarkung.code === gemarkungNumber
        }, this)
        if (typeof objGemarkung === 'object' && typeof objGemarkung.name !== 'undefined') {
          gemarkungName = objGemarkung.name.trim()
        }
      }
      return gemarkungName
    },

    onItemSelectGemarkungenName(event, gemeindesIndex, parcelsIndex) {
      let selectedGemarkungCode = this.findGemarkungNumberForGemarkungName(event.value, gemeindesIndex, parcelsIndex)
      if (typeof selectedGemarkungCode === 'string' && selectedGemarkungCode !== '') {
        this.form.gemeindes[gemeindesIndex].parcels[parcelsIndex].district_number = selectedGemarkungCode
      }
    },

    onItemSelectGemarkungenNumber(event, gemeindesIndex, parcelsIndex) {
      let selectedGemarkungName = this.findGemarkungNameForGemarkungNumber(event.value, gemeindesIndex, parcelsIndex)
      if (typeof selectedGemarkungName === 'string' && selectedGemarkungName !== '') {
        this.form.gemeindes[gemeindesIndex].parcels[parcelsIndex].district = selectedGemarkungName
      }
    },

    presetEmptyGemeindesHelper(count) {
      let emptyHelper = {
        federalStateName: '',
        communityNames: [],
        filteredCommunityNames: [],
      }
      this.gemeindesHelper = []
      if (typeof count === 'number' && count > 0) {
        for (let i = 1; i <= count; i++) {
          this.gemeindesHelper.push(JSON.parse(JSON.stringify(emptyHelper)))
        }
      }
    },

    initParcelsHelper(gemeindeIndex) {
      if (
        typeof gemeindeIndex === 'number' &&
        typeof this.gemeindesHelper === 'object' &&
        this.gemeindesHelper.length > 0 &&
        gemeindeIndex >= 0 &&
        gemeindeIndex < this.gemeindesHelper.length &&
        typeof this.form.gemeindes === 'object' &&
        this.form.gemeindes.length > 0 &&
        gemeindeIndex < this.form.gemeindes.length
      ) {
        this.gemeindesHelper[gemeindeIndex].parcelsHelper = []
        if (
          typeof this.form.gemeindes[gemeindeIndex].parcels === 'object' &&
          this.form.gemeindes[gemeindeIndex].parcels.length > 0
        ) {
          let objParcelsHelper = {
            federalStateName: '',
            communityName: '',
            gemarkungen: [],
            gemarkungenFilteredByName: [],
            germarkungenFilteredByCode: [],
          }
          objParcelsHelper.federalStateName = this.gemeindesHelper[gemeindeIndex].federalStateName
          if (
            typeof this.form.gemeindes[gemeindeIndex] === 'object' &&
            typeof this.form.gemeindes[gemeindeIndex].community === 'string'
          ) {
            objParcelsHelper.communityName = this.form.gemeindes[gemeindeIndex].community.trim()
          }
          if (
            typeof objParcelsHelper.federalStateName === 'string' &&
            objParcelsHelper.federalStateName !== '' &&
            typeof objParcelsHelper.communityName === 'string' &&
            objParcelsHelper.communityName !== ''
          ) {
            let arrGemarkungen = this.gemarkungenForStateAndCommunity(
              objParcelsHelper.federalStateName,
              objParcelsHelper.communityName,
            )
            if (typeof arrGemarkungen === 'object' && arrGemarkungen.length > 0) {
              arrGemarkungen.forEach(function (objGemarkung) {
                if (typeof objGemarkung.name === 'string' && typeof objGemarkung.code === 'string') {
                  objParcelsHelper.gemarkungen.push(objGemarkung)
                }
              }, this)
            }
          }
          this.form.gemeindes[gemeindeIndex].parcels.forEach(function () {
            this.gemeindesHelper[gemeindeIndex].parcelsHelper.push(JSON.parse(JSON.stringify(objParcelsHelper)))
          }, this)
        }
      }
    },

    updateParcelsHelper() {
      if (typeof this.gemeindesHelper === 'object' && this.gemeindesHelper.length > 0) {
        this.gemeindesHelper.forEach(function (objGemeinde) {
          if (typeof objGemeinde.parcelsHelper === 'object' && objGemeinde.parcelsHelper.length > 0) {
            objGemeinde.parcelsHelper.forEach(function (objParcel) {
              if (
                typeof objParcel.federalStateName === 'string' &&
                typeof objParcel.communityName === 'string' &&
                typeof objParcel.gemarkungen === 'object' &&
                objParcel.gemarkungen.length === 0
              ) {
                let arrGemarkungen = this.gemarkungenForStateAndCommunity(
                  objParcel.federalStateName,
                  objParcel.communityName,
                )
                if (typeof arrGemarkungen === 'object' && arrGemarkungen.length > 0) {
                  arrGemarkungen.forEach(function (objGemarkung) {
                    if (typeof objGemarkung.name === 'string' && typeof objGemarkung.code === 'string') {
                      objParcel.gemarkungen.push(objGemarkung)
                    }
                  }, this)
                }
              }
            }, this)
          }
        }, this)
      }
    },

    initGemeindesHelper() {
      this.gemeindesHelper = []
      if (typeof this.form.gemeindes === 'object' && this.form.gemeindes.length > 0) {
        this.form.gemeindes.forEach(function (gemeinde, gemeindeIndex) {
          let objHelper = {
            federalStateName: '',
            communityNames: [],
            filteredCommunityNames: [],
            parcelsHelper: [],
          }
          if (
            typeof gemeinde.federal_state_uid !== 'undefined' &&
            gemeinde.federal_state_uid.toString() !== '0' &&
            gemeinde.federal_state_uid.toString() !== ''
          ) {
            objHelper.federalStateName = this.$t('federal_states.' + gemeinde.federal_state_uid.toString())
            let arrCommunities = this.communitiesForFederalState(objHelper.federalStateName)
            if (typeof arrCommunities === 'object' && arrCommunities.length > 0) {
              arrCommunities.forEach(function (objCommunity) {
                if (typeof objCommunity.name === 'string') {
                  objHelper.communityNames.push(objCommunity.name)
                }
              }, this)
            }
          }
          this.gemeindesHelper.push(objHelper)
          this.initParcelsHelper(gemeindeIndex)
        }, this)
      }
    },

    communitiesForFederalState(federalStateName) {
      let found = false
      let resultArray = []
      if (typeof this.getArrFederalStatesCommunities === 'object' && this.getArrFederalStatesCommunities.length > 0) {
        let foundIndex = this.getArrFederalStatesCommunities.findIndex(function (objFederalStateCommunities) {
          if (objFederalStateCommunities.federalStateName === federalStateName) {
            return true
          }
        })
        if (foundIndex !== -1) {
          found = true
          let objCommunities = Object.values(this.getArrFederalStatesCommunities[foundIndex].arrCommunities)
          objCommunities.forEach(function (objCommunity) {
            resultArray.push(objCommunity)
          }, this)
        }
      }
      if (!found) {
        this.apiGetCommunitiesForFederalStatesPoolOnly({ federalState: federalStateName })
      }
      return resultArray
    },

    gemarkungenForStateAndCommunity(federalStateName, communityName) {
      let found = false
      let resultArray = []
      if (typeof this.getArrGemarkungenCollection === 'object' && this.getArrGemarkungenCollection.length > 0) {
        let foundIndex = this.getArrGemarkungenCollection.findIndex(function (objGemarkungen) {
          if (objGemarkungen.federalStateName === federalStateName && objGemarkungen.communityName === communityName) {
            return true
          }
        })
        if (foundIndex !== -1) {
          found = true
          let objGemarkungen = Object.values(this.getArrGemarkungenCollection[foundIndex].arrGemarkungen)
          objGemarkungen.forEach(function (objGemarkung) {
            resultArray.push(objGemarkung)
          }, this)
        }
      }
      if (!found) {
        this.apiGetGemarkungenCollection({
          federalState: federalStateName,
          community: communityName,
        })
      }
      return resultArray
    },

    initForm() {
      for (const [key, value] of Object.entries(this.$store.state.Properties.formPropertyAgriculture)) {
        if (typeof value !== 'undefined') {
          if (key === 'gemeindes') {
            this.presetEmptyGemeindesHelper(value.length)
          }
          this.form[key] = JSON.parse(JSON.stringify(value))
        }
      }
      this.initGemeindesHelper()
    },

    saveForm() {
      let myKeys = []
      this.resetFormPropertyAgriculture()
      myKeys = Object.keys(this.$store.state.Properties.formPropertyAgriculture)
      if (typeof myKeys === 'object') {
        myKeys.forEach(function (key) {
          if (typeof this.form[key] !== 'undefined') {
            this.$store.state.Properties.formPropertyAgriculture[key] = JSON.parse(JSON.stringify(this.form[key]))
          }
        }, this)
      }
    },

    cancel() {
      this.$emit('cancelPropVisible')
    },

    callForPrecedingStep() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForPrecedingStep')
      } else {
        this.$emit('callForPrecedingStep')
      }
    },

    callForNextStep() {
      if (this.isPropertyReadOnly) {
        this.$emit('callForNextStep')
      } else {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForNextStep')
      }
    },

    save() {
      if (this.isPropertyReadOnly) {
        return
      }

      this.prepareForValidate()
      if (this.form.validate().errors().any()) {
        this.$store.dispatch('showToast', {
          message: this.$t('newProperty.form_validation_text'),
          toastType: 'error',
        })
        return
      }
      this.saveForm()
      this.$emit('callForSaveProperty')
    },

    invokeReference(ref) {
      this.$nextTick(() => {
        if (!this.$refs[ref]) return
        if (
          typeof this.$refs !== 'undefined' &&
          typeof this.$refs[ref] !== 'undefined' &&
          typeof this.$refs[ref].focus === 'function'
        ) {
          this.$refs[ref].focus()
        }
      })
    },

    addGemeindes() {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyAgriculture.gemeindes[0]))
      myObject.parcels = []
      this.form.gemeindes.push(myObject)
      this.initGemeindesHelper()
      this.invokeReference(this.calcIdAndRef('community_', this.form.gemeindes.length - 1))
    },

    removeGemeindes(index) {
      let newIndex
      if (typeof index === 'number' && index >= 0 && index < this.form.gemeindes.length) {
        this.form.gemeindes.splice(index, 1)
        this.initGemeindesHelper()

        newIndex = index
        if (newIndex > this.form.gemeindes.length - 1) {
          newIndex--
        }
        this.resetFormErrors()
        if (newIndex >= 0) {
          this.invokeReference(this.calcIdAndRef('community_', newIndex))
        }
      }
    },

    addItemParcel(gemeindesIndex) {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyAgriculture.gemeindes[0].parcels[0]))
      myObject.usageTypes = []
      if (typeof gemeindesIndex === 'number' && gemeindesIndex >= 0 && gemeindesIndex < this.form.gemeindes.length) {
        this.form.gemeindes[gemeindesIndex].parcels.push(myObject)
        this.initParcelsHelper(gemeindesIndex)
      }
    },

    removeItemParcel(gemeindeIndex, parcelIndex) {
      if (
        typeof gemeindeIndex === 'number' &&
        gemeindeIndex >= 0 &&
        gemeindeIndex < this.form.gemeindes.length &&
        typeof parcelIndex === 'number' &&
        parcelIndex >= 0 &&
        parcelIndex < this.form.gemeindes[gemeindeIndex].parcels.length
      ) {
        this.form.gemeindes[gemeindeIndex].parcels.splice(parcelIndex, 1)
        this.initParcelsHelper(gemeindeIndex)
        this.resetFormErrors()
      }
    },

    addItemUsageTypeMore(gemeindeIndex, parcelIndex) {
      let myObject
      if (
        typeof gemeindeIndex === 'number' &&
        gemeindeIndex >= 0 &&
        gemeindeIndex < this.form.gemeindes.length &&
        typeof parcelIndex === 'number' &&
        parcelIndex >= 0 &&
        typeof this.form.gemeindes[gemeindeIndex].parcels === 'object' &&
        parcelIndex < this.form.gemeindes[gemeindeIndex].parcels.length
      ) {
        myObject = JSON.parse(JSON.stringify(defaultPropertyAgriculture.gemeindes[0].parcels[0].usageTypes[0]))
        this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes.push(myObject)
      }
    },

    removeItemUsageType(gemeindeIndex, parcelIndex, usageTypeIndex) {
      if (
        typeof gemeindeIndex === 'number' &&
        gemeindeIndex >= 0 &&
        gemeindeIndex < this.form.gemeindes.length &&
        typeof parcelIndex === 'number' &&
        parcelIndex >= 0 &&
        typeof this.form.gemeindes[gemeindeIndex].parcels === 'object' &&
        parcelIndex < this.form.gemeindes[gemeindeIndex].parcels.length &&
        typeof usageTypeIndex === 'number' &&
        usageTypeIndex >= 0 &&
        typeof this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes === 'object' &&
        usageTypeIndex < this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes.length
      ) {
        this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes.splice(usageTypeIndex, 1)
        this.resetFormErrors()
      }
    },

    isInArray(value, array) {
      return array.indexOf(value) > -1
    },

    getMaxLength(attr_key) {
      let myFederalStateUid = 0
      if (
        typeof this.$store.state.Properties.objPropertyDetails.step2 === 'object' &&
        typeof this.$store.state.Properties.objPropertyDetails.step2.federalStateUid !== 'undefined'
      ) {
        myFederalStateUid = this.$store.state.Properties.objPropertyDetails.step2.federalStateUid
      }

      if (attr_key === 'denominator') {
        return myFederalStateUid == 1 ? 15 : 4
      } else if (attr_key === 'areaOfTheLand') {
        return myFederalStateUid == 7 ? 9 : 8
      } else if (attr_key === 'yieldMeasurementNumber') {
        return myFederalStateUid === 7 ? 9 : 6
      } else {
        return 10
      }
    },

    getMaxValue(maxLength) {
      let myValue = 0

      if (typeof maxLength === 'number' && maxLength > 0) {
        let strValue = ''
        for (let i = 1; i <= maxLength; i++) {
          strValue = strValue + '9'
        }
        let parsedValue = parseInt(strValue)
        if (!isNaN(parsedValue)) {
          myValue = parsedValue
        }
      }

      return myValue
    },

    addFlatField(
      objFlatFields, // must be an object
      rawFieldName, // must be a string
      value, // must be a simple value, no objects
      firstIndex, // must be a number
      secondIndex, // optional: either undefined or number
      thirdIndex, // optional: either undefined or number
      maxRuleValue, // must be a number. 0 => no max rule will be set
      maxRuleMsg, // must be a string. "" => no max custom message will be set
      requiredValue, // must be true/false. false => no required rule will be set
      requiredMsg, // must be a string. "" => no required custom message will be set
      lessThanValue, // must be a number. 0 => no less_than rule will be set
      lessThanMsg, // must be a string. "" => no less_than custom message will be set
      greaterThanValue, // must be a number or undefined. undefined => no greater_than rule will be set
      greaterThanMsg, // must be a string. "" => no greater_than custom message will be set
      positiveValue, // must be true/false. false => no positive rule will be set
      positiveMsg, // must be a string. "" => no positive custom message will be set
    ) {
      const prefix = 'gemeindes_'
      let hasSecondIndex = typeof secondIndex === 'number'
      let hasThirdIndex = typeof thirdIndex === 'number'
      let flatFieldName = ''
      let fieldRules = []

      if (value === null) value = ''

      if (
        typeof objFlatFields === 'object' &&
        typeof rawFieldName === 'string' &&
        typeof value !== 'undefined' &&
        typeof value !== 'object' &&
        typeof firstIndex === 'number' &&
        typeof maxRuleValue === 'number' &&
        typeof maxRuleMsg === 'string' &&
        typeof requiredValue === 'boolean' &&
        typeof requiredMsg === 'string' &&
        typeof lessThanValue === 'number' &&
        typeof lessThanMsg === 'string' &&
        typeof greaterThanMsg === 'string' &&
        typeof positiveValue === 'boolean' &&
        typeof positiveMsg === 'string'
      ) {
        //build flat field name
        flatFieldName = prefix + firstIndex.toString() + '_'
        if (hasSecondIndex) {
          flatFieldName = flatFieldName + 'parcel_' + secondIndex.toString() + '_'
        } else if (hasThirdIndex) {
          flatFieldName = flatFieldName + 'use_' + thirdIndex.toString() + '_'
        }
        flatFieldName = flatFieldName + rawFieldName
        //add flat field to object
        objFlatFields[flatFieldName] = value

        //initialize field rules
        fieldRules = []

        //handle max rule
        if (maxRuleValue > 0) {
          if (maxRuleMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.max', maxRuleMsg)
          }
          fieldRules.push('max: ' + maxRuleValue.toString())
        }

        //handle required rule
        if (requiredValue) {
          if (requiredMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.required', requiredMsg)
          }
          fieldRules.push('required')
        }

        //handle less_than rule
        if (lessThanValue > 0) {
          if (lessThanMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.less_than', lessThanMsg)
          }
          fieldRules.push('less_than: ' + lessThanValue.toString())
        }

        //handle greater_than rule
        if (typeof greaterThanValue === 'number') {
          if (greaterThanMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.greater_than', greaterThanMsg)
          }
          fieldRules.push('greater_than: ' + greaterThanValue.toString())
        }

        //handle positive rule
        if (positiveValue) {
          if (positiveMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.positive', positiveMsg)
          }
          fieldRules.push('positive')
        }

        //add combined field rules
        this.form.validatorInstance.addRule(flatFieldName, fieldRules)
      }
    },

    addPositiveRule() {
      this.form.validatorInstance.extend({
        positive: [
          ':attribute must not be negative',
          function (value) {
            let result = true
            let myStringValue = value.value.toString().trim()
            let myFloatValue
            if (myStringValue !== '') {
              myFloatValue = parseFloat(myStringValue)
              if (!isNaN(myFloatValue)) {
                result = myFloatValue >= 0
              }
            }
            return result
          },
        ],
      })
    },
    prepareForValidate() {
      let myFlatFields = {}

      //remove existing flat fields first
      this.removeFlatFormFields()

      //add flat fields
      this.form.gemeindes.forEach(function (gemeinde, gemeindesIndex) {
        this.addFlatField(
          myFlatFields,
          'federal_state_uid',
          gemeinde.federal_state_uid,
          gemeindesIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          0,
          '',
          0,
          this.$t('newProperty.propertyAgriculture.messages.state_greater_than'),
          false,
          '',
        )
        // corridor

        gemeinde.parcels.forEach(function (parcel, parcelIndex) {
          this.addFlatField(
            myFlatFields,
            'corridor',
            parcel.corridor,
            gemeindesIndex,
            parcelIndex,
            undefined,
            0,
            '',
            false,
            '',
            1000,
            this.$t('newProperty.propertyAgriculture.messages.corridor_less_than'),
            undefined,
            '',
            true,
            this.$t('newProperty.propertyAgriculture.messages.positive'),
          )
          //counter
          this.addFlatField(
            myFlatFields,
            'counter',
            parcel.counter,
            gemeindesIndex,
            parcelIndex,
            undefined,
            0,
            '',
            false,
            '',
            100000,
            this.$t('newProperty.propertyAgriculture.messages.counter_less_than'),
            undefined,
            '',
            true,
            this.$t('newProperty.propertyAgriculture.messages.positive'),
          )
          //areaOfTheLand
          this.addFlatField(
            myFlatFields,
            'areaOfTheLand',
            parcel.areaOfTheLand,
            gemeindesIndex,
            parcelIndex,
            undefined,
            0,
            '',
            false,
            '',
            this.getMaxValue(this.getMaxLength('areaOfTheLand')) + 1,
            this.$t('newProperty.propertyAgriculture.messages.areaOfTheLand_less_than'),
            undefined,
            '',
            true,
            this.$t('newProperty.propertyAgriculture.messages.positive'),
          )
          //usage.areaOfTheLand
          parcel.usageTypes.forEach(function (usageType, usageTypeIndex) {
            this.addFlatField(
              myFlatFields,
              'areaOfTheLand',
              usageType.areaOfTheLand,
              gemeindesIndex,
              undefined,
              usageTypeIndex,
              0,
              '',
              false,
              '',
              100000000,
              this.$t('newProperty.propertyAgriculture.messages.areaOfTheLand_less_than'),
              undefined,
              '',
              true,
              this.$t('newProperty.propertyAgriculture.messages.positive'),
            )

            //getMaxLength("yieldMeasurementNumber")

            this.addFlatField(
              myFlatFields,
              'yieldMeasurementNumber',
              usageType.yieldMeasurementNumber,
              gemeindesIndex,
              undefined,
              usageTypeIndex,
              0,
              '',
              false,
              '',
              this.getMaxValue(this.getMaxLength('yieldMeasurementNumber')) + 1,
              this.$t('newProperty.propertyAgriculture.messages.areaOfTheLand_less_than'),
              undefined,
              '',
              true,
              this.$t('newProperty.propertyAgriculture.messages.positive'),
            )

            this.addFlatField(
              myFlatFields,
              'areaOfFarmBuildings',
              usageType.areaOfFarmBuildings,
              gemeindesIndex,
              undefined,
              usageTypeIndex,
              0,
              '',
              false,
              '',
              100000000,
              this.$t('newProperty.propertyAgriculture.messages.areaOfFarmBuildings_less_than'),
              undefined,
              '',
              true,
              this.$t('newProperty.propertyAgriculture.messages.positive'),
            )
            this.addFlatField(
              myFlatFields,
              'flowRate',
              usageType.flowRate,
              gemeindesIndex,
              undefined,
              usageTypeIndex,
              0,
              '',
              false,
              '',
              10000,
              this.$t('newProperty.propertyAgriculture.messages.flowRate_less_than'),
              undefined,
              '',
              true,
              this.$t('newProperty.propertyAgriculture.messages.positive'),
            )

            this.addFlatField(
              myFlatFields,
              'exemption_area',
              usageType.exemption_area,
              gemeindesIndex,
              undefined,
              usageTypeIndex,
              0,
              '',
              false,
              '',
              1000000000000000,
              this.$t('newProperty.propertyAgriculture.messages.exemption_area_less_than'),
              undefined,
              '',
              true,
              this.$t('newProperty.propertyAgriculture.messages.positive'),
            )
          }, this)
        }, this)
      }, this)
      this.form.set(myFlatFields)
    },

    removeFlatFormFields() {
      Object.keys(this.form.all()).forEach((key) => {
        if (key.startsWith('gemeindes_')) {
          this.form.forget(key)
        }
      })
      this.form.rules({})
    },

    isYieldMeasurementNumberDisabled(gemeindeIndex, parcelIndex, usageTypeIndex) {
      const validYieldMeasurementNumber = ['1', '21', '23']
      let myResult = true
      if (
        typeof gemeindeIndex === 'number' &&
        typeof parcelIndex === 'number' &&
        typeof usageTypeIndex === 'number' &&
        gemeindeIndex >= 0 &&
        parcelIndex >= 0 &&
        usageTypeIndex >= 0 &&
        typeof this.form.gemeindes === 'object' &&
        gemeindeIndex < this.form.gemeindes.length &&
        typeof this.form.gemeindes[gemeindeIndex].parcels === 'object' &&
        parcelIndex < this.form.gemeindes[gemeindeIndex].parcels.length &&
        typeof this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes === 'object' &&
        usageTypeIndex < this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes.length
      ) {
        myResult = !this.isInArray(
          this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes[usageTypeIndex].usageType,
          validYieldMeasurementNumber,
        )
        if (myResult) {
          this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes[usageTypeIndex].yieldMeasurementNumber = ''
        }
      }

      return myResult
    },

    isAreaOfFarmBuildingDisabled(gemeindeIndex, parcelIndex, usageTypeIndex) {
      const validAreaOfFarmBuilding = ['29', '30', '31', '32', '33', '34']
      let myResult = true
      if (
        typeof gemeindeIndex === 'number' &&
        typeof parcelIndex === 'number' &&
        typeof usageTypeIndex === 'number' &&
        gemeindeIndex >= 0 &&
        parcelIndex >= 0 &&
        usageTypeIndex >= 0 &&
        typeof this.form.gemeindes === 'object' &&
        gemeindeIndex < this.form.gemeindes.length &&
        typeof this.form.gemeindes[gemeindeIndex].parcels === 'object' &&
        parcelIndex < this.form.gemeindes[gemeindeIndex].parcels.length &&
        typeof this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes === 'object' &&
        usageTypeIndex < this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes.length
      ) {
        myResult = !this.isInArray(
          this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes[usageTypeIndex].usageType,
          validAreaOfFarmBuilding,
        )
        if (myResult) {
          this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes[usageTypeIndex].areaOfFarmBuildings = ''
        }
      }

      return myResult
    },

    isFlowRateDisabled(gemeindeIndex, parcelIndex, usageTypeIndex) {
      const validFlowRate = ['20']
      let myResult = true
      if (
        typeof gemeindeIndex === 'number' &&
        typeof parcelIndex === 'number' &&
        typeof usageTypeIndex === 'number' &&
        gemeindeIndex >= 0 &&
        parcelIndex >= 0 &&
        usageTypeIndex >= 0 &&
        typeof this.form.gemeindes === 'object' &&
        gemeindeIndex < this.form.gemeindes.length &&
        typeof this.form.gemeindes[gemeindeIndex].parcels === 'object' &&
        parcelIndex < this.form.gemeindes[gemeindeIndex].parcels.length &&
        typeof this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes === 'object' &&
        usageTypeIndex < this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes.length
      ) {
        myResult = !this.isInArray(
          this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes[usageTypeIndex].usageType,
          validFlowRate,
        )
        if (myResult) {
          this.form.gemeindes[gemeindeIndex].parcels[parcelIndex].usageTypes[usageTypeIndex].flowRate = ''
        }
      }

      return myResult
    },
    onlyNumber($event) {
      let code = $event.code ? $event.code : $event.which
      let key = $event.key
      if (code === 'KeyE' || key === 'e' || key === 'E') {
        $event.preventDefault()
      }
    },

    isDisabledReferenceCheckbox(gemeindesIndex, parcelsIndex) {
      let isDisabled = true

      if (!this.isPropertyReadOnly && this.parcel_is_reference_activated) {
        if (
          typeof gemeindesIndex === 'number' &&
          typeof parcelsIndex === 'number' &&
          Array.isArray(this.form.gemeindes) &&
          gemeindesIndex >= 0 &&
          gemeindesIndex < this.form.gemeindes.length &&
          Array.isArray(this.form.gemeindes[gemeindesIndex].parcels) &&
          parcelsIndex >= 0 &&
          parcelsIndex < this.form.gemeindes[gemeindesIndex].parcels.length
        ) {
          if (this.form.gemeindes[gemeindesIndex].parcels[parcelsIndex].isReference.toString() === '1') {
            isDisabled = false
          } else {
            isDisabled = this.hasReferenceParcel()
          }
        }
      }

      return isDisabled
    },

    hasReferenceParcel() {
      let referenceParcelsCount = 0
      this.form.gemeindes.forEach((gemeinde) => {
        if (referenceParcelsCount > 0) {
          return
        }
        gemeinde.parcels.forEach((parcel) => {
          if (referenceParcelsCount > 0) {
            return
          }
          if (parcel.isReference.toString() === '1') {
            referenceParcelsCount++
          }
        }, this)
      }, this)
      return referenceParcelsCount > 0
    },

    unsetAllParcelIsReference() {
      this.form.gemeindes.forEach((gemeinde) => {
        gemeinde.parcels.forEach((parcel) => {
          parcel.isReference = '0'
        }, this)
      }, this)
    },
  },
}
</script>

<style type="text/css">
textarea.textarea-field {
  border: 1px solid #bbbbbb;
  height: 150px;
  width: 100%;
}

.Step_SectionPropertyAgriculture .tooltip-icon img {
  height: 18px;
  margin-left: 5px;
}

label.label-title.tooltip-icon {
  position: relative;
}

.ToolTipTextBox {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: none;
  max-width: 600px;
  padding: 20px;
  padding: 15px !important;
  position: absolute;
  right: 0;
  top: 35px;
  transform: translate3d(0px, 40px, 0px) !important;
  z-index: 1;
}

.form_padding {
  padding-bottom: 5px;
  padding-top: 10px;
}

@media only screen and (min-width: 100px) and (max-width: 767px) {
  .checkbox_input input {
    display: block !important;
  }
}
</style>
