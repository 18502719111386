<template>
  <div>
    <div class="container">
      <div
        class="upload flex cursor-pointer items-center justify-between pl-2 pr-2"
        v-show="!showDragAndDrop"
        @click="choose"
      >
        <input
          ref="fileInput"
          type="file"
          class="hidden"
          :multiple="multiple"
          :accept="accept"
          @change="fileSelect"
        />
        <label class="lbl ml-3">{{ text }}</label>
        <img
          class="image-upload"
          src="@/assets/books.svg"
        />
      </div>
      <!-- Drag and Drop-->
      <div
        class="h-full"
        v-show="showDragAndDrop"
      >
        <div
          class="drop flex flex-col overflow-y-scroll"
          v-cloak
          @drop.prevent="addFile"
          @dragover.prevent
        >
          <div
            class="info_box"
            v-show="files.length === 0"
          >
            <slot name="emptyBody">
              <img
                class="img"
                src="@/assets/upload.svg"
              />
              <label
                class="label_inst"
                style="margin-top: 20px"
                >{{ $t('general.uploader.upload_signature_file') }}</label
              >
              <label
                class="label_inst"
                style="margin-top: 10px; margin-bottom: 10px"
                >{{ $t('general.uploader.or') }}</label
              >
              <button
                type="submit"
                :style="buttonStyle"
                @click="choose"
                class="upload-button-style"
              >
                {{ $t('general.uploader.select_file') }}
              </button>
              <label class="instructions">{{ $t('general.uploader.signature_instruction') }}</label>
            </slot>
          </div>
          <table class="filelist divide-gray divide-y bg-white">
            <tr
              v-for="(file, index) in files"
              :key="index"
            >
              <td class="filename">{{ file.name }}</td>
              <td class="status">
                <div v-show="file.uploadFailed"><img src="@/assets/books.svg" /></div>
                <div v-show="file.uploaded"><img src="@/assets/be.svg" /></div>
              </td>
              <td>({{ formatFileSize(file.size) }} kb)</td>
              <td class="action">
                <img
                  src="@/assets/btn_close_gray.svg"
                  class="img-close mr-2 cursor-pointer self-center"
                  @click="removeFile(file)"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid'
import { mapActions } from 'vuex'

export default {
  name: 'InputUpload',
  props: {
    accept: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    fileLimit: {
      type: Number,
      default: 25,
    },
    maxFileSize: {
      type: Number,
      default: 20971520, // 20 MB
    },
    showDragAndDrop: {
      type: Boolean,
      default: false,
    },
    buttonStyle: {
      default: '',
    },
  },
  data() {
    return {
      field: '',
      text: '',
      files: [],
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.field = this.value
      }
    },
    field(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('input', newValue)
      }
    },
  },
  methods: {
    ...mapActions(['showToast']),
    clear() {
      this.reset = false
      this.files = []
      this.text = ''
    },
    getFiles() {
      return this.files
    },
    choose() {
      if (!this.showDragAndDrop) {
        this.files = []
      }
      this.$refs.fileInput.click()
    },
    fileSelect(event) {
      let files = ''
      files = event.dataTransfer ? event.dataTransfer.files : event.target.files
      if (files.length > 0) {
        if (this.processSelectedFiles(files)) {
          if (this.multiple) {
            this.text = this.$t('general.x_files_selected', {
              count: files.length,
            })
          } else {
            this.text = files[0].name
          }

          this.$emit('select', { originalEvent: event, files: this.files })
          this.$refs.fileInput.value = ''
        }
      }
    },
    addFile(e) {
      let droppedFiles = e.dataTransfer.files
      if (!droppedFiles) return

      let selectedFiles = []
      ;[...droppedFiles].forEach((f) => {
        if (this.multiple || selectedFiles.length === 0) {
          selectedFiles.push(f)
        }
      })
      if (selectedFiles.length > 0) {
        if (this.processSelectedFiles(selectedFiles)) {
          this.$emit('select', { originalEvent: event, files: selectedFiles })
        }
      }
    },
    removeFile(file) {
      this.files = this.files.filter((f) => {
        return f !== file
      })
      this.$emit('select', { originalEvent: event, files: this.files })
    },
    processSelectedFiles(files) {
      if (files.length > 0) {
        // Validate file type
        if (this.accept) {
          let allowed_types = this.accept.split(',')
          for (let file of files) {
            let fext = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase()
            if (!allowed_types.includes('.' + fext)) {
              this.showToast({
                message: this.$t('general.file_type_error', {
                  allowed_types: this.accept,
                }),
                toastType: 'error',
              })
              return false
            }
          }
        }

        // Validate file size
        if (this.maxFileSize > 0) {
          for (let file of files) {
            if (file.size > this.maxFileSize) {
              this.showToast({
                message: this.$t('general.fileTooBig'),
                toastType: 'error',
              })
              return false
            }
          }
        }

        let limitExceeded = false
        for (let file of files) {
          if (this.fileLimit > this.files.length) {
            file.uuid = uuid.v1()
            this.files.push(file)
          } else {
            limitExceeded = true
          }
        }
        if (limitExceeded) {
          this.showToast({
            message: this.$t('general.upload_file_limit_error'),
            toastType: 'error',
          })
        }

        return true
      }

      return false
    },
    formatFileSize(size) {
      if (parseInt(size) > 0) {
        return (size / 1024).toFixed(2)
      } else {
        return ''
      }
    },
  },
}
</script>

<style scoped lang="scss">
.help_icon {
  box-sizing: border-box;
  color: #333333;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  height: 18px;
  line-height: normal;
  margin-left: 5px;
  text-align: center;
  width: 18px;
}
.hidden {
  display: none;
}

.right-text {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  color: blue;
  cursor: pointer;
  font-family: 'Segoe UI', sans-serif;
  font-size: 15px;
  line-height: normal;
  margin-bottom: 5px;

  margin-right: 5px;
  text-align: right;
  text-decoration: underline;
}

input {
  background-color: #ffffff;
  box-sizing: border-box;
  color: black;
  font-family: 'Segoe UI', sans-serif;
  text-align: left;
  width: 100%;
}

.label {
  color: #4b4b4b;
  font-family: 'Segoe UI', sans-serif;
  font-size: 15px;
  text-align: left;
}

.error-label {
  float: left;
  font-family: 'Segoe UI', sans-serif;
}

.upload {
  background-color: #ffffff;
  border: 2px solid #7a7a7a;
  box-sizing: border-box;
  height: 32px;
  padding: 2px 2px 2px 2px;
}

.image-upload {
  box-sizing: border-box;
  height: 18px;
  margin-right: 8px;
  width: 18px;
}

.lbl {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;

  color: #4b4b4b;
  font-family: 'Segoe UI', sans-serif;
  font-size: 15px;
  line-height: 20px;
  margin-right: 20px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.drop {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C4C4C4FF' stroke-width='2' stroke-dasharray='7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  box-sizing: border-box;
  height: 100%;
  padding: 2px 2px 2px 2px;
}

.img {
  height: 43px;
  width: 43px;
}

.img-close {
  cursor: pointer;
  height: 15px;
  width: 15px;
}
.overflow-y-scroll {
  overflow-y: auto;
  padding: 15px;
}
.filelist {
  table-layout: fixed;
  width: 100%;
  td {
    text-align: left;
  }
  td.filename {
    overflow: hidden;
    padding: 10px 10px 10px 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 440px;
  }
  td.status {
    width: 40px;

    img {
      margin-left: auto;
      margin-right: auto;
      width: 20px;
    }
  }
  td.action {
    padding-left: 0px;
    padding-right: 10px;
    width: 20px;
  }
}
.info_box {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top: 10px;
}
.upload-button-style {
  background-color: #229d56;
  border-color: transparent;
  border-radius: 2px;
  color: #ffffff;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  height: 40px;
  line-height: normal;
  text-align: center;
  width: 200px;
}
.instructions {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  color: #aeaeae !important;
  font-family: 'Segoe UI', sans-serif;
  font-size: 12px;
  height: 50px;
  line-height: 25px;
  margin-top: 8px;
  text-align: left;
  width: auto;
}
.label_inst {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  color: #4b4b4b !important;
  font-family: 'Segoe UI', sans-serif;
  font-size: 15px;
  text-align: center;
}
.h-full {
  height: 100%;
}
</style>
