<template>
  <!-- My Data  Start -->
  <section class="login_form section mydata">
    <div class="container">
      <div class="row topspace60">
        <div class="col-md-12 text-left">
          <div class="sec-heading">
            <h4 class="heading_title dynamicColor">{{ $t('mydata.title') }}</h4>
            <p class="p-content dynamicColor">{{ $t('mydata.subline') }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h2 class="form-title-h2 dynamicColor">{{ $t('mydata.base') }}</h2>
        </div>
        <div
          class="col-md-6"
          v-show="form.salutation"
        >
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.salutation') }}</label>
            <input
              type="text"
              autocomplete="off"
              v-model="form.salutation"
              disabled
              class="input-field"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.fname') }}</label>
            <input
              type="text"
              autocomplete="off"
              v-model="form.vorname"
              disabled
              class="input-field"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.lname') }}</label>
            <input
              type="text"
              autocomplete="off"
              v-model="form.nachname"
              disabled
              class="input-field"
            />
            <span v-if="form.errors().has('nachname')">
              {{ form.errors().get('nachname') }}
            </span>
          </div>
        </div>
        <!-- <div class="col-md-6">
          <div class="data-form">
           <label class="label-title">{{$t('mydata.dob')}}</label>
          <input type='date' v-model='form.geburtsdatum' disabled placeholder="tt.mm.jjjj" class="input-field"/>
          <span v-if="form.errors().has('geburtsdatum')">
              {{ form.errors().get('geburtsdatum') }}
          </span>
        </div>
        </div> -->
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.vatId') }}</label>
            <input
              type="text"
              autocomplete="off"
              v-model="form.vatId"
              disabled
              class="input-field"
            />
          </div>
        </div>
        <div
          class="col-md-6"
          v-show="form.clientType === 'private'"
        >
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.clientNumber') }}</label>
            <input
              type="text"
              autocomplete="off"
              v-model="form.clientNumber"
              disabled
              class="input-field"
            />
          </div>
        </div>
      </div>

      <div class="row topspace60">
        <div class="col-md-12">
          <h2 class="form-title-h2 dynamicColor">{{ $t('mydata.contact') }}</h2>
        </div>
        <div class="col-md-4">
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.street') }}</label>
            <input
              type="text"
              autocomplete="off"
              v-model="form.strasse"
              disabled
              class="input-field"
            />
            <span v-if="form.errors().has('strasse')">
              {{ form.errors().get('strasse') }}
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.houseno') }}</label>
            <input
              type="text"
              autocomplete="off"
              v-model="form.hausnummer"
              disabled
              class="input-field"
            />
            <span v-if="form.errors().has('hausnummer')">
              {{ form.errors().get('hausnummer') }}
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.pobox') }}</label>
            <input
              type="text"
              autocomplete="off"
              v-model="form.postfach"
              disabled
              class="input-field"
            />
            <span v-if="form.errors().has('postfach')">
              {{ form.errors().get('postfach') }}
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.plz') }}</label>
            <input
              type="text"
              autocomplete="off"
              v-model="form.plz"
              disabled
              class="input-field"
            />
            <span v-if="form.errors().has('plz')">
              {{ form.errors().get('plz') }}
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.ort') }}</label>
            <input
              type="text"
              autocomplete="off"
              v-model="form.ort"
              disabled
              class="input-field"
            />
            <span v-if="form.errors().has('ort')">
              {{ form.errors().get('ort') }}
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.land') }}</label>
            <input
              type="text"
              autocomplete="off"
              v-model="form.land"
              disabled
              class="input-field"
            />
            <span v-if="form.errors().has('land')">
              {{ form.errors().get('land') }}
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.email') }}</label>
            <input
              type="email"
              autocomplete="off"
              v-model="form.email"
              disabled
              class="input-field"
            />
            <span v-if="form.errors().has('email')">
              {{ form.errors().get('email') }}
            </span>
          </div>
        </div>
      </div>
      <div class="row topspace60">
        <div class="col-md-4">
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.mobile') }}</label>
            <input
              type="text"
              autocomplete="off"
              v-model="form.mobilnummer"
              disabled
              class="input-field"
            />
            <span v-if="form.errors().has('mobilnummer')">
              {{ form.errors().get('mobilnummer') }}
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.tel') }}</label>
            <input
              type="text"
              autocomplete="off"
              v-model="form.telefon"
              disabled
              class="input-field"
            />
            <span v-if="form.errors().has('telefon')">
              {{ form.errors().get('telefon') }}
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.fax') }}</label>
            <input
              type="text"
              autocomplete="off"
              v-model="form.fax"
              disabled
              class="input-field"
            />
            <span v-if="form.errors().has('fax')">
              {{ form.errors().get('fax') }}
            </span>
          </div>
        </div>
      </div>

      <div
        class="row topspace60"
        v-if="allowChangePassword"
      >
        <div class="col-md-12">
          <h2 class="form-title-h2 dynamicColor">{{ $t('mydata.pwd') }}</h2>
        </div>
        <div class="col-md-6">
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.npwd') }}</label>
            <!--  <input type='password' v-model='form.password' placeholder="Passwort eingeben" class="input-field"/> -->
            <Password
              inputClass="w-100"
              v-model="form.password"
              toggleMask
              :prompt-label="$t('passwordTemplate.promptLabel')"
              placeholder="Passwort eingeben"
              :strong-regex="strongRegex"
            >
              <template #header>
                <h6>{{ $t('passwordTemplate.title') }}</h6>
              </template>
              <template #footer>
                <Divider />
                <div
                  class="p-pl-2 p-ml-2 p-mt-0"
                  style="line-height: 1.5"
                >
                  <div class="flex items-center justify-start">
                    <img
                      class="validation-img"
                      :src="getMinimumValidationImage(form.password)"
                    />
                    <label :style="{ color: minimumError ? '#229D56' : '#A4262C' }">
                      {{ $t('passwordTemplate.text0') }}
                    </label>
                  </div>
                  <div class="flex items-center justify-start">
                    <img
                      class="validation-img"
                      :src="getLowercaseValidationImage(form.password)"
                    />
                    <label :style="{ color: lowercaseError ? '#229D56' : '#A4262C' }">
                      {{ $t('passwordTemplate.text1') }}
                    </label>
                  </div>
                  <div class="flex items-center justify-start">
                    <img
                      class="validation-img"
                      :src="getUppercaseValidationImage(form.password)"
                    />
                    <label :style="{ color: uppercaseError ? '#229D56' : '#A4262C' }">
                      {{ $t('passwordTemplate.text2') }}
                    </label>
                  </div>
                  <div class="flex items-center justify-start">
                    <img
                      class="validation-img"
                      :src="getNumericValidationImage(form.password)"
                    />
                    <label :style="{ color: numericError ? '#229D56' : '#A4262C' }">
                      {{ $t('passwordTemplate.text3') }}
                    </label>
                  </div>
                  <div class="flex items-center justify-start">
                    <img
                      class="validation-img"
                      :src="getDifferentCharValidationImage(form.password)"
                    />
                    <label :style="{ color: threeCharError ? '#229D56' : '#A4262C' }">
                      {{ $t('passwordTemplate.text4') }}
                    </label>
                  </div>
                  <div class="flex items-center justify-start">
                    <img
                      class="validation-img"
                      :src="getDifferentUserNameValidationImage(form.password)"
                    />
                    <label :style="{ color: usernameError ? '#229D56' : '#a72121' }">
                      {{ $t('passwordTemplate.text5') }}
                    </label>
                  </div>
                </div>
              </template>
            </Password>
            <span
              class="error"
              v-if="form.errors().has('password') || passError"
            >
              {{ form.errors().get('password') }}
            </span>
            <span
              class="error"
              v-if="passError"
            >
              {{ $t('mydata.pwErrorMinLen') }}
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="data-form">
            <label class="label-title">{{ $t('mydata.ncnfmpwd') }}</label>
            <Password
              inputClass="w-100"
              v-model="form.password_confirmation"
              :feedback="false"
              toggleMask
              placeholder="Passwort nochmal eingeben"
            ></Password>
            <span
              class="error"
              v-if="conPassError"
            >
              {{ $t('mydata.pwErrorConfirm') }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="col-md-12 text-center"
        v-if="allowChangePassword"
      >
        <div class="return-further form-button">
          <button
            class="button-style"
            @click="$router.go(-1)"
          >
            {{ $t('mydata.abort') }}
          </button>
          <button
            class="button-style"
            :style="[primaryColor ? { background: primaryColor } : '']"
            @click="submit"
          >
            {{ $t('mydata.save') }}
          </button>
        </div>
      </div>
    </div>
  </section>

  <!-- My Data  End ///////////// -->
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import { mapActions, mapGetters } from 'vuex'
import Password from 'primevue/password'
import Divider from 'primevue/divider'

export default {
  components: { Password, Divider },
  data: () => ({
    passError: false,
    conPassError: false,
    lowercaseError: false,
    uppercaseError: false,
    numericError: false,
    minimumError: false,
    threeCharError: false,
    usernameError: false,
    primaryColor: '',
    fontColor: '',
    strongRegex: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{9,})',

    form: form({
      anrede: '',
      vorname: '',
      nachname: '',
      vatId: '', // Steuernummer - both private and business
      clientNumber: '', // Identifikationsnummer - private only
      clientType: '', // either "private" or "business"
      strasse: '',
      hausnummer: '',
      postfach: '',
      plz: '',
      ort: '',
      land: '',
      email: '',
      mobilnummer: '',
      telefon: '',
      fax: '',
      password: '',
      password_confirmation: '',
    }),
  }),
  created() {
    this.form.vorname = this.getCurrentUser.firstName
    this.form.nachname = this.getCurrentUser.lastName
    this.form.email = this.getCurrentUser.email
    this.form.salutation = this.getCurrentUser.salutationLbl

    this.form.strasse = this.getCurrentUser.client.contact.street
    this.form.hausnummer = this.getCurrentUser.client.contact.houseNumber
    this.form.postfach = this.getCurrentUser.client.contact.poBox
    this.form.plz = this.getCurrentUser.client.contact.zipCode
    this.form.ort = this.getCurrentUser.client.contact.city
    this.form.land = this.getCurrentUser.client.contact.countryName
    this.form.telefon = this.getCurrentUser.client.contact.phone
    this.form.fax = this.getCurrentUser.client.contact.fax
    this.form.mobilnummer = this.getCurrentUser.mobile
      ? this.getCurrentUser.mobile
      : this.getCurrentUser.client.contact.mobile

    this.form.clientType = this.getCurrentUser.client.clientType
    this.form.vatId = this.getCurrentUser.client.vatId
    this.form.clientNumber =
      this.getCurrentUser.client.clientType === 'private' ? this.getCurrentUser.client.clientNumber : ''
  },
  computed: {
    ...mapGetters(['getCurrentUser']),
    allowChangePassword() {
      return this.getCurrentUser.authMode && this.getCurrentUser.authMode === 'credential'
    },
  },
  mounted() {
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#43ac6f'
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.fontColor : ''
    //let bodyStyles = document.documentElement;
    let bodyStyle = document.body.style
    bodyStyle.setProperty('--api-background', this.primaryColor)
  },
  methods: {
    ...mapActions(['updateUserInfo', 'encrypt']),
    async submit() {
      if (this.allowChangePassword) {
        if (this.form.validate().errors().any()) return
        let payload = {}
        let pass = this.form.password
        let conPass = this.form.password_confirmation
        this.passError = pass == '' || pass.length < 8
        if (this.passError) {
          return
        }
        this.conPassError = conPass == '' || conPass.length < 8 || conPass != pass
        if (this.conPassError) {
          return
        }

        payload['password'] = await this.encrypt(this.form.password)
        payload['confirm_password'] = await this.encrypt(this.form.password_confirmation)
        this.updateUserInfo(payload)
      }
    },

    getMinimumValidationImage(field) {
      if (field.length >= 9) {
        this.minimumError = true
        return require('@/assets/tick_password.svg')
      } else {
        this.minimumError = false
        return require('@/assets/cross_password.svg')
      }
    },
    getLowercaseValidationImage(field) {
      if (/[a-z]/.test(field)) {
        this.lowercaseError = true
        return require('@/assets/tick_password.svg')
      } else {
        this.lowercaseError = false
        return require('@/assets/cross_password.svg')
      }
    },
    getUppercaseValidationImage(field) {
      if (/[A-Z]/.test(field)) {
        this.uppercaseError = true
        return require('@/assets/tick_password.svg')
      } else {
        this.uppercaseError = false
        return require('@/assets/cross_password.svg')
      }
    },
    getNumericValidationImage(field) {
      if (/\d/.test(field)) {
        this.numericError = true
        return require('@/assets/tick_password.svg')
      } else {
        this.numericError = false
        return require('@/assets/cross_password.svg')
      }
    },
    getDifferentCharValidationImage(field) {
      if (/([\s\S])(?!\1\1)[\s\S]{2}/.test(field)) {
        this.threeCharError = true
        return require('@/assets/tick_password.svg')
      } else {
        this.threeCharError = false
        return require('@/assets/cross_password.svg')
      }
    },
    getDifferentUserNameValidationImage(field) {
      if (this.form.email !== field) {
        this.usernameError = true
        return require('@/assets/tick_password.svg')
      } else {
        this.usernameError = false
        return require('@/assets/cross_password.svg')
      }
    },
  },
}
</script>
<style scoped lang="css">
:root {
  --api-background: #43ac6f;
}

.login_form {
  padding-bottom: 100px;
}

.p-input-icon-right > i:last-of-type {
  color: var(--api-background) !important;
}

input:disabled {
  background: #f0f0f0;
}

.box-content:hover {
  border-color: #333;
}

.data-form input.input-field {
  border: 1px solid #bbbbbb;
  border-radius: 0;
  color: #999999;
  font-size: 15px;
  height: 40px;
  margin: 0 auto 0 auto;
  max-width: 100%;
  padding-left: 10px;
  width: 100%;
}

h2.form-title-h2 {
  color: #4b4b4b;
  font-size: 15px;
  margin-top: 30px;
  padding-bottom: 15px;
  position: relative;
  text-align: left;
}

h2.form-title-h2:before {
  border-bottom: 2px solid var(--api-background);
  bottom: 0;
  content: '';
  height: 2px;
  left: 0;
  position: absolute;
  width: 100px;
}

.data-form {
  padding: 5px 0;
}

.return-further.form-button {
  justify-content: center;
  margin: 50px auto 0 auto;
  max-width: 350px;
  text-align: center;
}

.return-further.form-button button.button-style {
  background: #229d56;
  border: 0;
  border-radius: 2px;
  color: #fff;
  height: 40px;
  max-width: 250px;
  width: 100%;
}

.return-further.form-button button.button-style:nth-child(1) {
  background: none;
  border: 1px solid #bbbbbb;
  border-radius: 2px;
  color: #333333;
}

.return-further.form-button button.button-style:nth-child(1):hover {
  background: #bbbbbb !important;
}

.return-further.form-button button.button-style:nth-child(2):hover {
  background: var(--api-background) !important;
  filter: brightness(0.8);
}

.sec-heading {
  border-bottom: 1px solid #dfdfdf;
  text-align: left;
}

select.form-control.input-field {
  color: #999999;
}

button.button-style:first-child:hover {
  background: var(--api-background) !important;
  filter: brightness(0.8);
}

.w-100 {
  width: 100%;
}
</style>
