<template>
  <section class="Step_SectionPropertyLivestock">
    <fieldset>
      <div class="Step_SectionPropertyLivestock">
        <InfoTextHeading
          :headingText="$t('newProperty.propertyLivestock.headline')"
          :infoText="$t('infoTextDisabledFields')"
        >
        </InfoTextHeading>

        <div
          class="col-md-7"
          @mouseenter="setInfoTooltip(0)"
        >
          <div class="data-form">
            <InfoText
              :text="$t('newProperty.propertyLivestock.infoText')"
              backgroundColor="rgba(239, 239, 239, 0.3)"
            >
            </InfoText>
          </div>
        </div>

        <div
          class="col-md-7"
          @mouseenter="setInfoTooltip(0)"
        >
          <h2 class="subheading_title">
            {{ $t('newProperty.propertyLivestock.subheadline') }}
          </h2>
          <hr class="subheading_devide" />
        </div>

        <div class="form-group">
          <div class="row_setp_main">
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.owned_space') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.ownedSpace"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @change="calcSelfCultivatedAreas()"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('owned_space')">
                  {{ form.errors().get('owned_space') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.minus_leased_areas') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.minusLeasedAreas"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @change="calcSelfCultivatedAreas()"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('minus_leased_areas')">
                  {{ form.errors().get('minus_leased_areas') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.plus_leased_areas') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.plusLeasedAreas"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @change="calcSelfCultivatedAreas()"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('plus_leased_areas')">
                  {{ form.errors().get('plus_leased_areas') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.self_caltivated_areas') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.selfCultivatedAreas"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('self_caltivated_areas')">
                  {{ form.errors().get('self_caltivated_areas') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <h2 class="subheading_title">
                {{ $t('newProperty.propertyLivestock.subheading_title_1') }}
              </h2>
              <hr class="subheading_devide" />
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.alpacas') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.alpacas"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('alpacas')">
                  {{ form.errors().get('alpacas') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.dam_animal_below_1_year') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.damAnimal_below1Year"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('dam_animal_below_1_year')">
                  {{ form.errors().get('dam_animal_below_1_year') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.dam_animal_above_1_year') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.damAnimal_above1Year"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('dam_animal_above_1_year')">
                  {{ form.errors().get('dam_animal_above_1_year') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{
                  $t('newProperty.propertyLivestock.laying_hens_include_breeding')
                }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.layingHens_layingHensIncludeBreeding"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('laying_hens_include_breeding')">
                  {{ form.errors().get('laying_hens_include_breeding') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.purchased_laying_hens') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.layingHens_purchasedLayingHens"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('purchased_laying_hens')">
                  {{ form.errors().get('purchased_laying_hens') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.poultry') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.poultry"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('poultry')">
                  {{ form.errors().get('poultry') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.breeding_rabbits') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.breedingRabbits"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('breeding_rabbits')">
                  {{ form.errors().get('breeding_rabbits') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.llamas') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.llamas"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('llamas')">
                  {{ form.errors().get('llamas') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{
                  $t('newProperty.propertyLivestock.below_3_years_or_small_horses')
                }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.horses_below3YearsOrSmallHorses"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('below_3_years_or_small_horses')">
                  {{ form.errors().get('below_3_years_or_small_horses') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.horses_horses') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.horses_horses"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('horses')">
                  {{ form.errors().get('horses') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.beef_below_1_year') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.beef_below1Year"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('beef_below_1_year')">
                  {{ form.errors().get('beef_below_1_year') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.beef_from_1_to_2_year') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.beef_1to2Year"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('beef_from_1_to_2_yeare')">
                  {{ form.errors().get('beef_from_1_to_2_yeare') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.beef_above_2_year') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.beef_above2Year"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('beef_above_2_year')">
                  {{ form.errors().get('beef_above_2_year') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{
                  $t('newProperty.propertyLivestock.fattening_animals_less_than_1_year')
                }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.fatteningAnimalsLessThan1Year"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('fattening_animals_less_than_1_year')">
                  {{ form.errors().get('fattening_animals_less_than_1_year') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.livestock_cow') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.cows"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('cows')">
                  {{ form.errors().get('cows') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.livestock_breeding_bulls') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.breedingBulls"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('breeding_bulls')">
                  {{ form.errors().get('breeding_bulls') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{
                  $t('newProperty.propertyLivestock.livestock_sheep_below_1_year')
                }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.sheep_below1Year"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('sheep_below_1_year')">
                  {{ form.errors().get('sheep_below_1_year') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{
                  $t('newProperty.propertyLivestock.livestock_sheep_above_1_year')
                }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.sheep_above1Year"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('sheep_above_1_year')">
                  {{ form.errors().get('sheep_above_1_year') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.livestock_breeding_pigs') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.breedingPigs"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('breeding_pigs')">
                  {{ form.errors().get('breeding_pigs') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.ostriches_above_14_months') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.ostriches_above14Months"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('ostriches_above_14_months')">
                  {{ form.errors().get('ostriches_above_14_months') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.ostriches_below_14_months') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.ostriches_below14Months"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('ostriches_below_14_months')">
                  {{ form.errors().get('ostriches_below_14_months') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.livestock_goats') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.goats"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('goats')">
                  {{ form.errors().get('goats') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <h2 class="subheading_title">
                {{ $t('newProperty.propertyLivestock.livestock_subheading') }}
              </h2>
              <hr class="subheading_devide" />
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.below_6_passes') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.youngChickensForFattening_below6Passes"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('young_chickens_for_fattening_below_6_passes')">
                  {{ form.errors().get('young_chickens_for_fattening_below_6_passes') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.above_6_passes') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.youngChickensForFattening_above6Passes"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('young_chickens_for_fattening_above_6_passes')">
                  {{ form.errors().get('young_chickens_for_fattening_above_6_passes') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.livestock_pullets') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.pullets"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('pullets')">
                  {{ form.errors().get('pullets') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.fattening_ducks_count') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.fatteningDucks_count"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('fattening_ducks_count')">
                  {{ form.errors().get('fattening_ducks_count') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{
                  $t('newProperty.propertyLivestock.fattening_ducks_raising_phase')
                }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.fatteningDucks_raisingPhase"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('fattening_ducks_raising_phase')">
                  {{ form.errors().get('fattening_ducks_raising_phase') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{
                  $t('newProperty.propertyLivestock.fattening_ducks_fattening_phase')
                }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.fatteningDucks_fatteningPhase"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('fattening_ducks_fattening_phase')">
                  {{ form.errors().get('fattening_ducks_fattening_phase') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.turkeys_self_raised') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.turkeys_selfRaised"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('turkeys_self_raised')">
                  {{ form.errors().get('turkeys_self_raised') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.turkeys_bought') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.turkeys_bought"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('turkeys_bought')">
                  {{ form.errors().get('turkeys_bought') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.turkeys_young_turkeys') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.turkeys_youngTurkeys"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('turkeys_young_turkeys')">
                  {{ form.errors().get('turkeys_young_turkeys') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.livestock_fattened_geese') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.fattenedGeese"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('fattened_geese')">
                  {{ form.errors().get('fattened_geese') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.livestock_mast_rabbit') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.mastRabbit"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('mast_rabbit')">
                  {{ form.errors().get('mast_rabbit') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.livestock_beef_cattle') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.beefCattle"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('beef_cattle')">
                  {{ form.errors().get('beef_cattle') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.pigs_light_piglets') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.pigs_lightPiglets"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('pigs_light_piglets')">
                  {{ form.errors().get('pigs_light_piglets') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.pigs_piglets') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.pigs_piglets"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('pigs_piglets')">
                  {{ form.errors().get('pigs_piglets') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.pigs_heavy_piglets') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.pigs_heavyPiglets"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('pigs_heavy_piglets')">
                  {{ form.errors().get('pigs_heavy_piglets') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.pigs_runner') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.pigs_runner"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('pigs_runner')">
                  {{ form.errors().get('pigs_runner') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.pigs_heavy_runner') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.pigs_heavyRunner"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('pigs_heavy_runner')">
                  {{ form.errors().get('pigs_heavy_runner') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.pigs_fattening_pigs') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.pigs_fatteningPigs"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('pigs_fattening_pigs')">
                  {{ form.errors().get('pigs_fattening_pigs') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.pigs_young_breeding_pigs') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.pigs_youngBreedingPigs"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('pigs_young_breeding_pigs')">
                  {{ form.errors().get('pigs_young_breeding_pigs') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <h2 class="subheading_title">{{ $t('newProperty.propertyLivestock.purchased_animals') }}</h2>
              <hr class="subheading_devide" />
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.bought_pigs_light_piglets') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.boughtPigs_lightPiglets"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('bought_pigs_light_piglets')">
                  {{ form.errors().get('bought_pigs_light_piglets') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.bought_pigs_piglets') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.boughtPigs_piglets"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('bought_pigs_piglets')">
                  {{ form.errors().get('bought_pigs_piglets') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.bought_pigs_heavy_piglets') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.boughtPigs_heavyPiglets"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('bought_pigs_heavy_piglets')">
                  {{ form.errors().get('bought_pigs_heavy_piglets') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.bought_pigs_runner') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.boughtPigs_runner"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('bought_pigs_runner')">
                  {{ form.errors().get('bought_pigs_runner') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">{{ $t('newProperty.propertyLivestock.bought_pigs_heavy_runner') }}</label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  v-model="form.boughtPigs_heavyRunner"
                  :disabled="isPropertyReadOnly"
                  placeholder=""
                  class="input-field hover-tooltip"
                  @focusin="setInfoTooltip(0)"
                />
                <span v-if="form.errors().has('bought_pigs_heavy_runner')">
                  {{ form.errors().get('bought_pigs_heavy_runner') }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="text_left step-button-section"
          @mouseenter="setInfoTooltip(0)"
        >
          <div>
            <button
              type="button"
              @click.prevent="cancel"
              class="step-button-style border-button-style hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.abort') }}
            </button>
            <button
              type="button"
              @click.prevent="save"
              class="step-button-style border-button-style hover-tooltip"
              v-show="!isPropertyReadOnly"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.save') }}
            </button>
            <button
              type="button"
              v-show="back_button_activated"
              @click.prevent="callForPrecedingStep"
              class="step-button-style border-button-style"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.back') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              class="step-button-style hover-tooltip"
              :style="[primaryColor ? { background: primaryColor } : '']"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              class="step-button-enter hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue_enter') }}
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </section>
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import ShowHideTooltip from '@/mixins/ShowHideTooltip'
import { mapGetters, mapActions } from 'vuex'
import InfoText from '@/components/helper/InfoText'
import InfoTextHeading from '@/components/helper/InfoTextHeading'

export default {
  name: 'PropertyLivestock',
  components: { InfoText, InfoTextHeading },
  mixins: [ShowHideTooltip],
  emits: ['callForNextStep', 'callForPrecedingStep', 'callForSaveProperty', 'cancelPropVisible'],
  data() {
    return {
      primaryColor: '',
      fontColor: '',
      form: form({
        ownedSpace: '', //Eigentumsflächen
        minusLeasedAreas: '', //Abzüglich verpachteter Flächen
        plusLeasedAreas: '', //Zuzüglich zugepachteter Flächen
        selfCultivatedAreas: '', //Selbstbewirtschaftete Flächen
        alpacas: '', //Alpakas
        damAnimal_below1Year: '', //Dammtiere unter 1 Jahr
        damAnimal_above1Year: '', //Dammtiere 1 Jahr und älter
        layingHens_layingHensIncludeBreeding: '', //Legehennen (einschließlich einer normalen Aufzucht zur Ergänzung des Bestandes)
        layingHens_purchasedLayingHens: '', //Legehennen aus zugekauften Junghennen
        poultry: '', //Zuchtputen, -enten, -gänse
        breedingRabbits: '', //Zucht- und Angorakaninchen
        llamas: '', //Lamas
        horses_below3YearsOrSmallHorses: '', //Pferde unter 3 Jahren und Kleinpferde
        horses_horses: '', //Pferde 3 Jahre und älter
        beef_below1Year: '', //Kälber und Jungvieh unter 1 Jahr (einschließlich Mastkälber, Starterkälber und Fresser)
        beef_1to2Year: '', //Jungvieh 1 bis 2 Jahre alt
        beef_above2Year: '', //Färsen (älter als 2 Jahre)
        fatteningAnimalsLessThan1Year: '', //Masttiere (Mastdauer weniger als 1 Jahr)
        cows: '', //Kühe (einschließlich Mutter- und Ammenkühe mit den dazugehörigen Saugkälbern)
        breedingBulls: '', //Zuchtbullen, Zugochsen
        sheep_below1Year: '', //Schafe unter 1 Jahr (einschließlich Mastlämmer)
        sheep_above1Year: '', //Schafe 1 Jahr und älter
        breedingPigs: '', //Zuchtschweine (einschließlich Jungzuchtschweine über etwa 90 kg)
        ostriches_above14Months: '', //Strauße (Zuchttiere 14 Monate und älter)
        ostriches_below14Months: '', //Strauße (Jungtiere/Masttiere unter 14 Monate)
        youngChickensForFattening_below6Passes: '', //Jungmasthühner (bis zu 6 Durchgänge je Jahr - schwere Tiere)
        youngChickensForFattening_above6Passes: '', //Jungmasthühner (mehr als 6 Durchgänge je Jahr - leichte Tiere)
        pullets: '', //Junghennen
        fatteningDucks_count: '', //Mastenten
        fatteningDucks_raisingPhase: '', //Mastenten in der Aufzuchtphase
        fatteningDucks_fatteningPhase: '', //Mastenten in der Mastphase
        turkeys_selfRaised: '', //Mastputen aus selbst erzeugten Jungputen
        turkeys_bought: '', //Mastputen aus zugekauften Jungputen
        turkeys_youngTurkeys: '', //Jungputen (bis etwa 8 Wochen)
        fattenedGeese: '', //Mastgänse
        mastRabbit: '', //Mastkaninchen
        beefCattle: '', //Rindvieh - Masttiere (Mastdauer 1 Jahr und mehr)
        pigs_lightPiglets: '', //Leichte Ferkel (bis etwa 12 kg)
        pigs_piglets: '', //Ferkel (über etwa 12 bis etwa 20 kg)
        pigs_heavyPiglets: '', //Schwere Ferkel und leichte Läufer (über etwa 20 bis etwa 30 kg)
        pigs_runner: '', //Läufer (über etwa 30 bis etwa 45 kg)
        pigs_heavyRunner: '', //Schwere Läufer (über etwa 45 bis etwa 60 kg)
        pigs_fatteningPigs: '', //Mastschweine
        pigs_youngBreedingPigs: '', //Jungzuchtschweine bis etwa 90 kg
        goats: '', //Ziegen
        boughtPigs_lightPiglets: '', //Leichte Ferkel (bis etwa 12 kg)
        boughtPigs_piglets: '', //Ferkel (über etwa 12 bis etwa 20 kg)
        boughtPigs_heavyPiglets: '', //Schwere Ferkel und leichte Läufer (über etwa 20 bis etwa 30 kg)
        boughtPigs_runner: '', //Läufer (über etwa 30 bis etwa 45 kg)
        boughtPigs_heavyRunner: '', //Schwere Läufer (über etwa 45 bis etwa 60 kg)
      })
        .rules({})
        .messages({}),
      back_button_activated: false,
    }
  },
  mounted() {
    this.initForm()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : ''
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.primaryColor : ''
    this.showHide()
    this.invokeReference('owned_space')
  },
  beforeUnmount() {
    this.saveForm()
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getFormPropertyLivestock', 'isPropertyReadOnly']),

    isNewProperty() {
      return this.$parent.isNewProperty
    },

    isShowNextStepButton() {
      let isShow = true
      if (this.$parent.step === this.$parent.maxStep && this.isPropertyReadOnly) {
        isShow = false
      }
      return isShow
    },
  },
  watch: {
    getFormPropertyLivestock() {
      this.initForm()
    },
  },
  methods: {
    ...mapActions(['resetFormPropertyLivestock', 'changeInfoTooltip']),

    setInfoTooltip(val) {
      this.changeInfoTooltip(val)
    },

    calcSelfCultivatedAreas() {
      let intOwnedSpace = parseInt(this.form.ownedSpace)
      let intMinusLeasedAreas = parseInt(this.form.minusLeasedAreas)
      let intPlusLeasedAreas = parseInt(this.form.plusLeasedAreas)
      let calculation = 0
      if (!isNaN(intOwnedSpace)) {
        calculation = calculation + intOwnedSpace
      }
      if (!isNaN(intMinusLeasedAreas)) {
        calculation = calculation - intMinusLeasedAreas
      }
      if (!isNaN(intPlusLeasedAreas)) {
        calculation = calculation + intPlusLeasedAreas
      }
      if (
        calculation < 0 ||
        (this.form.ownedSpace === '' && this.form.minusLeasedAreas === '' && this.form.plusLeasedAreas === '')
      ) {
        this.form.selfCultivatedAreas = ''
      } else {
        this.form.selfCultivatedAreas = calculation
      }
    },

    initForm() {
      for (const [key, value] of Object.entries(this.$store.state.Properties.formPropertyLivestock)) {
        if (typeof value !== 'undefined') {
          this.form[key] = JSON.parse(JSON.stringify(value))
        }
      }
    },

    saveForm() {
      let myKeys = []
      this.resetFormPropertyLivestock()
      myKeys = Object.keys(this.$store.state.Properties.formPropertyLivestock)
      if (typeof myKeys === 'object') {
        myKeys.forEach(function (key) {
          if (typeof this.form[key] !== 'undefined') {
            this.$store.state.Properties.formPropertyLivestock[key] = JSON.parse(JSON.stringify(this.form[key]))
          }
        }, this)
      }
    },

    cancel() {
      this.$emit('cancelPropVisible')
    },

    callForPrecedingStep() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForPrecedingStep')
      } else {
        this.$emit('callForPrecedingStep')
      }
    },

    callForNextStep() {
      if (this.isPropertyReadOnly) {
        this.$emit('callForNextStep')
      } else {
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForNextStep')
      }
    },

    save() {
      if (this.isPropertyReadOnly) {
        return
      }

      if (this.form.validate().errors().any()) {
        this.$store.dispatch('showToast', {
          message: this.$t('newProperty.form_validation_text'),
          toastType: 'error',
        })
        return
      }
      this.saveForm()
      this.$emit('callForSaveProperty')
    },

    invokeReference(ref) {
      this.$nextTick(() => {
        if (!this.$refs[ref]) return
        this.$refs[ref].focus()
      })
    },
    onlyNumber($event) {
      let code = $event.code ? $event.code : $event.which
      let key = $event.key
      if (code === 'KeyE' || key === 'e' || key === 'E') {
        $event.preventDefault()
      }
    },
  },
}
</script>

<style type="text/css">
textarea.textarea-field {
  border: 1px solid #bbbbbb;
  height: 150px;
  width: 100%;
}

.Step_SectionPropertyLivestock .tooltip-icon img {
  height: 18px;
  margin-left: 5px;
}

label.label-title.tooltip-icon {
  position: relative;
}

.ToolTipTextBox {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: none;
  max-width: 600px;
  padding: 20px;
  padding: 15px !important;
  position: absolute;
  right: 0;
  top: 35px;
  transform: translate3d(0px, 40px, 0px) !important;
  z-index: 1;
}

/*.Step_SectionPropertyLivestock .tooltip-icon img:hover + .ToolTipTextBox {
    display: block;
}*/
</style>
