<template>
  <div class="info-text-heading">
    <div :style="{ color: finalTextColor }">
      {{ headingText }}
    </div>
    <InfoText
      v-if="infoText !== ''"
      class="info-text-width default-font-weight"
      :text="infoText"
      :iconColor="iconColor"
      :textColor="textColor"
      :backgroundColor="backgroundColor"
    >
    </InfoText>
  </div>
</template>

<script type="text/javascript">
import InfoText from '@/components/helper/InfoText.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'InfoTextHeading',
  components: { InfoText },
  props: {
    headingText: {
      type: String,
      default: '',
    },
    infoText: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '#f2f2f2',
    },
  },

  data() {
    return {
      fontColor: '',
    }
  },

  created() {
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.fontColor : '#333333'
  },

  computed: {
    ...mapGetters(['getCurrentUser']),

    finalTextColor() {
      let myColor = this.textColor
      if (myColor === '') {
        myColor = this.fontColor
      }
      return myColor
    },
  },
}
</script>

<style scoped>
.info-text-heading {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 30px;
  font-weight: 700;
  justify-content: space-between;
  margin-bottom: 20px;
}
.info-text-width {
  max-width: 395px;
  min-width: 395px;
}
.default-font-weight {
  font-weight: var(--bs-body-font-weight);
}

@media only screen and (max-width: 1200px) {
  .info-text-content {
    margin-top: 20px;
  }

  .info-text-heading {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 30px;
    font-weight: 700;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 83.33333333%;
  }

  .info-text-width {
    max-width: unset;
    min-width: unset;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .info-text-heading {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 30px;
    font-weight: 700;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
  }
}
</style>
