export const defaultPropertyNonResidential = {
  nonResidentials: [
    {
      id: 0,
      locationPlanNumber: '', //(Lageplan-)Nummer
      buildingType: '0', //Gebäudeart
      yearOfConstruction: '', //Baujahr
      coreRenovationYear: '', //Es erfolgte eine Kernsanierung, die abgeschlossen wurde im Jahr
      demolitionObligationYear: '', //Es besteht eine Abbruchverpflichtung im Jahr
      areaOfTheLand: '', //Bruttogrundfläche in m²
      areaOfTheLandCivilDefense: '', //Davon für den Zivilschutz in m²
      exemptions: [
        {
          //Befreiungen
          id: 0,
          locationOfTheRoomsDesignation: '', //Lage der Räume / Bezeichnung
          exemption: '0', //Befreiung
          taxFreeArea: '', //Steuerfreie Fläche in m²
        },
      ],
      benefits: [
        {
          //Vergünstigungen
          id: 0,
          locationOfTheRoomsDesignation: '', //Lage der Räume / Bezeichnung
          benefit: '0', //Vergünstigung
          discountedArea: '', //Vergünstigte Fläche in m²
        },
      ],
    },
  ],
}
