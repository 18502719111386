<template>
  <div class="login_start without-header">
    <LoginNoPassword />
  </div>
  <Copyright />
</template>

<script>
// @ is an alias to /src
import LoginNoPassword from '@/components/LoginNoPassword.vue'
import Copyright from '@/components/copyright.vue'

export default {
  name: 'Home',
  components: {
    LoginNoPassword,
    Copyright,
  },
}
</script>
