export const defaultPropertyResidential = {
  residentials: [
    {
      id: 0,
      isSectionGarage: '0',
      isSectionBelow60: '0',
      isSectionBetween60100: '0',
      isSectionAbove100: '0',
      isSectionOther: '0',
      isSectionUsableSpaces: '0',
      residential: {
        yearOfConstruction: '',
        coreRenovationYear: '',
        demolitionObligationYear: '',
      },
      garageUnderground: {
        //Garagen- und Tiefgaragenstellplätze
        id: 0,
        amountGarages: '',
        garageUndergroundExemption: [
          {
            id: 0,
            locationOfTheRoomsDesignation: '',
            exemption: '0',
            taxFreeArea: '',
          },
        ],
        garageUndergroundBenefit: [
          {
            id: 0,
            locationOfTheRoomsDesignation: '',
            benefit: '0',
            discountedArea: '',
          },
        ],
      },
      apartmentsBelow60: {
        //Wohnungen unter 60m
        id: 0,
        counter: '',
        totalLivingSpace: '',
        apartmentsBelow60Exemption: [
          {
            id: 0,
            locationOfTheRoomsDesignation: '',
            exemption: '0',
            taxFreeArea: '',
          },
        ],
        apartmentsBelow60Benefit: [
          {
            id: 0,
            locationOfTheRoomsDesignation: '',
            benefit: '0',
            discountedArea: '',
          },
        ],
      },
      apartmentsBetween60100: {
        //Wohnungen mit einer Wohnfläche von 60 m² bis unter 100 m²
        id: 0,
        counter: '',
        totalLivingSpace: '',
        apartmentsBetween60100Exemption: [
          {
            id: 0,
            locationOfTheRoomsDesignation: '',
            exemption: '0',
            taxFreeArea: '',
          },
        ],
        apartmentsBetween60100Benefit: [
          {
            id: 0,
            locationOfTheRoomsDesignation: '',
            benefit: '0',
            discountedArea: '',
          },
        ],
      },
      apartmentsAbove100: {
        //Wohnungen mit einer Wohnfläche von 100 m² und mehr
        id: 0,
        counter: '',
        totalLivingSpace: '',
        apartmentsAbove100Exemption: [
          {
            id: 0,
            locationOfTheRoomsDesignation: '',
            exemption: '0',
            taxFreeArea: '',
          },
        ],
        apartmentsAbove100Benefit: [
          {
            id: 0,
            locationOfTheRoomsDesignation: '',
            benefit: '0',
            discountedArea: '',
          },
        ],
      },
      apartmentsOther: {
        //Weitere Wohnungen
        id: 0,
        counter: '',
        totalLivingSpace: '',
        apartmentsOtherExemption: [
          {
            id: 0,
            locationOfTheRoomsDesignation: '',
            exemption: '0',
            taxFreeArea: '',
          },
        ],
        apartmentsOtherBenefit: [
          {
            id: 0,
            locationOfTheRoomsDesignation: '',
            benefit: '0',
            discountedArea: '',
          },
        ],
      },
      usableSpaces: [
        {
          //Weitere Nutzflächen die keine Wohnflächen sind (ohne Zubehörräume wie zum Beispiel Keller, Waschküche)
          id: 0,
          usageType: '',
          totalSpace: '',
          locationOfTheRoomsDesignation: '',
          usableSpacesExemption: [
            {
              id: 0,
              exemption: '0',
              taxFreeArea: '',
            },
          ],
          usableSpacesBenefit: [
            {
              id: 0,
              benefit: '0',
              discountedArea: '',
            },
          ],
        },
      ],
    },
  ],
}
