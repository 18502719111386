export default {
  "elster": {
    "validationNavTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELSTER-Prüfung"])},
    "splashScreen": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenprüfung"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Daten werden aktuell auf Vollständigkeit und Plausibilität überprüft. Dies kann einen Moment dauern."])},
      "step1Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfung der Daten in Bereich \"%tab%\""])},
      "step2Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück %propertyUid%wird gespeichert."])},
      "step3Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück %propertyUid%wird neu geladen."])},
      "step4Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenprüfung durch ELSTER"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "step1ErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Datenprüfung hat Fehler gefunden. Bitte prüfen Sie unzulässige Formulareingaben unter \"%tab%\"."])},
      "step2ErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Speichern des Grundstücks ist fehlgeschlagen."])},
      "step3ErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Laden des Grundstücks ist fehlgeschlagen."])},
      "step4ErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Datenprüfung durch ELSTER ist fehlgeschlagen. Bitte versuchen Sie es später erneut."])}
    },
    "successScreen": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELSTER-Prüfung"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Daten wurden erfolgreich durch die ELSTER-Schnittstelle validiert und sind gültig für eine Deklaration."])},
      "okButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
    },
    "errorScreen": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELSTER-Prüfung"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten sind nicht vollständig oder ungültig."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachten Sie, dass aktuell die Hinweise und Fehlermeldungen der ELSTER-Schnittstelle noch optimiert und aussagekräftiger gemacht werden."])},
      "errorsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validierungsfehler:"])},
      "hintsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweise:"])},
      "okButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
    }
  },
  "general": {
    "errors": {
      "unknownError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein technischer Fehler. Bitte versuchen Sie es nach einiger Zeit!"])},
      "csrf_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Vorgang ist aufgrund eines ungültigen Tokens fehlgeschlagen. Bitte laden Sie die Seite neu"])},
      "user_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername ungültig oder unbekannt"])},
      "activePropertyDeleteNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein bereits zur Bearbeitung freigegebenes Grundstück kann nicht mehr gelöscht werden."])},
      "sessionExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an."])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflichtfeld"])},
      "invalidCommunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Feld Gemeinde im Tab Grundstücksadresse enthält keinen ELSTER-konformen Wert. Bitte wählen Sie einen Wert aus den Vorschlägen."])},
      "invalidCommunityForParcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens ein Feld Gemeinde im Tab Grundstück enthält keinen ELSTER-konformen Wert. Bitte leeren Sie die Gemeindefelder und wählen dann aus den Vorschlägen, die das System Ihnen anbietet."])},
      "invalidCommunityForAgriculture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens ein Feld Gemeinde im Tab Land- und Forstwirtschaft enthält keinen ELSTER-konformen Wert. Bitte leeren Sie die Gemeindefelder und wählen dann aus den Vorschlägen, die das System Ihnen anbietet."])},
      "elsterValidationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validierungsfehler"])},
      "invalidOwnershipStructureOfDeviatingOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte stellen Sie im Tab Grundstück in Feld Eigentumsverhältnisse die Option Alleineigentum einer natürlichen Person ein, um einen alternativen Eigentümer angeben zu können."])},
      "noAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Zugriff"])},
      "limitDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%length% von %maxLimit% Zeichen verwendet."])}
    },
    "sys_update_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das System ist aktuell nicht verfügbar, da ein Update eingespielt wird. Es wird nur wenige Minuten dauern. Danke für Ihr Verständnis."])},
    "new_version_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist eine neue Version der Anwendung verfügbar."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "reset_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter zurücksetzen"])},
    "salutation": {
      "mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])},
      "mrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])}
    },
    "buttons": {
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "save_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf Speichern"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])}
    },
    "x_files_selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Datei(en) ausgewählt"])},
    "upload_file_limit_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können maximal 25 Dateien auf einmal hochladen"])},
    "fileTooBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. 20MB erlaubt."])},
    "file_type_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ungültiger Dateityp. Erlaubte Typen sind ", _interpolate(_named("allowed_types"))])},
    "uploader": {
      "select_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei auswählen"])},
      "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])},
      "upload_signature_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie die Unterschrift per Drag&Drop hoch"])},
      "signature_instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur JPEG-, PNG- und GIF-Dateien mit einer Mindestgröße von 100 x 100"])},
      "x_docs_uploaded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Dokument(e) hochgeladen"])},
      "x_docs_upload_failed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Dokument(e) konnte(n) nicht hochgeladen werden"])}
    },
    "salutation_with_none": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mr"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mrs"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Anrede"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      }
    ],
    "grid": {
      "page_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigt"])},
      "page_info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
      "page_info3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von "])},
      "page_info4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenten"])},
      "no_records_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Dokumente gefunden"])},
      "page_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("currentPage")), " von ", _interpolate(_named("totalPages"))])}
    },
    "record_observer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datensatz geändert"])},
      "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Datensatz wurde von ", _interpolate(_named("username")), " aktualisiert. Möchten Sie Ihre ggf. gemachten Änderungen verwerfen und den Datensatz neu laden oder möchten Sie diese Änderungen von ", _interpolate(_named("username")), " beim Speichern Ihrer eigenen Änderungen überschreiben?"])},
      "systemInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Datensatz wurde vom System aktualisiert und wird nun neu geladen."])},
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datensatz neu laden"])},
      "overwrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen überschreiben"])}
    }
  },
  "login": {
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandantenportal v"])}
  },
  "login_start": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsteuer - Mandantenportal"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "user_or_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail oder Benutzername"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden mit Hilfe Ihres Kontos bei"])}
  },
  "header": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsteuer - Mandantenportal"])},
    "mydata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Daten"])},
    "useradmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerverwaltung"])},
    "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandant"])}
  },
  "profile": {
    "taxofc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerkanzlei"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])}
  },
  "start_step1": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir benötigen Ihre Freigabe!"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben Ihre Feststellungserklärung vorbereitet und möchten Sie bitten diese zu prüfen. Sofern Sie keine Beanstandungen haben, unterschreiben Sie das Dokument bitte digital. Alternativ können Sie es auch ausdrucken, unterschreiben und postalisch an uns senden. Sofern Sie Fragen haben oder Hilfe benötigen, melden Sie sich gerne bei uns."])},
    "multihouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrfamilienhaus"])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche in m2"])},
    "construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baujahr"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundesland"])},
    "estimated_property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voraussichtliche Grundsteuer"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "timer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauert ca. 5 Min"])}
  },
  "start_step2": {
    "confirm_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiermit bestätige ich das Steuerberaterhonorar in Höhe von 420,- €"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
  },
  "start_step3": {
    "signnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt unterschreiben"])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingeben"])},
    "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichnen"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
    "uploadtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie die Unterschrift per Drag&Drop hoch"])},
    "uploadsubline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur JPEG-, PNG- und GIF-Dateien mit einer Mindestgröße von 750 x 200"])}
  },
  "LoginNopwd": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsteuer - Mandantenportal"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "apiError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir konnten leider keine E-Mail erstellen, damit Sie ein neues Passwort vergeben können. Bitte wenden Sie sich an Ihren Steuerberater."])},
    "apiSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie erhalten eine E-Mail, um ein neues Passwort zu vergeben. Sofern Sie keine E-Mail erhalten, prüfen Sie bitte Ihren SPAM-Ordner. Sollten Sie dennoch keine E-Mail finden, wenden Sie sich bitte an Ihren Steuerberater."])},
    "apiSuccess_otherUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer erhält eine E-Mail, um ein neues Passwort zu vergeben. Sofern er keine E-Mail erhält, sollte er bitte seinen SPAM-Ordner prüfen. Sollte er dennoch keine E-Mail finden, wenden Sie sich bitte an Ihren Steuerberater."])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Benutzerkonto wurde gefunden, hat jedoch noch kein Passwort vergeben. Sie erhalten eine E-Mail, um ein Passwort zu vergeben. Sofern Sie keine E-Mail erhalten, prüfen Sie bitte Ihren SPAM-Ordner. Sollten Sie dennoch keine E-Mail finden, wenden Sie sich bitte an Ihren Steuerberater."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Benutzerkonto wurde gefunden, hat jedoch noch kein Passwort vergeben. Sie erhalten eine E-Mail, um ein Passwort zu vergeben. Sofern Sie keine E-Mail erhalten, prüfen Sie bitte Ihren SPAM-Ordner. Sollten Sie dennoch keine E-Mail finden, wenden Sie sich bitte an Ihren Steuerberater."])}
  },
  "LoginEstpwdset": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsteuer - Mandantenportal"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "pwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "cnfmpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "further": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "pwErrorMinLen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muß mindestens 8 Zeichen lang sein"])},
    "pwErrorConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort und Passwort-Bestätigung müssen identisch sein"])}
  },
  "LoginMobVorhan": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsteuer - Mandantenportal"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können sich per SMS-Code anmelden oder ein Passwort vergeben"])},
    "smscode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS-Code senden"])},
    "pwdset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort setzen"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])}
  },
  "LoginSmscode": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsteuer - Mandantenportal"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr SMS-Code wurde an die Nummer"])},
    "info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["versendet. Bitte geben Sie den Code hier unten ein. Sollten Sie keine SMS erhalten, wenden Sie sich bitte an Ihren Steuerberater, um Ihre Telefonnummer zu prüfen, oder probieren Sie die Anmeldung per Passwort."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "smsagain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS erneut senden"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "further": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
  },
  "LoginNoMobPwdVhan": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsteuer - Mandantenportal"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "pwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "forgotpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "further": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
    "mfa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei-Faktor-Code"])},
    "placeholder_sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code eingeben"])},
    "send_sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code anfordern"])},
    "send_sms_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code erneut senden"])},
    "sms_code_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Code wurde per SMS an die Nummer versendet, die mit %num% endet"])},
    "mfa_code_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Code wurde per E-Mail versendet."])},
    "expires_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch gültig:"])},
    "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M-12345678"])},
    "pwd_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort eingeben"])},
    "msg_name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Benutzernamen ein"])},
    "msg_pwd_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wird benötigt"])}
  },
  "LoginVhanPwdMob": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsteuer - Mandantenportal"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "pwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "smscode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login mit SMS-Code"])},
    "forgotpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "further": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
  },
  "dashboard": {
    "unknownPropertyTypeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück"])},
    "default_property_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück"])},
    "title_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen, "])},
    "title_postfix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
    "subline1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Portal können Sie Ihre Grundstücke für die Feststellungserklärungen verwalten, mit Ihrem Steuerberater zusammenarbeiten und den Status von jedem Grundstück nachvollziehen."])},
    "subline2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie auch"])},
    "subline3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre persönlichen Daten."])},
    "subline_mydata_href_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
    "plots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücke"])},
    "markall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle als gelesen markieren"])},
    "createproperty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbuchauszug"])},
    "requestproofbutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandsnachweis"])},
    "requestproof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandsnachweis anfordern"])},
    "requestprooftext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofern Sie Eigentümer, Erbbau- oder Nutzungsberechtigter von mehreren Grundstücken sind und Sie Informationen zum Gesamtbestand haben möchten, können Sie dies hier bei den Landesämtern pro Bundesland anfordern. Im Gegensatz zu Grundstücksnachweisen und Flurstücksnachweisen werden Ihnen hier alle Grundstücke zusammenfassend dargestellt."])},
    "Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung"])},
    "Attentiontext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachten Sie,dass für die Anfragen pro Bundesland unterschiedliche Amtsgebühren anfallen. Die Gebührenrechnungen werden direkt von den Ämtern an Sie gestellt."])},
    "Attentiontext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Nutzung dieses Services entstehen Ihnen bei uns keine Zusatzkosten."])},
    "states": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundesländer"])},
    "Conclude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "addProp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Grundstück"])},
    "addPropClassic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Grundstück (Classic)"])},
    "addDocProp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück hinzufügen"])},
    "editProp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück bearbeiten"])},
    "duplicateProp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück duplizieren"])},
    "duplicateProp_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie das Grundstück duplizieren? Es werden alle Daten außer Dokumenten übernommen."])},
    "answer_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "answer_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "deleteProp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück löschen"])},
    "prop_serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentrale Grd.-Nr."])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansehen"])},
    "property_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück ansehen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplizieren"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "noAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- keine Adresse angegeben -"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bisher wurden noch keine Grundstücke erfasst."])},
      "subline1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf den unteren Button, um Ihr erstes"])},
      "subline2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück anzulegen."])},
      "button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück hinzufügen"])}
    },
    "info1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liegenschaftsinformationen abrufen"])},
      "headline1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liegenschaftsinformationen digital abrufen"])},
      "block1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevante Liegenschaftsinformationen zum Grundstück können Sie im Folgenden digital abrufen und automatisch in GrundsteuerDigital übernehmen. Dazu gehören die folgenden Datenfelder:"])},
      "block2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Gemarkung</li><li>Gemarkungsnummer</li><li>Flurstücksnummer</li><li>Flurstückszähler</li><li>Flurstücksnenner</li><li>Bodenrichtwert</li></ul>"])},
      "headline2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Funktion steht Ihnen in der Anlage und Bearbeitung von Grundstücken zur Verfügung. Falls Sie diese Funktion nicht finden können, sprechen Sie hierzu gerne Ihre(n) Steuerberater/in für eine entsprechende Berechtigung an."])},
      "block3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hierzu werden Sie anhand der Grundstücksadresse zur Auswahl der zugehörigen Flurstücke eingeladen. Diese können Sie im Anschluss direkt in GrundsteuerDigital übertragen. Sollten Informationen wie der Bodenrichtwert noch nicht verfügbar sein, können Sie diesen zu einem späteren Zeitpunkt ergänzen. Die Informationen werden mit 2,50€ je Flurstück berechnet. Die Abrechnung erfolgt hierbei gegenüber Ihrer Steuerberatungskanzlei, welche Ihnen die entstandenen Kosten voraussichtlich weiter berechnen wird. Sprechen Sie hierzu gerne Ihre(n) Steuerberater/in an."])},
      "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigen Sie weitere Informationen?"])}
    },
    "info2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbuchauszug anfordern"])},
      "block1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Grundbuchauszug ist eine Abschrift/Kopie aus einem bestimmten Grundbuchblatt. Es gibt ihn in einfacher und amtlich beglaubigter Form und ist kostenpflichtig."])},
      "block2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Grundbuchauszug für Ihr Grundstück können Sie über GrundsteuerDigital anfordern. Dieser kommt im Anschluss per Post zu Ihnen nach Hause."])},
      "headline2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlungsempfehlung:"])},
      "block3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch händische Tätigkeiten wie z.B. die manuelle Übernahme der Grundbuchdaten, ist der Aufwand sehr hoch, sodass wir dies aus wirtschaftlicher Sicht nicht empfehlen. Sie können über GrundsteuerDigital eine deutlich kostengünstigere Funktion nutzen, über welche Sie die Liegenschaftsinformationen zu Ihrem Grundstück digital abrufen können. Das betrifft unter Anderem die Flurdaten ohne formalen Auszug zu einem deutlich reduzierten Preis gegenüber dem Grundbuchauszug. Eine Vollmacht ist hierbei nicht erforderlich."])},
      "button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbuchauszug jetzt anfordern"])},
      "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigen Sie weitere Informationen?"])}
    },
    "info3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht Geo-Viewer"])},
      "headline1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geo-Viewer der Bundesländer"])},
      "block1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Unterstützung bei der Erstellung der Feststellungserklärung planen einzelne Bundesländer zeitnah separate Geo-Viewer bereitzustellen. Zum aktuellen Zeitpunkt betrifft es die Folgenden:"])},
      "block2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Niedersachsen</li><li>NRW</li><li>Sachsen</li><li>Sachsen-Anhalt</li><li>Bayern (über BayernAtlas)</li></ul>"])},
      "block3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über diese Plattformen werden Liegenschaftsinformationen kostenfrei abrufbar sein. Diese können anschließend manuell in GrundsteuerDigital übertragen werden."])},
      "button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überblick Geo-Viewer"])}
    },
    "buttons": {
      "button_1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liegenschaftsinformationen abrufen"])},
      "button_2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbuchauszug"])},
      "button_3_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht Geo-Viewer"])}
    },
    "finished_declarations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigte Deklarationen"])},
    "elster_transferred_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übertragen am"])},
    "property_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
    "property_processing_info": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitung nicht möglich"])},
      "processing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Grundstück ist aktuell in der Kanzlei in Bearbeitung und kann daher nicht im Mandantenportal bearbeitet werden. Das Steuerbüro kann die Bearbeitung für Sie erneut freigeben."])},
      "elster_completed_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Grundstück wurde per ELSTER bereits übertragen und kann daher nicht mehr bearbeitet werden. Sofern Sie Korrekturen vornehmen müssen, wenden Sie sich bitte an Ihr Steuerbüro."])},
      "elster_submission_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Grundstück ist für die ELSTER-Übertrag bereit und kann daher nicht mehr bearbeitet werden. Sofern Sie Korrekturen vornehmen müssen, wenden Sie sich bitte dringend an Ihr Steuerbüro."])},
      "internal_approval_requested_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Grundstück ist aktuell in der Kanzlei in Prüfung und kann daher nicht im Mandantenportal bearbeitet werden. Das Steuerbüro kann die Bearbeitung für Sie erneut freigeben."])}
    }
  },
  "data_provisioning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenbeschaffung"])},
    "button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liegenschaftsinformationen abrufen"])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(folgt in Kürze)"])},
    "modal_required_abo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion nicht verfügbar"])},
    "modal_required_abo_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Funktion ist nur in Accounts mit aktivem Paketplan verfügbar, bitten wenden Sie sich an Ihre/n Steuerberater/in um diese Funktionalität freizuschalten."])},
    "modal_required_abo_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
  },
  "mydata": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Daten"])},
    "userTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
    "subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofern persönliche Daten von Ihnen nicht korrekt sind, melden Sie dies bitte Ihrem Steuerbüro."])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis"])},
    "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel/akademischer Grad"])},
    "fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "lname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "vatId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer"])},
    "clientNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
    "houseno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer"])},
    "pobox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postfach"])},
    "plz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
    "ort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
    "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "pwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "npwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
    "ncnfmpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort bestätigen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe erforderlich"])},
    "pwErrorMinLen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muß mindestens 8 Zeichen lang sein"])},
    "pwErrorConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort und Passwort-Bestätigung müssen identisch sein"])}
  },
  "Invitation": {
    "heading_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort setzen"])},
    "title_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen, "])},
    "title_postfix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
    "sub_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Portal können Sie Ihre Grundstücke für die Feststellungserklärungen verwalten, mit Ihrem Steuerberater zusammenarbeiten und den Status von jedem Grundstück nachvollziehen."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "pwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "cnfmpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "further": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe erforderlich"])},
    "pwErrorMinLen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muß mindestens 8 Zeichen lang sein"])},
    "pwErrorConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort und Passwort-Bestätigung müssen identisch sein"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um sich erfolgreich anmelden zu können, fordern Sie bitte nachfolgend einen Code an, welcher Ihnen per E-Mail oder SMS zugeht. Durch diese 2-Faktor-Authentisierung sichern wir Ihre Daten zusätzlich ab."])}
  },
  "InvitationTC": {
    "heading_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort setzen"])},
    "title_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen, "])},
    "title_postfix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
    "sub_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Portal können Sie Ihre Grundstücke für die Feststellungserklärungen verwalten, mit Ihrem Steuerberater zusammenarbeiten und den Status von jedem Grundstück nachvollziehen."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "pwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "cnfmpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "further": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe erforderlich"])},
    "pwErrorMinLen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muß mindestens 8 Zeichen lang sein"])},
    "pwErrorConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort und Passwort-Bestätigung müssen identisch sein"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um sich erfolgreich anmelden zu können, fordern Sie bitte nachfolgend einen Code an, welcher Ihnen per E-Mail oder SMS zugeht. Durch diese 2-Faktor-Authentisierung sichern wir Ihre Daten zusätzlich ab."])},
    "infoAlreadySent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der erforderliche Code für die 2-Faktor-Authentisierung wurde Ihnen bereits in einer separaten E-Mail oder SMS zugesendet. \n\nDurch diese 2-Faktor-Authentisierung sichern wir Ihre Daten zusätzlich ab."])}
  },
  "admin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerverwaltung"])},
    "menu1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer hinzufügen"])},
    "menu2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer bearbeiten"])},
    "menu3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer löschen"])},
    "col1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "col2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "col3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "col4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer"])},
    "modal_title_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer bearbeiten"])},
    "modal_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer hinzufügen"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer"])},
    "2faSMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei-Faktor-Authentisierung per SMS aktivieren"])},
    "ansehen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücke ansehen"])},
    "anlegen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücke anlegen"])},
    "bearbeiten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücke bearbeiten"])},
    "freigeben": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feststellungserklärungen freigeben"])},
    "Benutzerverwaltung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerverwaltung"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "menu_set_pwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort neu setzen"])},
    "messages": {
      "salutation_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie bitte eine Anrede aus."])},
      "first_name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname darf nicht leer sein"])},
      "last_name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname darf nicht leer sein"])},
      "email_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail darf nicht leer sein"])},
      "email_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Eingabe muss eine gültige E-Mail-Adresse sein"])},
      "mobile_dynamic_mobile_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer darf nicht leer sein."])}
    },
    "salutation_with_none": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mr"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mrs"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Anrede"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      }
    ],
    "addUser_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer wurde erfolgreich hinzugefügt.\nDem neuen Benutzer wurde eine Einladungs-E-Mail mit Aktivierungslink zugesandt. (Bitte ggf. SPAM-Ordner überprüfen!)"])},
    "editUser_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdaten wurden erfolgreich gespeichert."])},
    "deleteUser_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer erfolgreich gelöscht."])}
  },
  "change_status_options": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen angefragt"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen erhalten"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen eingetragen"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Änderungen"])}
  },
  "property_type": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unbebautes Grundstück"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfamilienhaus"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifamilienhaus"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietwohngrundstück"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentum"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teileigentum"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsgrundstück"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gemischt genutztes Grundstück"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sonstiges bebautes Grundstück"])}
  },
  "property_type_options": {
    "Art_des_grundstucks": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfamilienhaus"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einfamilienhaus.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifamilienhaus"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doppelhaushälfte.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietwohngrundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eigentumswohnung.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentum"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehrfamilienhaus.png"])}
      }
    ],
    "Bundesmodell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbebautes Grundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfamilienhaus"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einfamilienhaus.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifamilienhaus"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doppelhaushälfte.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietwohngrundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eigentumswohnung.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentum"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehrfamilienhaus.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teileigentum"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wohnung.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsgrundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produktionsstandort.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemischt genutztes Grundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges bebautes Grundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      }
    ],
    "Modifiziertes Bodenwertmodell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbebautes Grundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfamilienhaus"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einfamilienhaus.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifamilienhaus"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doppelhaushälfte.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietwohngrundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eigentumswohnung.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentum"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehrfamilienhaus.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teileigentum"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wohnung.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsgrundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produktionsstandort.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemischt genutztes Grundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges bebautes Grundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      }
    ],
    "Flächen-Modell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfamilienhaus"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einfamilienhaus.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifamilienhaus"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doppelhaushälfte.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietwohngrundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eigentumswohnung.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentum"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehrfamilienhaus.png"])}
      }
    ],
    "Wohnlagen-Modell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbebautes Grundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfamilienhaus"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einfamilienhaus.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifamilienhaus"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doppelhaushälfte.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietwohngrundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eigentumswohnung.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentum"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehrfamilienhaus.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teileigentum"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wohnung.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsgrundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produktionsstandort.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemischt genutztes Grundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges bebautes Grundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      }
    ],
    "Flächen-Faktor-Modell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbebautes Grundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfamilienhaus"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einfamilienhaus.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifamilienhaus"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doppelhaushälfte.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietwohngrundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eigentumswohnung.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentum"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehrfamilienhaus.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teileigentum"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wohnung.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsgrundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produktionsstandort.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemischt genutztes Grundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges bebautes Grundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      }
    ],
    "Flächen-Lage-Modell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfamilienhaus"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einfamilienhaus.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifamilienhaus"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doppelhaushälfte.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietwohngrundstück"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eigentumswohnung.png"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentum"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehrfamilienhaus.png"])}
      }
    ]
  },
  "economic_entity_types": {
    "default": [
      {
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbebautes Grundstück"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bebautes Grundstück"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einfamilienhaus.png"])}
      },
      {
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrieb der Land- und Forstwirtschaft"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      }
    ],
    "Baden-Württemberg": [
      {
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück (wirtschaftliche Einheit des Grundvermögens)"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      },
      {
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrieb der Land- und Forstwirtschaft"])},
        "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
      }
    ]
  },
  "property_type_search": {
    "unbebautes Grundstück": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
    "Einfamilienhaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
    "Zweifamilienhaus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
    "Mietwohngrundstück": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
    "Wohnungseigentum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])},
    "Teileigentum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])},
    "Geschäftsgrundstück": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])},
    "gemischt genutztes Grundstück": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
    "sonstiges bebautes Grundstück": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
  },
  "property_status": {
    "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Daten überprüfen"])},
    "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf, für Steuerbüro nicht sichtbar. (Jetzt freigeben)"])},
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bearbeitung beim Steuerbüro"])},
    "data_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Daten überprüfen"])},
    "internal_approval_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Prüfung beim Steuerbüro"])},
    "approval_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Freigabe ist erforderlich"])},
    "email_approval_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigaben per E-Mail angefordert"])},
    "elster_submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten bereit für ELSTER-Übermittlung durch Steuerbüro"])},
    "elster_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An das Finanzamt übermittelt, Wartet auf Bescheid"])},
    "assessment_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bescheid erhalten, in Prüfung"])},
    "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einspruch zum Bescheid"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])}
  },
  "property_status_filter": {
    "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf, für Steuerbüro nicht sichtbar"])}
  },
  "handle_property_status": {
    "confirm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe"])},
    "confirm_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie das Grundstück jetzt für das Steuerbüro zur Bearbeitung freigeben?"])},
    "answer_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "answer_release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "label_client_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Nachricht"])}
  },
  "federal_states": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baden-Württemberg"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bayern"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berlin"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brandenburg"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bremen"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamburg"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hessen"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mecklenburg-Vorpommern"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedersachsen"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nordrhein-Westfalen"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rheinland-Pfalz"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saarland"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachsen-Anhalt"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachsen"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schleswig-Holstein"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thüringen"])}
  },
  "federal_states_search": {
    "Baden-Württemberg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
    "Bayern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
    "Berlin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
    "Brandenburg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
    "Bremen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])},
    "Hamburg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])},
    "Hessen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])},
    "Mecklenburg-Vorpommern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
    "Niedersachsen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])},
    "Nordrhein-Westfalen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])},
    "Rheinland-Pfalz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])},
    "Saarland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])},
    "Sachsen-Anhalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])},
    "Sachsen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])},
    "Schleswig-Holstein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])},
    "Thüringen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])}
  },
  "ownership_salutation_options": {
    "Bundesmodell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Anrede"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbengemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsgemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksgemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft bürgerlichen Rechts"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentümergemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
      }
    ],
    "Modifiziertes Bodenwertmodell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Anrede"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbengemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsgemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksgemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft bürgerlichen Rechts"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentümergemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
      }
    ],
    "Flächen-Modell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Anrede"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbengemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsgemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksgemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft bürgerlichen Rechts"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentümergemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
      }
    ],
    "Wohnlagen-Modell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Anrede"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbengemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsgemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksgemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft bürgerlichen Rechts"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentümergemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
      }
    ],
    "Flächen-Faktor-Modell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Anrede"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbengemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsgemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksgemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft bürgerlichen Rechts"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentümergemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
      }
    ],
    "Flächen-Lage-Modell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Anrede"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbengemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsgemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksgemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft bürgerlichen Rechts"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentümergemeinschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
      }
    ]
  },
  "ownership_structure": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleineigentum einer natürlichen Person"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleineigentum einer Körperschaft des öffentlichen Rechts"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleineigentum einer unternehmerisch tätigen juristischen Person"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleineigentum einer nicht unternehmerisch tätigen juristischen Person"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehegatten/Lebenspartner"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbengemeinschaft"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruchteilsgemeinschaft"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksgemeinschaft ausschließlich von natürlichen Personen"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksgemeinschaft ausschließlich von juristischen Personen"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Grundstücksgemeinschaft"])}
  },
  "usage_type_options": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für öffentlichen Dienst/Gebrauch (jPdöR)"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundeseisenbahnvermögen"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige/mildtätige Zwecke"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religiöse Zwecke"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstwohnung Geistliche"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religionsgem. Besoldung"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gottesdienst"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestattungsplatz"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentlicher Verkehr"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flugbetrieb"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fließende Gewässer"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasser-/Bodenverbände (öR)"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wissenschaft/Unterricht/Erziehung"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankenhaus"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplomatische Vertretung"])}
  },
  "exemption_options": {
    "-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigengewerbliche Nutzung"])},
    "-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremdgewerbliche Nutzung"])},
    "-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigene Wohnzwecke"])},
    "-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremde Wohnzwecke"])},
    "-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremde öffentliche Zwecke"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für öffentlichen Dienst/Gebrauch (jPdöR)"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundeseisenbahnvermögen"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige/mildtätige Zwecke"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religiöse Zwecke"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstwohnung Geistliche"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religionsgem. Besoldung"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gottesdienst"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestattungsplatz"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentlicher Verkehr"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flugbetrieb"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fließende Gewässer"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasser-/Bodenverbände (öR)"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wissenschaft/Unterricht/Erziehung"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankenhaus"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplomatische Vertretung"])}
  },
  "exemption": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für öffentlichen Dienst/Gebrauch (jPdöR)"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundeseisenbahnvermögen"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige/mildtätige Zwecke"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religiöse Zwecke"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstwohnung Geistliche"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religionsgem. Besoldung"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gottesdienst"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestattungsplatz"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentlicher Verkehr"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flugbetrieb"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fließende Gewässer"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasser-/Bodenverbände (öR)"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wissenschaft/Unterricht/Erziehung"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankenhaus"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplomatische Vertretung"])}
  },
  "land_and_forestry_usage_type": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landwirtschaftliche Nutzung"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forstwirtschaftliche Nutzung"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewirtschaftungsbeschränkung Forstwirtschaft"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weinbauliche Nutzung"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemüsebau – Freiland"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemüsebau – unter Glas und Kunststoffen"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blumen- und Zierpflanzenbau – Freiland"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blumen- und Zierpflanzenbau – unter Glas und Kunststoffen"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obstbau - Freiland"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obstbau - unter Glas und Kunststoffen"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baumschulen - Freiland"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baumschulen - unter Glas und Kunststoffen"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleingarten- und Dauerkleingartenland"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gartenlaube größer 30 m²"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hopfen"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spargel"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasserflächen ohne oder mit geringer Nutzung (Fischertrag kleiner 1 kg/Ar)"])},
    "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasserflächen bei stehenden Gewässern mit Fischertrag zwischen 1 kg/Ar und 4 kg/Ar"])},
    "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasserflächen bei stehenden Gewässern mit Fischertrag größer 4 kg/Ar"])},
    "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasserflächen bei fließenden Gewässern mit Fischertrag"])},
    "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saatzucht"])},
    "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weihnachtsbaumkulturen"])},
    "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurzumtriebsplantagen"])},
    "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbauland"])},
    "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geringstland"])},
    "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unland"])},
    "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windenergie"])},
    "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hofstelle"])},
    "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftsgebäude der Fass- und Flaschenweinerzeugung"])},
    "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftsgebäude der Imkerei"])},
    "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftsgebäude der Wanderschäferei"])},
    "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftsgebäude des Pilzanbaus"])},
    "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftsgebäude der Produktion von Nützlingen"])},
    "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftsgebäude sonstiger Nebenbetriebe"])}
  },
  "development_state": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bauwartungsland"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rohbauland"])}
  },
  "benefit_options": {
    "Bundesmodell Nichtwohngrundstück": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denkmalgeschützte Gebäude und Gebäudeteile (§ 15 Absatz 5 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
      }
    ],
    "Bundesmodell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach bundesrechtlichen Wohnraumförderungsgesetz (§ 15 Absatz 2 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach landesrechtlichen Wohnraumförderungsgesetzen (§ 15 Absatz 3 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungsbaugesellschaft von Gebietskörperschaften (§ 15 Absatz 4 Satz 1 Nummer 1 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige Wohnungsbaugesellschaft (§ 15 Absatz 4 Satz 1 Nummer 2 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körperschaftsteuerbefreite Genossenschaften und Vereine (§ 15 Absatz 4 Satz 1 Nummer 3 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denkmalgeschützte Gebäude und Gebäudeteile (§ 15 Absatz 5 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
      }
    ],
    "Modifiziertes Bundesmodell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach bundesrechtlichen Wohnraumförderungsgesetz (§ 15 Absatz 2 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach landesrechtlichen Wohnraumförderungsgesetzen (§ 15 Absatz 3 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungsbaugesellschaft von Gebietskörperschaften (§ 15 Absatz 4 Satz 1 Nummer 1 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige Wohnungsbaugesellschaft (§ 15 Absatz 4 Satz 1 Nummer 2 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körperschaftsteuerbefreite Genossenschaften und Vereine (§ 15 Absatz 4 Satz 1 Nummer 3 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denkmalgeschützte Gebäude und Gebäudeteile (§ 15 Absatz 5 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
      }
    ],
    "Modifiziertes Bodenwertmodell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach bundesrechtlichen Wohnraumförderungsgesetz (§ 15 Absatz 2 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach landesrechtlichen Wohnraumförderungsgesetzen (§ 15 Absatz 3 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungsbaugesellschaft von Gebietskörperschaften (§ 15 Absatz 4 Satz 1 Nummer 1 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige Wohnungsbaugesellschaft (§ 15 Absatz 4 Satz 1 Nummer 2 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körperschaftsteuerbefreite Genossenschaften und Vereine (§ 15 Absatz 4 Satz 1 Nummer 3 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denkmalgeschützte Gebäude und Gebäudeteile (§ 15 Absatz 5 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
      }
    ],
    "Flächen-Modell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Wohnzwecke mit enger räumlicher Verbindung zu einem Betrieb der Land- und Forstwirtschaft (Artikel 4 Absatz 2 Bayerisches Grundsteuergesetz)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Baudenkmal nach Artikel 1 Absatz 2 Satz 1 oder Absatz 3 (Ensembleschutz) Bayerisches Denkmalschutzgesetz (Artikel 4 Absatz 3 Bayerisches Grundsteuergesetz)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Staatlichen oder kommunalen Wohnraumförderung den Bindungen des sozialen Wohnungsbaus (Artikel 4 Absatz 4 Nummer 1 Bayerisches Grundsteuergesetz)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Wohnungsbaugesellschaft von Gebietskörperschaften (Artikel 4 Absatz 4 Nummer 2 Bayerisches Grundsteuergesetz i. V. m. § 15 Absatz 4 Satz 1 Nummer 1 Grundsteuergesetz)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Gemeinnützige Wohnungsbaugesellschaft (Artikel 4 Absatz 4 Nummer 2 Bayerisches Grundsteuergesetz i. V. m. § 15 Absatz 4 Satz 1 Nummer 2 Grundsteuergesetz)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. Körperschaftsteuerbefreite Genossenschaften und Vereine (Artikel 4 Absatz 4 Nummer 2 Bayerisches Grundsteuergesetz i. V. m. § 15 Absatz 4 Satz 1 Nummer 3 Grundsteuergesetz)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 2 (Denkmalschutz) dieser Liste liegen gleichzeitig vor (Artikel 4 Absatz 2 und 3 Bayerisches Grundsteuergesetz)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 3 (Wohnraumförderung) dieser Liste liegen gleichzeitig vor (Artikel 4 Absatz 2 und 4 Nummer 1 Bayerisches Grundsteuergesetz)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 4 (Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 5 (gemeinnützige Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 6 (körperschaftsteuerbefreite Genossenschaft oder Verein) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 3 (Wohnraumförderung) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 4 (Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 5 (gemeinnützige Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 6 (körperschaftsteuerbefreite Genossenschaft oder Verein) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16. Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 3 (Wohnraumförderung) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17. Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 4 (Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18. Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 5 (gemeinnützige Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19. Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 6 (körperschaftsteuerbefreite Genossenschaft oder Verein) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19"])}
      }
    ],
    "Wohnlagen-Modell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Auf dem Grundstück befindet sich ein Baudenkmal (§4 Abs.3 Hamburgisches Grundsteuergesetz)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Wohnraumförderung (§ 4 Abs. 4 Nr. 1 Hamburgisches Grundsteuergesetz)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Wohnraumförderung (§ 4 Abs. 4 Nr. 2 Hamburgisches Grundsteuergesetz)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Förderung nach § 88d des Zweiten Wohnungsbaugesetzes in der bis zum 31. Dezember 2001 geltenden Fassung (§ 4 Abs. 4 Nr. 3 Hamburgisches Grundsteuergesetz)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Die Wohnflächen gelten nach dem Hamburgischen Wohnungsbindungsgesetz als öffentlich gefördert (§ 4 Abs. 4 Nr. 4 Hamburgisches Grundsteuergesetz)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. Die Voraussetzungen der Nummern 1 (Denkmalschutz) und 2 (Hamburgisches Wohnraumförderungsgesetz) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. Die Voraussetzungen der Nummern 1 (Denkmalschutz) und 3 (Wohnraumförderungsgesetz) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. Die Voraussetzungen der Nummern 1 (Denkmalschutz) und 4 (Zweites Wohnungsbaugesetz) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. Die Voraussetzungen der Nummern 1 (Denkmalschutz) und 5 (Hamburgisches Wohnungsbindungsgesetz) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
      }
    ],
    "Flächen-Faktor-Modell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach bundesrechtlichen Wohnraumförderungsgesetz (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 4 Satz 1 Nummer 2 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach landesrechtlichen Wohnraumförderungsgesetzen (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 4 Satz 1 Nummer 3 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungsbaugesellschaft von Gebietskörperschaften (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 2 GrStG) iVm denkmalgeschützte Gebäude und Gebäudeteile (§ 6 Absatz 3 HGrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige Wohnungsbaugesellschaft (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 4 Satz 1 Nummer 2 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körperschaftsteuerbefreite Genossenschaften und Vereine (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 4 Satz 1 Nummer 3 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denkmalgeschützte Gebäude und Gebäudeteile (§ 6 Absatz 3 HGrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach bundesrechtlichen Wohnraumförderungsgesetz § 6 Absatz 4 HGrStG iVm § 15 Absatz 2 GrStG) iVm denkmalgeschützte Gebäude und Gebäudeteile (§ 6 Absatz 3 HGrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach landesrechtlichen Wohnraumförderungsgesetzen (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 3 GrStG) i.V.m denkmalgeschützte Gebäude und Gebäudeteile (§ 6 Absatz 3 HGrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungsbaugesellschaft von Gebietskörperschaften (§ 15 Absatz 4 Satz 1 Nummer 1 GrStG) i.V.m denkmalgeschützte Gebäude und Gebäudeteile (§ 15 Absatz 5 GrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige Wohnungsbaugesellschaft (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 4 Satz 1 Nummer 2 GrStG) i.V.m denkmalgeschützte Gebäude und Gebäudeteile (§ 6 Absatz 3 HGrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körperschaftsteuerbefreite Genossenschaften und Vereine (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 4 Satz 1 Nummer 3 GrStG) i.V.m denkmalgeschützte Gebäude und Gebäudeteile (§ 6 Absatz 3 HGrStG)"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
      }
    ],
    "Flächen-Lage-Modell": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Wohnzwecke mit enger räumlicher Verbindung zu einem Betrieb der Land- und Forstwirtschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Baudenkmal"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Staatlichen oder kommunalen Wohnraumförderung den Bindungen des sozialen Wohnungsbaus"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Wohnungsbaugesellschaft von Gebietskörperschaften"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Gemeinnützige Wohnungsbaugesellschaft"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. Körperschaftsteuerbefreite Genossenschaften und Vereine"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 2 (Denkmalschutz) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 3 (Wohnraumförderung) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 4 (Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 5 (gemeinnützige Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 6 (körperschaftsteuerbefreite Genossenschaft oder Verein) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 3 (Wohnraumförderung) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 4 (Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 5 (gemeinnützige Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 6 (körperschaftsteuerbefreite Genossenschaft oder Verein) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16. Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 3 (Wohnraumförderung) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17. Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 4 (Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18 .Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 5 (gemeinnützige Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19. Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 6 (körperschaftsteuerbefreite Genossenschaft oder Verein) dieser Liste liegen gleichzeitig vor"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19"])}
      }
    ]
  },
  "benefit": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach bundesrechtlichen Wohnraumförderungsgesetz (§ 15 Absatz 2 GrStG)"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach landesrechtlichen Wohnraumförderungsgesetzen (§ 15 Absatz 3 GrStG)"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungsbaugesellschaft von Gebietskörperschaften (§ 15 Absatz 4 Satz 1 Nummer 1 GrStG)"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige Wohnungsbaugesellschaft (§ 15 Absatz 4 Satz 1 Nummer 2 GrStG)"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körperschaftsteuerbefreite Genossenschaften und Vereine (§ 15 Absatz 4 Satz 1 Nummer 3 GrStG)"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denkmalgeschützte Gebäude und Gebäudeteiles (§ 15 Absatz 5 GrStG)"])}
  },
  "contained_in_area_option": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Fläche"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweite Fläche"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beide Flächen"])}
  },
  "contained_in_area_option_search": {
    "Erste Fläche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
    "Zweite Fläche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
    "Beide Flächen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
  },
  "gemeindes": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassel"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berlin"])}
  },
  "salutation": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Anrede"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbengemeinschaft"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsgemeinschaft"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksgemeinschaft"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft bürgerlichen Rechts"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sozietät"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praxisgemeinschaft"])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsgemeinschaft"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentümergemeinschaft"])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnergesellschaft"])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenreederei"])}
  },
  "property_community_salutation": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ohne Anrede"])}
  },
  "dashboardStatus": {
    "null": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Daten überprüfen"])}
    },
    "draft": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf, für Steuerbüro nicht sichtbar."])},
      "redText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Jetzt freigeben)"])}
    },
    "processing": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bearbeitung beim Steuerbüro"])}
    },
    "internal_approval_requested": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Prüfung beim Steuerbüro"])}
    },
    "data_requested": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Daten überprüfen"])},
      "redText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Erledigt markieren)"])}
    },
    "approval_pending": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Freigabe ist erforderlich"])},
      "redText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "email_approval_pending": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigaben per E-Mail angefordert"])},
      "redText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "elster_submission": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten bereit für ELSTER-Übermittlung durch Steuerbüro"])}
    },
    "elster_completed": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An das Finanzamt übermittelt, Wartet auf Bescheid"])}
    },
    "assessment_received": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bescheid erhalten, in Prüfung"])}
    },
    "dispute": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einspruch zum Bescheid"])}
    },
    "completed": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])}
    }
  },
  "select": {
    "select_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])}
  },
  "deleteModal": {
    "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer löschen"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die folgenden Datensätze wirklich löschen?"])},
    "sel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die ausgewählten Datensätze wirklich löschen?"])}
  },
  "copyright": {
    "impressum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "datenschutz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])}
  },
  "notify": {
    "title": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
    }
  },
  "Property": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Grundstück anlegen"])},
    "subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir stellen Ihnen nun einige Fragen zu Ihrem Grundstück. Sofern Sie Fragen haben oder Hilfe benötigen, melden Sie sich gerne bei uns."])},
    "subline2": {
      "sublinetag1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neben der manuellen Anlage können Sie auch unsere"])},
      "sublinetag2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücks-Importvorlage"])},
      "sublinetag3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["herunterladen und diese in Excel befüllen und hier wieder hochladen."])}
    },
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
    "createprop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Grundstück manuell anlegen"])},
    "uploadtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Excel-Importvorlage hochladen"])},
    "propertyerror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie die Grundstücksart"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "timer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauert ca. 15 Min"])}
  },
  "Property_final": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigt!"])},
    "subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Grundstück wurde erfolgreich angelegt. Ihr Steuerbüro sieht das Grundstück noch nicht. Möchten Sie das Grundstück jetzt für Ihr Steuerbüro zur Bearbeitung freigeben?"])},
    "sharenow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt freigeben"])},
    "later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Später"])},
    "propertyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Grundstück wurde erfolgreich angelegt."])},
    "propertyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Grundstück wurde erfolgreich aktualisiert."])},
    "propertySaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Grundstück wurde erfolgreich gespeichert."])},
    "propertyDuplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Grundstück wurde erfolgreich dupliziert."])}
  },
  "Property_added": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück hinzufügen"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr neues Grundstück wurde hinzugefügt."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können zusätzliche Angaben machen, um die Erfassung ggf. zu vervollständigen und anschließend für Ihr Steuerbüro freizugeben."])},
    "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "dashboardButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Übersicht"])}
  },
  "newProperty": {
    "infoTooltip": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigen Sie weitere Informationen?"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Dieses Feld ist deaktiviert und muss für Ihr Grundstück nicht befüllt werden."])},
      "optional_help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld ist optional"])},
      "available_help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eintragen, falls vorhanden"])},
      "applicable_help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur ausfüllen, wenn zutreffend"])},
      "houseNumber": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Tragen Sie hier bitte die Hausnummer sowie den ggf. vorhandenen Hausnummernzusatz ein."])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "street": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Für die Elster-Übermittlung sind bei diesem Feld nur max. 25 Zeichen erlaubt. Sollte Ihr Straßenname länger sein, kürzen Sie den Namen bitte ab."])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "address": {
        "federalState": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Die Angabe des Bundeslandes ist die Grundlage für die weitere Grundstückserfassung. Hier wird zwischen Bundes- und Ländermodellen unterschieden. Je nach Bundesland unterscheiden sich daher die benötigten Angaben.<br><br>Bitte beachten Sie, dass ausgegraute Felder in dem gewählten Bundesland nicht erforderlich sind."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/welches-modell-gilt-fuer-mein-bundesland/"])}
        },
        "community": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Bitte tragen Sie die entsprechende Gemeinde in das Feld ein, oder wählen Sie die jeweilige Gemeinde aus den Vorschlägen aus.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Es ist wichtig, dass in diesem Feld die Gemeinde eingetragen wird und nicht der Landkreis."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "details": {
        "reference": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br>Einheitswert- und Grundsteuermessbescheid.<br><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>In manchen Bundesländern gibt es statt dem Einheitswert-Aktenzeichen eine Steuernummer. Dies gilt für Berlin, Bremen, Hamburg und Schleswig-Holstein. Bitte tragen Sie für diese Bundesländer die Steuernummer des Grundstücks ein.<br><a>ACHTUNG:</a> Es handelt sich hier nicht um Ihre persönliche Steuernummer, die Sie z.B. für Ihre Einkommensteuererklärung nutzen.<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Exakt wie im Einheitswert- und Grundsteuermessbescheid abgebildet."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/einheitswert-aktenzeichen-und-steuernummer/"])}
        },
        "name": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Hier wird automatisch die Grundstücksadresse eingesetzt. Sie können dies gerne jederzeit in eine beliebige Bezeichnung ändern."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "economicEntityType": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br><a>Unbebautes Grundstück:</a><br>Sofern sich kein nutzbares Gebäude auf dem Grundstück befindet, handelt es sich um ein unbebautes Grundstück.<br><br><a>Bebautes Grundstück:</a><br>Befindet sich auf dem Grundstück ein nutzbares Gebäude, handelt es sich um ein bebautes Grundstück.<br><br><a>Betrieb der Land- und Forstwirtschaft:</a><br>Ein Betrieb der Land- und Forstwirtschaft besteht aus dem gesamten land- und forstwirtschaftlichen Vermögen. Dazu gehören neben dem Grund und Boden auch die Wirtschaftsgebäude, stehende Betriebsmittel und der normale Bestand an umlaufenden Betriebsmitteln.<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Wählen Sie aus den Vorschlägen aus.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Die Nutzbarkeit beginnt zum Zeitpunkt der Bezugsfertigkeit des Gebäudes."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/art-der-wirtschaftlichen-einheit/"])}
        },
        "exemption": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Eine Befreiung/Vergünstigung kann vorliegen, wenn z.B. einer der folgenden Punkte zutreffen: <br/><br/> <ul><li>Gemeinnütziger Bereich</li><li>Stiftung oder Verein (greifen dort auch nur, soweit es sich nicht um Grundbesitz für Wohnzwecke handelt)</li><li>Juristische Person des öffentlichen Rechts</li><li>Gemeinnütziger oder mildtätiger Körperschaft</li><li>Personenvereinigung</li><li>Vermögensmasse</li><li>Religionsgemeinschaft</li></ul>"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/grundsteuerbefreiung-verguenstigung/"])}
        },
        "extraDetails": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Hier können Sie weitere Angaben eintragen, die an das Finanzamt übermittelt werden sollen. Dies könnten z.B. Sachverhalte sein, die aufgrund der ELSTER-Vorgabe nicht vollständig abgebildet werden können. Falls Sie lediglich Belege oder Aufstellungen zu Ihrer Erklärung zur Feststellung des Grundsteuerwerts einreichen wollen, machen Sie hier bitte keine Angaben."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "moreDetails": {
        "ownershipStructure": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br>Wer Eigentümer ist, ergibt sich zum einen aus dem Grundbuchauszug (im Abschnitt „Erste Abteilung”). Zum anderen enthält auch der bisherige Einheitswert- und Grundsteuermessbescheid Angaben zu den Eigentümern.<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Wählen Sie aus den Vorschlägen aus."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/eigentumsverhaeltnisse/"])}
        },
        "propertyType": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Kaufvertrag</li><li>Grundbuchauszug</li><li>Einheitswertbescheid</li><li>Grundsteuermessbescheid</li></ul><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Wählen Sie aus den Vorschlägen aus. Sofern das Feld ausgegraut ist, ist diese Angabe in dem gewählten Bundesland nicht erforderlich."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/art-des-grundstuecks/"])}
        },
        "developmentState": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Bebauungsplan</li><li>Flächennutzungsplan</li></ul><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Wählen Sie aus den Vorschlägen aus. Sofern das Feld ausgegraut ist, ist diese Angabe in dem gewählten Bundesland nicht erforderlich.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br><a>Rohbauland:</a><br>sind Flächen, die für eine bauliche Nutzung bestimmt sind, deren Erschließung aber noch nicht gesichert ist oder die nach Lage, Form oder Größe für eine bauliche Nutzung unzureichend gestaltet sind. Im Regelfall handelt es sich um größere, unerschlossene Grundstücksflächen, die kein land- und forstwirtschaftliches Vermögen mehr sind, selbst wenn sie noch so genutzt werden.<br><br><a>Bauerwartungsland:</a><br>sind solche Flächen, die planungsrechtlich für eine Bebauung zwar noch nicht bestimmt sind, bei denen aber damit zu rechnen ist, dass sie in absehbarer Zeit bebaut werden. Bauerwartungsland liegt im sogenannten Außenbereich."])}
        },
        "multiCommunities": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuchauszug</li></ul><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Bei Grundstücken in Randlagen kann es passieren, dass diese sich über mehrere Gemeindegebiete erstrecken. Das hat zur Folge, dass verschiedene Gemeinden für die Erhebung der Grundsteuer zuständig sind."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/grundbesitz-ueber-mehrere-gemeinden/"])}
        },
        "leasehold": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><ul><li>Grundbuch</li><li>Erbbaurechtsgrundbuch</li></ul><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Bei Grundstücken, die mit einem Erbbaurecht belastet sind, bildet das Erbbaurecht zusammen mit dem belasteten Grundstück eine wirtschaftliche Einheit. Der Gesamtwert von Grund und Boden sowie Gebäude wird dem Erbbauberechtigten zugerechnet. Ihm gegenüber ergeht der Feststellungsbescheid über den Grundsteuerwert."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/erbbaurecht/"])}
        },
        "buildingOnForeignGround": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><ul><li>Miet- oder Pachtvertrag</li></ul><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Sofern sämtliche Aufwendungen für die Herstellung eines Gebäudes auf einem Grundstück, dessen bürgerlich-rechtlicher Eigentümer ein anderer ist, von dem Steuerpflichtigen getragen werden, handelt es sich um ein Gebäude auf fremdem Grund und Boden. Steuerlich ist der Grund und Boden dem zivilrechtlichen Eigentümer, das Gebäude in diesem Fall dem wirtschaftlichen Eigentümer zuzurechnen.<br><br>Bei einem Gebäude auf fremdem Grund und Boden ist für den Grund und Boden sowie für das Gebäude auf fremdem Grund und Boden ein Gesamtwert zu ermitteln. Der ermittelte Wert ist dem Eigentümer des Grund und Bodens zuzurechnen."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/gebaeude-auf-fremden-grund/"])}
        },
        "residentialPurposes": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Einheitswertbescheid</li></ul><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Die Voraussetzungen sind regelmäßig erfüllt, wenn Ihr Grundstück im Rahmen der bisherigen Einheitsbewertung vom Finanzamt als Einfamilienhaus, Zweifamilienhaus, Mietwohngrundstück (in der Regel Mehrfamilienhäuser) oder Wohnungseigentum bewertet wurde und sich hinsichtlich Bauart und Nutzung seither keine Änderungen ergeben haben.<br><br>In allen anderen Fällen ermitteln Sie bitte anhand der Wohn- und Nutzflächenberechnung, ob der Anteil der Wohnnutzung an der Gesamtfläche mehr als 50 Prozent beträgt."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "registrationLandRegistry": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuch</li></ul><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Datum der Antragsstellung <br><br> <b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Es handelt sich um das Datum der Neueintragung für Wohnungs- und Teileigentum."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "totalPropertyPart": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuch</li></ul><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Quadratmeterzahl der bebauten oder befestigten Fläche. <br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Befüllen Sie dieses Feld nur, wenn Ihr Grundstück größer als 10.000 m² ist."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "areaOfTheLand": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Bestandsverzeichnis</li><li>Kaufvertrag</li><li>Grundbuchauszug</li></ul><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Die Fläche des Grundstücks<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Zahlenwert ohne Kommastelle<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Von der Größe des Grundstücks hängt die Höhe der Grundsteuer ab.<br>Sofern in den zwei Feldern “Zur wirtschaftlichen Einheit gehörender Anteil” nicht 1/1 eingetragen wurde, kann über diese Formel die “Fläche des Grundstücks in m²” berechnet werden:<br><br>[Fläche des Grundstücks in m² * (Zur wirtschaftlichen Einheit gehörender Anteil: Zähler / Zur wirtschaftlichen Einheit gehörender Anteil: Nenner)]"])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/grundstuecksflaeche-in-m%c2%b2/"])}
        },
        "areaOfTheLandValue": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Service Grundsteuerlösung: Liegenschaftsinformationen abrufen</li><li>Geo-Viewer</li></ul><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Betrag pro m²<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Zahlenwert mit Kommastelle, ohne Vorzeichen und ohne Eurozeichen<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>In vielen Bundesländern wird in die Berechnung des Grundsteuerwerts, der Wert des Grundstücks mit einbezogen. Dieser ermittelt sich anhand der Grundstücksfläche und des Bodenrichtwerts.<br><br>HINWEIS: Da ein Flurstück in unterschiedlichen Bodenrichtwertzonen liegen kann, werden Ihnen für ausgewählte Flurstücke mehrere Bodenrichtwerte zur Auswahl angezeigt. Diese unterscheiden sich je nach Nutzungsart der Fläche. Bitte prüfen Sie eingehend, ob das Flurstück (bzw. die Fläche) ggf. zu teilen und mit verschiedenen Bodenrichtwerten zu bewerten ist, da mehrere Nutzungsarten (z.B. Wohnbaufläche und Ackerfläche) vorliegen."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/bodenrichtwert/"])}
        },
        "areaOfTotalSubarea": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Tragen Sie bitte die m&sup2; für die gesamte selbstständig nutzbare Teilfläche ein."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "landValueOfSubarea": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Tragen Sie bitte den zutreffenden Bodenrichtwert für die Teilfläche hier ein.<br><br> (Falls nur ein Bodenrichtwert vorhanden ist, tragen Sie ihn bitte trotzdem ein, da dieser Schritt der Berechnung von der ELSTER-Übertragung losgelöst ist)."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "includedInWhichArea": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Teilen Sie bitte mit diesem Feld Ihre Teilfläche einer Grundstücksfläche zu, damit wir in der Berechnung den angegebenen Flächenbetrag davon abgrenzen können.<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Wählen Sie einen Eintrag aus der Auswahlliste aus."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "subAreaBuildable": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Wenn die Teilfläche eine bebaubare Teilfläche ist, ergibt sich der Bodenwert aus Fläche * BRW (für bebaubare Flächen)."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "landValueForBuildingLand": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Wenn kein gesonderter Bodenrichtwert vorliegt, der die geringere Nutzbarkeit dieser Fläche berücksichtigt, ist der Umrechnungskoeffizient auf die gesamte Fläche anzuwenden."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "community": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Bitte tragen Sie die entsprechende Gemeinde in das Feld ein, oder wählen Sie die jeweilige Gemeinde aus den Vorschlägen aus.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Es ist wichtig, dass in diesem Feld die Gemeinde eingetragen wird und nicht der Landkreis."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "parcel": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuchauszug</li><li>Bestandsnachweis</li><li>Auszug aus dem Liegenschaftskataster</li><li>Online abrufbar über Geo-Viewer</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Bitte tragen Sie die entsprechende Gemarkung in das Feld ein oder wählen Sie die jeweilige Gemarkung aus den Vorschlägen aus.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Eine Gemarkung ist eine katasterrechtliche Bezeichnung für ein Gebiet innerhalb einer Gemeinde. In der Regel ist das ein Ortsname, Stadtteilname oder Gebietsname. Die Gemarkung bildet einen Grundstücksverband aus einer größeren Zahl von in der Regel zusammenhängenden Grundstücken bzw. Flurstücken."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/gemarkung/"])}
        },
        "landRegisterSheet": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuch</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Grundbuchblattnummer (Pflichtangabe für die Bundesländer Hessen und NRW)<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Wie im Grundbuch aufgeführt.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Jedes Grundstück erhält im Grundbuch eine besondere Stelle, das Grundbuchblatt. Hier ist vermerkt, welche Flurstücke zu einem Grundstück gehören, wer Eigentümer ist und welche Belastungen (z.B. Grundschuld, Hypotheken) auf dem Grundstück liegen. Es handelt sich dabei um eine Nummer. <br><br>HINWEIS: Bitte beachten Sie, dass Sie in NRW & Hessen für die ELSTER-Übertragung zwingend die Grundbuchblattnummer benötigen, die Sie in Ihrem Grundbuchauszug finden oder in NRW im kostenfreien Geo-Viewer. Die Grundbuchblattnummer können wir leider nicht über unseren Liegenschaftsservice abrufen."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/grundbuchblatt/"])}
        },
        "corridor": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuchauszug</li><li>Bestandsnachweis</li><li>Liegenschaftskarte / Auszug aus dem Liegenschaftskataster</li><li>Online abrufbar über Geo-Viewer</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Flurnummer<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Wie im Grundbuchauszug abgebildet.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Die Flur ist ein Teil einer Gemarkung und beschreibt die Nutzfläche einer Siedlung innerhalb einer Gemeinde. Damit lässt sich die Lage eines einzelnen Grundstücks näher eingrenzen als mit der bloßen Angabe der Gemarkung."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/flur/"])}
        },
        "parcelAreaOfTheLand": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuchauszug</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Bitte tragen Sie hier die Fläche des erfassten Flurstücks in m² ein."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "parcelDataCounter": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuchauszug</li><li>Liegenschaftskarte / Auszug aus dem Liegenschaftskataster</li><li>GeoViewer je Bundesland</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Den Zähler: tragen Sie hier den Zahlenwert vor bzw. über dem Bruchstrich ein.<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Den Zähler (ohne Bruchstrich).<br><br><b>Beispiel:</b><br>Sie finden in Ihrem Grundbuch den Wert <b>83</b>/194. In diesem Fall müsste der Wert <b>83</b> eingetragen werden.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Mit Flurstück wird ein einzelnes Grundstück bezeichnet. Die Nummer eines Flurstücks besteht aus Zähler und Nenner.<br><br>Wichtig: Der gesamte Grundbesitz, der zum Beispiel mit einem Einfamilienhaus bebaut und im Übrigen als Garten genutzt wird, kann durchaus aus mehreren Flurstücken bestehen. In der Feststellungserklärung sind alle Flurstücke des Steuerpflichtigen separat anzugeben."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/flurstueck-zaehler-nenner/"])}
        },
        "parcelDataDenominator": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuchauszug</li><li>Liegenschaftskarte / Auszug aus dem Liegenschaftskataster</li><li>GeoViewer je Bundesland</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Den Nenner: tragen Sie hier den Zahlenwert hinter bzw. unter dem Bruchstrich ein. Nicht jedes Flurstückkennzeichen hat auch einen Flurstücknenner. Dieses Feld ist dann freizulassen.<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Den Nenner (ohne Bruchstrich).<br><br><b>Beispiel</b><br>Sie finden in Ihrem Grundbuch den Wert 83/<b>194</b>. In diesem Fall müsste der Wert <b>194</b> eingetragen werden.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Mit Flurstück wird ein einzelnes Grundstück bezeichnet. Die Nummer eines Flurstücks besteht aus Zähler und Nenner. <br><br>Wichtig: Der gesamte Grundbesitz, der zum Beispiel mit einem Einfamilienhaus bebaut und im Übrigen als Garten genutzt wird, kann durchaus aus mehreren Flurstücken bestehen. In der Feststellungserklärung sind alle Flurstücke des Steuerpflichtigen separat anzugeben."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/flurstueck-zaehler-nenner/"])}
        },
        "shareOfOwnershipCounter": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>im Grundbuch unter der Rubrik &#8220;Eigentümer&#8221;</li><li>Im Katasterauszug bei &#8220;Angaben zum Eigentum&#8221;</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Tragen Sie hier den Anteil (z.B. 1/2 oder 20/100) des jeweiligen Miteigentümers an der wirtschaftlichen Einheit (Flurstück) ein.<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Bitte geben Sie die Anteile der jeweiligen Person an der wirtschaftlichen Einheit als Bruch an.<br>Den Zähler (Zahl vor bzw. über dem Bruchstrich) ohne Bruchstrich.<br><br><b>Beispiel</b><br>Sie finden in Ihrem Grundbuch den Wert <b>1</b>/2. In diesem Fall muss der Wert <b>1</b> eingetragen werden. <br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Gemeinschaftseigentümern wie z.B. Ehepaaren und Lebenspartnern gehört eine Immobilie häufig gemeinsam. Bei zwei Miteigentümern ist der Miteigentumsanteil pro Person häufig 1/2. Es sind aber auch andere Anteile denkbar, z.B. 20/100 und 80/100."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/zur-wirtschaftlichen-einheit-gehoerender-anteil/"])}
        },
        "shareOfOwnershipDenominator": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>im Grundbuch unter der Rubrik &#8220;Eigentümer&#8221;</li><li>Im Katasterauszug bei &#8220;Angaben zum Eigentum&#8221;</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Tragen Sie hier den Anteil (z.B. 1/2 oder 20/100) des jeweiligen Miteigentümers an der wirtschaftlichen Einheit (Flurstück) ein.<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Bitte geben Sie die Anteile der jeweiligen Person an der wirtschaftlichen Einheit als Bruch an. <br> Den Nenner (Zahl hinter bzw. unter dem Bruchstrich) ohne Bruchstrich.<br><br><b>Beispiel</b><br>Sie finden in Ihrem Grundbuch den Wert 1/<b>2</b>. In diesem Fall muss der Wert <b>2</b> eingetragen werden. <br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Gemeinschaftseigentümern wie z.B. Ehepaaren und Lebenspartnern gehört eine Immobilie häufig gemeinsam. Bei zwei Miteigentümern ist der Miteigentumsanteil pro Person häufig 1/2. Es sind aber auch andere Anteile denkbar, z.B. 20/100 und 80/100."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/zur-wirtschaftlichen-einheit-gehoerender-anteil/"])}
        },
        "containedInArea": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Service Datenbeschaffung (Liegenschaftsinformationen abrufen)</li><li>Grundbuchauszug</li></ul><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Wählen Sie aus den Vorschlägen aus.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Diese Auswahl bezieht sich auf die Angaben unter „Flächenangaben des Grundstücks“ <br><br>Gibt es für das Grundstück nur einen Bodenrichtwert, wählen Sie hier „erste Fläche“ aus. Gibt es mehrere Bodenrichtwerte, geben hier an, ob für das Flurstück der Bodenrichtwert der ersten Fläche, der zweiten Fläche oder beider Flächen maßgebend ist."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      },
      "benefits": {
        "taxExemption": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Auch bei der Grundsteuer gibt es Befreiungs- und Vergünstigungsmöglichkeiten. Diese beziehen sich im Wesentlichen auf den gemeinnützigen Bereich etwa von Stiftungen oder Vereinen und greifen dort auch nur, soweit es sich nicht um Grundbesitz für Wohnzwecke handelt."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/grundsteuerbefreiung-verguenstigung/"])}
        },
        "benefit": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Auch bei der Grundsteuer gibt es Befreiungs- und Vergünstigungsmöglichkeiten. Diese beziehen sich im Wesentlichen auf den gemeinnützigen Bereich etwa von Stiftungen oder Vereinen und greifen dort auch nur, soweit es sich nicht um Grundbesitz für Wohnzwecke handelt."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/grundsteuerbefreiung-verguenstigung/"])}
        },
        "propertyExemptionBenefitType": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Wählen Sie aus den Vorschlägen aus. Sofern das Feld ausgegraut ist, ist diese Angabe in dem gewählten Bundesland nicht erforderlich.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Diese Angabe ist nur bei Wohnungsbaugesellschaften, Genossenschaften und Vereinen zu tätigen."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/art-des-grundstuecks/"])}
        }
      },
      "buildings": {
        "livingSpaceArea": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><ul><li>Kaufvertrag</li><li>Bauplan</li><li>Beauftragtes Architektenbüro</li></ul><b class='tooltip-title tooltip-file-icon'>Über wen kann ich sie berechnen lassen?</b><ul><li>Vermessungsingenieure</li></ul><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Wohnflächen sind Flächen, die zu Wohnzwecken dienen. Ein häusliches Arbeitszimmer (Homeoffice) gilt als Wohnfläche.<br><br>Die Wohnfläche ergibt sich aus der Wohnflächenberechnung. Liegt eine Wohnflächenberechnung nicht vor, ist die Wohnfläche nach Wohnflächenverordnung (WoFIV) zu ermitteln."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/wohn-nutzflaeche-des-gebaeudes-in-m%c2%b2/"])}
        },
        "usableSpaceArea": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><ul><li>Kaufvertrag</li><li>Bauplan</li><li>Beauftragtes Architektenbüro</li></ul><b class='tooltip-title tooltip-file-icon'>Über wen kann ich sie berechnen lassen?</b><ul><li>Vermessungsingenieure</li></ul><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Als Nutzflächen werden Flächen bezeichnet, die insbesondere betrieblichen Zwecken dienen und die keine Wohnflächen sind. Dazu zählen zum Beispiel Werkstätten, Verkaufsräume, Büros. Für die Nutzfläche kommt DIN 277 zur Anwendung."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/wohn-nutzflaeche-des-gebaeudes-in-m%c2%b2/"])}
        }
      },
      "ownership": {
        "financeAgency": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Bitte tragen Sie hier Ihr zuständiges Finanzamt ein."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "taxNumber": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Tragen Sie bitte hier die persönliche Steuernummer ein und <b>nicht</b> das Aktenzeichen bzw. die Steuernummer des Grundstückes.<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Bitte tragen Sie die Steuernummer so ein, wie sie im Schriftverkehr mit der Finanzverwaltung mitgeteilt wird (sog. landesspezifisches Bescheidformat).<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Die Steuernummer muss bei Übermittlung in das bundeseinheitliche ELSTER-Steuernummerformat (13-Ziffern) umgewandelt werden. Die Steuernummer wird auf dem PDF-Dokument im landesspezifischen Bescheidformat gedruckt."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/steuernummer-privatperson-unternehmen/"])}
        },
        "taxIdentificationNumber": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Mitteilungsschreiben des Bundeszentralamtes für Steuern.</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Eigentümer, die als natürliche Personen einen Wohnsitz im Inland haben, können vom Finanzamt über die Identifikationsnummer identifiziert werden. Die (optionale) Angabe der Identifikationsnummer erleichtert es dem Finanzamt, die jeweilige Zuordnung der Eigentümer zur Grundsteuer vorzunehmen.<br><br>Sollten Sie das Mitteilungsschreiben nicht mehr vorliegen haben, dann finden Sie die Identifikationsnummer auch auf Ihrer Lohnabrechnung oder auf Ihrem letzten Einkommensteuerbescheid. Außerdem finden Sie die Identifikationsnummer auch auf jedem anderen Schreiben des Finanzamts, solange es um die Einkommensteuer geht."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "shareOfOwnershipCounter": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Kaufvertrag</li><li>Grundbuch unter der Rubrik &#8220;Eigentümer&#8221;</li></ul><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Tragen Sie hier den Anteil (Zahlenwert vor bzw. über dem Bruchstrich) des jeweiligen (Mit-)Eigentümers an der wirtschaftlichen Einheit ein.<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Den Zähler (Zahl vor bzw. über dem Bruchstrich) ohne Bruchstrich.<br><br><b>Beispiel</b><br>Sie finden in Ihrem Grundbuch den Wert <b>1</b>/2. In diesem Fall müsste der Wert <b>1</b> eingetragen werden. <br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Gemeinschaftseigentümern wie z.B. Ehepaaren und Lebenspartnern gehört eine Immobilie häufig gemeinsam. Bei zwei Miteigentümern ist der Miteigentumsanteil pro Person häufig 1/2. Es sind aber auch andere Anteile denkbar, z.B. 20/100 und 80/100."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/zur-wirtschaftlichen-einheit-gehoerender-anteil/"])}
        },
        "shareOfOwnershipDenominator": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Kaufvertrag</li><li>Grundbuch unter der Rubrik &#8220;Eigentümer&#8221;</li></ul><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Tragen Sie hier den Anteil (Zahlenwert hinter bzw. unter dem Bruchstrich) des jeweiligen (Mit-)Eigentümers an der wirtschaftlichen Einheit ein.<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Den Nenner (Zahl hinter bzw. unter dem Bruchstrich) ohne Bruchstrich.<br><br><b>Beispiel</b><br>Sie finden in Ihrem Grundbuch den Wert <b>1</b>/2. In diesem Fall müsste der Wert <b>2</b> eingetragen werden. <br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Gemeinschaftseigentümern wie z.B. Ehepaaren und Lebenspartnern gehört eine Immobilie häufig gemeinsam. Bei zwei Miteigentümern ist der Miteigentumsanteil pro Person häufig 1/2. Es sind aber auch andere Anteile denkbar, z.B. 20/100 und 80/100."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/zur-wirtschaftlichen-einheit-gehoerender-anteil/"])}
        }
      },
      "residential": {
        "yearOfConstruction": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><ul><li>Kaufvertrag</li></ul><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Jahr der Bezugsfertigkeit<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Als Baujahr eines Gebäudes gilt das Jahr, in dem es bezugsfertig ist."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/baujahr-des-gebaeudes/"])}
        },
        "coreRenovationYear": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><ul><li>Kaufvertrag</li></ul><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Jahr der Kernsanierung<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Eine Kernsanierung liegt vor, wenn zum einen der Ausbau (u. a. Heizung, Fenster und Sanitäreinrichtungen) umfassend modernisiert und zum anderen der Rohbau teilweise oder ganz erneuert worden ist. Durch eine Kernsanierung erhält das Gebäude einen Zustand, der dem eines neuen Gebäudes nahezu entspricht.<br><br> Als Jahr der Kernsanierung gilt das Jahr, in dem die Kernsanierung abgeschlossen wird. Dieses ergibt sich zum Beispiel aus den Unterlagen zur Durchführung der Sanierung."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/jahr-der-kernsanierung/"])}
        },
        "demolitionObligationYear": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Eine bestehende Abbruchverpflichtung führt zu einer Verkürzung der Restnutzungsdauer und damit unter dem Strich zu einer Verringerung der Grundsteuer."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/abbruchverpflichtung/"])}
        },
        "amountGarages": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><ul><li>Kaufvertrag</li><li>Bauplan</li></ul><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Hier ist die Gesamtanzahl, der auf dem Grundstück vorhandenen Garagen- und Tiefgaragenstellplätzen anzugeben. Stellplätze im Freien und Carports sind nicht einzutragen."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/garagen/"])}
        }
      },
      "nonResidential": {
        "locationPlanNumber": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Der Lageplan wird auf der Grundlage amtlicher Katasterunterlagen, eigener Vermessungen des Baugrundstücks sowie der näheren Umgebung und durch die Einarbeitung bestehender baurechtlicher Vorgaben erstellt. Der Lageplan stellt insofern ein Abbild der Örtlichkeit dar und gibt andererseits die Möglichkeit, unter Beachtung des baulich Machbaren die rechtlich zulässigen Planungen festzulegen."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "buildingType": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><ul><li>Kaufvertrag</li><li>Bauantrag</li></ul><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Die Gebäudeart entscheidet nicht nur über die Art der Bewertung, sondern spielt auch bei der Ermittlung der wirtschaftlichen Gesamtnutzungsdauer bzw. der Restnutzungsdauer eine Rolle."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/gebaeudeart/"])}
        },
        "yearOfConstruction": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><ul><li>Kaufvertrag</li></ul><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Jahr der Bezugsfertigkeit<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Als Baujahr eines Gebäudes gilt das Jahr, in dem es bezugsfertig ist."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/baujahr-des-gebaeudes/"])}
        },
        "coreRenovationYear": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><ul><li>Kaufvertrag</li></ul><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Jahr der Kernsanierung<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Eine Kernsanierung liegt vor, wenn zum einen der Ausbau (u. a. Heizung, Fenster und Sanitäreinrichtungen) umfassend modernisiert und zum anderen der Rohbau teilweise oder ganz erneuert worden ist. Durch eine Kernsanierung erhält das Gebäude einen Zustand, der dem eines neuen Gebäudes nahezu entspricht.<br><br> Als Jahr der Kernsanierung gilt das Jahr, in dem die Kernsanierung abgeschlossen wird. Dieses ergibt sich zum Beispiel aus den Unterlagen zur Durchführung der Sanierung."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/jahr-der-kernsanierung/"])}
        },
        "demolitionObligationYear": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Eine bestehende Abbruchverpflichtung führt zu einer Verkürzung der Restnutzungsdauer und damit unter dem Strich zu einer Verringerung der Grundsteuer."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/abbruchverpflichtung/"])}
        }
      },
      "agriculture": {
        "gemeindeDescription": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Dieses Feld dient Ihnen als Notizfeld, z. B. um den Namen des Flurstückes anzugeben. Dieser Eintrag wird nicht an die Finanzverwaltung übermittelt."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "community": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Bitte tragen Sie die entsprechende Gemeinde in das Feld ein, oder wählen Sie die jeweilige Gemeinde aus den Vorschlägen aus.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Es ist wichtig, dass in diesem Feld die Gemeinde eingetragen wird und nicht der Landkreis."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "parcel": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuchauszug</li><li>Bestandsnachweis</li><li>Auszug aus dem Liegenschaftskataster</li><li>Online abrufbar über Geo-Viewer</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Bitte tragen Sie die entsprechende Gemarkung in das Feld ein oder wählen Sie die jeweilige Gemarkung aus den Vorschlägen aus.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Eine Gemarkung ist eine katasterrechtliche Bezeichnung für ein Gebiet innerhalb einer Gemeinde. In der Regel ist das ein Ortsname, Stadtteilname oder Gebietsname. Die Gemarkung bildet einen Grundstücksverband aus einer größeren Zahl von in der Regel zusammenhängenden Grundstücken bzw. Flurstücken."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/gemarkung/"])}
        },
        "parcelNumber": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuchauszug</li><li>Bestandsnachweis</li><li>Auszug aus dem Liegenschaftskataster</li><li>Online abrufbar über Geo-Viewer</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Bitte tragen Sie die Nummer der entsprechenden Gemarkung in das Feld ein oder wählen Sie die jeweilige Nummer aus den Vorschlägen aus.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Eine Gemarkung ist eine katasterrechtliche Bezeichnung für ein Gebiet innerhalb einer Gemeinde. In der Regel ist das ein Ortsname, Stadtteilname oder Gebietsname. Die Gemarkung bildet einen Grundstücksverband aus einer größeren Zahl von in der Regel zusammenhängenden Grundstücken bzw. Flurstücken."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/gemarkung/"])}
        },
        "landRegisterSheet": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuch</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Grundbuchblattnummer (Pflichtangabe für die Bundesländer Hessen und NRW)<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Wie im Grundbuch aufgeführt.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Jedes Grundstück erhält im Grundbuch eine besondere Stelle, das Grundbuchblatt. Hier ist vermerkt, welche Flurstücke zu einem Grundstück gehören, wer Eigentümer ist und welche Belastungen (z.B. Grundschuld, Hypotheken) auf dem Grundstück liegen. Es handelt sich dabei um eine Nummer. <br><br>HINWEIS: Bitte beachten Sie, dass Sie in NRW & Hessen für die ELSTER-Übertragung zwingend die Grundbuchblattnummer benötigen, die Sie in Ihrem Grundbuchauszug finden oder in NRW im kostenfreien Geo-Viewer. Die Grundbuchblattnummer können wir leider nicht über unseren Liegenschaftsservice abrufen."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/grundbuchblatt/"])}
        },
        "corridor": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuchauszug</li><li>Bestandsnachweis</li><li>Liegenschaftskarte / Auszug aus dem Liegenschaftskataster</li><li>Online abrufbar über Geo-Viewer</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Flurnummer<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Wie im Grundbuchauszug abgebildet.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Die Flur ist ein Teil einer Gemarkung und beschreibt die Nutzfläche einer Siedlung innerhalb einer Gemeinde. Damit lässt sich die Lage eines einzelnen Grundstücks näher eingrenzen als mit der bloßen Angabe der Gemarkung."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/flur/"])}
        },
        "parcelAreaOfTheLand": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuchauszug</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Bitte tragen Sie hier die Fläche des erfassten Flurstücks in m² ein."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "parcelDataCounter": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuchauszug</li><li>Liegenschaftskarte / Auszug aus dem Liegenschaftskataster</li><li>GeoViewer je Bundesland</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Den Zähler: tragen Sie hier den Zahlenwert vor bzw. über dem Bruchstrich ein.<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Den Zähler (ohne Bruchstrich).<br><br><b>Beispiel:</b><br>Sie finden in Ihrem Grundbuch den Wert <b>83</b>/194. In diesem Fall müsste der Wert <b>83</b> eingetragen werden.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Mit Flurstück wird ein einzelnes Grundstück bezeichnet. Die Nummer eines Flurstücks besteht aus Zähler und Nenner.<br><br>Wichtig: Der gesamte Grundbesitz, der zum Beispiel mit einem Einfamilienhaus bebaut und im Übrigen als Garten genutzt wird, kann durchaus aus mehreren Flurstücken bestehen. In der Feststellungserklärung sind alle Flurstücke des Steuerpflichtigen separat anzugeben."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/flurstueck-zaehler-nenner/"])}
        },
        "parcelDataDenominator": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-search-icon'>Wo finde ich die Angaben?</b><br><ul><li>Grundbuchauszug</li><li>Liegenschaftskarte / Auszug aus dem Liegenschaftskataster</li><li>GeoViewer je Bundesland</li></ul><img src='%img_path%' class='prevent-tooltip information-image'><br><b class='tooltip-title tooltip-file-icon'>Was muss ich eintragen?</b><br>Den Nenner: tragen Sie hier den Zahlenwert hinter bzw. unter dem Bruchstrich ein. Nicht jedes Flurstückkennzeichen hat auch einen Flurstücknenner. Dieses Feld ist dann freizulassen.<br><br><b class='tooltip-title tooltip-mouse-icon'>Wie muss ich es eintragen?</b><br>Den Nenner (ohne Bruchstrich).<br><br><b>Beispiel</b><br>Sie finden in Ihrem Grundbuch den Wert 83/<b>194</b>. In diesem Fall müsste der Wert <b>194</b> eingetragen werden.<br><br><b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Mit Flurstück wird ein einzelnes Grundstück bezeichnet. Die Nummer eines Flurstücks besteht aus Zähler und Nenner. <br><br>Wichtig: Der gesamte Grundbesitz, der zum Beispiel mit einem Einfamilienhaus bebaut und im Übrigen als Garten genutzt wird, kann durchaus aus mehreren Flurstücken bestehen. In der Feststellungserklärung sind alle Flurstücke des Steuerpflichtigen separat anzugeben."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hilfe.grundsteuer-digital.de/faq/flurstueck-zaehler-nenner/"])}
        },
        "areaOfTheLand": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='tooltip-title tooltip-info-icon'>Informationen</b><br>Die amtliche Fläche ist die Gesamtfläche eines Grundstücks, welche sich unabhängig von der Art der Nutzung im eigenen Besitz befindet."])},
          "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      }
    },
    "ModalClientComment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück speichern"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie eine persönliche Nachricht für Ihr Steuerbüro hinzufügen?"])},
      "label_client_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Nachricht"])},
      "button_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
    },
    "ModalStepsHavingErrors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück speichern"])},
      "textOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevor Sie Ihr Grundstück speichern können, prüfen Sie bitte Ihre Formulareingaben in folgendem Bereich:"])},
      "textMulti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevor Sie Ihr Grundstück speichern können, prüfen Sie bitte Ihre Formulareingaben in folgenden Bereichen:"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
    },
    "SkendataModalStepsHavingErrors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liegenschaftsinformationen abrufen"])},
      "textOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevor Sie Liegenschaftsinformationen abrufen können, prüfen Sie bitte Ihre Formulareingaben in folgendem Bereich:"])},
      "textMulti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevor Sie Liegenschaftsinformationen abrufen können, prüfen Sie bitte Ihre Formulareingaben in folgenden Bereichen:"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
    },
    "ElsterModalStepsHavingErrors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELSTER-Prüfung"])},
      "textOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevor Sie die ELSTER-Prüfung durchführen können, prüfen Sie bitte Ihre Formulareingaben in folgendem Bereich:"])},
      "textMulti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevor Sie die ELSTER-Prüfung durchführen können, prüfen Sie bitte Ihre Formulareingaben in folgenden Bereichen:"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
    },
    "label_client_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Nachricht"])},
    "announcement_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis"])},
    "announcement_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Funktion steht in Kürze zur Verfügung."])},
    "from_your_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus Ihren Daten übernehmen"])},
    "propertyAddress": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksadresse"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse / Lagebezeichnung"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundesland"])},
      "commune": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinde"])},
      "communitySearchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Suchbegriff eingeben..."])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße / Lagebezeichnung"])},
      "houseno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer"])},
      "plz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
      "ort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
      "additional_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusatzangaben"])},
      "help_info": {
        "bayern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofern für Ihr Grundstück keine Straße und Hausnummer vorhanden sind, tragen sie bitte ein Flurstück unter “Gemarkung(en) und Flurstück(e)” mit folgenden Angaben ein: Gemarkung, Grundbuchblatt, Flurstück: Zähler und Flurstück: Nenner."])},
        "hamburg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofern für Ihr Grundstück keine Straße und Hausnummer vorhanden sind, tragen sie bitte ein Flurstück unter “Gemarkung(en) und Flurstück(e)” mit folgenden Angaben ein: Gemarkung, Grundbuchblatt und Flurstücksnummer."])},
        "niedersachsen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofern für Ihr Grundstück keine Straße und Hausnummer vorhanden sind, tragen sie bitte ein Flurstück unter “Gemarkung(en) und Flurstück(e)” mit folgenden Angaben ein: Gemarkung, Grundbuchblatt, Flur, Flurstück: Zähler und Flurstück: Nenner."])}
      },
      "messages": {
        "federal_state_uid_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es muss ein Bundesland ausgewählt werden."])},
        "street_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße / Lagebezeichnung darf höchstens 25 Zeichen lang sein."])},
        "house_number_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer darf höchstens 39 Zeichen lang sein."])},
        "zip_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl darf höchstens 5-stellig sein."])},
        "zip_integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl darf nur Ziffern enthalten."])},
        "city_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort darf höchstens 25 Zeichen lang sein."])},
        "additional_information_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusatzangaben darf höchstens 25 Zeichen lang sein."])}
      }
    },
    "propertyDetails": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktenzeichen / EW-AZ"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
      "economic_entity_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der wirtschaftlichen Einheit"])},
      "exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liegt eine Befreiung/Vergünstigung vor?"])},
      "extra_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Angaben"])},
      "tooltip_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Einheitswert-Aktenzeichen finden Sie auf dem Einheitswert- und Grundsteuermessbescheid. In manchen Bundesländern gibt es statt dem Einheitswert-Aktenzeichen eine Steuernummer. Dies gilt für Berlin, Bremen, Hamburg und Schleswig-Holstein."])},
      "tooltip_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auch bei der Grundsteuer gibt es Befreiungs- und Vergünstigungsmöglichkeiten. Diese beziehen sich im Wesentlichen auf den gemeinnützigen Bereich etwa von Stiftungen oder Vereinen und greifen dort auch nur, soweit es sich nicht um Grundbesitz für Wohnzwecke handelt."])},
      "tooltip_more_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigen Sie weitere Informationen?"])},
      "tooltip_eet_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befindet sich auf dem Grundstück ein nutzbares Gebäude, handelt es sich um ein bebautes Grundstück. Dabei darf das Grundstück nicht zu einem Betrieb der Land- und Forstwirtschaft gehören."])},
      "tooltip_eet_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofern sich kein nutzbares Gebäude auf dem Grundstück befindet, handelt es sich um ein unbebautes Grundstück. Dabei darf das Grundstück ebenso nicht zu einem Betrieb der Land- und Forstwirtschaft gehören."])},
      "tooltip_eet_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Betrieb der Land- und Forstwirtschaft besteht aus dem gesamten land- und forstwirtschaftlichen Vermögen."])},
      "info_ownership_structure_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feld Eigentumsverhältnisse im nächsten Tab Grundstück wurde automatisch auf die Option 'Alleineigentum einer natürlichen Person' voreingestellt."])},
      "info_ownership_structure_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte stellen Sie im Tab Grundstück in Feld Eigentumsverhältnisse die Option 'Alleineigentum einer natürlichen Person' ein, um einen abweichenden Eigentümer angeben zu können."])},
      "messages": {
        "reference_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktenzeichen / EW-AZ darf nicht leer sein."])},
        "reference_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktenzeichen / EW-AZ darf höchstens 30 Zeichen lang sein."])},
        "name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung darf nicht leer sein."])},
        "economic_entity_type_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es muss ein Eintrag ausgewählt werden."])},
        "deviating_owner_salutation_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es muss ein Eintrag ausgewählt werden."])},
        "deviating_owner_first_name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname muss angegeben werden."])},
        "deviating_owner_last_name_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname muss angegeben werden."])}
      }
    },
    "propertyMoreDetails": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück"])},
      "headline1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück"])},
      "ownership_structure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentumsverhältnisse"])},
      "info_ownership_structure_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einstellung 'Abweichender Eigentümer' in Tab Details wurde deaktiviert, da sie nur bei Auswahl von 'Alleineigentum einer natürlichen Person' möglich ist."])},
      "property_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art des Grundstücks"])},
      "development_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei unbebauten Grundstücken gegebenenfalls abweichender Entwicklungszustand"])},
      "multi_communities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstreckt sich das Gründstück über mehrere hebeberechtigte Gemeinden"])},
      "leasehold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist auf dem Grundstück ein Erbbaurecht bestellt?"])},
      "building_on_foreign_ground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Ihrem Grund und Boden wurde ein fremdes / wurden fremde Gebäude errichtet"])},
      "property_residential_purposes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist das Grundstück bebaut und wird überwiegend zu Wohnzwecken genutzt?"])},
      "buildings_on_third_party_owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr(e) Gebäude wurde(n) auf fremden Grund und Boden errichtet?"])},
      "lifting_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebesatz"])},
      "registration_land_registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antrag auf Neueintragung wurde beim Grundbuchamt eingereicht am"])},
      "total_property_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche des gesamten zur wirtschaftlichen Einheit gehörenden Anteils Grund und Bodens in m², die bebaut oder befestigt ist"])},
      "total_property_part_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Angabe bei mehr als 10.000 m² Grund und Bodenfläche"])},
      "area_of_the_land_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flächenangaben des Grundstücks"])},
      "area_of_the_land_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche des Grundstücks in m²"])},
      "area_of_the_land_value_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bodenrichtwert pro m²"])},
      "located_in_outdoor_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befindet sich dieses Grundstück im Außenbereich?"])},
      "area_of_the_land_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweite Fläche des Grundstücks in m²"])},
      "area_of_the_land_value_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bodenrichtwert pro m²"])},
      "boris_d_abfrage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BORIS-D-Abfrage starten"])},
      "add_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fläche hinzufügen"])},
      "sub_area": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf diesem Grund und Boden befindet sich eine selbstständig nutzbare Teilfläche"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Angaben dienen NUR unserer Berechnung und werden nicht nach Elster übertragen. Falls für Ihren Sachverhalt eine selbstständig nutzbare Teilfläche in Frage kommt, nutzen Sie bitte das Freitextfeld im Reiter &#8220;Details&#8221;, um eine Bearbeitung durch das zuständige Finanzamt sicherzustellen.<br><br>Wir bitten Sie, beim Vorliegen einer Teilfläche, den zugehörigen Bodenrichtwert anzugeben und durch Auswahl der Checkboxen zu präzisieren, unabhängig davon, ob dieser Eintrag deckungsgleich mit dem in den Flächenangaben des Grundstücks ist."])},
        "area_of_total_subarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche der gesamten Teilfläche in m²"])},
        "land_value_of_subarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bodenrichtwert der Teilfläche"])},
        "included_in_which_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enthalten in welcher Grundstücksfläche"])},
        "buildable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist die Teilfläche bebaubar?"])},
        "land_value_for_building_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist der angegebene BRW für Bauflächen?"])},
        "included_in_which_area_options": {
          "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Fläche"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweite Fläche"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beide Flächen"])}
        },
        "help": {
          "area_of_total_subarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tragen Sie bitte die m² für die gesamte selbstständig nutzbare Teilfläche ein"])},
          "land_value_of_subarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tragen Sie bitte den zutreffenden Bodenrichtwert für die Teilfläche hier ein (falls nur ein Bodenrichtwert vorhanden ist, tragen Sie ihn bitte trotzdem ein, da dieser Schritt der Berechnung von der Elsterübertragung losgelöst ist)."])},
          "included_in_which_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen Sie bitte mit diesem Feld Ihre Teilfläche einer Grundstücksfläche zu, damit wir in der Berechnung den angegebenen Flächenbetrag davon abgrenzen können."])},
          "buildable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn die Teilfläche eine bebaubare Teilfläche ist, ergibt sich der Bodenwert aus Fläche * BRW (für bebaubare Flächen)"])},
          "land_value_for_building_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn kein gesonderter Bodenrichtwert vorliegt, der die geringere Nutzbarkeit dieser Fläche berücksichtigt, ist der Umrechnungskoeffizient auf die gesamte Fläche anzuwenden."])}
        }
      },
      "tooltip_ownership_structure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wer Eigentümer ist, ergibt sich zum einen aus dem Grundbuchauszug (im Abschnitt „Erste Abteilung”). Dort sind die Eigentümer des Grundstücks mit ihrem Anteil genannt (z.B. ist bei Ehepartnern normalerweise jeder zu ½ Eigentümer). Zum anderen enthält auch der bisherige Einheitswert- und Grundsteuermessbescheid Angaben zu den Eigentümern. Die jeweiligen Anteile werden hier jedoch nicht genannt."])},
      "tooltip_property_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Art des Grundstücks entscheidet darüber, in welchem Verfahren der Grundsteuerwert berechnet wird. Die Gebäudeart entscheidet aber nicht nur über die Art der Bewertung, sondern spielt auch bei der Ermittlung der wirtschaftlichen Gesamtnutzungsdauer bzw. der Restnutzungsdauer eine Rolle. Sie können diese Information dem Kaufvertrag, Grundbuchauszug Einheitswertbescheid oder Grundsteuermessbescheid entnehmen."])},
      "tooltip_development_state_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rohbauland sind Flächen, die nach §§ 30, 33 und 34 BauGB für eine bauliche Nutzung bestimmt sind, deren Erschließung aber noch nicht gesichert ist oder die nach Lage, Form oder Größe für eine bauliche Nutzung unzureichend gestaltet sind."])},
      "tooltip_development_state_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bauerwartungsland sind solche Flächen, die planungsrechtlich für eine Bebauung zwar noch nicht bestimmt sind, bei denen aber damit zu rechnen ist, dass sie in absehbarer Zeit bebaut werden.<br>Bauerwartungsland liegt im so genannten Außenbereich."])},
      "tooltip_multi_communities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei größeren Grundstücken der Land- und Forstwirtschaft und bei Grundstücken in Randlagen kann es passieren, dass diese sich über mehrere Gemeindegebiete erstrecken. Das hat zur Folge, dass verschiedene Gemeinden für die Erhebung der Grundsteuer zuständig sind."])},
      "tooltip_leasehold_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Grundstücken, die mit einem Erbbaurecht belastet sind, bildet das Erbbaurecht zusammen mit dem belasteten Grundstück eine wirtschaftliche Einheit."])},
      "tooltip_leasehold_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Gesamtwert von Grund und Boden sowie Gebäude wird dem Erbbauberechtigten zugerechnet. Ihm gegenüber ergeht der Feststellungsbescheid über den Grundsteuerwert."])},
      "tooltip_lifting_rate_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Hebesatz ist ein Faktor, den die Gemeinde festlegt und der dazu dient, die tatsächliche Höhe einer Steuerschuld zu bestimmen. Hebesätze werden auf die Realsteuern (Gewerbesteuer, Grundsteuer) angewandt."])},
      "tooltip_lifting_rate_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gemeinde legt in ihrer Haushaltssatzung die Hebesätze für das jeweilige Haushaltsjahr fest, sie können sich also jährlich verändern."])},
      "tooltip_building_on_foreign_ground_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofern sämtliche Aufwendungen für die Herstellung eines Gebäudes auf einem Grundstück, dessen bürgerlich-rechtlicher Eigentümer ein anderer ist, von dem Steuerpflichtigen getragen werden, handelt es sich um ein Gebäude auf fremdem Grund und Boden. Steuerlich ist der Grund und Boden dem zivilrechtlichen Eigentümer, das Gebäude in diesem Fall dem wirtschaftlichen Eigentümer zuzurechnen."])},
      "tooltip_building_on_foreign_ground_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei einem Gebäude auf fremdem Grund und Boden ist für den Grund und Boden sowie für das Gebäude auf fremdem Grund und Boden ein Gesamtwert zu ermitteln. Der ermittelte Wert ist dem Eigentümer des Grund und Bodens zuzurechnen."])},
      "tooltip_total_property_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In vielen Bundesländern wird in die Berechnung des Grundsteuerwerts der Wert des Grundstücks mit einbezogen. Dieser ermittelt sich anhand der Grundstücksfläche und des Bodenrichtwerts."])},
      "tooltip_area_of_the_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tragen Sie bitte die (ggf. anteilige) Fläche des Flurstücks bzw. der Flurstücke in Quadratmetern ein, soweit sie zu dem Grundstück (der wirtschaftlichen Einheit) gehört. Sofern die Flächen mehrerer Flurstücke der wirtschaftlichen Einheit innerhalb einer Bodenrichtzone liegen, sind diese in einer Summe einzutragen."])},
      "tooltip_area_of_the_land_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In vielen Bundesländern wird in die Berechnung des Grundsteuerwerts der Wert des Grundstücks mit einbezogen. Dieser ermittelt sich anhand der Grundstücksfläche und des Bodenrichtwerts."])},
      "tooltip_more_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigen Sie weitere Informationen?"])},
      "tooltip_residential_purposes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld ist ausschließlich für das Landesmodel Baden-Württemberg relevant."])},
      "parcels": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemarkung(en) und Flurstück(e)"])},
        "itemheadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemarkung"])},
        "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinde"])},
        "parcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemarkung"])},
        "land_register_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbuchblatt"])},
        "corridor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flur"])},
        "area_of_the_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche in m²"])},
        "parcel_data_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flurstück: Zähler"])},
        "parcel_data_denominator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flurstück: Nenner"])},
        "share_of_ownership_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur wirtschaftlichen Einheit gehörender Anteil: Zähler"])},
        "share_of_ownership_denominator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur wirtschaftlichen Einheit gehörender Anteil: Nenner"])},
        "contained_in_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enthalten in welcher Fläche (welcher Bodenrichtwert zutreffend)?"])},
        "add_parcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere hinzufügen"])},
        "tooltip_parcel_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Gemarkung bezeichnet man die gesamte Fläche einer Gemeinde."])},
        "tooltip_parcel_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo ist diese Angabe zu finden?"])},
        "tooltip_parcel_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><ul><li>Grundbuchauszug</li><li>Bestandsnachweis</li><li>Auszug aus dem Liegenschaftskataster</li><li>Online abrufbar über Geo-Viewer</li></ul></p>"])},
        "tooltip_land_register_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedes Grundstück erhält im Grundbuch eine besondere Stelle, das Grundbuchblatt. Hier ist vermerkt, welche Flurstücke zu einem Grundstück gehören, wer Eigentümer ist und welche Belastungen (z.B. Grundschuld, Hypotheken) auf dem Grundstück liegen. Es handelt sich dabei um eine Nummer."])},
        "tooltip_corridor_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Flur ist ein Teil der Gemarkung und beschreibt die Nutzfläche einer Siedlung innerhalb einer Gemeinde."])},
        "tooltip_corridor_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo ist diese Angabe zu finden?"])},
        "tooltip_corridor_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><ul><li>Grundbuchauszug</li><li>Bestandsnachweis</li><li>Liegenschaftskarte / Auszug aus dem Liegenschaftskataster</li><li>Online abrufbar über Geo-Viewer</li></ul></p>"])},
        "tooltip_parcel_data_counter_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Flurstück wird ein einzelnes Grundstück bezeichnet. Die Nummer eines Flurstücks besteht aus Zähler und Nenner, also lautet zum Beispiel 83/194."])},
        "tooltip_parcel_data_counter_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtig: Der gesamte Grundbesitz, der zum Beispiel mit einem Einfamilienhaus bebaut und im Übrigen als Garten genutzt wird, kann durchaus aus mehreren Flurstücken bestehen. In der Feststellungserklärung sind alle Flurstücke des Steuerpflichtigen separat anzugeben."])},
        "tooltip_parcel_data_counter_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo ist diese Angabe zu finden?"])},
        "tooltip_parcel_data_counter_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><ul><li>Grundbuchauszug</li><li>Liegenschaftskarte / Auszug aus dem Liegenschaftskataster</li><li>GeoViewer je Bundesland</li></ul></p>"])},
        "tooltip_parcel_data_denominator_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Flurstück wird ein einzelnes Grundstück bezeichnet. Die Nummer eines Flurstücks besteht aus Zähler und Nenner, also lautet zum Beispiel 83/194."])},
        "tooltip_parcel_data_denominator_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtig: Der gesamte Grundbesitz, der zum Beispiel mit einem Einfamilienhaus bebaut und im Übrigen als Garten genutzt wird, kann durchaus aus mehreren Flurstücken bestehen. In der Feststellungserklärung sind alle Flurstücke des Steuerpflichtigen separat anzugeben."])},
        "tooltip_parcel_data_denominator_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo ist diese Angabe zu finden?"])},
        "tooltip_parcel_data_denominator_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><ul><li>Grundbuchauszug</li><li>Liegenschaftskarte / Auszug aus dem Liegenschaftskataster</li><li>GeoViewer je Bundesland</li></ul></p>"])},
        "tooltip_share_of_ownership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tragen Sie bitte den Anteil (z. B. 1/1 oder 1/2) des jeweiligen (Mit-)Eigentümers an der wirtschaftlichen Einheit ein. Die Anteile finden Sie z. B. im Grundbuch unter der Rubrik <q>Eigentümer</q> oder dem Katasterauszug bei <q>Angaben zum Eigentum</q>."])},
        "data_provisioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenbeschaffung"])},
        "added_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemarkungen wurden importiert."])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies kann einen Moment dauern"])},
        "data_acquisition_limit_error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion nicht freigeschaltet"])},
        "data_acquisition_limit_error_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Steuerberater hat eine Kostenbremse pro Monat aktiviert. Bitte informieren Sie ihn, den Monatsbetrag in den Systemeinstellungen zu erhöhen, damit Sie die Funktion nutzen können."])}
      },
      "help_info": {
        "bayern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofern für ihr Grundstück keine Straße und Hausnummer vorhanden sind, tragen sie bitte ein Flurstück unter “Gemarkung(en) und Flurstück(e)” mit folgenden Angaben ein: Gemarkung, Grundbuchblatt, Flurstück: Zähler und Flurstück: Nenner."])},
        "hamburg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofern für ihr Grundstück keine Straße und Hausnummer vorhanden sind, tragen sie bitte ein Flurstück unter “Gemarkung(en) und Flurstück(e)” mit folgenden Angaben ein: Gemarkung, Grundbuchblatt und Flurstücksnummer."])},
        "niedersachsen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofern für ihr Grundstück keine Straße und Hausnummer vorhanden sind, tragen sie bitte ein Flurstück unter “Gemarkung(en) und Flurstück(e)” mit folgenden Angaben ein: Gemarkung, Grundbuchblatt, Flur, Flurstück: Zähler und Flurstück: Nenner."])}
      },
      "messages": {
        "area_of_the_land_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche muss entweder leer sein oder im Bereich von 1 bis 999999999 liegen."])},
        "area_of_the_land_value_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bodenrichtwert muss entweder leer oder 0 sein oder im Bereich von 0,00 bis 999.999,99 (max. 2 Nachkommastellen) liegen."])},
        "area_of_the_land_value_bw_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bodenrichtwert muss entweder leer oder 0 sein oder im Bereich von 0,00 bis 999.999.999,99 (max. 2 Nachkommastellen) liegen."])},
        "land_register_sheet_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbuchblatt darf max. 6-stellig sein, wobei führend nur Ziffern und abschließend eine Ziffer oder ein Buchstabe zulässig sind."])},
        "total_property_part_integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Fläche muss als Ganzzahl ohne Nachkommastellen angegeben werden"])}
      }
    },
    "propertyBenefits": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befreiung / Vergünstigung"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befreiung / Vergünstigung"])},
      "subheadline1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerbefreiungen und Steuervergünstigungen"])},
      "tax_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerbefreiung"])},
      "benefit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergünstigung"])},
      "benefits_property_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art des Grundstücks"])},
      "non_separable_part_real_estate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein räumlich nicht abgrenzbarer Teil des Grundbesitzes unterliegt einer Grundsteuerbefreiung (steuerfreie Nutzung überwiegt)"])},
      "subheadline2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Angaben, wenn die Grundsteuervergünstigung 'Wohnraumförderung' vorliegt"])},
      "expiration_first_grant_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablaufdatum des zuerst auslaufenden Förderungszeitraum"])},
      "expiration_last_grant_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablaufdatum des zuletzt auslaufenden Förderungszeitraum"])},
      "subheadline3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Angaben, wenn ein Grundstück für steuerbefreite Zwecke hergerichtet wird"])},
      "expected_exempt_purposes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voraussichtliche Verwendung für steuerbegünstigte Zwecke in %"])},
      "tax_privileged_purposes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerbefreiung"])},
      "tooltip_tax_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auch bei der Grundsteuer gibt es Befreiungs- und Vergünstigungsmöglichkeiten. Diese beziehen sich im Wesentlichen auf den gemeinnützigen Bereich etwa von Stiftungen oder Vereinen und greifen dort auch nur, soweit es sich nicht um Grundbesitz für Wohnzwecke handelt."])},
      "tooltip_benefit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auch bei der Grundsteuer gibt es Befreiungs- und Vergünstigungsmöglichkeiten. Diese beziehen sich im Wesentlichen auf den gemeinnützigen Bereich etwa von Stiftungen oder Vereinen und greifen dort auch nur, soweit es sich nicht um Grundbesitz für Wohnzwecke handelt."])},
      "tooltip_more_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigen Sie weitere Informationen?"])},
      "tooltip_benefit_property_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Art des Grundstücks entscheidet darüber, in welchem Verfahren der Grundsteuerwert berechnet wird. Die Gebäudeart entscheidet aber nicht nur über die Art der Bewertung, sondern spielt auch bei der Ermittlung der wirtschaftlichen Gesamtnutzungsdauer bzw. der Restnutzungsdauer eine Rolle. Sie können diese Information dem Kaufvertrag, Grundbuchauszug Einheitswertbescheid oder Grundsteuermessbescheid entnehmen."])},
      "monumentIdentification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist das Grundstück durch denkmalgeschützte Gebäude oder Gebäudeteile (§ 15 Absatz 5 GrStG) begünstigt?"])},
      "group1": {
        "groupheadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei unbebauten Grundstücken mit teilweiser Grundsteuerbefreiung"])},
        "itemheadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befreiung"])},
        "unbuilt_real_estate_exemption_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
        "tax_free_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerfreie Fläche in m²"])},
        "unbuilt_real_estate_tax_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerbefreiung"])},
        "add_undeveloped_land_with_partial_property_tax_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Befreiung hinzufügen"])}
      },
      "group2": {
        "groupheadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendung eines räumlich abgrenzbaren Teils des Grund und Bodens für steuerbefreite Zwecke"])},
        "itemheadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befreiung"])},
        "spatially_definable_exemption_part_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
        "spatially_definable_exemption_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerfreie Fläche in m²"])},
        "spatially_definable_tax_exemption_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerbefreiung"])},
        "add_definable_part_of_the_land_for_exempt_purposes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Befreiung hinzufügen"])}
      },
      "group3": {
        "groupheadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für Teile des Grundbesitzes liegen die Voraussetzungen für eine Steuervergünstigung vor"])},
        "itemheadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergünstigung"])},
        "spatially_definable_exemption_part_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
        "spatially_definable_exemption_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergünstigte Fläche in m²"])},
        "spatially_definable_tax_exemption_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergünstigung"])},
        "add_tax_relief_for_parts_of_the_property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Vergünstigung hinzufügen"])}
      },
      "property_exemption_benefit_type_options": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etwas_anderes.png"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfamilienhaus"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einfamilienhaus.png"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifamilienhaus"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doppelhaushälfte.png"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietwohngrundstück"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eigentumswohnung.png"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentum"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
          "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehrfamilienhaus.png"])}
        }
      ]
    },
    "propertyCommunity": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinschaft"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinschaft"])},
      "infoTextHtml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Bei den folgenden Eigentumsverhältnissen füllen Sie bitte den Reiter <q>Gemeinschaft</q> aus:</p><ul><li>Erbengemeinschaft</li><li>Bruchteilsgemeinschaft</li><li>Grundstücksgemeinschaft ohne geschäftsüblichen Namen</li></ul>"])},
      "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Gemeinschaft"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
      "house_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer"])},
      "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
      "postbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postfach"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "messages": {
        "name_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name darf höchstens 50 Zeichen lang sein."])},
        "street_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße darf höchstens 25 Zeichen lang sein."])},
        "house_number_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer darf höchstens 39 Zeichen lang sein."])},
        "zip_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl darf höchstens 5-stellig sein."])},
        "zip_integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl darf nur Ziffern enthalten."])},
        "postbox_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postfach darf höchstens 6-stellig sein."])},
        "postbox_integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postfach darf nur Ziffern enthalten."])},
        "city_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort darf höchstens 25 Zeichen lang sein."])}
      }
    },
    "propertyOwnership": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentümer"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentümer"])},
      "infoTextHtml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Bei den folgenden Eigentumsverhältnissen füllen Sie bitte den Reiter <q>Eigentümer / Beteiligte</q> aus:</p><ul><li>Ehegatten / Lebenspartner</li><li>Erbengemeinschaft</li><li>Bruchteilsgemeinschaft</li><li>Grundstücksgemeinschaft mit geschäftsüblichen Namen (zum Beispiel OHG oder KG)</li></ul>"])},
      "subheadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben zu weiteren Eigentümern/Beteiligte"])},
      "group1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentümer/Beteiligte"])},
      "tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Ehe- oder Lebenspartnern legen Sie hier bitte beide Partner als Eigentümer an."])},
      "tooltip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigen Sie weitere Informationen?"])},
      "salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel/akademischer Grad"])},
      "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname/Firma"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name/Firma"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
      "houseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
      "postbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postfach"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundesland"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "financeAgency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnsitz-/Betriebsstätten-Finanzamt"])},
      "taxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer"])},
      "taxIdentificationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
      "group2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteil am Grundstück"])},
      "area_of_the_land_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteil Zähler"])},
      "area_of_the_land_denominator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteil Nenner"])},
      "group3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesetzlich vertreten durch:"])},
      "rep_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liegt eine gesetzliche Vertretung für Eigentümer %index% vor?"])},
      "rep_salutation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
      "rep_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel/akademischer Grad"])},
      "rep_dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
      "rep_firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname/Firma"])},
      "rep_lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name/Firma"])},
      "rep_street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
      "rep_houseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer"])},
      "rep_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "rep_zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
      "rep_postbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postfach"])},
      "rep_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
      "rep_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "salutation_options": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Anrede"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herrn"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbengemeinschaft"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsgemeinschaft"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksgemeinschaft"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft bürgerlichen Rechts"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sozietät"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praxisgemeinschaft"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsgemeinschaft"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentümergemeinschaft"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnergesellschaft"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenreederei"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17"])}
        }
      ],
      "salutation_user_options": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Anrede"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herrn"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herrn und Frau"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herrn und Herrn"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau und Frau"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
        }
      ],
      "messages": {
        "title_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel darf höchstens 15 Zeichen lang sein."])},
        "firstName_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname/Firma darf nicht leer sein."])},
        "firstName_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname/Firma darf höchstens 25 Zeichen lang sein."])},
        "lastName_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name/Firma darf höchstens 25 Zeichen lang sein."])},
        "street_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße darf höchstens 25 Zeichen lang sein."])},
        "houseNumber_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer darf höchstens 25 Zeichen lang sein."])},
        "phone_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer darf höchstens 25 Zeichen lang sein."])},
        "zip_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ darf höchstens 12 Zeichen lang sein."])},
        "postbox_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postfach darf höchstens 6 Zeichen lang sein."])},
        "city_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt darf höchstens 25 Zeichen lang sein."])},
        "financeAgency_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzamt darf höchstens 25 Zeichen lang sein."])},
        "taxNumber_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer darf höchstens 13 Zeichen lang sein."])},
        "taxIdentificationNumber_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer darf höchstens 11 Zeichen lang sein."])}
      }
    },
    "propertyBuildings": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude(-teile)"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude(-teile)"])},
      "group1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude(-teile)"])},
      "livingSpaceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
      "livingSpace_tooltip_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnflächen sind Flächen, die zu Wohnzwecken dienen. Ein häusliches Arbeitszimmer (Homeoffice) gilt als Wohnfläche."])},
      "livingSpace_tooltip_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Wohnfläche ergibt sich aus der Wohnflächenberechnung. Liegt eine Wohnflächenberechnung nicht vor, ist die Wohnfläche nach Wohnflächenverordnung (WoFIV) zu ermitteln."])},
      "livingSpace_tooltip_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo ist diese Angabe zu finden?"])},
      "livingSpace_tooltip_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><ul><li>Kaufvertrag</li><li>Bauplan</li><li>Beauftragtes Architektenbüro</li></ul></p>"])},
      "livingSpace_tooltip_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über wen kann ich sie berechnen lassen:"])},
      "livingSpace_tooltip_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><ul><li>Vermessungsingenieure</li></ul></p>"])},
      "tooltip_more_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigen Sie weitere Informationen?"])},
      "livingSpaceArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnfläche in m²"])},
      "usableSpace_tooltip_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Nutzflächen werden Flächen bezeichnet, die insbesondere betrieblichen Zwecken dienen und die keine Wohnflächen sind. Dazu zählen zum Beispiel Werkstätten, Verkaufsräume, Büros. Für die Nutzfläche kommt DIN 277 zur Anwendung."])},
      "usableSpace_tooltip_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "usableSpace_tooltip_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo ist diese Angabe zu finden?"])},
      "usableSpace_tooltip_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><ul><li>Kaufvertrag</li><li>Bauplan</li><li>Beauftragtes Architektenbüro</li></ul></p>"])},
      "usableSpace_tooltip_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über wen kann ich sie berechnen lassen:"])},
      "usableSpace_tooltip_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><ul><li>Vermessungsingenieure</li></ul></p>"])},
      "usableSpaceArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzfläche in m²"])},
      "areaOfTheLandCivilDefense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäudeflächen in m², die dem Zivilschutz dienen"])},
      "civilDefenseInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohn- / Nutzungsflächen in m², die dem Zivilschutz dienen (sind in den erklärten Flächen zu Gebäuden/Gebäudeteilen enthalten)"])},
      "group2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergünstigung"])},
      "exemptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
      "exemptionLivingSpaceArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnfläche in m²"])},
      "exemptionUsableSpaceArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzfläche in m²"])},
      "exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befreiung"])},
      "add_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Befreiung hinzufügen"])},
      "group3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befreiung"])},
      "benefitDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
      "benefitLivingSpaceArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnfläche in m²"])},
      "benefitUsableSpaceArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzfläche in m²"])},
      "benefit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergünstigung"])},
      "add_benefit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Vergünstigung hinzufügen"])},
      "add_more_buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Gebäude(-teile) hinzufügen"])},
      "exemption_options": {
        "Bundesmodell": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach bundesrechtlichen Wohnraumförderungsgesetz (§ 15 Absatz 2 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach landesrechtlichen Wohnraumförderungsgesetzen (§ 15 Absatz 3 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungsbaugesellschaft von Gebietskörperschaften (§ 15 Absatz 4 Satz 1 Nummer 1 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige Wohnungsbaugesellschaft (§ 15 Absatz 4 Satz 1 Nummer 2 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körperschaftsteuerbefreite Genossenschaften und Vereine (§ 15 Absatz 4 Satz 1 Nummer 3 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denkmalgeschützte Gebäude und Gebäudeteile (§ 15 Absatz 5 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
          }
        ],
        "Modifiziertes Bodenwertmodell": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach bundesrechtlichen Wohnraumförderungsgesetz (§ 15 Absatz 2 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach landesrechtlichen Wohnraumförderungsgesetzen (§ 15 Absatz 3 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungsbaugesellschaft von Gebietskörperschaften (§ 15 Absatz 4 Satz 1 Nummer 1 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige Wohnungsbaugesellschaft (§ 15 Absatz 4 Satz 1 Nummer 2 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körperschaftsteuerbefreite Genossenschaften und Vereine (§ 15 Absatz 4 Satz 1 Nummer 3 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denkmalgeschützte Gebäude und Gebäudeteile (§ 15 Absatz 5 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
          }
        ],
        "Flächen-Modell": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Wohnzwecke mit enger räumlicher Verbindung zu einem Betrieb der Land- und Forstwirtschaft (Artikel 4 Absatz 2 Bayerisches Grundsteuergesetz)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Baudenkmal nach Artikel 1 Absatz 2 Satz 1 oder Absatz 3 (Ensembleschutz) Bayerisches Denkmalschutzgesetz (Artikel 4 Absatz 3 Bayerisches Grundsteuergesetz)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Staatlichen oder kommunalen Wohnraumförderung den Bindungen des sozialen Wohnungsbaus (Artikel 4 Absatz 4 Nummer 1 Bayerisches Grundsteuergesetz)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Wohnungsbaugesellschaft von Gebietskörperschaften (Artikel 4 Absatz 4 Nummer 2 Bayerisches Grundsteuergesetz i. V. m. § 15 Absatz 4 Satz 1 Nummer 1 Grundsteuergesetz)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Gemeinnützige Wohnungsbaugesellschaft (Artikel 4 Absatz 4 Nummer 2 Bayerisches Grundsteuergesetz i. V. m. § 15 Absatz 4 Satz 1 Nummer 2 Grundsteuergesetz)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. Körperschaftsteuerbefreite Genossenschaften und Vereine (Artikel 4 Absatz 4 Nummer 2 Bayerisches Grundsteuergesetz i. V. m. § 15 Absatz 4 Satz 1 Nummer 3 Grundsteuergesetz)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 2 (Denkmalschutz) dieser Liste liegen gleichzeitig vor (Artikel 4 Absatz 2 und 3 Bayerisches Grundsteuergesetz)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 3 (Wohnraumförderung) dieser Liste liegen gleichzeitig vor (Artikel 4 Absatz 2 und 4 Nummer 1 Bayerisches Grundsteuergesetz)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 4 (Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 5 (gemeinnützige Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 6 (körperschaftsteuerbefreite Genossenschaft oder Verein) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 3 (Wohnraumförderung) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 4 (Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 5 (gemeinnützige Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 6 (körperschaftsteuerbefreite Genossenschaft oder Verein) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16. Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 3 (Wohnraumförderung) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17. Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 4 (Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18. Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 5 (gemeinnützige Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19. Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 6 (körperschaftsteuerbefreite Genossenschaft oder Verein) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19"])}
          }
        ],
        "Wohnlagen-Modell": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Auf dem Grundstück befindet sich ein Baudenkmal (§4 Abs.3 Hamburgisches Grundsteuergesetz)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Wohnraumförderung (§ 4 Abs. 4 Nr. 1 Hamburgisches Grundsteuergesetz)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Wohnraumförderung (§ 4 Abs. 4 Nr. 2 Hamburgisches Grundsteuergesetz)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Förderung nach § 88d des Zweiten Wohnungsbaugesetzes in der bis zum 31. Dezember 2001 geltenden Fassung (§ 4 Abs. 4 Nr. 3 Hamburgisches Grundsteuergesetz)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Die Wohnflächen gelten nach dem Hamburgischen Wohnungsbindungsgesetz als öffentlich gefördert (§ 4 Abs. 4 Nr. 4 Hamburgisches Grundsteuergesetz)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. Die Voraussetzungen der Nummern 1 (Denkmalschutz) und 2 (Hamburgisches Wohnraumförderungsgesetz) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. Die Voraussetzungen der Nummern 1 (Denkmalschutz) und 3 (Wohnraumförderungsgesetz) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. Die Voraussetzungen der Nummern 1 (Denkmalschutz) und 4 (Zweites Wohnungsbaugesetz) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. Die Voraussetzungen der Nummern 1 (Denkmalschutz) und 5 (Hamburgisches Wohnungsbindungsgesetz) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
          }
        ],
        "Flächen-Faktor-Modell": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach bundesrechtlichen Wohnraumförderungsgesetz (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 4 Satz 1 Nummer 2 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach landesrechtlichen Wohnraumförderungsgesetzen (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 4 Satz 1 Nummer 3 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungsbaugesellschaft von Gebietskörperschaften (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 2 GrStG) iVm denkmalgeschützte Gebäude und Gebäudeteile (§ 6 Absatz 3 HGrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige Wohnungsbaugesellschaft (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 4 Satz 1 Nummer 2 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körperschaftsteuerbefreite Genossenschaften und Vereine (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 4 Satz 1 Nummer 3 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denkmalgeschützte Gebäude und Gebäudeteile (§ 6 Absatz 3 HGrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach bundesrechtlichen Wohnraumförderungsgesetz § 6 Absatz 4 HGrStG iVm § 15 Absatz 2 GrStG) iVm denkmalgeschützte Gebäude und Gebäudeteile (§ 6 Absatz 3 HGrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach landesrechtlichen Wohnraumförderungsgesetzen (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 3 GrStG) i.V.m denkmalgeschützte Gebäude und Gebäudeteile (§ 6 Absatz 3 HGrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungsbaugesellschaft von Gebietskörperschaften (§ 15 Absatz 4 Satz 1 Nummer 1 GrStG) i.V.m denkmalgeschützte Gebäude und Gebäudeteile (§ 15 Absatz 5 GrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige Wohnungsbaugesellschaft (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 4 Satz 1 Nummer 2 GrStG) i.V.m denkmalgeschützte Gebäude und Gebäudeteile (§ 6 Absatz 3 HGrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körperschaftsteuerbefreite Genossenschaften und Vereine (§ 6 Absatz 4 HGrStG iVm § 15 Absatz 4 Satz 1 Nummer 3 GrStG) i.V.m denkmalgeschützte Gebäude und Gebäudeteile (§ 6 Absatz 3 HGrStG)"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
          }
        ],
        "Flächen-Lage-Modell": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Wohnzwecke mit enger räumlicher Verbindung zu einem Betrieb der Land- und Forstwirtschaft"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Baudenkmal"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Staatlichen oder kommunalen Wohnraumförderung den Bindungen des sozialen Wohnungsbaus"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Wohnungsbaugesellschaft von Gebietskörperschaften"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Gemeinnützige Wohnungsbaugesellschaft"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. Körperschaftsteuerbefreite Genossenschaften und Vereine"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 2 (Denkmalschutz) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 3 (Wohnraumförderung) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 4 (Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 5 (gemeinnützige Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11. Die Voraussetzungen der Nummern 1 (Wohnteil LuF) und 6 (körperschaftsteuerbefreite Genossenschaft oder Verein) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 3 (Wohnraumförderung) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 4 (Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 5 (gemeinnützige Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15. Die Voraussetzungen der Nummern 2 (Denkmalschutz) und 6 (körperschaftsteuerbefreite Genossenschaft oder Verein) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16. Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 3 (Wohnraumförderung) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17. Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 4 (Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18 .Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 5 (gemeinnützige Wohnungsbaugesellschaft) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19. Die Voraussetzungen der Nummern 1 (Wohnteil LuF), 2 (Denkmalschutz) und 6 (körperschaftsteuerbefreite Genossenschaft oder Verein) dieser Liste liegen gleichzeitig vor"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19"])}
          }
        ]
      },
      "exemption_options_active": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigengewerbliche Nutzung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremdgewerbliche Nutzung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigene Wohnzwecke"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremde Wohnzwecke"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-4"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremde öffentliche Zwecke"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-5"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für öffentlichen Dienst/Gebrauch (jPdöR)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundeseisenbahnvermögen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige/mildtätige Zwecke"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religiöse Zwecke"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstwohnung Geistliche"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religionsgem. Besoldung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gottesdienst"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestattungsplatz"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentlicher Verkehr"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flugbetrieb"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fließende Gewässer"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasser-/Bodenverbände (öR)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wissenschaft/Unterricht/Erziehung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankenhaus"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplomatische Vertretung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
        }
      ],
      "fixed_exemption_options": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für öffentlichen Dienst/Gebrauch (jPdöR)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundeseisenbahnvermögen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige/mildtätige Zwecke"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religiöse Zwecke"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstwohnung Geistliche"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religionsgem. Besoldung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gottesdienst"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestattungsplatz"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentlicher Verkehr"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flugbetrieb"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fließende Gewässer"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasser-/Bodenverbände (öR)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wissenschaft/Unterricht/Erziehung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankenhaus"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplomatische Vertretung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
        }
      ],
      "benefit_options": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach bundesrechtlichen Wohnraumförderungsgesetz (§ 15 Absatz 2 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach landesrechtlichen Wohnraumförderungsgesetzen (§ 15 Absatz 3 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungsbaugesellschaft von Gebietskörperschaften (§ 15 Absatz 4 Satz 1 Nummer 1 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige Wohnungsbaugesellschaft (§ 15 Absatz 4 Satz 1 Nummer 2 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körperschaftsteuerbefreite Genossenschaften und Vereine (§ 15 Absatz 4 Satz 1 Nummer 3 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denkmalgeschützte Gebäude und Gebäudeteiles (§ 15 Absatz 5 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
        }
      ],
      "messages": {
        "livingSpaceDescription_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung darf höchstens 25 Zeichen lang sein."])},
        "livingSpaceDescription_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung darf nicht leer sein."])},
        "livingSpaceArea_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnfläche überschreitet zulässigen Maximalwert."])},
        "usableSpaceArea_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzfläche überschreitet zulässigen Maximalwert"])},
        "livingSpaceArea_one_of_two_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnfläche ist erforderlich, wenn keine Nutzfläche angegeben ist."])},
        "usableSpaceArea_one_of_two_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzfläche ist erforderlich, wenn keine Wohnfläche angegeben ist."])},
        "areaOfTheLandCivilDefense_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäudefläche überschreitet zulässigen Maximalwert."])},
        "benefitDescription_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung darf höchstens 25 Zeichen lang sein."])},
        "benefitLivingSpaceArea_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnfläche überschreitet zulässigen Maximalwert."])},
        "benefitUsableSpaceArea_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzfläche überschreitet zulässigen Maximalwert"])},
        "benefitLivingSpaceArea_one_of_two_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnfläche ist erforderlich, wenn keine Nutzfläche angegeben ist."])},
        "benefitUsableSpaceArea_one_of_two_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzfläche ist erforderlich, wenn keine Wohnfläche angegeben ist."])},
        "exemptionDescription_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung darf höchstens 25 Zeichen lang sein."])},
        "exemptionLivingSpaceArea_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnfläche überschreitet zulässigen Maximalwert."])},
        "exemptionUsableSpaceArea_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzfläche überschreitet zulässigen Maximalwert"])},
        "exemptionLivingSpaceArea_one_of_two_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnfläche ist erforderlich, wenn keine Nutzfläche angegeben ist."])},
        "exemptionUsableSpaceArea_one_of_two_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzfläche ist erforderlich, wenn keine Wohnfläche angegeben ist."])}
      }
    },
    "propertyResidential": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohngrundstücke"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohngrundstücke"])},
      "group1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohngrundstück"])},
      "sectionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Angaben zum Wohngrundstück treffen zu? Bitte wählen Sie aus (Mehrfachauswahl möglich)."])},
      "sectionGarage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Wohngrundstück besitzt Garagen- und Tiefgaragenstellplätze."])},
      "sectionBelow60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Wohnflächen der Wohnungen liegen unter 60m²."])},
      "sectionBetween60100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Wohnflächen der Wohnungen liegen zwischen 60 m² und unter 100 m²."])},
      "sectionAbove100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Wohnflächen der Wohnungen liegen bei 100 m² und mehr."])},
      "sectionOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind weitere Wohnungen vorhanden."])},
      "sectionUsableSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt weitere Nutzflächen, die keine Wohnflächen sind (ohne Zubehörräume wie zum Beispiel Keller, Waschküche)."])},
      "general_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Angaben"])},
      "year_of_construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baujahr des Gebäudes (Zeitpunkt der Bezugsfertigkeit)"])},
      "year_of_construction_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Baujahr eines Gebäudes gilt das Jahr, in dem es bezugsfertig ist."])},
      "year_of_construction_tooltip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo ist diese Angabe zu finden?"])},
      "year_of_construction_tooltip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><ul><li>Kaufvertrag</li><li>Jahr der Bezugsfertigkeit</li></ul></p>"])},
      "tooltip_more_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigen Sie weitere Informationen?"])},
      "core_renovation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es erfolgte eine Kernsanierung, die abgeschlossen wurde im Jahr"])},
      "core_renovation_year_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Kernsanierung liegt vor, wenn zum einen der Ausbau (u. a. Heizung, Fenster und Sanitäreinrichtungen) umfassend modernisiert und zum anderen der Rohbau teilweise oder ganz erneuert worden ist. Durch eine Kernsanierung erhält das Gebäude einen Zustand, der dem eines neuen Gebäudes nahezu entspricht."])},
      "core_renovation_year_tooltip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo ist diese Angabe zu finden?"])},
      "core_renovation_year_tooltip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><ul><li>Kaufvertrag</li><li>Jahr der Bezugsfertigkeit</li></ul></p>"])},
      "core_renovation_year_tooltip4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Jahr der Kernsanierung gilt das Jahr, in dem die Kernsanierung abgeschlossen wird. Dieses ergibt sich zum Beispiel aus den Unterlagen zur Durchführung der Sanierung."])},
      "demolition_obligation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es besteht eine Abbruchverpflichtung im Jahr"])},
      "demolition_obligation_year_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine bestehende Abbruchverpflichtung führt zu einer Verkürzung der Restnutzungsdauer und damit unter dem Strich zu einer Verringerung der Grundsteuer."])},
      "garage_underground_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garagen- und Tiefgaragenstellplätze"])},
      "amount_garages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Garagen-/Tiefgaragenstellplätze"])},
      "amount_garages_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier ist die Gesamtanzahl der auf dem Grundstück vorhandenen Garagen- und Tiefgaragenstellplätze anzugeben."])},
      "amount_garages_tooltip1a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellplätze im Freien und Carports sind nicht einzutragen."])},
      "amount_garages_tooltip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo ist diese Angabe zu finden?"])},
      "amount_garages_tooltip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><ul><li>Kaufvertrag</li></ul></p>"])},
      "exemption_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auch bei der Grundsteuer gibt es Befreiungs- und Vergünstigungsmöglichkeiten. Diese beziehen sich im Wesentlichen auf den gemeinnützigen Bereich etwa von Stiftungen oder Vereinen und greifen dort auch nur, soweit es sich nicht um Grundbesitz für Wohnzwecke handelt."])},
      "location_rooms_designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lage der Räume / Bezeichnung"])},
      "tax_free_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerfreie Fläche in m²"])},
      "add_more_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Befreiung hinzufügen"])},
      "add_more_residential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiteres Wohngrundstück hinzufügen"])},
      "add_more_usable_space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Nutzfläche hinzufügen"])},
      "exemption_options": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für öffentlichen Dienst/Gebrauch (jPdöR)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundeseisenbahnvermögen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige/mildtätige Zwecke"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religiöse Zwecke"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstwohnung Geistliche"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religionsgem. Besoldung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gottesdienst"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wissenschaft/Unterricht/Erziehung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplomatische Vertretung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
        }
      ],
      "benefit_options": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach bundesrechtlichen Wohnraumförderungsgesetz (§ 15 Absatz 2 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach landesrechtlichen Wohnraumförderungsgesetzen (§ 15 Absatz 3 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungsbaugesellschaft von Gebietskörperschaften (§ 15 Absatz 4 Satz 1 Nummer 1 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige Wohnungsbaugesellschaft (§ 15 Absatz 4 Satz 1 Nummer 2 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körperschaftsteuerbefreite Genossenschaften und Vereine (§ 15 Absatz 4 Satz 1 Nummer 3 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denkmalgeschützte Gebäude und Gebäudeteiles (§ 15 Absatz 5 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
        }
      ],
      "garage_underground_benefit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergünstigung"])},
      "benefit_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auch bei der Grundsteuer gibt es Befreiungs- und Vergünstigungsmöglichkeiten. Diese beziehen sich im Wesentlichen auf den gemeinnützigen Bereich etwa von Stiftungen oder Vereinen und greifen dort auch nur, soweit es sich nicht um Grundbesitz für Wohnzwecke handelt."])},
      "discounted_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergünstigte Fläche in m²"])},
      "add_more_benefit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Vergünstigung hinzufügen"])},
      "apartments_below_60_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungen unter 60 m²"])},
      "apartments_below_60_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Wohnungen mit Wohnfläche unter 60 m²"])},
      "apartments_below_60_living_space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtgröße der Wohnungen mit Wohnfläche unter 60 m²"])},
      "exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befreiung"])},
      "benefit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergünstigung"])},
      "apartments_between_60100_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungen mit einer Wohnfläche von 60 m² bis unter 100 m²"])},
      "apartments_between_60100_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Wohnungen mit einer Wohnfläche von 60 m² bis unter 100 m²"])},
      "apartments_between_60100_living_space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtgröße der Wohnungen mit einer Wohnfläche von 60 m² bis unter 100 m²"])},
      "apartments_above_100_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungen mit einer Wohnfläche von 100 m² und mehr"])},
      "apartments_above_100_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Wohnungen mit einer Wohnfläche von 100 m² und mehr"])},
      "apartments_above_100_living_space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtgröße der Wohnungen mit einer Wohnfläche von 100 m² und mehr"])},
      "apartments_other_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weitere Wohnungen"])},
      "apartments_other_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Wohnungen, die nicht in anderen Feldern enthalten sind"])},
      "apartments_other_living_space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtgröße der Wohnungen, die nicht in anderen Feldern enthalten sind"])},
      "usable_spaces_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Nutzflächen, die keine Wohnflächen sind (ohne Zubehörräume wie zum Beispiel Keller, Waschküche)"])},
      "usable_spaces_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzfläche"])},
      "usable_spaces_usage_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzung"])},
      "usable_spaces_total_spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzfläche in m²"])},
      "messages": {
        "residential_yearOfConstruction_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Jahreszahl darf höchstens 4-stellig sein."])},
        "residential_yearOfConstruction_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Jahreszahl darf nicht negativ sein."])},
        "residential_coreRenovationYear_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Jahreszahl darf höchstens 4-stellig sein."])},
        "residential_coreRenovationYear_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Jahreszahl darf nicht negativ sein."])},
        "residential_demolitionObligationYear_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Jahreszahl darf höchstens 4-stellig sein."])},
        "residential_demolitionObligationYear_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Jahreszahl darf nicht negativ sein."])},
        "garageUnderground_amountGarages_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl darf höchstens 4-stellig sein."])},
        "garageUnderground_amountGarages_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl darf nicht negativ sein."])},
        "locationOfTheRoomsDesignation_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe darf höchstens 25 Zeichen lang sein."])},
        "taxFreeArea_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche überschreitet den zulässigen Maximalwert."])},
        "discountedArea_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche überschreitet den zulässigen Maximalwert."])},
        "counter_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe überschreitet den zulässigen Maximalwert."])},
        "totalLivingSpace_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe überschreitet den zulässigen Maximalwert."])},
        "usageType_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe darf höchstens 25 Zeichen lang sein."])},
        "totalSpace_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe überschreitet den zulässigen Maximalwert."])}
      }
    },
    "propertyNonResidential": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nichtwohngrundstücke"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nichtwohngrundstücke"])},
      "group1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nichtwohngrundstück"])},
      "location_plan_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Lageplan-)Nummer"])},
      "location_plan_number_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Lageplan wird auf der Grundlage amtlicher Katasterunterlagen, eigener Vermessungen des Baugrundstücks sowie der näheren Umgebung und durch die Einarbeitung bestehender baurechtlicher Vorgaben erstellt. Der Lageplan stellt insofern ein Abbild der Örtlichkeit dar und gibt andererseits die Möglichkeit, unter Beachtung des baulich Machbaren die rechtlich zulässigen Planungen festzulegen."])},
      "buildingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäudeart"])},
      "buildingType_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gebäudeart entscheidet nicht nur über die Art der Bewertung, sondern spielt auch bei der Ermittlung der wirtschaftlichen Gesamtnutzungsdauer bzw. der Restnutzungsdauer eine Rolle."])},
      "buildingType_tooltip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo ist diese Angabe zu finden?"])},
      "buildingType_tooltip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><ul><li>Kaufvertrag</li><li>Bauantrag</li></ul></p>"])},
      "year_of_construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baujahr"])},
      "year_of_construction_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Baujahr eines Gebäudes gilt das Jahr, in dem es bezugsfertig ist."])},
      "year_of_construction_tooltip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo ist diese Angabe zu finden?"])},
      "year_of_construction_tooltip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><ul><li>Kaufvertrag</li><li>Jahr der Bezugsfertigkeit</li></ul></p>"])},
      "tooltip_more_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigen Sie weitere Informationen?"])},
      "core_renovation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es erfolgte eine Kernsanierung, die abgeschlossen wurde im Jahr"])},
      "core_renovation_year_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Kernsanierung liegt vor, wenn zum einen der Ausbau (u. a. Heizung, Fenster und Sanitäreinrichtungen) umfassend modernisiert und zum anderen der Rohbau teilweise oder ganz erneuert worden ist. Durch eine Kernsanierung erhält das Gebäude einen Zustand, der dem eines neuen Gebäudes nahezu entspricht."])},
      "core_renovation_year_tooltip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo ist diese Angabe zu finden?"])},
      "core_renovation_year_tooltip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><ul><li>Kaufvertrag</li><li>Jahr der Bezugsfertigkeit</li></ul></p>"])},
      "core_renovation_year_tooltip4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Jahr der Kernsanierung gilt das Jahr, in dem die Kernsanierung abgeschlossen wird. Dieses ergibt sich zum Beispiel aus den Unterlagen zur Durchführung der Sanierung."])},
      "demolition_obligation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es besteht eine Abbruchverpflichtung im Jahr"])},
      "demolition_obligation_year_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine bestehende Abbruchverpflichtung führt zu einer Verkürzung der Restnutzungsdauer und damit unter dem Strich zu einer Verringerung der Grundsteuer."])},
      "areaOfTheLand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttogrundfläche in m²"])},
      "areaOfTheLandCivilDefense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davon für den Zivilschutz in m²"])},
      "civilDefenseInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für den Zivilschutz genutzte Gebäude, Gebäudeteile und Anlagen bleiben bei der Ermittlung des Grundsteuerwerts außer Betracht. Geben Sie daher bitte an, wie viele Quadratmeter der Bruttogrundfläche auf Gebäude, Gebäudeteile und Anlagen für den Zivilschutz entfallen."])},
      "exemption_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auch bei der Grundsteuer gibt es Befreiungs- und Vergünstigungsmöglichkeiten. Diese beziehen sich im Wesentlichen auf den gemeinnützigen Bereich etwa von Stiftungen oder Vereinen und greifen dort auch nur, soweit es sich nicht um Grundbesitz für Wohnzwecke handelt."])},
      "location_rooms_designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lage der Räume / Bezeichnung"])},
      "tax_free_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerfreie Fläche in m²"])},
      "add_more_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Befreiung hinzufügen"])},
      "add_more_non_residential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiteres Nichtwohngrundstück hinzufügen"])},
      "building_type_options": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemischt genutzte Grundstücke (Wohnhäuser mit Mischnutzung)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banken und ähnliche Geschäftshäuser"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bürogebäude, Verwaltungsgebäude"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeindezentren, Vereinsheime, Saalbauten, Veranstaltungsgebäude"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergärten (Kindertagesstätten), allgemeinbildende Schulen, berufsbildende Schulen, Hochschulen, Sonderschulen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnheime, Internate, Alten- oder Pflegeheime"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankenhäuser, Kliniken, Tageskliniken, Ärztehäuser"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beherbergungsstätten, Hotels, Verpflegungseinrichtungen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporthallen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tennishallen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freizeitbäder, Kur- und Heilbäder"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbrauchermärkte"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf- und Warenhäuser"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autohäuser ohne Werkstatt"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebs- und Werkstätten ohne Hallenanteil; Industrielle Produktionsgebäude, Massivbauweise"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrgeschossige Betriebs- und Werkstätten mit einem hohen Hallenanteil; Industrielle Produktionsgebäude, überwiegend Skelettbauweise"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagergebäude ohne Mischnutzung, Kaltlager"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12.1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagergebäude mit bis zu 25 Prozent Mischnutzung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12.2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagergebäude mit mehr als 25 Prozent Mischnutzung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12.3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Museen, Theater, Sakralbauten"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reithallen, ehemalige landwirtschaftliche Mehrzweckhallen, Scheunen und Ähnliches"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stallbauten"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochgaragen, Tiefgaragen und Nutzfahrzeuggaragen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelgaragen, Mehrfachgaragen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carports und Ähnliches"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18"])}
        }
      ],
      "exemption_options": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für öffentlichen Dienst/Gebrauch (jPdöR)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundeseisenbahnvermögen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige/mildtätige Zwecke"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religiöse Zwecke"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstwohnung Geistliche"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religionsgem. Besoldung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gottesdienst"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestattungsplatz"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentlicher Verkehr"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flugbetrieb"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fließende Gewässer"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasser-/Bodenverbände (öR)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wissenschaft/Unterricht/Erziehung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankenhaus"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplomatische Vertretung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
        }
      ],
      "benefit_options": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach bundesrechtlichen Wohnraumförderungsgesetz (§ 15 Absatz 2 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraumförderung nach landesrechtlichen Wohnraumförderungsgesetzen (§ 15 Absatz 3 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungsbaugesellschaft von Gebietskörperschaften (§ 15 Absatz 4 Satz 1 Nummer 1 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinnützige Wohnungsbaugesellschaft (§ 15 Absatz 4 Satz 1 Nummer 2 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körperschaftsteuerbefreite Genossenschaften und Vereine (§ 15 Absatz 4 Satz 1 Nummer 3 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denkmalgeschützte Gebäude und Gebäudeteiles (§ 15 Absatz 5 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
        }
      ],
      "benefit_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auch bei der Grundsteuer gibt es Befreiungs- und Vergünstigungsmöglichkeiten. Diese beziehen sich im Wesentlichen auf den gemeinnützigen Bereich etwa von Stiftungen oder Vereinen und greifen dort auch nur, soweit es sich nicht um Grundbesitz für Wohnzwecke handelt."])},
      "discounted_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergünstigte Fläche in m²"])},
      "add_more_benefit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Vergünstigung hinzufügen"])},
      "exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befreiung"])},
      "benefit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergünstigung"])},
      "messages": {
        "locationPlanNumber_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Lageplan-)Nummer überschreitet den zulässigen Maximalwert."])},
        "yearOfConstruction_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Jahreszahl darf höchstens 4-stellig sein."])},
        "yearOfConstruction_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Jahreszahl darf nicht negativ sein."])},
        "coreRenovationYear_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Jahreszahl darf höchstens 4-stellig sein."])},
        "coreRenovationYear_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Jahreszahl darf nicht negativ sein."])},
        "demolitionObligationYear_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Jahreszahl darf höchstens 4-stellig sein."])},
        "demolitionObligationYear_positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Jahreszahl darf nicht negativ sein."])},
        "areaOfTheLand_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche überschreitet den zulässigen Maximalwert."])},
        "areaOfTheLandCivilDefense_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche überschreitet den zulässigen Maximalwert."])},
        "locationOfTheRoomsDesignation_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe darf höchstens 25 Zeichen lang sein."])},
        "taxFreeArea_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche überschreitet den zulässigen Maximalwert."])},
        "discountedArea_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche überschreitet den zulässigen Maximalwert."])}
      }
    },
    "propertyLeasehold": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbbaurecht"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbbaurecht"])},
      "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Name & Anschrift des/der Erbbauverpflichteten oder des (wirtschaftlichen) Eigentümers/der (wirtschaftlichen) Eigentümerin des Gebäudes auf fremdem Grund und Boden ein."])},
      "dummy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Seite ist in Bearbeitung und in Kürze verfügbar."])},
      "salutations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anredeschlüssel"])},
      "academic_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel / akademischer Grad"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbbauverpflichtete/Eigentümer des Gebäudes"])},
      "lname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name/Firma"])},
      "delete_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Löschen eines oder mehrerer Erbbauverpflichteten"])},
      "form_of_address_options": {
        "2-6-9": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Anrede"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herrn"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herrn und Frau"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herrn und Herrn"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau und Frau"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbengemeinschaft"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsgemeinschaft"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksgemeinschaft"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft bürgerlichen Rechts"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sozietät"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praxisgemeinschaft"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsgemeinschaft"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungseigentümergemeinschaft"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnergesellschaft"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])}
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenreederei"])},
            "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17"])}
          }
        ]
      }
    },
    "propertyAgriculture": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land und Forstwirtschaft"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land und Forstwirtschaft"])},
      "total_liberation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befreiung gesamt"])},
      "non_delimitable_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befreiung nicht abgrenzbarer Teil"])},
      "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinde"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundesland"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
      "description_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld dient Ihnen als Notizfeld, z. B. um den Namen des Flurstückes anzugeben. Dieser Eintrag wird nicht an die Finanzverwaltung übermittelt."])},
      "parcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flurstück"])},
      "parcelno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemarkungsnummer"])},
      "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemarkung"])},
      "corridor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flur"])},
      "land_register_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbuchblatt"])},
      "corridor_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Flur ist ein Teil der Gemarkung und beschreibt die Nutzfläche einer Siedlung innerhalb einer Gemeinde."])},
      "corridor_tooltip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo ist diese Angabe zu finden?"])},
      "corridor_tooltip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Grundbuchauszug</li><li>Bestandsnachweis</li><li>Liegenschaftskarte/Auszug aus dem Liegenschaftskataster</li><li>Online abrufbar über Geo-Viewer</li></ul>"])},
      "corridor_tooltip4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigen Sie weitere Informationen?"])},
      "parcel_data_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flurstück: Zähler"])},
      "parcel_data_counter_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Flurstück wird ein einzelnes Grundstück bezeichnet. Die Nummer eines Flurstücks besteht aus Zähler und Nenner, also lautet zum Beispiel 83/194."])},
      "parcel_data_counter_tooltip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtig: Der gesamte Grundbesitz, der zum Beispiel mit einem Einfamilienhaus bebaut und im Übrigen als Garten genutzt wird, kann durchaus aus mehreren Flurstücken bestehen. In der Feststellungserklärung sind alle Flurstücke des Steuerpflichtigen separat anzugeben."])},
      "parcel_data_counter_tooltip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo ist diese Angabe zu finden?"])},
      "parcel_data_counter_tooltip4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Grundbuchauszug</li><li>Liegenschaftskarte/Auszug aus dem Liegenschaftskataster</li><li>Geo-Viewer je Bundesland</li></ul>"])},
      "parcel_data_counter_tooltip5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigen Sie weitere Informationen?"])},
      "parcel_data_denominator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flurstück: Nenner"])},
      "parcel_data_denominator_tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Flurstück wird ein einzelnes Grundstück bezeichnet. Die Nummer eines Flurstücks besteht aus Zähler und Nenner, also lautet zum Beispiel 83/194."])},
      "parcel_data_denominator_tooltip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtig: Der gesamte Grundbesitz, der zum Beispiel mit einem Einfamilienhaus bebaut und im Übrigen als Garten genutzt wird, kann durchaus aus mehreren Flurstücken bestehen. In der Feststellungserklärung sind alle Flurstücke des Steuerpflichtigen separat anzugeben."])},
      "parcel_data_denominator_tooltip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo ist diese Angabe zu finden?"])},
      "parcel_data_denominator_tooltip4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Grundbuchauszug</li><li>Liegenschaftskarte/Auszug aus dem Liegenschaftskataster</li><li>Geo-Viewer je Bundesland</li></ul>"])},
      "parcel_data_denominator_tooltip5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigen Sie weitere Informationen?"])},
      "area_of_the_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amtliche Fläche"])},
      "area_of_the_land_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die amtliche Fläche ist die Gesamtfläche eines Grundstücks, welches sich unabhängig von der Art der Nutzung im eigenen Besitz befindet."])},
      "is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flurstück als Referenzgrundstück verwenden"])},
      "is_reference_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiermit können Sie dieses Flurstück als Lage des Betriebs der Land und Forstwirtschaft für die Felder 8 und 9 im Freigabedokument festlegen, falls keine Straße und Hausnummer vorhanden sind."])},
      "usage_type_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Nutzung"])},
      "usage_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzung"])},
      "area_of_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche der Nutzung"])},
      "earnings_metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ertragsmesszahl"])},
      "floor_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttogrundfläche der Wirtschaftsgebäude"])},
      "floor_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchflussmenge in l/s"])},
      "liberation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befreiung"])},
      "favoredArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begünstigte Fläche in m²"])},
      "addUses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Nutzungsart"])},
      "addGemeinde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Gemeinde"])},
      "addParcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiteres Flurstück"])},
      "dummy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Seite ist in Bearbeitung und in Kürze verfügbar."])},
      "federal_states": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baden-Württemberg"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bayern"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berlin"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brandenburg"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bremen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamburg"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hessen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mecklenburg-Vorpommern"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedersachsen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nordrhein-Westfalen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rheinland-Pfalz"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saarland"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachsen-Anhalt"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachsen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schleswig-Holstein"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thüringen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])}
        }
      ],
      "data_list": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbesitz, der von einer inländischen juristischen Person des öffentlichen Rechts für einen öffentlichen Dienst oder Gebrauch benutzt wird (§ 3 Absatz 1 Nummer 1 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbesitz, der vom Bundeseisenbahnvermögen für Verwaltungszwecke benutzt wird (§ 3 Absatz 1 Nummer 2 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbesitz, der von einer inländischen juristischen Person des öffentlichen Rechts oder einer inländischen Körperschaft, Personenvereinigung oder Vermögensmasse, die nach der Satzung, dem Stiftungsgeschäft oder der sonstigen Verfassung und nach ihrer tatsächlichen Geschäftsführung ausschließlich und unmittelbar gemeinnützigen oder mildtätigen Zwecken dient, für gemeinnützige oder mildtätige Zwecke benutzt wird (§ 3 Absatz 1 Nummer 3 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbesitz, der von einer Religionsgesellschaft, die Körperschaft des öffentlichen Rechts ist, einem ihrer Orden, einer ihrer religiösen Genossenschaften oder einem ihrer Verbände oder von einer jüdischen Kultusgemeinde, die keine Körperschaft des öffentlichen Rechts ist, für Zwecke der religiösen Unterweisung, der Wissenschaft, des Unterrichts, der Erziehung oder für Zwecke der eigenen Verwaltung benutzt wird (§ 3 Absatz 1 Nummer 4 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstwohnungen der Geistlichen und Kirchendiener der Religionsgesellschaften, die Körperschaften des öffentlichen Rechts sind, und der jüdischen Kultusgemeinden (§ 3 Absatz 1 Nummer 5 GrStG). § 5 GrStG ist insoweit nicht anzuwenden."])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbesitz der Religionsgesellschaften, die Körperschaften des öffentlichen Rechts sind, und der jüdischen Kultusgemeinden, der am 1. Januar 1987 und im Veranlagungszeitpunkt zu einem nach Kirchenrecht gesonderten Vermögen, insbesondere einem Stellenfonds gehört, dessen Erträge ausschließlich für die Besoldung und Versorgung der Geistlichen und Kirchendiener sowie ihrer Hinterbliebenen bestimmt sind (§ 3 Absatz 1 Nummer 6 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbesitz, der dem Gottesdienst einer Religionsgesellschaft, die Körperschaft des öffentlichen Rechts ist, oder einer jüdischen Kultusgemeinde gewidmet ist (§ 4 Nummer 1 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestattungsplätze (§ 4 Nummer 2 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dem öffentlichen Verkehr dienende Straßen, Wege, Plätze, Wasserstraßen, Häfen und Schienenwege sowie die Grundflächen mit den diesem Verkehr unmittelbar dienenden Bauwerken und Einrichtungen (§ 4 Nummer 3 Buchstabe a GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Verkehrsflughäfen und Verkehrslandeplätzen alle Flächen, die unmittelbar zur Gewährleistung eines ordnungsgemäßen Flugbetriebes notwendig sind und von Hochbauten und sonstigen Luftfahrthindernissen freigehalten werden müssen, die Grundflächen mit den Bauwerken und Einrichtungen, die unmittelbar diesem Betrieb dienen, sowie die Grundflächen ortsfester Flugsicherungsanlagen einschl...."])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fließende Gewässer und die ihren Abfluss regelnden Sammelbecken (§ 4 Nummer 3 Buchstabe c GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundflächen mit den im Interesse der Ordnung und Verbesserung der Wasser- und Bodenverhältnisse unterhaltenen Einrichtungen der öffentlich-rechtlichen Wasser- und Bodenverbände und die im öffentlichen Interesse staatlich unter Schau gestellten Privatdeiche (§ 4 Nummer 4 GrStG)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbesitz, der für Zwecke der Wissenschaft, des Unterrichts oder der Erziehung benutzt wird (§ 4 Nummer 5 GrStG), wenn durch die Landesregierung oder die von ihr beauftragte Stelle anerkannt ist, dass der Benutzungszweck im Rahmen der öffentlichen Aufgaben liegt und der Grundbesitz ausschließlich demjenigen, der ihn benutzt, oder einer juristischen Person des öffentlichen Rechts zuzurechnen ist."])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbesitz, der für die Zwecke eines Krankenhauses benutzt wird (§ 4 Nummer 6 GrStG), wenn das Krankenhaus in dem Kalenderjahr, das dem Veranlagungszeitpunkt vorangeht, die Voraussetzungen des § 67 Absatz 1 oder 2 der Abgabenordnung erfüllt hat und der Grundbesitz ausschließlich demjenigen, der ihn benutzt, oder einer juristischen Person des öffentlichen Rechts zuzurechnen ist."])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbesitz ausländischer Staaten, der für diplomatische Zwecke genutzt wird (Wiener Übereinkommen über diplomatische Beziehungen - WÜD - vom 18. April 1961 (BGBl. 1964 II S. 959)) und Grundbesitz, der unter der gleichen Voraussetzung konsularischen Zwecken dient (Wiener Übereinkommen über konsularische Beziehungen - WÜK - vom 24. April 1963 (BGBl. 1969 II S. 1587))."])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
        }
      ],
      "exemption_befreiung": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehr- oder Versuchszwecke"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übungs- oder Flugplatz gemäß § 6 Nr. 2 GrStG"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbesitz, der unter § 4 Nummer 1 bis 4 Grundsteuergesetz fällt"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbesitz, der unter § 3 Absatz 1 Satz 1 Nummer 6 GrStG fällt"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
        }
      ],
      "usage_type_options": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landwirtschaftliche Nutzung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forstwirtschaftliche Nutzung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewirtschaftungsbeschränkung Forstwirtschaft"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weinbauliche Nutzung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemüsebau – Freiland"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemüsebau – unter Glas und Kunststoffen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blumen- und Zierpflanzenbau – Freiland"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blumen- und Zierpflanzenbau – unter Glas und Kunststoffen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obstbau - Freiland"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obstbau - unter Glas und Kunststoffen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baumschulen - Freiland"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baumschulen - unter Glas und Kunststoffen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleingarten- und Dauerkleingartenland"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gartenlaube größer 30 m²"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hopfen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spargel"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasserflächen ohne oder mit geringer Nutzung (Fischertrag kleiner 1 kg/Ar)"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasserflächen bei stehenden Gewässern mit Fischertrag zwischen 1 kg/Ar und 4 kg/Ar"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasserflächen bei stehenden Gewässern mit Fischertrag größer 4 kg/Ar"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasserflächen bei fließenden Gewässern mit Fischertrag"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saatzucht"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weihnachtsbaumkulturen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurzumtriebsplantagen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbauland"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geringstland"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unland"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windenergie"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hofstelle"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftsgebäude der Fass- und Flaschenweinerzeugung"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftsgebäude der Imkerei"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftsgebäude der Wanderschäferei"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftsgebäude des Pilzanbaus"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["32"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftsgebäude der Produktion von Nützlingen"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["33"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftsgebäude sonstiger Nebenbetriebe"])},
          "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["34"])}
        }
      ],
      "messages": {
        "state_greater_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundesland: Auswahl ist erforderlich."])},
        "positive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Wert darf nicht negativ sein"])},
        "corridor_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flur überschreitet den zulässigen Maximalwert."])},
        "counter_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zähler überschreitet den zulässigen Maximalwert."])},
        "denominator_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenner überschreitet den zulässigen Maximalwert."])},
        "areaOfTheLand_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche überschreitet den zulässigen Maximalwert."])},
        "exemption_area_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche überschreitet den zulässigen Maximalwert."])},
        "flowRate_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe überschreitet den zulässigen Maximalwert."])},
        "areaOfFarmBuildings_less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche überschreitet den zulässigen Maximalwert."])}
      }
    },
    "propertyLivestock": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tierbestand"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tierbestand"])},
      "infoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füllen Sie die Anlage Tierbestand aus, wenn Sie Tierhaltung betreiben."])},
      "subheadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landwirtschaftliche Nutzung"])},
      "owned_space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentumsflächen"])},
      "minus_leased_areas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abzüglich verpachteten Flächen"])},
      "plus_leased_areas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuzüglich zugepachteten Flächen"])},
      "self_caltivated_areas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstbewirtschaftete Flächen"])},
      "alpacas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpakas"])},
      "dam_animal_below_1_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dammtiere unter 1 Jahr"])},
      "dam_animal_above_1_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dammtiere 1 Jahr und älter"])},
      "laying_hens_include_breeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legehennen (einschließlich einer normalen Aufzucht zur Ergänzung des Bestandes)"])},
      "purchased_laying_hens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legehennen aus zugekauften Junghennen"])},
      "poultry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuchtputen, -enten, -gänse"])},
      "breeding_rabbits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zucht- und Angorakaninchen"])},
      "llamas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamas"])},
      "below_3_years_or_small_horses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pferde unter 3 Jahren und Kleinpferde"])},
      "horses_horses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pferde 3 Jahre und älter"])},
      "beef_below_1_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kälber und Jungvieh unter 1 Jahr (einschließlich Mastkälber, Starterkälber und Fresser)"])},
      "beef_from_1_to_2_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jungvieh 1 bis 2 Jahre alt"])},
      "beef_above_2_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Färsen (älter als 2 Jahre)"])},
      "fattening_animals_less_than_1_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masttiere (Mastdauer weniger als 1 Jahr)"])},
      "livestock_cow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kühe (einschließlich Mutter- und Ammenkühe mit den dazugehörigen Saugkälbern)"])},
      "livestock_breeding_bulls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuchtbullen, Zugochsen"])},
      "livestock_sheep_below_1_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schafe unter 1 Jahr (einschließlich Mastlämmer)"])},
      "livestock_sheep_above_1_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schafe 1 Jahr und älter"])},
      "livestock_breeding_pigs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuchtschweine (einschließlich Jungzuchtschweine über etwa 90 kg)"])},
      "ostriches_above_14_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strauße (Zuchttiere 14 Monate und älter)"])},
      "ostriches_below_14_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strauße (Jungtiere/Masttiere unter 14 Monate)"])},
      "livestock_goats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziegen"])},
      "livestock_subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tierarten nach der Erzeugung"])},
      "below_6_passes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jungmasthühner (bis zu 6 Durchgänge je Jahr - schwere Tiere)"])},
      "above_6_passes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jungmasthühner (mehr als 6 Durchgänge je Jahr - leichte Tiere)"])},
      "livestock_pullets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junghennen"])},
      "fattening_ducks_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastenten"])},
      "fattening_ducks_raising_phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastenten in der Aufzuchtphase"])},
      "fattening_ducks_fattening_phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastenten in der Mastphase"])},
      "turkeys_self_raised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastputen aus selbst erzeugten Jungputen"])},
      "turkeys_bought": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastputen aus zugekauften Jungputen"])},
      "turkeys_young_turkeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jungputen (bis etwa 8 Wochen)"])},
      "livestock_fattened_geese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastgänse"])},
      "livestock_mast_rabbit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastkaninchen"])},
      "livestock_beef_cattle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rindvieh - Masttiere (Mastdauer 1 Jahr und mehr)"])},
      "pigs_light_piglets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leichte Ferkel (bis etwa 12 kg)"])},
      "pigs_piglets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferkel (über etwa 12 bis etwa 20 kg)"])},
      "pigs_heavy_piglets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwere Ferkel und leichte Läufer (über etwa 20 bis etwa 30 kg)"])},
      "pigs_runner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läufer (über etwa 30 bis etwa 45 kg)"])},
      "pigs_heavy_runner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwere Läufer (über etwa 45 bis etwa 60 kg)"])},
      "pigs_fattening_pigs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastschweine"])},
      "pigs_young_breeding_pigs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jungzuchtschweine bis etwa 90 kg"])},
      "purchased_animals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugekaufte Tiere"])},
      "bought_pigs_light_piglets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leichte Ferkel (bis etwa 12 kg)"])},
      "bought_pigs_piglets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferkel (über etwa 12 bis etwa 20 kg)"])},
      "bought_pigs_heavy_piglets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwere Ferkel und leichte Läufer (über etwa 20 bis etwa 30 kg)"])},
      "bought_pigs_runner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läufer (über etwa 30 bis etwa 45 kg)"])},
      "bought_pigs_heavy_runner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwere Läufer (über etwa 45 bis etwa 60 kg)"])},
      "subheading_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tierarten nach dem Durschnittsbestand"])}
    },
    "form_validation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte unzulässige Formulareingaben prüfen!"])},
    "Dokumente": {
      "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente"])},
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente"])},
      "show_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument anzeigen"])},
      "document_ablegen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument ablegen"])},
      "document_anzeigen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument anzeigen"])},
      "document_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument(e) löschen"])},
      "delete_success_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument wurde erfolgreich gelöscht."])},
      "delete_success_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente wurden erfolgreich gelöscht."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt von"])},
      "upload_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochgeladen am"])},
      "file_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateigröße"])},
      "uploader_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziehen und Ablegen oder Durchsuchen Datei zum Hochladen"])},
      "x_docs_uploaded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Dokument(e) hochgeladen"])},
      "x_docs_upload_failed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Dokument(e) konnte(n) nicht hochgeladen werden"])},
      "confirm_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument(e) löschen"])},
      "confirm_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die ausgewählten Dokumente wirklich löschen?"])}
    },
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksadresse"])},
    "plot_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksdetails"])},
    "district_parcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemarkung(en) und Flurstück(e)"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinschaft"])},
    "residential_plots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohngrundstücke"])},
    "nonresidential_plots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nichtwohngrundstücke"])},
    "leasehold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbbaurecht"])},
    "agricultureandforestry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land und Forstwirtschaft"])},
    "livestock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tierbestand"])},
    "read_only_save_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern des Grundstücks ist nicht möglich. Für dieses Grundstück haben Sie nur eine Leseberechtigung."])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "continue_enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder Enter drücken"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "step2_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir benötigen ein paar Angaben zum Grundstück"])},
    "type_of_plot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art des Grundstücks"])},
    "type_of_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsart"])},
    "step3_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Grundstücksdetails"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "land_for_forestry_or_agriculture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück für Forst- oder Landwirtschaft"])},
    "leasehold_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist auf dem Grundstück ein Erbbaurecht bestellt?"])},
    "area_of_the_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche des Grundstücks in m²"])},
    "area_of_the_land_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bodenrichtwert in €/m²"])},
    "bORIS_d_abfrage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BORIS-D-Abfrage starten"])},
    "step4_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte erfassen Sie nun Gemarkung(en) und Flurstück(e)"])},
    "step4_subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemarkung"])},
    "land_register_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbuchblatt"])},
    "corridor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flur"])},
    "parcel_data_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flurstück: Zähler"])},
    "parcel_data_denominator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flurstück: Nenner"])},
    "parcel_area_of_the_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche in m²"])},
    "share_of_ownership_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miteigentumsanteil laut Grundbuch: Zähler"])},
    "share_of_ownership_denominator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miteigentumsanteil laut Grundbuch: Nenner"])},
    "share_of_property_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur wirtschaftlichen Einheit gehörender Anteil:Zähler"])},
    "share_of_property_denominator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur wirtschaftlichen Einheit gehörender Anteil:Nenner"])},
    "addMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere hinzufügen"])},
    "addUses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Nutzungsart"])},
    "step5_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben zur Gemeinschaft"])},
    "step5_subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinschaft"])},
    "anrede": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
    "ownership_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Gemeinschaft"])},
    "fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "lname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnummer"])},
    "ort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "telefonnummer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
    "houseno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer"])},
    "pobox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postfach"])},
    "plz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "owners_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben zu weiteren Eigentümern/Beteiligte"])},
    "owners_participants_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentümer/Beteiligte"])},
    "owners_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel/akademischer Grad"])},
    "owners_fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname/Firma"])},
    "owners_lname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name/Firma"])},
    "owners_finance_agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnsitz-/Betriebsstätten-Finanzamt"])},
    "tax_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer"])},
    "tax_identification_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
    "share_in_the_property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteil am Grundstück"])},
    "owners_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteil Zähler"])},
    "owners_denominator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteil Nenner"])},
    "representative_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegebenfalls gesetzlich vertreten durch:"])},
    "representative_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel/akademischer Grad"])},
    "step6_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben zum Wohngrundstück"])},
    "step6_subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Angaben"])},
    "step6_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baujahr des Gebäudes (Zeitpunkt der Bezugsfertigkeit)"])},
    "core_renovation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es erfolgte eine Kernsanierung, die abgeschlossen wurde im Jahr"])},
    "demolition_obligation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es besteht eine Abbruchverpflichtung im Jahr"])},
    "date_application_for_new_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antrag auf Neueintragung wurde beim Grundbuchamt eingereicht am"])},
    "amount_garages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Garagen-/Tiefgaragenstellplätze"])},
    "apartments_below_60_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungen unter 60m²"])},
    "apartments_below_60_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Wohnung mit Wohnfläche unter 60m²"])},
    "apartments_below_60_total_living_space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtgröße der Wohnung mit Wohnfläche unter 60m²"])},
    "apartments_below_60_exemption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befreiung"])},
    "apartments_below_60_tax_free_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerfreie Fläche in m²"])},
    "discounted_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergünstigte Fläche in m²"])},
    "apartments_60_100_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungen mit einer Wohnfläche von 60 m² bis unter 100 m²"])},
    "apartments_60_100_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Wohnung mit Wohnfläche zwischen 60 und 100m²"])},
    "apartments_60_100_total_living_space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtgröße der Wohnung mit Wohnfläche zwischen 60 und 100m²"])},
    "apartments_above_100_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungen mit einer Wohnfläche von 100 m² und mehr"])},
    "apartments_above_100_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Wohnung mit Wohnfläche über 100m²"])},
    "apartments_above_100_total_living_space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtgröße der Wohnung mit Wohnfläche über 100m²"])},
    "apartments_other_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weitere Wohnungen"])},
    "apartments_other_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Wohnungen, die nicht in anderen Feldern enthalten sind"])},
    "apartments_other_total_living_space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtgröße der Wohnung, die nicht in anderen Feldern enthalten"])},
    "usable_spaces_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Nutzflächen die keine Wohnflächen sind (ohne Zubehörräume wie zum Beispiel Keller, Waschküche)"])},
    "usable_spaces_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzfläche"])},
    "usage_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzung"])},
    "total_spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzfläche in m²"])},
    "step7_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben zum Nichtwohngrundstück"])},
    "step7_subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nichtwohngrundstück"])},
    "location_plan_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Lageplan-)Nummer"])},
    "building_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäudeart"])},
    "construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baujahr"])},
    "non_residential_area_of_the_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttogrundfläche in m²"])},
    "area_of_the_land_civil_defense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davon für den Zivilschutz in m²"])},
    "step8_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben zum Erbbaurecht"])},
    "step8_subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Name & Anschrift des/der Erbbauverpflichteten oder des (wirtschaftlichen) Eigentümers/der (wirtschaftlichen) Eigentümerin des Gebäudes auf fremdem Grund und Boden ein."])},
    "step8_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbbauverpflichtete/Eigentümer des Gebäudes"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname"])},
    "luf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LuF"])},
    "parcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flurstück"])},
    "parcelno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemarkungsnummer"])},
    "step8_area_of_the_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amtliche Fläche in m²"])},
    "step8_usage_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Nutzung"])},
    "yield_measurement_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ertragsmesszahl (nur bei Landwirtschaftliche Nutzung, Saatzucht und Kurzumtriebsplantagen)"])},
    "area_of_farm_buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttogrundfläche der Wirtschaftsgebäude (nur bei Nutzung 29-34)"])},
    "flow_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchflussmenge in l/s (nur bei Nutzung 20)"])},
    "step10_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben zum Tierbestand"])},
    "step10_subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landwirtschaftliche Nutzung"])},
    "step10_subheading_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tierarten nach dem Durschnittsbestand"])},
    "owned_space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentumsflächen"])},
    "minus_leased_areas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abzüglich verpachteten Flächen"])},
    "plus_leased_areas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuzüglich zugepachteten Flächen"])},
    "self_caltivated_areas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstbewirtschaftete Flächen"])},
    "alpacas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpakas"])},
    "dam_animal_below_1_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dammtiere unter 1 Jahr"])},
    "dam_animal_above_1_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dammtiere 1 Jahr und älter"])},
    "laying_hens_include_breeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legehennen (einschließlich einer normalen Aufzucht zur Ergänzung des Bestandes)"])},
    "purchased_laying_hens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legehennen aus zugekauften Junghennen"])},
    "poultry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuchtputen, -enten, -gänse"])},
    "breeding_rabbits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zucht- und Angorakaninchen"])},
    "llamas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamas"])},
    "below_3_years_or_small_horses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pferde unter 3 Jahren und Kleinpferde"])},
    "horses_horses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pferde 3 Jahre und älter"])},
    "beef_below_1_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kälber und Jungvieh unter 1 Jahr (einschließlich Mastkälber, Starterkälber und Fresser)"])},
    "beef_from_1_to_2_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jungvieh 1 bis 2 Jahre alt"])},
    "beef_above_2_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Färsen (älter als 2 Jahre)"])},
    "fattening_animals_less_than_1_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masttiere (Mastdauer weniger als 1 Jahr)"])},
    "livestock_cow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kühe (einschließlich Mutter- und Ammenkühe mit den dazugehörigen Saugkälbern)"])},
    "livestock_breeding_bulls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuchtbullen, Zugochsen"])},
    "livestock_sheep_below_1_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schafe unter 1 Jahr (einschließlich Mastlämmer)"])},
    "livestock_sheep_above_1_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schafe 1 Jahr und älter"])},
    "livestock_breeding_pigs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuchtschweine (einschließlich Jungzuchtschweine über etwa 90 kg)"])},
    "ostriches_above_14_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strauße (Zuchttiere 14 Monate und älter)"])},
    "ostriches_below_14_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strauße (Jungtiere/Masttiere unter 14 Monate)"])},
    "livestock_goats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziegen"])},
    "livestock_subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tierarten nach der Erzeugung"])},
    "below_6_passes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jungmasthühner (bis zu 6 Durchgänge je Jahr - schwere Tiere)"])},
    "above_6_passes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jungmasthühner (mehr als 6 Durchgänge je Jahr - leichte Tiere)"])},
    "livestock_pullets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junghennen"])},
    "fattening_ducks_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastenten"])},
    "fattening_ducks_raising_phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastenten in der Aufzuchtphase"])},
    "fattening_ducks_fattening_phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastenten in der Mastphase"])},
    "turkeys_self_raised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastputen aus selbst erzeugten Jungputen"])},
    "turkeys_bought": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastputen aus zugekauften Jungputen"])},
    "turkeys_young_turkeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jungputen (bis etwa 8 Wochen)"])},
    "livestock_fattened_geese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastgänse"])},
    "livestock_mast_rabbit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastkaninchen"])},
    "livestock_beef_cattle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rindvieh - Masttiere (Mastdauer 1 Jahr und mehr)"])},
    "pigs_light_piglets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leichte Ferkel (bis etwa 12 kg)"])},
    "pigs_piglets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferkel (über etwa 12 bis etwa 20 kg)"])},
    "pigs_heavy_piglets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwere Ferkel und leichte Läufer (über etwa 20 bis etwa 30 kg)"])},
    "pigs_runner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läufer (über etwa 30 bis etwa 45 kg)"])},
    "pigs_heavy_runner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwere Läufer (über etwa 45 bis etwa 60 kg)"])},
    "pigs_fattening_pigs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastschweine"])},
    "pigs_young_breeding_pigs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jungzuchtschweine bis etwa 90 kg"])},
    "purchased_animals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugekaufte Tiere"])},
    "bought_pigs_light_piglets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leichte Ferkel (bis etwa 12 kg)"])},
    "bought_pigs_piglets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferkel (über etwa 12 bis etwa 20 kg)"])},
    "bought_pigs_heavy_piglets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwere Ferkel und leichte Läufer (über etwa 20 bis etwa 30 kg)"])},
    "bought_pigs_runner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läufer (über etwa 30 bis etwa 45 kg)"])},
    "bought_pigs_heavy_runner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwere Läufer (über etwa 45 bis etwa 60 kg)"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
    "upload_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei erfolgreich hochgeladen"])},
    "Interrupt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "Interrupt_text_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Grundstücksanlage abbrechen?"])},
    "Interrupt_text_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Grundstücksbearbeitung abbrechen?"])},
    "Interrupt_text_read_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Grundstücksanzeige abbrechen?"])},
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "AddQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie das neu erfasste Grundstück speichern?"])},
    "UpdateQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie das Grundstück speichern?"])},
    "SaveNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt speichern"])},
    "DoNotSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfassung abbrechen"])},
    "GoBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter erfassen"])},
    "no_result_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine Ergebnisse gefunden"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe erforderlich"])}
  },
  "passwordTemplate": {
    "promptLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Passwort eingeben"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort-Anforderungen"])},
    "text0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 9 Zeichen"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Kleinbuchstabe"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Großbuchstabe"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Zahl"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 3 verschiedene Zeichen"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anders als Ihre E-Mail"])}
  },
  "declaration": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir benötigen Ihre Freigabe!"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben Ihre Feststellungserklärung vorbereitet und möchten Sie bitten diese zu prüfen. Sofern Sie keine Beanstandungen haben, unterschreiben Sie das Dokument sowie unsere Vollständigkeitserklärung bitte digital. Alternativ können Sie es auch ausdrucken, unterschreiben und postalisch an uns senden. Sofern Sie Fragen haben oder Hilfe benötigen, melden Sie sich gerne bei uns."])},
    "buttons": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "generate_signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschrift generieren"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
    },
    "multihouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrfamilienhaus"])},
    "default_property_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Grundstück"])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche in m2"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundesland"])},
    "timer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauert ca. 5 Min"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "num_pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite(n)"])},
    "sign_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt unterschreiben"])},
    "control_print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feststellungserklärung - Freizeichnungsdokument"])},
    "completed_declaration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständigkeitserklärung"])},
    "approve_declaration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigeben"])},
    "elster_pdf_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das ELSTER-Freizeichnungsdokument konnte nicht erzeugt werden. Dies kann passieren, sofern die zu übertragenen Daten nicht für ELSTER gültig sind. Bitte wenden Sie sich an Ihren Steuerberater, damit dieser Ihre Daten überprüft."])},
    "tabs": {
      "text-signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingeben"])},
      "draw_signaure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichnen"])},
      "upload_signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])}
    },
    "finish": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank!"])},
      "info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir werden Ihre Feststellungserklärung nun beim Finanzamt einreichen und melden uns bei Ihnen zurück, sobald wir einen Bescheid von der Finanzverwaltung erhalten haben."])},
      "info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit freundlichen Grüßen"])},
      "info3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir werden Ihre Feststellungserklärung nun beim Finanzamt einreichen. Sobald Sie einen Bescheid von der Finanzverwaltung erhalten haben, melden Sie sich bitte bei uns."])},
      "documents_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgend die Dokument zu dieser Feststellungserklärung:"])}
    },
    "close_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können dieses Fenster jetzt schließen."])},
    "mfa_code_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben in einer separaten E-Mail einen Code erhalten. Bitte geben Sie den Code hier ein."])}
  },
  "property_change": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir benötigen Ihre Informationen!"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben bereits Ihre Feststellungserklärung übermittelt und möchten Sie nun darum bitten, etwaige Änderungen seit der letzten Übermittlung an den Grundstücken einzutragen. Falls es keine Änderungen gibt, teilen Sie uns dies gerne über den Button \"Keine Änderungen seit der letzten Übermittlung\" mit. Sie haben außerdem die Möglichkeit, uns die Änderungen per E-Mail oder postalisch mitzuteilen."])},
    "info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für Fragen stehen wir Ihnen gerne zur Verfügung. Gerne unterstützen wir Sie bei der Erfassung von geänderten Grundstücksinformationen."])},
    "subline1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der nachfolgenden Liste finden Sie eine vollständige Übersicht Ihrer Grundstücke. Wir bitten darum, den Button \"Änderungen eintragen\", der im Freitextbereich bereitsteht, zu verwenden, um eventuelle Modifikationen seit der letzten Übermittlung einzutragen."])},
    "title_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen, "])},
    "title_postfix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!"])},
    "property_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentrale Grd.-Nr."])},
    "property_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "property_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
    "property_has_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gab es Änderungen am Grundstück"])},
    "property_has_change_options": {
      "-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Auswählen"])},
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
    },
    "property_change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungsstatus"])},
    "property_change_status_no1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihre Rückmeldung!"])},
    "property_change_status_no2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bestätigen hiermit, dass wir die von Ihnen übermittelten Informationen zu den Grundstücken erhalten haben. Es wurden keine Änderungen von Ihrer Seite gemeldet. Ihre Informationen wurden erfolgreich erfasst und es sind keine weiteren Maßnahmen erforderlich."])},
    "property_change_status_no3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir möchten uns herzlich für Ihre Unterstützung bedanken. Sollten Sie in Zukunft Änderungen vornehmen wollen oder weitere Anliegen haben, stehen wir Ihnen gerne zur Verfügung."])},
    "modal_header_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz zur Änderung"])},
    "property_change_status_option": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen eintragen"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen übermittelt"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf gespeichert"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Änderungen"])}
    },
    "property_has_change_options_color": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orange_data_query"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["green_data_query"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yellow_data_query"])}
    },
    "buttons": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "generate_signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschrift generieren"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
    },
    "multihouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrfamilienhaus"])},
    "default_property_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Grundstück"])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche in m2"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundesland"])},
    "timer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauert ca. 5 Min"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "num_pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite(n)"])},
    "sign_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt unterschreiben"])},
    "control_print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feststellungserklärung - Freizeichnungsdokument"])},
    "completed_declaration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständigkeitserklärung"])},
    "approve_declaration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigeben"])},
    "elster_pdf_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das ELSTER-Freizeichnungsdokument konnte nicht erzeugt werden. Dies kann passieren, sofern die zu übertragenen Daten nicht für ELSTER gültig sind. Bitte wenden Sie sich an Ihren Steuerberater, damit dieser Ihre Daten überprüft."])},
    "tabs": {
      "text-signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingeben"])},
      "draw_signaure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichnen"])},
      "upload_signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])}
    },
    "finish": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank!"])},
      "info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir werden Ihre Feststellungserklärung nun beim Finanzamt einreichen und melden uns bei Ihnen zurück, sobald wir einen Bescheid von der Finanzverwaltung erhalten haben."])},
      "info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit freundlichen Grüßen"])},
      "info3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir werden Ihre Feststellungserklärung nun beim Finanzamt einreichen. Sobald Sie einen Bescheid von der Finanzverwaltung erhalten haben, melden Sie sich bitte bei uns."])},
      "documents_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgend die Dokument zu dieser Feststellungserklärung:"])}
    },
    "close_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können dieses Fenster jetzt schließen."])},
    "mfa_code_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben in einer separaten E-Mail einen Code erhalten. Bitte geben Sie den Code hier ein."])}
  },
  "deviatingOwner": {
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abweichender Eigentümer"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abweichender Eigentümer (Ehepartner, Kinder)"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofern das Grundstück nicht Ihnen, sondern einem direkten Angehören (z. B. Ehepartner, Kinder) gehört, können Sie hier den abweichenden Eigentümer eingeben."])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])}
  },
  "infoTextDisabledFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachten Sie, dass ausgegraute Felder in dem gewählten Bundesland nicht erforderlich sind."])},
  "locale": {
    "separator": {
      "thousands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
      "decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([","])}
    }
  },
  "roometric": {
    "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäudeservice"])},
    "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannen Sie Ihre Wohnfläche mit Ihrem Smartphone"])},
    "modalText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie wissen nicht, wie groß Ihre Wohnfläche nach der Wohnflächenverordnung ist?"])},
    "modalText1_nonResidential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie wissen nicht, wie groß Ihre Bruttogrundfläche ist?"])},
    "modalText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dann sparen Sie sich Zeit und Nerven und laden sich jetzt die Gebäudeflächen-App auf Ihr Smartphone."])},
    "modalText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie datenkonforme Maße Ihrer berechneten Wohnfläche innerhalb weniger Minuten."])},
    "modalText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzen Sie die Aufmaß-App für 14,99€ je Objekt und erhalten Sie sofort Ihr Ergebnis!"])},
    "modalButtonClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "modalButtonSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt loslegen"])}
  }
}