<template>
  <div
    class="header"
    v-show="tc_uid > 0"
  >
    <div
      class="main_top"
      :style="[primaryColor ? { background: primaryColor } : '']"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-6 text-left">
            <h4
              class="top-title"
              style="text-align: left"
            >
              {{ $t('header.title') }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'

export default {
  name: 'InvitationHeader',
  data() {
    return {
      tc_uid: 0,
      primaryColor: '',
    }
  },

  computed: {
    ...mapGetters(['getInvitationInfo']),
  },

  watch: {
    getInvitationInfo(val) {
      this.primaryColor = val.primaryColor ? val.primaryColor : ''
      if (typeof val.taxConsultant !== 'undefined' && typeof val.taxConsultant.id !== 'undefined') {
        this.tc_uid = val.taxConsultant.id
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
ul.user-selector {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0;
  max-width: 180px;
  padding: 10px;
  position: absolute;
  right: 0;
  text-align: left;
  width: 100%;
  z-index: 10;
}
ul.user-selector li {
  font-size: 14px;
  list-style: none;
  padding: 5px 0;
}
ul.user-selector li a {
  font-size: 14px;
  list-style: none;
  text-decoration: none;
}
span.user-icon-img {
  padding-right: 10px;
}
.select-user {
  padding-left: 5px;
}
.select-user span > i.chevron {
  font-size: 15px;
  font-weight: 800;
}
.select-user span > i.person {
  font-size: 20px;
  padding-left: 10px;
}
.select-user span > i {
  color: #fff;
}
.user-profile {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.main_top {
  background: #229d56;
  height: 45px;
}

.main_top img {
  margin: 9px 0;
  max-width: 180px;
  width: 100%;
}
.top-title {
  color: white !important;
  cursor: default !important;
  font-size: 15px;
  font-weight: 400;
  line-height: 45px;
  margin: 0;
  text-decoration: none;
}
.top-title a {
  color: white;
  text-decoration: none;
}
.header {
  position: sticky;
  top: 0;
  z-index: 9999;
}
</style>
