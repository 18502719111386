<template>
  <div
    class="info-text-content"
    :style="{ background: finalBackgroundColor }"
  >
    <div class="info-text-icon">
      <Svgall
        class="info-text-img"
        :name="'info'"
        :color="finalIconColor"
      />
    </div>
    <div
      class="info-text-content-div"
      :style="{ color: finalTextColor }"
      v-if="!htmlMode"
    >
      {{ text }}
    </div>
    <div
      class="info-text-content-div"
      :style="{ color: finalTextColor }"
      v-if="htmlMode"
      v-html="text"
    />
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
export default {
  name: 'InfoText',
  props: {
    text: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '#f2f2f2',
    },
    htmlMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fontColor: '',
    }
  },

  created() {
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.fontColor : '#333333'
  },

  computed: {
    ...mapGetters(['getCurrentUser']),

    finalIconColor() {
      let myColor = this.iconColor
      if (myColor === '') {
        myColor = this.fontColor
      }
      return myColor
    },

    finalTextColor() {
      let myColor = this.textColor
      if (myColor === '') {
        myColor = this.fontColor
      }
      return myColor
    },

    finalBackgroundColor() {
      let myColor = this.backgroundColor
      if (myColor === '') {
        myColor = '#f2f2f2'
      }
      return myColor
    },
  },
}
</script>

<style scoped>
.info-text-content {
  align-content: center;
  align-items: center;
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 14px;
  padding: 5px;
}

.info-text-button-row {
  align-items: stretch;
  border: none;
  display: flex;
  font-size: 14px;
  padding: 5px;
}

.info-text-content-div {
  padding: 5px;
}

.info-text-icon {
  padding: 10px;
}

.info-text-img {
  height: 18px;
  width: 18px;
}
</style>
