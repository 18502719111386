//import axios from 'axios';
import apiClient from '../../Api'
import router from '../../router'
import i18n from '@/i18n'

const { t } = i18n.global
const state = {
  userExist: '',
  userName: '',
  emailSent: '',
  token: localStorage.getItem('token'),
  sms_code: '',
  passUpdate: '',
  clientData: '',
  allProperties: '', // *deprecated*
  accountInfo: '',
  dashboardProfileInfo: '',
  propertyDetails: '', // *deprecated*
  countries: '', // *deprecated*
  deleteProp: '', // *deprecated*
  addProperty: '', // *deprecated*
  userInfo: '',
  gemeinde: '', // *deprecated*
  invitationInfo: '',
}

const getters = {
  isUserExist: (state) => state.userExist,
  userName: (state) => state.userName,
  emailSent: (state) => state.emailSent,
  getToken: (state) => state.token,
  getPermissionForTokenPermissionKey: (key) => {
    var permission = false
    var token = localStorage.getItem('token')

    if (typeof token === 'string') {
      var splittedToken = token.split('.')

      if (splittedToken.length === 3) {
        var tokenPayload = JSON.parse(atob(splittedToken[1]))

        if (typeof tokenPayload.permissions !== 'undefined') {
          var tokenPermissionValue = tokenPayload.permissions[key]

          if (typeof tokenPermissionValue !== 'undefined') {
            permission = tokenPermissionValue
          }
        }
      }
    }
    return permission
  },
  hasSetPasswordToken: () => {
    var found = false
    var token = localStorage.getItem('token')

    if (typeof token === 'string') {
      var splittedToken = token.split('.')

      if (splittedToken.length === 3) {
        var tokenPayload = JSON.parse(atob(splittedToken[1]))
        found = tokenPayload.action === 'set_password'
      }
    }
    return found
  },
  hasInvitationToken: () => {
    var found = false
    var token = localStorage.getItem('token')

    if (typeof token === 'string') {
      var splittedToken = token.split('.')

      if (splittedToken.length === 3) {
        var tokenPayload = JSON.parse(atob(splittedToken[1]))
        found = tokenPayload.action === 'accept_invitation'
      }
    }
    return found
  },
  smsCode: (state) => state.sms_code,
  getPassUpdate: (state) => state.passUpdate,
  getClientData: (state) => state.clientData,
  getAllProperties: (state) => state.allProperties, // *deprecated*
  getAccountInfo: (state) => state.accountInfo,
  getDashboardProfileInfo: (state) => state.dashboardProfileInfo,
  getPropertyDetails: (state) => state.propertyDetails, // *deprecated*
  getCountries: (state) => state.countries, // *deprecated*
  getDeleteProp: (state) => state.deleteProp, // *deprecated*
  getAddProp: (state) => state.addProperty, // *deprecated*
  getUserInfos: (state) => state.userInfo,
  getGemeindes: (state) => state.gemeinde, // *deprecated*
  getInvitationInfo: (state) => state.invitationInfo,
}

const mutations = {
  setUserExist: (state, userExist) => {
    state.userExist = userExist
  },
  setUserName: (state, username) => {
    state.userName = username
  },
  setEmailSent: (state, sent) => {
    state.emailSent = sent
  },
  setToken: (state, val) => {
    state.token = val
  },
  setSmsCode: (state, val) => {
    state.sms_code = val
  },
  setPassUpdate: (state, val) => {
    state.passUpdate = val
  },
  setclientData: (state, val) => {
    state.clientData = val
  },
  setInvitationInfo: (state, val) => {
    state.invitationInfo = val
  },
  setAllProperties: (state, val) => {
    // *deprecated*
    state.allProperties = val
  },
  setAccountInfo: (state, val) => {
    state.accountInfo = val
  },
  setDashboardAccountInfo: (state, val) => {
    state.dashboardProfileInfo = val
  },
  setPropertyDetails: (state, val) => {
    // *deprecated*
    state.propertyDetails = val
  },
  setCountries: (state, val) => {
    // *deprecated*
    state.countries = val
  },
  setDeleteProp: (state, val) => {
    // *deprecated*
    state.deleteProp = val
  },
  setAddProperty: (state, val) => {
    // *deprecated*
    state.addProperty = val
  },
  setUserInfo: (state, val) => {
    state.userInfo = val
  },
  setGemeinde: (state, val) => {
    // *deprecated*
    state.gemeinde = val
  },
}

const actions = {
  tcInvitationSetPassPost({ commit, dispatch }, payload) {
    commit('showPageLoader')
    apiClient
      .post('resetPassword/update', payload)
      .then((response) => {
        commit('hidePageLoader')
        dispatch('processLoginResponse', { response: response, redirect: false })
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
      })
  },
  actionTCInvitationInfo({ commit, dispatch }, payload) {
    commit('showPageLoader')
    apiClient
      .post('resetPassword/validateCode', payload)
      .then((response) => {
        commit('hidePageLoader')
        if (response.data.success) {
          commit('setInvitationInfo', response.data.userInfo)
        } else if (response.data.userId) {
          commit('setUserName', response.data.userId)
          router.push({
            name: 'Home',
            params: {
              autoLogin: 1,
            },
          })
        } else {
          dispatch('showToast', {
            message: response.data.message,
            toastType: 'error',
          })
          router.push('/')
        }
      })
      .catch(() => {
        commit('hidePageLoader')
        commit('setInvitationInfo', '')
        router.push('/')
      })
  },
  userExist({ commit, dispatch }, userName) {
    apiClient
      .post('login/getAuthMethod', { userName: userName })
      .then((response) => {
        if (response.data.success) {
          commit('setUserExist', response.data)
        } else {
          commit('hidePageLoader')
          dispatch('showToast', {
            message: response.data.message,
            toastType: 'error',
          })
        }
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message === 'canceled' || error.response.status !== 403) {
          commit('setUserExist', { success: false, message: t('general.errors.unknownError') })
        }
      })
  },
  sendMfaCode({ commit, dispatch }, payload) {
    commit('showPageLoader')
    let endpoint = 'login/sendMfaCode'
    if (payload.resetPwdCode) {
      endpoint = 'resetPassword/sendMfaCode'
    }
    return apiClient
      .post(endpoint, payload)
      .then((response) => {
        commit('hidePageLoader')
        return response
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
        return false
      })
  },
  login({ commit, dispatch }, payload) {
    commit('showPageLoader')
    return apiClient
      .post('login/verifyCredential', payload)
      .then((response) => {
        dispatch('processLoginResponse', { response: response, redirect: true })
        commit('hidePageLoader')
        return response
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
        router.push('/')
      })
  },
  processLoginResponse({ dispatch, commit }, { response, redirect }) {
    if (response.data.success) {
      if (response.data.loggedIn) {
        localStorage.setItem('client_logged_in', '1')
        router.push(sessionStorage.getItem('deepLink') || '/dashboard')
        sessionStorage.removeItem('deepLink')
        commit('setCurrentUser', response.data.userInfo)
      }
    } else {
      dispatch('showToast', {
        message: response.data.message,
        toastType: 'error',
      })
      if (redirect) {
        router.push('/')
      }
    }
  },
  logout({ commit }) {
    commit('showPageLoader')
    apiClient
      .post('logout', null)
      .then(() => {
        commit('hidePageLoader')
        location.href = '/'
      })
      .catch(() => {
        commit('hidePageLoader')
        location.href = '/'
      })
  },
  resetPass({ commit, dispatch }, userName) {
    commit('showPageLoader')
    let successMessage = ''
    if (
      typeof this.state.Users === 'undefined' ||
      typeof this.state.Users.currentUser === 'undefined' ||
      typeof this.state.Users.currentUser.uid === 'undefined' ||
      userName === this.state.Users.currentUser.uid
    ) {
      successMessage = t('LoginNopwd.apiSuccess')
    } else {
      successMessage = t('LoginNopwd.apiSuccess_otherUser')
    }
    apiClient
      .post('login/forgotPassword', { userName: userName })
      .then((response) => {
        commit('hidePageLoader')
        if (response.data.success) {
          dispatch('showToast', {
            message: successMessage,
            toastType: 'success',
          })
        } else {
          dispatch('showToast', {
            message: response.data.message,
            toastType: 'error',
          })
        }
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
      })
  },
  datevLogin({ commit, dispatch }, payload) {
    commit('showPageLoader')
    if (typeof payload === 'undefined') {
      payload = {}
    }
    payload.oauth_type = 'datev'
    apiClient
      .post('datev/oauth', payload)
      .then((response) => {
        commit('hidePageLoader')
        window.history.replaceState({}, document.title, '/')
        if (response.data.success) {
          if (typeof response.data.oauth_url !== 'undefined' && response.data.oauth_url !== '') {
            window.location.href = response.data.oauth_url
          } else if (response.data.loggedIn) {
            dispatch('processLoginResponse', { response: response, redirect: false })
          }
        } else {
          dispatch('showToast', {
            message: response.data.message,
            toastType: 'error',
          })
        }

        return response
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
      })
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
