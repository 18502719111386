import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '@/assets/css/common.css'
import i18n from '@/i18n'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import 'primevue/resources/themes/fluent-light/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import Svgall from '@/components/Svgall.vue'
import PrimeVue from 'primevue/config'
import $bus from './plugins/events'
import { Modal } from './plugins/modal-plugin'
import { datadogLogs } from '@datadog/browser-logs'

const toastOptions = {
  position: 'top-right',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false,
}

if (process.env.VUE_APP_DATADOG_TOKEN && process.env.VUE_APP_DATADOG_SITE) {
  datadogLogs.init({
    clientToken: process.env.VUE_APP_DATADOG_TOKEN,
    site: process.env.VUE_APP_DATADOG_SITE,
    forwardErrorsToLogs: true,
    service: process.env.VUE_APP_DATADOG_SERVICE,
    beforeSend: (log) => {
      // don't log client network failures and request cancellations
      if (
        typeof log.error !== 'undefined' &&
        typeof log.http !== 'undefined' &&
        typeof log.http.status_code !== 'undefined' &&
        log.http.status_code === 0
      ) {
        return false
      }

      if (
        log.message.indexOf('[Vue warn]') !== -1 ||
        log.message.indexOf('props.column.$scopedSlots') !== -1 ||
        log.message.indexOf('Avoided redundant navigation') !== -1
      ) {
        return false
      }
    },
  })
  datadogLogs.setGlobalContext({
    host: location.host,
    tags: [],
  })
}

let app = createApp(App).use(i18n).use(store).use(router).use(Toast, toastOptions).use(PrimeVue)
app.component('Svgall', Svgall)
app.config.globalProperties.$bus = $bus
app.use(Modal)
app.mount('#app')
