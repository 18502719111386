export const defaultPropertyBenefits = {
  //Steuerbefreiungen und Steuervergünstigungen
  tax_exemption: '0', // -1-15
  benefit: '0', // 1-6
  non_separable_part_real_estate: '0', // 1-15 same list as usage_type_options
  property_exemption_benefit_type: '0', // Art des Grundstücks
  monument_identification: '0',
  //Zusätzliche Angaben, wenn die Grundsteuervergünstigung "Wohnraumförderung" vorliegt
  expiration_first_grant_period: '', //Ablaufdatum des zuerst auslaufenden Förderungszeitraum
  expiration_last_grant_period: '', //Ablaufdatum des zuletzt auslaufenden Förderungszeitraum

  //Zusätzliche Angaben, wenn ein Grundstück für steuerbefreite Zwecke hergerichtet wird
  expected_exempt_purposes: '', //Voraussichtliche Verwendung für steuerbegünstigte Zwecke in %
  tax_privileged_purposes: '0', //Steuerbefreiung; 1-15 same list as usage_type_options

  //Bei unbebauten Grundstücken mit teilweiser Grundsteuerbefreiung
  unbuilt_real_estate_exemptions: [
    {
      id: 0, //internal data, no form edit
      unbuilt_real_estate_exemption_description: '', //Bezeichnung
      tax_free_area: '', //Steuerfreie Fläche in m²
      unbuilt_real_estate_tax_exemption: '0', //Steuerbefreiung; 1-15 same list as usage_type_options
    },
  ],

  //Verwendung eines räumlich abgrenzbaren Teils des Grund und Bodens für steuerbefreite Zwecke
  use_of_definable_parts: [
    {
      id: 0, //internal data, no form edit
      spatially_definable_exemption_part_description: '', //Bezeichnung
      spatially_definable_exemption_area: '', //Steuerfreie Fläche in m²
      spatially_definable_tax_exemption_part: '0', //Steuerbefreiung; 1-15 same list as usage_type_options
    },
  ],

  //Für Teile des Grundbesitzes liegen die Voraussetzungen für eine Steuervergünstigung vor
  tax_break_conditions: [
    {
      id: 0, //internal data, no form edit
      spatially_definable_exemption_part_description: '', //Bezeichnung
      spatially_definable_exemption_area: '', //Steuerfreie Fläche in m²
      spatially_definable_tax_exemption_part: '0', //Steuerbefreiung; 1-15 same list as usage_type_options
    },
  ],
}
