export default {
  mounted() {
    this.hideCustomCalendarIconsForFirefox()
  },
  methods: {
    hideCustomCalendarIconsForFirefox() {
      const userAgent = navigator.userAgent
      const regex = /Firefox\/(?<majorRelease>\d+(\.\d+)?)/
      const match = userAgent.match(regex)
      const FirefoxVersion = match ? Number(match.groups.majorRelease) : null
      if (FirefoxVersion > 108) {
        const icons = document.querySelectorAll('.date-icon-firefox-fix')
        icons.forEach((el) => {
          el.classList.add('icon-display-none')
        })
      }
    },
  },
}
