<template>
  <div class="spiner_center_over">
    <div class="spiner_center">
      <div
        class="spinner-border text-primary"
        role="status"
      >
        <span class="sr-only"></span>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'Property',
}
</script>

<style lang="scss">
@import './assets/custom.css';
@import './assets/form_style.css';

.spiner_center {
  height: auto;
  left: 50%;
  margin: 0 auto;
  position: fixed;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}
.spiner_center_over:before {
  background: rgba(0, 0, 0, 0.6);
  content: '';
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}
</style>
