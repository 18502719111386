<template>
  <InvitationHeader />
  <div class="login_start">
    <InvitationProfileinfo />
    <PropertyChangeSection />
  </div>
  <Copyright />
</template>

<script>
import PropertyChangeSection from '@/components/PropertyChange'
import Copyright from '@/components/copyright.vue'
import InvitationHeader from '@/components/InvitationHeader'
import InvitationProfileinfo from '@/components/InvitationProfileinfo'

export default {
  name: 'PropertyChange',
  components: {
    InvitationHeader,
    InvitationProfileinfo,
    PropertyChangeSection,
    Copyright,
  },
}
</script>
