<template>
  <div>
    <div
      class="tool-tip prevent-tooltip"
      :class="[
        { 'register-tool-tip': showBottomLeft },
        { 'outside-tooltip': showOutside },
        { 'disabled-tool-tip': isDisabled },
        { 'selection-title-tooltip': selectionTitleTooltip },
      ]"
    >
      <div class="prevent-tooltip">
        <span
          v-if="isDisabled"
          class="disabled-tooltip-content"
          v-html="$t('newProperty.infoTooltip.disabled')"
        >
        </span>
        <span
          v-else
          class="prevent-tooltip"
        >
          <span
            v-html="content.replace('%img_path%', imagePath)"
            class="tooltip-content prevent-tooltip"
          ></span>
          <span
            class="info prevent-tooltip"
            v-if="url"
          >
            {{ infoContent }}
          </span>
          <a
            v-if="url"
            class="url prevent-tooltip"
            :href="url"
            target="_blank"
          >
            {{ url }}
          </a>
        </span>
      </div>
    </div>
    <span :class="[arrowOrLine, { 'disabled-side-line': isDisabled }]"></span>

    <Dialog
      :visible="showInfoImage"
      :modal="true"
      contentStyle="padding:1.5rem;"
      :showHeader="false"
      :closable="false"
      :draggable="false"
      @hide="InfoImageUrl = false"
    >
      <img
        class="info-img prevent-tooltip"
        :src="InfoImageUrl"
      />
    </Dialog>
  </div>
</template>
<script>
//import ModalDialog from "@/components/ModalDialog";
import { mapGetters } from 'vuex'
import Dialog from 'primevue/dialog'

export default {
  components: { Dialog },
  props: {
    content: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    showBottomLeft: {
      type: Boolean,
      default: false,
    },
    isRegisterContent: {
      type: Boolean,
      default: false,
    },
    showOutside: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    imagePath: {
      type: String,
      default: '',
    },
    selectionTitleTooltip: {
      type: Boolean,
      default: false,
    },
    numberOfLinesOfLabel: {
      type: Number,
      default: 1,
    },
    diffY: {
      type: String,
      default: '2em', // allows to set a greater value for y position when tooltip is shown below a field that is higher, e. g. a textarea
    },
    maxHeight: {
      type: String,
      default: 'unset', //set maxHeight of InfoTooltip for form fields near tho the forms bottom
    },
  },
  computed: {
    ...mapGetters(['InfoImageUrl']),
    infoContent() {
      return this.$t('newProperty.infoTooltip.info')
    },
    arrowOrLine() {
      return this.showOutside ? 'show-side-line' : 'tool-tip-arrow'
    },
    showInfoImage() {
      return this.InfoImageUrl !== false
    },
    additionalVerticalEm() {
      const emPerLine = 1.5
      let myAdditionalVerticalEm = 0
      if (this.numberOfLinesOfLabel > 1) {
        myAdditionalVerticalEm = (this.numberOfLinesOfLabel - 1) * emPerLine
      }
      return myAdditionalVerticalEm
    },
  },
  mounted() {
    let tooltipOutsideTopEffective = '-0.7em'
    let tooltipSidelineTopEffective = '2.5em'
    let diffYEffective = this.diffY
    if (this.numberOfLinesOfLabel === 0) {
      let transformedDiffY = this.diffY.replace('em', '')
      transformedDiffY = parseFloat(transformedDiffY, 10)
      if (!isNaN(transformedDiffY)) {
        transformedDiffY += this.additionalVerticalEm
        transformedDiffY -= 1.5
        diffYEffective = transformedDiffY.toString(10) + 'em'
      }

      tooltipOutsideTopEffective = (-2.4 + this.additionalVerticalEm + 0.2).toString(10) + 'em'
      tooltipSidelineTopEffective = (1.0 + this.additionalVerticalEm).toString(10) + 'em'
    } else if (this.numberOfLinesOfLabel > 1) {
      let transformedDiffY = this.diffY.replace('em', '')
      transformedDiffY = parseFloat(transformedDiffY, 10)
      if (!isNaN(transformedDiffY)) {
        transformedDiffY += this.additionalVerticalEm
        diffYEffective = transformedDiffY.toString(10) + 'em'
      }

      tooltipOutsideTopEffective = (-0.7 + this.additionalVerticalEm + 0.2).toString(10) + 'em'
      tooltipSidelineTopEffective = (2.5 + this.additionalVerticalEm).toString(10) + 'em'
    }

    let bodyStyle = document.body.style
    bodyStyle.setProperty('--tooltip-diff-y', diffYEffective)
    bodyStyle.setProperty('--tooltip-outside-top', tooltipOutsideTopEffective)
    bodyStyle.setProperty('--tooltip-sideline-top', tooltipSidelineTopEffective)
    bodyStyle.setProperty('--tooltip-max-height', this.maxHeight)
  },
}
</script>
<style lang="css" scoped>
:root {
  --tooltip-diff-y: 2em;
  --tooltip-outside-top: -0.7em;
  --tooltip-sideline-top: 2.5em;
  --tooltip-max-height: unset;
}

.tool-tip-arrow:before {
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: var(--api-background);
  border-style: solid;
  border-top-color: transparent;
  border-width: 8px 8px;
  content: '';
  display: block;
  height: 0;
  position: absolute;
  right: -15px;
  top: 2px;
  width: 0;
}

span.tooltip-content,
span.info {
  color: white !important;
}

.tool-tip {
  background-color: var(--api-background);
  border-radius: 6px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 15%);
  color: white;
  font-size: 13px;
  line-height: 18px;
  max-height: var(--tooltip-max-height);
  overflow-y: auto;
  padding: 10px 10px 15px 27px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.tool-tip .info {
  display: block;
  padding-bottom: 10px;
  padding-top: 15px;
}

.show-side-line {
  background-color: var(--api-background);
  display: inline-block;
  height: 40px;
  position: absolute;
  right: -7px;
  top: var(--tooltip-sideline-top);
  width: 4px;
}
.disabled-tool-tip,
.disabled-side-line {
  background-color: #f2f2f2;
}
.tool-tip-arrow:before {
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-style: solid;
  border-top-color: transparent;
  border-width: 8px 8px;
  content: '';
  display: block;
  height: 0;
  position: absolute;
  right: -15px;
  top: 2px;
  width: 0;
}

.tooltip .info-labels {
  padding: 10px 15px 10px 30px;
}

.register-tool-tip {
  font-weight: normal;
  right: -13px;
  top: 49px;
}
.register-tool-tip .tool-tip-arrow::before {
  right: -6px;
  top: 33px;
  transform: rotate(90deg);
}
.url {
  color: white;
  cursor: pointer;
  text-decoration: underline !important;
  word-break: break-all;
}
.outside-tooltip {
  padding-left: 30px;
  top: var(--tooltip-outside-top);
  transform: translateY(45px);
}
.tooltip-title {
  position: relative;
}
.tooltip-title:before {
  content: '';
  display: inline-block;
  height: 25px;
  left: -26px;
  position: absolute;
  width: 25px;
}
.tooltip-search-icon::before {
  background: url('~@/assets/img/infotooltip/white-search.svg') no-repeat center / contain;
  background-size: 18px;
}
.tooltip-tick-icon::before {
  background: url('~@/assets/img/infotooltip/tick_password.svg') no-repeat center / contain;
  background-size: 18px;
}
.tooltip-file-icon::before {
  background: url('~@/assets/img/infotooltip/file-white.svg') no-repeat center / contain;
  background-size: 15px;
}
.tooltip-mouse-icon::before {
  background: url('~@/assets/img/infotooltip/mouse-white.svg') no-repeat center / contain;
  background-size: 13px;
}
.tooltip-info-icon::before {
  background: url('~@/assets/img/infotooltip/info-white.svg') no-repeat center / contain;
  background-size: 17px;
}

.tooltip-content :deep(.tooltip-title) {
  position: relative;
}

.tooltip-content :deep(.tooltip-title::before) {
  content: '';
  display: inline-block;
  height: 25px;
  left: -26px;
  position: absolute;
  width: 25px;
}

.tooltip-content :deep(.tooltip-search-icon::before) {
  background: url('~@/assets/img/infotooltip/white-search.svg') no-repeat center / contain;
  background-size: 18px;
}
.tooltip-content :deep(.tooltip-tick-icon::before) {
  background: url('~@/assets/img/infotooltip/tick_password.svg') no-repeat center / contain;
  background-size: 18px;
}
.tooltip-content :deep(.tooltip-file-icon::before) {
  background: url('~@/assets/img/infotooltip/file-white.svg') no-repeat center / contain;
  background-size: 15px;
}
.tooltip-content :deep(.tooltip-mouse-icon::before) {
  background: url('~@/assets/img/infotooltip/mouse-white.svg') no-repeat center / contain;
  background-size: 13px;
}
.tooltip-content :deep(.tooltip-info-icon::before) {
  background: url('~@/assets/img/infotooltip/info-white.svg') no-repeat center / contain;
  background-size: 17px;
}

.tooltip-content :deep(a) {
  text-decoration: underline;
}
.tooltip-content :deep(a:not([href]):not([class])) {
  text-decoration: underline;
}
.tooltip-content :deep(.prevent-tooltip) {
}
.tooltip-content :deep(.information-image) {
  cursor: pointer;
  height: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100px;
}

.disabled-tooltip-content :deep(.tooltip-title) {
  position: relative;
}

.disabled-tooltip-content :deep(.tooltip-title::before) {
  content: '';
  display: inline-block;
  height: 25px;
  left: -26px;
  position: absolute;
  width: 25px;
}

.disabled-tooltip-content :deep(.tooltip-info-icon::before) {
  background-color: var(--api-font);
  -webkit-mask-image: url('~@/assets/img/infotooltip/info-white.svg');
  mask-image: url('~@/assets/img/infotooltip/info-white.svg');
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 17px;
  mask-size: 17px;
}

@media (min-width: 992px) {
  .tool-tip {
    right: -264px;
    width: 252px;
  }
}

@media (min-width: 1200px) {
  .tool-tip {
    right: -317px;
    width: 305px;
  }
}

@media (min-width: 1400px) {
  .tool-tip {
    right: -370px;
    width: 358px;
  }
}

@media (max-width: 991px) {
  .tool-tip {
    right: auto;
    top: var(--tooltip-diff-y);
    width: 358px;
  }
}

@media (max-width: 767px) {
  .show-side-line {
    right: 0;
  }
}

@media (max-width: 1290px) {
  .tool-tip-arrow:before {
    border-bottom-color: var(--api-background);
    border-right-color: transparent;
    right: 0;
    top: 12px;
  }
}

.modal_backdrop_tooltip :deep(.modal) {
  height: auto !important;
  max-width: 800px !important;
  padding-bottom: 20px;
}

.info-img {
  height: auto;
  width: 100%;
}
</style>
