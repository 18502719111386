<template>
  <!-- Property  Start -->
  <section class="login_form section newproperty">
    <div class="container">
      <div class="steps">
        <!-- Mobile Topbar-->
        <div class="mobile-top-bar">
          <div class="step-list">
            <!-- Edit existing Property mode => steps are clickable -->
            <ul class="step-list-ul">
              <li
                v-for="(myStep, index) in steps"
                :key="index"
                @click.prevent="gotoStep(myStep.component)"
                :class="evaluateClassForStep(myStep)"
                v-show="myStep.activated === true"
              >
                <a
                  href="javascript:void(0)"
                  :class="evaluateClassForStep(index)"
                  :style="
                    evaluateClassForStep(index) == 'selected'
                      ? mselectedfontColor
                      : evaluateClassForStep(index) == 'unselected'
                        ? munselectedfontColor
                        : ''
                  "
                >
                  <span class="steptitle-img">
                    <Svgall
                      class="property_nav"
                      v-if="index <= step && myStep.coloredImageAsset !== ''"
                      :name="myStep.coloredSvg"
                      :color="primaryColor"
                    />
                    <img
                      :src="myStep.greyImageAsset"
                      v-if="index > step && myStep.greyImageAsset !== ''"
                    />
                  </span>
                  <span class="step-title">{{ myStep.title }}</span></a
                >
              </li>
              <li
                v-show="canPerformElsterValidation"
                @click.prevent="startElsterValidation()"
              >
                <hr class="subheading_devide nav_left_hr" />
                <a
                  href="javascript:void(0)"
                  class="nav_add_on"
                >
                  <span class="steptitle-img">
                    <Svgall
                      class="property_nav"
                      name="elster_validation"
                      :color="primaryColor"
                    />
                  </span>
                  <span class="step-title">{{ $t('elster.validationNavTitle') }}</span>
                </a>
              </li>
            </ul>

            <!-- Create new Property mode => steps are not clickable // v-show="step < index+2 && myStep.activated === true" -->
            <ul
              class="step-list-ul"
              v-if="false"
            >
              <li
                v-for="(myStep, index) in steps"
                :key="index"
                :class="step < 1 ? 'left_divider' : 'addbefore'"
                v-show="index <= 2"
              >
                <a
                  href="javascript:void(0)"
                  :class="evaluateClassForStep(index)"
                  :style="
                    evaluateClassForStep(index) == 'selected'
                      ? mselectedfontColor
                      : evaluateClassForStep(index) == 'unselected'
                        ? munselectedfontColor
                        : ''
                  "
                >
                  <span class="steptitle-img">
                    <Svgall
                      class="property_nav"
                      v-if="index <= step && myStep.coloredImageAsset !== ''"
                      :name="myStep.coloredSvg"
                      :color="primaryColor"
                    />
                    <img
                      :src="myStep.greyImageAsset"
                      v-if="index > step && myStep.greyImageAsset !== ''"
                    />
                  </span>
                  <span class="step-title">{{ myStep.title }}</span></a
                >
              </li>
            </ul>
          </div>
        </div>

        <!-- Left Side Bar -->
        <div class="left-side-bar">
          <div class="step-list">
            <!-- Edit existing Property mode => steps are clickable -->
            <ul class="step-list-ul">
              <li
                v-for="(myStep, index) in steps"
                :key="index"
                @click.prevent="gotoStep(myStep.component)"
                :class="evaluateClassForStep(myStep)"
                v-show="myStep.activated === true"
              >
                <a
                  href="javascript:void(0)"
                  :class="evaluateClassForStep(index)"
                  :style="
                    evaluateClassForStep(index) == 'selected'
                      ? selectedfontColor
                      : evaluateClassForStep(index) == 'unselected'
                        ? unselectedfontColor
                        : ''
                  "
                >
                  <span class="steptitle-img">
                    <Svgall
                      class="property_nav"
                      v-if="index <= step && myStep.coloredImageAsset !== ''"
                      :name="myStep.coloredSvg"
                      :color="primaryColor"
                    />
                    <img
                      :src="myStep.greyImageAsset"
                      v-if="index > step && myStep.greyImageAsset !== ''"
                    />
                  </span>
                  <span class="step-title">{{ myStep.title }}</span>
                </a>
              </li>
              <li
                v-show="canPerformElsterValidation"
                @click.prevent="startElsterValidation()"
              >
                <hr class="subheading_devide nav_left_hr" />
                <a
                  href="javascript:void(0)"
                  class="nav_add_on"
                >
                  <span class="steptitle-img">
                    <Svgall
                      class="property_nav"
                      name="elster_validation"
                      :color="primaryColor"
                    />
                  </span>
                  <span class="step-title">{{ $t('elster.validationNavTitle') }}</span>
                </a>
              </li>
            </ul>

            <!-- Create new Property mode => steps are not clickable -->
            <ul
              class="step-list-ul"
              v-if="false"
            >
              <li
                v-for="(myStep, index) in steps"
                :key="index"
                :class="evaluateClassForStep(myStep)"
                v-show="myStep.activated === true && index <= 2"
              >
                <a
                  href="javascript:void(0)"
                  :class="evaluateClassForStep(index)"
                  :style="
                    evaluateClassForStep(index) == 'selected'
                      ? selectedfontColor
                      : evaluateClassForStep(index) == 'unselected'
                        ? unselectedfontColor
                        : ''
                  "
                >
                  <span class="steptitle-img">
                    <Svgall
                      class="property_nav"
                      v-if="index <= step && myStep.coloredImageAsset !== ''"
                      :name="myStep.coloredSvg"
                      :color="primaryColor"
                    />
                    <img
                      :src="myStep.greyImageAsset"
                      v-if="index > step && myStep.greyImageAsset !== ''"
                    />
                  </span>
                  <span class="step-title">{{ myStep.title }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <!-- Steps Content Section -->
        <div>
          <div class="quiz">
            <PropertyAddress
              :class="steps[step].component === 'PropertyAddress' ? 'active' : ''"
              ref="childPropertyAddress"
              @cancelPropVisible="prev"
              @call-for-next-step="propertyAddressNextStep"
              @call-for-save-property="callForSaveProperty"
              @did-change-federal-state="propertyAddressDidChangeFederalState"
              v-show="steps[step].component === 'PropertyAddress' && steps[step].activated === true"
            ></PropertyAddress>
            <PropertyDetails
              :class="steps[step].component === 'PropertyDetails' ? 'active' : ''"
              ref="childPropertyDetails"
              @cancelPropVisible="prev"
              @call-for-preceding-step="precedingStep"
              @call-for-next-step="nextStep"
              @call-for-save-property="callForSaveProperty"
              @did-change-economic-entity-type="propertyDetailsDidChangeEconomicEntityType"
              v-show="steps[step].component === 'PropertyDetails' && steps[step].activated === true"
            ></PropertyDetails>
            <PropertyMoreDetails
              :class="steps[step].component === 'PropertyMoreDetails' ? 'active' : ''"
              ref="childPropertyMoreDetails"
              @cancelPropVisible="prev"
              @call-for-preceding-step="precedingStep"
              @call-for-next-step="nextStep"
              @call-for-save-property="callForSaveProperty"
              @show-provisioning-modal-dialog="openDataProvisioningDialog"
              v-show="steps[step].component === 'PropertyMoreDetails' && steps[step].activated === true"
            ></PropertyMoreDetails>
            <PropertyBenefits
              :class="steps[step].component === 'PropertyBenefits' ? 'active' : ''"
              ref="childPropertyBenefits"
              @cancelPropVisible="prev"
              @call-for-preceding-step="precedingStep"
              @call-for-next-step="nextStep"
              @call-for-save-property="callForSaveProperty"
              v-show="steps[step].component === 'PropertyBenefits' && steps[step].activated === true"
            ></PropertyBenefits>
            <PropertyCommunity
              :class="steps[step].component === 'PropertyCommunity' ? 'active' : ''"
              ref="childPropertyCommunity"
              @cancelPropVisible="prev"
              @call-for-preceding-step="precedingStep"
              @call-for-next-step="nextStep"
              @call-for-save-property="callForSaveProperty"
              v-show="steps[step].component === 'PropertyCommunity' && steps[step].activated === true"
            ></PropertyCommunity>
            <PropertyOwnership
              :class="steps[step].component === 'PropertyOwnership' ? 'active' : ''"
              ref="childPropertyOwnership"
              @cancelPropVisible="prev"
              @call-for-preceding-step="precedingStep"
              @call-for-next-step="nextStep"
              @call-for-save-property="callForSaveProperty"
              v-show="steps[step].component === 'PropertyOwnership' && steps[step].activated === true"
            ></PropertyOwnership>
            <PropertyBuildings
              :class="steps[step].component === 'PropertyBuildings' ? 'active' : ''"
              ref="childPropertyBuildings"
              @cancelPropVisible="prev"
              @call-for-preceding-step="precedingStep"
              @call-for-next-step="nextStep"
              @call-for-save-property="callForSaveProperty"
              @show-roometric-dialog="openRoometricDialog"
              v-show="steps[step].component === 'PropertyBuildings' && steps[step].activated === true"
            ></PropertyBuildings>
            <PropertyResidential
              :class="steps[step].component === 'PropertyResidential' ? 'active' : ''"
              ref="childPropertyResidential"
              @cancelPropVisible="prev"
              @call-for-preceding-step="precedingStep"
              @call-for-next-step="nextStep"
              @call-for-save-property="callForSaveProperty"
              @show-roometric-dialog="openRoometricDialog"
              v-show="steps[step].component === 'PropertyResidential' && steps[step].activated === true"
            ></PropertyResidential>
            <PropertyNonResidential
              :class="steps[step].component === 'PropertyNonResidential' ? 'active' : ''"
              ref="childPropertyNonResidential"
              @cancelPropVisible="prev"
              @call-for-preceding-step="precedingStep"
              @call-for-next-step="nextStep"
              @call-for-save-property="callForSaveProperty"
              @show-roometric-dialog="openRoometricDialogNonResidential"
              v-show="steps[step].component === 'PropertyNonResidential' && steps[step].activated === true"
            ></PropertyNonResidential>
            <PropertyLeasehold
              :class="steps[step].component === 'PropertyLeasehold' ? 'active' : ''"
              ref="childPropertyLeasehold"
              @cancelPropVisible="prev"
              @call-for-preceding-step="precedingStep"
              @call-for-next-step="nextStep"
              @call-for-save-property="callForSaveProperty"
              v-show="steps[step].component === 'PropertyLeasehold' && steps[step].activated === true"
            ></PropertyLeasehold>
            <PropertyAgriculture
              :class="steps[step].component === 'PropertyAgriculture' ? 'active' : ''"
              ref="childPropertyAgriculture"
              @cancelPropVisible="prev"
              @call-for-preceding-step="precedingStep"
              @call-for-next-step="nextStep"
              @call-for-save-property="callForSaveProperty"
              v-show="steps[step].component === 'PropertyAgriculture' && steps[step].activated === true"
            ></PropertyAgriculture>
            <PropertyLivestock
              :class="steps[step].component === 'PropertyLivestock' ? 'active' : ''"
              ref="childPropertyLivestock"
              @cancelPropVisible="prev"
              @call-for-preceding-step="precedingStep"
              @call-for-next-step="nextStep"
              @call-for-save-property="callForSaveProperty"
              v-show="steps[step].component === 'PropertyLivestock' && steps[step].activated === true"
            ></PropertyLivestock>
            <Dokumente
              :active="steps[step].component === 'Dokumente'"
              ref="childDokumente"
              @cancelPropVisible="prev"
              @call-for-preceding-step="precedingStep"
              @call-for-next-step="nextStep"
              @call-for-save-property="callForSaveProperty"
              v-show="steps[step].component === 'Dokumente' && steps[step].activated === true"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="spiner_center_over"
      v-if="!isNewProperty && isloading"
    >
      <div
        class="spiner_center"
        v-if="!isNewProperty && isloading"
      >
        <div
          class="spinner-border text-primary"
          role="status"
          v-if="!isNewProperty && isloading"
        >
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
  </section>

  <!-- New Property added user dialog -->
  <ModalDialog
    v-show="didAddNewProperty"
    :showClose="false"
    class="modal_wide modal_wide_cancel"
  >
    <template
      v-slot:body
      class="modal-inside"
    >
      <div class="popup_title b_btm border">
        <strong> {{ $t('Property_added.title') }} </strong>
      </div>
      <div class="textfileformating textfileformating02">
        <div class="filemin_cont cancel-popup">
          <div class="modal-text text-center">
            {{ $t('Property_added.text1') }}<br /><br />
            {{ $t('Property_added.text2') }}
          </div>
        </div>
        <div class="btn_170 btn_cancel">
          <button
            class="modal-button"
            @click="editAddedProperty"
          >
            {{ $t('Property_added.editButton') }}
          </button>
          <button
            class="modal-button"
            @click="gotoDashboard"
          >
            {{ $t('Property_added.dashboardButton') }}
          </button>
        </div>
      </div>
    </template>
  </ModalDialog>

  <!-- Data Acquisition modal loader -->
  <ModalDialog
    v-show="toggleDataAcquisitionLoader"
    :showClose="false"
    class="modal_wide modal_wide_cancel"
  >
    <template
      v-slot:body
      class="modal-inside"
    >
      <div class="popup_title b_btm border">
        <strong> {{ $t('newProperty.propertyMoreDetails.parcels.loading').toString() }} </strong>
      </div>
      <div class="textfileformating textfileformating02">
        <div class="filemin_cont cancel-popup">
          <div class="modal-text card-header text-center">
            <img
              class="border-success spinner-border w-25 h-25 border-0"
              src="@/assets/loading.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </template>
  </ModalDialog>

  <!-- Data Acquisition Abo Requied -->
  <ModalDialog
    v-show="toggleDataAcquisitionAboRequied"
    :showClose="false"
    @close="toggleDataAcquisitionAboRequied = false"
    class="modal_wide modal_wide_cancel"
  >
    <template
      v-slot:body
      class="modal-inside"
    >
      <div class="popup_title b_btm border">
        <strong>
          {{ $t('newProperty.propertyMoreDetails.parcels.data_acquisition_limit_error_title').toString() }}
        </strong>
      </div>
      <div class="textfileformating textfileformating02">
        <div class="filemin_cont cancel-popup">
          <div class="modal-text text-center">
            {{ $t('newProperty.propertyMoreDetails.parcels.data_acquisition_limit_error_text').toString() }}
          </div>
        </div>
        <div class="btn_170 btn_cancel">
          <button
            class="modal-button"
            @click="toggleDataAcquisitionAboRequied = false"
          >
            {{ $t('dashboard.Conclude') }}
          </button>
        </div>
      </div>
    </template>
  </ModalDialog>

  <ModalDialog
    class="modal_wide modal_wide_cancel"
    v-show="cancelPropVisible"
    @close="cancelPropVisible = false"
  >
    <template
      v-slot:body
      class="modal-inside"
    >
      <div class="popup_title b_btm">
        <h2>{{ $t('newProperty.Interrupt') }}</h2>
      </div>
      <div class="textfileformating textfileformating02">
        <div class="filemin_cont cancel-popup">
          <div class="modal-text">
            <span>
              {{ cancelText }}
            </span>
          </div>
        </div>
        <div class="btn_170 btn_cancel">
          <button
            class="border-button-style"
            @click="cancelProp('Nein')"
          >
            {{ $t('newProperty.no') }}
          </button>
          <button
            class="modal-button"
            :style="[primaryColor ? { background: primaryColor } : '']"
            @click="cancelProp('Ja')"
          >
            {{ $t('newProperty.yes') }}
          </button>
        </div>
      </div>
    </template>
  </ModalDialog>

  <!-- Property save dialog from "Weiter"-Button -->
  <ModalDialog
    class="modal_wide modal_wide_cancel"
    v-show="savePropVisible && canSaveProperty"
    @close="savePropVisible = false"
  >
    <template
      v-slot:body
      class="modal-inside"
    >
      <div class="popup_title b_btm b_btm_indent">
        <h2>{{ $t('newProperty.Save') }}</h2>
      </div>
      <div class="textfileformating textfileformating02">
        <div class="filemin_cont2 cancel-popup">
          <div class="modal-text">
            <span v-if="isNewProperty">{{ $t('newProperty.AddQuestion') }} </span>
            <span v-if="!isNewProperty">{{ $t('newProperty.UpdateQuestion') }} </span>
          </div>
          <div
            class="form-group"
            v-show="shouldAskForClientComment"
          >
            <div class="row_setp_main">
              <div class="data-form">
                <h2 class="subheading_title comment_field_label">
                  {{ $t('newProperty.label_client_comment') }}
                </h2>
                <textarea
                  type="text"
                  autocomplete="off"
                  v-model="saveForm.client_comment"
                  placeholder=""
                  class="input-field comment-area"
                  ref="saveForm_client_comment_2"
                  @keyup.esc="goBackFromSaveProperty"
                  @keyup.enter="doSavePropertyIfEmptyComment"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="btn_170 btn_cancel btn_cancel_indent save_confirmation">
          <button
            class="border-button-style"
            @click="goBackFromSaveProperty"
          >
            {{ $t('newProperty.GoBack') }}
          </button>
          <button
            class="border-button-style"
            @click="doNotSaveProperty"
          >
            {{ $t('newProperty.DoNotSave') }}
          </button>
          <button
            class="modal-button padding-right-zero"
            :style="[primaryColor ? { background: primaryColor } : '']"
            @click="doSaveProperty"
          >
            {{ $t('newProperty.SaveNow') }}
          </button>
        </div>
      </div>
    </template>
  </ModalDialog>

  <!-- Property add client_comment dialog from "Speichern"-Button -->
  <ModalDialog
    class="modal_wide modal_wide_cancel"
    v-show="addCommentVisible"
    @close="performSaveProperty"
  >
    <template
      v-slot:body
      class="modal-inside"
    >
      <div class="popup_title b_btm b_btm_indent">
        <h2>{{ $t('newProperty.ModalClientComment.title') }}</h2>
      </div>
      <div class="textfileformating textfileformating02">
        <div class="filemin_cont2 cancel-popup">
          <div class="modal-text">
            <span>{{ $t('newProperty.ModalClientComment.body') }} </span>
          </div>
          <div class="form-group">
            <div class="row_setp_main">
              <div class="data-form">
                <h2 class="subheading_title comment_field_label">
                  {{ $t('newProperty.ModalClientComment.label_client_comment') }}
                </h2>
                <textarea
                  type="text"
                  autocomplete="off"
                  v-model="saveForm.client_comment"
                  placeholder=""
                  class="input-field comment-area"
                  ref="saveForm_client_comment"
                  @keyup.esc="performSaveProperty"
                  @keyup.enter="performSavePropertyIfEmptyComment"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="btn_170 btn_170_right">
          <button
            class="modal-button"
            :style="[primaryColor ? { background: primaryColor } : '']"
            @click="performSaveProperty"
          >
            {{ $t('newProperty.ModalClientComment.button_continue') }}
          </button>
        </div>
      </div>
    </template>
  </ModalDialog>

  <!-- Cannot save property: show steps having errors -->
  <ModalDialog
    class="modal_wide modal_wide_cancel"
    v-show="stepsHavingErrorsVisible"
    @close="stepsHavingErrorsVisible = false"
  >
    <template
      v-slot:body
      class="modal-inside"
    >
      <div class="popup_title b_btm b_btm_indent">
        <h2>{{ $t('newProperty.ModalStepsHavingErrors.title') }}</h2>
      </div>
      <div class="textfileformating textfileformating02">
        <div class="filemin_cont2 cancel-popup">
          <div class="modal-text">
            <span v-show="stepsHavingErrors.length === 1">{{ $t('newProperty.ModalStepsHavingErrors.textOne') }} </span>
            <span v-show="stepsHavingErrors.length > 1">{{ $t('newProperty.ModalStepsHavingErrors.textMulti') }} </span>
          </div>
          <div class="modal-text modal_display_flex">
            <div
              class="modal_display_flex_row"
              v-for="(errorStep, errorStepIndex) in stepsHavingErrors"
              :key="errorStepIndex"
              @click.prevent="gotoStep(errorStep.component)"
            >
              <Svgall
                class="property_nav modal-error-step pointer-cursor me-2"
                :name="errorStep.coloredSvg"
                :color="primaryColor"
              />
              <span class="step-title primary-color pointer-cursor ml-4">{{ errorStep.title }}</span>
            </div>
          </div>
        </div>
        <div class="btn_170 btn_170_right btn_cancel">
          <button
            class="button-no-highlight"
            @click="stepsHavingErrorsVisible = false"
          >
            {{ $t('newProperty.ModalStepsHavingErrors.cancelButton') }}
          </button>
        </div>
      </div>
    </template>
  </ModalDialog>

  <!-- Elster validation pre-check: show steps having errors -->
  <ModalDialog
    class="modal_wide modal_wide_cancel"
    v-show="elsterStepsHavingErrorsVisible"
    @close="elsterStepsHavingErrorsVisible = false"
  >
    <template
      v-slot:body
      class="modal-inside"
    >
      <div class="popup_title b_btm b_btm_indent">
        <h2>{{ $t('newProperty.ElsterModalStepsHavingErrors.title') }}</h2>
      </div>
      <div class="textfileformating textfileformating02">
        <div class="filemin_cont2 cancel-popup">
          <div class="modal-text">
            <span v-show="stepsHavingErrors.length === 1"
              >{{ $t('newProperty.ElsterModalStepsHavingErrors.textOne') }}
            </span>
            <span v-show="stepsHavingErrors.length > 1"
              >{{ $t('newProperty.ElsterModalStepsHavingErrors.textMulti') }}
            </span>
          </div>
          <div class="modal-text modal_display_flex">
            <div
              class="modal_display_flex_row"
              v-for="(errorStep, errorStepIndex) in stepsHavingErrors"
              :key="errorStepIndex"
              @click.prevent="gotoStep(errorStep.component)"
            >
              <Svgall
                class="property_nav modal-error-step pointer-cursor me-2"
                :name="errorStep.coloredSvg"
                :color="primaryColor"
              />
              <span class="step-title primary-color pointer-cursor ml-4">{{ errorStep.title }}</span>
            </div>
          </div>
        </div>
        <div class="btn_170 btn_170_right btn_cancel">
          <button
            class="button-no-highlight"
            @click="elsterStepsHavingErrorsVisible = false"
          >
            {{ $t('newProperty.ModalStepsHavingErrors.cancelButton') }}
          </button>
        </div>
      </div>
    </template>
  </ModalDialog>

  <!-- Elster Validation Splash Screen -->
  <ModalDialog
    class="modal_wide modal_wide_cancel"
    v-show="elsterValidation.splashScreenVisible"
    @close="cancelElsterValidation()"
  >
    <template
      v-slot:body
      class="modal-inside"
    >
      <div class="popup_title b_btm b_btm_indent">
        <h2>{{ $t('elster.splashScreen.title') }}</h2>
      </div>
      <div class="textfileformating textfileformating02">
        <div class="filemin_cont2 cancel-popup">
          <div class="modal-text">
            <span>{{ $t('elster.splashScreen.text') }} </span>
          </div>
          <div class="modal-text elster_display_flex">
            <div
              class="elster_display_flex_row"
              v-for="(validationStep, elsterValidationStepIndex) in elsterValidation.validationSteps"
              :key="elsterValidationStepIndex"
            >
              <div class="elster_display_flex_col_number text-primary">
                <span :class="getClassWaiting(validationStep)"
                  ><i
                    :class="getIconClassByNumber(elsterValidationStepIndex + 1)"
                    class="text-primary fw-bold"
                  ></i
                ></span>
              </div>
              <div class="elster_display_flex_col_description">
                <span :class="getClassWaiting(validationStep)">{{ validationStep.infoText }}</span>
              </div>
              <div class="elster_display_flex_col_status">
                <div
                  v-show="validationStep.running"
                  class="spinner-border spinner-border-m text-primary m-0"
                >
                  <span class="sr-only"></span>
                </div>
                <i
                  v-show="validationStep.success"
                  class="bi bi-check-circle text-success"
                ></i>
                <i
                  v-show="validationStep.failed"
                  class="bi bi-x-circle text-danger"
                ></i>
              </div>
            </div>
          </div>
          <div
            v-show="elsterValidation.splashScreenErrorMsg !== ''"
            class="modal-text"
          >
            <span class="elster_error">{{ elsterValidation.splashScreenErrorMsg }} </span>
          </div>
        </div>
        <div class="btn_170 btn_170_right btn_cancel">
          <button
            class="button-no-highlight"
            @click="cancelElsterValidation"
          >
            {{ $t('elster.splashScreen.cancelButton') }}
          </button>
        </div>
      </div>
    </template>
  </ModalDialog>

  <!-- Elster Validation Success Screen -->
  <ModalDialog
    class="modal_wide modal_wide_cancel"
    v-show="elsterValidation.successScreenVisible"
    @close="closeElsterSuccessScreen()"
  >
    <template
      v-slot:body
      class="modal-inside"
    >
      <div class="popup_title b_btm b_btm_indent">
        <h2>{{ $t('elster.successScreen.title') }}</h2>
      </div>
      <div class="textfileformating textfileformating02">
        <div
          class="filemin_cont2 cancel-popup"
          style="text-align: center"
        >
          <div
            class="modal-text"
            style="font-size: 100px"
          >
            <i class="bi bi-check-circle-fill text-success"></i>
          </div>
          <div class="modal-text">
            <span style="font-weight: bold">{{ $t('elster.successScreen.text') }} </span>
          </div>
        </div>
        <div class="btn_170 btn_170_right">
          <button
            class="modal-button"
            :style="[primaryColor ? { background: primaryColor } : '']"
            @click="closeElsterSuccessScreen"
          >
            {{ $t('elster.successScreen.okButton') }}
          </button>
        </div>
      </div>
    </template>
  </ModalDialog>

  <!-- Elster Validation Error Screen -->
  <ModalDialogLarge
    class="modal_wide modal_wide_cancel"
    v-show="elsterValidation.errorScreenVisible"
    @close="closeElsterErrorScreen()"
  >
    <template
      v-slot:body
      class="modal-inside"
    >
      <div class="popup_title b_btm b_btm_indent">
        <h2>{{ $t('elster.errorScreen.title') }}</h2>
      </div>
      <div class="textfileformating textfileformating02 elster_scroll">
        <div
          class="filemin_cont2 cancel-popup"
          style="text-align: center"
        >
          <div
            class="modal-text"
            style="font-size: 100px"
          >
            <i class="bi bi-exclamation-triangle-fill text-warning"></i>
          </div>
          <div class="modal-text">
            <span style="font-weight: bold">{{ $t('elster.errorScreen.text1') }} </span>
          </div>
          <div class="modal-text">
            <span>{{ $t('elster.errorScreen.text2') }} </span>
          </div>
          <div
            v-show="elsterValidation.elsterValidationErrors.length > 0"
            style="text-align: left"
          >
            <div class="modal-text">
              <span>{{ $t('elster.errorScreen.errorsTitle') }} </span>
            </div>
            <ul>
              <li
                v-for="(myError, index) in elsterValidation.elsterValidationErrors"
                :key="index"
              >
                <p>{{ myError.readable }}</p>
              </li>
            </ul>
          </div>
          <div
            v-show="elsterValidation.elsterValidationHints.length > 0"
            style="text-align: left"
          >
            <div class="modal-text">
              <span>{{ $t('elster.errorScreen.hintsTitle') }} </span>
            </div>
            <ul>
              <li
                v-for="(myHint, index) in elsterValidation.elsterValidationHints"
                :key="index"
              >
                <p>{{ myHint.readable }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="btn_170 btn_170_right">
          <button
            class="modal-button"
            :style="[primaryColor ? { background: primaryColor } : '']"
            @click="closeElsterErrorScreen"
          >
            {{ $t('elster.successScreen.okButton') }}
          </button>
        </div>
      </div>
    </template>
  </ModalDialogLarge>

  <!-- Skendata data provisioning pre-check: show steps having errors -->
  <ModalDialog
    class="modal_wide modal_wide_cancel"
    v-show="skendataStepsHavingErrorsVisible"
    @close="skendataStepsHavingErrorsVisible = false"
  >
    <template
      v-slot:body
      class="modal-inside"
    >
      <div class="popup_title b_btm b_btm_indent">
        <h2>{{ $t('newProperty.SkendataModalStepsHavingErrors.title') }}</h2>
      </div>
      <div class="textfileformating textfileformating02">
        <div class="filemin_cont2 cancel-popup">
          <div class="modal-text">
            <span v-show="stepsHavingErrors.length === 1"
              >{{ $t('newProperty.SkendataModalStepsHavingErrors.textOne') }}
            </span>
            <span v-show="stepsHavingErrors.length > 1"
              >{{ $t('newProperty.SkendataModalStepsHavingErrors.textMulti') }}
            </span>
          </div>
          <div class="modal-text modal_display_flex">
            <div
              class="modal_display_flex_row"
              v-for="(errorStep, errorStepIndex) in stepsHavingErrors"
              :key="errorStepIndex"
              @click.prevent="gotoStep(errorStep.component)"
            >
              <Svgall
                class="property_nav modal-error-step pointer-cursor me-2"
                :name="errorStep.coloredSvg"
                :color="primaryColor"
              />
              <span class="step-title primary-color pointer-cursor ml-4">{{ errorStep.title }}</span>
            </div>
          </div>
        </div>
        <div class="btn_170 btn_170_right btn_cancel">
          <button
            class="button-no-highlight"
            @click="skendataStepsHavingErrorsVisible = false"
          >
            {{ $t('newProperty.ModalStepsHavingErrors.cancelButton') }}
          </button>
        </div>
      </div>
    </template>
  </ModalDialog>

  <PropertyModalDialog
    v-show="this.dataProvisioningDialogVisible === true"
    @close="closeDataProvisioningDialog"
    class="modal_wide"
  >
    <template
      v-slot:body
      class="modal-inside"
    >
      <div>
        <div class="popup_title">
          <h2>{{ $t('data_provisioning.title') }}</h2>
        </div>
        <hr class="subheading_devide" />

        <div class="modal-tab-border-home tab-top-height">
          <!-- Liegenschaftsinformationen abrufen -->
          <div class="modal-content-highlight modal-content">
            <div class="modal-icon">
              <img
                class="modal-img"
                src="@/assets/note.svg"
                alt=""
              />
            </div>
            <div>
              <div class="modal-content-div modal-content-headline">
                {{ $t('dashboard.info1.headline1') }}
              </div>
              <div class="modal-content-div">
                {{ $t('dashboard.info1.block1') }}
              </div>
              <div class="modal-content-div">
                <span v-html="$t('dashboard.info1.block2')"></span>
              </div>
              <div class="modal-content-div modal-content-headline">
                {{ $t('dashboard.info1.headline2') }}
              </div>
              <div class="modal-content-div">
                {{ $t('dashboard.info1.block3') }}
              </div>
              <div class="modal-button-row-inside">
                <button
                  class="btn btn-primary button-style01 modal-button-info modal-button-left bg-success text-white"
                  @click="confirmOpenDataAacquisitionAPI"
                >
                  {{ $t('data_provisioning.button_text') }}
                </button>
                <!--                <button class="modal-button-display-only btn btn-primary button-style01 modal-button-info modal-button-left"-->
                <!--                        disabled>-->
                <!--                  {{ $t('data_provisioning.coming_soon') }}-->
                <!--                </button>-->
              </div>
            </div>
          </div>

          <div><br /></div>

          <!-- Grundbuchauszug anfordern -->
          <div class="modal-content">
            <div class="modal-icon">
              <img
                class="modal-img"
                src="@/assets/note.svg"
                alt=""
              />
            </div>
            <div>
              <div class="modal-content-div modal-content-headline">
                {{ $t('dashboard.info2.title') }}
              </div>
              <div class="modal-content-div">
                {{ $t('dashboard.info2.block1') }}
              </div>
              <div class="modal-content-div">
                {{ $t('dashboard.info2.block2') }}
              </div>
              <div class="modal-content-div">
                {{ $t('dashboard.info2.more') }}
              </div>
              <div class="modal-content-div">
                <a
                  href="https://hilfe.grundsteuer-digital.de/faq/grundbuchauszug/"
                  target="_blank"
                >
                  https://hilfe.grundsteuer-digital.de/faq/grundbuchauszug/
                </a>
              </div>
              <div class="modal-content-div"></div>
              <div class="modal-content-div modal-content-headline">
                {{ $t('dashboard.info2.headline2') }}
              </div>
              <div class="modal-content-div">
                {{ $t('dashboard.info2.block3') }}
              </div>
              <div class="modal-button-row-inside">
                <button
                  class="btn btn-primary button-style01 modal-button-info modal-button-left"
                  @click="openGrundbuchauszugWebsite"
                >
                  {{ $t('dashboard.info2.button_text') }}
                </button>
              </div>
            </div>
          </div>

          <div><br /></div>

          <!-- Übersicht GeoViewer -->
          <div class="modal-content">
            <div class="modal-icon">
              <img
                class="modal-img"
                src="@/assets/note.svg"
                alt=""
              />
            </div>
            <div>
              <div class="modal-content-div modal-content-headline">
                {{ $t('dashboard.info3.headline1') }}
              </div>
              <div class="modal-content-div">
                {{ $t('dashboard.info3.block1') }}
              </div>
              <div class="modal-content-div">
                <span v-html="$t('dashboard.info3.block2')"></span>
              </div>
              <div class="modal-content-div">
                {{ $t('dashboard.info3.block3') }}
              </div>
              <div class="modal-button-row-inside">
                <button
                  class="btn btn-primary button-style01 modal-button-info modal-button-left"
                  @click="openGeoViewerWebsite"
                >
                  {{ $t('dashboard.info3.button_text') }}
                </button>
              </div>
            </div>
          </div>

          <!-- Close Button -->
          <div class="modal-button-row">
            <button
              class="btn btn-primary button-style01 modal-button-info modal-button-right"
              @click="closeDataProvisioningDialog"
            >
              {{ $t('dashboard.Conclude') }}
            </button>
          </div>
          <div><br /></div>
        </div>
      </div>
    </template>
  </PropertyModalDialog>

    <!-- Data Acquisition Abo Required modal -->
    <ModalDialog
      v-show="toggleDataAcquisitionAboRequired"
      :showClose="true"
      @close="toggleDataAcquisitionAboRequired=false"
      class="modal_wide modal_wide_cancel"
    >
      <template
        v-slot:body
        class="modal-inside"
      >
        <div class="m-3">
          <div class="popup_title b_btm b_btm_indent">
            <h2>{{ $t('data_provisioning.modal_required_abo_title') }}</h2>
          </div>
          <div><br /></div>
          <div class="modal-tab-border-home">
            <!-- Liegenschaftsinformationen abrufen -->
            <div class="modal-content-highlight modal-content">
              <div class="modal-icon">
                <img
                  class="modal-img"
                  src="@/assets/note.svg"
                  alt=""
                />
              </div>
              <div class="modal-content-div modal-content-headline">
                {{ $t('data_provisioning.modal_required_abo_text') }}
              </div>
            </div>
            <!-- Close Button -->
            <div class="modal-button-row">
              <button
                class="btn btn-primary button-style01 modal-button-info modal-button-right"
                @click="toggleDataAcquisitionAboRequired=false"
              >
                {{ $t('data_provisioning.modal_required_abo_button') }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>

  <RoometricDialog
    v-show="isRoometricDialogVisible"
    :isNonResidentialVariant="isRoometricNonResidentialVariant"
    @close-roometric-dialog="closeRoometricDialog"
  >
  </RoometricDialog>
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import i18n from '@/i18n'

const { t } = i18n.global
import ModalDialog from '@/components/ModalDialog.vue'
import ModalDialogLarge from '@/components/ModalDialogLarge'
import PropertyModalDialog from '@/components/PropertyModalDialog.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import PropertyAddress from '@/components/propertySteps/PropertyAddress.vue'
import PropertyDetails from '@/components/propertySteps/PropertyDetails.vue'
import PropertyMoreDetails from '@/components/propertySteps/PropertyMoreDetails'
import PropertyBenefits from '@/components/propertySteps/PropertyBenefits'
import PropertyCommunity from '@/components/propertySteps/PropertyCommunity'
import PropertyOwnership from '@/components/propertySteps/PropertyOwnership'
import PropertyBuildings from '@/components/propertySteps/PropertyBuildings'
import PropertyResidential from '@/components/propertySteps/PropertyResidential'
import PropertyNonResidential from '@/components/propertySteps/PropertyNonResidential'
import PropertyLeasehold from '@/components/propertySteps/PropertyLeasehold'
import PropertyAgriculture from '@/components/propertySteps/PropertyAgriculture'
import PropertyLivestock from '@/components/propertySteps/PropertyLivestock'
import Dokumente from '@/components/propertySteps/Dokumente'
import { evaluatePropertyTypeName } from '@/helper/PropertyHelper'
import RoometricDialog from '@/components/RoometricDialog'
import { isRoometricActive } from '@/helper/RoometricHelper'
import apiClient from '@/Api'

export default {
  name: 'Property',
  components: {
    PropertyAddress,
    PropertyDetails,
    PropertyMoreDetails,
    PropertyBenefits,
    PropertyCommunity,
    PropertyOwnership,
    PropertyBuildings,
    PropertyResidential,
    PropertyNonResidential,
    PropertyLeasehold,
    PropertyAgriculture,
    PropertyLivestock,
    ModalDialog,
    ModalDialogLarge,
    PropertyModalDialog,
    Dokumente,
    RoometricDialog,
  },

  data: () => ({
    saveForm: form({
      client_comment: '',
    }),
    step: 0,
    isNewProperty: true, //controls whether a new property is being created or an existing property is being edited
    cancelPropVisible: false,
    savePropVisible: false,
    dataProvisioningDialogVisible: false,
    toggleDataAcquisitionAboRequired: false,
    addCommentVisible: false,
    isRoometricDialogVisible: false,
    isRoometricNonResidentialVariant: false,
    elsterValidation: {
      splashScreenVisible: false,
      splashScreenErrorMsg: '',
      validationSteps: [
        {
          name: 'checkForm',
          infoText: '',
          running: false,
          done: false,
          success: false,
          failed: false,
        },
        {
          name: 'saveProperty',
          infoText: '',
          running: false,
          done: false,
          success: false,
          failed: false,
        },
        {
          name: 'reloadProperty',
          infoText: '',
          running: false,
          done: false,
          success: false,
          failed: false,
        },
        {
          name: 'elsterValidate',
          infoText: '',
          running: false,
          done: false,
          success: false,
          failed: false,
        },
      ],
      elsterValidationErrors: [],
      elsterValidationHints: [],
      elsterValidationSuccess: false,
      successScreenVisible: false,
      errorScreenVisible: false,
    },
    isloading: false,
    showDoc: false,
    primaryColor: '',
    fontColor: '',
    changefontColor: '',
    selectedfontColor: '',
    mselectedfontColor: '',
    unselectedfontColor: '',
    munselectedfontColor: '',
    arrCountries: [],
    fileUrl: '',
    numPages: null,
    isPropertyNameCalculated: false,
    steps: [
      {
        component: 'PropertyAddress', //classic: "Adresse"
        title: t('newProperty.propertyAddress.navTitle'),
        focus: 'community',
        activated: true, // never change by direct access, always utilize "setTabActivated()"
        coloredSvg: 'property_address',
        coloredImageAsset: require('@/assets/newproperty/address_green.svg'),
        greyImageAsset: require('@/assets/newproperty/address_grey.svg'),
      },
      {
        component: 'PropertyDetails', //classic: "Grundstückdetails"
        title: t('newProperty.propertyDetails.navTitle'),
        focus: 'reference',
        activated: true, // never change by direct access, always utilize "setTabActivated()"
        coloredSvg: 'property_details',
        coloredImageAsset: require('@/assets/newproperty/property_details_green.svg'),
        greyImageAsset: require('@/assets/newproperty/property_details_grey.svg'),
      },
      {
        component: 'PropertyMoreDetails', //classic: "Grundstücksart"
        title: t('newProperty.propertyMoreDetails.navTitle'),
        focus: 'lifting_rate',
        activated: true, // never change by direct access, always utilize "setTabActivated()"
        coloredSvg: 'property_type',
        coloredImageAsset: require('@/assets/newproperty/property_type_green.svg'),
        greyImageAsset: require('@/assets/newproperty/property_type_grey.svg'),
      },
      {
        component: 'PropertyBenefits', //classic: ""
        title: t('newProperty.propertyBenefits.navTitle'),
        focus: 'expiration_first_grant_period',
        activated: true, // never change by direct access, always utilize "setTabActivated()"
        coloredSvg: 'property_benefit',
        coloredImageAsset: require('@/assets/newproperty/property_benefit_green.svg'),
        greyImageAsset: require('@/assets/newproperty/property_benefit_grey.svg'),
      },
      {
        component: 'PropertyCommunity', //classic: ""
        title: t('newProperty.propertyCommunity.navTitle'),
        focus: 'community_name',
        activated: true, // never change by direct access, always utilize "setTabActivated()"
        coloredSvg: 'property_community',
        coloredImageAsset: require('@/assets/newproperty/community_green.svg'),
        greyImageAsset: require('@/assets/newproperty/community_grey.svg'),
      },
      {
        component: 'PropertyOwnership', //classic: ""
        title: t('newProperty.propertyOwnership.navTitle'),
        focus: 'ownership_name',
        activated: true, // never change by direct access, always utilize "setTabActivated()"
        coloredSvg: 'property_owner',
        coloredImageAsset: require('@/assets/newproperty/property_owner_green.svg'),
        greyImageAsset: require('@/assets/newproperty/property_owner_grey.svg'),
      },
      {
        component: 'PropertyBuildings', //classic: ""
        title: t('newProperty.propertyBuildings.navTitle'),
        activated: true, // never change by direct access, always utilize "setTabActivated()"
        coloredSvg: 'property_building',
        coloredImageAsset: require('@/assets/newproperty/property_building_green.svg'),
        greyImageAsset: require('@/assets/newproperty/property_building_grey.svg'),
      },
      {
        component: 'PropertyResidential', //classic: ""
        title: t('newProperty.propertyResidential.navTitle'),
        activated: true, // never change by direct access, always utilize "setTabActivated()"
        coloredSvg: 'residential_lots',
        coloredImageAsset: require('@/assets/newproperty/residential_lots_green.svg'),
        greyImageAsset: require('@/assets/newproperty/residential_lots_grey.svg'),
      },
      {
        component: 'PropertyNonResidential', //classic: ""
        title: t('newProperty.propertyNonResidential.navTitle'),
        activated: true, // never change by direct access, always utilize "setTabActivated()"
        coloredSvg: 'non_residentialproperties',
        coloredImageAsset: require('@/assets/newproperty/non-residentialproperties_green.svg'),
        greyImageAsset: require('@/assets/newproperty/non-residentialproperties_grey.svg'),
      },
      {
        component: 'PropertyLeasehold', //classic: ""
        title: t('newProperty.propertyLeasehold.navTitle'),
        activated: true, // never change by direct access, always utilize "setTabActivated()"
        coloredSvg: 'leasehold',
        coloredImageAsset: require('@/assets/newproperty/leasehold_green.svg'),
        greyImageAsset: require('@/assets/newproperty/leasehold_grey.svg'),
      },
      {
        component: 'PropertyAgriculture', //classic: ""
        title: t('newProperty.propertyAgriculture.navTitle'),
        activated: true, // never change by direct access, always utilize "setTabActivated()"
        coloredSvg: 'agricultureandforestry',
        coloredImageAsset: require('@/assets/newproperty/agricultureandforestry_green.svg'),
        greyImageAsset: require('@/assets/newproperty/agricultureandforestry_grey.svg'),
      },
      {
        component: 'PropertyLivestock', //classic: ""
        title: t('newProperty.propertyLivestock.navTitle'),
        focus: 'owned_space',
        activated: true, // never change by direct access, always utilize "setTabActivated()"
        coloredSvg: 'livestock',
        coloredImageAsset: require('@/assets/newproperty/livestock_green.svg'),
        greyImageAsset: require('@/assets/newproperty/livestock_grey.svg'),
      },
      {
        component: 'Dokumente', //classic: ""
        title: t('newProperty.Dokumente.navTitle'),
        focus: 'owned_space',
        activated: true, // never change by direct access, always utilize "setTabActivated()"
        coloredSvg: 'Dokumente',
        coloredImageAsset: require('@/assets/newproperty/livestock_green.svg'),
        greyImageAsset: require('@/assets/newproperty/document_gray.svg'),
      },
    ],
    stepsHavingErrors: [],
    stepsHavingErrorsVisible: false,
    skendataStepsHavingErrorsVisible: false,
    elsterStepsHavingErrorsVisible: false,
    form: form({}),
    skenDataAddress: '',
    toggleDataAcquisitionLoader: false,
    toggleDataAcquisitionAboRequied: false,
  }),

  created() {
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#43ac6f'
  },

  mounted() {
    this.setObjPropertyDetails([])
    this.initPropertyForms()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#43ac6f'
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.fontColor : ''
    //this.selectedfontColor = "color:"+this.fontColor+" !important; border-left: 4px solid "+this.fontColor+";"
    //this.selectedfontColor = "color:"+this.primaryColor+" !important;"
    //this.mselectedfontColor = "color:"+this.fontColor+" !important; border-bottom: 4px solid "+this.fontColor+";"
    //this.mselectedfontColor = "color:"+this.primaryColor+" !important;"
    //this.unselectedfontColor =  "color:"+this.fontColor+" !important; border-left: 4px solid #fff;"
    //this.unselectedfontColor =  "color:"+this.primaryColor+" !important;"
    //this.munselectedfontColor =  "color:"+this.fontColor+" !important; border-bottom: 4px solid #fff;"
    //this.munselectedfontColor =  "color:"+this.primaryColor+" !important;"
    const myPropertyId = this.$route.params.id
    if (myPropertyId) {
      this.isNewProperty = false
      this.loadProperty(myPropertyId)
    } else {
      this.isNewProperty = true
    }
  },
  beforeUnmount() {
    this.resetEditPropertyData()
    this.isloading = false
    this.stopObserver()
  },

  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getArrCountries',
      'getObjPropertyDetails',
      'triggerRefreshRecord',
      'getLastAddedPropertyId',
      'isPropertyReadOnly',
    ]),

    cancelText() {
      if (this.isPropertyReadOnly) {
        return this.$t('newProperty.Interrupt_text_read_only')
      } else if (this.isNewProperty) {
        return this.$t('newProperty.Interrupt_text_add')
      } else {
        return this.$t('newProperty.Interrupt_text_edit')
      }
    },

    canPerformElsterValidation() {
      return this.canSaveProperty && !this.isPropertyReadOnly && !this.isNewProperty
    },

    canEditProperty() {
      return this.getCurrentUser.permissions && Object.values(this.getCurrentUser.permissions).includes('EDIT_PROPERTY')
    },

    canSaveProperty() {
      if (
        this.getObjPropertyDetails.clientEditingLocked &&
        parseInt(this.getObjPropertyDetails.clientEditingLocked) === 1
      ) {
        return false
      }

      if (this.getCurrentUser.permissions) {
        if (this.$route.params.id && Object.values(this.getCurrentUser.permissions).includes('EDIT_PROPERTY')) {
          return true
        } else if (Object.values(this.getCurrentUser.permissions).includes('ADD_PROPERTY')) {
          return true
        }
      }

      return false
    },

    shouldAskForClientComment() {
      let result = false

      result =
        this.canSaveProperty &&
        !Number.isNaN(parseInt(this.getObjPropertyDetails.status, 10)) &&
        parseInt(this.getObjPropertyDetails.status, 10) > 0

      return result
    },

    maxStep() {
      let myMaxStep = this.steps.length - 1
      while (this.steps[myMaxStep].activated === false && myMaxStep > 0) {
        myMaxStep--
      }
      return myMaxStep
    },

    didAddNewProperty() {
      return (
        typeof this.getLastAddedPropertyId !== 'undefined' &&
        !Number.isNaN(parseInt(this.getLastAddedPropertyId, 10)) &&
        parseInt(this.getLastAddedPropertyId, 10) > 0
      )
    },

    isFormValidationActive() {
      let result = true

      if (!this.isNewProperty && this.isPropertyReadOnly) {
        result = false
      } else {
        if (!this.canEditProperty) {
          result = false
        }
      }
      return result
    },

    isRoometricActive() {
      return isRoometricActive()
    },
  },

  watch: {
    getObjPropertyDetails() {
      this.showHideTabs(this.getObjPropertyDetails)
      this.updateFormsFromObjPropertyDetails()
      this.handlePropertyTypeActivation()
      this.showHideTabs(this.getObjPropertyDetails)
      this.isloading = false
    },
    savePropVisible(newValue) {
      if (newValue === true) {
        if (this.shouldAskForClientComment) {
          this.invokeReference('saveForm_client_comment_2')
        }
      }
    },
    cancelPropVisible() {
      const app = this
      if (app.cancelPropVisible) {
        document.addEventListener('keyup', app.cancelListener)
      } else {
        document.removeEventListener('keyup', app.cancelListener)
      }
    },
    triggerRefreshRecord(newValue) {
      if (newValue) {
        this.setObjPropertyDetails([])
        this.initPropertyForms()
        this.loadProperty(this.$route.params.id)
      }
    },
    elsterValidation: {
      handler(newValue) {
        if (newValue.splashScreenVisible) {
          if (newValue.validationSteps[0].success) {
            if (!newValue.validationSteps[1].running && !newValue.validationSteps[1].done) {
              this.startElsterValidationStep(1)
            } else {
              if (newValue.validationSteps[1].success) {
                if (!newValue.validationSteps[2].running && !newValue.validationSteps[2].done) {
                  this.startElsterValidationStep(2)
                } else {
                  if (newValue.validationSteps[2].success) {
                    if (!newValue.validationSteps[3].running && !newValue.validationSteps[3].done) {
                      this.startElsterValidationStep(3)
                    } else {
                      if (newValue.validationSteps[3].success) {
                        setTimeout(() => {
                          this.elsterValidation.splashScreenVisible = false
                          this.showElsterValidationResult()
                        }, 500)
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          let isStepRunning = false
          newValue.validationSteps.forEach((step) => {
            if (!isStepRunning && typeof step.running === 'boolean' && step.running) {
              isStepRunning = true
            }
          }, this)
          if (!isStepRunning) {
            this.hidePageLoader()
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      'resetFormPropertyAddress',
      'resetFormPropertyDetails',
      'resetFormPropertyMoreDetails',
      'resetFormPropertyBenefits',
      'resetFormPropertyCommunity',
      'resetFormPropertyOwnership',
      'resetFormPropertyBuildings',
      'resetFormPropertyResidential',
      'resetFormPropertyNonResidential',
      'resetFormPropertyLeasehold',
      'resetFormPropertyAgriculture',
      'resetFormPropertyLivestock',
      'apiGetPropertyDetails',
      'saveProperty',
      'savePropertyBeforeSkendataRequest',
      'savePropertySilently',
      'showToast',
      'apiGetPropertyDetailsSilently',
      'getDataAcquisition',
      'getSkendataProcess',
      'getSkendataFulfillment',
      'saveSkendataFulfillment',
      'saveSkendataParcels',
      'stopObserver',
    ]),
    ...mapMutations([
      'setObjPropertyDetails',
      'updateFormPropertyAddress',
      'updateFormPropertyDetails',
      'updateFormPropertyMoreDetails',
      'updateFormPropertyBenefits',
      'updateFormPropertyCommunity',
      'updateFormPropertyOwnership',
      'updateFormPropertyBuildings',
      'updateFormPropertyResidential',
      'updateFormPropertyNonResidential',
      'updateFormPropertyLeasehold',
      'updateFormPropertyAgriculture',
      'updateFormPropertyLivestock',
      'pushFormPropertyAddress',
      'pushFormPropertyDetails',
      'pushFormPropertyMoreDetails',
      'pushFormPropertyBenefits',
      'pushFormPropertyCommunity',
      'pushFormPropertyOwnership',
      'pushFormPropertyBuildings',
      'pushFormPropertyResidential',
      'pushFormPropertyNonResidential',
      'pushFormPropertyLeasehold',
      'pushFormPropertyAgriculture',
      'pushFormPropertyLivestock',
      'showPageLoader',
      'hidePageLoader',
    ]),

    initPropertyForms() {
      this.resetFormPropertyAddress()
      this.$refs.childPropertyAddress.initForm()
      this.resetFormPropertyDetails()
      this.$refs.childPropertyDetails.initForm()
      this.resetFormPropertyMoreDetails()
      this.$refs.childPropertyMoreDetails.initForm()
      this.resetFormPropertyBenefits()
      this.$refs.childPropertyBenefits.initForm()
      this.resetFormPropertyCommunity()
      this.$refs.childPropertyCommunity.initForm()
      this.resetFormPropertyOwnership()
      this.$refs.childPropertyOwnership.initForm()
      this.resetFormPropertyBuildings()
      this.$refs.childPropertyBuildings.initForm()
      this.resetFormPropertyResidential()
      this.$refs.childPropertyResidential.initForm()
      this.resetFormPropertyNonResidential()
      this.$refs.childPropertyNonResidential.initForm()
      this.resetFormPropertyLeasehold()
      this.$refs.childPropertyLeasehold.initForm()
      this.resetFormPropertyAgriculture()
      this.$refs.childPropertyAgriculture.initForm()
      this.resetFormPropertyLivestock()
      this.$refs.childPropertyLivestock.initForm()
    },

    loadProperty(propertyId) {
      this.apiGetPropertyDetails(propertyId)
      this.isloading = true
    },

    updateFormsFromObjPropertyDetails() {
      this.updateFormPropertyAddress()
      this.renderFormPropertyAddress()
      this.updateFormPropertyDetails()
      this.renderFormPropertyDetails()
      this.updateFormPropertyMoreDetails()
      this.renderFormPropertyMoreDetails()
      this.updateFormPropertyBenefits()
      this.renderFormPropertyBenefits()
      this.updateFormPropertyCommunity()
      this.renderFormPropertyCommunity()
      this.updateFormPropertyOwnership()
      this.renderFormPropertyOwnership()
      this.updateFormPropertyBuildings()
      this.renderFormPropertyBuildings()
      this.updateFormPropertyResidential()
      this.renderFormPropertyResidential()
      this.updateFormPropertyNonResidential()
      this.renderFormPropertyNonResidential()
      this.updateFormPropertyLeasehold()
      this.renderFormPropertyLeasehold()
      this.updateFormPropertyAgriculture()
      this.renderFormPropertyAgriculture()
      this.updateFormPropertyLivestock()
      this.renderFormPropertyLivestock()
    },

    renderFormPropertyAddress() {
      this.$refs.childPropertyAddress.initForm()
    },

    renderFormPropertyDetails() {
      this.$refs.childPropertyDetails.initForm()
    },

    renderFormPropertyMoreDetails() {
      this.$refs.childPropertyMoreDetails.initForm()
    },

    renderFormPropertyBenefits() {
      this.$refs.childPropertyBenefits.initForm()
    },

    renderFormPropertyCommunity() {
      this.$refs.childPropertyCommunity.initForm()
    },

    renderFormPropertyOwnership() {
      this.$refs.childPropertyOwnership.initForm()
    },

    renderFormPropertyBuildings() {
      this.$refs.childPropertyBuildings.initForm()
    },

    renderFormPropertyResidential() {
      this.$refs.childPropertyResidential.initForm()
    },

    renderFormPropertyNonResidential() {
      this.$refs.childPropertyNonResidential.initForm()
    },

    renderFormPropertyLeasehold() {
      this.$refs.childPropertyLeasehold.initForm()
    },

    renderFormPropertyAgriculture() {
      this.$refs.childPropertyAgriculture.initForm()
    },

    renderFormPropertyLivestock() {
      this.$refs.childPropertyLivestock.initForm()
    },

    resetEditPropertyData() {
      this.initPropertyForms()
      this.setObjPropertyDetails([])
    },

    clearFormPropertyBenefits() {
      this.resetFormPropertyBenefits()
      this.$refs.childPropertyBenefits.initForm()
    },

    evaluateClassForStep(no) {
      if (no < this.step) {
        //this.changefontColor = "color:"+this.fontColor+"; border-left: 4px solid #fff;"
        return 'unselected'
      }
      if (no == this.step) {
        //this.changefontColor = "color:"+this.fontColor+"; border-left: 4px solid "+this.fontColor+";"
        return 'selected'
      }
    },

    prev() {
      this.cancelPropVisible = true
    },

    cancelProp(stat) {
      if (stat != 'Nein') {
        this.$router.push('/dashboard')
        this.cancelPropVisible = false
      } else {
        this.cancelPropVisible = false
      }
    },

    propertyAddressNextStep() {
      if (this.isNewProperty) {
        this.setCalculatedPropertyName()
      }
      this.nextStep()
    },

    propertyAddressDidChangeFederalState({ oldFederalStateUid, newFederalStateUid }) {
      //rule_G_1515
      //when change happened from or to "Baden-Württemberg"
      // => if oldValue <> "3", then reset formPropertyDetails.economic_entity_type to default value
      if (oldFederalStateUid.toString() === '1' || newFederalStateUid.toString() === '1') {
        if (this.$store.state.Properties.formPropertyDetails.economic_entity_type !== '3') {
          this.$store.state.Properties.formPropertyDetails.economic_entity_type = '0'
          this.$refs.childPropertyDetails.form.economic_entity_type = '0'
        }
      }
      this.handlePropertyTypeActivation(false) //quick fix for release #1
      this.showHideTabs()
    },

    propertyDetailsDidChangeEconomicEntityType() {
      this.handlePropertyTypeActivation(false)
      this.showHideTabs()
    },

    //quick fix for release #1
    handlePropertyTypeActivation(afterLoadProperty) {
      let myFederalStateUid = ''
      let myEconomicEntityType = ''
      let myActivationState = false
      let mustUpdatePropertyTypeSelection = false

      //after property loading
      if (afterLoadProperty) {
        if (
          typeof this.$store.state.Properties.objPropertyDetails.step2 === 'object' &&
          typeof this.$store.state.Properties.objPropertyDetails.step2.federalStateUid !== 'undefined'
        ) {
          myFederalStateUid = this.$store.state.Properties.objPropertyDetails.step2.federalStateUid.toString()
        }
        if (
          typeof this.$store.state.Properties.objPropertyDetails.step2 === 'object' &&
          typeof this.$store.state.Properties.objPropertyDetails.step2.economicEntityType !== 'undefined'
        ) {
          myEconomicEntityType = this.$store.state.Properties.objPropertyDetails.step2.economicEntityType.toString()
        }
      }
      //during creating or editing a property
      else {
        if (typeof this.$refs.childPropertyAddress.form.federal_state_uid !== 'undefined') {
          myFederalStateUid = this.$refs.childPropertyAddress.form.federal_state_uid.toString()
        }
        if (typeof this.$refs.childPropertyDetails.form.economic_entity_type !== 'undefined') {
          myEconomicEntityType = this.$refs.childPropertyDetails.form.economic_entity_type.toString()
        }
        mustUpdatePropertyTypeSelection = true
      }

      myActivationState = !this.isPropertyTypeDisabled(myEconomicEntityType, myFederalStateUid)

      if (myActivationState === true) {
        this.$refs.childPropertyMoreDetails.property_type_activated = true
        //console.log("activated PropertyType")
      } else {
        if (mustUpdatePropertyTypeSelection) {
          this.updatePropertyTypeSelection(myEconomicEntityType, myFederalStateUid)
        }
        this.$refs.childPropertyMoreDetails.property_type_activated = false
        //console.log("deactivated PropertyType")
      }
    },

    updatePropertyTypeSelection(economicEntityType, federalStateUid) {
      economicEntityType = economicEntityType.toString()
      federalStateUid = federalStateUid.toString()
      let myPropertyType = ''

      if (federalStateUid !== '1') {
        if (economicEntityType === '1') {
          myPropertyType = '1'
          if (federalStateUid === '6') {
            myPropertyType = ''
          } else if (federalStateUid === '2') {
            myPropertyType = ''
          } else if (federalStateUid === '7') {
            myPropertyType = ''
          } else if (federalStateUid === '9') {
            myPropertyType = ''
          } else {
            myPropertyType = '1'
          }
        }
      }

      this.$refs.childPropertyMoreDetails.form.property_type = myPropertyType
      if (typeof this.$store.state.Properties.formPropertyMoreDetails === 'object') {
        this.$store.state.Properties.formPropertyMoreDetails['property_type'] = myPropertyType
      }
    },

    isPropertyTypeDisabled(economicEntityType, federalStateUid) {
      economicEntityType = economicEntityType.toString()
      federalStateUid = federalStateUid.toString()

      let federalStateUids = ['2', '6', '7', '9']

      if (economicEntityType === '3') {
        return true
      }

      if (federalStateUids.includes(federalStateUid)) {
        if (economicEntityType === '2') {
          return false
        }
      } else if (federalStateUid !== '1' && economicEntityType !== '1') {
        return false
      }

      return true
    },

    setCalculatedPropertyName() {
      if (this.isPropertyNameCalculated === true) {
        return
      }

      let myStreet = ''
      let myHouseNumber = ''
      let myZip = ''
      let myCity = ''

      if (this.$store.state.Properties.formPropertyAddress.street !== 'undefined') {
        myStreet = this.$store.state.Properties.formPropertyAddress.street
      }
      if (this.$store.state.Properties.formPropertyAddress.house_number !== 'undefined') {
        myHouseNumber = this.$store.state.Properties.formPropertyAddress.house_number
      }
      if (this.$store.state.Properties.formPropertyAddress.zip !== 'undefined') {
        myZip = this.$store.state.Properties.formPropertyAddress.zip
      }
      if (this.$store.state.Properties.formPropertyAddress.city !== 'undefined') {
        myCity = this.$store.state.Properties.formPropertyAddress.city
      }

      let part1 = (myStreet + ' ' + myHouseNumber).trim()
      let part2 = (myZip + ' ' + myCity).trim()
      let mySeparator = ', '
      if (part1 === '' || part2 === '') {
        mySeparator = ''
      }

      this.$refs.childPropertyDetails.setNameField(part1 + mySeparator + part2)
      this.isPropertyNameCalculated = true
    },

    //rule_G_1532, rule_G_1226
    //parameter: objPropertyDetails
    //  - if set: get trigger values from the property object
    //  - if not set: get trigger values from the child component forms
    showHideTabs(objPropertyDetails) {
      let tabActivations = {
        PropertyAddress: true,
        PropertyDetails: true,
        PropertyMoreDetails: true,
        PropertyBenefits: false,
        PropertyCommunity: true,
        PropertyOwnership: true,
        PropertyBuildings: false,
        PropertyResidential: true,
        PropertyNonResidential: true,
        PropertyLeasehold: true,
        PropertyAgriculture: true,
        PropertyLivestock: true,
        Dokumente: !this.isNewProperty,
      }

      let formFields = this.getEnableDisableFormFields()
      let triggerParams = this.getShowHideTabsTriggerParams(objPropertyDetails)

      tabActivations.PropertyAgriculture = triggerParams.economicEntityType === '3'
      tabActivations.PropertyLivestock = triggerParams.economicEntityType === '3'

      //1. federalState: "Hamburg" AND economicEntityType: "Bebautes Grundstück"
      if (triggerParams.federalStateUid === '6' && triggerParams.economicEntityType === '2') {
        tabActivations.PropertyResidential = false //"Wohngrundstück"
        tabActivations.PropertyNonResidential = false //"Nichtwohngrundstück"
        tabActivations.PropertyAgriculture = false //"Land und Forstwirtschaft"
        tabActivations.PropertyLivestock = false //"Tierbestand"
        this.setFormFieldsEnabledByIds(formFields, [0, 1, 3, 4], false)
      }

      //2. federalState: "Hessen" AND economicEntityType: "Bebautes Grundstück"
      if (triggerParams.federalStateUid === '7' && triggerParams.economicEntityType === '2') {
        tabActivations.PropertyResidential = false //"Wohngrundstück"
        tabActivations.PropertyNonResidential = false //"Nichtwohngrundstück"
        tabActivations.PropertyLeasehold = false //"Erbbaurecht"
        tabActivations.PropertyAgriculture = false //"Land und Forstwirtschaft"
        tabActivations.PropertyLivestock = false //"Tierbestand"
        this.setFormFieldsEnabledByIds(formFields, [0, 1, 2, 3], false)
      }

      //3. federalState: "Bayern" AND economicEntityType: "Bebautes Grundstück"
      if (triggerParams.federalStateUid === '2' && triggerParams.economicEntityType === '2') {
        tabActivations.PropertyResidential = false //"Wohngrundstück"
        tabActivations.PropertyNonResidential = false //"Nichtwohngrundstück"
        tabActivations.PropertyAgriculture = false //"Land und Forstwirtschaft"
        tabActivations.PropertyLivestock = false //"Tierbestand"

        this.setFormFieldsEnabledByIds(formFields, [1, 3, 4, 8], false)
      }

      //4. federalState: "Niedersachsen" AND economicEntityType: "Bebautes Grundstück"
      if (triggerParams.federalStateUid === '9' && triggerParams.economicEntityType === '2') {
        tabActivations.PropertyResidential = false //"Wohngrundstück"
        tabActivations.PropertyNonResidential = false //"Nichtwohngrundstück"
        tabActivations.PropertyAgriculture = false //"Land und Forstwirtschaft"
        tabActivations.PropertyLivestock = false //"Tierbestand"
        this.setFormFieldsEnabledByIds(formFields, [1, 3], false)
      }

      //5. federalState: not in "Hamburg, Hessen, Bayern, Niedersachsen" AND economicEntityType: "Bebautes Grundstück"
      if (
        ['2', '6', '7', '9'].indexOf(triggerParams.federalStateUid) === -1 &&
        triggerParams.economicEntityType === '2'
      ) {
        tabActivations.PropertyBuildings = false //"Gebäude(-teile)"
        tabActivations.PropertyAgriculture = false //"Land und Forstwirtschaft"
        tabActivations.PropertyLivestock = false //"Tierbestand"
        this.setFormFieldsEnabledByIds(formFields, [1, 2, 3], false)
      }

      //6. federalState: "Hamburg" AND economicEntityType: "unbebautes Grundstück"
      if (triggerParams.federalStateUid === '6' && triggerParams.economicEntityType === '1') {
        tabActivations.PropertyBuildings = false //"Gebäude(-teile)"
        tabActivations.PropertyResidential = false //"Wohngrundstück"
        tabActivations.PropertyNonResidential = false //"Nichtwohngrundstück"
        tabActivations.PropertyAgriculture = false //"Land und Forstwirtschaft"
        tabActivations.PropertyLivestock = false //"Tierbestand"
        this.setFormFieldsEnabledByIds(formFields, [0, 1, 3, 4], false)
      }

      //7. federalState: "Bayern" AND economicEntityType: "unbebautes Grundstück"
      if (triggerParams.federalStateUid === '2' && triggerParams.economicEntityType === '1') {
        tabActivations.PropertyBuildings = false //"Gebäude(-teile)"
        tabActivations.PropertyResidential = false //"Wohngrundstück"
        tabActivations.PropertyNonResidential = false //"Nichtwohngrundstück"
        tabActivations.PropertyAgriculture = false //"Land und Forstwirtschaft"
        tabActivations.PropertyLivestock = false //"Tierbestand"
        this.setFormFieldsEnabledByIds(formFields, [0, 1, 3, 4, 8], false)
      }

      //8. federalState: "Hessen" AND economicEntityType: "unbebautes Grundstück"
      if (triggerParams.federalStateUid === '7' && triggerParams.economicEntityType === '1') {
        tabActivations.PropertyBuildings = false //"Gebäude(-teile)"
        tabActivations.PropertyResidential = false //"Wohngrundstück"
        tabActivations.PropertyNonResidential = false //"Nichtwohngrundstück"
        tabActivations.PropertyLeasehold = false //"Erbbaurecht"
        tabActivations.PropertyAgriculture = false //"Land und Forstwirtschaft"
        tabActivations.PropertyLivestock = false //"Tierbestand"
        this.setFormFieldsEnabledByIds(formFields, [0, 1, 2, 3], false)
      }

      //9. federalState: "Niedersachsen" AND economicEntityType: "unbebautes Grundstück"
      if (triggerParams.federalStateUid === '9' && triggerParams.economicEntityType === '1') {
        tabActivations.PropertyBuildings = false //"Gebäude(-teile)"
        tabActivations.PropertyResidential = false //"Wohngrundstück"
        tabActivations.PropertyNonResidential = false //"Nichtwohngrundstück"
        tabActivations.PropertyAgriculture = false //"Land und Forstwirtschaft"
        tabActivations.PropertyLivestock = false //"Tierbestand"
        this.setFormFieldsEnabledByIds(formFields, [0, 1, 3], false)
      }

      //10. economicEntityType: "Betrieb der Land- und Forstwirtschaft"
      if (triggerParams.economicEntityType === '3') {
        tabActivations.PropertyBuildings = false //"Gebäude(-teile)"
        tabActivations.PropertyResidential = false //"Wohngrundstück"
        tabActivations.PropertyNonResidential = false //"Nichtwohngrundstück"
        tabActivations.PropertyLeasehold = false //"Erbbaurecht"
        this.setFormFieldsEnabledByIds(formFields, [0, 1, 2, 3, 4, 5, 6, 11], false)
        //added formField 11 (parcels section) to idArray required by GRDDEV-2424
      }

      //11. federalState: not in "Hamburg, Hessen, Bayern, Niedersachsen" AND economicEntityType: "unbebautes Grundstück"
      // added federalState "Baden-Württemberg" to the array, because it does not know the economicEntityType "unbebautes Grundstück" (but "Grundstück" instead)
      if (
        ['1', '2', '6', '7', '9'].indexOf(triggerParams.federalStateUid) === -1 &&
        triggerParams.economicEntityType === '1'
      ) {
        tabActivations.PropertyBuildings = false //"Gebäude(-teile)"
        tabActivations.PropertyResidential = false //"Wohngrundstück"
        tabActivations.PropertyNonResidential = false //"Nichtwohngrundstück"
        tabActivations.PropertyAgriculture = false //"Land und Forstwirtschaft"
        tabActivations.PropertyLivestock = false //"Tierbestand"

        //preset PropertyType to "unbebautes Grundstück"
        this.$refs.childPropertyMoreDetails.form.property_type = '1'
        if (typeof this.$store.state.Properties.formPropertyMoreDetails === 'object') {
          this.$store.state.Properties.formPropertyMoreDetails['property_type'] = '1'
        }
        this.setFormFieldsEnabledByIds(formFields, [0, 2, 3], false)
      }

      //12: federalState: "Baden-Württemberg" AND economicEntityType: "Grundstück"
      if (triggerParams.federalStateUid === '1' && triggerParams.economicEntityType === '1') {
        tabActivations.PropertyBuildings = false //"Gebäude(-teile)"
        tabActivations.PropertyResidential = false //"Wohngrundstück"
        tabActivations.PropertyNonResidential = false //"Nichtwohngrundstück"
        tabActivations.PropertyAgriculture = false //"Land und Forstwirtschaft"
        tabActivations.PropertyLivestock = false //"Tierbestand"
        this.setFormFieldsEnabledByIds(formFields, [0, 2, 6], false)
        this.setFormFieldsEnabledByIds(formFields, [3], true)
      }

      //show/hide tab "Befreiung / Vergünstigung" based on "Liegt eine Befreiung/Vergünstigung vor?"
      tabActivations.PropertyBenefits = triggerParams.exemption === '1' && triggerParams.economicEntityType !== '3'

      //rule_G_1226: federalState: not in "Bayern, Niedersachsen, Hamburg"
      if (['2', '9', '6'].indexOf(triggerParams.federalStateUid) === -1) {
        this.setFormFieldsEnabledByIds(formFields, [7], false)
      }

      switch (triggerParams.ownershipStructure) {
        case '':
        case '0':
        case '1':
        case '2':
        case '3':
          tabActivations.PropertyCommunity = false
          this.resetFormPropertyCommunity()
          this.$refs.childPropertyCommunity.initForm()

          tabActivations.PropertyOwnership = false
          this.resetFormPropertyOwnership()
          this.$refs.childPropertyOwnership.initForm()
          break

        case '4': //rule_G_1620: hide tab "Gemeinschaft" if ownershipStructure is "Ehegatten/Lebenspartner"
          tabActivations.PropertyCommunity = false
          this.resetFormPropertyCommunity()
          this.$refs.childPropertyCommunity.initForm()

          tabActivations.PropertyOwnership = true
          break

        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
          tabActivations.PropertyCommunity = true
          tabActivations.PropertyOwnership = true
          break
      }

      //rule_G_1619 disable: propertyType is unequal "Wohnungseigentum" or "Teileigentum"
      if (
        triggerParams.propertyTypeName.toLowerCase() !== this.$t('property_type.5').toLowerCase() &&
        triggerParams.propertyTypeName.toLowerCase() !== this.$t('property_type.6').toLowerCase()
      ) {
        this.setFormFieldsEnabledByIds(formFields, [9], false)
      }

      //rule_G_1433 disable "weitere Nutzflächen" in tab "Wohngrundstücke" if propertyType is unequal "Mietwohngrundstück"
      if (triggerParams.propertyTypeName.toLowerCase() !== this.$t('property_type.4').toLowerCase()) {
        this.setFormFieldsEnabledByIds(formFields, [155], false)
      } else {
        this.setFormFieldsEnabledByIds(formFields, [155], true)
      }

      //rule_G_1619 enable: federalState in "Hamburg, Hessen, Bayern, Niedersachsen" AND economicEntityType: "bebautes Grundstück"
      if (
        ['2', '6', '7', '9'].indexOf(triggerParams.federalStateUid) !== -1 &&
        triggerParams.economicEntityType === '2'
      ) {
        this.setFormFieldsEnabledByIds(formFields, [9], true)
      }

      //rule_G_1624
      tabActivations.PropertyLeasehold =
        triggerParams.buildingOnForeignGround === '1' || triggerParams.leasehold === '1'

      //rule_G_1557: federalState in "Bayern, Hessen, Hamburg, Niedersachsen" AND economicEntityType: "Bebautes Grundstück"
      if (
        ['2', '6', '7', '9'].indexOf(triggerParams.federalStateUid) !== -1 &&
        triggerParams.economicEntityType === '2'
      ) {
        tabActivations.PropertyBuildings = true
      }

      //rule_G_2627: activate "Befindet sich dieses Grundstück im Außenbereich?" only for federal state "HE"
      this.setFormFieldsEnabledByIds(formFields, [15], triggerParams.federalStateUid === '7')

      //adopt show/hide rules for (non)residential tabs from ui-tc (EditProperty.vue)
      if (tabActivations.PropertyBuildings) {
        tabActivations.PropertyResidential = false
        tabActivations.PropertyNonResidential = false
      } else {
        switch (triggerParams.propertyType) {
          case '':
          case '0':
          case '1':
            tabActivations.PropertyResidential = false
            tabActivations.PropertyNonResidential = false
            break
          case '2':
          case '3':
          case '4':
          case '5':
            tabActivations.PropertyResidential = true
            tabActivations.PropertyNonResidential = false
            break
          case '6':
          case '7':
          case '8':
          case '9':
            tabActivations.PropertyResidential = false
            tabActivations.PropertyNonResidential = true
            break
        }
      }

      //rule G_2539 federalState equals "HE" then hide tab "Erbbaurecht" independently from any other fields checked elsewhere
      if (triggerParams.federalStateUid === '7') {
        tabActivations.PropertyLeasehold = false
      }

      //rule G_2615 show "Erbbaurecht" if buildingsOnThirdPartyOwners is set active and federalState equals "HH"
      if (triggerParams.federalStateUid === '6' && triggerParams.buildingsOnThirdPartyOwners === '1') {
        tabActivations.PropertyLeasehold = true
      }

      //activate add-buttons in PropertyBuildings
      this.setFormFieldsEnabledByIds(formFields, [101], triggerParams.exemption === '1')
      //delete all property buildingparts/buildings in PropertyBuildings if tab is hidden
      this.setFormFieldsEnabledByIds(formFields, [102], tabActivations.PropertyBuildings)
      //activate add-buttons in PropertyResidential
      this.setFormFieldsEnabledByIds(formFields, [156], triggerParams.exemption === '1')
      //activate add-buttons in PropertyNonResidential
      this.setFormFieldsEnabledByIds(formFields, [166], triggerParams.exemption === '1')

      //set exemptions trigger for PropertyAgriculture
      this.setFormFieldsEnabledByIds(formFields, [180], triggerParams.exemption === '1')

      for (const [key, value] of Object.entries(tabActivations)) {
        this.setTabActivated(key, value)
      }

      //rule_G_1621 federalState in "Bayern, Niedersachsen"
      //then disable field "Art des Grundstücks" in tab "Grundstück"
      if (['2', '9'].indexOf(triggerParams.federalStateUid) !== -1) {
        this.setFormFieldsEnabledByIds(formFields, [0], false)
      }

      //rule_G_2083.1 federalState equals "Baden-Württemberg" and exemption equals true
      if (triggerParams.federalStateUid === '1' && triggerParams.exemption === '1') {
        this.setFormFieldsEnabledByIds(formFields, [34, 21, 22, 23, 24, 25, 26, 27], false)
      }

      //rule_G_2083.2 federalState in "Bayern, Niedersachsen" and exemption equals true
      if (['2', '9'].indexOf(triggerParams.federalStateUid) !== -1 && triggerParams.exemption === '1') {
        this.setFormFieldsEnabledByIds(formFields, [28, 29, 30, 31, 32, 33], false)
      }

      //rule_G_2083.3 federalState in "Berlin, Brandenburg, Bremen, Mecklenburg-Vorpommern, Nordrhein-Westfalen, Rheinland-Pfalz, Saarland, Sachsen-Anhalt, Sachsen, Schleswig-Holstein, Thüringen"
      //and exemption equals true
      if (
        ['3', '4', '5', '8', '10', '11', '12', '13', '14', '15', '16'].indexOf(triggerParams.federalStateUid) !== -1 &&
        triggerParams.exemption === '1'
      ) {
        this.setFormFieldsEnabledByIds(formFields, [34, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33], false)
      }

      //rule_G_2083.4 federalState equals "Hamburg" and exemption equals true
      if (triggerParams.federalStateUid === '6' && triggerParams.exemption === '1') {
        this.setFormFieldsEnabledByIds(formFields, [34, 28, 29, 30, 31, 32, 33], false)
      }

      //rule_G_2083.5 federalState equals "Hessen" and exemption equals true
      if (triggerParams.federalStateUid === '7' && triggerParams.exemption === '1') {
        this.setFormFieldsEnabledByIds(formFields, [34, 21, 22, 23, 24, 25, 26, 27, 31, 32, 33], false)
      }

      //rule_G_2611 deactivate LuF parcels isReference checkbox if street and housenumber are not empty
      if (triggerParams.street.trim() === '' || triggerParams.houseNumber.trim() === '') {
        this.setFormFieldsEnabledByIds(formFields, [110], true)
      } else {
        this.setFormFieldsEnabledByIds(formFields, [110], false)
      }

      //rule_G_2548 show subArea section only if "Bundesmodell" is chosen
      //stateCodes for "Bundesmodell" are ["3", "4", "5", "8", "10", "11", "12", "13", "15", "16"]
      this.setFormFieldsEnabledByIds(
        formFields,
        [12],
        ['3', '4', '5', '8', '10', '11', '12', '13', '14', '15', '16'].indexOf(triggerParams.federalStateUid) !== -1,
      )

      //rule_G_3247 hide "Zivilschutz" info box in "Bayern", "Niedersachsen" and "Hamburg"
      //in tabs "Gebäude(-teile)" and "Nichtwohngrundstücke"
      this.setFormFieldsEnabledByIds(
        formFields,
        [103, 167],
        ['2', '9', '6'].indexOf(triggerParams.federalStateUid) === -1,
      )

      formFields.forEach(function (item) {
        this.setFormFieldActivated(item.component, item.formFieldName, item.enabled)
      }, this)
    },

    getShowHideTabsTriggerParams(objPropertyDetails) {
      let triggerParams = {
        federalStateUid: '',
        street: '',
        houseNumber: '',
        economicEntityType: '',
        ownershipStructure: '',
        propertyType: '',
        propertyTypeName: '',
        buildingOnForeignGround: '0',
        buildingsOnThirdPartyOwners: '0',
        leasehold: '0',
        exemption: '0',
      }

      //retrieve triggerParams from property object
      if (typeof objPropertyDetails === 'object') {
        if (typeof objPropertyDetails.step2 === 'object') {
          if (typeof objPropertyDetails.step2.federalStateUid !== 'undefined') {
            triggerParams.federalStateUid = objPropertyDetails.step2.federalStateUid.toString()
          }
          if (typeof objPropertyDetails.step2.street !== 'undefined') {
            triggerParams.street = objPropertyDetails.step2.street
          }
          if (typeof objPropertyDetails.step2.houseNumber !== 'undefined') {
            triggerParams.houseNumber = objPropertyDetails.step2.houseNumber
          }
          if (typeof objPropertyDetails.step2.economicEntityType !== 'undefined') {
            triggerParams.economicEntityType = objPropertyDetails.step2.economicEntityType.toString()
          }
          if (typeof objPropertyDetails.step2.exemption !== 'undefined') {
            triggerParams.exemption = objPropertyDetails.step2.exemption.toString()
          }
        }
        if (typeof objPropertyDetails.step2a === 'object') {
          if (typeof objPropertyDetails.step2a.exemption !== 'undefined') {
            triggerParams.exemption = objPropertyDetails.step2a.exemption.toString()
          }
        }
        if (typeof objPropertyDetails.step3 === 'object') {
          if (
            typeof objPropertyDetails.step3.ownershipStructure !== 'undefined' &&
            objPropertyDetails.step3.ownershipStructure !== null
          ) {
            triggerParams.ownershipStructure = objPropertyDetails.step3.ownershipStructure.toString()
          }
          if (typeof objPropertyDetails.step3.propertyType !== 'undefined') {
            triggerParams.propertyType = objPropertyDetails.step3.propertyType.toString()
          }
          if (typeof objPropertyDetails.step3.buildingOnForeignGround !== 'undefined') {
            triggerParams.buildingOnForeignGround = objPropertyDetails.step3.buildingOnForeignGround.toString()
          }
          if (typeof objPropertyDetails.step3.buildingsOnThirdPartyOwners !== 'undefined') {
            triggerParams.buildingsOnThirdPartyOwners = objPropertyDetails.step3.buildingsOnThirdPartyOwners.toString()
          }
          if (typeof objPropertyDetails.step3.leasehold !== 'undefined') {
            triggerParams.leasehold = objPropertyDetails.step3.leasehold.toString()
          }
        }
      }

      //retrieve triggerParams from child component forms
      else {
        //property tab form "Grundstücksadresse"
        if (
          typeof this.$refs.childPropertyAddress !== 'undefined' &&
          typeof this.$refs.childPropertyAddress.form === 'object'
        ) {
          if (typeof this.$refs.childPropertyAddress.form.federal_state_uid !== 'undefined') {
            triggerParams.federalStateUid = this.$refs.childPropertyAddress.form.federal_state_uid.toString()
          }
          if (typeof this.$refs.childPropertyAddress.form.street !== 'undefined') {
            triggerParams.street = this.$refs.childPropertyAddress.form.street
          }
          if (typeof this.$refs.childPropertyAddress.form.house_number !== 'undefined') {
            triggerParams.houseNumber = this.$refs.childPropertyAddress.form.house_number
          }
        }

        //property tab form "Details"
        if (
          typeof this.$refs.childPropertyDetails !== 'undefined' &&
          typeof this.$refs.childPropertyDetails.form === 'object'
        ) {
          if (typeof this.$refs.childPropertyDetails.form.economic_entity_type !== 'undefined') {
            triggerParams.economicEntityType = this.$refs.childPropertyDetails.form.economic_entity_type.toString()
          }
          if (typeof this.$refs.childPropertyDetails.form.exemption !== 'undefined') {
            triggerParams.exemption = this.$refs.childPropertyDetails.form.exemption.toString()
          }
        }

        //property tab form "Grundstück"
        if (
          typeof this.$refs.childPropertyMoreDetails !== 'undefined' &&
          typeof this.$refs.childPropertyMoreDetails.form === 'object'
        ) {
          if (
            typeof this.$refs.childPropertyMoreDetails.form.ownership_structure !== 'undefined' &&
            this.$refs.childPropertyMoreDetails.form.ownership_structure !== null
          ) {
            triggerParams.ownershipStructure = this.$refs.childPropertyMoreDetails.form.ownership_structure.toString()
          }
          if (typeof this.$refs.childPropertyMoreDetails.form.property_type !== 'undefined') {
            triggerParams.propertyType = this.$refs.childPropertyMoreDetails.form.property_type.toString()
          }
          if (typeof this.$refs.childPropertyMoreDetails.form.building_on_foreign_ground !== 'undefined') {
            triggerParams.buildingOnForeignGround =
              this.$refs.childPropertyMoreDetails.form.building_on_foreign_ground.toString()
          }
          if (typeof this.$refs.childPropertyMoreDetails.form.buildings_on_third_party_owners !== 'undefined') {
            triggerParams.buildingsOnThirdPartyOwners =
              this.$refs.childPropertyMoreDetails.form.buildings_on_third_party_owners.toString()
          }
          if (typeof this.$refs.childPropertyMoreDetails.form.leasehold !== 'undefined') {
            triggerParams.leasehold = this.$refs.childPropertyMoreDetails.form.leasehold.toString()
          }
        }
      }

      triggerParams.propertyTypeName = evaluatePropertyTypeName(
        triggerParams.federalStateUid,
        triggerParams.propertyType,
      )

      return triggerParams
    },

    getEnableDisableFormFields() {
      let formFields = [
        {
          id: 0,
          component: 'PropertyMoreDetails',
          formFieldName: 'property_type', //Art des Grundstücks
          enabled: true,
        },
        {
          id: 1,
          component: 'PropertyMoreDetails',
          formFieldName: 'development_state', //bei unbebauten Grundstücken ggf. abw. Entwicklungszustand
          enabled: true,
        },
        {
          id: 2,
          component: 'PropertyMoreDetails',
          formFieldName: 'total_property_part', //Fläche des gesamten zur wirtschaftlichen Einheit gehörenden Anteils Grund und Bodens in m², die bebaut oder befestigt ist
          enabled: true,
        },
        {
          id: 3,
          component: 'PropertyMoreDetails',
          formFieldName: 'property_residential_purposes', //ist das Grundstück bebaut und wird überwiegend zu Wohnnzwecken genutzt?
          enabled: true,
        },
        {
          id: 4,
          component: 'PropertyMoreDetails',
          formFieldName: 'area_of_the_land_value_1', //Bodenrichtwert 1
          enabled: true,
        },
        {
          id: 5,
          component: 'PropertyMoreDetails',
          formFieldName: 'leasehold', //Ist auf dem Grundstück ein Erbbaurecht bestellt?
          enabled: true,
        },
        {
          id: 6,
          component: 'PropertyMoreDetails',
          formFieldName: 'building_on_foreign_ground', //Gebäude auf fremdem Grund und Boden
          enabled: true,
        },
        {
          id: 7,
          component: 'PropertyMoreDetails',
          formFieldName: 'buildings_on_third_party_owners', //Ihr(e) Gebäude wurde(n) auf fremden Grund und Boden errichtet?
          enabled: true,
        },
        {
          id: 8,
          component: 'PropertyMoreDetails',
          formFieldName: 'corridor', //Flur
          enabled: true,
        },
        {
          id: 9,
          component: 'PropertyMoreDetails',
          formFieldName: 'registration_land_registry', //Antrag auf Neueintragung wurde beim Grundbuchamt eingereicht am
          enabled: true,
        },
        {
          id: 10,
          component: 'PropertyMoreDetails',
          formFieldName: 'land_register_sheet', //Grundbuchblatt
          enabled: true,
        },
        {
          id: 11,
          component: 'PropertyMoreDetails',
          formFieldName: 'parcels', //tab section "Gemarkung(en) und Flurstück(e)"
          enabled: true,
        },
        {
          id: 12,
          component: 'PropertyMoreDetails',
          formFieldName: 'sub_area_section', //tab section "Auf diesem Grund und Boden befindet sich eine selbstständig nutzbare Teilfläche"
          enabled: true,
        },
        {
          id: 15,
          component: 'PropertyMoreDetails',
          formFieldName: 'located_in_outdoor_area', //Befindet sich dieses Grundstück im Außenbereich?
          enabled: true,
        },
        {
          id: 20,
          component: 'PropertyBenefits',
          formFieldName: 'non_separable_part_real_estate', //Ein räumlich nicht abgrenzbarer Teil des Grundbesitzes unterliegt einer Grundsteuerbefreiung (steuerfreie Nutzung überwiegt)
          enabled: true,
        },
        {
          id: 21,
          component: 'PropertyBenefits',
          formFieldName: 'expiration_first_grant_period', //Ablaufdatum des zuerst auslaufenden Förderungszeitraum
          enabled: true,
        },
        {
          id: 22,
          component: 'PropertyBenefits',
          formFieldName: 'expiration_last_grant_period', //Ablaufdatum des zuletzt auslaufenden Förderungszeitraum
          enabled: true,
        },
        {
          id: 23,
          component: 'PropertyBenefits',
          formFieldName: 'expected_exempt_purposes', //Voraussichtliche Verwendung für steuerbegünstigte Zwecke in %
          enabled: true,
        },
        {
          id: 24,
          component: 'PropertyBenefits',
          formFieldName: 'tax_privileged_purposes', //Steuerbefreiung; 1-15 same list as usage_type_options
          enabled: true,
        },
        {
          id: 25,
          component: 'PropertyBenefits',
          formFieldName: 'unbuilt_real_estate_exemption_description', //Bei unbebauten Grundstücken mit teilweiser Grundsteuerbefreiung - Bezeichnung
          enabled: true,
        },
        {
          id: 26,
          component: 'PropertyBenefits',
          formFieldName: 'tax_free_area', //Bei unbebauten Grundstücken mit teilweiser Grundsteuerbefreiung - Steuerfreie Fläche in m²
          enabled: true,
        },
        {
          id: 27,
          component: 'PropertyBenefits',
          formFieldName: 'unbuilt_real_estate_tax_exemption', //Bei unbebauten Grundstücken mit teilweiser Grundsteuerbefreiung - Steuerbefreiung
          enabled: true,
        },
        {
          id: 28,
          component: 'PropertyBenefits',
          formFieldName: 'spatially_definable_exemption_part_description', //Verwendung eines räumlich abgrenzbaren Teils des Grund und Bodens für steuerbefreite Zwecke - Bezeichnung
          enabled: true,
        },
        {
          id: 29,
          component: 'PropertyBenefits',
          formFieldName: 'spatially_definable_exemption_area', //Verwendung eines räumlich abgrenzbaren Teils des Grund und Bodens für steuerbefreite Zwecke - Steuerfreie Fläche in m²
          enabled: true,
        },
        {
          id: 30,
          component: 'PropertyBenefits',
          formFieldName: 'spatially_definable_tax_exemption_part', //Verwendung eines räumlich abgrenzbaren Teils des Grund und Bodens für steuerbefreite Zwecke - Steuerbefreiung
          enabled: true,
        },
        {
          id: 31,
          component: 'PropertyBenefits',
          formFieldName: 'tax_break_conditions_spatially_definable_exemption_part_description', //Für Teile des Grundbesitzes liegen die Voraussetzungen für eine Steuervergünstigung vor - Bezeichnung
          enabled: true,
        },
        {
          id: 32,
          component: 'PropertyBenefits',
          formFieldName: 'tax_break_conditions_spatially_definable_exemption_area', //Für Teile des Grundbesitzes liegen die Voraussetzungen für eine Steuervergünstigung vor - Steuerfreie Fläche in m²
          enabled: true,
        },
        {
          id: 33,
          component: 'PropertyBenefits',
          formFieldName: 'tax_break_conditions_spatially_definable_tax_exemption_part', //Für Teile des Grundbesitzes liegen die Voraussetzungen für eine Steuervergünstigung vor - Steuerbefreiung
          enabled: true,
        },
        {
          id: 34,
          component: 'PropertyBenefits',
          formFieldName: 'property_exemption_benefit_type', //Art des Grundstücks
          enabled: true,
        },
        {
          id: 101,
          component: 'PropertyBuildings',
          formFieldName: 'exemption', // controlling data variable for show/hide add-fields
          enabled: true,
        },
        {
          id: 102,
          component: 'PropertyBuildings',
          formFieldName: 'tab', // controlling data variable for show/hide whole tab
          enabled: true,
        },
        {
          id: 103,
          component: 'PropertyBuildings',
          formFieldName: 'civil_defense_info_text',
          enabled: true,
        },
        {
          id: 110,
          component: 'PropertyAgriculture',
          formFieldName: 'parcel_is_reference', // Flurstück als Referenzgrundstück verwenden
          enabled: true,
        },
        {
          id: 155,
          component: 'PropertyResidential',
          formFieldName: 'usableSpaces', //weitere Nutzflächen
          enabled: true,
        },
        {
          id: 156,
          component: 'PropertyResidential',
          formFieldName: 'exemption', //Befreiungen und Vergünstigungen
          enabled: true,
        },
        {
          id: 166,
          component: 'PropertyNonResidential',
          formFieldName: 'exemption', //Befreiungen und Vergünstigungen
          enabled: true,
        },
        {
          id: 167,
          component: 'PropertyNonResidential',
          formFieldName: 'civil_defense_info_text',
          enabled: true,
        },
        {
          id: 180,
          component: 'PropertyAgriculture',
          formFieldName: 'exemptions', // trigger for all exemption related fields in form
          enabled: true,
        },
      ]
      return formFields
    },

    setFormFieldsEnabledByIds(formFieldArray, idArray, enabled) {
      if (typeof formFieldArray === 'object' && typeof idArray === 'object' && typeof enabled === 'boolean') {
        idArray.forEach(function (id) {
          let formField
          if (typeof id === 'number') {
            formField = formFieldArray.filter((item) => {
              if (
                typeof item === 'object' &&
                typeof item.id === 'number' &&
                typeof item.component === 'string' &&
                typeof item.formFieldName === 'string' &&
                typeof item.enabled === 'boolean'
              ) {
                if (item.id === id) {
                  return item
                }
              }
            })
            if (typeof formField[0] === 'object') {
              formField[0].enabled = enabled
            }
          }
        })
      }
    },

    hideTab(stepComponentName) {
      this.setTabActivated(stepComponentName, false)
    },

    showTab(stepComponentName) {
      this.setTabActivated(stepComponentName, true)
    },

    setTabActivated(stepComponentName, activated) {
      if (typeof stepComponentName === 'string' && typeof activated === 'boolean') {
        this.getStepComponentByName(stepComponentName)['activated'] = activated

        if (activated === false) {
          if (stepComponentName === 'PropertyNonResidential') {
            //reset form to empty
            this.resetFormPropertyNonResidential()
            this.pushFormPropertyNonResidential()
            this.$refs.childPropertyNonResidential.resetForm()
          }
        }
      }
    },

    setFormFieldActivated(stepComponentName, formFieldName, activated) {
      let refName
      if (
        typeof stepComponentName === 'string' &&
        typeof formFieldName === 'string' &&
        typeof activated === 'boolean'
      ) {
        refName = 'child' + stepComponentName
        if (typeof this.$refs[refName] === 'object') {
          if (typeof this.$refs[refName][formFieldName + '_activated'] === 'boolean') {
            this.$refs[refName][formFieldName + '_activated'] = activated
          }
        }
      }
    },

    getStepComponentByName(stepComponentName) {
      let stepComponent = []

      if (!stepComponentName) {
        return null
      }

      // Get Step Component
      stepComponent = this.steps.filter((item) => {
        if (item.component.indexOf(stepComponentName) !== -1) {
          return item
        }
      })

      if (typeof stepComponent[0] === 'object') {
        return stepComponent[0]
      } else {
        return null
      }
    },

    gotoStep(stepComponentName) {
      this.stepsHavingErrorsVisible = false
      this.skendataStepsHavingErrorsVisible = false

      if (this.isFormValidationActive) {
        if (this.form.validate().errors().any()) {
          return
        }

        if (this.steps[this.step].component !== 'Dokumente') {
          if (typeof this.$refs['child' + this.steps[this.step].component].prepareForValidate === 'function') {
            this.$refs['child' + this.steps[this.step].component].prepareForValidate()
          }
          if (this.$refs['child' + this.steps[this.step].component].form.validate().errors().any()) {
            this.$store.dispatch('showToast', {
              message: this.$t('newProperty.form_validation_text'),
              toastType: 'error',
            })
            return
          }

          // save current step's form before switching to another step
          this.$refs['child' + this.steps[this.step].component].saveForm()
        }
      }

      // now switch to the given step
      let myIndex = this.steps.findIndex((stepsElement) => stepsElement.component === stepComponentName)
      if (myIndex !== -1) {
        if (this.steps[myIndex].activated === true) {
          this.step = myIndex
        }
      }
    },

    precedingStep() {
      if (this.isFormValidationActive) {
        if (typeof this.$refs['child' + this.steps[this.step].component].prepareForValidate === 'function') {
          this.$refs['child' + this.steps[this.step].component].prepareForValidate()
        }

        if (
          typeof this.$refs['child' + this.steps[this.step].component].form === 'object' &&
          typeof this.$refs['child' + this.steps[this.step].component].form.validate === 'function'
        ) {
          if (this.$refs['child' + this.steps[this.step].component].form.validate().errors().any()) {
            this.$store.dispatch('showToast', {
              message: this.$t('newProperty.form_validation_text'),
              toastType: 'error',
            })
            return
          }
        }

        // save current step's form before switching to another step
        if (typeof this.$refs['child' + this.steps[this.step].component].saveForm === 'function') {
          this.$refs['child' + this.steps[this.step].component].saveForm()
        }
      }

      this.step = this.calcPrecedingStep()
    },

    calcPrecedingStep() {
      let myPrecedingStep = this.step

      if (myPrecedingStep > 0) {
        do {
          myPrecedingStep--
        } while (this.steps[myPrecedingStep].activated === false && myPrecedingStep > 0)

        if (this.steps[myPrecedingStep].activated === false) {
          myPrecedingStep = this.step
        }
      }

      return myPrecedingStep
    },

    nextStep() {
      if (this.isFormValidationActive) {
        if (typeof this.$refs['child' + this.steps[this.step].component].prepareForValidate === 'function') {
          this.$refs['child' + this.steps[this.step].component].prepareForValidate()
        }

        if (
          typeof this.$refs['child' + this.steps[this.step].component].form === 'object' &&
          typeof this.$refs['child' + this.steps[this.step].component].form.validate === 'function'
        ) {
          if (this.$refs['child' + this.steps[this.step].component].form.validate().errors().any()) {
            this.$store.dispatch('showToast', {
              message: this.$t('newProperty.form_validation_text'),
              toastType: 'error',
            })
            return
          }
        }

        // save current step's form before switching to another step
        if (typeof this.$refs['child' + this.steps[this.step].component].saveForm === 'function') {
          this.$refs['child' + this.steps[this.step].component].saveForm()
        }
      }

      if (this.step === this.maxStep) {
        if (this.canSaveProperty) {
          this.validateOtherStepsBeforeSave()
          if (this.stepsHavingErrors.length === 0) {
            this.savePropVisible = true
          } else {
            this.stepsHavingErrorsVisible = true
          }
        } else {
          this.savePropVisible = true
        }
      } else {
        this.step = this.calcNextStep()
      }
    },

    calcNextStep() {
      let myNextStep = this.step

      if (myNextStep < this.maxStep) {
        do {
          myNextStep++
        } while (this.steps[myNextStep].activated === false && myNextStep < this.maxStep)

        if (this.steps[myNextStep].activated === false) {
          myNextStep = this.step
        }
      }

      return myNextStep
    },

    goBackFromSaveProperty() {
      this.savePropVisible = false
    },

    doNotSaveProperty() {
      this.$router.push('/dashboard')
    },

    callForSaveProperty() {
      if (!this.isPropertyReadOnly) {
        this.validateOtherStepsBeforeSave()
        if (this.stepsHavingErrors.length === 0) {
          this.doSaveProperty()
        } else {
          this.stepsHavingErrorsVisible = true
        }
      } else {
        this.$store.dispatch('showToast', {
          message: this.$t('newProperty.read_only_save_error'),
          toastType: 'error',
        })
      }
    },

    performSaveProperty() {
      this.addCommentVisible = false
      document.removeEventListener('keyup', this.modalListner)
      this.saveProperty({
        property: this.$store.state.Properties.objPropertyDetails,
        client_comment: typeof this.saveForm.client_comment === 'string' ? this.saveForm.client_comment.trim() : '',
      })
    },

    performSavePropertyIfEmptyComment() {
      if (this.saveForm.client_comment.trim() === '') {
        this.performSaveProperty()
      }
    },

    // check all active forms except the current one that already has been checked
    validateOtherStepsBeforeSave(includeCurrentStep = false) {
      this.stepsHavingErrors = []
      if (this.isFormValidationActive) {
        this.steps.forEach((step, index) => {
          if (
            (index !== this.step || includeCurrentStep) &&
            step.component !== 'Dokumente' &&
            step.activated === true
          ) {
            let refName = 'child' + step.component
            if (typeof this.$refs[refName] === 'object') {
              if (typeof this.$refs[refName].prepareForValidate === 'function') {
                this.$refs[refName].prepareForValidate()
              }
              if (this.$refs[refName].form.validate().errors().any()) {
                this.stepsHavingErrors.push(step)
              }
            }
          }
        }, this)
      }
    },

    doSaveProperty() {
      this.pushForms()

      //rule_G_1624 empty FormPropertyLeasehold if form.building_on_foreign_ground is not 1
      if (this.$refs.childPropertyMoreDetails.form.building_on_foreign_ground.toString() !== '1') {
        this.resetFormPropertyLeasehold()
      }
      //end rule_G-1624

      if (this.savePropVisible || !this.shouldAskForClientComment) {
        this.savePropVisible = false
        this.performSaveProperty()
      } else {
        this.addCommentVisible = true //get client_comment before executing performSaveProperty()
        this.invokeReference('saveForm_client_comment')
      }
    },

    doSavePropertyIfEmptyComment() {
      if (this.saveForm.client_comment.trim() === '') {
        this.doSaveProperty()
      }
    },

    doSavePropertyBeforeSkendataRequest() {
      this.pushForms()

      //rule_G_1624 empty FormPropertyLeasehold if form.building_on_foreign_ground is not 1
      if (this.$refs.childPropertyMoreDetails.form.building_on_foreign_ground.toString() !== '1') {
        this.resetFormPropertyLeasehold()
      }
      //end rule_G-1624

      this.savePropVisible = false
      document.removeEventListener('keyup', this.modalListner)
      this.savePropertyBeforeSkendataRequest(this.$store.state.Properties.objPropertyDetails)
    },

    pushForms() {
      this.$refs.childPropertyAddress.saveForm()
      this.pushFormPropertyAddress()
      this.$refs.childPropertyDetails.saveForm()
      this.pushFormPropertyDetails()
      this.$refs.childPropertyMoreDetails.saveForm()
      this.pushFormPropertyMoreDetails()
      this.$refs.childPropertyBenefits.saveForm()
      this.pushFormPropertyBenefits()
      this.$refs.childPropertyCommunity.saveForm()
      this.pushFormPropertyCommunity()
      this.$refs.childPropertyOwnership.saveForm()
      this.pushFormPropertyOwnership()
      this.$refs.childPropertyBuildings.saveForm()
      this.pushFormPropertyBuildings()
      this.$refs.childPropertyResidential.saveForm()
      this.pushFormPropertyResidential()
      this.$refs.childPropertyNonResidential.saveForm()
      this.pushFormPropertyNonResidential()
      this.$refs.childPropertyLeasehold.saveForm()
      this.pushFormPropertyLeasehold()
      this.$refs.childPropertyAgriculture.saveForm()
      this.pushFormPropertyAgriculture()
      this.$refs.childPropertyLivestock.saveForm()
      this.pushFormPropertyLivestock()
    },

    invokeReference(ref) {
      this.$nextTick(() => {
        if (
          typeof this.$refs !== 'undefined' &&
          typeof this.$refs[ref] !== 'undefined' &&
          typeof this.$refs[ref].focus === 'function'
        ) {
          this.$refs[ref].focus()
        }
      })
    },

    cancelListener() {
      if (event.code === 'Enter') {
        this.cancelProp('Ja')
      }
    },

    openDataProvisioningDialog() {
      this.dataProvisioningDialogVisible = true
    },

    closeDataProvisioningDialog() {
      this.dataProvisioningDialogVisible = false
      this.selected = []
    },

    openGrundbuchauszugWebsite() {
      window.open(`https://auszug.grundsteuer-digital.de/`, '_blank')
    },

    openGeoViewerWebsite() {
      window.open(
        'https://hilfe.grundsteuer-digital.de/faq/informationsschreiben-und-seiten-der-bundeslaender/',
        '_blank',
      )
    },

    openGeoViewerDialog() {
      this.geoViewerDialogVisible = true
    },

    async isValidAccessforSkendata() {
      let dataAcquisitionClientPortal = this.getCurrentUser.dataAcquisition.dataAcquisitionClientPortal
      let dataAcquisition = this.getCurrentUser.dataAcquisition.dataAcquisition
      let dataAcquisitionLimit = this.getCurrentUser.dataAcquisition.dataAcquisitionLimit
      let dataAcquisitionLimitAmount = this.getCurrentUser.dataAcquisition.dataAcquisitionLimitAmount

      let dataAcquisitionData = await this.getDataAcquisition()

      let totalCosts = dataAcquisitionData.total_costs

      let is_data_acquisition_active = dataAcquisition === '1' && dataAcquisitionClientPortal === '1'

      if (!is_data_acquisition_active) {
        this.toggleDataAcquisitionAboRequied = true
        this.toggleDataAcquisitionLoader = false
        this.dataProvisioningDialogVisible = false
        return false
      }

      if (!dataAcquisitionLimit) {
        return true
      }

      if (totalCosts >= parseFloat(dataAcquisitionLimitAmount)) {
        this.toggleDataAcquisitionAboRequied = true
        this.toggleDataAcquisitionLoader = false
        this.dataProvisioningDialogVisible = false
        return false
      }

      return true
    },

    async confirmOpenDataAacquisitionAPI() {
      const isUnpaidAccount = this.getCurrentUser.taxConsultant.accountPayType !== 'paid';

      if (isUnpaidAccount) {
        this.closeDataProvisioningDialog()
        this.toggleDataAcquisitionAboRequired = true
        return false
      }

      const is_valid = await this.isValidAccessforSkendata()

      if (!is_valid) {
        return false
      }

      let formValidationOk = true
      if (this.$route.query.skendata_request !== 'true') {
        this.validateOtherStepsBeforeSave(true)
        if (this.stepsHavingErrors.length === 0) {
          this.doSavePropertyBeforeSkendataRequest()
        } else {
          formValidationOk = false
          this.closeDataProvisioningDialog()
          this.skendataStepsHavingErrorsVisible = true
        }
      }

      if (formValidationOk) {
        if (this.$route.query.skendata_request === 'true') {
          this.closeDataProvisioningDialog()
          this.toggleDataAcquisitionLoader = true

          let address = ''
          let property_uid = 0
          let user_identifier = 0
          let account_uid = 0
          let user_uid = 0
          let process_id = ''

          if (this.$route.params.id > 0) {
            property_uid = this.$route.params.id
            address = this.getPropertyAddress()
            account_uid = this.getCurrentUser.accountUid
            user_uid = this.getCurrentUser.clientUid
            user_identifier = account_uid + user_uid + property_uid

            let payloadkendataProcess = {
              property_uid: property_uid,
              address: address,
              account_uid: account_uid,
              user_uid: user_uid,
              user_identifier: user_identifier,
            }

            this.getSkendataProcess(payloadkendataProcess)
              .then((response) => {
                if (response) {
                  if (response.data.success) {
                    process_id = response.data.process_id.toString()
                  }
                  if (address.toString() !== '' && property_uid > 0 && user_identifier > 0) {
                    let payloadFulfillment = {
                      property_uid: property_uid,
                      address: address,
                      user_identifier: user_identifier,
                      parcel_id: 0,
                      process_id: process_id,
                    }

                    this.getSkendataFulfillment(payloadFulfillment).then((response) => {
                      if (response) {
                        if (response.data.link) {
                          let fulfillment_link = response.data.link
                          window.open(fulfillment_link, '_self')
                          //window.open(fulfillment_link, '_blank');
                        }
                        if (response.data.error) {
                          this.showToast({
                            message: t('general.errors.unknownError'),
                            toastType: 'error',
                          })
                          this.toggleDataAcquisitionLoader = true
                        }
                      }
                    })
                  }
                }
              })
              .catch(() => {
                this.showToast({
                  message: t('general.errors.unknownError'),
                  toastType: 'error',
                })
              })
          }
        }
      }
    },

    getPropertyAddress() {
      let propertyStreet = ''
      if (this.$store.state.Properties.formPropertyAddress.street !== 'undefined') {
        propertyStreet = this.$store.state.Properties.formPropertyAddress.street
      }
      let propertyHouseNumber = ''
      if (this.$store.state.Properties.formPropertyAddress.house_number !== 'undefined') {
        propertyHouseNumber = this.$store.state.Properties.formPropertyAddress.house_number
      }
      let propertyyZip = ''
      if (this.$store.state.Properties.formPropertyAddress.zip !== 'undefined') {
        propertyyZip = this.$store.state.Properties.formPropertyAddress.zip
      }
      let propertyCity = ''
      if (this.$store.state.Properties.formPropertyAddress.city !== 'undefined') {
        propertyCity = this.$store.state.Properties.formPropertyAddress.city
      }

      return propertyStreet + ' ' + propertyHouseNumber + ' ' + propertyyZip + ' ' + propertyCity
    },

    async sendSkendataToApi() {
      if ((await this.isValidAccessforSkendata()) === true) {
        if (this.$route.query.fulfillment_id) {
          let fulfillment_id = this.$route.query.fulfillment_id
          let property_uid = this.$route.params.id
          let property_address = this.getPropertyAddress()

          this.skenDataAddress = property_address

          let payloadSkendataFulfillment = {
            property_uid: property_uid,
            fulfillment_id: fulfillment_id,
          }

          // get skendata fulfillment data and save skendata in gsd db
          this.saveSkendataFulfillment(payloadSkendataFulfillment).then((response) => {
            if (response) {
              /**
               * @param {{error_key:string}} response.data
               */
              if (response.data.success === false) {
                if (response.data.error.includes('Client error')) {
                  // Action cancelled - nothing to do here
                } else if (response.data.error_key) {
                  this.showToast({
                    message: t('general.errors.unknownError'),
                    toastType: 'error',
                  })
                }
              } else {
                let objSkendataData = {
                  skendata: response.data.skendata,
                  account_uid: response.data.account_uid,
                  user_uid: this.getCurrentUser.clientUid,
                  data: response.data.data,
                  address: property_address,
                }

                this.saveSkendataParcels(objSkendataData).then((response) => {
                  if (response.data.success) {
                    this.showToast({
                      message: t('newProperty.propertyMoreDetails.parcels.added_successfully'),
                      toastType: 'success',
                    })
                    this.toggleDataAcquisitionLoader = false
                    this.loadProperty(property_uid)
                  } else {
                    this.showToast({
                      message: t('general.errors.unknownError'),
                      toastType: 'error',
                    })
                  }
                })
              }
            }
          })
        }
      }
    },

    editAddedProperty() {
      if (this.didAddNewProperty) {
        let propertyId = this.getLastAddedPropertyId
        this.$store.state.Properties.lastAddedPropertyId = 0
        this.$router.push('/property/' + propertyId)
      }
    },

    gotoDashboard() {
      if (this.didAddNewProperty) {
        this.$store.state.Properties.lastAddedPropertyId = 0
        this.$router.push('/dashboard')
      }
    },

    openRoometricDialog() {
      if (this.isRoometricActive) {
        this.isRoometricNonResidentialVariant = false
        this.isRoometricDialogVisible = true
      }
    },

    openRoometricDialogNonResidential() {
      if (this.isRoometricActive) {
        this.isRoometricNonResidentialVariant = true
        this.isRoometricDialogVisible = true
      }
    },

    closeRoometricDialog() {
      this.isRoometricDialogVisible = false
    },

    startElsterValidation() {
      let formValidationOk = false
      this.validateOtherStepsBeforeSave(true)
      if (this.stepsHavingErrors.length === 0) {
        formValidationOk = true
      } else {
        this.elsterStepsHavingErrorsVisible = true
      }

      if (formValidationOk) {
        let propertyUid = this.isNewProperty ? '' : '"' + this.getObjPropertyDetails.uid + '" '
        this.elsterValidation.validationSteps.forEach((validationStep) => {
          validationStep.running = false
          validationStep.done = false
          validationStep.success = false
          validationStep.failed = false
          switch (validationStep.name) {
            case 'checkForm':
              validationStep.infoText = this.$t('elster.splashScreen.step1Info').replace(
                '%tab%',
                this.steps[this.step].title,
              )
              break
            case 'saveProperty':
              validationStep.infoText = this.$t('elster.splashScreen.step2Info').replace('%propertyUid%', propertyUid)
              break
            case 'reloadProperty':
              validationStep.infoText = this.$t('elster.splashScreen.step3Info').replace('%propertyUid%', propertyUid)
              break
            case 'elsterValidate':
              validationStep.infoText = this.$t('elster.splashScreen.step4Info')
          }
        }, this)
        this.elsterValidation.splashScreenErrorMsg = ''
        this.elsterValidation.elsterValidationErrors = []
        this.elsterValidation.elsterValidationHints = []
        this.elsterValidation.elsterValidationSuccess = false
        this.elsterValidation.successScreenVisible = false
        this.elsterValidation.errorScreenVisible = false
        this.elsterValidation.splashScreenVisible = true
        this.startElsterValidationStep(0)
      }
    },

    startElsterValidationStep(stepNumber) {
      //stepNumber begins with 0
      if (
        typeof stepNumber === 'number' &&
        stepNumber >= 0 &&
        stepNumber < this.elsterValidation.validationSteps.length
      ) {
        this.elsterValidation.validationSteps[stepNumber].running = true
        this.elsterValidation.validationSteps[stepNumber].done = false
        this.elsterValidation.validationSteps[stepNumber].success = false
        this.elsterValidation.validationSteps[stepNumber].failed = false

        switch (stepNumber) {
          case 0:
            this.elsterCheckForm()
            break
          case 1:
            this.elsterSaveProperty()
            break
          case 2:
            this.elsterReloadProperty()
            break
          case 3:
            this.elsterValidate()
            break
        }
      }
    },

    elsterCheckForm() {
      this.elsterValidation.validationSteps[0].running = true
      this.elsterValidation.validationSteps[0].done = false
      this.elsterValidation.validationSteps[0].success = false
      this.elsterValidation.validationSteps[0].failed = false

      let currentPropertyStepComponent = this.steps[this.step]
      let foundErrors = false
      if (currentPropertyStepComponent.component !== 'Dokumente') {
        if (typeof this.$refs['child' + currentPropertyStepComponent.component].prepareForValidate === 'function') {
          this.$refs['child' + this.steps[this.step].component].prepareForValidate()
        }
        if (this.$refs['child' + currentPropertyStepComponent.component].form.validate().errors().any()) {
          this.elsterValidation.splashScreenErrorMsg = this.$t('elster.splashScreen.step1ErrorMsg').replace(
            '%tab%',
            currentPropertyStepComponent.title,
          )
          foundErrors = true
        } else {
          // save current step's form before switching to another step
          this.$refs['child' + this.steps[this.step].component].saveForm()
        }
      }
      this.elsterValidation.validationSteps[0].running = false
      this.elsterValidation.validationSteps[0].done = true
      this.elsterValidation.validationSteps[0].success = !foundErrors
      this.elsterValidation.validationSteps[0].failed = foundErrors
    },

    elsterSaveProperty() {
      this.elsterValidation.validationSteps[1].running = true
      this.elsterValidation.validationSteps[1].done = false
      this.elsterValidation.validationSteps[1].success = false
      this.elsterValidation.validationSteps[1].failed = false

      this.pushForms()

      //rule_G_1624 empty FormPropertyLeasehold if form.building_on_foreign_ground is not 1
      if (this.$refs.childPropertyMoreDetails.form.building_on_foreign_ground.toString() !== '1') {
        this.resetFormPropertyLeasehold()
      }
      //end rule_G-1624

      this.savePropertySilently({
        property: this.$store.state.Properties.objPropertyDetails,
        client_comment: '',
        callbackFn: this.onElsterSaveProperty,
      })
    },

    onElsterSaveProperty(success, errorMsg) {
      if (
        this.elsterValidation.validationSteps[1].running &&
        typeof success === 'boolean' &&
        typeof errorMsg === 'string'
      ) {
        this.elsterValidation.validationSteps[1].running = false
        this.elsterValidation.validationSteps[1].done = true
        this.elsterValidation.validationSteps[1].success = success
        this.elsterValidation.validationSteps[1].failed = !success
        if (!success) {
          this.elsterValidation.splashScreenErrorMsg = this.$t('elster.splashScreen.step2ErrorMsg')
        }
        if (errorMsg !== '') {
          this.elsterValidation.splashScreenErrorMsg = this.elsterValidation.splashScreenErrorMsg + ' ' + errorMsg
        }
      }
    },

    elsterReloadProperty() {
      this.elsterValidation.validationSteps[2].running = true
      this.elsterValidation.validationSteps[2].done = false
      this.elsterValidation.validationSteps[2].success = false
      this.elsterValidation.validationSteps[2].failed = false

      this.setObjPropertyDetails([])
      this.initPropertyForms()
      this.apiGetPropertyDetailsSilently({
        property_id: this.$route.params.id,
        callbackFn: this.onElsterReloadProperty,
      })
    },

    onElsterReloadProperty(success, errorMsg) {
      if (
        this.elsterValidation.validationSteps[2].running &&
        typeof success === 'boolean' &&
        typeof errorMsg === 'string'
      ) {
        this.elsterValidation.validationSteps[2].running = false
        this.elsterValidation.validationSteps[2].done = true
        this.elsterValidation.validationSteps[2].success = success
        this.elsterValidation.validationSteps[2].failed = !success
        if (!success) {
          this.elsterValidation.splashScreenErrorMsg = this.$t('elster.splashScreen.step3ErrorMsg')
        }
        if (errorMsg !== '') {
          this.elsterValidation.splashScreenErrorMsg = this.elsterValidation.splashScreenErrorMsg + ' ' + errorMsg
        }
      }
    },

    elsterValidate() {
      this.elsterValidation.validationSteps[3].running = true
      this.elsterValidation.validationSteps[3].done = false
      this.elsterValidation.validationSteps[3].success = false
      this.elsterValidation.validationSteps[3].failed = false

      this.apiElsterValidate()
    },

    isElsterStepRunning() {
      let myRunning = false

      this.elsterValidation.validationSteps.forEach((step) => {
        if (!myRunning && typeof step.running === 'boolean' && step.running) {
          myRunning = true
        }
      }, this)

      return myRunning
    },

    cancelElsterValidation() {
      this.elsterValidation.splashScreenVisible = false
      if (this.isElsterStepRunning()) {
        this.showPageLoader()
      } else {
        if (this.elsterValidation.validationSteps[2].done && this.elsterValidation.validationSteps[2].failed) {
          this.$router.push('/dashboard')
        }
      }
    },

    getIconClassByNumber(number) {
      let myClass = ''

      if (typeof number === 'number' && number >= 0 && number <= 9) {
        myClass = 'bi bi-' + number + '-circle'
      }

      return myClass
    },

    getClassWaiting(elsterValidationStep) {
      let myClass = ''

      if (
        !elsterValidationStep.running &&
        !elsterValidationStep.done &&
        !elsterValidationStep.success &&
        !elsterValidationStep.failed
      ) {
        myClass = 'elster_waiting_step'
      }

      return myClass
    },

    apiElsterValidate() {
      let data = {
        uid: this.$route.params.id,
      }
      apiClient
        .post('property/elsterValidate', data)
        .then((response) => {
          if (
            typeof response !== 'undefined' &&
            typeof response.data !== 'undefined' &&
            typeof response.data.validatorResult === 'object' &&
            typeof response.data.validatorResult[data.uid] !== 'undefined'
          ) {
            let validatorResult = JSON.parse(JSON.stringify(response.data.validatorResult[data.uid]))
            if (validatorResult.errors && typeof validatorResult.errors === 'object') {
              Object.values(validatorResult.errors).forEach((error) => {
                if (error.type === 'error') {
                  this.elsterValidation.elsterValidationErrors.push(error)
                } else if (error.type === 'hint') {
                  this.elsterValidation.elsterValidationHints.push(error)
                }
              }, this)
            }
            if (typeof validatorResult.success === 'boolean' && validatorResult.success === true) {
              this.elsterValidation.elsterValidationSuccess = true
            }
            this.onElsterValidate(true)
          } else {
            this.onElsterValidate(false)
          }
        })
        .catch(() => {
          this.onElsterValidate(false)
        })
    },

    onElsterValidate(success) {
      if (this.elsterValidation.validationSteps[3].running && typeof success === 'boolean') {
        this.elsterValidation.validationSteps[3].running = false
        this.elsterValidation.validationSteps[3].done = true
        this.elsterValidation.validationSteps[3].success = success
        this.elsterValidation.validationSteps[3].failed = !success
        if (!success) {
          this.elsterValidation.splashScreenErrorMsg = this.$t('elster.splashScreen.step4ErrorMsg')
        }
      }
    },

    showElsterValidationResult() {
      if (this.elsterValidation.elsterValidationSuccess) {
        this.elsterValidation.successScreenVisible = true
      } else {
        this.elsterValidation.errorScreenVisible = true
      }
    },

    closeElsterSuccessScreen() {
      this.elsterValidation.successScreenVisible = false
    },

    closeElsterErrorScreen() {
      this.elsterValidation.errorScreenVisible = false
    },
  },
  beforeMount() {
    if (this.$route.query.fulfillment_id) {
      this.toggleDataAcquisitionLoader = true
      setTimeout(() => {
        this.sendSkendataToApi()
      }, 2500)
    }
    if (this.$route.query.skendata_request === 'true') {
      setTimeout(() => {
        this.confirmOpenDataAacquisitionAPI()
      }, 1200)
    }
  },
}
</script>

<style lang="scss">
@import './assets/custom.css';
@import './assets/form_style.css';

.return-further.return-further-style {
  max-width: 350px !important;
}

button.o-btn-action {
  background: rgba(34, 157, 86, 1);
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 16px !important;
  font-weight: normal;
  max-width: 120px;
  padding: 10px 10px;
  width: 100%;
}

.newsignature {
  line-height: 1.2;
  max-width: 100%;
  word-break: break-all;
}

.property_error {
  color: red;
  display: flex;
}

.return-further-style {
  margin: 40px 0 0;
  max-width: 350px;
}

.Modal_content_Area input[type='text'] {
  height: 32px !important;
  width: 68% !important;
}

.form-bottom-button {
  justify-content: flex-end;
}

.tab-bord.Step_SectionPropertyAddress,
.Step_Section03 .step-button-sectioner-profile {
  overflow: hidden;
}

.form-bottom-button button {
  height: 32px !important;
  width: 120px !important;
}

h4.heading_title.centerleft {
  font-size: 26px;
}

.gdr_plots {
  align-items: center;
  display: flex;
  grid-gap: 20px;
  flex-wrap: wrap;
}

.gdr_plots_inner {
  align-items: center;
  color: #4b4b4b;
  display: flex;
  font-size: 15px;
  grid-gap: 10px;
}

.gdr_plots_inner input.radio-button {
  height: 22px;
  width: 22px;
}

.gdr_plots .property_error {
  flex: 0 0 100%;
}

.steps {
  display: grid;
  grid-template-columns: 25% auto !important;
  grid-column-gap: 4.2%;
  padding-top: 15px;
}

.mobile-top-bar {
  display: none;
}

.left-side-bar {
  display: block;
}

optgroup {
  color: #4b4b4b;
  font-size: 15px;
  font-weight: 100;
}

.modal-text {
  margin-bottom: 25px;
  margin-top: 22px;
}

.modal_display_flex {
  align-content: flex-start;
  display: flex;
  flex-direction: column;
}

.modal_display_flex_row {
  align-items: center;
  display: flex;
}

.elster_display_flex {
  align-content: flex-start;
  display: flex;
  flex-direction: column;
}

.elster_display_flex_row {
  align-items: center;
  display: flex;
}

.elster_display_flex_col_number {
  display: flex;
  font-size: x-large;
  min-height: 30px;
  min-width: 30px;
}

.elster_display_flex_col_number .bi::before {
  font-weight: 600 !important;
}

.elster_display_flex_col_description {
  display: flex;
}

.elster_display_flex_col_status {
  display: flex;
  font-size: x-large;
  margin-left: 15px;
  min-height: 30px;
  min-width: 30px;
}

.elster_waiting_step {
  color: var(--api-font);
  opacity: 0.6;
}

span.elster_error {
  border: 3px solid #a4262c;
  color: #a4262c !important;
  display: flex;
  padding: 15px;
}

.spinner {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    cursor: pointer;
  }
}

.spinner-border-m {
  height: 1.5rem !important;
  min-height: 1.5rem;
  min-width: 1.5rem;
  width: 1.5rem !important;
  --bs-spinner-width: 1.5rem !important;
  --bs-spinner-height: 1.5rem !important;
  --bs-spinner-border-width: 0.15em !important;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .comp_info {
    flex-direction: row !important;
  }
  .comp_user {
    margin-right: 20px;
  }
  button.button-style:first-child {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 100px) and (max-width: 767px) {
  .comp_info {
    flex-direction: column;
  }
  .comp_user {
    text-align: center;
  }
}

@media only screen and (min-width: 780px) and (max-width: 980px) {
  .comp_info {
    max-width: 100%;
  }
  .tab-border-profile {
    min-height: 300px;
  }
  .container {
    max-width: 100%;
  }
  .comp_info {
    max-width: 100% !important;
  }
}

.input-icon {
  position: relative;
}

.input-icon img {
  pointer-events: none;
  position: absolute;
  right: 6px;
  top: 12px;
}

.Step_SectionPropertyAddress,
.Step_SectionPropertyDetails,
.Step_SectionPropertyMoreDetails {
  .step-button-section {
    padding-bottom: 77px;
  }
}

.Step_SectionPropertyDetails {
  .step-button-section {
    padding-bottom: 227px;
  }
}

.Step_Section04 {
  .step-button-section {
    padding-bottom: 169px;
  }
}

.Step_Section05,
.Step_Section09,
.Step_Section010 {
  .step-button-section {
    padding-bottom: 89px;
  }
}

.Step_Section06 {
  .step-button-section {
    padding-bottom: 260px;
  }
}

.Step_Section07 {
  .step-button-section {
    padding-bottom: 129px;
  }
}

.Step_Section08 {
  .step-button-section {
    padding-bottom: 99px;
  }
}

@media (max-width: 1290px) {
  .steps {
    display: block;
  }
  .mobile-top-bar {
    display: block;
  }
  .left-side-bar {
    display: none;
  }
  .step-button-section {
    padding-bottom: 44px !important;
  }
}

.left-divider::before {
  width: 0 !important;
}

.show_spinner::-webkit-inner-spin-button,
.show_spinner::-webkit-outer-spin-button {
  cursor: pointer;
  opacity: 1;
}

.btn_max {
  max-width: 170px;
  width: 100%;
}

select {
  -webkit-border-radius: 0; /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -moz-border-radius: 0; /* Firefox 1-3.6 */
  border-radius: 0; /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
}

.searchableSelect {
  border: 1px solid #bbbbbb;
  border-radius: 0;
  color: #4b4b4b;
  height: 40px;
  line-height: 31px;
  padding: 0 6px;
  width: 100% !important;
}

.searchableSelect .vue-input input {
  color: #4b4b4b;
  font-size: 16px;
}

.vue-dropdown-item.selected.highlighted,
.vue-dropdown-item.highlighted {
  background-color: #f3f3f3;
}

.searchableSelect .vue-input input:focus,
.searchableSelect .vue-input select:focus {
  border: 0px solid #333 !important;
  box-shadow: 0 0 0 !important;
  outline: none;
}

.not_ul_new {
  border: 1px solid;
}

.dropdown-menu.dropdown-menu-new {
  border: none !important;
  border-radius: 0;
  padding: 0px;
  width: 100%;
}

.dropdown-menu-new ul li {
  border: 0 !important;
  font-size: 14px;
  list-style: none;
  padding: 3px 10px;
}

.dropdown-menu-new ul {
  height: 100%;
  margin: 0 !important;
  max-height: 400px;
  overflow: auto;
  padding: 0px 0 0 0 !important;
}

.dropdown-menu-new ul li:hover {
  background: #1e90ff;
  color: #fff;
}

.data-form {
  margin-bottom: 15px;
  position: relative;
}

.dropdown-menu-new ul li.INputSearch {
  background: #fff;
  border-bottom: 1px solid #c7c7c7 !important;
  left: 0;
  margin: 0px 0 10px;
  padding: 10px 10px;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}

.dropdown-menu-new ul li.INputSearch:hover {
  background: none;
}

.dropdown-menu-new ul li.INputSearch i {
  color: #333;
  position: absolute;
  right: 20px;
  top: 17px;
}

.SelectBox i.chevron_arrow {
  font-size: 10px;
  font-weight: 800 !important;
  position: absolute;
  right: 5px;
  top: 13px;
}

.SelectBox {
  position: relative;
}

li.INputSearch:hover {
  background: #fff !important;
}

.input-field::-webkit-input-placeholder {
  color: #4b4b4b;
}

.input-field:-ms-input-placeholder {
  color: #4b4b4b;
}

.input-field::placeholder {
  color: #4b4b4b;
}

.deleteIcon {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.deleteIcon h2.subheading_title {
  margin-top: 0;
}

.deleteIcon img {
  max-width: 20px;
  width: 100%;
}

option:checked,
option:focus {
  background: var(--api-background) !important;
  color: white;
}

.btn_cancel button.border-button-style {
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 170px;
  width: 100%;
}

.spiner_center {
  height: auto;
  left: 50%;
  margin: 0 auto;
  position: fixed;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.spiner_center_over:before {
  background: rgba(0, 0, 0, 0.6);
  content: '';
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}

.quiz > section {
  display: block !important;
  opacity: 0;
  transform: translatex(-150%);
  transition-duration: 0.8s;
}

.quiz > section.active {
  opacity: 1;
  transform: translatex(0%);
}

.quiz > section:not(.active) {
  position: absolute;
  top: 0;
}

.quiz {
  overflow-y: hidden;
  padding-top: 20px;
  position: relative;
}

.text_left.step-button-section {
  padding-bottom: 50px;
}

.modal-content {
  align-content: center !important;
  align-items: center !important;
  background: #f2f2f2 !important;
  border: none !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  font-size: 14px !important;
  padding: 5px !important;
}

.modal-content-highlight {
  background: #fff4ce !important;
}

.modal-button-row {
  align-items: stretch !important;
  border: none !important;
  display: flex !important;
  font-size: 14px !important;
  padding: 5px !important;
}

.modal-button-row-inside {
  align-items: stretch !important;
  border: none !important;
  display: flex !important;
  font-size: 14px !important;
  margin-top: -20px;
  padding: 5px;
  padding-bottom: 20px;
}

.modal-button-left {
  align-self: flex-start !important;
  margin-left: 0 !important;
}

.modal-button-right {
  margin-left: auto !important;
}

.modal-content-div {
  padding: 5px !important;
}

.modal-content-headline {
  font-weight: 600 !important;
}

.modal-icon {
  padding: 10px !important;
}

.modal-img {
  height: 18px !important;
  width: 18px !important;
}

.modal-button-info {
  background: none !important;
  border-color: #333 !important;
  color: #333 !important;
  line-height: 20px !important;
  margin-left: auto;
  margin-top: 30px !important;
  padding: 10px 0 !important;
  width: 280px !important;
}

.modal-button-info:hover {
  background: #ccc !important;
  border-color: #ccc !important;
  color: #333 !important;
}

.modal-tab-border-home {
  border: none;
}

.modal-button-display-only {
  border: none !important;
  font-size: 14px !important;
  max-width: 140px;
}

.modal-backdrop .modal {
  border-top: 3px solid var(--api-background) !important;
}
.save_confirmation button:first-child {
  margin-left: 0;
}

.form-group {
  padding-bottom: 25px;
}

.comment_field_label {
  margin-top: 0;
  padding-bottom: 5px;
}

.filemin_cont2.cancel-popup {
  min-height: 145px;
  padding-left: 6px;
  padding-right: 6px;
  text-align: left;
}

.btn_170_right {
  text-align: right;
}

.b_btm_indent {
  padding-left: 21px;
}

.btn_cancel_indent {
  padding-left: 6px;
}

.padding-right-zero {
  padding-right: 0;
}

.comment-area {
  height: 5em;
  resize: none;
  width: 100%;
}

.step-list-ul li a.nav_add_on:hover {
  border-color: transparent !important;
  color: var(--api-font) !important;
  font-weight: var(--bs-body-font-weight);
}

.step-list-ul li a.nav_add_on:hover span {
  border-color: transparent;
  color: var(--api-font) !important;
}

.button-no-highlight {
  background: none;
  border: 1px solid #333333;
  border-radius: 2px;
  color: #333333;
  height: 40px;
  margin: 0 10px;
  max-width: 170px;
  width: 130%;
}

.button-no-highlight:hover {
  background: rgba(204, 204, 204, 1);
  border-color: rgba(204, 204, 204, 1);
}

.modal_spinner {
  display: block !important;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.elster_scroll {
  max-height: 95%;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-y: auto;
}

@media (max-width: 991px) {
  .col-md-7 {
    width: 83.333333% !important;
  }
}
@media (max-width: 767px) {
  .col-md-7 {
    width: 100% !important;
  }
}

.modal-error-step svg {
  height: auto;
  max-width: 20px;
}

.primary-color {
  color: var(--api-background) !important;
}

.pointer-cursor {
  cursor: pointer;
}
</style>
