<template>
  <!-- Declaration  Start -->
  <section class="login_form section declaration-sec">
    <div class="container">
      <div
        v-show="step === 1"
        class="row"
      >
        <div class="col-md-12 text-left">
          <div class="sec-heading">
            <h4 class="heading_title heading_title_left dynamicColor frm_margin_on_mobile">
              {{ $t('declaration.title') }}
            </h4>
            <p class="p-content dynamicColor frm_margin_on_mobile">{{ $t('declaration.info') }}</p>
          </div>
        </div>
      </div>
      <div
        v-show="step < 3"
        class="row"
      >
        <div class="col-md-12 property-info text-left">
          <div class="info_detail_set">
            <span class="icon-text">
              <span class="img-tag"><img src="@/assets/home-icon.svg" /></span>
              <span class="heading">{{ getPropertyType }}:</span>
              <br />
              <span class="property-name">{{ getPropertyAddressOrName }}</span>
            </span>
            <span class="icon-text icon-text02">
              <span class="heading">{{ $t('declaration.area') }}:</span>
              <br />{{ sumOfAreas }}m²
            </span>
            <span class="icon-text">
              <span class="heading">{{ $t('declaration.state') }}:</span>
              <br />
              {{ getPropertyFederalState }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-show="step === 1"
        class="row"
      >
        <div
          class="col-md-12 mt-4"
          v-show="askForMfaCode"
        >
          <label>{{ $t('declaration.mfa_code_sent') }}</label>
          <div class="w-100 mt-2">
            <input
              type="text"
              autocomplete="off"
              v-model="signatureMfaCode"
              placeholder=""
              ref="signature-mfa-input"
              class="input-field w-25"
              @keyup.enter="validateMfa"
            />
          </div>
          <button
            class="step-button-style mt-2"
            :style="[primaryColor ? { background: primaryColor } : '']"
            @click="validateMfa"
          >
            {{ $t('declaration.buttons.continue') }}
          </button>
        </div>
        <div
          class="col-md-12 mt-4"
          v-show="!askForMfaCode"
        >
          <button
            class="step-button-style"
            :style="[primaryColor ? { background: primaryColor } : '']"
            @click="startRelease"
          >
            {{ $t('declaration.buttons.start') }}
          </button>
          <button
            type="button"
            @click="goToHome"
            class="step-button-style border-button-style mx-3"
          >
            {{ $t('general.buttons.cancel') }}
          </button>
          <div class="time-set">
            <img src="@/assets/time-icon.svg" />
            <span>{{ $t('declaration.timer') }}</span>
          </div>
        </div>
      </div>
      <div
        v-show="step === 2"
        class="row"
      >
        <div
          class="col-md-12"
          v-show="!loadingDocument"
        >
          <div class="d-flex flex-column justify-content-center align-items-center mt-5">
            <div class="headign-title">{{ getDocumentApprovalHeading }}</div>
            <button
              @click="captureSignature"
              class="step-button-style mt-5 w-auto px-4"
              :style="[primaryColor ? { background: primaryColor } : '']"
            >
              {{ $t('declaration.sign_now') }}
            </button>
          </div>
          <div class="d-flex justify-content-between rectangle pdf-header mt-3">
            <div></div>
            <div class="d-flex align-items-center">{{ numPages }} {{ $t('declaration.num_pages') }}</div>
            <div class="d-flex align-items-center icon">
              <img
                src="@/assets/download.svg"
                @click="downloadDocument"
              />
            </div>
          </div>
          <div class="pdf-container">
            <div class="pdf-page">
              <vue-pdf-embed
                ref="pdfRef"
                :source="pdfSource"
                :disableTextLayer="true"
                @rendered="handlePdfDocumentRedered"
              />
            </div>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center mt-3">
            <button
              @click="captureSignature"
              class="step-button-style w-auto px-4"
              :style="[primaryColor ? { background: primaryColor } : '']"
            >
              {{ $t('declaration.sign_now') }}
            </button>
          </div>
        </div>
      </div>
      <div
        v-show="step === 3"
        class="row"
      >
        <div class="col-md-12 text-left">
          <div class="sec-heading">
            <h4 class="heading_title heading_title_left dynamicColor">
              {{ $t('declaration.finish.heading') }}
            </h4>
            <p class="p-content dynamicColor">{{ getFinishedInfoMainText }}</p>

            <p
              class="p-content dynamicColor"
              v-if="signedDocuments.length > 0"
            >
              {{ $t('declaration.finish.documents_list') }}
            </p>
            <p
              class="p-content dynamicColor cursorPointer"
              v-for="signedDocument in signedDocuments"
              :key="signedDocument.document_uid"
              @click="downloadSignedDocument(signedDocument)"
            >
              <img
                :src="getDocumentIcon(signedDocument.fileExt)"
                class="document_icon"
              />
              {{ signedDocument.filename }}
            </p>

            <p class="p-content dynamicColor mt-5">{{ $t('declaration.finish.info2') }}</p>
            <p class="p-content dynamicColor">{{ getClientAssignee }}</p>
            <p
              class="p-content dynamicColor mt-5 py-5"
              v-if="signingCode"
            >
              {{ $t('declaration.close_window') }}
            </p>
            <p v-else>
              <button
                class="step-button-style"
                :style="[primaryColor ? { background: primaryColor } : '']"
                @click="goToHome"
              >
                {{ $t('declaration.buttons.continue') }}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>

    <ModalDialogSignature
      v-show="showSignatureDialog"
      @close="closeSignatureDialog"
      class="modal_wide modal_large"
    >
      <template v-slot:body>
        <div class="popup_title b_btm">
          <h2>{{ $t('declaration.sign_now') }}</h2>
        </div>
        <div class="tab-container">
          <tabs
            :tabs="tabs"
            @click.self="activateTab"
          >
            <template #tab-pane-text-signature>
              <div class="tab-content">
                <div class="col_view">
                  <div class="col_view_input">
                    <input
                      type="text"
                      name="text"
                      v-model="signatureText"
                    />
                  </div>
                  <button
                    class="step-button-style mt-0 w-auto px-2"
                    :style="[primaryColor ? { background: primaryColor } : '']"
                    @click="generateSignature"
                  >
                    {{ $t('declaration.buttons.generate_signature') }}
                  </button>
                </div>
                <div
                  class="newsignature d-flex justify-content-around align-items-center"
                  v-show="signatureUri"
                >
                  <img
                    :src="signatureUri"
                    class="txt-signature"
                  />
                </div>
              </div>
            </template>
            <template #tab-pane-draw-signature>
              <div class="tab-content">
                <div class="d-flex justify-content-end mb-2">
                  <span
                    class="clear-link"
                    @click="clearSignaturePad"
                    >{{ $t('declaration.clear') }}</span
                  >
                </div>
                <Vue3Signature
                  ref="signature-pad"
                  :w="'800px'"
                  :h="'250px'"
                  class="signature-container"
                ></Vue3Signature>
              </div>
            </template>
            <template #tab-pane-upload-signature>
              <div class="uploader-tab">
                <InputUpload
                  ref="uploader"
                  :showDragAndDrop="true"
                  :multiple="false"
                  accept=".png,.jpg,.jpeg,.gif"
                  :buttonStyle="[primaryColor ? { background: primaryColor } : '']"
                />
              </div>
            </template>
            <template #tab-footer>
              <div class="d-flex justify-content-end">
                <button
                  type="button"
                  @click="closeSignatureDialog"
                  class="step-button-style border-button-style mx-3 mt-0"
                >
                  {{ $t('general.buttons.cancel') }}
                </button>
                <button
                  type="button"
                  @click.prevent="applySignature"
                  class="step-button-style mt-0"
                  :style="[primaryColor ? { background: primaryColor } : '']"
                >
                  {{ $t('general.buttons.save') }}
                </button>
              </div>
            </template>
          </tabs>
        </div>
      </template>
    </ModalDialogSignature>
  </section>
  <!-- Declaration  End ///////////// -->
</template>

<script type="text/javascript">
import Tabs from '@/components/tab/Tabs.vue'
import ModalDialogSignature from '@/components/ModalDialogSignature.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VuePdfEmbed from 'vue-pdf-embed'
import Vue3Signature from 'vue3-signature'
import apiClient from '@/Api'
import InputUpload from '@/components/InputUpload'
import { GlobalWorkerOptions } from 'vue-pdf-embed/dist/index.essential.mjs'
import PdfWorker from 'pdfjs-dist/build/pdf.worker.js?url'

GlobalWorkerOptions.workerSrc = PdfWorker

export default {
  name: 'DeclarationSection',
  components: {
    ModalDialogSignature,
    VuePdfEmbed,
    Vue3Signature,
    Tabs,
    InputUpload,
  },
  data() {
    return {
      declarationId: 0,
      declarationData: {
        property: {},
      },
      sumOfAreas: 0,
      primaryColor: '',
      step: 1,
      showSignatureDialog: false,
      signatureText: '',
      signatureUri: '',
      pdfSource: {
        url: '',
        isEvalSupported: false,
      },
      currentDocumentUrl: '',
      numPages: null,
      documentType: '',
      loadingDocument: false,
      selectedTab: 'text-signature',
      signatureId: 0,
      signingCode: '',
      forceCaptureSignature: false,
      signedDocuments: [],
      tabs: [
        {
          name: this.$t('declaration.tabs.text-signature'),
          slug: 'text-signature',
        },
        {
          name: this.$t('declaration.tabs.draw_signaure'),
          slug: 'draw-signature',
        },
        {
          name: this.$t('declaration.tabs.upload_signature'),
          slug: 'upload-signature',
        },
      ],
      askForMfaCode: false,
      signatureMfaCode: '',
      mfaValidated: false,
      signedDocumentCount: 0,
      signatureOption: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(255,255,255)',
      },
    }
  },
  watch: {
    step(nv) {
      if (nv === 3) {
        this.getSignedDocuments()
      }
    },
  },
  created() {
    this.signingCode = ''
    if (this.$route.query.code) {
      this.showPageLoader()
      apiClient.post('signingDeclaration/validateCode', { signingCode: this.$route.query.code }).then((response) => {
        if (response.data.success && response.data.userInfo) {
          if (typeof response.data.mfaValidated !== 'undefined') {
            this.mfaValidated = response.data.mfaValidated
          }
          this.signingCode = this.$route.query.code
          this.setInvitationInfo(response.data.userInfo)
          this.primaryColor = response.data.userInfo.primaryColor
          this.fetchDeclaration()
          this.signedDocumentCount = response.data.signedDocumentCount
          if (this.signedDocumentCount) {
            if (this.mfaValidated) {
              this.checkSignedDocument()
            } else {
              this.triggerApprovalMfa()
            }
          }
        } else {
          this.hidePageLoader()
          if (response.data.message) {
            this.showToast({
              message: response.data.message,
              toastType: 'error',
            })
          }
          this.$router.push('/')
        }
      })
    } else if (
      this.getCurrentUser.permissions &&
      Object.values(this.getCurrentUser.permissions).includes('RELEASE_DECLARATIONS') &&
      this.$route.params.uid
    ) {
      this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#43ac6f'
      this.declarationId = this.$route.params.uid
    } else {
      this.$router.push('/dashboard')
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser']),
    getPropertyType() {
      let propertyHeader = this.$t('declaration.default_property_header')
      if (
        this.declarationData.property &&
        this.declarationData.property.propertyType &&
        this.declarationData.property.federalStateUid
      ) {
        if (['2', '9'].indexOf(this.declarationData.property.federalStateUid.toString()) === -1) {
          let propertyTypeDescription = this.$t('property_type.' + this.declarationData.property.propertyType)
          if (typeof propertyTypeDescription === 'string' && propertyTypeDescription !== '') {
            propertyHeader = propertyTypeDescription
          }
        }
      }
      return propertyHeader
    },
    getPropertyAddressOrName() {
      let propertyAddressOrName
      if (
        (typeof this.declarationData.property.street === 'string' &&
          this.declarationData.property.street.trim() !== '') ||
        (typeof this.declarationData.property.houseNumber === 'string' &&
          this.declarationData.property.houseNumber.trim() !== '') ||
        (typeof this.declarationData.property.zip === 'string' && this.declarationData.property.zip.trim() !== '') ||
        (typeof this.declarationData.property.city === 'string' && this.declarationData.property.city.trim() !== '')
      ) {
        let helpString1 = ''
        if (
          typeof this.declarationData.property.street === 'string' &&
          this.declarationData.property.street.trim() !== ''
        ) {
          helpString1 = this.declarationData.property.street.trim()
        }
        if (
          typeof this.declarationData.property.houseNumber === 'string' &&
          this.declarationData.property.houseNumber.trim() !== ''
        ) {
          if (helpString1 !== '') {
            helpString1 = helpString1 + ' '
          }
          helpString1 = helpString1 + this.declarationData.property.houseNumber.trim()
        }

        let helpString2 = ''
        if (typeof this.declarationData.property.zip === 'string' && this.declarationData.property.zip.trim() !== '') {
          helpString2 = this.declarationData.property.zip.trim()
        }
        if (
          typeof this.declarationData.property.city === 'string' &&
          this.declarationData.property.city.trim() !== ''
        ) {
          if (helpString2 !== '') {
            helpString2 = helpString2 + ' '
          }
          helpString2 = helpString2 + this.declarationData.property.city.trim()
        }

        let helpString3 = helpString1 !== '' && helpString2 !== '' ? ', ' : ''

        propertyAddressOrName = helpString1 + helpString3 + helpString2
      } else {
        propertyAddressOrName = this.declarationData.property.name
      }
      return propertyAddressOrName
    },
    getPropertyFederalState() {
      return this.declarationData.property && this.declarationData.property.federalStateName
        ? this.declarationData.property.federalStateName
        : ''
    },
    getDocumentApprovalHeading() {
      if (this.step === 2) {
        if (this.documentType === 'controlPrint') {
          return this.$t('declaration.control_print')
        } else if (this.documentType === 'completedDeclaration') {
          return this.$t('declaration.completed_declaration')
        }
      }
      return ''
    },
    getClientAssignee() {
      if (this.declarationData.client && this.declarationData.client.assigneeName) {
        return this.declarationData.client.assigneeName
      } else {
        let tc_info = this.getInvitationInfo()
        if (tc_info.taxConsultant) {
          return tc_info.taxConsultant.companyName
            ? tc_info.taxConsultant.companyName
            : tc_info.taxConsultant.firstName + ' ' + tc_info.taxConsultant.lastName
        }
      }
      return ''
    },
    getFinishedInfoMainText() {
      if (
        this.declarationData.powerOfAttorneyToReceive &&
        this.declarationData.powerOfAttorneyToReceive.receiverType === 'none'
      ) {
        return this.$t('declaration.finish.info3')
      } else {
        return this.$t('declaration.finish.info1')
      }
    },
  },
  mounted() {
    if (this.declarationId) {
      this.fetchDeclaration()
    }
  },
  methods: {
    ...mapActions(['showToast']),
    ...mapMutations(['showPageLoader', 'hidePageLoader', 'setInvitationInfo']),
    ...mapGetters(['getInvitationInfo']),
    checkSignedDocument() {
      if (this.signedDocumentCount > 1) {
        this.step = 3
      } else if (this.signedDocumentCount > 0) {
        this.step = 2
        this.fetchDocument('completedDeclaration')
        this.forceCaptureSignature = true
      }
    },
    fetchDeclaration() {
      this.showPageLoader()
      let payload = { id: this.declarationId, fetchSignedDocumentCount: 1 }
      let endpoint = 'declaration/get'
      if (this.signingCode) {
        payload = { signingCode: this.signingCode }
        endpoint = 'signingDeclaration/get'
      }
      apiClient.post(endpoint, payload).then((response) => {
        if (response.data.success && response.data.declarationData) {
          this.declarationData = response.data.declarationData
          if (response.data.declarationData.signedDocumentCount > 1) {
            this.step = 3
          } else if (response.data.declarationData.signedDocumentCount > 0) {
            this.step = 2
            this.fetchDocument('completedDeclaration')
            this.forceCaptureSignature = true
          }

          if (response.data.propertyData) {
            this.hidePageLoader()
            this.sumOfAreas = 0
            let parcels = response.data.propertyData.step3.parcels
            for (let x in parcels) {
              if (
                parseFloat(parcels[x].areaOfTheLand) > 0 &&
                parseFloat(parcels[x].shareOfOwnershipCounter) > 0 &&
                parseFloat(parcels[x].shareOfOwnershipDenominator) > 0
              ) {
                let rowArea =
                  parseFloat(parcels[x].areaOfTheLand) *
                  parseFloat(parcels[x].shareOfOwnershipCounter / parcels[x].shareOfOwnershipDenominator)
                this.sumOfAreas +=
                  response.data.propertyData.step2.federalStateUid === '7' ? parseInt(rowArea) : parseFloat(rowArea)
              }
            }

            this.sumOfAreas = Math.floor(Number.parseFloat(this.sumOfAreas))
          } else {
            apiClient.post('property/detail', { id: this.declarationData.property.id }).then((resp) => {
              this.hidePageLoader()
              if (resp.data.success && resp.data.propertyData) {
                this.sumOfAreas = 0
                let parcels = resp.data.propertyData.step3.parcels
                for (let x in parcels) {
                  if (
                    parseFloat(parcels[x].areaOfTheLand) > 0 &&
                    parseFloat(parcels[x].shareOfOwnershipCounter) > 0 &&
                    parseFloat(parcels[x].shareOfOwnershipDenominator) > 0
                  ) {
                    let rowArea =
                      parseFloat(parcels[x].areaOfTheLand) *
                      parseFloat(parcels[x].shareOfOwnershipCounter / parcels[x].shareOfOwnershipDenominator)
                    this.sumOfAreas +=
                      resp.data.propertyData.step2.federalStateUid === '7' ? parseInt(rowArea) : parseFloat(rowArea)
                  }
                }

                this.sumOfAreas = Math.floor(Number.parseFloat(this.sumOfAreas))
              } else {
                this.declarationData = {}
                this.showToast({
                  message: response.data.message,
                  toastType: 'error',
                })
              }
            })
          }
        } else if (response.data.message) {
          this.hidePageLoader()
          this.showToast({
            message: response.data.message,
            toastType: 'error',
          })
        }
      })
    },
    goToHome() {
      this.$router.push('/dashboard')
    },
    validateMfa() {
      if (this.signingCode && !this.mfaValidated && this.signatureMfaCode) {
        this.showPageLoader()
        apiClient
          .post('signingDeclaration/validateMfaCode', {
            signingCode: this.signingCode,
            mfaCode: this.signatureMfaCode,
          })
          .then((response) => {
            if (response.data.success) {
              this.mfaValidated = true
              if (this.signedDocumentCount) {
                this.hidePageLoader()
                this.checkSignedDocument()
              } else {
                this.startRelease()
              }
            } else {
              this.hidePageLoader()
              if (response.data.message) {
                this.showToast({
                  message: response.data.message,
                  toastType: 'error',
                })
              }
            }
          })
      }
    },
    triggerApprovalMfa() {
      this.showPageLoader()
      apiClient.post('signingDeclaration/sendMfaCode', { signingCode: this.signingCode }).then((response) => {
        this.hidePageLoader()
        if (response.data.success) {
          this.askForMfaCode = true
          this.$nextTick(() => this.$refs['signature-mfa-input'].focus())
        } else {
          if (response.data.message) {
            this.showToast({
              message: response.data.message,
              toastType: 'error',
            })
          }
        }
      })
    },
    startRelease() {
      if (this.signingCode && !this.mfaValidated) {
        this.triggerApprovalMfa()
        return
      }

      this.step = 2
      this.fetchDocument('controlPrint')
    },
    downloadDocument() {
      if (this.isMobile()) {
        this.downloadDocumentMobile()
      } else {
        const downloadLink = document.createElement('a')
        downloadLink.href = this.currentDocumentUrl
        downloadLink.download = this.$t('declaration.control_print') + '.pdf'
        downloadLink.click()
      }
    },
    downloadDocumentMobile() {
      let byteCharacters = atob(this.currentDocumentUrl.replace('data:application/pdf;base64,', ''))
      let byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      let byteArray = new Uint8Array(byteNumbers)
      let file = new Blob([byteArray], { type: 'application/pdf;base64' })
      this.pdfUrl = (window.URL || window.webkitURL).createObjectURL(file)
      window.open(this.pdfUrl)
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    downloadSignedDocument(signedDocument) {
      if (signedDocument.document_uid) {
        this.showPageLoader()
        let payload = {
          id: this.declarationId,
          documentId: signedDocument.document_uid,
        }
        let endpoint = 'declaration/getSignedDocument'
        if (this.signingCode) {
          payload = {
            signingCode: this.signingCode,
            documentId: signedDocument.document_uid,
          }
          endpoint = 'signingDeclaration/getSignedDocument'
        }
        apiClient
          .post(endpoint, payload)
          .then((response) => {
            this.hidePageLoader()
            if (response.data.success && response.data.token) {
              location.href = 'download?token=' + response.data.token
            } else {
              this.showToast({
                message: response.data.message,
                toastType: 'error',
              })
            }
          })
          .catch(() => {
            this.hidePageLoader()
          })
      } else if (signedDocument.documentContent) {
        if (this.isMobile()) {
          let byteCharacters = atob(signedDocument.documentContent)
          let byteNumbers = new Array(byteCharacters.length)
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }
          let byteArray = new Uint8Array(byteNumbers)
          let file = new Blob([byteArray], { type: 'application/pdf;base64' })
          this.pdfUrl = (window.URL || window.webkitURL).createObjectURL(file)
          window.open(this.pdfUrl)
        } else {
          const downloadLink = document.createElement('a')
          downloadLink.href = 'data:application/pdf;base64,' + signedDocument.documentContent
          downloadLink.download = signedDocument.filename
          downloadLink.click()
        }
      }
    },
    getSignedDocuments() {
      this.signedDocuments = []
      if (this.signingCode) {
        apiClient
          .post('signingDeclaration/getSignedDocuments', {
            signingCode: this.signingCode,
          })
          .then((response) => {
            if (response.data.success && response.data.signedDocuments) {
              for (let x in response.data.signedDocuments) {
                this.signedDocuments.push(response.data.signedDocuments[x])
              }
            }
          })
      }
    },
    getDocumentIcon(file_ext) {
      switch (file_ext) {
        case 'xls,xlsx':
          return require('@/assets/newproperty/xlx.svg')
        case 'doc,docx':
          return require('@/assets/newproperty/doc.svg')
        case 'pdf':
          return require('@/assets/newproperty/pdf.svg')
        default:
          return require('@/assets/newproperty/document_generic.svg')
      }
    },
    fetchDocument(documentType) {
      this.loadingDocument = true
      this.showPageLoader()
      let payload = {
        id: this.declarationId,
        documentType: documentType,
      }
      let endpoint = 'declaration/getDocument'
      if (this.signingCode) {
        payload = {
          signingCode: this.signingCode,
          documentType: documentType,
        }
        endpoint = 'signingDeclaration/getDocument'
      }
      apiClient.post(endpoint, payload).then((response) => {
        this.hidePageLoader()
        if (response.data.success && response.data.skip) {
          this.step = 3
          return false
        }

        this.loadingDocument = false
        if (response.data.success && response.data.documentContent) {
          this.currentDocumentUrl = 'data:application/pdf;base64,' + response.data.documentContent
          this.documentType = documentType
          this.signatureId = response.data.signatureId ? parseInt(response.data.signatureId) : 0

          this.pdfSource.url = this.currentDocumentUrl
        } else if (documentType === 'controlPrint') {
          this.step = 1
          this.showToast({
            message: this.$t('declaration.elster_pdf_failed'),
            toastType: 'error',
          })
        } else if (response.data.message) {
          this.showToast({
            message: response.data.message,
            toastType: 'error',
          })
        }
      })
    },
    captureSignature() {
      if (this.step === 2) {
        if (this.documentType === 'controlPrint' || this.forceCaptureSignature) {
          if (typeof this.$refs['signature-pad'] !== 'undefined') {
            this.$refs['signature-pad'].clear()
          }
          this.$refs.uploader.clear()
          this.showSignatureDialog = true
          this.forceCaptureSignature = false
        } else if (this.documentType === 'completedDeclaration') {
          this.applySignature()
        }
      }
    },
    closeSignatureDialog() {
      this.showSignatureDialog = false
    },
    clearSignaturePad() {
      if (typeof this.$refs['signature-pad'] !== 'undefined') {
        this.$refs['signature-pad'].clear()
      }
    },
    applySignature() {
      let send_request = false
      let payload = new FormData()
      payload.append('id', this.declarationId)
      payload.append('documentType', this.documentType)
      payload.append('signatureId', this.signatureId)
      if (this.selectedTab === 'draw-signature') {
        let png = ''
        if (typeof this.$refs['signature-pad'] !== 'undefined') {
          png = this.$refs['signature-pad'].save()
        }
        if (png !== '') {
          payload.append('signature', png)
          send_request = true
        }
      } else if (this.selectedTab === 'upload-signature') {
        let files = this.$refs.uploader.getFiles()
        if (files.length > 0) {
          payload.append('file', files[0])
          send_request = true
        }
      } else if (this.selectedTab === 'text-signature' && this.signatureUri !== '') {
        payload.append('signature', this.signatureUri)
        send_request = true
      }

      if (send_request) {
        this.showPageLoader()
        let endpoint = 'declaration/signDocument'
        if (this.signingCode) {
          endpoint = 'signingDeclaration/signDocument'
          payload.append('signingCode', this.signingCode)
        }
        apiClient
          .post(endpoint, payload, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          })
          .then((response) => {
            this.hidePageLoader()
            if (response.data.success) {
              this.showSignatureDialog = false
              if (this.step === 2 && this.documentType === 'controlPrint') {
                this.fetchDocument('completedDeclaration')
              } else {
                this.step = 3
              }
            } else if (response.data.message) {
              this.showToast({
                message: response.data.message,
                toastType: 'error',
              })
            }
          })
          .catch(() => {
            this.hidePageLoader()
            this.showToast({
              message: this.$t('general.errors.unknownError'),
              toastType: 'error',
            })
          })
      }
    },
    generateSignature() {
      if (this.signatureText.trim() !== '') {
        this.showPageLoader()
        let payload = {
          text: this.signatureText,
        }
        if (this.signingCode) {
          payload.signingCode = this.signingCode
        }
        apiClient
          .post('generateSignature', payload)
          .then((res) => {
            this.hidePageLoader()
            if (res.data.success) {
              this.signatureUri = 'data:image/png;base64,' + res.data.signature
            } else if (res.data.message) {
              this.showToast({
                message: res.data.message,
                toastType: 'error',
              })
            }
          })
          .catch(() => {
            this.hidePageLoader()
            this.showToast({
              message: this.$t('general.errors.unknownError'),
              toastType: 'error',
            })
          })
      }
    },
    activateTab(activeTab) {
      this.selectedTab = activeTab
    },
    handlePdfDocumentRedered() {
      this.numPages = this.$refs.pdfRef.pageCount
    },
  },
}
</script>
<style scoped lang="css">
@import './assets/custom.css';
@import './assets/form_style.css';

:root {
  --api-background: #43ac6f;
}

.isDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.topspace60 {
  margin-top: 20px;
}

h4.heading_title {
  padding-bottom: 20px;
}

a.button-style-new,
button.button-style-new {
  background: #229d56;
  border: 0;
  border-radius: 0;
  color: #fff;
  display: block;
  height: 40px;
  line-height: 40px;
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.modal-backdrop .modal {
  border-top: 5px solid #229d56;
}

.__Modal_Popup .modal {
  height: auto !important;
}

.popup_title h2 {
  color: #333;
  font-size: 26px;
}

.tab-border-home p {
  font-size: 16px;
}

.tab-border-home {
  border: none;
}

.select2_input a button:hover {
  background: #229d56;
  color: #fff;
  font-weight: 700;
}

.select2_input a button,
.select2_input button {
  background: #fff;
  border: 1px solid #ccc;
  font-size: 15px;
  margin: 5px 5px 5px;
  padding: 10px 15px;
}

.select2_input {
  text-align: left;
}

.select2_input + .button-style01:hover {
  background: #ccc;
  border-color: #ccc;
}

.select2_input + .button-style01 {
  background: none;
  border-color: #333;
  color: #333;
  line-height: 20px;
  margin-left: auto;
  margin-top: 30px;
  padding: 10px 0;
  width: 280px;
}

.tab-border-home.tab-top-height {
  height: auto;
}

.tab-border-home.tab-top-height.textformatting {
  padding: 0 0 0 0 !important;
}

.btn_170 {
  display: flex;
  justify-content: flex-end;
  margin-left: auto !important;
  margin-right: -15px;
}

button.button-style:first-child {
  border: none !important;
}

button.button-style.red_btn:hover {
  background: #d55959 !important;
  border-color: #d55959;
  color: #fff;
  filter: brightness(0.8);
}

button.button-style.btn_line:hover {
  background: #bbbbbb;
  border-color: #bbb;
}

.Search_Titzle.Search_Titzle02.Titzle02_mob {
  padding-left: 0;
}

section.section {
  margin-bottom: 100px;
  min-height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Search_Titzle input[type='text'] {
    margin-left: 20px !important;
  }

  .footer-space {
    min-height: 100px !important;
  }

  .icon-div {
    width: 30% !important;
  }

  .new-notifications .col-lg-6 {
    width: 100%;
  }
}

@media only screen and (min-width: 100px) and (max-width: 768px) {
  .icon-div {
    width: 20%;
  }

  .icon-cnt-div {
    width: 80%;
  }

  .footer-space {
    min-height: 100px !important;
  }

  .Search_Titzle input[type='text'] {
    margin-left: 5px !important;
  }

  .mb_300 {
    max-width: 100% !important;
  }

  .mb_300 img {
    margin-right: 20px !important;
    max-width: 22px !important;
  }
}

.spiner_center_over.dashbord-spiner {
  background: #ffffff;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 11;
}

.dashbord-spiner .spiner_center {
  height: auto;
  left: 50%;
  margin: 0 auto;
  position: absolute !important;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.new-notifications {
  position: relative;
}

.dashbord-spiner:before {
  display: none;
}

.emptyDashboardImage {
  max-height: 250px;
  max-width: 250px;
}

.modal-content {
  align-content: center;
  align-items: center;
  background: #f2f2f2;
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 14px;
  padding: 5px;
}

.modal-button-row {
  align-items: stretch;
  border: none;
  display: flex;
  font-size: 14px;
  padding: 5px;
}

.modal-button-left {
  align-self: flex-start;
  margin-left: 0 !important;
}

.modal-button-right {
  margin-left: auto;
}

.modal-content-div {
  padding: 5px;
}

.modal-content-headline {
  font-weight: 600;
}

.modal-icon {
  padding: 10px;
}

.modal-img {
  height: 18px;
  width: 18px;
}

.modal-button {
  background: none;
  border-color: #333;
  color: #333;
  line-height: 20px;
  margin-left: auto;
  margin-top: 30px;
  padding: 10px 0;
  width: 280px;
}

.modal-button:hover {
  background: #ccc;
  border-color: #ccc;
  color: #333;
}

button.btnactive {
  border: 1px solid var(--api-background);
}

.btn_cancel button.border-button-style {
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 170px;
  width: 100%;
}

.check-icon-text {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  font-weight: 400;
  padding-top: 5px;
}

.info_detail_set {
  display: flex;
  width: 100%;
  grid-gap: 50px;
  background-color: #f2f2f2;
  padding: 10px;
}

.property-info {
  align-items: center;
  display: flex;
  margin-top: 15px;
  text-align: left;
  width: 100%;
}

.property-info img {
  max-width: 20px;
  width: 100%;
}

.property-info .heading {
  font-weight: 600;
}

.property-info .info_detail_set .icon-text:last-child {
  border-right: 0;
}

.property-info .property-name {
  white-space: nowrap;
}

.property-info .info_detail_set .icon-text:first-child {
  width: 400px;
}

.button {
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  margin-top: 10px;
  padding: 5px 15px;
  width: auto;
}

.btn-cancel {
  background: #fff;
  border: 1px solid #000;
  color: #000;
  padding-bottom: 4px;
  padding-top: 4px;
}

.time-set {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 15px 0 0;
}

.time-set img {
  width: 20px;
}

.time-set span {
  color: #4b4b4b;
  font-size: 15px;
  padding-left: 10px;
}

.rectangle {
  background-color: #797979;
  color: #fff;
  font-weight: 600;
  height: 56px;
  padding: 2px 2px 2px 2px;
  width: 100%;
}

.icon img {
  cursor: pointer;
  height: 24px;
  margin-left: 20px;
  width: 24px;
}

.icon {
  padding-right: 30px;
}

.pdf-header {
  position: sticky;
  top: 45px;
  z-index: 100;
}

.pdf-container {
  background: #f4f4f4;
  border: 2px solid #c2cad8;
  padding: 10px 20px;
  width: 100%;
}

.pdf-container .pdf-page {
  margin-top: 10px;
  position: relative;
}

:deep(.modal_large .modal) {
  height: auto;
  max-width: 50% !important;
}

@media only screen and (min-width: 100px) and (max-width: 767px) {
  :deep(.modal_large .modal) {
    height: auto;
    max-width: 100% !important;
  }

  .col_view {
    align-items: end !important;
    display: flex !important;
    flex-direction: column;
    justify-content: end;
    /*justify-content-between align-items-center*/
  }

  .col_view_input {
    flex: 1 1 auto !important;
    margin-left: 1rem !important;
    margin-right: 0 !important;
  }

  .button {
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    margin-top: 10px !important;
    padding: 5px 15px;
    width: 55%;
  }
}

.tab-content {
  background-color: #ffffff;
  border: 2px solid #d0d0d0;
  border-radius: 5px;
  box-sizing: border-box;
  display: block;
  height: 300px;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 10px;
}

.uploader-tab {
  height: 300px;
}

.tab-container {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}

.Modal_content_Area input {
  height: auto !important;
  padding: 5px 2px;
}

.newsignature {
  height: 85%;
}

.signature-container {
  margin-left: -10px;
}

span.clear-link {
  color: #4b4b4b !important;
  cursor: pointer;
  float: right;
  text-decoration: underline;
}

.txt-signature {
  max-height: 100%;
}

.col_view {
  align-items: center;
  display: flex;
  justify-content: space-between;
  /*justify-content-between align-items-center*/
}

.col_view_input {
  margin-left: 1rem;
  margin-right: 1rem;
  width: 68%;
}
.col_view_input input {
  height: 40px !important;
}
.w-auto {
  max-width: unset;
}
.document_icon {
  margin-right: 10px;
  width: 20px;
}
:deep(.modal_large .modal) {
  height: auto;
  max-width: 50% !important;
}
</style>
