import { useToast } from 'vue-toastification'
import { JSEncrypt } from 'jsencrypt'
import apiClient from '@/Api'

const toast = useToast()

const state = {
  pageLoading: false,
  csrfFailed: false,
  maintenanceStatus: false,
  isDialogOpen: false,
}

const getters = {
  isPageLoading: (state) => state.pageLoading,
  isCsrfFailed: (state) => state.csrfFailed,
  getMaintenanceStatus: (state) => state.maintenanceStatus,
  isDialogOpen: (state) => state.isDialogOpen,
}

const mutations = {
  showPageLoader: (state) => {
    state.pageLoading = true
  },
  hidePageLoader: (state) => {
    state.pageLoading = false
  },
  setCsrfFailed: (state) => {
    state.csrfFailed = true
  },
  setMaintenanceStatus: (state, val) => {
    state.maintenanceStatus = val
  },
  setDialog(state, val) {
    state.isDialogOpen = val
  },
}

const actions = {
  // eslint-disable-next-line no-unused-vars
  showToast({ commit }, { message, toastType }) {
    if (message) {
      if (toastType && toastType === 'error') {
        toast.error(message)
      } else {
        toast.success(message)
      }
    }
  },
  // eslint-disable-next-line no-unused-vars
  encrypt({ commit }, text) {
    if (process.env.VUE_APP_ENABLE_UI_ENC === 'true') {
      let ui_enc_token = ''
      let cookie_val = `; ${document.cookie}`
      let cookie_parts = cookie_val.split('; client_ui_key=')
      if (cookie_parts.length === 2) {
        ui_enc_token = decodeURIComponent(cookie_parts.pop().split(';').shift())
      }

      if (ui_enc_token) {
        const encryptor = new JSEncrypt({})

        encryptor.setPublicKey(atob(ui_enc_token))

        return encryptor.encrypt(text)
      }
    }
    return text
  },
  observer({ commit, dispatch, getters }) {
    apiClient
      .post('observer', {})
      .then((response) => {
        if (response.data.success) {
          if (response.data.systemUpdate) {
            commit('setMaintenanceStatus', true)
          } else if (getters.getMaintenanceStatus) {
            location.reload()
          }
        }

        setTimeout(() => dispatch('observer'), 30000)
      })
      .catch()
  },
  setDialog({ commit }, val) {
    commit('setDialog', val)
  },
  // eslint-disable-next-line no-unused-vars
  cityLookup({ commit }, zip) {
    return apiClient
      .post('getCity', {
        zip: zip,
      })
      .then((response) => {
        if (response.data.success) {
          return response.data.cities
        } else {
          return []
        }
      })
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
