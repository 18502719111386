<template>
  <!-- User administration  Start -->
  <section class="login_form section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-left">
          <div class="sec-heading">
            <h4 class="heading_title title_v_middle">{{ $t('admin.title') }}</h4>
          </div>
        </div>
      </div>

      <div class="dashbord-content topspace60">
        <div class="row">
          <div class="col-md-12">
            <div class="column-right ColumnClass">
              <div class="search_row">
                <div class="row">
                  <div class="col-md-8">
                    <div class="search_space">
                      <div class="action_row">
                        <a
                          @click="addingModeEditPopup"
                          :class="{ isDisabled: !isEnabledAddButton }"
                        >
                          <Svgall
                            class="list_edit_delete"
                            :name="'user_add'"
                            :color="IconColor"
                          />
                          <span>{{ $t('admin.menu1') }}</span>
                        </a>
                        <a
                          @click="editPopup"
                          :class="{ isDisabled: !isEnabledEditButton }"
                        >
                          <Svgall
                            class="list_edit_delete"
                            :name="'user_edit'"
                            :color="IconColor"
                          />
                          <span>{{ $t('admin.menu2') }}</span>
                        </a>
                        <span
                          @click="userDelete"
                          class="delete_point"
                          :class="{ isDisabled: !isEnabledDeleteButton }"
                        >
                          <Svgall
                            class="list_edit_delete"
                            :name="'delete'"
                            :color="IconColor"
                          />
                          <span>{{ $t('admin.menu3') }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 nopadding">
                    <div class="Search_Titzle Search_Titzle02 srch_bar">
                      <!-- <i class="bi bi-search"></i> -->
                      <input
                        type="text"
                        autocomplete="off"
                        name=""
                        v-model.trim="search"
                        @keyup="filterAllUsers"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="new-notifications">
                <!-- Box Row 01 -->
                <div class="row">
                  <div class="col-lg-12 col-sm-12 table_scroll">
                    <table class="style_min table">
                      <thead>
                        <tr>
                          <th scope="col">
                            <span class="checkbox_input">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="allSelected"
                                @change="selectAll"
                              />
                              <span class="checkbox_title">{{ $t('admin.col1') }}</span>
                            </span>
                          </th>
                          <th scope="">{{ $t('admin.col2') }}</th>
                          <th scope="col">{{ $t('admin.col3') }}</th>
                          <th scope="col">{{ $t('admin.col4') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(user, index) in users"
                          :key="index"
                        >
                          <td>
                            <span class="checkbox_input">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="selected"
                                :value="index"
                                @change="removeAllSelect"
                              />
                              <span
                                @click="editUser(index)"
                                class="checkbox_title point_cursor"
                              >
                                {{ user.username }}
                                <span
                                  v-if="user.is_default === '1'"
                                  class="nf_starcolor"
                                >
                                  *</span
                                >
                              </span>
                            </span>
                          </td>
                          <td class="menu_td">
                            <span
                              class="align-collon"
                              :id="'mygroup' + user.prim_uid"
                            >
                              <a
                                data-bs-toggle="dropdown"
                                :data-target="'#u_' + user.prim_uid"
                                :data-parent="'#mygroup' + user.prim_uid"
                                :href="'#u_' + user.prim_uid"
                                role="button"
                                aria-expanded="false"
                                :aria-controls="'#u_' + user.prim_uid"
                              >
                                <i class="bi bi-three-dots-vertical"></i>
                              </a>

                              <div
                                class="dropdown-menu pop_key point_cursor"
                                :id="'u_' + user.prim_uid"
                                @click="sendResetPwdMail(addLength(user.uid))"
                              >
                                <!-- <img class="max_18" src="@/assets/key.svg"> -->
                                <span
                                  ><Svgall
                                    class="edit_delete"
                                    name="key"
                                    :color="IconColor"
                                /></span>
                                <span>&nbsp;{{ $t('admin.menu_set_pwd') }}</span>
                              </div>
                            </span>
                            <span
                              class="point_cursor"
                              @click="editUser(index)"
                              >{{ user.first_name }} {{ user.last_name }}
                            </span>
                          </td>
                          <td>{{ user.email }}</td>
                          <td>{{ user.mobile }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- Box Row 01 Ends ///////// -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!----- edit user --------->
      <ModalDialog
        v-if="isEdituserVisible"
        @close="handleModalClose"
        class="modal_wide modal_wide_new"
      >
        <template
          v-slot:body
          class="modal-inside"
        >
          <div class="popup_title b_btm">
            <h2 v-if="addingMode">{{ $t('admin.modal_title_add') }}</h2>
            <h2 v-if="!addingMode">{{ $t('admin.modal_title_edit') }}</h2>
          </div>

          <div class="tab-border-home">
            <form class="form_full">
              <div class="row custom-row">
                <div
                  class="col-md-6"
                  v-if="!addingMode"
                >
                  <span>
                    <label class="label-title">{{ $t('admin.username') }}</label>
                    <input
                      type="text"
                      autocomplete="off"
                      class="form-control input-field"
                      v-model="form.username"
                      disabled
                      id="Benutzername"
                      placeholder=""
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('username')"
                    >
                      {{ form.errors().get('username') }}
                    </span>
                  </span>
                </div>
                <div class="col-md-6">
                  <label class="label-title">{{ $t('admin.salutation') }}<span class="nf_starcolor">*</span></label>
                  <select
                    ref="salutation"
                    v-model="form.salutation"
                    class="form-control input-field"
                  >
                    <option
                      v-for="option in salutationList"
                      v-bind:key="option.code"
                      :value="option.code"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                  <span
                    class="error"
                    v-if="form.errors().has('salutation')"
                  >
                    {{ form.errors().get('salutation') }}
                  </span>
                </div>
              </div>
              <div class="row custom-row">
                <div class="col-md-6">
                  <label class="label-title">{{ $t('admin.first_name') }}<span class="nf_starcolor">*</span></label>
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control input-field"
                    v-model="form.first_name"
                    id="first_name"
                    ref="first_name"
                    placeholder=""
                    @keyup.esc="handleModalClose"
                    @keyup.enter="submituser"
                  />
                  <input
                    type="hidden"
                    v-model="form.mfa_mode"
                  />
                  <input
                    type="hidden"
                    v-model="form.client_uid"
                  />
                  <input
                    type="hidden"
                    v-model="form.prim_uid"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('first_name')"
                  >
                    {{ form.errors().get('first_name') }}
                  </span>
                </div>
                <div class="col-md-6">
                  <span>
                    <label class="label-title">{{ $t('admin.last_name') }}<span class="nf_starcolor">*</span></label>
                    <input
                      type="text"
                      autocomplete="off"
                      class="form-control input-field"
                      v-model="form.last_name"
                      id="last_name"
                      placeholder=""
                      @keyup.esc="handleModalClose"
                      @keyup.enter="submituser"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('last_name')"
                    >
                      {{ form.errors().get('last_name') }}
                    </span>
                  </span>
                </div>
                <div class="col-md-6">
                  <label class="label-title">{{ $t('admin.email') }}<span class="nf_starcolor">*</span></label>
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control input-field"
                    v-model="form.email"
                    id="email"
                    placeholder=""
                    @keyup.esc="handleModalClose"
                    @keyup.enter="submituser"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('email')"
                  >
                    {{ form.errors().get('email') }}
                  </span>
                </div>
                <div class="col-md-6">
                  <span>
                    <label class="label-title"
                      >{{ $t('admin.mobile')
                      }}<span
                        v-if="isMobileRequired"
                        class="nf_starcolor"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      autocomplete="off"
                      class="form-control input-field"
                      v-model="form.mobile"
                      id="handynummer"
                      placeholder=""
                      @keyup.esc="handleModalClose"
                      @keyup.enter="submituser"
                    />
                    <span
                      class="error"
                      v-if="isMobileError"
                    >
                      {{ $t('admin.messages.mobile_dynamic_mobile_required') }}
                    </span>
                  </span>
                </div>
                <div class="col-md-6">&nbsp;</div>
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheck2fa"
                  :checked="form.mfa_mode === 'SMS'"
                  @change="set2faSMSMode($event)"
                />
                <label
                  class="form-check-label"
                  for="flexCheck2fa"
                >
                  {{ $t('admin.2faSMS') }}
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="ansehen"
                  id="flexCheckDefault"
                  :checked="form.permissions && form.permissions.includes('VIEW_PROPERTY')"
                  @change="selectPermission($event, 'VIEW_PROPERTY')"
                  :disabled="isViewPropertyDisabled"
                />
                <label
                  class="form-check-label"
                  for="flexCheckDefault"
                >
                  {{ $t('admin.ansehen') }}
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="anlegen"
                  id="flexCheckChecked"
                  :checked="form.permissions && form.permissions.includes('ADD_PROPERTY')"
                  @change="selectPermission($event, 'ADD_PROPERTY')"
                />
                <label
                  class="form-check-label"
                  for="flexCheckChecked"
                >
                  {{ $t('admin.anlegen') }}
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="bearbeiten"
                  id="flexCheckChecked1"
                  :checked="form.permissions && form.permissions.includes('EDIT_PROPERTY')"
                  @change="selectPermission($event, 'EDIT_PROPERTY')"
                  :disabled="isEditPropertyDisabled"
                />
                <label
                  class="form-check-label"
                  for="flexCheckChecked1"
                >
                  {{ $t('admin.bearbeiten') }}
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="freigeben"
                  id="flexCheckChecked2"
                  :checked="form.permissions && form.permissions.includes('RELEASE_DECLARATIONS')"
                  @change="selectPermission($event, 'RELEASE_DECLARATIONS')"
                />
                <label
                  class="form-check-label"
                  for="flexCheckChecked2"
                >
                  {{ $t('admin.freigeben') }}
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="freigeben"
                  id="flexCheckChecked3"
                  :checked="form.permissions && form.permissions.includes('MANAGE_USERS')"
                  @change="selectPermission($event, 'MANAGE_USERS')"
                  :disabled="isManageUsersDisabled"
                />
                <label
                  class="form-check-label"
                  for="flexCheckChecked3"
                >
                  {{ $t('admin.Benutzerverwaltung') }}
                </label>
              </div>

              <div class="return-further max_400">
                <button
                  type="submit"
                  class="button-style-border"
                  @click.stop.prevent="handleModalClose"
                >
                  {{ $t('admin.abort') }}
                </button>
                <button
                  type="submit"
                  :style="[primaryColor ? { background: primaryColor } : '']"
                  class="button-style-new"
                  @click.prevent="submituser"
                >
                  {{ $t('admin.save') }}
                </button>
              </div>
            </form>
          </div>
        </template>
      </ModalDialog>
      <!----- end edit user ------------>
    </div>
  </section>

  <DeleteModalDialog
    v-show="isDeleteVisible"
    @close="handleModalClose"
    class="modal_wide"
  >
    <template
      v-slot:body
      class="modal-inside"
    >
      <div>
        <div class="popup_title b_btm">
          <h2>{{ $t('deleteModal.h2') }}</h2>
        </div>
        <div class="tab-border-home tab-top-height textformatting">
          <div class="min_cont">
            <p>{{ $t('deleteModal.sel') }}</p>
          </div>
          <div class="benutzerverwaltung btn_170">
            <button
              class="button-style btn_line"
              @click="handleModalClose"
            >
              {{ $t('mydata.abort') }}
            </button>
            <button
              class="button-style red_btn"
              @click="deleteUserConfirm"
            >
              {{ $t('mydata.delete') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </DeleteModalDialog>
  <!-- User administration  End ///////////// -->
</template>

<script type="text/javascript">
import i18n from '@/i18n'
const { t } = i18n.global
import ModalDialog from '@/components/ModalDialog.vue'
import DeleteModalDialog from '@/components/DeleteModalDialog.vue'
import form from 'vuejs-form'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Header',
  components: {
    ModalDialog,
    DeleteModalDialog,
  },
  data() {
    return {
      isEdituserVisible: false,
      addingMode: true,
      search: '',
      users: [],
      selected: [],
      allSelected: false,
      editDisabled: false,
      editID: '',
      username: '',
      deletedUser: [],
      IconColor: '',
      primaryColor: '',
      deleteUsers: {
        _product_type: '',
        client_uid: '',
        records: [],
      },
      isDeleteVisible: false,
      isViewPropertyDisabled: false,
      isEditPropertyDisabled: false,
      isManageUsersDisabled: false,
      isMobileError: false,
      form: form({
        username: '',
        salutation: '',
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        mfa_mode: 'EMAIL',
        client_uid: '',
        prim_uid: '',
        permissions: ['VIEW_PROPERTY'],
      })
        .rules({
          salutation: 'required',
          first_name: 'required',
          last_name: 'required',
          email: ['required', 'email'],
        })
        .messages({
          'salutation.required': t('admin.messages.salutation_required'),
          'first_name.required': t('admin.messages.first_name_required'),
          'last_name.required': t('admin.messages.last_name_required'),
          'email.required': t('admin.messages.email_required'),
          'email.email': t('admin.messages.email_email'),
        }),
    }
  },
  created() {
    if (!this.getCurrentUser.permissions || !Object.values(this.getCurrentUser.permissions).includes('MANAGE_USERS')) {
      this.$router.push('/dashboard')
    } else {
      this.getUsers(this.getCurrentUser.clientUid)
      this.form.client_uid = this.getCurrentUser.clientUid
      this.IconColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#464747'
      this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#43ac6f'
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getAllUsers']),

    salutationList() {
      let list = []
      let resultArray = []
      list = this.$tm('admin.salutation_with_none')
      for (let k of list) {
        resultArray.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }
      return resultArray
    },

    isEnabledDeleteButton() {
      let isEnabled = false
      if (
        this.getCurrentUser.permissions &&
        Object.values(this.getCurrentUser.permissions).includes('MANAGE_USERS') &&
        Array.isArray(this.selected) &&
        this.selected.length > 0
      ) {
        isEnabled = true
        this.selected.forEach((userId) => {
          if (
            typeof this.users[userId].is_default !== 'undefined' &&
            this.users[userId].is_default.toString() === '1'
          ) {
            isEnabled = false
          }
        }, this)
      }
      return isEnabled
    },

    isEnabledEditButton() {
      return (
        this.getCurrentUser.permissions &&
        Object.values(this.getCurrentUser.permissions).includes('MANAGE_USERS') &&
        Array.isArray(this.selected) &&
        this.selected.length === 1
      )
    },

    isEnabledAddButton() {
      return this.getCurrentUser.permissions && Object.values(this.getCurrentUser.permissions).includes('MANAGE_USERS')
    },

    isMobileRequired() {
      let isRequired = false

      if (typeof this.form !== 'undefined' && typeof this.form.mfa_mode === 'string') {
        isRequired = this.form.mfa_mode === 'SMS'
      }

      return isRequired
    },
  },
  watch: {
    getCurrentUser() {
      this.getUsers({ uid: this.getCurrentUser.clientUid })
    },
    getAllUsers() {
      this.users = this.getAllUsers !== '' ? this.getAllUsers : []
      this.selected = []
    },
  },
  methods: {
    ...mapActions(['getUsers', 'saveUser', 'deleteUserapi', 'resetPass', 'encrypt']),
    filterAllUsers() {
      if (this.search !== '') {
        this.users = Object.values(this.users).filter(
          (AllUsers) =>
            AllUsers.email.toLowerCase().includes(this.search.toLowerCase()) ||
            AllUsers.last_name.toLowerCase().includes(this.search.toLowerCase()) ||
            AllUsers.first_name.toLowerCase().includes(this.search.toLowerCase()) ||
            AllUsers.mobile.toLowerCase().includes(this.search.toLowerCase()) ||
            AllUsers.username.toLowerCase().includes(this.search.toLowerCase()) ||
            AllUsers.uid.toLowerCase().includes(this.search.toLowerCase()),
        )
      } else {
        this.users = this.getAllUsers
      }
    },
    set2faSMSMode(e) {
      if (e.target.checked) {
        this.form.mfa_mode = 'SMS'
      } else {
        this.form.mfa_mode = 'EMAIL'
      }
    },
    selectPermission(e, permission) {
      this.setPermission(permission, e.target.checked)
    },
    setPermission(permission, isChecked) {
      let permissionsAllowed = [
        'VIEW_PROPERTY',
        'ADD_PROPERTY',
        'EDIT_PROPERTY',
        'RELEASE_DECLARATIONS',
        'MANAGE_USERS',
      ]
      if (typeof permission === 'string' && typeof isChecked === 'boolean' && permissionsAllowed.includes(permission)) {
        if (isChecked) {
          this.addPermissionPrim(permission)
        } else {
          this.removePermissionPrim(permission)
        }
        this.updatePermissionLogic()
      }
    },
    updatePermissionLogic() {
      this.isViewPropertyDisabled = false
      this.isEditPropertyDisabled = false
      this.isManageUsersDisabled = false

      if (this.form.permissions.includes('ADD_PROPERTY')) {
        this.addPermissionPrim('EDIT_PROPERTY')
        this.isEditPropertyDisabled = true
        this.addPermissionPrim('VIEW_PROPERTY')
        this.isViewPropertyDisabled = true
      } else {
        if (this.form.permissions.includes('EDIT_PROPERTY')) {
          this.addPermissionPrim('VIEW_PROPERTY')
          this.isViewPropertyDisabled = true
        }
      }

      if (this.editID !== '' && !this.addingMode && !Number.isNaN(parseInt(this.editID, 10))) {
        let index = parseInt(this.editID, 10)
        if (typeof this.users[index] !== 'undefined' && this.users[index].is_default.toString() === '1') {
          this.addPermissionPrim('MANAGE_USERS')
          this.isManageUsersDisabled = true
        }
      }
    },
    addPermissionPrim(permission) {
      if (!this.form.permissions.includes(permission)) {
        this.form.permissions.push(permission)
      }
    },
    removePermissionPrim(permission) {
      let index = this.form.permissions.indexOf(permission)
      if (index > -1) {
        this.form.permissions.splice(index, 1)
      }
    },
    editUser(index) {
      this.form.username = this.users[index].username
      this.form.salutation = this.users[index].salutation
      this.form.first_name = this.users[index].first_name
      this.form.last_name = this.users[index].last_name
      this.form.email = this.users[index].email
      this.form.mobile = this.users[index].mobile
      this.form.mfa_mode = this.users[index].mfa_mode
      this.form.client_uid = this.users[index].client_uid
      this.form.prim_uid = this.users[index].prim_uid
      this.form.permissions = []
      this.form.permissions = this.parse_object_data(this.users[index].permissions)
      this.isEdituserVisible = true
      this.addingMode = false
      this.invokeReference('first_name')
      this.editID = index
      this.updatePermissionLogic()
    },
    removeAllSelect() {
      this.allSelected = false
    },
    selectAll() {
      if (this.allSelected) {
        const allkeys = Object.keys(this.users)
        this.selected = allkeys
        this.deletedUser = this.users
        this.editDisabled = true
      } else {
        this.selected = []
        this.editDisabled = false
        this.deletedUser = []
      }
    },
    submituser() {
      let hasError = false
      this.isMobileError = false
      hasError = this.form.validate().errors().any()
      if (!this.validateMobile()) {
        this.isMobileError = true
        hasError = true
      }
      if (hasError) {
        return
      }

      this.saveUser(this.form.all())
      this.isEdituserVisible = false
    },
    validateMobile() {
      return !this.isMobileRequired || this.form.mobile.trim() !== ''
    },
    userDelete() {
      var selectedUser = this.selected
      var allUsers = this.users
      var records = []
      selectedUser.forEach((user) => {
        records.push(Object.values(allUsers)[user])
      }, this)
      this.deleteUsers.client_uid = this.getCurrentUser.clientUid
      this.deleteUsers.records = records

      this.isDeleteVisible = true
    },
    editPopup() {
      this.editID = this.selected[0]
      this.editUser(this.editID)
    },
    addingModeEditPopup() {
      this.editID = ''
      this.isEdituserVisible = true
      this.addingMode = true
      this.resetForm()
      this.invokeReference('first_name')
      this.updatePermissionLogic()
    },
    parse_object_data(data) {
      const parsed_data = []
      const data_length = Object.keys(data).length
      if (data_length > 0) {
        for (let i = 0; i < data_length; i++) {
          parsed_data.push(data[i])
        }
      }

      return parsed_data
    },
    handleModalClose() {
      this.isEdituserVisible = false
      this.isDeleteVisible = false
      this.resetForm()
      this.selected = []
      this.allSelected = false
    },
    addLength(id) {
      if (typeof id === 'undefined') {
        return ''
      }

      const length = id.toString().length
      var value = id
      var i = ''
      for (i = 1; i <= 8 - length; i++) {
        value = ('0' + value).slice(-8)
      }
      return 'M-' + value
    },
    resetForm() {
      this.username = ''
      this.form.salutation = 'mr'
      this.form.first_name = ''
      this.form.last_name = ''
      this.form.email = ''
      this.form.mobile = ''
      this.form.mfa_mode = 'EMAIL'
      this.form.prim_uid = ''
      this.form.can_view_properties = true
      this.form.can_create_properties = false
      this.form.can_edit_properties = false
      this.form.can_release_declarations = false
      this.form.can_manage_users = false
      this.form.permissions = ['VIEW_PROPERTY']
      this.isMobileError = false
      this.form.errors().forget()
    },
    deleteUserConfirm() {
      this.deleteUserapi(this.deleteUsers)
      this.isDeleteVisible = false
    },
    async sendResetPwdMail(val) {
      val = await this.encrypt(val)
      this.resetPass(val)
    },
    invokeReference(ref) {
      this.$nextTick(() => {
        if (
          typeof this.$refs !== 'undefined' &&
          typeof this.$refs[ref] !== 'undefined' &&
          typeof this.$refs[ref].focus === 'function'
        ) {
          this.$refs[ref].focus()
        }
      })
    },
  },
}
</script>
<style scoped lang="css">
@import './assets/custom.css';
@import './assets/form_style.css';
.dashbord-content .row,
.search_row .row,
.new-notifications .row {
  --bs-gutter-x: 0 !important;
}
/*.form-check-input:checked[type=checkbox] {
    background-color: #229D56; border-color: #229D56;
}*/
button.button-style.red_btn:hover {
  background: #d55959 !important;
  border-color: #d55959;
  color: #fff;
  filter: brightness(0.8);
}
.isDisabled {
  opacity: 0.5;
  pointer-events: none;
}
.topspace60 {
  margin-top: 20px;
}
h4.heading_title {
  padding-bottom: 20px;
}
a.button-style-new,
button.button-style-new {
  background: #229d56;
  border: 0;
  border-radius: 0;
  color: #fff;
  display: block;
  height: 40px;
  line-height: 40px;
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
.modal-backdrop .modal {
  border-top: 5px solid #229d56;
}
.__Modal_Popup .modal {
  height: auto !important;
}
.popup_title h2 {
  color: #333;
  font-size: 26px;
}
.tab-border-home p {
  font-size: 16px;
}
.tab-border-home {
  border: none;
}
.select2_input a button:hover {
  background: #229d56;
  color: #fff;
  font-weight: 700;
}
.select2_input a button,
.select2_input button {
  background: #fff;
  border: 1px solid #ccc;
  font-size: 15px;
  margin: 5px 5px 5px;
  padding: 10px 15px;
}
.select2_input {
  text-align: left;
}
.select2_input + .button-style01:hover {
  background: #ccc;
  border-color: #ccc;
}

.select2_input + .button-style01 {
  background: none;
  border-color: #333;
  color: #333;
  line-height: 20px;
  margin-top: 30px;
  max-width: 200px;
  padding: 10px 0;
  width: 100%;
}
.tab-border-home.tab-top-height {
  height: auto;
}
.table_scroll {
  overflow: hidden;
}
.action_row {
  justify-content: flex-start;
}
.search_row {
  padding: 5px 0;
}
.dashbord-content .row {
  align-items: center;
  width: 100%;
}
.Search_Titzle02 {
  height: auto;
  padding: 0;
}
.Search_Titzle input[type='text'] {
  width: 100%;
}
.nopadding {
  padding: 0;
}
.tab-border-home.tab-top-height.textformatting {
  padding: 0 0 0 0 !important;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Search_Titzle input[type='text'] {
    margin-left: 20px !important;
  }
  .footer-space {
    min-height: 100px !important;
  }
  .icon-div {
    width: 30% !important;
  }
  .new-notifications .col-lg-6 {
    width: 100%;
  }
}
@media only screen and (min-width: 100px) and (max-width: 768px) {
  .icon-div {
    width: 20%;
  }
  .icon-cnt-div {
    width: 80%;
  }
  .table_scroll {
    overflow: scroll;
  }
  .footer-space {
    min-height: 100px !important;
  }
  .Search_Titzle input[type='text'] {
    margin-left: 0px !important;
  }
}
td.menu_td > span > div.pop_key {
  top: -10px !important;
  transform: translate3d(10px, 0px, 0px) !important;
  width: 200px;
}
select {
  appearance: auto;
  -webkit-border-radius: 0; /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -moz-border-radius: 0; /* Firefox 1-3.6 */
  border-radius: 0; /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
}
.pop_key {
  height: 35px;
  line-height: 20px;
}

input#flexCheckChecked1:disabled {
  background-color: var(--bg-disabled);
  opacity: 0.6;
}

input#flexCheckChecked3:disabled {
  background-color: var(--bg-disabled);
  opacity: 0.6;
}

.edit_delete {
  float: left;
}
</style>
