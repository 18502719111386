<template>
  <!-- Dashboard  Start -->
  <section class="login_form section dashboard-sec frm_margin_on_mobile">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-left">
          <div class="sec-heading">
            <h4 class="heading_title heading_title_left dynamicColor">
              {{ $t('dashboard.title_prefix') }}{{ clientName }}{{ $t('dashboard.title_postfix') }}
            </h4>
            <p class="p-content dynamicColor">
              {{ $t('dashboard.subline1') }}
              {{ $t('dashboard.subline2') + ' ' }}
              <a
                style="color: var(--api-background)"
                href="#"
                @click.prevent="$router.push('mydata')"
              >
                {{ $t('dashboard.subline_mydata_href_text') }}
              </a>
              {{ ' ' + $t('dashboard.subline3') }}
            </p>
          </div>
        </div>
      </div>

      <div v-if="canViewProperty">
        <!-- New dashboard view if empty properties -->
        <div
          class="dashboard-content topspace60"
          v-show="!hasProperties && canAddProperty"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="empty_main">
                <div class="empty_img">
                  <!-- <img class="emptyDashboardImage" src="@/assets/newproperty/empty_dashboard.svg"> -->
                  <Svgall
                    :name="'empty_dashboard'"
                    :color="primaryColor"
                  />
                  <h2 class="dynamicColor">{{ $t('dashboard.empty.title') }}</h2>
                  <p class="dynamicColor">
                    {{ $t('dashboard.empty.subline1') }}<br />{{ $t('dashboard.empty.subline2') }}
                  </p>
                  <button
                    class="button-style-new"
                    :style="[primaryColor ? { background: primaryColor } : '']"
                    @click="$router.push('/property')"
                  >
                    <img
                      class="max_22"
                      src="@/assets/newproperty/empty_add_green.svg"
                    />
                    {{ $t('dashboard.empty.button_text') }}
                  </button>
                </div>
                <div class="row mob-spacing">
                  <div class="col-lg-12">
                    <div class="dashboard_button_bottom empty_button">
                      <button
                        v-on:click.stop.prevent="openLiegenschaftsinformationenDialog"
                        :style="[primaryColor ? { background: primaryColor } : '']"
                        class="button-style-new"
                      >
                        <img
                          src="@/assets/newproperty/property_information.svg"
                          alt=""
                          class="max_20"
                        />
                        {{ $t('dashboard.buttons.button_1_text') }}
                      </button>
                      <button
                        v-on:click.stop.prevent="openGrundbuchauszugDialog"
                        :style="[primaryColor ? { background: primaryColor } : '']"
                        class="button-style-new"
                      >
                        <img
                          src="@/assets/books.svg"
                          alt=""
                          class="max_20"
                        />
                        {{ $t('dashboard.buttons.button_2_text') }}
                      </button>
                      <button
                        v-on:click.stop.prevent="openGeoViewerDialog"
                        :style="[primaryColor ? { background: primaryColor } : '']"
                        class="button-style-new"
                      >
                        <img
                          class="max_20"
                          src="@/assets/doc-check.svg"
                          alt=""
                        />
                        {{ $t('dashboard.buttons.button_3_text') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Standard dashboard view if properties are available -->
        <div
          class="dashbord-content topspace60 frm_margin_on_mobile"
          v-show="hasProperties"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="column-right">
                <div class="Search_Titzle Search_Titzle02 Titzle02_mob">
                  <h4 class="mobilehide">{{ $t('dashboard.plots') }}</h4>
                  <div class="btn_row">
                    <button
                      class="btn btn_first"
                      :class="{ btnactive: list == true }"
                      @click="setDashboardListLayout(true)"
                    >
                      <img
                        class="max_22"
                        v-if="!list"
                        src="@/assets/grey-list.svg"
                      />
                      <Svgall
                        class="listDynamic"
                        v-if="list"
                        :name="'list_icon'"
                        :color="primaryColor"
                      />
                    </button>
                    <button
                      class="btn btn_second"
                      :class="{ btnactive: list == false }"
                      @click="setDashboardListLayout(false)"
                    >
                      <img
                        class="max_22"
                        v-if="list"
                        src="@/assets/grey-grid.svg"
                      />
                      <Svgall
                        class="listDynamic"
                        v-if="!list"
                        :name="'grid_icon'"
                        :color="primaryColor"
                      />
                    </button>
                    <input
                      type="text"
                      autocomplete="off"
                      class="input-search"
                      v-model.trim="search"
                      @keyup.enter="filterAllproperty"
                      placeholder="Suchen..."
                    />
                    <div class="dropdown">
                      <button
                        class="btn btn_second dropdown-toggle filter_menu"
                        data-bs-toggle="dropdown"
                      >
                        <Svgall
                          class="listDynamic"
                          name="filter_icon"
                          :color="primaryColor"
                        />
                      </button>
                      <div class="dropdown-menu dropdown-menu-end filter_menu_dropdown pop_over">
                        <ul>
                          <li
                            v-for="(filterMenu, filterKey) in propertyFilterOptions"
                            :key="filterKey"
                            @click="filterProperty(filterMenu.key)"
                          >
                            <i
                              :class="'bi ' + filterMenu.statusicon"
                              :style="[primaryColor ? { color: primaryColor } : '']"
                            ></i>
                            <span>{{ filterMenu.filterLbl ? filterMenu.filterLbl : filterMenu.name }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="title-button-div">
                  <h4>{{ $t('dashboard.plots') }}</h4>
                  <button
                    class="button-style-new"
                    @click="$router.push('/property')"
                    v-if="canAddProperty"
                  >
                    <img
                      class="max_22"
                      src="@/assets/white-plus.svg"
                    />{{ $t('dashboard.addProp') }}
                  </button>
                </div>
                <div class="title-button-div">
                  <button
                    class="button-style-new mb_300"
                    v-if="list"
                  >
                    <img
                      class="max_22"
                      src="@/assets/doc_add_white.svg"
                    />

                    {{ $t('dashboard.addDocProp') }}
                  </button>
                </div>
                <div
                  class="action_row mobilehide"
                  v-if="list"
                >
                  <router-link
                    to="/property"
                    v-if="canAddProperty"
                  >
                    <!-- <img class="max_22" src="@/assets/doc_add.svg"> -->
                    <Svgall
                      class="list_edit_delete"
                      :name="'doc_add'"
                      :color="edit_delete"
                    />
                    {{ $t('dashboard.addDocProp') }}
                  </router-link>
                  <a
                    v-if="canEditProperty"
                    @click="editProp"
                    :class="{ isDisabled: editDisabled || selected.length > 1 || selected.length == 0 }"
                    ><img src="" />
                    <Svgall
                      class="list_edit_delete"
                      :name="'doc_edit'"
                      :color="edit_delete"
                    />
                    {{ $t('dashboard.editProp') }}</a
                  >
                  <a
                    v-if="canAddProperty"
                    @click="duplicateProp(selected[0])"
                    :class="{ isDisabled: selected.length !== 1 }"
                    ><img src="" />
                    <Svgall
                      class="list_edit_delete"
                      :name="'duplicate'"
                      :color="edit_delete"
                    />
                    {{ $t('dashboard.duplicateProp') }}</a
                  >
                  <span
                    @click="deleteCkeckProp"
                    :class="{ isDisabled: selected.length == 0 }"
                  >
                    <!-- <img class="max_22" src="@/assets/trash.svg">  -->
                    <Svgall
                      class="list_edit_delete"
                      :name="'delete'"
                      :color="edit_delete"
                    />
                    {{ $t('dashboard.deleteProp') }}
                  </span>
                </div>
                <div class="new-notifications notify-mob">
                  <Spinner
                    v-show="show_spiner"
                    class="dashbord-spiner"
                  />
                  <table
                    class="style_min mobilehide table"
                    v-if="list"
                  >
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          v-if="canEditProperty"
                        >
                          <span class="checkbox_input">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="allSelected"
                              @change="selectAll"
                            />
                            <span class="checkbox_title">{{ $t('dashboard.prop_serial') }}</span>
                          </span>
                        </th>
                        <th scope="col">{{ $t('dashboard.type') }}</th>
                        <th scope="col">{{ $t('dashboard.property_name') }}</th>
                        <th scope="col">{{ $t('dashboard.Status') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="properties in allProperties"
                        :key="properties.prim_uid"
                      >
                        <td v-if="canEditProperty">
                          <span class="checkbox_input">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="selected"
                              :value="properties"
                            />
                            <span
                              class="cursorPointer checkbox_title"
                              @click="editPropFun(canEditProperty, canViewProperty, properties)"
                              >{{ properties.property_id }}</span
                            >
                          </span>
                        </td>
                        <td>
                          <span
                            class="cursorPointer"
                            @click="editPropFun(canEditProperty, canViewProperty, properties)"
                            >{{ dashboardPropertyLabelAndImage(properties).label }}</span
                          >
                        </td>
                        <td>
                          <span
                            class="cursorPointer"
                            @click="editPropFun(canEditProperty, canViewProperty, properties)"
                            >{{ properties.name }}</span
                          >
                        </td>
                        <td>
                          <span :class="property_status[properties.status].iconcolor"
                            ><i :class="'bi ' + property_status[properties.status].statusicon"></i
                          ></span>
                          <span :class="property_status[properties.status].statuscolor">
                            {{ $t('dashboardStatus.' + properties.status + '.name') }}&nbsp;</span
                          >
                          <span
                            class="redTextlist clickable"
                            v-if="properties.status == 'draft'"
                            @click="handleStatusClick(properties)"
                          >
                            {{ $t('dashboardStatus.' + properties.status + '.redText') }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="row">
                    <div
                      class="grid-list-view"
                      :class="list == true ? 'mobilelistview' : 'mobilegridview'"
                    >
                      <ul>
                        <li
                          v-for="properties in allProperties"
                          :key="properties.prim_uid"
                        >
                          <div class="ov_visible box-content">
                            <div class="icon-div">
                              <img
                                class="cursorPointer"
                                @click="editPropFun(canEditProperty, canViewProperty, properties)"
                                :src="dashboardPropertyLabelAndImage(properties).image"
                                alt=""
                              />
                              <span class="desk-top">{{ properties.property_id }}</span>
                            </div>
                            <div class="icon-cnt-div">
                              <div class="preview-cnt">
                                <span
                                  class="cursorPointer"
                                  @click="editPropFun(canEditProperty, canViewProperty, properties)"
                                  >{{ dashboardPropertyLabelAndImage(properties).label }}</span
                                >
                                <div
                                  class="asd_menu"
                                  v-if="canEditProperty || canReleaseDeclaration"
                                >
                                  <span class="mnu_pop"
                                    ><i
                                      class="bi bi-three-dots"
                                      data-bs-toggle="collapse"
                                      :href="'#menu_2' + properties.prim_uid"
                                      role="button"
                                      aria-expanded="false"
                                      aria-controls="collapseExample"
                                    ></i>
                                    <div
                                      class="pop_over collapse"
                                      :id="'menu_2' + properties.prim_uid"
                                    >
                                      <span class="edit-delete">
                                        <router-link :to="'/property/' + properties.prim_uid">
                                          <Svgall
                                            class="edit_delete"
                                            :name="properties.locked_editing === 1 ? 'eye_icon' : 'pencil'"
                                            :color="edit_delete"
                                          />
                                          <span class="icon-title">{{
                                            properties.locked_editing === 1
                                              ? $t('dashboard.view')
                                              : $t('dashboard.edit')
                                          }}</span>
                                        </router-link>
                                      </span>
                                      <span
                                        class="edit-delete"
                                        v-if="canAddProperty"
                                        @click="duplicateProp(properties)"
                                      >
                                        <Svgall
                                          class="edit_delete"
                                          :name="'duplicate'"
                                          :color="edit_delete"
                                        />
                                        <span class="icon-title">{{ $t('dashboard.duplicate') }} </span>
                                      </span>
                                      <span
                                        class="edit-delete"
                                        v-if="properties.status_val === 0"
                                        @click="deleteProp(properties)"
                                      >
                                        <Svgall
                                          class="edit_delete"
                                          :name="'delete'"
                                          :color="edit_delete"
                                        />
                                        <span class="icon-title">{{ $t('dashboard.delete') }} </span>
                                      </span>
                                      <span
                                        class="edit-delete"
                                        v-if="properties.status === 'approval_pending'"
                                        @click="handleStatusClick(properties)"
                                      >
                                        <Svgall
                                          class="edit_delete"
                                          :name="'pen_square'"
                                          :color="edit_delete"
                                        />
                                        <span class="icon-title">{{ $t('declaration.approve_declaration') }} </span>
                                      </span>
                                    </div>
                                  </span>
                                </div>
                              </div>
                              <div class="SchulstrasseCnt cursorPointer">
                                <!-- <router-link  :to="'/property/'+properties.prim_uid"> -->
                                <span
                                  @click="editPropFun(canEditProperty, canViewProperty, properties)"
                                  v-if="properties.name"
                                  >{{ properties.name }}</span
                                >
                                <span
                                  @click="editPropFun(canEditProperty, canViewProperty, properties)"
                                  v-else
                                  class="graycolor"
                                  >{{ $t('dashboard.noAddress') }}</span
                                >
                                <!-- </router-link> -->
                              </div>
                              <div :class="'check-icon-text ' + property_status[properties.status].statuscolor">
                                <span class="red-title-icon"
                                  ><i :class="'bi ' + property_status[properties.status].statusicon"></i
                                ></span>
                                <span
                                  class="red-title"
                                  :class="{
                                    'clickable text-decoration-underline':
                                      properties.status === 'approval_pending' ||
                                      properties.status === 'processing' ||
                                      properties.status === 'internal_approval_requested' ||
                                      properties.status === 'elster_submission' ||
                                      properties.status === 'elster_completed',
                                  }"
                                  @click="handleStatusClick(properties)"
                                >
                                  {{ $t('dashboardStatus.' + properties.status + '.name') }}</span
                                >
                                <span
                                  class="red_txt clickable text-decoration-underline"
                                  v-if="
                                    properties.status === 'draft' ||
                                    properties.status === 'approval_pending' ||
                                    properties.status === 'data_requested'
                                  "
                                  @click="handleStatusClick(properties, true)"
                                >
                                  {{ $t('dashboardStatus.' + properties.status + '.redText') }}
                                </span>
                              </div>
                              <span class="mobile-top">{{ properties.property_id }}</span>
                            </div>

                            <div class="border-top-set">
                              <div class="icon-div">
                                <span class="mobile-list">{{ properties.property_id }}</span>
                              </div>
                              <div class="icon-cnt-div">
                                <div :class="'check-icon-text ' + property_status[properties.status].statuscolor">
                                  <span class="red-title-icon"
                                    ><i :class="'bi ' + property_status[properties.status].statusicon"></i
                                  ></span>
                                  <span
                                    @click="handleStatusClick(properties)"
                                    class="red-title"
                                  >
                                    {{ $t('dashboardStatus.' + properties.status + '.name') }}</span
                                  >
                                  <span
                                    class="redTextlist"
                                    v-if="properties.status == 'draft'"
                                    >{{ $t('dashboardStatus.' + properties.status + '.redText') }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li
                          class="last-add-li-box mobilehide"
                          v-if="canAddProperty"
                        >
                          <!-- Box Row 02 -->
                          <div class="grid-space">
                            <div
                              class="content_center add_user_box box-content"
                              style="align-items: center"
                            >
                              <div class="icon-cnt-div">
                                <router-link to="/property">
                                  <div class="preview-cnt">
                                    <span>
                                      <!--  <img src="@/assets/plus.svg" class="max_15" alt=""/> -->
                                      <Svgall
                                        class="plus_svg"
                                        :name="'plus'"
                                        :color="edit_delete"
                                      />
                                      {{ $t('dashboard.addProp') }}
                                    </span>
                                  </div>
                                </router-link>
                              </div>
                            </div>
                          </div>
                          <!-- Box Row 03 Ends ///////// -->
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="devider mobilehide"></div>
              </div>
              <div v-if="hasDeclarations">
                <div class="Search_Titzle Search_Titzle02 Titzle02_mob mobilehide">
                  <h4>{{ $t('dashboard.finished_declarations') }}</h4>
                  <input
                    type="text"
                    class="input-search"
                    v-model.trim="declarationSearch"
                    @keyup.enter="filterDeclaration"
                    placeholder="Suchen..."
                  />
                </div>
                <div class="new-notifications notify-mob">
                  <Spinner
                    v-show="declarationLoading"
                    class="dashbord-spiner"
                  />
                  <table class="style_min mobilehide table">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t('dashboard.prop_serial') }}</th>
                        <th scope="col">{{ $t('dashboard.type') }}</th>
                        <th scope="col">{{ $t('newProperty.propertyDetails.name') }}</th>
                        <th scope="col">{{ $t('dashboard.elster_transferred_at') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="declaration in finishedDeclarations"
                        :key="declaration.id"
                      >
                        <td
                          class="cursorPointer"
                          @click="editDeclarationProp(canEditProperty, declaration)"
                        >
                          {{ declaration.uid }}
                        </td>
                        <td>{{ declaration.type }}</td>
                        <td>{{ declaration.address }}</td>
                        <td>{{ declaration.elsterTransferredAt }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row mob-spacing">
                <div class="col-lg-12">
                  <div class="dashboard_button_bottom empty_button">
                    <button
                      v-on:click.stop.prevent="openLiegenschaftsinformationenDialog"
                      :style="[primaryColor ? { background: primaryColor } : '']"
                      class="button-style-new"
                    >
                      <img
                        src="@/assets/newproperty/property_information.svg"
                        alt=""
                        class="max_20"
                      />
                      {{ $t('dashboard.buttons.button_1_text') }}
                    </button>
                    <button
                      v-on:click.stop.prevent="openGrundbuchauszugDialog"
                      :style="[primaryColor ? { background: primaryColor } : '']"
                      class="button-style-new"
                    >
                      <img
                        src="@/assets/books.svg"
                        alt=""
                        class="max_20"
                      />
                      {{ $t('dashboard.buttons.button_2_text') }}
                    </button>
                    <button
                      v-on:click.stop.prevent="openGeoViewerDialog"
                      :style="[primaryColor ? { background: primaryColor } : '']"
                      class="button-style-new"
                    >
                      <img
                        class="max_20"
                        src="@/assets/doc-check.svg"
                        alt=""
                      />
                      {{ $t('dashboard.buttons.button_3_text') }}
                    </button>
                    <button
                      v-if="isRoometricActive"
                      v-on:click.stop.prevent="openRoometricDialog"
                      :style="[primaryColor ? { background: primaryColor } : '']"
                      class="button-style-new"
                    >
                      <img
                        class="max_20"
                        src="@/assets/roometric/metrics_service.svg"
                        alt=""
                      />
                      {{ $t('roometric.buttonLabel') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DashboardModalDialog
        v-show="this.liegenschaftsinformationenDialogVisible === true"
        @close="closeLiegenschaftsinformationenDialog"
        class="modal_wide"
      >
        <template
          v-slot:body
          class="modal-inside"
        >
          <div>
            <div class="popup_title">
              <h2>{{ $t('dashboard.info1.title') }}</h2>
            </div>
            <hr class="subheading_devide" />

            <div class="tab-border-home tab-top-height">
              <div class="modal-content">
                <div class="modal-icon">
                  <!--  <img class="modal-img" src="@/assets/note.svg" alt=""/> -->
                  <Svgall
                    class="modal-note"
                    :name="'info'"
                    :color="infoIconColor"
                  />
                </div>
                <div>
                  <div class="modal-content-div modal-content-headline">
                    {{ $t('dashboard.info1.headline1') }}
                  </div>
                  <div class="modal-content-div">
                    {{ $t('dashboard.info1.block1') }}
                  </div>
                  <div class="modal-content-div">
                    <span v-html="$t('dashboard.info1.block2')"></span>
                  </div>
                  <div class="modal-content-div modal-content-headline">
                    {{ $t('dashboard.info1.headline2') }}
                  </div>
                  <div class="modal-content-div">
                    {{ $t('dashboard.info1.block3') }}
                  </div>
                  <div class="modal-content-div"></div>
                </div>
              </div>
              <button
                class="btn btn-primary button-style01 modal-button"
                @click="closeLiegenschaftsinformationenDialog"
              >
                {{ $t('dashboard.Conclude') }}
              </button>
            </div>
          </div>
        </template>
      </DashboardModalDialog>

      <DashboardModalDialog
        v-show="this.grundbuchauszugDialogVisible === true"
        @close="closeGrundbuchauszugDialog"
        class="modal_wide"
      >
        <template
          v-slot:body
          class="modal-inside"
        >
          <div>
            <div class="popup_title">
              <h2>{{ $t('dashboard.info2.title') }}</h2>
            </div>
            <hr class="subheading_devide" />

            <div class="tab-border-home tab-top-height">
              <div class="modal-content">
                <div class="modal-icon">
                  <!--  <img class="modal-img" src="@/assets/note.svg" alt=""/> -->
                  <Svgall
                    class="modal-note"
                    :name="'info'"
                    :color="infoIconColor"
                  />
                </div>
                <div>
                  <div class="modal-content-div">
                    {{ $t('dashboard.info2.block1') }}
                  </div>
                  <div class="modal-content-div">
                    {{ $t('dashboard.info2.block2') }}
                  </div>
                  <div class="modal-content-div">
                    {{ $t('dashboard.info2.more') }}
                  </div>
                  <div class="modal-content-div">
                    <a
                      href="https://hilfe.grundsteuer-digital.de/faq/grundbuchauszug/"
                      target="_blank"
                    >
                      https://hilfe.grundsteuer-digital.de/faq/grundbuchauszug/
                    </a>
                  </div>
                  <div class="modal-content-div"></div>
                </div>
              </div>

              <div><br /></div>

              <div class="modal-content">
                <div class="modal-icon">
                  <!-- <img class="modal-img" src="@/assets/note.svg" alt=""/> -->
                  <Svgall
                    class="modal-note"
                    :name="'info'"
                    :color="infoIconColor"
                  />
                </div>
                <div>
                  <div class="modal-content-div modal-content-headline">
                    {{ $t('dashboard.info2.headline2') }}
                  </div>
                  <div class="modal-content-div">
                    {{ $t('dashboard.info2.block3') }}
                  </div>
                  <div class="modal-content-div"></div>
                </div>
              </div>

              <div class="modal-button-row">
                <button
                  class="btn btn-primary button-style01 modal-button modal-button-left"
                  @click="openGrundbuchauszugWebsite"
                >
                  {{ $t('dashboard.info2.button_text') }}
                </button>

                <button
                  class="btn btn-primary button-style01 modal-button modal-button-right"
                  @click="closeGrundbuchauszugDialog"
                >
                  {{ $t('dashboard.Conclude') }}
                </button>
              </div>
            </div>
          </div>
        </template>
      </DashboardModalDialog>

      <DashboardModalDialog
        v-show="this.geoViewerDialogVisible === true"
        @close="closeGeoViewerDialog"
        class="modal_wide"
      >
        <template
          v-slot:body
          class="modal-inside"
        >
          <div>
            <div class="popup_title">
              <h2>{{ $t('dashboard.info3.title') }}</h2>
            </div>
            <hr class="subheading_devide" />

            <div class="tab-border-home tab-top-height">
              <div class="modal-content">
                <div class="modal-icon">
                  <!-- <img class="modal-img" src="@/assets/note.svg" alt=""/> -->
                  <Svgall
                    class="modal-note"
                    :name="'info'"
                    :color="infoIconColor"
                  />
                </div>
                <div>
                  <div class="modal-content-div modal-content-headline">
                    {{ $t('dashboard.info3.headline1') }}
                  </div>
                  <div class="modal-content-div">
                    {{ $t('dashboard.info3.block1') }}
                  </div>
                  <div class="modal-content-div">
                    <span v-html="$t('dashboard.info3.block2')"></span>
                  </div>
                  <div class="modal-content-div">
                    {{ $t('dashboard.info3.block3') }}
                  </div>
                  <div class="modal-content-div"></div>
                </div>
              </div>

              <div class="modal-button-row">
                <button
                  class="btn btn-primary button-style01 modal-button modal-button-left"
                  @click="openGeoViewerWebsite"
                >
                  {{ $t('dashboard.info3.button_text') }}
                </button>

                <button
                  class="btn btn-primary button-style01 modal-button modal-button-right"
                  @click="closeGeoViewerDialog"
                >
                  {{ $t('dashboard.Conclude') }}
                </button>
              </div>
            </div>
          </div>
        </template>
      </DashboardModalDialog>

      <BestandsnachweisDialog
        v-show="isModalVisible"
        @close="handleModalClose"
        class="modal_wide"
      >
        <template
          v-slot:body
          class="modal-inside"
        >
          <div>
            <div class="popup_title">
              <h2>{{ $t('dashboard.requestproof') }}</h2>
            </div>

            <div class="tab-border-home tab-top-height">
              <p>{{ $t('dashboard.requestprooftext1') }}</p>

              <p>
                <!-- <img src="@/assets/note.svg" alt=""/> -->
                <Svgall
                  class="modal-note"
                  :name="'info'"
                  :color="infoIconColor"
                />
                <span>
                  <b>{{ $t('dashboard.Attention') }}:</b> {{ $t('dashboard.Attentiontext1') }}<br />{{
                    $t('dashboard.Attentiontext2')
                  }}</span
                >
              </p>

              <div class="popup_title">
                <p>{{ $t('dashboard.states') }}:</p>
              </div>
              <div class="select2_input">
                <span
                  v-for="item in states"
                  :key="item.link"
                >
                  <a
                    v-if="item.link != ''"
                    :href="item.link"
                    target="_blank"
                  >
                    <button class="btn-default rounded-pill">{{ item.title }}</button>
                  </a>
                  <button
                    v-if="item.link == ''"
                    class="btn-default rounded-pill"
                  >
                    <!--  <img v-if="item.title=='Nordrhein Westfalen'" @click="tooltip_dash" src="@/assets/note.svg"
                         class="max_15" alt=""/> -->
                    <Svgall
                      v-if="item.title == 'Nordrhein Westfalen'"
                      @click="tooltip_dash"
                      class="modal-note"
                      :name="'info'"
                      :color="infoIconColor"
                    />
                    {{ item.title }}
                  </button>

                  <div
                    class="toll-tip-box"
                    v-if="item.title == 'Nordrhein Westfalen' && Westfalen"
                  >
                    Keine zentrale Stelle, pro Stadt/Gemeinde bitte erfragen
                  </div>
                </span>
              </div>
              <button
                class="btn btn-primary button-style01"
                @click="handleModalClose"
              >
                {{ $t('dashboard.Conclude') }}
              </button>
            </div>
          </div>
        </template>
      </BestandsnachweisDialog>

      <DeleteModalDialog
        v-show="isDeleteVisible"
        @close="handleModalClose"
        class="modal_wide"
      >
        <template
          v-slot:body
          class="modal-inside"
        >
          <div>
            <div class="popup_title b_btm">
              <h2>{{ $t('dashboard.deleteProp') }}</h2>
            </div>
            <div class="tab-border-home tab-top-height textformatting">
              <div class="min_cont">
                <p>{{ $t('deleteModal.p') }}</p>
              </div>
              <div class="benutzerverwaltung btn_170">
                <button
                  class="button-style btn_line"
                  @click="handleModalClose"
                >
                  {{ $t('mydata.abort') }}
                </button>
                <button
                  class="button-style red_btn"
                  @click="deleteConfirmProp"
                >
                  {{ $t('dashboard.delete') }}
                </button>
              </div>
            </div>
          </div>
        </template>
      </DeleteModalDialog>

      <ModalDialog
        class="modal_wide modal_wide_cancel"
        v-show="isDuplicateVisible"
        @close="handleModalClose"
      >
        <template
          v-slot:body
          class="modal-inside"
        >
          <div class="popup_title b_btm">
            <h2>{{ $t('dashboard.duplicateProp') }}</h2>
          </div>
          <div class="textfileformating textfileformating02">
            <div class="filemin_cont cancel-popup">
              <div class="modal-text">
                <span>{{ $t('dashboard.duplicateProp_text') }} </span>
              </div>
            </div>
            <div class="btn_170 btn_cancel">
              <button
                class="border-button-style"
                @click="handleModalClose"
              >
                {{ $t('dashboard.answer_no') }}
              </button>
              <button
                class="modal-button"
                :style="[primaryColor ? { background: primaryColor } : '']"
                @click="duplicateConfirmProp"
              >
                {{ $t('dashboard.answer_yes') }}
              </button>
            </div>
          </div>
        </template>
      </ModalDialog>

      <ModalDialog
        class="modal_wide modal_wide_cancel"
        v-show="releasePropVisible && canEditProperty"
        @close="cancelReleaseProperty"
      >
        <template
          v-slot:body
          class="modal-inside"
        >
          <div class="popup_title b_btm">
            <h2>{{ $t('handle_property_status.confirm_title') }}</h2>
          </div>
          <div class="textfileformating textfileformating02">
            <div class="filemin_cont cancel-popup">
              <div class="modal-text">
                <span>{{ $t('handle_property_status.confirm_question') }} </span>
              </div>
              <div class="form-group">
                <div class="row_setp_main">
                  <div class="data-form">
                    <h2 class="subheading_title comment_field_label">
                      {{ $t('handle_property_status.label_client_comment') }}
                    </h2>
                    <textarea
                      type="text"
                      autocomplete="off"
                      v-model="statusForm.client_comment"
                      placeholder=""
                      class="input-field comment-area"
                      ref="statusForm_client_comment"
                      @keyup.esc="cancelReleaseProperty"
                      @keyup.enter="doReleasePropertyIfEmptyComment"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="btn_170 btn_cancel">
              <button
                class="border-button-style"
                @click="cancelReleaseProperty"
              >
                {{ $t('handle_property_status.answer_cancel') }}
              </button>
              <button
                class="modal-button"
                :style="[primaryColor ? { background: primaryColor } : '']"
                @click="doReleaseProperty"
              >
                {{ $t('handle_property_status.answer_release') }}
              </button>
            </div>
          </div>
        </template>
      </ModalDialog>

      <ModalDialog
        class="modal_wide modal_wide_cancel"
        v-show="showPropertyLockingInfo"
        @close="hidePropertyLockingInfoDialog"
      >
        <template
          v-slot:body
          class="modal-inside"
        >
          <div class="popup_title b_btm">
            <h2>{{ propertyLockingTitle }}</h2>
          </div>
          <div class="textfileformating textfileformating02">
            <div class="filemin_cont cancel-popup">
              <div class="modal-text">
                <span>{{ propertyLockingMessage }} </span>
              </div>
            </div>
            <div class="btn_170 btn_cancel">
              <button
                class="modal-button"
                :style="[primaryColor ? { background: primaryColor } : '']"
                @click="viewProperty"
              >
                {{ $t('dashboard.property_view') }}
              </button>
              <button
                class="modal-button"
                :style="[primaryColor ? { background: primaryColor } : '']"
                @click="hidePropertyLockingInfoDialog"
              >
                {{ $t('general.ok') }}
              </button>
            </div>
          </div>
        </template>
      </ModalDialog>

      <RoometricDialog
        v-show="isRoometricDialogVisible"
        @close-roometric-dialog="closeRoometricDialog"
      >
      </RoometricDialog>
    </div>
  </section>
  <!-- Dashboard  End ///////////// -->
</template>

<script type="text/javascript">
import i18n from '@/i18n'
import form from 'vuejs-form'
import BestandsnachweisDialog from '@/components/BestandsnachweisDialog.vue'
import DeleteModalDialog from '@/components/DeleteModalDialog.vue'
import DashboardModalDialog from '@/components/DashboardModalDialog.vue'
import Spinner from '@/components/SpinnerLoader'
import ModalDialog from '@/components/ModalDialog.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import RoometricDialog from '@/components/RoometricDialog'
import { isRoometricActive } from '@/helper/RoometricHelper'

const { t, tm } = i18n.global

export default {
  name: 'Header',
  components: {
    BestandsnachweisDialog,
    DashboardModalDialog,
    DeleteModalDialog,
    Spinner,
    ModalDialog,
    RoometricDialog,
  },
  data() {
    return {
      statusForm: form({
        client_comment: '',
      }),
      hasProperties: false,
      hasDeclarations: false,
      clientName: '',
      declarationSearch: '',
      declarationLoading: false,
      show_spiner: true,
      isRoometricDialogVisible: false,
      isModalVisible: false,
      isDuplicateVisible: false,
      liegenschaftsinformationenDialogVisible: false,
      grundbuchauszugDialogVisible: false,
      geoViewerDialogVisible: false,
      Westfalen: false,
      releasePropVisible: false,
      releasePropUid: '',
      isDeleteVisible: false,
      deletedProperty: [],
      duplicatedProperty: [],
      allProperties: [],
      finishedDeclarations: [],
      selected: [],
      propType: [],
      allSelected: false,
      primaryColor: '',
      edit_delete: '',
      fontColor: '',
      editDisabled: false,
      taxModels: [
        {
          model: 'Bundesmodell',
          stateCodes: ['3', '4', '5', '8', '10', '11', '12', '13', '15', '16'],
        },
        {
          model: 'Modifiziertes Bodenwertmodell',
          stateCodes: ['1'],
        },
        {
          model: 'Flächen-Modell',
          stateCodes: ['2'],
        },
        {
          model: 'Wohnlagen-Modell',
          stateCodes: ['6'],
        },
        {
          model: 'Flächen-Faktor-Modell',
          stateCodes: ['7'],
        },
        {
          model: 'Flächen-Lage-Modell',
          stateCodes: ['9'],
        },
        {
          model: 'Modifiziertes Bodenwertmodell',
          stateCodes: ['14'],
        },
      ],
      property_type: {
        '': { propertyName: this.$t('property_type.1'), image: 'etwas_anderes.png' },
        0: { propertyName: this.$t('property_type.1'), image: 'etwas_anderes.png' },
        1: { propertyName: this.$t('property_type.1'), image: 'etwas_anderes.png' },
        2: { propertyName: this.$t('property_type.2'), image: 'einfamilienhaus.png' },
        3: { propertyName: this.$t('property_type.3'), image: 'doppelhaushälfte.png' },
        4: { propertyName: this.$t('property_type.4'), image: 'eigentumswohnung.png' },
        5: { propertyName: this.$t('property_type.5'), image: 'mehrfamilienhaus.png' },
        6: { propertyName: this.$t('property_type.6'), image: 'wohnung.png' },
        7: { propertyName: this.$t('property_type.7'), image: 'produktionsstandort.png' },
        8: { propertyName: this.$t('property_type.8'), image: 'etwas_anderes.png' },
        9: { propertyName: this.$t('property_type.9'), image: 'etwas_anderes.png' },
      },
      uses_type: {
        '-1': { useName: 'unbebautes Grundstück' },
        '-2': { useName: 'unbebautes Grundstück' },
        '-3': { useName: 'unbebautes Grundstück' },
        '-4': { useName: 'unbebautes Grundstück' },
      },
      states: [
        {
          title: 'Baden-Württemberg',
          link: 'https://www.service-bw.de/leistung/-/sbw/Liegenschaftskataster++Auszug+beantragen-359-leistung-0',
        },
        { title: 'Bayern', link: 'https://www.ldbv.bayern.de/produkte/kataster/katasterauszug.html' },
        { title: 'Berlin', link: 'https://service.berlin.de/dienstleistung/324341/' },
        {
          title: 'Brandenburg',
          link: 'https://geobasis-bb.de/lgb/de/geodaten/liegenschaftskataster/liegenschaftskataster-auszug/',
        },
        { title: 'Bremen', link: 'https://www.service.bremen.de/sixcms/detail.php?gsid=bremen128.c.8759.de' },
        { title: 'Hamburg', link: 'https://serviceportal.hamburg.de/HamburgGateway/Service/Entry/GS' },
        {
          title: 'Hessen',
          link: 'https://hvbg.hessen.de/geoinformation/liegenschaftskataster/ausgaben-aus-dem-liegenschaftskataster',
        },
        {
          title: 'Mecklenburg-Vorpommern',
          link: 'https://www.geoportal-mv.de/portal/Geodatenshop/Liegenschaftskataster',
        },
        { title: 'Niedersachsen', link: 'https://www.geobasisdaten.niedersachsen.de/mapservice/webformulare/' },
        { title: 'Nordrhein Westfalen', link: '' },
        {
          title: 'Rheinland-Pfalz',
          link: 'https://lvermgeo.rlp.de/de/geodaten-geoshop/shops/bestellung-von-auszuegen-aus-dem-liegenschaftskataster/',
        },
        { title: 'Saarland', link: 'https://www.shop.lvgl.saarland.de/' },
        { title: 'Sachsen', link: 'https://www.landesvermessung.sachsen.de/bestandsnachweis-5716.html' },
        {
          title: 'Sachsen-Anhalt',
          link: 'https://www.lvermgeo.sachsen-anhalt.de/de/liegenschaftsbuch/liegenschaftsbuch.html',
        },
        { title: 'Schleswig-Holstein', link: 'https://geoserver.gdi-sh.de/formular/' },
        {
          title: 'Thüringen',
          link: 'https://tlbg-onlineshop.thueringen.de/onlineshop/flurstuecke-und-gebaeude/auszuege-liegenschaftskataster/',
        },
      ],
      property_status: {
        null: {
          name: t('property_status.null'),
          statuscolor: 'red_icon',
          statusicon: 'bi-check-circle-fill',
          iconcolor: 'red_icon',
        },
        draft: {
          name: t('property_status.draft'),
          filterLbl: t('property_status_filter.draft'),
          statuscolor: 'yellow_icon',
          statusicon: 'bi-pencil-square',
          iconcolor: 'gray_iocn',
        },
        processing: {
          name: t('property_status.processing'),
          statuscolor: 'yellow_icon',
          statusicon: 'bi-pencil-square',
          iconcolor: 'gray_iocn',
        },
        data_requested: {
          name: t('property_status.data_requested'),
          statuscolor: 'red_icon',
          statusicon: 'bi-check-circle-fill',
          iconcolor: 'red_icon',
        },
        internal_approval_requested: {
          name: t('property_status.internal_approval_requested'),
          statuscolor: 'graycolor',
          statusicon: 'bi-hourglass-top',
          iconcolor: 'gray_iocn',
        },
        approval_pending: {
          name: t('property_status.approval_pending'),
          statuscolor: 'red_icon',
          statusicon: 'bi-pencil-square',
          iconcolor: 'red_icon',
        },
        email_approval_pending: {
          name: t('property_status.approval_pending'),
          statuscolor: 'red_icon',
          statusicon: 'bi-pencil-square',
          iconcolor: 'red_icon',
        },
        elster_submission: {
          name: t('property_status.elster_submission'),
          statuscolor: 'graycolor',
          statusicon: 'bi-hourglass-top',
          iconcolor: 'gray_iocn',
        },
        elster_completed: {
          name: t('property_status.elster_completed'),
          statuscolor: 'graycolor',
          statusicon: 'bi-hourglass-top',
          iconcolor: 'gray_iocn',
        },
        assessment_received: {
          name: t('property_status.assessment_received'),
          statuscolor: 'graycolor',
          statusicon: 'bi-hourglass-top',
          iconcolor: 'gray_iocn',
        },
        dispute: {
          name: t('property_status.dispute'),
          statuscolor: 'graycolor',
          statusicon: 'bi-hourglass-top',
          iconcolor: 'gray_iocn',
        },
        completed: {
          name: t('property_status.completed'),
          statuscolor: 'green_txt',
          statusicon: 'bi-check-circle-fill',
          iconcolor: 'green_txt',
        },
      },
      showPropertyLockingInfo: false,
      propertyLockingTitle: '',
      propertyLockingMessage: '',
      propertyLockingInfoUid: 0,
    }
  },
  created() {
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#43ac6f'
    this.edit_delete = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#464747'
    this.infoIconColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#000000'
  },
  mounted() {
    this.clientName = this.getCurrentUser.firstName + ' ' + this.getCurrentUser.lastName
    if (this.getCurrentUser.salutation) {
      let mySalutation = this.$t('general.salutation.' + this.getCurrentUser.salutation)
      if (mySalutation.startsWith('general.salutation.')) {
        mySalutation = ''
      } else {
        mySalutation = mySalutation + ' '
      }
      this.clientName = mySalutation + this.clientName
    }
    this.search = ''
    this.selectedFilterKey = ''
    this.filterAllproperty()
    this.getCompletedDeclarations()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#43ac6f'
    this.setDashboardPropertySearch('')
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getArrProperties',
      'getCountries',
      'getDeleteProp',
      'getArrDeclarations',
      'getDashboardListLayout',
      'getDashboardPropertySearch',
      'getDashboardSelectedFilterKey',
    ]),
    canViewProperty() {
      return this.getCurrentUser.permissions && Object.values(this.getCurrentUser.permissions).includes('VIEW_PROPERTY')
    },
    canAddProperty() {
      return this.getCurrentUser.permissions && Object.values(this.getCurrentUser.permissions).includes('ADD_PROPERTY')
    },
    canEditProperty() {
      return this.getCurrentUser.permissions && Object.values(this.getCurrentUser.permissions).includes('EDIT_PROPERTY')
    },
    canReleaseDeclaration() {
      return (
        this.getCurrentUser.permissions &&
        Object.values(this.getCurrentUser.permissions).includes('RELEASE_DECLARATIONS')
      )
    },
    list() {
      return this.getDashboardListLayout
    },

    search: {
      get() {
        return this.getDashboardPropertySearch
      },
      set(val) {
        this.setDashboardPropertySearch(val)
      },
    },
    selectedFilterKey: {
      get() {
        return this.getDashboardSelectedFilterKey
      },
      set(val) {
        this.setDashboardSelectedFilterKey(val)
      },
    },

    isRoometricActive() {
      return isRoometricActive()
    },
    propertyFilterOptions() {
      let filterOpt = []
      filterOpt.push({
        key: 'reset_filter',
        name: this.$t('general.reset_filter'),
        statusicon: 'bi-x-circle',
      })
      for (let property_status in this.property_status) {
        if (property_status !== 'null' && property_status !== 'email_approval_pending') {
          filterOpt.push({ ...this.property_status[property_status], ...{ key: property_status } })
        }
      }
      return filterOpt
    },
  },
  watch: {
    getArrProperties(val) {
      if (val.totalRecords && val.totalRecords > 0) {
        this.allProperties = val.properties
        this.hasProperties = true
      } else {
        this.allProperties = []
        if (this.search || this.selectedFilterKey) {
          this.hasProperties = true
        } else {
          this.hasProperties = false
        }
      }
      this.show_spiner = false
    },
    getArrDeclarations(val) {
      if (val.totalRecords && val.totalRecords > 0) {
        this.finishedDeclarations = val.declarations
        this.hasDeclarations = true
      } else {
        this.finishedDeclarations = []
        this.hasDeclarations = !!this.declarationSearch
      }
      this.declarationLoading = false
    },
  },
  methods: {
    ...mapActions([
      'apiGetProperties',
      'shareProperty',
      'countries',
      'deleteProperty',
      'showToast',
      'apiDuplicateProperty',
      'dataRequestCompleted',
      'apiGetCompletedDeclarations',
    ]),
    ...mapMutations(['setDashboardListLayout', 'setDashboardPropertySearch', 'setDashboardSelectedFilterKey']),

    dashboardPropertyLabelAndImage(property) {
      let myPropertyLabelAndImage = {
        label: this.$t('dashboard.unknownPropertyTypeName'),
        image: 'etwas_anderes.png',
      }

      if (
        typeof property === 'object' &&
        typeof property.federal_state_uid !== 'undefined' &&
        typeof property.property_type !== 'undefined' &&
        typeof property.property_exemption_benefit_type !== 'undefined' &&
        typeof property.economic_entity_type !== 'undefined'
      ) {
        let objDescription = this.getDashboardPropertyTypeNameAndImage(
          property.federal_state_uid,
          property.property_type,
          property.property_exemption_benefit_type,
          property.economic_entity_type,
        )
        if (typeof objDescription !== 'undefined') {
          myPropertyLabelAndImage.label = objDescription.name
          myPropertyLabelAndImage.image = objDescription.image
        }
      }

      return myPropertyLabelAndImage
    },
    filterAllproperty() {
      this.show_spiner = true
      this.apiGetProperties({
        txt: this.search,
        filterKey: this.selectedFilterKey,
      })
    },
    filterProperty(filterKey) {
      if (filterKey === 'reset_filter') {
        this.selectedFilterKey = ''
      } else {
        this.selectedFilterKey = filterKey
      }
      this.filterAllproperty()
    },
    filterDeclaration() {
      if (this.declarationSearch !== '') {
        this.declarationLoading = true
        this.apiGetCompletedDeclarations(this.declarationSearch)
      }
    },
    getCompletedDeclarations() {
      this.apiGetCompletedDeclarations()
    },

    openpopup() {
      this.isModalVisible = true
    },

    openLiegenschaftsinformationenDialog() {
      this.liegenschaftsinformationenDialogVisible = true
    },

    closeLiegenschaftsinformationenDialog() {
      this.liegenschaftsinformationenDialogVisible = false
      this.selected = []
    },

    openGrundbuchauszugDialog() {
      this.grundbuchauszugDialogVisible = true
    },

    closeGrundbuchauszugDialog() {
      this.grundbuchauszugDialogVisible = false
      this.selected = []
    },

    openGrundbuchauszugWebsite() {
      window.open('https://auszug.grundsteuer-digital.de/', '_blank')
    },

    openGeoViewerDialog() {
      this.geoViewerDialogVisible = true
    },

    closeGeoViewerDialog() {
      this.geoViewerDialogVisible = false
      this.selected = []
    },

    openGeoViewerWebsite() {
      window.open(
        'https://hilfe.grundsteuer-digital.de/faq/informationsschreiben-und-seiten-der-bundeslaender/',
        '_blank',
      )
    },

    openRoometricDialog() {
      if (this.isRoometricActive) {
        this.isRoometricDialogVisible = true
      }
    },

    closeRoometricDialog() {
      this.isRoometricDialogVisible = false
    },

    deleteCkeckProp() {
      let found = this.selected.filter((o) => o.status_val === 1)
      if (found.length > 0) {
        this.showToast({
          message: this.$t('general.errors.activePropertyDeleteNotAllowed'),
          toastType: 'error',
        })
      } else {
        this.isDeleteVisible = true
        var selectedprop = this.selected
        selectedprop.forEach((prop) => {
          this.deletedProperty.push(prop)
        }, this)
      }
    },
    deleteProp(rec) {
      this.selected = []
      this.selected.push(rec)

      this.deletedProperty = this.selected
      this.isDeleteVisible = true
    },

    duplicateProp(rec) {
      //console.log("this.selected: ", this.selected)
      if (typeof rec === 'object') {
        this.selected = []
        this.selected.push(rec)
      }
      this.duplicatedProp = this.selected
      //console.log("this.selected: ", this.selected)
      this.isDuplicateVisible = true
    },

    duplicateConfirmProp() {
      let payload
      if (typeof this.duplicatedProp === 'object' && this.duplicatedProp.length === 1) {
        payload = {
          duplicateClientUid: this.duplicatedProp[0].client_uid,
          propertyId: this.duplicatedProp[0].prim_uid,
        }
        this.apiDuplicateProperty(payload)
      }

      this.isModalVisible = false
      this.isDeleteVisible = false
      this.isDuplicateVisible = false
      this.liegenschaftsinformationenDialogVisible = false
      this.grundbuchauszugDialogVisible = false
      this.geoViewerDialogVisible = false
      this.selected = []
      this.deletedProperty = []
      this.duplicatedProperty = []
      this.allSelected = false
    },

    tooltip_dash() {
      this.Westfalen = !this.Westfalen
    },
    handleModalClose() {
      this.isModalVisible = false
      this.isDeleteVisible = false
      this.isDuplicateVisible = false
      this.liegenschaftsinformationenDialogVisible = false
      this.grundbuchauszugDialogVisible = false
      this.geoViewerDialogVisible = false
      this.selected = []
      this.allSelected = false
      this.deletedProperty = []
      this.duplicatedProperty = []
    },
    deleteConfirmProp() {
      this.deleteProperty(this.deletedProperty)

      this.isModalVisible = false
      this.isDeleteVisible = false
      this.isDuplicateVisible = false
      this.liegenschaftsinformationenDialogVisible = false
      this.grundbuchauszugDialogVisible = false
      this.geoViewerDialogVisible = false
      this.selected = []
      this.deletedProperty = []
      this.duplicatedProperty = []
      this.allSelected = false
    },
    selectAll() {
      if (this.allSelected) {
        this.selected = Object.keys(this.allProperties)
        this.editDisabled = true
      } else {
        this.selected = []
        this.editDisabled = false
      }
    },
    editProp() {
      if (this.selected[0]) {
        this.$router.push('/property/' + this.selected[0].prim_uid)
      }
    },
    handleStatusClick(property, clickedSubText) {
      if (property.status === 'draft' && (this.canEditProperty || this.canAddProperty)) {
        this.releasePropVisible = true
        this.invokeReference('statusForm_client_comment')
        this.releasePropUid = property.prim_uid
      } else if (
        property.status === 'data_requested' &&
        clickedSubText &&
        (this.canEditProperty || this.canAddProperty)
      ) {
        this.dataRequestCompleted(property.prim_uid)
      } else if (property.status === 'approval_pending' && property.declaration_uid) {
        if (this.canReleaseDeclaration) {
          this.$router.push('/declaration/' + property.declaration_uid)
        } else {
          this.showToast({
            message: this.$t('general.errors.noAccess'),
            toastType: 'error',
          })
        }
      } else if (property.status === 'processing') {
        this.$router.push('/property/' + property.prim_uid)
      } else if (
        property.status === 'internal_approval_requested' ||
        property.status === 'elster_submission' ||
        property.status === 'elster_completed'
      ) {
        this.propertyLockingInfoUid = property.prim_uid
        this.propertyLockingTitle = this.$t('dashboard.property_processing_info.title')
        this.propertyLockingMessage = this.$t('dashboard.property_processing_info.internal_approval_requested_message')
        if (property.status === 'elster_submission') {
          this.propertyLockingMessage = this.$t('dashboard.property_processing_info.elster_submission_message')
        } else if (property.status === 'elster_completed') {
          this.propertyLockingMessage = this.$t('dashboard.property_processing_info.elster_completed_message')
        }
        this.showPropertyLockingInfo = true
      }
    },
    hidePropertyLockingInfoDialog() {
      this.showPropertyLockingInfo = false
    },
    viewProperty() {
      this.$router.push('/property/' + this.propertyLockingInfoUid)
    },
    doReleaseProperty() {
      let clientComment = ''
      if (typeof this.statusForm.client_comment === 'string') {
        clientComment = this.statusForm.client_comment.trim()
        this.statusForm.client_comment = ''
      }
      this.shareProperty({ id: this.releasePropUid, client_comment: clientComment })
      this.releasePropVisible = false
      this.releasePropUid = ''
    },
    doReleasePropertyIfEmptyComment() {
      if (this.statusForm.client_comment.trim() === '') {
        this.doReleaseProperty()
      }
    },
    cancelReleaseProperty() {
      this.releasePropVisible = false
      this.releasePropUid = ''
      this.statusForm.client_comment = ''
    },
    removeAllSelect() {
      this.allSelected = false
      this.editDisabled = false
      var propertyCount = Object.keys(this.allProperties).length
      var selectedCount = Object.keys(this.selected).length
      if (propertyCount == selectedCount) {
        this.allSelected = true
      }
    },

    propertyHasAddress(property) {
      let myResult = false
      if (typeof property === 'object') {
        myResult =
          (typeof property.street === 'string' && property.street.trim() !== '') ||
          (typeof property.house_number === 'string' && property.house_number.trim() !== '') ||
          (typeof property.zip === 'string' && property.zip.trim() !== '') ||
          (typeof property.city === 'string' && property.city.trim() !== '')
      }
      return myResult
    },

    propertyAddressLine1(property) {
      let separator = ', '
      let myAddressLine1 = ''
      let myStreet = ''
      let myHouseNumber = ''
      let myZip = ''
      let myStreetComplete = ''

      if (this.propertyHasAddress(property)) {
        if (typeof property.street === 'string' && property.street.trim() !== '') {
          myStreet = property.street.trim()
        }
        if (typeof property.house_number === 'string' && property.house_number.trim() !== '') {
          myHouseNumber = property.house_number.trim()
        }
        if (typeof property.zip === 'string' && property.zip.trim() !== '') {
          myZip = property.zip.trim()
        }
        myStreetComplete = (myStreet + ' ' + myHouseNumber).trim()

        if (myStreetComplete === '' || myZip === '') {
          separator = ''
        }
        myAddressLine1 = myStreetComplete + separator + myZip
      }

      return myAddressLine1
    },

    propertyAddressLine2(property, separator) {
      let myAddressLine2 = ''
      let myCity = ''
      let myFederalStateName = ''
      let mySeparator = ' '

      if (typeof property === 'object') {
        if (typeof property.city === 'string' && property.city.trim() !== '') {
          myCity = property.city.trim()
        }
        if (typeof property.federal_state_name === 'string' && property.federal_state_name.trim() !== '') {
          myFederalStateName = property.federal_state_name.trim()
        }
        if (typeof separator === 'string') {
          mySeparator = separator
        }

        if (myCity === '' || myFederalStateName === '') {
          mySeparator = ''
        }

        myAddressLine2 = (myCity + mySeparator + myFederalStateName).trim()
      }

      return myAddressLine2
    },

    propertyAddressLine(property) {
      return (this.propertyAddressLine1(property) + ' ' + this.propertyAddressLine2(property, ', ')).trim()
    },

    editPropFun(iseditable, isviewable, property) {
      if (iseditable) {
        if (this.canReleaseDeclaration && property.status === 'approval_pending' && property.declaration_uid) {
          this.$router.push('/declaration/' + property.declaration_uid)
        } else {
          this.$router.push('/property/' + property.prim_uid)
        }
      } else {
        if (isviewable) {
          this.$router.push('/property/' + property.prim_uid)
        }
      }
    },
    editDeclarationProp(iseditable, declaration) {
      this.$router.push('/property/' + declaration.propertyId)
    },
    invokeReference(ref) {
      this.$nextTick(() => {
        if (
          typeof this.$refs !== 'undefined' &&
          typeof this.$refs[ref] !== 'undefined' &&
          typeof this.$refs[ref].focus === 'function'
        ) {
          this.$refs[ref].focus()
        }
      })
    },

    getDashboardPropertyTypeNameAndImage(
      federalStateUid,
      propertyType,
      propertyExemptionBenefitType,
      economicEntityType,
    ) {
      let myPropertyType = {
        name: this.$t('dashboard.unknownPropertyTypeName'),
        image: 'etwas_anderes.png',
      }

      let numberFederalStateUid = 0
      let isCalculated = false

      // federal state uid must have a value > 0
      if (typeof federalStateUid !== 'undefined') {
        if (typeof federalStateUid === 'string') {
          numberFederalStateUid = parseInt(federalStateUid, 10)
        } else if (typeof federalStateUid === 'number') {
          numberFederalStateUid = federalStateUid
        }

        // propertyType name and image can only get evaluated if federalStateUid is set
        if (!Number.isNaN(numberFederalStateUid) && numberFederalStateUid > 0) {
          //use propertyExemptionBenefitType if federalStateUid is "BY" or "NI (GRDDEV-1944)
          if (numberFederalStateUid === 2 || numberFederalStateUid === 9) {
            let numberPropertyExemptionBenefitType = 0
            if (typeof propertyExemptionBenefitType !== 'undefined') {
              if (typeof propertyExemptionBenefitType === 'string') {
                numberPropertyExemptionBenefitType = parseInt(propertyExemptionBenefitType, 10)
              } else if (typeof propertyExemptionBenefitType === 'number') {
                numberPropertyExemptionBenefitType = propertyExemptionBenefitType
              }
              if (!Number.isNaN(numberPropertyExemptionBenefitType) && numberPropertyExemptionBenefitType > 0) {
                let arrOptions = this.property_exemption_benefit_type_options()
                let foundElement = arrOptions.find(
                  (element) => element.code === numberPropertyExemptionBenefitType.toString(10),
                )
                if (typeof foundElement !== 'undefined') {
                  myPropertyType.name = foundElement.name
                  myPropertyType.image = foundElement.image
                  isCalculated = true
                }
              }
            }
          }
          //use propertyType for all federalStateUids except "BY", "NI" (GRDDEV-1944)
          else {
            let numberPropertyType = 0
            if (typeof propertyType !== 'undefined') {
              if (typeof propertyType === 'string') {
                numberPropertyType = parseInt(propertyType, 10)
              } else if (typeof propertyType === 'number') {
                numberPropertyType = propertyType
              }
              if (!Number.isNaN(numberPropertyType) && numberPropertyType > 0) {
                let myTaxModelObj = []
                let myPropertyTypeOptions = []
                let arrOptions = []

                // Get State Tax Model
                myTaxModelObj = this.taxModels.filter((item) => {
                  if (item.stateCodes.includes(numberFederalStateUid.toString(10))) {
                    return item
                  }
                })

                myPropertyTypeOptions = tm('property_type_options.Bundesmodell')
                if (typeof myTaxModelObj[0] !== 'undefined' && typeof myTaxModelObj[0].model !== 'undefined') {
                  if (myTaxModelObj[0].model !== '') {
                    myPropertyTypeOptions = tm('property_type_options.' + myTaxModelObj[0].model)
                  }
                }

                for (let k of myPropertyTypeOptions) {
                  arrOptions.push({
                    code: this.$rt(k.code),
                    name: this.$rt(k.name),
                    image: this.$rt(k.image),
                  })
                }

                let foundElement = arrOptions.find((element) => element.code === numberPropertyType.toString(10))
                if (typeof foundElement !== 'undefined') {
                  myPropertyType.name = foundElement.name
                  myPropertyType.image = foundElement.image
                  isCalculated = true
                }
              }
            }
          }

          //if propertyType or propertyExemptionBenefitType is not set, build property label and info from economicEntityType
          if (!isCalculated) {
            if (typeof economicEntityType !== 'undefined') {
              let numberEconomicEntityType = 0
              if (typeof economicEntityType === 'string') {
                numberEconomicEntityType = parseInt(economicEntityType, 10)
              } else if (typeof economicEntityType === 'number') {
                numberEconomicEntityType = economicEntityType
              }
              if (!Number.isNaN(numberEconomicEntityType) && numberEconomicEntityType > 0) {
                let myEconomicEntityTypeOptions = []
                let arrOptions = []

                if (numberFederalStateUid === 1) {
                  myEconomicEntityTypeOptions = tm('economic_entity_types.Baden-Württemberg')
                } else {
                  myEconomicEntityTypeOptions = tm('economic_entity_types.default')
                }

                for (let k of myEconomicEntityTypeOptions) {
                  arrOptions.push({
                    code: this.$rt(k.code),
                    name: this.$rt(k.name),
                    image: this.$rt(k.image),
                  })
                }

                let foundElement = arrOptions.find((element) => element.code === numberEconomicEntityType.toString(10))
                if (typeof foundElement !== 'undefined') {
                  myPropertyType.name = foundElement.name
                  myPropertyType.image = foundElement.image
                  isCalculated = true
                }
              }
            }
          }
        }
      }

      return myPropertyType
    },

    property_exemption_benefit_type_options() {
      let resultArray = []
      let myOptions = this.$tm('newProperty.propertyBenefits.property_exemption_benefit_type_options')
      for (let k of myOptions) {
        resultArray.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
          image: this.$rt(k.image),
        })
      }
      return resultArray
    },
  },
}
</script>
<style scoped lang="css">
@import './assets/custom.css';
@import './assets/form_style.css';

:root {
  --api-background: #43ac6f;
}

.isDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.topspace60 {
  margin-top: 20px;
}

h4.heading_title {
  padding-bottom: 20px;
}

a.button-style-new,
button.button-style-new {
  background: #229d56;
  border: 0;
  border-radius: 0;
  color: #fff;
  display: block;
  height: 40px;
  line-height: 40px;
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.modal-backdrop .modal {
  border-top: 5px solid #229d56;
}

.__Modal_Popup .modal {
  height: auto !important;
}

.popup_title h2 {
  color: #333;
  font-size: 26px;
}

.tab-border-home p {
  font-size: 16px;
}

.tab-border-home {
  border: none;
}

.select2_input a button:hover {
  background: #229d56;
  color: #fff;
  font-weight: 700;
}

.select2_input a button,
.select2_input button {
  background: #fff;
  border: 1px solid #ccc;
  font-size: 15px;
  margin: 5px 5px 5px;
  padding: 10px 15px;
}

.select2_input {
  text-align: left;
}

.select2_input + .button-style01:hover {
  background: #ccc;
  border-color: #ccc;
}

.select2_input + .button-style01 {
  background: none;
  border-color: #333;
  color: #333;
  line-height: 20px;
  margin-left: auto;
  margin-top: 30px;
  padding: 10px 0;
  width: 280px;
}

.tab-border-home.tab-top-height {
  height: auto;
}

.tab-border-home.tab-top-height.textformatting {
  padding: 0 0 0 0 !important;
}

.btn_170 {
  display: flex;
  justify-content: flex-end;
  margin-left: auto !important;
  margin-right: -15px;
}

button.button-style:first-child {
  border: none !important;
}

button.button-style.red_btn:hover {
  background: #d55959 !important;
  border-color: #d55959;
  color: #fff;
  filter: brightness(0.8);
}

button.button-style.btn_line:hover {
  background: #bbbbbb;
  border-color: #bbb;
}

.Search_Titzle.Search_Titzle02.Titzle02_mob {
  padding-left: 0;
}

section.section {
  margin-bottom: 100px;
  min-height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Search_Titzle input[type='text'] {
    margin-left: 20px !important;
  }

  .footer-space {
    min-height: 100px !important;
  }

  .icon-div {
    width: 30% !important;
  }

  .new-notifications .col-lg-6 {
    width: 100%;
  }
}

@media only screen and (min-width: 100px) and (max-width: 768px) {
  .icon-div {
    width: 20%;
  }

  .icon-cnt-div {
    width: 80%;
  }

  .footer-space {
    min-height: 100px !important;
  }

  .Search_Titzle input[type='text'] {
    margin-left: 5px !important;
  }

  .mb_300 {
    max-width: 100% !important;
  }

  .mb_300 img {
    margin-right: 20px !important;
    max-width: 22px !important;
  }
}

.spiner_center_over.dashbord-spiner {
  background: #ffffff;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 11;
}

.dashbord-spiner .spiner_center {
  height: auto;
  left: 50%;
  margin: 0 auto;
  position: absolute !important;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.new-notifications {
  position: relative;
}

.dashbord-spiner:before {
  display: none;
}

.emptyDashboardImage {
  max-height: 250px;
  max-width: 250px;
}

.modal-content {
  align-content: center;
  align-items: center;
  background: #f2f2f2;
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 14px;
  padding: 5px;
}

.modal-button-row {
  align-items: stretch;
  border: none;
  display: flex;
  font-size: 14px;
  padding: 5px;
}

.modal-button-left {
  align-self: flex-start;
  margin-left: 0 !important;
}

.modal-button-right {
  margin-left: auto;
}

.modal-content-div {
  padding: 5px;
}

.modal-content-headline {
  font-weight: 600;
}

.modal-icon {
  padding: 10px;
}

.modal-img {
  height: 18px;
  width: 18px;
}

.modal-button {
  background: none;
  border-color: #333;
  color: #333;
  line-height: 20px;
  margin-left: auto;
  margin-top: 30px;
  padding: 10px 0;
  width: 280px;
}

.modal-button:hover {
  background: #ccc;
  border-color: #ccc;
  color: #333;
}

button.btnactive {
  border: 1px solid var(--api-background);
}

.btn_cancel button.border-button-style {
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 170px;
  width: 100%;
}

.check-icon-text {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  font-weight: 400;
  padding-top: 5px;
}

span.red-title {
  width: 85%;
}

.gray_iocn span,
.graycolor span,
.graycolor {
  color: #afaeaf !important;
}

.form-group {
  padding-bottom: 25px;
}

.comment_field_label {
  margin-top: 0;
  padding-bottom: 5px;
}

.comment-area {
  height: 5em;
  resize: none;
  width: 100%;
}
.filter_menu:after {
  display: none;
}
.filter_menu {
  border: 1px solid #bfbfbf;
  border-radius: 0;
  margin: 0 5px;
}
.filter_menu_dropdown ul {
  padding-left: 5px;
}
.filter_menu_dropdown ul li {
  cursor: pointer;
  list-style: none;
  margin-top: 5px;
  text-decoration: underline;
  white-space: nowrap;
}
.filter_menu_dropdown ul li span {
  margin-left: 10px;
}
.filter_menu_dropdown {
  width: auto !important;
}
</style>
