// we need our modal component

import AppModal from '../components/Dialog'

const Modal = {
  // every plugin for Vue.js needs install method
  // this method will run after Vue.use(<your-plugin-here>) is executed
  install(app) {
    // We must create new Eventbus
    // which is just another Vue instance that will be listening for and emiting events from our main instance

    // making our modal component global
    app.component('app-modal', AppModal)

    // exposing global $modal object with method show()
    // method show() takes object params as argument
    // inside this object we can have modal title, text, styles... and also our callback confirm function
    app.config.globalProperties.$modal = {
      show(params) {
        // if we use this.$modal.show(params) inside our original Vue instance
        // we will emit 'show' event with parameters 'params'
        app.config.globalProperties.$bus.emit('show', params)
        app.config.globalProperties.$store.dispatch('setDialog', params)
      },
    }
  },
}

export { Modal }
