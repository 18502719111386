export const defaultPropertyOwnership = {
  owners: [
    {
      id: 0, //internal data, no form edit
      salutation: '0',
      title: '',
      dateOfBirth: null,
      firstName: '',
      lastName: '',
      street: '',
      houseNumber: '',
      phone: '',
      zip: '',
      postbox: '',
      city: '',
      federalStateUid: '0',
      countryUid: '0',
      financeAgency: '',
      taxNumber: '',
      taxIdentificationNumber: '',
      area_of_the_land_counter: '',
      area_of_the_land_denominator: '',
      is_legal_representative: '0',
      representative: {
        salutation: '0',
        title: '',
        dateOfBirth: null,
        firstName: '',
        lastName: '',
        street: '',
        houseNumber: '',
        phone: '',
        zip: '',
        postbox: '',
        city: '',
        countryUid: '0',
      },
    },
  ],
}
