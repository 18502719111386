export const defaultPropertyAgriculture = {
  totalExemption: -1,
  exemptionNonDelimitablePart: -1,
  gemeindes: [
    {
      id: 0,
      federal_state_uid: '0',
      community: '',
      description: '',
      parcels: [
        {
          id: 0,
          corridor: '',
          landRegisterSheet: '',
          counter: '',
          denominator: '',
          areaOfTheLand: '',
          district: '',
          district_number: '',
          isReference: 0,
          usageTypes: [
            {
              id: 0,
              usageType: '0',
              areaOfTheLand: '',
              yieldMeasurementNumber: '',
              areaOfFarmBuildings: '',
              flowRate: '',
              exemption: '',
              exemption_area: '',
            },
          ],
        },
      ],
    },
  ],
}
