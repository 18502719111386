const RoometricPrimaryColor = '#116dff'

function isRoometricActive() {
  let result = false

  if (
    typeof process.env.VUE_APP_SHOW_ROOMETRIC === 'string' &&
    process.env.VUE_APP_SHOW_ROOMETRIC.toLowerCase() === 'true'
  ) {
    result = true
  }

  return result
}

function getRoometricUrl() {
  let result = ''

  if (typeof process.env.VUE_APP_ROOMETRIC_URL === 'string') {
    result = process.env.VUE_APP_ROOMETRIC_URL
  }

  return result
}

export { RoometricPrimaryColor, isRoometricActive, getRoometricUrl }
