<template>
  <!-- Dashboard  Start -->
  <section
    class="login_form section"
    v-show="!isloading"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-left">
          <div class="sec-heading">
            <h4 class="heading_title">
              {{ $t('InvitationTC.title_prefix') }}{{ clientUserSalutation }}{{ $t('InvitationTC.title_postfix') }}
            </h4>
            <p class="p-content">{{ $t('InvitationTC.sub_line') }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 offset-md-4">
        <div class="dashbord-content topspace60">
          <h2 class="heading_title centertitle">{{ $t('InvitationTC.heading_title') }}</h2>
          <div class="row">
            <form>
              <label
                class="label-title"
                for="username"
                >{{ $t('InvitationTC.name') }}</label
              >
              <input
                type="disabled"
                class="form-control input-field"
                v-model="form.username"
                disabled
                id="username"
              />
              <span
                class="error"
                v-if="form.errors().has('username')"
              >
                {{ form.errors().get('username') }}
              </span>
              <label class="label-title">{{ $t('InvitationTC.pwd') }}</label>
              <Password
                inputClass="w-100"
                v-model="form.password"
                toggleMask
                :prompt-label="$t('passwordTemplate.promptLabel')"
                placeholder="Passwort eingeben"
                :strong-regex="strongRegex"
              >
                <template #header>
                  <h6>{{ $t('passwordTemplate.title') }}</h6>
                </template>
                <template #footer>
                  <Divider />
                  <div
                    class="p-pl-2 p-ml-2 p-mt-0"
                    style="line-height: 1.5"
                  >
                    <div class="flex items-center justify-start">
                      <img
                        class="validation-img"
                        :src="getMinimumValidationImage(form.password)"
                      />
                      <label :style="{ color: minimumError ? '#229D56' : '#A4262C' }">
                        {{ $t('passwordTemplate.text0') }}
                      </label>
                    </div>
                    <div class="flex items-center justify-start">
                      <img
                        class="validation-img"
                        :src="getLowercaseValidationImage(form.password)"
                      />
                      <label :style="{ color: lowercaseError ? '#229D56' : '#A4262C' }">
                        {{ $t('passwordTemplate.text1') }}
                      </label>
                    </div>
                    <div class="flex items-center justify-start">
                      <img
                        class="validation-img"
                        :src="getUppercaseValidationImage(form.password)"
                      />
                      <label :style="{ color: uppercaseError ? '#229D56' : '#A4262C' }">
                        {{ $t('passwordTemplate.text2') }}
                      </label>
                    </div>
                    <div class="flex items-center justify-start">
                      <img
                        class="validation-img"
                        :src="getNumericValidationImage(form.password)"
                      />
                      <label :style="{ color: numericError ? '#229D56' : '#A4262C' }">
                        {{ $t('passwordTemplate.text3') }}
                      </label>
                    </div>
                    <div class="flex items-center justify-start">
                      <img
                        class="validation-img"
                        :src="getDifferentCharValidationImage(form.password)"
                      />
                      <label :style="{ color: threeCharError ? '#229D56' : '#A4262C' }">
                        {{ $t('passwordTemplate.text4') }}
                      </label>
                    </div>
                    <div class="flex items-center justify-start">
                      <img
                        class="validation-img"
                        :src="getDifferentUserNameValidationImage(form.password)"
                      />
                      <label :style="{ color: usernameError ? '#229D56' : '#a72121' }">
                        {{ $t('passwordTemplate.text5') }}
                      </label>
                    </div>
                  </div>
                </template>
              </Password>

              <!-- <label class="label-title" for="passwort">{{ $t('InvitationTC.pwd') }}</label>
              <input type="password" class="form-control input-field" v-model='form.password' id="passwort"
                     placeholder="Passwort eingeben"> -->
              <span
                class="error"
                v-if="form.errors().has('password') || passError"
              >
                {{ form.errors().get('password') }}
              </span>
              <span
                class="error"
                v-if="passError"
              >
                {{ $t('mydata.pwErrorMinLen') }}
              </span>

              <label class="label-title">{{ $t('InvitationTC.cnfmpwd') }}</label>
              <Password
                inputClass="w-100"
                v-model="form.password_confirmation"
                :feedback="false"
                toggleMask
                placeholder="Passwort nochmal eingeben"
              ></Password>
              <span
                class="error"
                v-if="conPassError"
              >
                {{ $t('InvitationTC.pwErrorConfirm') }}
              </span>

              <div v-show="mfaMode">
                <label
                  class="label-title"
                  for="Code"
                  >{{ $t('LoginNoMobPwdVhan.mfa_code') }}</label
                >
                <div class="mfa_info">
                  <!-- <img src="@/assets/note.svg" alt=""/> -->
                  <Svgall
                    class="modal-note"
                    :name="'info'"
                    :color="infocolor"
                  />
                  <span> {{ $t('InvitationTC.infoAlreadySent') }} </span>
                </div>
                <input
                  ref="code"
                  type="text"
                  autocomplete="off"
                  class="form-control input-field"
                  id="Code"
                  v-model="form.mfa_code"
                  :placeholder="$t('LoginNoMobPwdVhan.mfa_code')"
                />
                <span
                  class="red_txt float-start"
                  v-show="codeLifetime > 0"
                  >{{ $t('LoginNoMobPwdVhan.expires_in') }} {{ getExpiryTime }}</span
                >
                <a
                  href="javascript:void(0)"
                  v-show="!mfaCodeSent"
                  class="a-style"
                  @click="sendResetPwdMfaCode()"
                  >{{ $t('LoginNoMobPwdVhan.send_sms') }}</a
                >
                <a
                  href="javascript:void(0)"
                  v-show="mfaCodeSent && codeLifetime > 0"
                  class="a-style"
                  @click="sendResetPwdMfaCode()"
                  >{{ $t('LoginNoMobPwdVhan.send_sms_again') }}</a
                >
              </div>

              <div class="return-further">
                <button
                  @click="$router.push('/')"
                  type="button"
                  class="button-style"
                >
                  {{ $t('InvitationTC.return') }}
                </button>
                <button
                  type="submit"
                  :style="[primaryColor ? { background: primaryColor } : '']"
                  class="button-style"
                  @click.stop.prevent="submit"
                >
                  {{ $t('InvitationTC.further') }}
                </button>
              </div>
              <div
                class="row mb-3 mt-3"
                v-if="datevLoginEnabled"
              >
                <div class="col-md-12 text-center">
                  <p>{{ $t('general.uploader.or') }}</p>
                  <img
                    src="@/assets/datev_login.png"
                    class="clickable datev_login"
                    @click="startDatevLogin()"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import Password from 'primevue/password'
import Divider from 'primevue/divider'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { Password, Divider },
  data: () => ({
    passError: false,
    conPassError: false,
    isloading: false,
    lowercaseError: false,
    uppercaseError: false,
    numericError: false,
    minimumError: false,
    threeCharError: false,
    usernameError: false,
    strongRegex: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{9,})',
    clientUserSalutation: '',
    mfaCodeSent: false,
    mfaMode: '',
    infocolor: '',
    datevLoginEnabled: false,
    codeLifetime: 0,
    primaryColor: '#464747',
    form: form({
      username: '',
      password: '',
      password_confirmation: '',
      mfa_code: '',
    }),
  }),

  created() {
    this.mfaMode = ''
    this.loadInvitationInfo()
    this.isloading = true
    this.infocolor = '#000000'
  },
  computed: {
    ...mapGetters(['getInvitationInfo', 'getCurrentUser']),
    getExpiryTime() {
      if (this.codeLifetime) {
        let d = new Date(0)
        d.setSeconds(this.codeLifetime)
        return d.toISOString().substr(14, 5)
      } else {
        return ''
      }
    },
  },
  watch: {
    getInvitationInfo(val) {
      if (val !== '') {
        this.sendResetPwdMfaCode()
      }
      this.primaryColor = val.primaryColor ? val.primaryColor : '#464747'
      this.infocolor = this.primaryColor
      document.body.style.setProperty('--api-background', this.primaryColor)
      document.body.style.setProperty('--mfainfo-background', this.hexToRgbA(this.primaryColor, 0.25))

      this.setUsername(val)
      this.setClientUserSalutation(val)
      if (val.mfaMode) {
        this.mfaMode = val.mfaMode
      }
      this.datevLoginEnabled = val.taxConsultant.enableDatevLogin && val.taxConsultant.enableDatevLogin === true
      this.isloading = false
    },
  },

  methods: {
    ...mapActions([
      'actionTCInvitationInfo',
      'tcInvitationSetPassPost',
      'encrypt',
      'sendMfaCode',
      'showToast',
      'datevLogin',
    ]),
    loadInvitationInfo() {
      let data = {
        code: this.$route.query.code,
        validate_code: 1,
      }
      this.actionTCInvitationInfo(data)
    },
    setUsername(val) {
      this.form.username = val.uid
    },
    setClientUserSalutation(val) {
      this.clientUserSalutation = val.firstName + ' ' + val.lastName
      if (val.salutation && val.salutation !== 1) {
        this.clientUserSalutation = this.$t('salutation.' + val.salutation) + ' ' + this.clientUserSalutation
      }
    },

    async submit() {
      if (this.form.validate().errors().any()) return

      let pass = this.form.password
      let conPass = this.form.password_confirmation
      this.passError = pass === '' || pass.length < 8
      if (this.passError) {
        return
      }
      this.conPassError = conPass === '' || conPass.length < 8 || conPass != pass
      if (this.conPassError) {
        return
      }

      let data = {
        password: await this.encrypt(this.form.password),
        confirm_password: await this.encrypt(this.form.password_confirmation),
        code: this.$route.query.code,
      }
      if (this.mfaMode) {
        data['mfa_code'] = this.form.mfa_code
      }
      this.tcInvitationSetPassPost(data)
    },
    getMinimumValidationImage(field) {
      if (field.length >= 9) {
        this.minimumError = true
        return require('@/assets/tick_password.svg')
      } else {
        this.minimumError = false
        return require('@/assets/cross_password.svg')
      }
    },
    getLowercaseValidationImage(field) {
      if (/[a-z]/.test(field)) {
        this.lowercaseError = true
        return require('@/assets/tick_password.svg')
      } else {
        this.lowercaseError = false
        return require('@/assets/cross_password.svg')
      }
    },
    getUppercaseValidationImage(field) {
      if (/[A-Z]/.test(field)) {
        this.uppercaseError = true
        return require('@/assets/tick_password.svg')
      } else {
        this.uppercaseError = false
        return require('@/assets/cross_password.svg')
      }
    },
    getNumericValidationImage(field) {
      if (/\d/.test(field)) {
        this.numericError = true
        return require('@/assets/tick_password.svg')
      } else {
        this.numericError = false
        return require('@/assets/cross_password.svg')
      }
    },
    getDifferentCharValidationImage(field) {
      if (/([\s\S])(?!\1\1)[\s\S]{2}/.test(field)) {
        this.threeCharError = true
        return require('@/assets/tick_password.svg')
      } else {
        this.threeCharError = false
        return require('@/assets/cross_password.svg')
      }
    },
    getDifferentUserNameValidationImage(field) {
      if (this.getInvitationInfo.email !== field) {
        this.usernameError = true
        return require('@/assets/tick_password.svg')
      } else {
        this.usernameError = false
        return require('@/assets/cross_password.svg')
      }
    },
    async sendResetPwdMfaCode() {
      let loginData = {}
      loginData.resend = this.mfaCodeSent ? 1 : 0
      loginData.resetPwdCode = this.$route.query.code
      this.sendMfaCode(loginData).then((response) => {
        if (response && response.data.success) {
          if (this.mfaMode === 'SMS') {
            this.showToast({
              message: this.$t('LoginNoMobPwdVhan.sms_code_sent').replace('%num%', response.data.phone_last_4),
              toastType: 'success',
            })
          } else if (this.mfaMode === 'EMAIL') {
            this.showToast({
              message: this.$t('LoginNoMobPwdVhan.mfa_code_sent'),
              toastType: 'success',
            })
          }
          this.mfaCodeSent = true
          if (response.data.expires_in && this.codeLifetime === 0) {
            this.codeLifetime = response.data.expires_in
            this.mfaCodeTimer()
          }
        }
      })
    },
    mfaCodeTimer() {
      if (this.codeLifetime > 0) {
        setTimeout(() => {
          this.codeLifetime -= 1
          this.mfaCodeTimer()
        }, 1000)
      }
    },
    startDatevLogin() {
      this.datevLogin({
        process: 'datevAccountSetup',
        datevAccountSetupCode: this.$route.query.code,
      })
    },
    hexToRgbA(hex, A) {
      var c
      if (typeof A !== 'number' || A < 0 || A > 1) {
        A = '1'
      }
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + A.toString() + ')'
      } else {
        return 'rgba(0,0,0,' + A.toString() + ')'
      }
    },
  },
}
</script>
<style scoped lang="css">
@import './assets/custom.css';
@import './assets/form_style.css';
:root {
  --mfainfo-background: none;
  --api-background: #43ac6f;
}

.mfa_info {
  align-items: center;
  background: var(--mfainfo-background);
  color: #333;
  display: flex;
  font-size: 14px;
  gap: 15px;
  margin-bottom: 15px;
  padding: 10px;
}
.return-further {
  margin: 40px 0 20px !important;
  max-width: 100% !important;
}

.topspace60 {
  margin-top: 20px;
}

h4.heading_title {
  padding-bottom: 20px;
}
.validation-img {
  height: 12px;
  margin-right: 5px;
  width: 12px;
}

a.a-style {
  color: var(--api-background);
  display: block;
  font-size: 15px;
  text-align: right;
  width: 100%;
}
.datev_login {
  height: 42px;
  width: 136px;
}
button.button-style:hover {
  background: var(--api-background) !important;
}
button.button-style:first-child:hover {
  background: #bbbbbb !important;
}
.w-100 {
  width: 100%;
}
</style>
