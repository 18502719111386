<template>
  <Header />
  <div class="login_start">
    <MyData />
  </div>
  <Copyright />
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import MyData from '@/components/MyData.vue'
import Copyright from '@/components/copyright.vue'

export default {
  name: 'Home',
  components: {
    Header,
    MyData,
    Copyright,
  },
}
</script>
