<template>
  <router-view
    v-if="!authVerifying"
    :key="$route.fullPath"
  >
  </router-view>
  <Spinner v-show="showPageLoader" />
  <ModalDialog
    v-show="isDeployModalVisible"
    class="maintenance"
    modalSize="full-screen"
    :showClose="false"
  >
    <template v-slot:body>
      <div class="flex h-full flex-col items-center justify-center">
        <div class="-mt-10 flex w-full flex-col items-center">
          <img
            :src="require('@/assets/system_update.svg')"
            class="update_icon"
          />
          <p>{{ $t('general.sys_update_message') }}</p>
        </div>
      </div>
    </template>
  </ModalDialog>
  <ModalDialog
    v-show="isDeployModalVisible"
    class="maintenance"
    modalSize="full-screen"
    :showClose="false"
  >
    <template v-slot:body>
      <div class="flex h-full flex-col items-center justify-center">
        <div class="-mt-10 flex w-full flex-col items-center">
          <img
            :src="require('@/assets/system_update.svg')"
            class="update_icon"
          />
          <p>{{ $t('general.sys_update_message') }}</p>
        </div>
      </div>
    </template>
  </ModalDialog>
  <ModalDialog
    class="modal_wide modal_wide_cancel"
    v-show="isversionChanged"
    :showClose="false"
  >
    <template v-slot:body>
      <div class="textfileformating textfileformating02">
        <div class="filemin_cont cancel-popup">
          <div class="modal-text">
            <span>
              {{ $t('general.new_version_info') }}
            </span>
          </div>
        </div>
        <div class="btn_170 btn_cancel">
          <!-- <button class="border-button-style" @click="cancelProp('Nein')">
            {{ $t('newProperty.no') }}
          </button> -->
          <button
            class="modal-button"
            @click="reload"
          >
            {{ $t('general.ok') }}
          </button>
        </div>
      </div>
    </template>
  </ModalDialog>
  <MyDialog
    v-show="isDialogOpen"
    :dialogData="dialogData"
  />
</template>

<script>
import Spinner from '@/components/SpinnerLoader'
import ModalDialog from '@/components/ModalDialog.vue'
import MyDialog from '@/components/Dialog.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    Spinner,
    ModalDialog,
    MyDialog,
  },
  data() {
    return {
      isDeployModalVisible: false,
      isversionChanged: false,
      authVerifying: false,
      isDialogOpen: false,
      primaryColor: '',
      fontColor: '',
      dialogData: {},
      actionsWoutSessionCheck: ['/', '/resetPassword', '/404'],
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'isPageLoading', 'getRecordUpdatedTime', 'getRecordChangeInfo']),
    showPageLoader() {
      return this.isPageLoading || this.authVerifying
    },
  },
  watch: {
    getCurrentUser() {
      this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#43ac6f'
      this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.fontColor : '#4b4b4b'
      let bodyStyle = document.body.style
      bodyStyle.setProperty('--api-background', this.primaryColor)
      if (this.fontColor) {
        bodyStyle.setProperty('--api-font', this.fontColor)
      }
    },
    getRecordUpdatedTime(newValue, oldValue) {
      if (newValue > 0 && oldValue > 0 && newValue > oldValue) {
        let params = {
          headerText: this.$t('general.record_observer.title'),
          title: this.$t('general.record_observer.info', { username: this.getRecordChangeInfo.userName }),
          showClose: true,
          canEscape: true,
          showCancel: true,
          onConfirm: () => {
            this.refreshRecord()
          },
          buttons: {
            ok: this.$t('general.record_observer.reload'),
            cancel: this.$t('general.record_observer.overwrite'),
            delete: false,
          },
        }

        if (this.getRecordChangeInfo.userName === 'SYSTEM') {
          params.title = this.$t('general.record_observer.systemInfo')
          params.showCancel = false
        }

        this.$modal.show(params)
      }
    },
  },
  async created() {
    this.authVerifying = true

    // Datev response handler
    let url = new URL(window.location.href)
    let code = url.searchParams.get('code')
    if (code !== null && code !== '') {
      await this.datevLogin({
        code: code,
      })
    }

    await this.$router.isReady()
    let login_token = this.$route.query.login_token ? this.$route.query.login_token : ''
    if (localStorage.getItem('client_logged_in') || login_token) {
      let payload = {
        headers: {},
      }
      if (login_token) {
        payload.headers['X-CLIENT-LOGIN-TOKEN'] = login_token
      }

      this.fetchCurrentUser(payload).then((response) => {
        this.authVerifying = false
        let redirect = !this.actionsWoutSessionCheck.includes(this.$route.path)
        if (response.data.success) {
          if (login_token !== '') {
            localStorage.setItem('client_logged_in', '1')
          }
          if (!redirect) {
            this.$router.push(sessionStorage.getItem('deepLink') || '/dashboard')
          }
          sessionStorage.removeItem('deepLink')
        } else if (redirect) {
          this.$router.push('/')
        }

        if (!response.data.success) {
          localStorage.removeItem('client_logged_in')
        }
      })
    } else {
      this.authVerifying = false
    }
  },
  mounted() {
    // setup observer
    this.observer()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#43ac6f'
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.fontColor : '#4b4b4b'
    let bodyStyle = document.body.style
    bodyStyle.setProperty('--api-background', this.primaryColor)
    if (this.fontColor) {
      bodyStyle.setProperty('--api-font', this.fontColor)
    }
  },
  methods: {
    ...mapActions(['fetchCurrentUser', 'observer', 'datevLogin']),
    ...mapMutations(['refreshRecord']),

    appObserver() {
      this.$api.trigger('observer', {}).then((response) => {
        if (response.data.success) {
          if (response.data.systemUpdate) {
            this.isDeployModalVisible = true
          } else if (this.isDeployModalVisible) {
            location.reload()
          } else if (response.data.versionChanged) {
            this.isversionChanged = true
          }

          if (response.data.onloadError) {
            this.$api.showToast(response.data.onloadError, 'error')
          }
        }

        setTimeout(() => this.appObserver(), 30000)
      })
    },
    reload() {
      location.reload()
    },
  },
}
</script>
<style lang="scss">
#app {
  font-family: 'Segoe UI', sans-serif !important;
}
</style>
