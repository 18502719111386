export const defaultPropertyLeasehold = {
  leaseholds: [
    {
      id: 0,
      formOfAddress: 0,
      titleAcademicDegree: '',
      telephoneNumber: '',
      firstName: '',
      lastName: '',
      street: '',
      houseNumber: '',
      zip: '',
      city: '',
      postbox: '',
      countryUid: '0',
    },
  ],
}
