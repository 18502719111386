<template>
  <section class="Step_SectionPropertyBuildings">
    <fieldset>
      <div class="Step_SectionPropertyBuildings">
        <InfoTextHeading
          :headingText="$t('newProperty.propertyBuildings.headline')"
          :infoText="$t('infoTextDisabledFields')"
        >
        </InfoTextHeading>

        <div class="form-group">
          <div class="row_setp_main">
            <div
              v-for="(object, index) in form.buildings"
              :key="index"
            >
              <!-- Gebäude(-teile) 1 -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <hr class="subheading_devide" />
                <div class="deleteIcon delete_">
                  <h5 class="subheading_title_title">
                    {{ $t('newProperty.propertyBuildings.group1_title') }} {{ index + 1 }}
                  </h5>
                  <div class="flex-button-icon">
                    <button
                      v-if="index === 0 && isRoometricActive"
                      v-on:click.stop.prevent="openRoometricDialog"
                      :style="[primaryColor ? { background: primaryColor } : '']"
                      class="button-style-for-title hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    >
                      <img
                        class="max_20"
                        src="@/assets/roometric/metrics_service.svg"
                        alt=""
                      />
                      {{ $t('roometric.buttonLabel') }}
                    </button>
                    <Svgall
                      @click="removeBuilding(index)"
                      :name="'delete'"
                      :color="primaryColor"
                      v-show="!isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    />
                  </div>
                </div>
              </div>

              <!-- Bezeichnung -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyBuildings.livingSpaceDescription') }}
                    <span class="nf_starcolor">*</span>
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.buildings[index].livingSpaceDescription"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('buildings_' + index + '_livingSpaceDescription')"
                  >
                    {{ form.errors().get('buildings_' + index + '_livingSpaceDescription') }}
                  </span>
                </div>
              </div>

              <!-- Wohnfläche in m² -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyBuildingsLivingSpaceArea_' + index)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyBuildings.livingSpaceArea') }}
                    <InfoTooltip
                      v-show="infoTooltip === 'propertyBuildingsLivingSpaceArea_' + index"
                      :content="$t('newProperty.infoTooltip.buildings.livingSpaceArea.content')"
                      :url="$t('newProperty.infoTooltip.buildings.livingSpaceArea.url')"
                    />
                  </label>
                  <input
                    type="number"
                    @keypress="onlyNumber"
                    autocomplete="off"
                    v-model="form.buildings[index].livingSpaceArea"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyBuildingsLivingSpaceArea_' + index)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('buildings_' + index + '_livingSpaceArea')"
                  >
                    {{ form.errors().get('buildings_' + index + '_livingSpaceArea') }}
                  </span>
                </div>
              </div>

              <!-- Nutzfläche in m² -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyBuildingsUsableSpaceArea_' + index)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyBuildings.usableSpaceArea') }}
                    <InfoTooltip
                      v-show="infoTooltip === 'propertyBuildingsUsableSpaceArea_' + index"
                      :content="$t('newProperty.infoTooltip.buildings.usableSpaceArea.content')"
                      :url="$t('newProperty.infoTooltip.buildings.usableSpaceArea.url')"
                    />
                  </label>
                  <input
                    type="number"
                    autocomplete="off"
                    @keypress="onlyNumber"
                    v-model="form.buildings[index].usableSpaceArea"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyBuildingsUsableSpaceArea_' + index)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('buildings_' + index + '_usableSpaceArea')"
                  >
                    {{ form.errors().get('buildings_' + index + '_usableSpaceArea') }}
                  </span>
                </div>
              </div>

              <!-- Gebäudeflächen in m², die dem Zivilschutz dienen -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.propertyBuildings.areaOfTheLandCivilDefense') }}</label>
                  <input
                    type="number"
                    autocomplete="off"
                    @keypress="onlyNumber"
                    v-model="form.buildings[index].areaOfTheLandCivilDefense"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                  <div
                    class="info-text my-2"
                    v-show="civil_defense_info_text_activated"
                  >
                    {{ $t('newProperty.propertyBuildings.civilDefenseInfoText') }}
                  </div>
                  <span
                    class="error"
                    v-if="form.errors().has('buildings_' + index + '_areaOfTheLandCivilDefense')"
                  >
                    {{ form.errors().get('buildings_' + index + '_areaOfTheLandCivilDefense') }}
                  </span>
                </div>
                <br />
              </div>

              <!-- Vergünstigung / benefits[] -->
              <div
                v-for="(object, benefitIndex) in form.buildings[index].benefits"
                :key="benefitIndex"
              >
                <!-- Vergünstigung 1 -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <hr class="subheading_devide" />
                  <div class="deleteIcon delete_">
                    <h5 class="subheading_title_inner">
                      {{ $t('newProperty.propertyBuildings.group2_title') }} {{ benefitIndex + 1 }}
                    </h5>
                    <Svgall
                      @click="removeBenefit(index, benefitIndex)"
                      :name="'delete'"
                      :color="primaryColor"
                      v-show="!isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    />
                  </div>
                </div>

                <!-- Bezeichnung -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">{{ $t('newProperty.propertyBuildings.benefitDescription') }}</label>
                    <input
                      type="text"
                      autocomplete="off"
                      v-model="form.buildings[index].benefits[benefitIndex].benefitDescription"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="
                        form.errors().has('buildings_' + index + '_benefits_' + benefitIndex + '_benefitDescription')
                      "
                    >
                      {{
                        form.errors().get('buildings_' + index + '_benefits_' + benefitIndex + '_benefitDescription')
                      }}
                    </span>
                  </div>
                </div>

                <!-- Wohnfläche in m² -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">{{ $t('newProperty.propertyBuildings.benefitLivingSpaceArea') }}</label>
                    <input
                      type="number"
                      autocomplete="off"
                      @keypress="onlyNumber"
                      v-model="form.buildings[index].benefits[benefitIndex].benefitLivingSpaceArea"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="
                        form
                          .errors()
                          .has('buildings_' + index + '_benefits_' + benefitIndex + '_benefitLivingSpaceArea')
                      "
                    >
                      {{
                        form
                          .errors()
                          .get('buildings_' + index + '_benefits_' + benefitIndex + '_benefitLivingSpaceArea')
                      }}
                    </span>
                  </div>
                </div>

                <!-- Nutzfläche in m² -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">{{ $t('newProperty.propertyBuildings.benefitUsableSpaceArea') }}</label>
                    <input
                      type="number"
                      autocomplete="off"
                      @keypress="onlyNumber"
                      v-model="form.buildings[index].benefits[benefitIndex].benefitUsableSpaceArea"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="
                        form
                          .errors()
                          .has('buildings_' + index + '_benefits_' + benefitIndex + '_benefitUsableSpaceArea')
                      "
                    >
                      {{
                        form
                          .errors()
                          .get('buildings_' + index + '_benefits_' + benefitIndex + '_benefitUsableSpaceArea')
                      }}
                    </span>
                  </div>
                </div>

                <!-- Vergünstigung -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyBuildings.benefit') }}
                    </label>
                    <select
                      v-model="form.buildings[index].benefits[benefitIndex].benefit"
                      :disabled="isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    >
                      <option
                        v-for="option in benefit_options"
                        v-bind:key="option.code"
                        :value="option.code"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <br v-show="benefitIndex < form.buildings[index].benefits.length - 1" />
              </div>

              <div
                class="col-md-7"
                v-show="exemption_activated"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="add-field __space">
                  <button
                    class="add-field-button hover-tooltip"
                    :disabled="isPropertyReadOnly"
                    @click="addBenefit(index)"
                    @focusin="setInfoTooltip(0)"
                  >
                    <Svgall
                      :name="'add_more'"
                      :color="primaryColor"
                    />
                    <span>{{ $t('newProperty.propertyBuildings.add_benefit') }}</span>
                  </button>
                </div>
              </div>

              <!-- Befreiung / exemptions[] -->
              <div
                v-for="(object, exemptionIndex) in form.buildings[index].exemptions"
                :key="exemptionIndex"
              >
                <!-- Befreiung 1 -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <hr class="subheading_devide" />
                  <div class="deleteIcon delete_">
                    <h5 class="subheading_title_inner">
                      {{ $t('newProperty.propertyBuildings.group3_title') }} {{ exemptionIndex + 1 }}
                    </h5>
                    <Svgall
                      @click="removeExemption(index, exemptionIndex)"
                      :name="'delete'"
                      :color="primaryColor"
                      v-show="!isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    />
                  </div>
                </div>

                <!-- Bezeichnung -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">{{ $t('newProperty.propertyBuildings.exemptionDescription') }}</label>
                    <input
                      type="text"
                      autocomplete="off"
                      v-model="form.buildings[index].exemptions[exemptionIndex].exemptionDescription"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="
                        form
                          .errors()
                          .has('buildings_' + index + '_exemptions_' + exemptionIndex + '_exemptionDescription')
                      "
                    >
                      {{
                        form
                          .errors()
                          .get('buildings_' + index + '_exemptions_' + exemptionIndex + '_exemptionDescription')
                      }}
                    </span>
                  </div>
                </div>

                <!-- Wohnfläche in m² -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">{{
                      $t('newProperty.propertyBuildings.exemptionLivingSpaceArea')
                    }}</label>
                    <input
                      type="number"
                      autocomplete="off"
                      @keypress="onlyNumber"
                      v-model="form.buildings[index].exemptions[exemptionIndex].exemptionLivingSpaceArea"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="
                        form
                          .errors()
                          .has('buildings_' + index + '_exemptions_' + exemptionIndex + '_exemptionLivingSpaceArea')
                      "
                    >
                      {{
                        form
                          .errors()
                          .get('buildings_' + index + '_exemptions_' + exemptionIndex + '_exemptionLivingSpaceArea')
                      }}
                    </span>
                  </div>
                </div>

                <!-- Nutzfläche in m² -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">{{
                      $t('newProperty.propertyBuildings.exemptionUsableSpaceArea')
                    }}</label>
                    <input
                      type="number"
                      autocomplete="off"
                      @keypress="onlyNumber"
                      v-model="form.buildings[index].exemptions[exemptionIndex].exemptionUsableSpaceArea"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="
                        form
                          .errors()
                          .has('buildings_' + index + '_exemptions_' + exemptionIndex + '_exemptionUsableSpaceArea')
                      "
                    >
                      {{
                        form
                          .errors()
                          .get('buildings_' + index + '_exemptions_' + exemptionIndex + '_exemptionUsableSpaceArea')
                      }}
                    </span>
                  </div>
                </div>

                <!-- Befreiung -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyBuildings.exemption') }}
                    </label>
                    <select
                      v-model="form.buildings[index].exemptions[exemptionIndex].exemption"
                      :disabled="isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    >
                      <option
                        v-for="option in buildings_exemption_options"
                        v-bind:key="option.code"
                        :value="option.code"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <br v-show="exemptionIndex < form.buildings[index].exemptions.length - 1" />
              </div>

              <div
                class="col-md-7"
                v-show="exemption_activated"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="add-field __space">
                  <button
                    class="add-field-button hover-tooltip"
                    :disabled="isPropertyReadOnly"
                    @click="addExemption(index)"
                    @focusin="setInfoTooltip(0)"
                  >
                    <Svgall
                      :name="'add_more'"
                      :color="primaryColor"
                    />
                    <span>{{ $t('newProperty.propertyBuildings.add_exemption') }}</span>
                  </button>
                </div>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="add-field __space">
                <button
                  class="add-field-button hover-tooltip"
                  :disabled="isPropertyReadOnly"
                  @click="addBuilding"
                  @focusin="setInfoTooltip(0)"
                >
                  <Svgall
                    :name="'add_more'"
                    :color="primaryColor"
                  />
                  <span>{{ $t('newProperty.propertyBuildings.add_more_buildings') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="text_left step-button-section"
          @mouseenter="setInfoTooltip(0)"
        >
          <div>
            <button
              type="button"
              @click.prevent="cancel"
              class="step-button-style border-button-style hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.abort') }}
            </button>
            <button
              type="button"
              @click.prevent="save"
              class="step-button-style border-button-style hover-tooltip"
              v-show="!isPropertyReadOnly"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.save') }}
            </button>
            <button
              type="button"
              v-show="back_button_activated"
              @click.prevent="callForPrecedingStep"
              class="step-button-style border-button-style"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.back') }}
            </button>
            <button
              type="button"
              @click.prevent="callForNextStep"
              :style="[primaryColor ? { background: primaryColor } : '']"
              class="step-button-style hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue') }}
            </button>
            <button
              type="button"
              @click.prevent="callForNextStep"
              class="step-button-enter hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue_enter') }}
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </section>
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import i18n from '@/i18n'
import InfoTooltip from '@/components/helper/InfoTooltip'
import ShowHideTooltip from '@/mixins/ShowHideTooltip'
const { t } = i18n.global
import { mapGetters, mapActions } from 'vuex'
import { defaultPropertyBuildings } from '../../store/defaults/properties/DefaultPropertyBuildings'
import { evaluateBuildingsExemptionOptions, evaluateBenefitOptions } from '@/helper/PropertyHelper'
import InfoTextHeading from '@/components/helper/InfoTextHeading'
import { isRoometricActive } from '@/helper/RoometricHelper'

export default {
  name: 'PropertyBuildings',
  components: { InfoTextHeading, InfoTooltip },
  mixins: [ShowHideTooltip],
  emits: ['callForNextStep', 'callForPrecedingStep', 'callForSaveProperty', 'cancelPropVisible', 'showRoometricDialog'],
  data() {
    return {
      primaryColor: '',
      fontColor: '',
      form: form({
        buildings: [
          {
            id: 0,
            livingSpaceDescription: '',
            livingSpaceArea: '',
            usableSpaceArea: '',
            areaOfTheLandCivilDefense: '',
            benefits: [
              {
                id: 0,
                benefitDescription: '',
                benefitLivingSpaceArea: '',
                benefitUsableSpaceArea: '',
                benefit: '0',
              },
            ],
            exemptions: [
              {
                id: 0,
                exemptionDescription: '',
                exemptionLivingSpaceArea: '',
                exemptionUsableSpaceArea: '',
                exemption: '0',
              },
            ],
          },
        ],
      }), // set form rules and messages in method "prepareForValidate()"
      exemption_activated: true,
      tab_activated: false,
      back_button_activated: false,
      civil_defense_info_text_activated: false,
    }
  },
  mounted() {
    this.initForm()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : ''
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.primaryColor : ''
    this.addOneOfTwoRequiredRule()
    this.showHide()
  },
  beforeUnmount() {
    this.saveForm()
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getFormPropertyBuildings',
      'getFormPropertyAddress',
      'isPropertyReadOnly',
      'infoTooltip',
    ]),

    isNewProperty() {
      return this.$parent.isNewProperty
    },

    buildings_exemption_options() {
      return evaluateBuildingsExemptionOptions()
    },

    benefit_options() {
      let myOptions = []
      if (
        typeof this.getFormPropertyAddress === 'object' &&
        typeof this.getFormPropertyAddress.federal_state_uid !== 'undefined'
      ) {
        myOptions = evaluateBenefitOptions(this.getFormPropertyAddress.federal_state_uid)
      }
      return myOptions
    },

    buildings_benefit_options() {
      let myRawOptions = this.$tm('newProperty.propertyBuildings.benefit_options')
      let myOptions = []
      for (let k of myRawOptions) {
        myOptions.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }
      return myOptions
    },

    exemption_options_active() {
      let myRawOptions = this.$tm('newProperty.propertyBuildings.exemption_options_active')
      let myOptions = []
      for (let k of myRawOptions) {
        myOptions.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }
      return myOptions
    },

    isRoometricActive() {
      return isRoometricActive()
    },

    isShowNextStepButton() {
      let isShow = true
      if (this.$parent.step === this.$parent.maxStep && this.isPropertyReadOnly) {
        isShow = false
      }
      return isShow
    },
  },
  watch: {
    getFormPropertyBuildings() {
      this.initForm()
    },

    exemption_activated(newValue) {
      if (newValue === false) {
        this.removeAllBenefits()
        this.removeAllExemptions()
      }
    },

    tab_activated(newValue) {
      if (newValue === false) {
        this.form.buildings = []
        this.saveForm()
      }
    },
  },
  methods: {
    ...mapActions(['resetFormPropertyBuildings', 'changeInfoTooltip']),

    calcIdAndRef(prefix, buildingIndex, secondIndex) {
      let result = ''
      if (typeof prefix === 'string' && typeof buildingIndex === 'number') {
        result = prefix + buildingIndex.toString()
        if (typeof secondIndex === 'number') {
          result = result + '_' + secondIndex.toString()
        }
      }
      return result
    },

    setInfoTooltip(val) {
      this.changeInfoTooltip(val)
    },

    initForm() {
      for (const [key, value] of Object.entries(this.$store.state.Properties.formPropertyBuildings)) {
        if (typeof value !== 'undefined') {
          this.form[key] = JSON.parse(JSON.stringify(value))
        }
      }
    },

    saveForm() {
      let myKeys = []
      this.resetFormPropertyBuildings()
      myKeys = Object.keys(this.$store.state.Properties.formPropertyBuildings)
      if (typeof myKeys === 'object') {
        myKeys.forEach(function (key) {
          if (typeof this.form[key] !== 'undefined') {
            this.$store.state.Properties.formPropertyBuildings[key] = JSON.parse(JSON.stringify(this.form[key]))
          }
        }, this)
      }
    },

    cancel() {
      this.$emit('cancelPropVisible')
    },

    callForPrecedingStep() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForPrecedingStep')
      } else {
        this.$emit('callForPrecedingStep')
      }
    },

    callForNextStep() {
      if (this.isPropertyReadOnly) {
        this.$emit('callForNextStep')
      } else {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForNextStep')
      }
    },

    save() {
      if (this.isPropertyReadOnly) {
        return
      }

      this.prepareForValidate()
      if (this.form.validate().errors().any()) {
        this.$store.dispatch('showToast', {
          message: this.$t('newProperty.form_validation_text'),
          toastType: 'error',
        })
        return
      }
      this.saveForm()
      this.$emit('callForSaveProperty')
    },

    invokeReference(ref) {
      this.$nextTick(() => {
        if (!this.$refs[ref]) return
        this.$refs[ref].focus()
      })
    },

    addFlatField(
      objFlatFields, // must be an object
      rawFieldName, // must be a string
      value, // must be a simple value, no objects
      buildingIndex, // must be a number
      exemptionIndex, // optional: either undefined or number
      benefitIndex, // optional: either undefined or number
      maxRuleValue, // must be a number. 0 => no max rule will be set
      maxRuleMsg, // must be a string. "" => no max custom message will be set
      requiredValue, // must be true/false. false => no required rule will be set
      requiredMsg, // must be a string. "" => no required custom message will be set
      lessThanValue, // must be a number. 0 => no less_than rule will be set
      lessThanMsg, // must be a string. "" => no less_than custom message will be set
      oneOfTwoRequiredValue, // must be either a string or "undefined". undefined => no one_of_two_required rule will be set
      oneOfTwoRequiredMsg, //must be a string. "" => no one_of_two_required message will be set
    ) {
      const prefix = 'buildings_'
      const exemptionsPrefix = 'exemptions_'
      const benefitsPrefix = 'benefits_'
      let isExemptionField = typeof exemptionIndex === 'number'
      let isBenefitField = typeof benefitIndex === 'number'
      let flatFieldName = ''
      let fieldRules = []

      if (value === null) value = ''
      if (oneOfTwoRequiredValue === null) oneOfTwoRequiredValue = ''

      if (
        typeof objFlatFields === 'object' &&
        typeof rawFieldName === 'string' &&
        typeof value !== 'undefined' &&
        typeof value !== 'object' &&
        typeof buildingIndex === 'number' &&
        typeof maxRuleValue === 'number' &&
        typeof maxRuleMsg === 'string' &&
        typeof requiredValue === 'boolean' &&
        typeof requiredMsg === 'string' &&
        typeof lessThanValue === 'number' &&
        typeof lessThanMsg === 'string' &&
        typeof oneOfTwoRequiredMsg === 'string'
      ) {
        //build flat field name
        flatFieldName = prefix + buildingIndex.toString() + '_'
        if (isExemptionField) {
          flatFieldName = flatFieldName + exemptionsPrefix + exemptionIndex.toString() + '_'
        } else if (isBenefitField) {
          flatFieldName = flatFieldName + benefitsPrefix + benefitIndex.toString() + '_'
        }
        flatFieldName = flatFieldName + rawFieldName

        //add flat field to object
        objFlatFields[flatFieldName] = value

        //initialize field rules
        fieldRules = []

        //handle max rule
        if (maxRuleValue > 0) {
          if (maxRuleMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.max', maxRuleMsg)
          }
          fieldRules.push('max: ' + maxRuleValue.toString())
        }

        //handle required rule
        if (requiredValue) {
          if (requiredMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.required', requiredMsg)
          }
          fieldRules.push('required')
        }

        //handle less_than rule
        if (lessThanValue > 0) {
          if (lessThanMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.less_than', lessThanMsg)
          }
          fieldRules.push('less_than: ' + lessThanValue.toString())
        }

        //handle one_of_two_required rule
        if (typeof oneOfTwoRequiredValue !== 'undefined') {
          if (oneOfTwoRequiredMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.one_of_two_required', oneOfTwoRequiredMsg)
          }
          fieldRules.push('one_of_two_required: ' + oneOfTwoRequiredValue.toString())
        }

        //add combined field rules
        this.form.validatorInstance.addRule(flatFieldName, fieldRules)
      }
    },

    addOneOfTwoRequiredRule() {
      this.form.validatorInstance.extend({
        one_of_two_required: [
          ':attribute is required',
          ({ value, parameters }) => value.toString().trim() !== '' || parameters[0].toString().trim() !== '',
        ],
      })
    },

    prepareForValidate() {
      let myFlatFields = {}

      //remove existing flat fields first
      this.removeFlatFormFields()

      //add flat fields
      this.form.buildings.forEach(function (building, buildingIndex) {
        this.addFlatField(
          myFlatFields,
          'livingSpaceDescription',
          building.livingSpaceDescription,
          buildingIndex,
          undefined,
          undefined,
          25,
          t('newProperty.propertyBuildings.messages.livingSpaceDescription_max'),
          true,
          t('newProperty.propertyBuildings.messages.livingSpaceDescription_required'),
          0,
          '',
          undefined,
          '',
        )
        this.addFlatField(
          myFlatFields,
          'livingSpaceArea',
          building.livingSpaceArea,
          buildingIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          1000000000,
          t('newProperty.propertyBuildings.messages.livingSpaceArea_less_than'),
          building.usableSpaceArea,
          t('newProperty.propertyBuildings.messages.livingSpaceArea_one_of_two_required'),
        )
        this.addFlatField(
          myFlatFields,
          'usableSpaceArea',
          building.usableSpaceArea,
          buildingIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          1000000000,
          t('newProperty.propertyBuildings.messages.usableSpaceArea_less_than'),
          building.livingSpaceArea,
          t('newProperty.propertyBuildings.messages.usableSpaceArea_one_of_two_required'),
        )
        this.addFlatField(
          myFlatFields,
          'areaOfTheLandCivilDefense',
          building.areaOfTheLandCivilDefense,
          buildingIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          1000000000,
          t('newProperty.propertyBuildings.messages.areaOfTheLandCivilDefense_less_than'),
          undefined,
          '',
        )
        if (typeof building.benefits === 'object') {
          building.benefits.forEach(function (benefit, benefitIndex) {
            this.addFlatField(
              myFlatFields,
              'benefitDescription',
              building.benefits[benefitIndex].benefitDescription,
              buildingIndex,
              undefined,
              benefitIndex,
              25,
              t('newProperty.propertyBuildings.messages.benefitDescription_max'),
              false,
              '',
              0,
              '',
              undefined,
              '',
            )
            this.addFlatField(
              myFlatFields,
              'benefitLivingSpaceArea',
              building.benefits[benefitIndex].benefitLivingSpaceArea,
              buildingIndex,
              undefined,
              benefitIndex,
              0,
              '',
              false,
              '',
              1000000000000000,
              t('newProperty.propertyBuildings.messages.benefitLivingSpaceArea_less_than'),
              building.benefits[benefitIndex].benefitUsableSpaceArea,
              t('newProperty.propertyBuildings.messages.benefitLivingSpaceArea_one_of_two_required'),
            )
            this.addFlatField(
              myFlatFields,
              'benefitUsableSpaceArea',
              building.benefits[benefitIndex].benefitUsableSpaceArea,
              buildingIndex,
              undefined,
              benefitIndex,
              0,
              '',
              false,
              '',
              1000000000000000,
              t('newProperty.propertyBuildings.messages.benefitUsableSpaceArea_less_than'),
              building.benefits[benefitIndex].benefitLivingSpaceArea,
              t('newProperty.propertyBuildings.messages.benefitUsableSpaceArea_one_of_two_required'),
            )
          }, this)
        }
        if (typeof building.exemptions === 'object') {
          building.exemptions.forEach(function (exemption, exemptionIndex) {
            this.addFlatField(
              myFlatFields,
              'exemptionDescription',
              building.exemptions[exemptionIndex].exemptionDescription,
              buildingIndex,
              exemptionIndex,
              undefined,
              25,
              t('newProperty.propertyBuildings.messages.exemptionDescription_max'),
              false,
              '',
              0,
              '',
              undefined,
              '',
            )
            this.addFlatField(
              myFlatFields,
              'exemptionLivingSpaceArea',
              building.exemptions[exemptionIndex].exemptionLivingSpaceArea,
              buildingIndex,
              exemptionIndex,
              undefined,
              0,
              '',
              false,
              '',
              1000000000000000,
              t('newProperty.propertyBuildings.messages.exemptionLivingSpaceArea_less_than'),
              building.exemptions[exemptionIndex].exemptionUsableSpaceArea,
              t('newProperty.propertyBuildings.messages.exemptionLivingSpaceArea_one_of_two_required'),
            )
            this.addFlatField(
              myFlatFields,
              'exemptionUsableSpaceArea',
              building.exemptions[exemptionIndex].exemptionUsableSpaceArea,
              buildingIndex,
              exemptionIndex,
              undefined,
              0,
              '',
              false,
              '',
              1000000000000000,
              t('newProperty.propertyBuildings.messages.exemptionUsableSpaceArea_less_than'),
              building.exemptions[exemptionIndex].exemptionLivingSpaceArea,
              t('newProperty.propertyBuildings.messages.exemptionUsableSpaceArea_one_of_two_required'),
            )
          }, this)
        }
      }, this)

      this.form.set(myFlatFields)
      //console.log("Form: ", this.form)
    },

    removeFlatFormFields() {
      Object.keys(this.form.all()).forEach((key) => {
        if (key.startsWith('buildings_')) {
          this.form.forget(key)
        }
      })
      this.form.rules({})
    },

    removeBuilding(index) {
      if (typeof index === 'number' && index >= 0 && index < this.form.buildings.length) {
        this.form.buildings.splice(index, 1)
      }
    },

    addBuilding() {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyBuildings.buildings[0]))
      myObject.benefits = []
      myObject.exemptions = []
      this.form.buildings.push(myObject)
    },

    removeExemption(buildingIndex, exemptionIndex) {
      if (
        typeof buildingIndex === 'number' &&
        buildingIndex >= 0 &&
        buildingIndex < this.form.buildings.length &&
        typeof exemptionIndex === 'number' &&
        exemptionIndex >= 0 &&
        exemptionIndex < this.form.buildings[buildingIndex].exemptions.length
      ) {
        this.form.buildings[buildingIndex].exemptions.splice(exemptionIndex, 1)
      }
    },

    addExemption(buildingIndex) {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyBuildings.buildings[0].exemptions[0]))
      if (typeof buildingIndex === 'number' && buildingIndex >= 0 && buildingIndex < this.form.buildings.length) {
        this.form.buildings[buildingIndex].exemptions.push(myObject)
      }
    },

    removeBenefit(buildingIndex, benefitIndex) {
      if (
        typeof buildingIndex === 'number' &&
        buildingIndex >= 0 &&
        buildingIndex < this.form.buildings.length &&
        typeof benefitIndex === 'number' &&
        benefitIndex >= 0 &&
        benefitIndex < this.form.buildings[buildingIndex].benefits.length
      ) {
        this.form.buildings[buildingIndex].benefits.splice(benefitIndex, 1)
      }
    },

    addBenefit(buildingIndex) {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyBuildings.buildings[0].benefits[0]))
      if (typeof buildingIndex === 'number' && buildingIndex >= 0 && buildingIndex < this.form.buildings.length) {
        this.form.buildings[buildingIndex].benefits.push(myObject)
      }
    },

    removeAllExemptions() {
      this.form.buildings.forEach(function (building) {
        building.exemptions = []
      }, this)
    },

    removeAllBenefits() {
      this.form.buildings.forEach(function (building) {
        building.benefits = []
      }, this)
    },

    onlyNumber($event) {
      let code = $event.code ? $event.code : $event.which
      let key = $event.key
      if (code === 'KeyE' || key === 'e' || key === 'E') {
        $event.preventDefault()
      }
    },

    openRoometricDialog() {
      if (this.isRoometricActive) {
        this.$emit('showRoometricDialog')
      }
    },
  },
}
</script>

<style type="text/css">
textarea.textarea-field {
  border: 1px solid #bbbbbb;
  height: 150px;
  width: 100%;
}

.Step_SectionPropertyBuildings .tooltip-icon img {
  height: 18px;
  margin-left: 5px;
}

label.label-title.tooltip-icon {
  position: relative;
}

.ToolTipTextBox {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: none;
  max-width: 600px;
  padding: 20px;
  padding: 15px !important;
  position: absolute;
  right: 0;
  top: 35px;
  transform: translate3d(0px, 40px, 0px) !important;
  z-index: 1;
}

/*.Step_SectionPropertyBuildings .tooltip-icon img:hover + .ToolTipTextBox {
    display: block;
}*/

.button-style-for-title {
  background: #229d56;
  border: 0;
  border-radius: 2px;
  color: #fff;
  height: 40px;
  margin-right: 15px;
  width: 200px;
}

.flex-button-icon {
  align-items: center;
  display: flex;
}

.info-text {
  background-color: #f2f2f2;
  float: left;
  font-size: 12px;
  line-height: normal;
  padding: 8px;
  text-align: left;
}
</style>
