import app from './modules/app'
import login from './modules/login'
import Users from './modules/Users'
import Properties from './modules/Properties'
import recordObserver from '@/store/modules/recordObserver'
import { createStore } from 'vuex'

export default createStore({
  state: {
    infoTooltip: 0,
    InfoImageUrl: false,
  },
  getters: {
    infoTooltip: (state) => state.infoTooltip,
    InfoImageUrl: (state) => state.InfoImageUrl,
  },
  mutations: {
    setInfoTooltip(state, val) {
      if (val === '') {
        val = 0
      }
      state.infoTooltip = val
    },
    setInfoImageUrl(state, data) {
      state.InfoImageUrl = data
    },
  },
  actions: {
    async changeInfoTooltip({ commit }, payload) {
      commit('setInfoTooltip', payload)
    },
  },
  modules: {
    app,
    login,
    Users,
    Properties,
    recordObserver,
  },
})
