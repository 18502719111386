export const defaultPropertyLivestock = {
  ownedSpace: '', //Eigentumsflächen
  minusLeasedAreas: '', //Abzüglich verpachteter Flächen
  plusLeasedAreas: '', //Zuzüglich zugepachteter Flächen
  selfCultivatedAreas: '', //Selbstbewirtschaftete Flächen
  alpacas: '', //Alpakas
  damAnimal_below1Year: '', //Dammtiere unter 1 Jahr
  damAnimal_above1Year: '', //Dammtiere 1 Jahr und älter
  layingHens_layingHensIncludeBreeding: '', //Legehennen (einschließlich einer normalen Aufzucht zur Ergänzung des Bestandes)
  layingHens_purchasedLayingHens: '', //Legehennen aus zugekauften Junghennen
  poultry: '', //Zuchtputen, -enten, -gänse
  breedingRabbits: '', //Zucht- und Angorakaninchen
  llamas: '', //Lamas
  horses_below3YearsOrSmallHorses: '', //Pferde unter 3 Jahren und Kleinpferde
  horses_horses: '', //Pferde 3 Jahre und älter
  beef_below1Year: '', //Kälber und Jungvieh unter 1 Jahr (einschließlich Mastkälber, Starterkälber und Fresser)
  beef_1to2Year: '', //Jungvieh 1 bis 2 Jahre alt
  beef_above2Year: '', //Färsen (älter als 2 Jahre)
  fatteningAnimalsLessThan1Year: '', //Masttiere (Mastdauer weniger als 1 Jahr)
  cows: '', //Kühe (einschließlich Mutter- und Ammenkühe mit den dazugehörigen Saugkälbern)
  breedingBulls: '', //Zuchtbullen, Zugochsen
  sheep_below1Year: '', //Schafe unter 1 Jahr (einschließlich Mastlämmer)
  sheep_above1Year: '', //Schafe 1 Jahr und älter
  breedingPigs: '', //Zuchtschweine (einschließlich Jungzuchtschweine über etwa 90 kg)
  ostriches_above14Months: '', //Strauße (Zuchttiere 14 Monate und älter)
  ostriches_below14Months: '', //Strauße (Jungtiere/Masttiere unter 14 Monate)
  youngChickensForFattening_below6Passes: '', //Jungmasthühner (bis zu 6 Durchgänge je Jahr - schwere Tiere)
  youngChickensForFattening_above6Passes: '', //Jungmasthühner (mehr als 6 Durchgänge je Jahr - leichte Tiere)
  pullets: '', //Junghennen
  fatteningDucks_count: '', //Mastenten
  fatteningDucks_raisingPhase: '', //Mastenten in der Aufzuchtphase
  fatteningDucks_fatteningPhase: '', //Mastenten in der Mastphase
  turkeys_selfRaised: '', //Mastputen aus selbst erzeugten Jungputen
  turkeys_bought: '', //Mastputen aus zugekauften Jungputen
  turkeys_youngTurkeys: '', //Jungputen (bis etwa 8 Wochen)
  fattenedGeese: '', //Mastgänse
  mastRabbit: '', //Mastkaninchen
  beefCattle: '', //Rindvieh - Masttiere (Mastdauer 1 Jahr und mehr)
  pigs_lightPiglets: '', //Leichte Ferkel (bis etwa 12 kg)
  pigs_piglets: '', //Ferkel (über etwa 12 bis etwa 20 kg)
  pigs_heavyPiglets: '', //Schwere Ferkel und leichte Läufer (über etwa 20 bis etwa 30 kg)
  pigs_runner: '', //Läufer (über etwa 30 bis etwa 45 kg)
  pigs_heavyRunner: '', //Schwere Läufer (über etwa 45 bis etwa 60 kg)
  pigs_fatteningPigs: '', //Mastschweine
  pigs_youngBreedingPigs: '', //Jungzuchtschweine bis etwa 90 kg
  goats: '', //Ziegen
  boughtPigs_lightPiglets: '', //Leichte Ferkel (bis etwa 12 kg)
  boughtPigs_piglets: '', //Ferkel (über etwa 12 bis etwa 20 kg)
  boughtPigs_heavyPiglets: '', //Schwere Ferkel und leichte Läufer (über etwa 20 bis etwa 30 kg)
  boughtPigs_runner: '', //Läufer (über etwa 30 bis etwa 45 kg)
  boughtPigs_heavyRunner: '', //Schwere Läufer (über etwa 45 bis etwa 60 kg)
}
