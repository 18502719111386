export const defaultPropertyDetails = {
  reference: '',
  name: '',
  economic_entity_type: '0',
  exemption: 0,
  extra_details: '',
  saveDeviatingOwner: false,
  deviatingOwner: {
    id: 0,
    salutation: '',
    title: '',
    firstName: '',
    lastName: '',
    street: '',
    houseNumber: '',
    poBox: '',
    zipCode: '',
    city: '',
    countryUid: '',
    taxNumber: '',
    identificationNumber: '',
    dob: '',
  },
}
