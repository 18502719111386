<template>
  <!-- Header Section -->
  <header class="app-head section">
    <div class="container">
      <div class="men_row frm_margin_on_mobile">
        <div class="row">
          <div class="col-md-2 d-flex justify-content-strat align-items-center">
            <div class="comp_logo">
              <img
                :src="comp_logo_image"
                v-if="comp_logo_image"
              />
            </div>
          </div>
          <div class="col-md-3 p-0">
            <div
              class="comp_user"
              v-if="profile_pic_url"
            >
              <!-- <img src="@/assets/user.png"> -->
              <img :src="profile_pic_url" />
            </div>
          </div>

          <div
            v-show="showInfo"
            class="col-md-7 p-0"
          >
            <div class="comp_info">
              <div class="comp_user_data">
                <div class="info_inline f_60">
                  <div class="abso_div_large">
                    <label>
                      <span>
                        <Svgall
                          class="profiles_svg"
                          :name="'profile_building'"
                          :color="primaryColor"
                        />
                      </span>
                      {{ $t('profile.taxofc') }}:
                    </label>
                    <span class="dynamicColor">{{ steuerkanzlei }}</span>
                  </div>
                </div>

                <div class="info_inline f_40 pos_rel">
                  <div class="abso_div">
                    <label>
                      <span>
                        <Svgall
                          class="profiles_svg"
                          :name="'profile_location'"
                          :color="primaryColor"
                        />
                      </span>
                      {{ $t('profile.address') }}:
                    </label>
                    <span class="dynamicColor">{{ adresse }}<br />{{ adresse_2 }}</span>
                  </div>
                </div>

                <div
                  class="info_inline f_60"
                  v-if="email !== ''"
                >
                  <div class="abso_div_large">
                    <label>
                      <span>
                        <Svgall
                          class="profiles_svg"
                          :name="'profile_email'"
                          :color="primaryColor"
                        />
                      </span>
                      {{ $t('profile.email') }}:
                    </label>
                    <span
                      ><a
                        class="hoverColor"
                        :href="'mailto:' + email"
                        >{{ email }}</a
                      ></span
                    >
                  </div>
                </div>

                <div
                  class="info_inline f_40"
                  v-if="telefon !== ''"
                >
                  <div class="abso_div">
                    <label>
                      <span>
                        <!-- <img class="max_18" src="@/assets/phone.svg"> -->
                        <Svgall
                          class="profiles_svg"
                          :name="'profile_phone'"
                          :color="primaryColor"
                        /> </span
                      >{{ $t('profile.tel') }}:</label
                    >
                    <span
                      ><a
                        class="hoverColor"
                        :href="'tel:' + telefon"
                        >{{ telefon }}</a
                      ></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<!-- Header Section ///////////// -->

<script type="text/javascript">
import { mapGetters } from 'vuex'

export default {
  name: 'Profileinfo',
  data() {
    return {
      steuerkanzlei: '',
      email: '',
      adresse: '',
      adresse_2: '',
      telefon: '',
      comp_logo_image: '',
      profile_pic_url: '',
      primaryColor: '',
      showInfo: true,
    }
  },
  mounted() {
    if (this.getCurrentUser.id) {
      if (this.getCurrentUser.taxConsultant.showBranchInfo) {
        if (this.getCurrentUser.branch) {
          this.steuerkanzlei = this.getCurrentUser.branch.name
          this.email = this.getCurrentUser.branch.email
          this.adresse = this.getCurrentUser.branch.street
          this.adresse_2 = this.getCurrentUser.branch.zip + ' ' + this.getCurrentUser.branch.city
          this.telefon = this.getCurrentUser.branch.phone
        } else {
          this.showInfo = false
        }
      } else {
        this.steuerkanzlei = this.getCurrentUser.taxConsultant.companyName
          ? this.getCurrentUser.taxConsultant.companyName
          : this.getCurrentUser.taxConsultant.firstName + ' ' + this.getCurrentUser.taxConsultant.lastName
        this.email = this.getCurrentUser.taxConsultant.email
        this.adresse = this.getCurrentUser.taxConsultant.street
        if (this.getCurrentUser.taxConsultant.houseNumber !== '') {
          this.adresse = this.adresse + ' ' + this.getCurrentUser.taxConsultant.houseNumber
        }
        this.adresse_2 = this.getCurrentUser.taxConsultant.zip + ' ' + this.getCurrentUser.taxConsultant.city
        this.telefon = this.getCurrentUser.taxConsultant.phone
      }
      this.comp_logo_image = this.getCurrentUser.taxConsultant.companyLogoPic
    }
  },
  created() {
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#464747'
  },
  computed: {
    ...mapGetters(['getCurrentUser']),
  },
}
</script>
<style>
body {
  background: #ffff !important;
  margin: 0;
  padding: 0;
}

.comp_user img {
  width: 110px;
}

.info_inline a {
  color: #4b4b4b;
}

header.app-head.section {
  font-family: 'Segoe UI', sans-serif !important;
}

.max_18 {
  margin-right: 5px !important;
}

.container_max {
  margin: 0 auto !important;
  max-width: 1200px;
}

.info_inline img {
  margin-right: 10px;
  max-height: 20px;
  max-width: 20px;
}

.comp_user {
  text-align: right;
}

.comp_info {
  align-items: center;
  display: flex;
  width: 100%;
}

.comp_user_data {
  color: #4b4b4b;
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  justify-content: space-between;
  /*max-width: 664px;*/
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
}

.men_row {
  border: 2px solid #dfdfdf;
  border-radius: 5px;
  margin-top: 25px;
  padding: 10px;
}

.comp_user_data label {
  font-weight: 600;
}

.comp_logo {
  text-align: center;
}

.comp_logo img {
  height: auto;
  max-height: 100px;
  max-width: 100px;
  width: auto;
}

.info_inline {
  margin: 10px 0;
}

.men_row {
  align-items: center;
}

.info_inline.f_60 {
  max-width: 58%;
  width: 100%;
}

.info_inline.f_40 {
  max-width: 38%;
  width: 100%;
}

.abso_div {
  align-items: flex-start;
  display: flex;
}

.abso_div_large {
  align-items: flex-start;
  display: flex;
}

.pos_rel {
  position: relative;
}

.info_inline.f_60 > label {
  display: inline-block;
  min-width: 150px;
}

.abso_div > label {
  align-items: center;
  display: flex;
  min-width: 100px;
}

.abso_div_large > label {
  align-items: center;
  display: flex;
  min-width: 140px;
}

ul.user-selector li a {
  color: #333;
}

.Login_SMS_Code {
  padding-top: 8px;
}

a.link-style:hover {
  color: #333 !important;
  text-decoration: underline !important;
}

.hoverColor:hover {
  color: #229d56;
}

.content-heading p {
  color: #4b4b4b;
  font-size: 20px;
  line-height: 25px;
  margin: 15px 0 20px;
}

.headign-title {
  color: #4b4b4b;
  font-size: 26px;
  font-weight: 700;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 1090px) {
  .comp_logo {
    margin-left: 0;
  }

  .comp_info {
    max-width: 75%;
  }

  .info_inline.f_60,
  .info_inline.f_40 {
    max-width: 100%;
  }

  .info_inline.f_60 > label,
  .abso_div > label {
    min-width: 200px;
    padding-right: 0px;
  }

  .button-set button.start-button {
    font-size: 22px;
  }

  .content-heading p {
    font-size: 18px;
  }

  .expired-section-inner {
    margin: 100px auto 100px auto !important;
  }

  footer.app-footer {
    position: relative !important;
  }

  .abso_div > label {
    align-items: center;
    display: flex;
    margin-right: unset !important;
    min-width: 140px !important;
  }
}
</style>
