<template>
  <transition name="modal-fade">
    <div class="modal-backdrop __Modal_Popup">
      <div class="modal">
        <div class="Modal_content_Area">
          <header
            class="modal-header"
            v-if="showClose"
          >
            <img
              src="@/assets/btn_close_gray.svg"
              class="btn-close"
              @click="close"
              v-if="showClose"
            />
          </header>

          <slot name="body"> </slot>

          <slot name="footer"> </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalDialog',
  props: {
    showClose: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped lang="scss">
.modal-backdrop {
  align-items: normal;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

.__Modal_Popup .modal {
  align-content: stretch;
  background: white;
  display: flex;
  flex-direction: column;
  height: auto; //281px;
  justify-content: space-between;
  left: 50%;
  margin: 0 auto;
  max-width: 560px !important;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}
.__Modal_Popup .modal-header {
  border: 0;
  border-radius: 5px;
  box-shadow: 0 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 1000;
}
.modal-backdrop .modal {
  border-top: 3px solid #229d56;
  padding-bottom: 25px;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}
</style>
