<template>
  <Header />
  <div class="login_start">
    <ProfileInfo />
    <DeclarationSection />
  </div>
  <Copyright />
</template>

<script>
import Header from '@/components/Header.vue'
import ProfileInfo from '@/components/Profileinfo.vue'
import DeclarationSection from '@/components/Declaration'
import Copyright from '@/components/copyright.vue'

export default {
  name: 'Declaration',
  components: {
    Header,
    ProfileInfo,
    DeclarationSection,
    Copyright,
  },
}
</script>
