//import axios from 'axios';
import apiClient from '../../Api'
import router from '../../router'
import i18n from '@/i18n'

const { t } = i18n.global
const state = {
  arrProperties: '',
  arrDeclarations: '',
  objPropertyDetails: {},
  arrCountries: '',
  responseObjPropertyDelete: '',
  responseObjPropertyPost: '',
  responseObjPropertyPut: '',
  arrCommunities: '',
  arrFederalStatesCommunities: '',
  arrGemarkungen: '',
  arrGemarkungenCollection: '',
  formPropertyAddress: '',
  formPropertyDetails: '',
  formPropertyMoreDetails: '',
  formPropertyBenefits: '',
  formPropertyCommunity: '',
  formPropertyOwnership: '',
  formPropertyBuildings: '',
  formPropertyResidential: '',
  formPropertyNonResidential: '',
  formPropertyLeasehold: '',
  formPropertyAgriculture: '',
  formPropertyLivestock: '',
  federalStates: [],
  readOnly: false,
  lastAddedPropertyId: '',
  dashboardListLayout: false,
  dashboardPropertySearch: '',
  dashboardSelectedFilterKey: '',
}
const { defaultPropertyAddress, propertyDataStructure } = require('../defaults/properties/DefaultPropertyAddress')
const { defaultPropertyDetails } = require('../defaults/properties/DefaultPropertyDetails')
const { defaultPropertyMoreDetails } = require('../defaults/properties/DefaultPropertyMoreDetails')
const { defaultPropertyBenefits } = require('../defaults/properties/DefaultPropertyBenefits')
const { defaultPropertyCommunity } = require('../defaults/properties/DefaultPropertyCommunity')
const { defaultPropertyOwnership } = require('../defaults/properties/DefaultPropertyOwnership')
const { defaultPropertyBuildings } = require('../defaults/properties/DefaultPropertyBuildings')
const { defaultPropertyResidential } = require('../defaults/properties/DefaultPropertyResidential')
const { defaultPropertyNonResidential } = require('../defaults/properties/DefaultPropertyNonResidential')
const { defaultPropertyLeasehold } = require('../defaults/properties/DefaultPropertyLeasehold')
const { defaultPropertyAgriculture } = require('../defaults/properties/DefaultPropertyAgriculture')
const { defaultPropertyLivestock } = require('../defaults/properties/DefaultPropertyLivestock')

const getters = {
  getArrProperties: (state) => state.arrProperties,
  getArrDeclarations: (state) => state.arrDeclarations,
  getObjPropertyDetails: (state) => state.objPropertyDetails,
  getArrCountries: (state) => state.arrCountries,
  getFederalStates: (state) => state.federalStates,
  getResponseObjPropertyDelete: (state) => state.responseObjPropertyDelete,
  getResponseObjPropertyPost: (state) => state.responseObjPropertyPost,
  getResponseObjPropertyPut: (state) => state.responseObjPropertyPut,
  getArrCommunities: (state) => state.arrCommunities,
  getArrFederalStatesCommunities: (state) => state.arrFederalStatesCommunities,
  getArrGemarkungen: (state) => state.arrGemarkungen,
  getArrGemarkungenCollection: (state) => state.arrGemarkungenCollection,
  getFormPropertyAddress: (state) => state.formPropertyAddress,
  getFormPropertyDetails: (state) => state.formPropertyDetails,
  getFormPropertyMoreDetails: (state) => state.formPropertyMoreDetails,
  getFormPropertyBenefits: (state) => state.formPropertyBenefits,
  getFormPropertyCommunity: (state) => state.formPropertyCommunity,
  getFormPropertyOwnership: (state) => state.formPropertyOwnership,
  getFormPropertyBuildings: (state) => state.formPropertyBuildings,
  getFormPropertyResidential: (state) => state.formPropertyResidential,
  getFormPropertyNonResidential: (state) => state.formPropertyNonResidential,
  getFormPropertyLeasehold: (state) => state.formPropertyLeasehold,
  getFormPropertyAgriculture: (state) => state.formPropertyAgriculture,
  getFormPropertyLivestock: (state) => state.formPropertyLivestock,
  isPropertyReadOnly: (state) => state.readOnly,
  getLastAddedPropertyId: (state) => state.lastAddedPropertyId,
  getDashboardListLayout: (state) => state.dashboardListLayout,
  getDashboardPropertySearch: (state) => state.dashboardPropertySearch,
  getDashboardSelectedFilterKey: (state) => state.dashboardSelectedFilterKey,
}

const mutations = {
  setArrProperties: (state, val) => {
    if (typeof val === 'object') {
      state.arrProperties = JSON.parse(JSON.stringify(val))
    } else {
      state.arrProperties = val
    }
  },
  setArrDeclarations: (state, val) => {
    if (typeof val === 'object') {
      state.arrDeclarations = JSON.parse(JSON.stringify(val))
    } else {
      state.arrDeclarations = val
    }
  },
  setPropertyReadOnly: (state, val) => {
    state.readOnly = val
  },

  setObjPropertyDetails: (state, val) => {
    if (val.id) {
      state.objPropertyDetails = val
    } else {
      state.objPropertyDetails = propertyDataStructure
    }
  },
  setArrCountries: (state, val) => {
    if (typeof val === 'object') {
      state.arrCountries = JSON.parse(JSON.stringify(val))
    } else {
      state.arrCountries = val
    }
  },
  setFederalStates: (state, val) => {
    if (typeof val === 'object') {
      state.federalStates = JSON.parse(JSON.stringify(val))
    } else {
      state.federalStates = val
    }
  },
  setResponseObjPropertyDelete: (state, val) => {
    if (typeof val === 'object') {
      state.responseObjPropertyDelete = JSON.parse(JSON.stringify(val))
    } else {
      state.responseObjPropertyDelete = val
    }
  },
  setResponseObjPropertyPost: (state, val) => {
    if (typeof val === 'object') {
      state.responseObjPropertyPost = JSON.parse(JSON.stringify(val))
    } else {
      state.responseObjPropertyPost = val
    }
  },
  setResponseObjPropertyPut: (state, val) => {
    if (typeof val === 'object') {
      state.responseObjPropertyPut = JSON.parse(JSON.stringify(val))
    } else {
      state.responseObjPropertyPut = val
    }
  },
  setArrCommunities: (state, val) => {
    if (typeof val === 'object') {
      let parsed_data = []
      const data_length = Object.keys(val).length
      if (data_length > 0) {
        for (let i = 0; i < data_length; i++) {
          parsed_data.push(val[i])
        }
      }
      state.arrCommunities = parsed_data
    } else {
      state.arrCommunities = val
    }
  },
  setArrFederalStatesCommunities: (state, val) => {
    if (typeof val === 'object') {
      state.arrFederalStatesCommunities = JSON.parse(JSON.stringify(val))
    } else {
      state.arrFederalStatesCommunities = val
    }
  },
  setArrFederalStatesCommunitiesForFederalStateName: (state, val) => {
    if (typeof val.federalStateName === 'string') {
      let federalStateName = val.federalStateName.trim()
      if (federalStateName !== '') {
        // initialize array if needed
        if (typeof state.arrFederalStatesCommunities !== 'object') {
          state.arrFederalStatesCommunities = []
        }
        // remove array record if already existing
        if (state.arrFederalStatesCommunities.length > 0) {
          let foundIndex = state.arrFederalStatesCommunities.findIndex(function (objFederalStateCommunities) {
            if (objFederalStateCommunities.federalStateName === federalStateName) {
              return true
            }
          })
          if (foundIndex !== -1) {
            state.arrFederalStatesCommunities.splice(foundIndex, 1)
          }
        }
        // create new object for array
        let federalStateCommunities = {
          federalStateName: federalStateName,
          arrCommunities: [],
        }
        if (typeof val.arrCommunities === 'object') {
          federalStateCommunities.arrCommunities = JSON.parse(JSON.stringify(val.arrCommunities))
        }
        // push object
        state.arrFederalStatesCommunities.push(federalStateCommunities)
      }
    }
  },
  setArrGemarkungen: (state, val) => {
    if (typeof val === 'object') {
      let parsed_data = []
      const data_length = Object.keys(val).length
      if (data_length > 0) {
        for (let i = 0; i < data_length; i++) {
          parsed_data.push(val[i])
        }
      }
      state.arrGemarkungen = parsed_data
    } else {
      state.arrGemarkungen = val
    }
  },
  setArrGemarkungenCollection: (state, val) => {
    if (typeof val === 'object') {
      state.arrGemarkungenCollection = JSON.parse(JSON.stringify(val))
    } else {
      state.arrGemarkungenCollection = val
    }
  },
  setArrGemarkungenCollectionForStateAndCommunity: (state, val) => {
    if (typeof val.federalStateName === 'string' && typeof val.communityName === 'string') {
      let federalStateName = val.federalStateName.trim()
      let communityName = val.communityName.trim()
      if (federalStateName !== '' && communityName !== '') {
        // initialize array if needed
        if (typeof state.arrGemarkungenCollection !== 'object') {
          state.arrGemarkungenCollection = []
        }
        // remove array record if already existing
        if (state.arrGemarkungenCollection.length > 0) {
          let foundIndex = state.arrGemarkungenCollection.findIndex(function (objGemarkungenCollection) {
            if (
              objGemarkungenCollection.federalStateName === federalStateName &&
              objGemarkungenCollection.communityName === communityName
            ) {
              return true
            }
          })
          if (foundIndex !== -1) {
            state.arrGemarkungenCollection.splice(foundIndex, 1)
          }
        }
        // create new object for array
        let gemarkungenCollection = {
          federalStateName: federalStateName,
          communityName: communityName,
          arrGemarkungen: [],
        }
        if (typeof val.arrGemarkungen === 'object') {
          gemarkungenCollection.arrGemarkungen = JSON.parse(JSON.stringify(val.arrGemarkungen))
        }
        // push object
        state.arrGemarkungenCollection.push(gemarkungenCollection)
      }
    }
  },
  setFormPropertyAddress: (state, val) => {
    if (typeof val === 'object') {
      state.formPropertyAddress = JSON.parse(JSON.stringify(val))
    } else {
      state.formPropertyAddress = val
    }
  },
  setLastAddedPropertyId: (state, val) => {
    state.lastAddedPropertyId = val
  },
  updateFormPropertyAddress: () => {
    if (state.formPropertyAddress === '') {
      state.formPropertyAddress = JSON.parse(JSON.stringify(defaultPropertyAddress))
    }

    if (typeof state.objPropertyDetails === 'object') {
      state.formPropertyAddress['federal_state_uid'] = state.objPropertyDetails.step2.federalStateUid
      state.formPropertyAddress['community'] = state.objPropertyDetails.step2.community
      state.formPropertyAddress['street'] = state.objPropertyDetails.step2.street
      state.formPropertyAddress['house_number'] = state.objPropertyDetails.step2.houseNumber
      state.formPropertyAddress['zip'] = state.objPropertyDetails.step2.zip
      state.formPropertyAddress['city'] = state.objPropertyDetails.step2.city
      state.formPropertyAddress['additional_information'] = state.objPropertyDetails.step2.additional_information
    }
  },
  pushFormPropertyAddress: () => {
    state.objPropertyDetails.step2.federalStateUid = state.formPropertyAddress['federal_state_uid']
    state.objPropertyDetails.step2.community = state.formPropertyAddress['community']
    state.objPropertyDetails.step2.street = state.formPropertyAddress['street']
    state.objPropertyDetails.step2.houseNumber = state.formPropertyAddress['house_number']
    state.objPropertyDetails.step2.zip = state.formPropertyAddress['zip']
    state.objPropertyDetails.step2.city = state.formPropertyAddress['city']
    state.objPropertyDetails.step2.additional_information = state.formPropertyAddress['additional_information']
  },
  pushFormPropertyDetails: () => {
    state.objPropertyDetails.step2.reference = state.formPropertyDetails['reference']
    state.objPropertyDetails.step2.name = state.formPropertyDetails['name']
    state.objPropertyDetails.step2.economicEntityType = state.formPropertyDetails['economic_entity_type']
    state.objPropertyDetails.step2.exemption = state.formPropertyDetails['exemption']
    state.objPropertyDetails.step2.extraDetails = state.formPropertyDetails['extra_details']
    state.objPropertyDetails.saveDeviatingOwner = state.formPropertyDetails['saveDeviatingOwner'] ? 1 : 0
    state.objPropertyDetails.deviatingOwner = {}
    if (state.formPropertyDetails['saveDeviatingOwner']) {
      state.objPropertyDetails.deviatingOwner.id = state.formPropertyDetails.deviatingOwner.id
      state.objPropertyDetails.deviatingOwner.salutation = state.formPropertyDetails['deviatingOwner']['salutation']
      state.objPropertyDetails.deviatingOwner.title = state.formPropertyDetails['deviatingOwner']['title']
      state.objPropertyDetails.deviatingOwner.firstName = state.formPropertyDetails['deviatingOwner']['firstName']
      state.objPropertyDetails.deviatingOwner.lastName = state.formPropertyDetails['deviatingOwner']['lastName']
      state.objPropertyDetails.deviatingOwner.street = state.formPropertyDetails['deviatingOwner']['street']
      state.objPropertyDetails.deviatingOwner.houseNumber = state.formPropertyDetails['deviatingOwner']['houseNumber']
      state.objPropertyDetails.deviatingOwner.poBox = state.formPropertyDetails['deviatingOwner']['poBox']
      state.objPropertyDetails.deviatingOwner.zipCode = state.formPropertyDetails['deviatingOwner']['zipCode']
      state.objPropertyDetails.deviatingOwner.city = state.formPropertyDetails['deviatingOwner']['city']
      state.objPropertyDetails.deviatingOwner.countryUid = state.formPropertyDetails['deviatingOwner']['countryUid']
      state.objPropertyDetails.deviatingOwner.taxNumber = state.formPropertyDetails['deviatingOwner']['taxNumber']
      state.objPropertyDetails.deviatingOwner.identificationNumber =
        state.formPropertyDetails['deviatingOwner']['identificationNumber']
      state.objPropertyDetails.deviatingOwner.dob = state.formPropertyDetails['deviatingOwner']['dob']
    } else {
      state.objPropertyDetails.deviatingOwner = {}
    }
  },
  setFormPropertyDetails: (state, val) => {
    if (typeof val === 'object') {
      state.formPropertyDetails = JSON.parse(JSON.stringify(val))
    } else {
      state.formPropertyDetails = val
    }
  },
  updateFormPropertyDetails: () => {
    if (state.formPropertyDetails === '') {
      state.formPropertyDetails = JSON.parse(JSON.stringify(defaultPropertyDetails))
    }

    if (typeof state.objPropertyDetails === 'object') {
      state.formPropertyDetails['reference'] = state.objPropertyDetails.step2.reference
      state.formPropertyDetails['name'] = state.objPropertyDetails.step2.name
      state.formPropertyDetails['economic_entity_type'] = state.objPropertyDetails.step2.economicEntityType
      state.formPropertyDetails['exemption'] = state.objPropertyDetails.step2.exemption
      state.formPropertyDetails['extra_details'] = state.objPropertyDetails.step2.extraDetails
      state.formPropertyDetails['saveDeviatingOwner'] = false
      if (
        typeof state.objPropertyDetails.deviatingOwner === 'object' &&
        typeof state.objPropertyDetails.deviatingOwner.id !== 'undefined'
      ) {
        let parsedId = parseInt(state.objPropertyDetails.deviatingOwner.id)
        if (!isNaN(parsedId)) {
          state.formPropertyDetails['saveDeviatingOwner'] = parsedId > 0
        }
      }
      if (state.formPropertyDetails['saveDeviatingOwner']) {
        state.formPropertyDetails['deviatingOwner']['id'] = state.objPropertyDetails.deviatingOwner.id
        state.formPropertyDetails['deviatingOwner']['salutation'] = state.objPropertyDetails.deviatingOwner.salutation
        state.formPropertyDetails['deviatingOwner']['title'] = state.objPropertyDetails.deviatingOwner.title
        state.formPropertyDetails['deviatingOwner']['firstName'] = state.objPropertyDetails.deviatingOwner.firstName
        state.formPropertyDetails['deviatingOwner']['lastName'] = state.objPropertyDetails.deviatingOwner.lastName
        state.formPropertyDetails['deviatingOwner']['street'] = state.objPropertyDetails.deviatingOwner.street
        state.formPropertyDetails['deviatingOwner']['houseNumber'] = state.objPropertyDetails.deviatingOwner.houseNumber
        state.formPropertyDetails['deviatingOwner']['poBox'] = state.objPropertyDetails.deviatingOwner.poBox
        state.formPropertyDetails['deviatingOwner']['zipCode'] = state.objPropertyDetails.deviatingOwner.zipCode
        state.formPropertyDetails['deviatingOwner']['city'] = state.objPropertyDetails.deviatingOwner.city
        state.formPropertyDetails['deviatingOwner']['countryUid'] = state.objPropertyDetails.deviatingOwner.countryUid
        state.formPropertyDetails['deviatingOwner']['taxNumber'] = state.objPropertyDetails.deviatingOwner.taxNumber
        state.formPropertyDetails['deviatingOwner']['identificationNumber'] =
          state.objPropertyDetails.deviatingOwner.identificationNumber
        state.formPropertyDetails['deviatingOwner']['dob'] = state.objPropertyDetails.deviatingOwner.dob
      }
    }
  },
  setFormPropertyMoreDetails: (state, val) => {
    if (typeof val === 'object') {
      state.formPropertyMoreDetails = JSON.parse(JSON.stringify(val))
    } else {
      state.formPropertyMoreDetails = val
    }
  },
  updateFormPropertyMoreDetails: () => {
    if (state.formPropertyMoreDetails === '') {
      state.formPropertyMoreDetails = Object.assign(state.formPropertyMoreDetails, defaultPropertyMoreDetails)
    }

    if (typeof state.objPropertyDetails === 'object') {
      state.formPropertyMoreDetails['ownership_structure'] = state.objPropertyDetails.step3.ownershipStructure
      state.formPropertyMoreDetails['property_type'] = state.objPropertyDetails.step3.propertyType
      state.formPropertyMoreDetails['development_state'] = state.objPropertyDetails.step3.developmentState
      state.formPropertyMoreDetails['multi_communities'] = state.objPropertyDetails.step3.multiCommunities
      state.formPropertyMoreDetails['leasehold'] = state.objPropertyDetails.step3.leasehold
      state.formPropertyMoreDetails['building_on_foreign_ground'] =
        state.objPropertyDetails.step3.buildingOnForeignGround
      state.formPropertyMoreDetails['property_residential_purposes'] =
        state.objPropertyDetails.step3.propertyResidentialPurposes
      state.formPropertyMoreDetails['buildings_on_third_party_owners'] =
        state.objPropertyDetails.step3.buildingsOnThirdPartyOwners
      state.formPropertyMoreDetails['lifting_rate'] = state.objPropertyDetails.step3.liftingRate
      state.formPropertyMoreDetails['registration_land_registry'] =
        state.objPropertyDetails.step3.registrationLandRegistry
      state.formPropertyMoreDetails['total_property_part'] = state.objPropertyDetails.step3.totalPropertyPart
      state.formPropertyMoreDetails['area_of_the_land_1'] = state.objPropertyDetails.step3.areaOfTheLand1
      state.formPropertyMoreDetails['area_of_the_land_value_1'] = state.objPropertyDetails.step3.areaOfTheLandValue1
      state.formPropertyMoreDetails['located_in_outdoor_area_1'] = state.objPropertyDetails.step3.locatedInOutdoorArea1
      state.formPropertyMoreDetails['area_of_the_land_2'] = state.objPropertyDetails.step3.areaOfTheLand2
      state.formPropertyMoreDetails['area_of_the_land_value_2'] = state.objPropertyDetails.step3.areaOfTheLandValue2
      state.formPropertyMoreDetails['located_in_outdoor_area_2'] = state.objPropertyDetails.step3.locatedInOutdoorArea2
      if (typeof state.objPropertyDetails.step3.parcels === 'object') {
        let objParcels = Object.values(state.objPropertyDetails.step3.parcels)
        if (objParcels.length > 0) {
          state.formPropertyMoreDetails.parcels = []
          objParcels.forEach(function (parcel) {
            let myParcel = {}
            myParcel.id = parcel.id
            myParcel.community = typeof parcel.community === 'string' ? parcel.community : ''
            myParcel.parcel = parcel.parcel
            myParcel.land_register_sheet = parcel.landRegisterSheet
            myParcel.corridor = parcel.corridor
            myParcel.area_of_the_land = parcel.areaOfTheLand
            myParcel.parcel_data_counter = parcel.parcelDataCounter
            myParcel.parcel_data_denominator = parcel.parcelDataDenominator
            myParcel.share_of_ownership_counter = parcel.shareOfOwnershipCounter
            myParcel.share_of_ownership_denominator = parcel.shareOfOwnershipDenominator
            myParcel.contained_in_area = parcel.containedInArea
            if (myParcel.contained_in_area === '') {
              myParcel.contained_in_area = '0'
            }

            state.formPropertyMoreDetails.parcels.push(myParcel)
          }, this)
        }
      }
      if (typeof state.objPropertyDetails.subArea === 'object') {
        if (
          typeof state.objPropertyDetails.subArea.id !== 'undefined' &&
          typeof state.objPropertyDetails.subArea.areaOfTotalSubarea !== 'undefined' &&
          typeof state.objPropertyDetails.subArea.landValueOfSubarea !== 'undefined' &&
          typeof state.objPropertyDetails.subArea.includedInWhichArea !== 'undefined' &&
          typeof state.objPropertyDetails.subArea.buildable !== 'undefined' &&
          typeof state.objPropertyDetails.subArea.landValueForBuildingLand !== 'undefined'
        ) {
          state.formPropertyMoreDetails['showSubAreaSection'] = true
          state.formPropertyMoreDetails.subArea.id = state.objPropertyDetails.subArea.id
          state.formPropertyMoreDetails.subArea.areaOfTotalSubarea = state.objPropertyDetails.subArea.areaOfTotalSubarea
          state.formPropertyMoreDetails.subArea.landValueOfSubarea = state.objPropertyDetails.subArea.landValueOfSubarea
          state.formPropertyMoreDetails.subArea.includedInWhichArea =
            state.objPropertyDetails.subArea.includedInWhichArea
          state.formPropertyMoreDetails.subArea.buildable = state.objPropertyDetails.subArea.buildable
          state.formPropertyMoreDetails.subArea.landValueForBuildingLand =
            state.objPropertyDetails.subArea.landValueForBuildingLand
        }
      }
    }
  },
  pushFormPropertyMoreDetails: () => {
    //console.log('state.formPropertyMoreDetails: ', state.formPropertyMoreDetails);
    state.objPropertyDetails.step3.ownershipStructure = state.formPropertyMoreDetails['ownership_structure']
    state.objPropertyDetails.step3.propertyType = state.formPropertyMoreDetails['property_type']
    state.objPropertyDetails.step3.developmentState = state.formPropertyMoreDetails['development_state']
    state.objPropertyDetails.step3.multiCommunities = state.formPropertyMoreDetails['multi_communities']
    state.objPropertyDetails.step3.leasehold = state.formPropertyMoreDetails['leasehold']
    state.objPropertyDetails.step3.buildingOnForeignGround = state.formPropertyMoreDetails['building_on_foreign_ground']
    state.objPropertyDetails.step3.propertyResidentialPurposes =
      state.formPropertyMoreDetails['property_residential_purposes']
    state.objPropertyDetails.step3.buildingsOnThirdPartyOwners =
      state.formPropertyMoreDetails['buildings_on_third_party_owners']
    state.objPropertyDetails.step3.liftingRate = state.formPropertyMoreDetails['lifting_rate']
    state.objPropertyDetails.step3.registrationLandRegistry =
      state.formPropertyMoreDetails['registration_land_registry']
    state.objPropertyDetails.step3.totalPropertyPart = state.formPropertyMoreDetails['total_property_part']
    state.objPropertyDetails.step3.areaOfTheLand1 = state.formPropertyMoreDetails['area_of_the_land_1']
    state.objPropertyDetails.step3.areaOfTheLandValue1 = state.formPropertyMoreDetails['area_of_the_land_value_1']
    state.objPropertyDetails.step3.locatedInOutdoorArea1 = state.formPropertyMoreDetails['located_in_outdoor_area_1']
    state.objPropertyDetails.step3.areaOfTheLand2 = state.formPropertyMoreDetails['area_of_the_land_2']
    state.objPropertyDetails.step3.areaOfTheLandValue2 = state.formPropertyMoreDetails['area_of_the_land_value_2']
    state.objPropertyDetails.step3.locatedInOutdoorArea2 = state.formPropertyMoreDetails['located_in_outdoor_area_2']
    if (typeof state.formPropertyMoreDetails.parcels === 'object' && state.formPropertyMoreDetails.parcels.length > 0) {
      state.objPropertyDetails.step3.parcels = []
      state.formPropertyMoreDetails.parcels.forEach(function (parcel) {
        let myParcel = {}
        myParcel.id = parcel.id
        myParcel.community = parcel.community
        myParcel.parcel = parcel.parcel
        myParcel.landRegisterSheet = parcel.land_register_sheet
        myParcel.corridor = parcel.corridor
        myParcel.areaOfTheLand = parcel.area_of_the_land
        myParcel.parcelDataCounter = parcel.parcel_data_counter
        myParcel.parcelDataDenominator = parcel.parcel_data_denominator
        myParcel.shareOfOwnershipCounter = parcel.share_of_ownership_counter
        myParcel.shareOfOwnershipDenominator = parcel.share_of_ownership_denominator
        myParcel.containedInArea = parcel.contained_in_area

        state.objPropertyDetails.step3.parcels.push(myParcel)
      })
    }
    state.objPropertyDetails.subArea = null
    if (state.formPropertyMoreDetails['showSubAreaSection']) {
      state.objPropertyDetails.subArea = {}
      state.objPropertyDetails.subArea.id = state.formPropertyMoreDetails.subArea['id']
      state.objPropertyDetails.subArea.areaOfTotalSubarea = state.formPropertyMoreDetails.subArea['areaOfTotalSubarea']
      state.objPropertyDetails.subArea.landValueOfSubarea = state.formPropertyMoreDetails.subArea['landValueOfSubarea']
      state.objPropertyDetails.subArea.includedInWhichArea =
        state.formPropertyMoreDetails.subArea['includedInWhichArea']
      state.objPropertyDetails.subArea.buildable = state.formPropertyMoreDetails.subArea['buildable']
      state.objPropertyDetails.subArea.landValueForBuildingLand =
        state.formPropertyMoreDetails.subArea['landValueForBuildingLand']
    }
  },

  setFormPropertyBenefits: (state, val) => {
    if (typeof val === 'object') {
      state.formPropertyBenefits = JSON.parse(JSON.stringify(val))
    } else {
      state.formPropertyBenefits = val
    }
  },
  updateFormPropertyBenefits: () => {
    if (state.formPropertyBenefits === '') {
      state.formPropertyBenefits = Object.assign({}, defaultPropertyBenefits)
    }
    if (typeof state.objPropertyDetails === 'object') {
      state.formPropertyBenefits['tax_exemption'] = state.objPropertyDetails.step3a.taxExemption
      state.formPropertyBenefits['benefit'] = state.objPropertyDetails.step3a.benefit
      state.formPropertyBenefits['monument_identification'] = state.objPropertyDetails.step3a.monument_identification
      state.formPropertyBenefits['non_separable_part_real_estate'] =
        state.objPropertyDetails.step3a.nonSeparablePartRealEstate
      state.formPropertyBenefits['property_exemption_benefit_type'] =
        state.objPropertyDetails.step3a.propertyExemptionBenefitType
      state.formPropertyBenefits['expiration_first_grant_period'] =
        state.objPropertyDetails.step3a.expirationFirstGrantPeriod
      state.formPropertyBenefits['expiration_last_grant_period'] =
        state.objPropertyDetails.step3a.expirationLastGrantPeriod
      state.formPropertyBenefits['expected_exempt_purposes'] = state.objPropertyDetails.step3a.expectedExemptPurposes
      state.formPropertyBenefits['tax_privileged_purposes'] = state.objPropertyDetails.step3a.taxPrivilegedPurposes
      if (typeof state.objPropertyDetails.step3a.unbuiltRealEstateExemptions === 'object') {
        let objExemptions = Object.values(state.objPropertyDetails.step3a.unbuiltRealEstateExemptions)
        if (objExemptions.length > 0) {
          state.formPropertyBenefits.unbuilt_real_estate_exemptions = []
          objExemptions.forEach(function (exemption) {
            let myExemption = {}
            let myTaxExemption = '0'
            myExemption.id = exemption.primUid
            myExemption.unbuilt_real_estate_exemption_description = exemption.unbuiltRealEstateExemptionDescription
            myExemption.tax_free_area = exemption.taxFreeArea
            myTaxExemption = parseInt(exemption.unbuiltRealEstateTaxExemption)
            if (isNaN(myTaxExemption)) {
              myTaxExemption = '0'
            } else {
              myTaxExemption = myTaxExemption.toString()
            }
            myExemption.unbuilt_real_estate_tax_exemption = myTaxExemption
            state.formPropertyBenefits.unbuilt_real_estate_exemptions.push(myExemption)
          }, this)
        }
      }
      if (typeof state.objPropertyDetails.step3a.useOfDefinableParts === 'object') {
        let objParts = Object.values(state.objPropertyDetails.step3a.useOfDefinableParts)
        if (objParts.length > 0) {
          state.formPropertyBenefits.use_of_definable_parts = []
          objParts.forEach(function (part) {
            let myPart = {}
            let myTaxExemption = '0'
            myPart.id = part.primUid
            myPart.spatially_definable_exemption_part_description = part.spatiallyDefinableExemptionPartDescription
            myPart.spatially_definable_exemption_area = part.spatiallyDefinableExemptionArea
            myTaxExemption = parseInt(part.spatiallyDefinableTaxExemptionPart)
            if (isNaN(myTaxExemption)) {
              myTaxExemption = '0'
            } else {
              myTaxExemption = myTaxExemption.toString()
            }
            myPart.spatially_definable_tax_exemption_part = myTaxExemption
            state.formPropertyBenefits.use_of_definable_parts.push(myPart)
          }, this)
        }
      }
      if (typeof state.objPropertyDetails.step3a.taxBreakConditions === 'object') {
        let objConditions = Object.values(state.objPropertyDetails.step3a.taxBreakConditions)
        if (objConditions.length > 0) {
          state.formPropertyBenefits.tax_break_conditions = []
          objConditions.forEach(function (condition) {
            let myCondition = {}
            let myTaxExemption = '0'
            myCondition.id = condition.primUid
            myCondition.spatially_definable_exemption_part_description =
              condition.spatiallyDefinableExemptionPartDescription
            myCondition.spatially_definable_exemption_area = condition.spatiallyDefinableExemptionArea
            myTaxExemption = parseInt(condition.spatiallyDefinableTaxExemptionPart)
            if (isNaN(myTaxExemption)) {
              myTaxExemption = '0'
            } else {
              myTaxExemption = myTaxExemption.toString()
            }
            myCondition.spatially_definable_tax_exemption_part = myTaxExemption
            state.formPropertyBenefits.tax_break_conditions.push(myCondition)
          }, this)
        }
      }
    }
  },

  pushFormPropertyBenefits: () => {
    state.objPropertyDetails.step3a.taxExemption = state.formPropertyBenefits['tax_exemption']
    state.objPropertyDetails.step3a.benefit = state.formPropertyBenefits['benefit']
    state.objPropertyDetails.step3a.nonSeparablePartRealEstate =
      state.formPropertyBenefits['non_separable_part_real_estate']
    state.objPropertyDetails.step3a.propertyExemptionBenefitType =
      state.formPropertyBenefits['property_exemption_benefit_type']
    state.objPropertyDetails.step3a.expirationFirstGrantPeriod =
      state.formPropertyBenefits['expiration_first_grant_period']
    state.objPropertyDetails.step3a.expirationLastGrantPeriod =
      state.formPropertyBenefits['expiration_last_grant_period']
    state.objPropertyDetails.step3a.expectedExemptPurposes = state.formPropertyBenefits['expected_exempt_purposes']
    state.objPropertyDetails.step3a.taxPrivilegedPurposes = state.formPropertyBenefits['tax_privileged_purposes']
    state.objPropertyDetails.step3a.monument_identification = state.formPropertyBenefits['monument_identification']

    state.objPropertyDetails.step3a.unbuiltRealEstateExemptions = []
    if (
      typeof state.formPropertyBenefits.unbuilt_real_estate_exemptions === 'object' &&
      state.formPropertyBenefits.unbuilt_real_estate_exemptions.length > 0
    ) {
      state.formPropertyBenefits.unbuilt_real_estate_exemptions.forEach(function (exemption) {
        let myExemption = {}
        myExemption.primUid = exemption.id
        myExemption.unbuiltRealEstateExemptionDescription = exemption.unbuilt_real_estate_exemption_description
        myExemption.taxFreeArea = exemption.tax_free_area
        myExemption.unbuiltRealEstateTaxExemption = exemption.unbuilt_real_estate_tax_exemption

        state.objPropertyDetails.step3a.unbuiltRealEstateExemptions.push(myExemption)
      })
    }

    state.objPropertyDetails.step3a.useOfDefinableParts = []
    if (
      typeof state.formPropertyBenefits.use_of_definable_parts === 'object' &&
      state.formPropertyBenefits.use_of_definable_parts.length > 0
    ) {
      state.formPropertyBenefits.use_of_definable_parts.forEach(function (part) {
        let myPart = {}
        myPart.primUid = part.id
        myPart.spatiallyDefinableExemptionPartDescription = part.spatially_definable_exemption_part_description
        myPart.spatiallyDefinableExemptionArea = part.spatially_definable_exemption_area
        myPart.spatiallyDefinableTaxExemptionPart = part.spatially_definable_tax_exemption_part

        state.objPropertyDetails.step3a.useOfDefinableParts.push(myPart)
      })
    }

    state.objPropertyDetails.step3a.taxBreakConditions = []
    if (
      typeof state.formPropertyBenefits.tax_break_conditions === 'object' &&
      state.formPropertyBenefits.tax_break_conditions.length > 0
    ) {
      state.formPropertyBenefits.tax_break_conditions.forEach(function (condition) {
        let myCondition = {}
        myCondition.primUid = condition.id
        myCondition.spatiallyDefinableExemptionPartDescription =
          condition.spatially_definable_exemption_part_description
        myCondition.spatiallyDefinableExemptionArea = condition.spatially_definable_exemption_area
        myCondition.spatiallyDefinableTaxExemptionPart = condition.spatially_definable_tax_exemption_part

        state.objPropertyDetails.step3a.taxBreakConditions.push(myCondition)
      })
    }
  },

  setFormPropertyCommunity: (state, val) => {
    if (typeof val === 'object') {
      state.formPropertyCommunity = JSON.parse(JSON.stringify(val))
    } else {
      state.formPropertyCommunity = val
    }
  },
  updateFormPropertyCommunity: () => {
    if (state.formPropertyCommunity === '') {
      state.formPropertyCommunity = JSON.parse(JSON.stringify(defaultPropertyCommunity))
    }

    if (typeof state.objPropertyDetails === 'object') {
      state.formPropertyCommunity['id'] = state.objPropertyDetails.step4.primUid
      state.formPropertyCommunity['salutation'] = state.objPropertyDetails.step4.salutation
      if (state.formPropertyCommunity['salutation'] === '') {
        state.formPropertyCommunity['salutation'] = defaultPropertyCommunity.salutation
      }
      state.formPropertyCommunity['name'] = state.objPropertyDetails.step4.name
      state.formPropertyCommunity['street'] = state.objPropertyDetails.step4.street
      state.formPropertyCommunity['house_number'] = state.objPropertyDetails.step4.houseNumber
      state.formPropertyCommunity['zip'] = state.objPropertyDetails.step4.zip
      state.formPropertyCommunity['postbox'] = state.objPropertyDetails.step4.postbox
      state.formPropertyCommunity['city'] = state.objPropertyDetails.step4.city
      state.formPropertyCommunity['country_uid'] = state.objPropertyDetails.step4.countryUid
      if (state.formPropertyCommunity['country_uid'] === '') {
        state.formPropertyCommunity['country_uid'] = defaultPropertyCommunity.country_uid
      }
    }
  },
  pushFormPropertyCommunity: () => {
    state.objPropertyDetails.step4.primUid = state.formPropertyCommunity['id']
    state.objPropertyDetails.step4.salutation = state.formPropertyCommunity['salutation']
    state.objPropertyDetails.step4.name = state.formPropertyCommunity['name']
    state.objPropertyDetails.step4.street = state.formPropertyCommunity['street']
    state.objPropertyDetails.step4.houseNumber = state.formPropertyCommunity['house_number']
    state.objPropertyDetails.step4.zip = state.formPropertyCommunity['zip']
    state.objPropertyDetails.step4.postbox = state.formPropertyCommunity['postbox']
    state.objPropertyDetails.step4.city = state.formPropertyCommunity['city']
    state.objPropertyDetails.step4.countryUid = state.formPropertyCommunity['country_uid']
  },

  setFormPropertyOwnership: (state, val) => {
    if (typeof val === 'object') {
      state.formPropertyOwnership = JSON.parse(JSON.stringify(val))
    } else {
      state.formPropertyOwnership = val
    }
  },
  updateFormPropertyOwnership: () => {
    if (state.formPropertyOwnership === '') {
      state.formPropertyOwnership = JSON.parse(JSON.stringify(defaultPropertyOwnership))
      state.formPropertyOwnership.owners = []
    }

    if (typeof state.objPropertyDetails === 'object') {
      if (typeof state.objPropertyDetails.stepOwner === 'object') {
        state.formPropertyOwnership.owners = []
        let objOwners = Object.values(state.objPropertyDetails.stepOwner)
        if (objOwners.length > 0) {
          objOwners.forEach(function (owner) {
            let myOwner = {}
            myOwner.id = owner.primUid
            myOwner.salutation = owner.salutation !== '' ? owner.salutation.toString() : '0'
            myOwner.title = owner.title
            myOwner.dateOfBirth = owner.dateOfBirth
            myOwner.firstName = owner.firstName
            myOwner.lastName = owner.lastName
            myOwner.street = owner.street
            myOwner.houseNumber = owner.houseNumber
            myOwner.phone = owner.phone
            myOwner.zip = owner.zip
            myOwner.postbox = owner.postbox
            myOwner.city = owner.city
            myOwner.federalStateUid = owner.federalStateUid !== '' ? owner.federalStateUid.toString() : '0'
            myOwner.countryUid = owner.countryUid !== '' ? owner.countryUid.toString() : '0'
            myOwner.financeAgency = owner.financeAgency
            myOwner.taxNumber = owner.taxNumber
            myOwner.taxIdentificationNumber = owner.taxIdentificationNumber
            myOwner.area_of_the_land_counter = owner.area_of_the_land_counter
            myOwner.area_of_the_land_denominator = owner.area_of_the_land_denominator
            myOwner.representative = {}
            myOwner.is_legal_representative = '0'
            if (typeof owner.representative === 'object') {
              myOwner.representative.salutation =
                owner.representative.salutation !== '' ? owner.representative.salutation.toString() : '0'
              myOwner.representative.title = owner.representative.title
              myOwner.representative.dateOfBirth = owner.representative.dateOfBirth
              myOwner.representative.firstName = owner.representative.firstName
              myOwner.representative.lastName = owner.representative.lastName
              myOwner.representative.street = owner.representative.street
              myOwner.representative.houseNumber = owner.representative.houseNumber
              myOwner.representative.phone = owner.representative.phone
              myOwner.representative.zip = owner.representative.zip
              myOwner.representative.postbox = owner.representative.postbox
              myOwner.representative.city = owner.representative.city
              myOwner.representative.countryUid =
                owner.representative.countryUid !== '' ? owner.representative.countryUid.toString() : '0'

              if (
                myOwner.representative.salutation !== '0' ||
                myOwner.representative.title !== '' ||
                myOwner.representative.dateOfBirth ||
                myOwner.representative.firstName !== '' ||
                myOwner.representative.lastName !== '' ||
                myOwner.representative.street !== '' ||
                myOwner.representative.houseNumber !== '' ||
                myOwner.representative.phone !== '' ||
                myOwner.representative.zip !== '' ||
                myOwner.representative.postbox !== '' ||
                myOwner.representative.city !== '' ||
                myOwner.representative.countryUid !== '0'
              ) {
                myOwner.is_legal_representative = '1'
              }
            }
            state.formPropertyOwnership.owners.push(myOwner)
          }, this)
        }
      }
    }
  },
  pushFormPropertyOwnership: () => {
    state.objPropertyDetails.stepOwner = []
    if (typeof state.formPropertyOwnership.owners === 'object' && state.formPropertyOwnership.owners.length > 0) {
      state.formPropertyOwnership.owners.forEach(function (owner) {
        let myOwner = {}
        myOwner.primUid = owner.id
        myOwner.salutation = owner.salutation
        myOwner.title = owner.title
        myOwner.dateOfBirth = owner.dateOfBirth ? owner.dateOfBirth : null
        myOwner.firstName = owner.firstName
        myOwner.lastName = owner.lastName
        myOwner.street = owner.street
        myOwner.houseNumber = owner.houseNumber
        myOwner.phone = owner.phone
        myOwner.zip = owner.zip
        myOwner.postbox = owner.postbox
        myOwner.city = owner.city
        myOwner.federalStateUid = owner.federalStateUid
        myOwner.countryUid = owner.countryUid
        myOwner.financeAgency = owner.financeAgency
        myOwner.taxNumber = owner.taxNumber
        myOwner.taxIdentificationNumber = owner.taxIdentificationNumber
        myOwner.area_of_the_land_counter = owner.area_of_the_land_counter
        myOwner.area_of_the_land_denominator = owner.area_of_the_land_denominator
        myOwner.representative = {}
        if (owner.is_legal_representative === '1') {
          if (typeof owner.representative === 'object') {
            myOwner.representative.salutation = owner.representative.salutation
            myOwner.representative.title = owner.representative.title
            myOwner.representative.dateOfBirth = owner.representative.dateOfBirth
              ? owner.representative.dateOfBirth
              : null
            myOwner.representative.firstName = owner.representative.firstName
            myOwner.representative.lastName = owner.representative.lastName
            myOwner.representative.street = owner.representative.street
            myOwner.representative.houseNumber = owner.representative.houseNumber
            myOwner.representative.phone = owner.representative.phone
            myOwner.representative.zip = owner.representative.zip
            myOwner.representative.postbox = owner.representative.postbox
            myOwner.representative.city = owner.representative.city
            myOwner.representative.countryUid = owner.representative.countryUid
          }
        }

        state.objPropertyDetails.stepOwner.push(myOwner)
      })
    }
  },

  setFormPropertyBuildings: (state, val) => {
    if (typeof val === 'object') {
      state.formPropertyBuildings = JSON.parse(JSON.stringify(val))
    } else {
      state.formPropertyBuildings = val
    }
  },
  updateFormPropertyBuildings: () => {
    if (state.formPropertyBuildings === '') {
      state.formPropertyBuildings = JSON.parse(JSON.stringify(defaultPropertyBuildings))
      state.formPropertyBuildings.buildings = []
    }

    if (typeof state.objPropertyDetails === 'object') {
      if (typeof state.objPropertyDetails.step5 === 'object') {
        let objBuildings = Object.values(state.objPropertyDetails.step5)
        state.formPropertyBuildings.buildings = []
        if (objBuildings.length > 0) {
          objBuildings.forEach(function (building) {
            let myBuilding = {}
            myBuilding.id = building.primUid
            myBuilding.livingSpaceDescription = building.livingSpaceDescription
            myBuilding.livingSpaceArea = building.livingSpaceArea
            myBuilding.usableSpaceArea = building.usableSpaceArea
            myBuilding.areaOfTheLandCivilDefense = building.areaOfTheLandCivilDefense

            myBuilding.benefits = []
            if (typeof building.benefits === 'object') {
              let objBenefits = Object.values(building.benefits)
              if (objBenefits.length > 0) {
                objBenefits.forEach(function (benefit) {
                  let myBenefit = {}
                  myBenefit.id = benefit.primUid
                  myBenefit.benefitDescription = benefit.benefitDescription
                  myBenefit.benefitLivingSpaceArea = benefit.benefitLivingSpaceArea
                  myBenefit.benefitUsableSpaceArea = benefit.benefitUsableSpaceArea
                  myBenefit.benefit = benefit.benefit !== '' ? benefit.benefit.toString() : '0'

                  myBuilding.benefits.push(myBenefit)
                }, this)
              }
            }

            myBuilding.exemptions = []
            if (typeof building.exemptions === 'object') {
              let objExemptions = Object.values(building.exemptions)
              if (objExemptions.length > 0) {
                objExemptions.forEach(function (exemption) {
                  let myExemption = {}
                  myExemption.id = exemption.primUid
                  myExemption.exemptionDescription = exemption.exemptionDescription
                  myExemption.exemptionLivingSpaceArea = exemption.exemptionLivingSpaceArea
                  myExemption.exemptionUsableSpaceArea = exemption.exemptionUsableSpaceArea
                  myExemption.exemption = exemption.exemption !== '' ? exemption.exemption.toString() : '0'

                  myBuilding.exemptions.push(myExemption)
                }, this)
              }
            }

            state.formPropertyBuildings.buildings.push(myBuilding)
          }, this)
        }
      }
    }
  },
  pushFormPropertyBuildings: () => {
    state.objPropertyDetails.step5 = []
    if (typeof state.formPropertyBuildings.buildings === 'object' && state.formPropertyBuildings.buildings.length > 0) {
      state.formPropertyBuildings.buildings.forEach(function (building) {
        let myBuilding = {}
        myBuilding.primUid = building.id
        myBuilding.livingSpaceDescription = building.livingSpaceDescription
        myBuilding.livingSpaceArea = building.livingSpaceArea !== '' ? building.livingSpaceArea : null
        myBuilding.usableSpaceArea = building.usableSpaceArea !== '' ? building.usableSpaceArea : null
        myBuilding.areaOfTheLandCivilDefense =
          building.areaOfTheLandCivilDefense !== '' ? building.areaOfTheLandCivilDefense : null

        myBuilding.benefits = []
        if (typeof building.benefits === 'object' && building.benefits.length > 0) {
          building.benefits.forEach(function (benefit) {
            let myBenefit = {}
            myBenefit.primUid = benefit.id
            myBenefit.benefitDescription = benefit.benefitDescription
            myBenefit.benefitLivingSpaceArea =
              benefit.benefitLivingSpaceArea !== '' ? benefit.benefitLivingSpaceArea : null
            myBenefit.benefitUsableSpaceArea =
              benefit.benefitUsableSpaceArea !== '' ? benefit.benefitUsableSpaceArea : null
            myBenefit.benefit = benefit.benefit
            myBuilding.benefits.push(myBenefit)
          }, this)
        }

        myBuilding.exemptions = []
        if (typeof building.exemptions === 'object' && building.exemptions.length > 0) {
          building.exemptions.forEach(function (exemption) {
            let myExemption = {}
            myExemption.primUid = exemption.id
            myExemption.exemptionDescription = exemption.exemptionDescription
            myExemption.exemptionLivingSpaceArea =
              exemption.exemptionLivingSpaceArea !== '' ? exemption.exemptionLivingSpaceArea : null
            myExemption.exemptionUsableSpaceArea =
              exemption.exemptionUsableSpaceArea !== '' ? exemption.exemptionUsableSpaceArea : null
            myExemption.exemption = exemption.exemption
            myBuilding.exemptions.push(myExemption)
          }, this)
        }

        state.objPropertyDetails.step5.push(myBuilding)
      }, this)
    }
  },

  setFormPropertyResidential: (state, val) => {
    if (typeof val === 'object') {
      state.formPropertyResidential = JSON.parse(JSON.stringify(val))
    } else {
      state.formPropertyResidential = val
    }
  },
  updateFormPropertyResidential: () => {
    if (state.formPropertyResidential === '') {
      state.formPropertyResidential = JSON.parse(JSON.stringify(defaultPropertyResidential))
      state.formPropertyResidential.residentials = []
    }

    if (typeof state.objPropertyDetails === 'object') {
      if (typeof state.objPropertyDetails.step6 === 'object') {
        state.formPropertyResidential.residentials = []
        let objResidentials = Object.values(state.objPropertyDetails.step6)
        if (objResidentials.length > 0) {
          objResidentials.forEach(function (residential) {
            let myResidential = JSON.parse(JSON.stringify(defaultPropertyResidential)).residentials[0]
            myResidential.garageUnderground.garageUndergroundExemption = []
            myResidential.garageUnderground.garageUndergroundBenefit = []
            myResidential.apartmentsBelow60.apartmentsBelow60Exemption = []
            myResidential.apartmentsBelow60.apartmentsBelow60Benefit = []
            myResidential.apartmentsBetween60100.apartmentsBetween60100Exemption = []
            myResidential.apartmentsBetween60100.apartmentsBetween60100Benefit = []
            myResidential.apartmentsAbove100.apartmentsAbove100Exemption = []
            myResidential.apartmentsAbove100.apartmentsAbove100Benefit = []
            myResidential.apartmentsOther.apartmentsOtherExemption = []
            myResidential.apartmentsOther.apartmentsOtherBenefit = []
            myResidential.usableSpaces.usableSpacesExemption = []
            myResidential.usableSpaces.usableSpacesBenefit = []

            myResidential.id = residential.primUid
            myResidential.residential.yearOfConstruction = residential.yearOfConstruction
            myResidential.residential.coreRenovationYear = residential.coreRenovationYear
            myResidential.residential.demolitionObligationYear = residential.demolitionObligationYear

            myResidential.isSectionGarage = '0'
            myResidential.isSectionBelow60 = '0'
            myResidential.isSectionBetween60100 = '0'
            myResidential.isSectionAbove100 = '0'
            myResidential.isSectionOther = '0'
            myResidential.isSectionUsableSpaces = '0'

            myResidential.garageUnderground.amountGarages =
              residential.garageUnderground.amountGarages === null
                ? defaultPropertyResidential.residentials[0].garageUnderground.amountGarages
                : residential.garageUnderground.amountGarages
            myResidential.garageUnderground.id =
              typeof residential.garageUnderground.primUid !== 'undefined'
                ? residential.garageUnderground.primUid
                : defaultPropertyResidential.residentials[0].garageUnderground.id
            if (myResidential.garageUnderground.id.toString() !== '0') {
              myResidential.isSectionGarage = '1'
            }
            if (typeof residential.garageUnderground.garageUndergroundExemption === 'object') {
              let objGarageUndergroundExemption = Object.values(
                residential.garageUnderground.garageUndergroundExemption,
              )
              if (objGarageUndergroundExemption.length > 0) {
                objGarageUndergroundExemption.forEach(function (exemption) {
                  let myExemption = {}
                  myExemption['id'] = exemption.primUid
                  myExemption['locationOfTheRoomsDesignation'] = exemption.locationOfTheRoomsDesignation
                  myExemption['exemption'] = exemption.exemption
                  myExemption['taxFreeArea'] = exemption.taxFreeArea
                  myResidential.garageUnderground.garageUndergroundExemption.push(myExemption)
                }, this)
              }
            }
            if (typeof residential.garageUnderground.garageUndergroundBenefit === 'object') {
              let objGarageUndergroundBenefit = Object.values(residential.garageUnderground.garageUndergroundBenefit)
              if (objGarageUndergroundBenefit.length > 0) {
                objGarageUndergroundBenefit.forEach(function (benefit) {
                  let myBenefit = {}
                  myBenefit['id'] = benefit.primUid
                  myBenefit['locationOfTheRoomsDesignation'] = benefit.locationOfTheRoomsDesignation
                  myBenefit['benefit'] = benefit.benefit
                  myBenefit['discountedArea'] = benefit.discountedArea
                  myResidential.garageUnderground.garageUndergroundBenefit.push(myBenefit)
                }, this)
              }
            }

            myResidential.apartmentsBelow60.counter =
              residential.apartmentsBelow60.counter === null
                ? defaultPropertyResidential.residentials[0].apartmentsBelow60.counter
                : residential.apartmentsBelow60.counter
            myResidential.apartmentsBelow60.totalLivingSpace =
              residential.apartmentsBelow60.totalLivingSpace === null
                ? defaultPropertyResidential.residentials[0].apartmentsBelow60.totalLivingSpace
                : residential.apartmentsBelow60.totalLivingSpace
            myResidential.apartmentsBelow60.id =
              typeof residential.apartmentsBelow60.primUid !== 'undefined'
                ? residential.apartmentsBelow60.primUid
                : defaultPropertyResidential.residentials[0].apartmentsBelow60.id
            if (myResidential.apartmentsBelow60.id.toString() !== '0') {
              myResidential.isSectionBelow60 = '1'
            }

            if (typeof residential.apartmentsBelow60.apartmentsBelow60Exemption === 'object') {
              let objApartmentsBelow60Exemption = Object.values(
                residential.apartmentsBelow60.apartmentsBelow60Exemption,
              )
              if (objApartmentsBelow60Exemption.length > 0) {
                objApartmentsBelow60Exemption.forEach(function (exemption) {
                  let myExemption = {}
                  myExemption['id'] = exemption.primUid
                  myExemption['locationOfTheRoomsDesignation'] = exemption.locationOfTheRoomsDesignation
                  myExemption['exemption'] = exemption.exemption
                  myExemption['taxFreeArea'] = exemption.taxFreeArea
                  myResidential.apartmentsBelow60.apartmentsBelow60Exemption.push(myExemption)
                }, this)
              }
            }
            if (typeof residential.apartmentsBelow60.apartmentsBelow60Benefit === 'object') {
              let objApartmentsBelow60Benefit = Object.values(residential.apartmentsBelow60.apartmentsBelow60Benefit)
              if (objApartmentsBelow60Benefit.length > 0) {
                objApartmentsBelow60Benefit.forEach(function (benefit) {
                  let myBenefit = {}
                  myBenefit['id'] = benefit.primUid
                  myBenefit['locationOfTheRoomsDesignation'] = benefit.locationOfTheRoomsDesignation
                  myBenefit['benefit'] = benefit.benefit
                  myBenefit['discountedArea'] = benefit.discountedArea
                  myResidential.apartmentsBelow60.apartmentsBelow60Benefit.push(myBenefit)
                }, this)
              }
            }

            myResidential.apartmentsBetween60100.counter =
              residential.apartmentsBetween60100.counter === null
                ? defaultPropertyResidential.residentials[0].apartmentsBetween60100.counter
                : residential.apartmentsBetween60100.counter
            myResidential.apartmentsBetween60100.totalLivingSpace =
              residential.apartmentsBetween60100.totalLivingSpace === null
                ? defaultPropertyResidential.residentials[0].apartmentsBetween60100.totalLivingSpace
                : residential.apartmentsBetween60100.totalLivingSpace
            myResidential.apartmentsBetween60100.id =
              typeof residential.apartmentsBetween60100.primUid !== 'undefined'
                ? residential.apartmentsBetween60100.primUid
                : defaultPropertyResidential.residentials[0].apartmentsBetween60100.id
            if (myResidential.apartmentsBetween60100.id.toString() !== '0') {
              myResidential.isSectionBetween60100 = '1'
            }

            if (typeof residential.apartmentsBetween60100.apartmentsBetween60100Exemption === 'object') {
              let objApartmentsBetween60100Exemption = Object.values(
                residential.apartmentsBetween60100.apartmentsBetween60100Exemption,
              )
              if (objApartmentsBetween60100Exemption.length > 0) {
                objApartmentsBetween60100Exemption.forEach(function (exemption) {
                  let myExemption = {}
                  myExemption['id'] = exemption.primUid
                  myExemption['locationOfTheRoomsDesignation'] = exemption.locationOfTheRoomsDesignation
                  myExemption['exemption'] = exemption.exemption
                  myExemption['taxFreeArea'] = exemption.taxFreeArea
                  myResidential.apartmentsBetween60100.apartmentsBetween60100Exemption.push(myExemption)
                }, this)
              }
            }
            if (typeof residential.apartmentsBetween60100.apartmentsBetween60100Benefit === 'object') {
              let objApartmentsBetween60100Benefit = Object.values(
                residential.apartmentsBetween60100.apartmentsBetween60100Benefit,
              )
              if (objApartmentsBetween60100Benefit.length > 0) {
                objApartmentsBetween60100Benefit.forEach(function (benefit) {
                  let myBenefit = {}
                  myBenefit['id'] = benefit.primUid
                  myBenefit['locationOfTheRoomsDesignation'] = benefit.locationOfTheRoomsDesignation
                  myBenefit['benefit'] = benefit.benefit
                  myBenefit['discountedArea'] = benefit.discountedArea
                  myResidential.apartmentsBetween60100.apartmentsBetween60100Benefit.push(myBenefit)
                }, this)
              }
            }

            myResidential.apartmentsAbove100.counter =
              residential.apartmentsAbove100.counter === null
                ? defaultPropertyResidential.residentials[0].apartmentsAbove100.counter
                : residential.apartmentsAbove100.counter
            myResidential.apartmentsAbove100.totalLivingSpace =
              residential.apartmentsAbove100.totalLivingSpace === null
                ? defaultPropertyResidential.residentials[0].apartmentsAbove100.totalLivingSpace
                : residential.apartmentsAbove100.totalLivingSpace
            myResidential.apartmentsAbove100.id =
              typeof residential.apartmentsAbove100.primUid !== 'undefined'
                ? residential.apartmentsAbove100.primUid
                : defaultPropertyResidential.residentials[0].apartmentsAbove100.id
            if (myResidential.apartmentsAbove100.id.toString() !== '0') {
              myResidential.isSectionAbove100 = '1'
            }

            if (typeof residential.apartmentsAbove100.apartmentsAbove100Exemption === 'object') {
              let objApartmentsAbove100Exemption = Object.values(
                residential.apartmentsAbove100.apartmentsAbove100Exemption,
              )
              if (objApartmentsAbove100Exemption.length > 0) {
                objApartmentsAbove100Exemption.forEach(function (exemption) {
                  let myExemption = {}
                  myExemption['id'] = exemption.primUid
                  myExemption['locationOfTheRoomsDesignation'] = exemption.locationOfTheRoomsDesignation
                  myExemption['exemption'] = exemption.exemption
                  myExemption['taxFreeArea'] = exemption.taxFreeArea
                  myResidential.apartmentsAbove100.apartmentsAbove100Exemption.push(myExemption)
                }, this)
              }
            }
            if (typeof residential.apartmentsAbove100.apartmentsAbove100Benefit === 'object') {
              let objApartmentsAbove100Benefit = Object.values(residential.apartmentsAbove100.apartmentsAbove100Benefit)
              if (objApartmentsAbove100Benefit.length > 0) {
                objApartmentsAbove100Benefit.forEach(function (benefit) {
                  let myBenefit = {}
                  myBenefit['id'] = benefit.primUid
                  myBenefit['locationOfTheRoomsDesignation'] = benefit.locationOfTheRoomsDesignation
                  myBenefit['benefit'] = benefit.benefit
                  myBenefit['discountedArea'] = benefit.discountedArea
                  myResidential.apartmentsAbove100.apartmentsAbove100Benefit.push(myBenefit)
                }, this)
              }
            }

            myResidential.apartmentsOther.counter =
              residential.apartmentsOther.counter === null
                ? defaultPropertyResidential.residentials[0].apartmentsOther.counter
                : residential.apartmentsOther.counter
            myResidential.apartmentsOther.totalLivingSpace =
              residential.apartmentsOther.totalLivingSpace === null
                ? defaultPropertyResidential.residentials[0].apartmentsOther.totalLivingSpace
                : residential.apartmentsOther.totalLivingSpace
            myResidential.apartmentsOther.id =
              typeof residential.apartmentsOther.primUid !== 'undefined'
                ? residential.apartmentsOther.primUid
                : defaultPropertyResidential.residentials[0].apartmentsOther.id
            if (myResidential.apartmentsOther.id.toString() !== '0') {
              myResidential.isSectionOther = '1'
            }

            if (typeof residential.apartmentsOther.apartmentsOtherExemption === 'object') {
              let objApartmentsOtherExemption = Object.values(residential.apartmentsOther.apartmentsOtherExemption)
              if (objApartmentsOtherExemption.length > 0) {
                objApartmentsOtherExemption.forEach(function (exemption) {
                  let myExemption = {}
                  myExemption['id'] = exemption.primUid
                  myExemption['locationOfTheRoomsDesignation'] = exemption.locationOfTheRoomsDesignation
                  myExemption['exemption'] = exemption.exemption
                  myExemption['taxFreeArea'] = exemption.taxFreeArea
                  myResidential.apartmentsOther.apartmentsOtherExemption.push(myExemption)
                }, this)
              }
            }
            if (typeof residential.apartmentsOther.apartmentsOtherBenefit === 'object') {
              let objApartmentsOtherBenefit = Object.values(residential.apartmentsOther.apartmentsOtherBenefit)
              if (objApartmentsOtherBenefit.length > 0) {
                objApartmentsOtherBenefit.forEach(function (benefit) {
                  let myBenefit = {}
                  myBenefit['id'] = benefit.primUid
                  myBenefit['locationOfTheRoomsDesignation'] = benefit.locationOfTheRoomsDesignation
                  myBenefit['benefit'] = benefit.benefit
                  myBenefit['discountedArea'] = benefit.discountedArea
                  myResidential.apartmentsOther.apartmentsOtherBenefit.push(myBenefit)
                }, this)
              }
            }

            myResidential.usableSpaces = []
            if (typeof residential.usableSpaces === 'object') {
              let objUsableSpaces = Object.values(residential.usableSpaces)
              if (objUsableSpaces.length > 0) {
                myResidential.isSectionUsableSpaces = '1'
                objUsableSpaces.forEach((usableSpace) => {
                  let myUsableSpace = {}
                  myUsableSpace.id =
                    typeof usableSpace.primUid !== 'undefined'
                      ? usableSpace.primUid
                      : defaultPropertyResidential.residentials[0].usableSpaces[0].id
                  myUsableSpace.usageType =
                    usableSpace.usageType === null
                      ? defaultPropertyResidential.residentials[0].usableSpaces[0].usageType
                      : usableSpace.usageType
                  myUsableSpace.totalSpace =
                    usableSpace.totalSpace === null
                      ? defaultPropertyResidential.residentials[0].usableSpaces[0].totalSpace
                      : usableSpace.totalSpace
                  myUsableSpace.locationOfTheRoomsDesignation =
                    usableSpace.locationOfTheRoomsDesignation === null
                      ? defaultPropertyResidential.residentials[0].usableSpaces[0].locationOfTheRoomsDesignation
                      : usableSpace.locationOfTheRoomsDesignation

                  myUsableSpace.usableSpacesExemption = []
                  if (typeof usableSpace.usableSpacesExemption === 'object') {
                    let objUsableSpacesExemption = Object.values(usableSpace.usableSpacesExemption)
                    if (objUsableSpacesExemption.length > 0) {
                      objUsableSpacesExemption.forEach(function (exemption) {
                        let myExemption = {}
                        myExemption['id'] = exemption.primUid
                        myExemption['exemption'] = exemption.exemption
                        myExemption['taxFreeArea'] = exemption.taxFreeArea
                        myUsableSpace.usableSpacesExemption.push(myExemption)
                      }, this)
                    }
                  }

                  myUsableSpace.usableSpacesBenefit = []
                  if (typeof usableSpace.usableSpacesBenefit === 'object') {
                    let objUsableSpacesBenefit = Object.values(usableSpace.usableSpacesBenefit)
                    if (objUsableSpacesBenefit.length > 0) {
                      objUsableSpacesBenefit.forEach(function (benefit) {
                        let myBenefit = {}
                        myBenefit['id'] = benefit.primUid
                        myBenefit['benefit'] = benefit.benefit
                        myBenefit['discountedArea'] = benefit.discountedArea
                        myUsableSpace.usableSpacesBenefit.push(myBenefit)
                      }, this)
                    }
                  }

                  myResidential.usableSpaces.push(myUsableSpace)
                }, this)
              }
            }
            state.formPropertyResidential.residentials.push(myResidential)
          }, this)
        }
      }
    }

    //console.log('state.formPropertyResidential: ', state.formPropertyResidential);
  },
  pushFormPropertyResidential: () => {
    state.objPropertyDetails.step6 = []
    if (
      typeof state.formPropertyResidential.residentials === 'object' &&
      state.formPropertyResidential.residentials.length > 0
    ) {
      state.formPropertyResidential.residentials.forEach(function (residential) {
        let myResidential = {}
        myResidential.primUid = residential.id
        myResidential.yearOfConstruction =
          residential.residential.yearOfConstruction !== '' ? residential.residential.yearOfConstruction : null
        myResidential.coreRenovationYear =
          residential.residential.coreRenovationYear !== '' ? residential.residential.coreRenovationYear : null
        myResidential.demolitionObligationYear =
          residential.residential.demolitionObligationYear !== ''
            ? residential.residential.demolitionObligationYear
            : null

        myResidential.garageUnderground = {}
        if (residential.isSectionGarage === '1') {
          myResidential.garageUnderground.amountGarages =
            residential.garageUnderground.amountGarages !== '' ? residential.garageUnderground.amountGarages : null
          myResidential.garageUnderground.primUid = residential.garageUnderground.id
          myResidential.garageUnderground.garageUndergroundExemption = []
          if (
            typeof residential.garageUnderground.garageUndergroundExemption === 'object' &&
            residential.garageUnderground.garageUndergroundExemption.length > 0
          ) {
            residential.garageUnderground.garageUndergroundExemption.forEach(function (exemption) {
              let myExemption = {}
              myExemption['primUid'] = exemption.id
              myExemption['locationOfTheRoomsDesignation'] =
                exemption.locationOfTheRoomsDesignation !== '' ? exemption.locationOfTheRoomsDesignation : null
              myExemption['exemption'] = exemption.exemption
              myExemption['taxFreeArea'] = exemption.taxFreeArea !== '' ? exemption.taxFreeArea : null
              myResidential.garageUnderground.garageUndergroundExemption.push(myExemption)
            }, this)
          }
          myResidential.garageUnderground.garageUndergroundBenefit = []
          if (
            typeof residential.garageUnderground.garageUndergroundBenefit === 'object' &&
            residential.garageUnderground.garageUndergroundBenefit.length > 0
          ) {
            residential.garageUnderground.garageUndergroundBenefit.forEach(function (benefit) {
              let myBenefit = {}
              myBenefit['primUid'] = benefit.id
              myBenefit['locationOfTheRoomsDesignation'] =
                benefit.locationOfTheRoomsDesignation !== '' ? benefit.locationOfTheRoomsDesignation : null
              myBenefit['benefit'] = benefit.benefit
              myBenefit['discountedArea'] = benefit.discountedArea !== '' ? benefit.discountedArea : null
              myResidential.garageUnderground.garageUndergroundBenefit.push(myBenefit)
            }, this)
          }
        }

        myResidential.apartmentsBelow60 = {}
        if (residential.isSectionBelow60 === '1') {
          myResidential.apartmentsBelow60.primUid = residential.apartmentsBelow60.id
          myResidential.apartmentsBelow60.counter =
            residential.apartmentsBelow60.counter !== '' ? residential.apartmentsBelow60.counter : null
          myResidential.apartmentsBelow60.totalLivingSpace =
            residential.apartmentsBelow60.totalLivingSpace !== ''
              ? residential.apartmentsBelow60.totalLivingSpace
              : null
          myResidential.apartmentsBelow60.apartmentsBelow60Exemption = []
          if (
            typeof residential.apartmentsBelow60.apartmentsBelow60Exemption === 'object' &&
            residential.apartmentsBelow60.apartmentsBelow60Exemption.length > 0
          ) {
            residential.apartmentsBelow60.apartmentsBelow60Exemption.forEach(function (exemption) {
              let myExemption = {}
              myExemption['primUid'] = exemption.id
              myExemption['locationOfTheRoomsDesignation'] =
                exemption.locationOfTheRoomsDesignation !== '' ? exemption.locationOfTheRoomsDesignation : null
              myExemption['exemption'] = exemption.exemption
              myExemption['taxFreeArea'] = exemption.taxFreeArea !== '' ? exemption.taxFreeArea : null
              myResidential.apartmentsBelow60.apartmentsBelow60Exemption.push(myExemption)
            }, this)
          }
          myResidential.apartmentsBelow60.apartmentsBelow60Benefit = []
          if (
            typeof residential.apartmentsBelow60.apartmentsBelow60Benefit === 'object' &&
            residential.apartmentsBelow60.apartmentsBelow60Benefit.length > 0
          ) {
            residential.apartmentsBelow60.apartmentsBelow60Benefit.forEach(function (benefit) {
              let myBenefit = {}
              myBenefit['primUid'] = benefit.id
              myBenefit['locationOfTheRoomsDesignation'] =
                benefit.locationOfTheRoomsDesignation !== '' ? benefit.locationOfTheRoomsDesignation : null
              myBenefit['benefit'] = benefit.benefit
              myBenefit['discountedArea'] = benefit.discountedArea !== '' ? benefit.discountedArea : null
              myResidential.apartmentsBelow60.apartmentsBelow60Benefit.push(myBenefit)
            }, this)
          }
        }

        myResidential.apartmentsBetween60100 = {}
        if (residential.isSectionBetween60100 === '1') {
          myResidential.apartmentsBetween60100.primUid = residential.apartmentsBetween60100.id
          myResidential.apartmentsBetween60100.counter =
            residential.apartmentsBetween60100.counter !== '' ? residential.apartmentsBetween60100.counter : null
          myResidential.apartmentsBetween60100.totalLivingSpace =
            residential.apartmentsBetween60100.totalLivingSpace !== ''
              ? residential.apartmentsBetween60100.totalLivingSpace
              : null
          myResidential.apartmentsBetween60100.apartmentsBetween60100Exemption = []
          if (
            typeof residential.apartmentsBetween60100.apartmentsBetween60100Exemption === 'object' &&
            residential.apartmentsBetween60100.apartmentsBetween60100Exemption.length > 0
          ) {
            residential.apartmentsBetween60100.apartmentsBetween60100Exemption.forEach(function (exemption) {
              let myExemption = {}
              myExemption['primUid'] = exemption.id
              myExemption['locationOfTheRoomsDesignation'] =
                exemption.locationOfTheRoomsDesignation !== '' ? exemption.locationOfTheRoomsDesignation : null
              myExemption['exemption'] = exemption.exemption
              myExemption['taxFreeArea'] = exemption.taxFreeArea !== '' ? exemption.taxFreeArea : null
              myResidential.apartmentsBetween60100.apartmentsBetween60100Exemption.push(myExemption)
            }, this)
          }
          myResidential.apartmentsBetween60100.apartmentsBetween60100Benefit = []
          if (
            typeof residential.apartmentsBetween60100.apartmentsBetween60100Benefit === 'object' &&
            residential.apartmentsBetween60100.apartmentsBetween60100Benefit.length > 0
          ) {
            residential.apartmentsBetween60100.apartmentsBetween60100Benefit.forEach(function (benefit) {
              let myBenefit = {}
              myBenefit['primUid'] = benefit.id
              myBenefit['locationOfTheRoomsDesignation'] =
                benefit.locationOfTheRoomsDesignation !== '' ? benefit.locationOfTheRoomsDesignation : null
              myBenefit['benefit'] = benefit.benefit
              myBenefit['discountedArea'] = benefit.discountedArea !== '' ? benefit.discountedArea : null
              myResidential.apartmentsBetween60100.apartmentsBetween60100Benefit.push(myBenefit)
            }, this)
          }
        }

        myResidential.apartmentsAbove100 = {}
        if (residential.isSectionAbove100 === '1') {
          myResidential.apartmentsAbove100.primUid = residential.apartmentsAbove100.id
          myResidential.apartmentsAbove100.counter =
            residential.apartmentsAbove100.counter !== '' ? residential.apartmentsAbove100.counter : null
          myResidential.apartmentsAbove100.totalLivingSpace =
            residential.apartmentsAbove100.totalLivingSpace !== ''
              ? residential.apartmentsAbove100.totalLivingSpace
              : null
          myResidential.apartmentsAbove100.apartmentsAbove100Exemption = []
          if (
            typeof residential.apartmentsAbove100.apartmentsAbove100Exemption === 'object' &&
            residential.apartmentsAbove100.apartmentsAbove100Exemption.length > 0
          ) {
            residential.apartmentsAbove100.apartmentsAbove100Exemption.forEach(function (exemption) {
              let myExemption = {}
              myExemption['primUid'] = exemption.id
              myExemption['locationOfTheRoomsDesignation'] =
                exemption.locationOfTheRoomsDesignation !== '' ? exemption.locationOfTheRoomsDesignation : null
              myExemption['exemption'] = exemption.exemption
              myExemption['taxFreeArea'] = exemption.taxFreeArea !== '' ? exemption.taxFreeArea : null
              myResidential.apartmentsAbove100.apartmentsAbove100Exemption.push(myExemption)
            }, this)
          }
          myResidential.apartmentsAbove100.apartmentsAbove100Benefit = []
          if (
            typeof residential.apartmentsAbove100.apartmentsAbove100Benefit === 'object' &&
            residential.apartmentsAbove100.apartmentsAbove100Benefit.length > 0
          ) {
            residential.apartmentsAbove100.apartmentsAbove100Benefit.forEach(function (benefit) {
              let myBenefit = {}
              myBenefit['primUid'] = benefit.id
              myBenefit['locationOfTheRoomsDesignation'] =
                benefit.locationOfTheRoomsDesignation !== '' ? benefit.locationOfTheRoomsDesignation : null
              myBenefit['benefit'] = benefit.benefit
              myBenefit['discountedArea'] = benefit.discountedArea !== '' ? benefit.discountedArea : null
              myResidential.apartmentsAbove100.apartmentsAbove100Benefit.push(myBenefit)
            }, this)
          }
        }

        myResidential.apartmentsOther = {}
        if (residential.isSectionOther === '1') {
          myResidential.apartmentsOther.primUid = residential.apartmentsOther.id
          myResidential.apartmentsOther.counter =
            residential.apartmentsOther.counter !== '' ? residential.apartmentsOther.counter : null
          myResidential.apartmentsOther.totalLivingSpace =
            residential.apartmentsOther.totalLivingSpace !== '' ? residential.apartmentsOther.totalLivingSpace : null
          myResidential.apartmentsOther.apartmentsOtherExemption = []
          if (
            typeof residential.apartmentsOther.apartmentsOtherExemption === 'object' &&
            residential.apartmentsOther.apartmentsOtherExemption.length > 0
          ) {
            residential.apartmentsOther.apartmentsOtherExemption.forEach(function (exemption) {
              let myExemption = {}
              myExemption['primUid'] = exemption.id
              myExemption['locationOfTheRoomsDesignation'] =
                exemption.locationOfTheRoomsDesignation !== '' ? exemption.locationOfTheRoomsDesignation : null
              myExemption['exemption'] = exemption.exemption
              myExemption['taxFreeArea'] = exemption.taxFreeArea !== '' ? exemption.taxFreeArea : null
              myResidential.apartmentsOther.apartmentsOtherExemption.push(myExemption)
            }, this)
          }
          myResidential.apartmentsOther.apartmentsOtherBenefit = []
          if (
            typeof residential.apartmentsOther.apartmentsOtherBenefit === 'object' &&
            residential.apartmentsOther.apartmentsOtherBenefit.length > 0
          ) {
            residential.apartmentsOther.apartmentsOtherBenefit.forEach(function (benefit) {
              let myBenefit = {}
              myBenefit['primUid'] = benefit.id
              myBenefit['locationOfTheRoomsDesignation'] =
                benefit.locationOfTheRoomsDesignation !== '' ? benefit.locationOfTheRoomsDesignation : null
              myBenefit['benefit'] = benefit.benefit
              myBenefit['discountedArea'] = benefit.discountedArea !== '' ? benefit.discountedArea : null
              myResidential.apartmentsOther.apartmentsOtherBenefit.push(myBenefit)
            }, this)
          }
        }

        myResidential.usableSpaces = []
        if (residential.isSectionUsableSpaces === '1') {
          if (typeof residential.usableSpaces === 'object' && residential.usableSpaces.length > 0) {
            residential.usableSpaces.forEach((usableSpace) => {
              let myUsableSpace = {}
              myUsableSpace.primUid = usableSpace.id
              myUsableSpace.usageType = usableSpace.usageType !== '' ? usableSpace.usageType : null
              myUsableSpace.totalSpace = usableSpace.totalSpace !== '' ? usableSpace.totalSpace : null
              myUsableSpace.locationOfTheRoomsDesignation =
                usableSpace.locationOfTheRoomsDesignation !== '' ? usableSpace.locationOfTheRoomsDesignation : null

              myUsableSpace.usableSpacesExemption = []
              if (
                typeof usableSpace.usableSpacesExemption === 'object' &&
                usableSpace.usableSpacesExemption.length > 0
              ) {
                usableSpace.usableSpacesExemption.forEach(function (exemption) {
                  let myExemption = {}
                  myExemption['primUid'] = exemption.id
                  myExemption['exemption'] = exemption.exemption
                  myExemption['taxFreeArea'] = exemption.taxFreeArea !== '' ? exemption.taxFreeArea : null
                  myUsableSpace.usableSpacesExemption.push(myExemption)
                }, this)
              }

              myUsableSpace.usableSpacesBenefit = []
              if (typeof usableSpace.usableSpacesBenefit === 'object' && usableSpace.usableSpacesBenefit.length > 0) {
                usableSpace.usableSpacesBenefit.forEach(function (benefit) {
                  let myBenefit = {}
                  myBenefit['primUid'] = benefit.id
                  myBenefit['benefit'] = benefit.benefit
                  myBenefit['discountedArea'] = benefit.discountedArea !== '' ? benefit.discountedArea : null
                  myUsableSpace.usableSpacesBenefit.push(myBenefit)
                }, this)
              }

              myResidential.usableSpaces.push(myUsableSpace)
            }, this)
          }
        }

        state.objPropertyDetails.step6.push(myResidential)
      }, this)
    }
  },

  setFormPropertyNonResidential: (state, val) => {
    if (typeof val === 'object') {
      state.formPropertyNonResidential = JSON.parse(JSON.stringify(val))
    } else {
      state.formPropertyNonResidential = val
    }
  },
  updateFormPropertyNonResidential: () => {
    if (state.formPropertyNonResidential === '') {
      state.formPropertyNonResidential = JSON.parse(JSON.stringify(defaultPropertyNonResidential))
      state.formPropertyNonResidential.nonResidentials = []
    }

    if (typeof state.objPropertyDetails === 'object') {
      if (typeof state.objPropertyDetails.step7 === 'object') {
        state.formPropertyNonResidential.nonResidentials = []
        let objNonResidentials = Object.values(state.objPropertyDetails.step7)
        if (objNonResidentials.length > 0) {
          objNonResidentials.forEach(function (nonResidential) {
            let myNonResidential = JSON.parse(JSON.stringify(defaultPropertyNonResidential)).nonResidentials[0]
            myNonResidential.exemptions = []
            myNonResidential.benefits = []

            myNonResidential.id = nonResidential.primUid
            myNonResidential.locationPlanNumber = nonResidential.locationPlanNumber
            myNonResidential.buildingType = nonResidential.buildingType
            myNonResidential.yearOfConstruction = nonResidential.yearOfConstruction
            myNonResidential.coreRenovationYear = nonResidential.coreRenovationYear
            myNonResidential.demolitionObligationYear = nonResidential.demolitionObligationYear
            myNonResidential.areaOfTheLand = nonResidential.areaOfTheLand
            myNonResidential.areaOfTheLandCivilDefense = nonResidential.areaOfTheLandCivilDefense

            if (typeof nonResidential.exemptions === 'object') {
              let objExemptions = Object.values(nonResidential.exemptions)
              if (objExemptions.length > 0) {
                objExemptions.forEach(function (exemption) {
                  let myExemption = {}
                  myExemption['id'] = exemption.primUid
                  myExemption['locationOfTheRoomsDesignation'] = exemption.locationOfTheRoomsDesignation
                  myExemption['exemption'] = exemption.exemption
                  myExemption['taxFreeArea'] = exemption.taxFreeArea
                  myNonResidential.exemptions.push(myExemption)
                }, this)
              }
            }

            if (typeof nonResidential.benefits === 'object') {
              let objBenefits = Object.values(nonResidential.benefits)
              if (objBenefits.length > 0) {
                objBenefits.forEach(function (benefit) {
                  let myBenefit = {}
                  myBenefit['id'] = benefit.primUid
                  myBenefit['locationOfTheRoomsDesignation'] = benefit.locationOfTheRoomsDesignation
                  myBenefit['benefit'] = benefit.benefit
                  myBenefit['discountedArea'] = benefit.discountedArea
                  myNonResidential.benefits.push(myBenefit)
                }, this)
              }
            }

            state.formPropertyNonResidential.nonResidentials.push(myNonResidential)
          }, this)
        }
      }
    }
  },
  pushFormPropertyNonResidential: () => {
    state.objPropertyDetails.step7 = []
    if (
      typeof state.formPropertyNonResidential.nonResidentials === 'object' &&
      state.formPropertyNonResidential.nonResidentials.length > 0
    ) {
      state.formPropertyNonResidential.nonResidentials.forEach(function (nonResidential) {
        let myNonResidential = {}
        myNonResidential.primUid = nonResidential.id
        myNonResidential.locationPlanNumber =
          nonResidential.locationPlanNumber !== '' ? nonResidential.locationPlanNumber : null
        myNonResidential.buildingType = nonResidential.buildingType
        myNonResidential.yearOfConstruction =
          nonResidential.yearOfConstruction !== '' ? nonResidential.yearOfConstruction : null
        myNonResidential.coreRenovationYear =
          nonResidential.coreRenovationYear !== '' ? nonResidential.coreRenovationYear : null
        myNonResidential.demolitionObligationYear =
          nonResidential.demolitionObligationYear !== '' ? nonResidential.demolitionObligationYear : null
        myNonResidential.areaOfTheLand = nonResidential.areaOfTheLand !== '' ? nonResidential.areaOfTheLand : null
        myNonResidential.areaOfTheLandCivilDefense =
          nonResidential.areaOfTheLandCivilDefense !== '' ? nonResidential.areaOfTheLandCivilDefense : null

        myNonResidential.exemptions = []
        if (typeof nonResidential.exemptions === 'object' && nonResidential.exemptions.length > 0) {
          nonResidential.exemptions.forEach(function (exemption) {
            let myExemption = {}
            myExemption['primUid'] = exemption.id
            myExemption['locationOfTheRoomsDesignation'] =
              exemption.locationOfTheRoomsDesignation !== '' ? exemption.locationOfTheRoomsDesignation : null
            myExemption['exemption'] = exemption.exemption
            myExemption['taxFreeArea'] = exemption.taxFreeArea !== '' ? exemption.taxFreeArea : null
            myNonResidential.exemptions.push(myExemption)
          }, this)
        }

        myNonResidential.benefits = []
        if (typeof nonResidential.benefits === 'object' && nonResidential.benefits.length > 0) {
          nonResidential.benefits.forEach(function (benefit) {
            let myBenefit = {}
            myBenefit['primUid'] = benefit.id
            myBenefit['locationOfTheRoomsDesignation'] =
              benefit.locationOfTheRoomsDesignation !== '' ? benefit.locationOfTheRoomsDesignation : null
            myBenefit['benefit'] = benefit.benefit
            myBenefit['discountedArea'] = benefit.discountedArea !== '' ? benefit.discountedArea : null
            myNonResidential.benefits.push(myBenefit)
          }, this)
        }

        state.objPropertyDetails.step7.push(myNonResidential)
      }, this)
    }
  },

  setFormPropertyLeasehold: (state, val) => {
    if (typeof val === 'object') {
      state.formPropertyLeasehold = JSON.parse(JSON.stringify(val))
    } else {
      state.formPropertyLeasehold = val
    }
  },
  updateFormPropertyLeasehold: () => {
    if (state.formPropertyLeasehold === '') {
      state.formPropertyLeasehold = JSON.parse(JSON.stringify(defaultPropertyLeasehold))
    }

    if (typeof state.objPropertyDetails === 'object') {
      if (typeof state.objPropertyDetails.step8 === 'object') {
        let objLeaseholds = Object.values(state.objPropertyDetails.step8)
        if (objLeaseholds.length > 0) {
          state.formPropertyLeasehold.leaseholds = []
          objLeaseholds.forEach(function (leasehold) {
            let myLeasehold = {}
            myLeasehold.id = leasehold.primUid
            myLeasehold.countryUid = leasehold.countryUid
            myLeasehold.firstName = leasehold.firstName
            myLeasehold.formOfAddress = leasehold.formOfAddress
            myLeasehold.houseNumber = leasehold.houseNumber
            myLeasehold.lastName = leasehold.lastName
            myLeasehold.postbox = leasehold.postbox
            myLeasehold.street = leasehold.street
            myLeasehold.telephoneNumber = leasehold.telephoneNumber
            myLeasehold.titleAcademicDegree = leasehold.titleAcademicDegree
            myLeasehold.zip = leasehold.zip
            myLeasehold.city = leasehold.city
            state.formPropertyLeasehold.leaseholds.push(myLeasehold)
          }, this)
        }
      }
    }
  },
  pushFormPropertyLeasehold: () => {
    //state.objPropertyDetails.step99.dummy = state.formPropertyLeasehold['dummy'];
    state.objPropertyDetails.step8 = []
    if (
      typeof state.formPropertyLeasehold.leaseholds === 'object' &&
      state.formPropertyLeasehold.leaseholds.length > 0
    ) {
      state.formPropertyLeasehold.leaseholds.forEach(function (leasehold) {
        let myLeaseholds = {}
        myLeaseholds.primUid = leasehold.id
        myLeaseholds.firstName = leasehold.firstName
        myLeaseholds.lastName = leasehold.lastName
        myLeaseholds.street = leasehold.street
        myLeaseholds.houseNumber = leasehold.houseNumber
        myLeaseholds.zip = leasehold.zip
        myLeaseholds.postbox = leasehold.postbox
        myLeaseholds.city = leasehold.city
        myLeaseholds.countryUid = leasehold.countryUid
        myLeaseholds.titleAcademicDegree = leasehold.titleAcademicDegree
        myLeaseholds.formOfAddress = leasehold.formOfAddress
        myLeaseholds.telephoneNumber = leasehold.telephoneNumber

        state.objPropertyDetails.step8.push(myLeaseholds)
      })
    }
  },

  setFormPropertyAgriculture: (state, val) => {
    if (typeof val === 'object') {
      state.formPropertyAgriculture = JSON.parse(JSON.stringify(val))
    } else {
      state.formPropertyAgriculture = val
    }
  },
  updateFormPropertyAgriculture: () => {
    if (state.formPropertyAgriculture === '') {
      state.formPropertyAgriculture = JSON.parse(JSON.stringify(defaultPropertyAgriculture))
      state.formPropertyAgriculture.gemeindes = []
    }

    if (typeof state.objPropertyDetails === 'object') {
      state.formPropertyAgriculture['totalExemption'] = state.objPropertyDetails.lafTotalExemption
      state.formPropertyAgriculture['exemptionNonDelimitablePart'] =
        state.objPropertyDetails.lafExemptionNonDelimitablePart
      if (typeof state.objPropertyDetails.step9 === 'object') {
        state.formPropertyAgriculture.gemeindes = []
        let objAgri = Object.values(state.objPropertyDetails.step9)

        if (objAgri.length > 0) {
          state.formPropertyAgriculture.gemeindes = []
          objAgri.forEach(function (agri) {
            let objGemeinde = Object.values(agri)
            if (objGemeinde.length > 0) {
              objGemeinde.forEach(function (gemeinde) {
                let myGemeinde = {}
                myGemeinde.id = gemeinde.primUid
                myGemeinde.community = gemeinde.community
                myGemeinde.description = gemeinde.description
                myGemeinde.federal_state_uid = gemeinde.federal_state_uid !== '' ? gemeinde.federal_state_uid : '0'
                myGemeinde.parcels = []

                if (typeof gemeinde.parcels == 'object') {
                  let objParcels = Object.values(gemeinde.parcels)
                  if (objParcels.length > 0) {
                    objParcels.forEach(function (parcel) {
                      let myParcels = {}
                      myParcels.id = parcel.primUid
                      myParcels.areaOfTheLand = parcel.areaOfTheLand
                      myParcels.corridor = parcel.corridor
                      myParcels.landRegisterSheet = parcel.landRegisterSheet
                      myParcels.counter = parcel.counter
                      myParcels.denominator = parcel.denominator
                      myParcels.district = parcel.district
                      myParcels.district_number = parcel.district_number
                      myParcels.isReference = parcel.isReference
                      myParcels.usageTypes = []
                      if (typeof parcel.usageTypes == 'object') {
                        let objUsageTypes = Object.values(parcel.usageTypes)
                        myParcels.usageTypes = []
                        if (objUsageTypes.length > 0) {
                          objUsageTypes.forEach(function (usageType) {
                            let myUsageType = {}
                            myUsageType.id = usageType.primUid
                            myUsageType.areaOfFarmBuildings = usageType.areaOfFarmBuildings
                            myUsageType.areaOfTheLand = usageType.areaOfTheLand
                            myUsageType.exemption = usageType.exemption
                            myUsageType.exemption_area = usageType.exemption_area
                            myUsageType.flowRate = usageType.flowRate
                            myUsageType.usageType = usageType.usageType
                            myUsageType.yieldMeasurementNumber = usageType.yieldMeasurementNumber
                            myParcels.usageTypes.push(myUsageType)
                          }, this)
                        }
                      }
                      myGemeinde.parcels.push(myParcels)
                    }, this)
                  }
                }
                state.formPropertyAgriculture.gemeindes.push(myGemeinde)
              }, this)
            }
          })
        }
      }
    }
  },
  pushFormPropertyAgriculture: () => {
    state.objPropertyDetails.step9.totalExemption = state.formPropertyAgriculture['totalExemption']
    state.objPropertyDetails.step9.exemptionNonDelimitablePart =
      state.formPropertyAgriculture['exemptionNonDelimitablePart']
    state.objPropertyDetails.step9.gemeindes = []
    if (
      typeof state.formPropertyAgriculture.gemeindes === 'object' &&
      state.formPropertyAgriculture.gemeindes.length > 0
    ) {
      state.formPropertyAgriculture.gemeindes.forEach(function (gemeinde) {
        let myGemeinde = {}
        myGemeinde.primUid = gemeinde.id
        myGemeinde.community = gemeinde.community
        myGemeinde.description = gemeinde.description
        myGemeinde.federal_state_uid = gemeinde.federal_state_uid
        myGemeinde.parcels = []
        if (typeof gemeinde.parcels == 'object') {
          let objParcels = Object.values(gemeinde.parcels)
          if (objParcels.length > 0) {
            objParcels.forEach(function (parcel) {
              let myParcels = {}
              myParcels.primUid = parcel.id
              myParcels.areaOfTheLand = parcel.areaOfTheLand
              myParcels.corridor = parcel.corridor
              myParcels.landRegisterSheet = parcel.landRegisterSheet
              myParcels.counter = parcel.counter
              myParcels.denominator = parcel.denominator
              myParcels.district = parcel.district
              myParcels.district_number = parcel.district_number
              myParcels.isReference = parcel.isReference
              myParcels.usageTypes = []
              if (typeof parcel.usageTypes == 'object') {
                let objUsageTypes = Object.values(parcel.usageTypes)
                if (objUsageTypes.length > 0) {
                  objUsageTypes.forEach(function (usageType) {
                    let myUsageType = {}
                    myUsageType.primUid = usageType.id
                    myUsageType.areaOfFarmBuildings = usageType.areaOfFarmBuildings
                    myUsageType.areaOfTheLand = usageType.areaOfTheLand
                    myUsageType.exemption = usageType.exemption
                    myUsageType.exemption_area = usageType.exemption_area
                    myUsageType.flowRate = usageType.flowRate
                    myUsageType.usageType = usageType.usageType
                    myUsageType.yieldMeasurementNumber = usageType.yieldMeasurementNumber
                    myParcels.usageTypes.push(myUsageType)
                  }, this)
                }
              }
              myGemeinde.parcels.push(myParcels)
            }, this)
          }
        }
        state.objPropertyDetails.step9.gemeindes.push(myGemeinde)
      }, this)
    }
  },

  setFormPropertyLivestock: (state, val) => {
    if (typeof val === 'object') {
      state.formPropertyLivestock = JSON.parse(JSON.stringify(val))
    } else {
      state.formPropertyLivestock = val
    }
  },
  updateFormPropertyLivestock: () => {
    if (state.formPropertyLivestock === '') {
      state.formPropertyLivestock = JSON.parse(JSON.stringify(defaultPropertyLivestock))
    }

    if (typeof state.objPropertyDetails === 'object') {
      if (state.objPropertyDetails.step10) {
        state.formPropertyLivestock['ownedSpace'] = state.objPropertyDetails.step10.ownedSpace ?? ''
        state.formPropertyLivestock['minusLeasedAreas'] = state.objPropertyDetails.step10.minusLeasedAreas ?? ''
        state.formPropertyLivestock['plusLeasedAreas'] = state.objPropertyDetails.step10.plusLeasedAreas ?? ''
        state.formPropertyLivestock['selfCultivatedAreas'] = state.objPropertyDetails.step10.selfCultivatedAreas ?? ''
        state.formPropertyLivestock['alpacas'] = state.objPropertyDetails.step10.alpacas ?? ''
        state.formPropertyLivestock['damAnimal_below1Year'] = state.objPropertyDetails.step10.damAnimal_below1Year ?? ''
        state.formPropertyLivestock['damAnimal_above1Year'] = state.objPropertyDetails.step10.damAnimal_above1Year ?? ''
        state.formPropertyLivestock['layingHens_layingHensIncludeBreeding'] =
          state.objPropertyDetails.step10.layingHens_layingHensIncludeBreeding ?? ''
        state.formPropertyLivestock['layingHens_purchasedLayingHens'] =
          state.objPropertyDetails.step10.layingHens_purchasedLayingHens ?? ''
        state.formPropertyLivestock['poultry'] = state.objPropertyDetails.step10.poultry ?? ''
        state.formPropertyLivestock['breedingRabbits'] = state.objPropertyDetails.step10.breedingRabbits ?? ''
        state.formPropertyLivestock['llamas'] = state.objPropertyDetails.step10.llamas ?? ''
        state.formPropertyLivestock['horses_below3YearsOrSmallHorses'] =
          state.objPropertyDetails.step10.horses_below3YearsOrSmallHorses ?? ''
        state.formPropertyLivestock['horses_horses'] = state.objPropertyDetails.step10.horses_horses ?? ''
        state.formPropertyLivestock['beef_below1Year'] = state.objPropertyDetails.step10.beef_below1Year ?? ''
        state.formPropertyLivestock['beef_1to2Year'] = state.objPropertyDetails.step10.beef_1to2Year ?? ''
        state.formPropertyLivestock['beef_above2Year'] = state.objPropertyDetails.step10.beef_above2Year ?? ''
        state.formPropertyLivestock['fatteningAnimalsLessThan1Year'] =
          state.objPropertyDetails.step10.fatteningAnimalsLessThan1Year ?? ''
        state.formPropertyLivestock['cows'] = state.objPropertyDetails.step10.cows ?? ''
        state.formPropertyLivestock['breedingBulls'] = state.objPropertyDetails.step10.breedingBulls ?? ''
        state.formPropertyLivestock['sheep_below1Year'] = state.objPropertyDetails.step10.sheep_below1Year ?? ''
        state.formPropertyLivestock['sheep_above1Year'] = state.objPropertyDetails.step10.sheep_above1Year ?? ''
        state.formPropertyLivestock['breedingPigs'] = state.objPropertyDetails.step10.breedingPigs ?? ''
        state.formPropertyLivestock['ostriches_above14Months'] =
          state.objPropertyDetails.step10.ostriches_above14Months ?? ''
        state.formPropertyLivestock['ostriches_below14Months'] =
          state.objPropertyDetails.step10.ostriches_below14Months ?? ''
        state.formPropertyLivestock['youngChickensForFattening_below6Passes'] =
          state.objPropertyDetails.step10.youngChickensForFattening_below6Passes ?? ''
        state.formPropertyLivestock['youngChickensForFattening_above6Passes'] =
          state.objPropertyDetails.step10.youngChickensForFattening_above6Passes ?? ''
        state.formPropertyLivestock['pullets'] = state.objPropertyDetails.step10.pullets ?? ''
        state.formPropertyLivestock['fatteningDucks_count'] = state.objPropertyDetails.step10.fatteningDucks_count ?? ''
        state.formPropertyLivestock['fatteningDucks_raisingPhase'] =
          state.objPropertyDetails.step10.fatteningDucks_raisingPhase ?? ''
        state.formPropertyLivestock['fatteningDucks_fatteningPhase'] =
          state.objPropertyDetails.step10.fatteningDucks_fatteningPhase ?? ''
        state.formPropertyLivestock['turkeys_selfRaised'] = state.objPropertyDetails.step10.turkeys_selfRaised ?? ''
        state.formPropertyLivestock['turkeys_bought'] = state.objPropertyDetails.step10.turkeys_bought ?? ''
        state.formPropertyLivestock['turkeys_youngTurkeys'] = state.objPropertyDetails.step10.turkeys_youngTurkeys ?? ''
        state.formPropertyLivestock['fattenedGeese'] = state.objPropertyDetails.step10.fattenedGeese ?? ''
        state.formPropertyLivestock['mastRabbit'] = state.objPropertyDetails.step10.mastRabbit ?? ''
        state.formPropertyLivestock['beefCattle'] = state.objPropertyDetails.step10.beefCattle ?? ''
        state.formPropertyLivestock['pigs_lightPiglets'] = state.objPropertyDetails.step10.pigs_lightPiglets ?? ''
        state.formPropertyLivestock['pigs_piglets'] = state.objPropertyDetails.step10.pigs_piglets ?? ''
        state.formPropertyLivestock['pigs_heavyPiglets'] = state.objPropertyDetails.step10.pigs_heavyPiglets ?? ''
        state.formPropertyLivestock['pigs_runner'] = state.objPropertyDetails.step10.pigs_runner ?? ''
        state.formPropertyLivestock['pigs_fatteningPigs'] = state.objPropertyDetails.step10.pigs_fatteningPigs ?? ''
        state.formPropertyLivestock['pigs_heavyRunner'] = state.objPropertyDetails.step10.pigs_heavyRunner ?? ''
        state.formPropertyLivestock['pigs_youngBreedingPigs'] =
          state.objPropertyDetails.step10.pigs_youngBreedingPigs ?? ''
        state.formPropertyLivestock['goats'] = state.objPropertyDetails.step10.goats ?? ''
        state.formPropertyLivestock['boughtPigs_lightPiglets'] =
          state.objPropertyDetails.step10.boughtPigs_lightPiglets ?? ''
        state.formPropertyLivestock['boughtPigs_piglets'] = state.objPropertyDetails.step10.boughtPigs_piglets ?? ''
        state.formPropertyLivestock['boughtPigs_heavyPiglets'] =
          state.objPropertyDetails.step10.boughtPigs_heavyPiglets ?? ''
        state.formPropertyLivestock['boughtPigs_runner'] = state.objPropertyDetails.step10.boughtPigs_runner ?? ''
        state.formPropertyLivestock['boughtPigs_heavyRunner'] =
          state.objPropertyDetails.step10.boughtPigs_heavyRunner ?? ''
      }
    }
  },

  pushFormPropertyLivestock: () => {
    state.objPropertyDetails.step10.ownedSpace = state.formPropertyLivestock['ownedSpace']
    state.objPropertyDetails.step10.minusLeasedAreas = state.formPropertyLivestock['minusLeasedAreas']
    state.objPropertyDetails.step10.plusLeasedAreas = state.formPropertyLivestock['plusLeasedAreas']
    state.objPropertyDetails.step10.selfCultivatedAreas = state.formPropertyLivestock['selfCultivatedAreas']
    state.objPropertyDetails.step10.alpacas = state.formPropertyLivestock['alpacas']
    state.objPropertyDetails.step10.damAnimal_below1Year = state.formPropertyLivestock['damAnimal_below1Year']
    state.objPropertyDetails.step10.damAnimal_above1Year = state.formPropertyLivestock['damAnimal_above1Year']
    state.objPropertyDetails.step10.layingHens_layingHensIncludeBreeding =
      state.formPropertyLivestock['layingHens_layingHensIncludeBreeding']
    state.objPropertyDetails.step10.layingHens_purchasedLayingHens =
      state.formPropertyLivestock['layingHens_purchasedLayingHens']
    state.objPropertyDetails.step10.poultry = state.formPropertyLivestock['poultry']
    state.objPropertyDetails.step10.breedingRabbits = state.formPropertyLivestock['breedingRabbits']
    state.objPropertyDetails.step10.llamas = state.formPropertyLivestock['llamas']
    state.objPropertyDetails.step10.horses_below3YearsOrSmallHorses =
      state.formPropertyLivestock['horses_below3YearsOrSmallHorses']
    state.objPropertyDetails.step10.horses_horses = state.formPropertyLivestock['horses_horses']
    state.objPropertyDetails.step10.beef_below1Year = state.formPropertyLivestock['beef_below1Year']
    state.objPropertyDetails.step10.beef_1to2Year = state.formPropertyLivestock['beef_1to2Year']
    state.objPropertyDetails.step10.beef_above2Year = state.formPropertyLivestock['beef_above2Year']
    state.objPropertyDetails.step10.fatteningAnimalsLessThan1Year =
      state.formPropertyLivestock['fatteningAnimalsLessThan1Year']
    state.objPropertyDetails.step10.cows = state.formPropertyLivestock['cows']
    state.objPropertyDetails.step10.breedingBulls = state.formPropertyLivestock['breedingBulls']
    state.objPropertyDetails.step10.sheep_below1Year = state.formPropertyLivestock['sheep_below1Year']
    state.objPropertyDetails.step10.sheep_above1Year = state.formPropertyLivestock['sheep_above1Year']
    state.objPropertyDetails.step10.breedingPigs = state.formPropertyLivestock['breedingPigs']
    state.objPropertyDetails.step10.ostriches_above14Months = state.formPropertyLivestock['ostriches_above14Months']
    state.objPropertyDetails.step10.ostriches_below14Months = state.formPropertyLivestock['ostriches_below14Months']
    state.objPropertyDetails.step10.youngChickensForFattening_below6Passes =
      state.formPropertyLivestock['youngChickensForFattening_below6Passes']
    state.objPropertyDetails.step10.youngChickensForFattening_above6Passes =
      state.formPropertyLivestock['youngChickensForFattening_above6Passes']
    state.objPropertyDetails.step10.pullets = state.formPropertyLivestock['pullets']
    state.objPropertyDetails.step10.fatteningDucks_count = state.formPropertyLivestock['fatteningDucks_count']
    state.objPropertyDetails.step10.fatteningDucks_raisingPhase =
      state.formPropertyLivestock['fatteningDucks_raisingPhase']
    state.objPropertyDetails.step10.fatteningDucks_fatteningPhase =
      state.formPropertyLivestock['fatteningDucks_fatteningPhase']
    state.objPropertyDetails.step10.turkeys_selfRaised = state.formPropertyLivestock['turkeys_selfRaised']
    state.objPropertyDetails.step10.turkeys_bought = state.formPropertyLivestock['turkeys_bought']
    state.objPropertyDetails.step10.turkeys_youngTurkeys = state.formPropertyLivestock['turkeys_youngTurkeys']
    state.objPropertyDetails.step10.fattenedGeese = state.formPropertyLivestock['fattenedGeese']
    state.objPropertyDetails.step10.mastRabbit = state.formPropertyLivestock['mastRabbit']
    state.objPropertyDetails.step10.beefCattle = state.formPropertyLivestock['beefCattle']
    state.objPropertyDetails.step10.pigs_lightPiglets = state.formPropertyLivestock['pigs_lightPiglets']
    state.objPropertyDetails.step10.pigs_piglets = state.formPropertyLivestock['pigs_piglets']
    state.objPropertyDetails.step10.pigs_heavyPiglets = state.formPropertyLivestock['pigs_heavyPiglets']
    state.objPropertyDetails.step10.pigs_runner = state.formPropertyLivestock['pigs_runner']
    state.objPropertyDetails.step10.pigs_heavyRunner = state.formPropertyLivestock['pigs_heavyRunner']
    state.objPropertyDetails.step10.pigs_fatteningPigs = state.formPropertyLivestock['pigs_fatteningPigs']
    state.objPropertyDetails.step10.pigs_youngBreedingPigs = state.formPropertyLivestock['pigs_youngBreedingPigs']
    state.objPropertyDetails.step10.goats = state.formPropertyLivestock['goats']
    state.objPropertyDetails.step10.boughtPigs_lightPiglets = state.formPropertyLivestock['boughtPigs_lightPiglets']
    state.objPropertyDetails.step10.boughtPigs_piglets = state.formPropertyLivestock['boughtPigs_piglets']
    state.objPropertyDetails.step10.boughtPigs_heavyPiglets = state.formPropertyLivestock['boughtPigs_heavyPiglets']
    state.objPropertyDetails.step10.boughtPigs_runner = state.formPropertyLivestock['boughtPigs_runner']
    state.objPropertyDetails.step10.boughtPigs_heavyRunner = state.formPropertyLivestock['boughtPigs_heavyRunner']
  },

  setDashboardListLayout: (state, val) => {
    state.dashboardListLayout = val
  },

  setDashboardPropertySearch: (state, val) => {
    state.dashboardPropertySearch = val
  },

  setDashboardSelectedFilterKey: (state, val) => {
    state.dashboardSelectedFilterKey = val
  },
}

const actions = {
  apiGetProperties({ commit }, search) {
    if (typeof search === 'undefined') {
      search = {
        txt: state.dashboardPropertySearch,
        filterKey: state.dashboardSelectedFilterKey,
      }
    } else {
      if (typeof search.txt === 'undefined') {
        search.txt = state.dashboardPropertySearch
      }
      if (typeof search.filterKey === 'undefined') {
        search.filterKey = state.dashboardSelectedFilterKey
      }
    }
    apiClient
      .post('property/list', { txt_filter: search.txt, selected_filter: search.filterKey })
      .then((response) => {
        if (response.data.success) {
          commit('setArrProperties', {
            totalRecords: parseInt(response.data.total_records),
            properties: response.data.records,
          })
        } else {
          commit('setArrProperties', {})
        }
      })
      .catch(() => {
        commit('setArrProperties', {})
      })
  },
  apiGetCompletedDeclarations({ commit }, search) {
    apiClient
      .post('declaration/completedList', { txt_filter: search })
      .then((response) => {
        if (response.data.success) {
          commit('setArrDeclarations', {
            totalRecords: parseInt(response.data.total_records),
            declarations: response.data.records,
          })
        } else {
          commit('setArrDeclarations', {})
        }
      })
      .catch(() => {
        commit('setArrDeclarations', {})
      })
  },
  apiGetPropertyDetails({ commit, dispatch }, property_id) {
    commit('showPageLoader')
    dispatch('stopObserver')
    apiClient
      .post('property/detail', { id: property_id })
      .then((response) => {
        commit('hidePageLoader')
        if (response.data.success) {
          dispatch('startObserver', {
            recordType: 'properties',
            recordId: response.data.propertyData.id,
            recordTimestamp: response.data.propertyData.recordTimestamp,
          })
          commit('setObjPropertyDetails', response.data.propertyData)
          if (response.data.propertyData.clientEditingLocked && response.data.propertyData.clientEditingLocked === 1) {
            commit('setPropertyReadOnly', true)
          }
          if (
            response.data.propertyData.step2.federalStateName &&
            response.data.propertyData.step2.federalStateName !== ''
          ) {
            dispatch('apiGetCommunities', { federalState: response.data.propertyData.step2.federalStateName })
          }
        } else {
          dispatch('showToast', {
            message: response.data.message,
            toastType: 'error',
          })
          router.push('/dashboard')
        }
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
          router.push('/dashboard')
        }
      })
  },
  apiGetPropertyDetailsSilently({ commit, dispatch }, payload) {
    //structure of payload: property_id, callbackFn
    let property_id = payload.property_id
    let callbackFn = payload.callbackFn
    dispatch('stopObserver')
    apiClient
      .post('property/detail', { id: property_id })
      .then((response) => {
        commit('hidePageLoader')
        if (response.data.success) {
          dispatch('startObserver', {
            recordType: 'properties',
            recordId: response.data.propertyData.id,
            recordTimestamp: response.data.propertyData.recordTimestamp,
          })
          commit('setObjPropertyDetails', response.data.propertyData)
          if (response.data.propertyData.clientEditingLocked && response.data.propertyData.clientEditingLocked === 1) {
            commit('setPropertyReadOnly', true)
          }
          if (
            response.data.propertyData.step2.federalStateName &&
            response.data.propertyData.step2.federalStateName !== ''
          ) {
            dispatch('apiGetCommunities', { federalState: response.data.propertyData.step2.federalStateName })
          }
          if (typeof callbackFn === 'function') {
            callbackFn(true, '')
          }
        } else {
          if (typeof callbackFn === 'function') {
            callbackFn(false, response.data.message)
          }
        }
      })
      .catch((error) => {
        if (error.message !== 'canceled') {
          if (typeof callbackFn === 'function') {
            callbackFn(false, t('general.errors.unknownError'))
          }
        }
      })
  },

  fetchFederalStates({ commit, getters }) {
    if (getters.getFederalStates.length === 0) {
      return apiClient
        .post('getFederalStates', null)
        .then((response) => {
          if (response.data.success) {
            commit('setFederalStates', response.data.states)
            return response.data.states
          } else {
            commit('setFederalStates', [])
            return []
          }
        })
        .catch(() => {
          commit('setFederalStates', [])
          return []
        })
    } else {
      return new Promise((resolve) => {
        resolve(getters.getFederalStates)
      })
    }
  },
  saveProperty({ commit, dispatch }, payload) {
    //structure of payload: payload.property; payload.client_comment
    let property = payload.property
    let client_comment = typeof payload.client_comment === 'string' ? payload.client_comment.trim() : ''
    property['client_comment'] = client_comment

    commit('showPageLoader')
    dispatch('stopObserver')
    let isNewProperty =
      typeof property.id === 'undefined' || Number.isNaN(parseInt(property.id, 10)) || parseInt(property.id, 10) === 0

    apiClient
      .post('property/save', property)
      .then((response) => {
        commit('hidePageLoader')
        if (response.data.success) {
          dispatch('startObserver', {
            recordType: 'properties',
            recordId: response.data.property_uid,
            recordTimestamp: response.data.recordTimestamp,
          })
          dispatch('showToast', {
            message: t('Property_final.propertySaved'),
            toastType: 'success',
          })
          if (isNewProperty) {
            commit('setLastAddedPropertyId', response.data.property_uid)
            //router.push is handled by Property.vue in this case
          } else {
            router.push('/dashboard')
          }
        } else {
          commit('hidePageLoader')
          let errorMsg
          if (typeof response.data.message === 'string') {
            errorMsg = response.data.message
          } else {
            errorMsg = t('general.errors.unknownError')
          }
          dispatch('showToast', {
            message: errorMsg,
            toastType: 'error',
          })
        }
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
      })
  },
  savePropertySilently({ commit, dispatch }, payload) {
    //structure of payload: payload.property; payload.client_comment; payload.callbackFn(success, errorMsg)
    let property = payload.property
    let client_comment = typeof payload.client_comment === 'string' ? payload.client_comment.trim() : ''
    property['client_comment'] = client_comment

    dispatch('stopObserver')
    let isNewProperty =
      typeof property.id === 'undefined' || Number.isNaN(parseInt(property.id, 10)) || parseInt(property.id, 10) === 0

    apiClient
      .post('property/save', property)
      .then((response) => {
        if (response.data.success) {
          dispatch('startObserver', {
            recordType: 'properties',
            recordId: response.data.property_uid,
            recordTimestamp: response.data.recordTimestamp,
          })
          if (typeof payload.callbackFn === 'function') {
            payload.callbackFn(true, '')
          }
          if (isNewProperty) {
            commit('setLastAddedPropertyId', response.data.property_uid)
          }
        } else {
          let errorMsg
          if (typeof response.data.message === 'string') {
            errorMsg = response.data.message
          } else {
            errorMsg = t('general.errors.unknownError')
          }
          if (typeof payload.callbackFn === 'function') {
            payload.callbackFn(false, errorMsg)
          }
        }
      })
      .catch((error) => {
        if (error.message !== 'canceled') {
          if (typeof payload.callbackFn === 'function') {
            payload.callbackFn(false, error.message)
          }
        }
      })
  },

  savePropertyBeforeSkendataRequest({ commit, dispatch }, payload) {
    let silentMode = true
    commit('showPageLoader')
    apiClient
      .post('property/save', payload)
      .then((response) => {
        commit('hidePageLoader')
        if (response.data.success) {
          if (!silentMode) {
            dispatch('showToast', {
              message: t('Property_final.propertySaved'),
              toastType: 'success',
            })
          }
          window.location.href = '#/property/' + response.data.property_uid + '?skendata_request=true'
          router.go()
        } else {
          commit('hidePageLoader')
          if (!silentMode) {
            dispatch('showToast', {
              message: response.data.message,
              toastType: 'error',
            })
          }
        }
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          if (!silentMode) {
            dispatch('showToast', {
              message: t('general.errors.unknownError'),
              toastType: 'error',
            })
          }
        }
      })
  },
  deletePropertyItems({ commit, dispatch }, payload) {
    commit('showPageLoader')
    return apiClient
      .post('property/deleteItems', payload)
      .then((response) => {
        commit('hidePageLoader')
        if (response.data.success) {
          return true
        } else {
          dispatch('showToast', {
            message: response.data.message,
            toastType: 'error',
          })
          return false
        }
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
        return false
      })
  },
  apiDuplicateProperty({ commit, dispatch }, payload) {
    commit('showPageLoader')
    return apiClient
      .post('property/duplicate', payload)
      .then((response) => {
        commit('hidePageLoader')
        if (response.data.success) {
          dispatch('apiGetProperties')
          dispatch('showToast', {
            message: t('Property_final.propertyDuplicated'),
            toastType: 'success',
          })
          return true
        } else {
          dispatch('showToast', {
            message: response.data.message,
            toastType: 'error',
          })
          return false
        }
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
        return false
      })
  },
  getSkendataFulfillment({ commit, dispatch }, payload) {
    return apiClient
      .post('skendata/fulfillment', payload)
      .then((response) => {
        commit('hidePageLoader')
        if (response) {
          return response
        }
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
        return false
      })
  },
  getSkendataProcess({ commit, dispatch }, payload) {
    return apiClient
      .post('property/skendata_get_process', payload)
      .then((response) => {
        setTimeout(function () {
          //commit("hidePageLoader");
        }, 3000)
        return response
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
        return false
      })
  },
  getDataAcquisition({ commit, dispatch }) {
    commit('showPageLoader')
    return apiClient
      .post('skendata/data_acquisition', {})
      .then((response) => {
        if (response.data.success) {
          return response.data
        }
        dispatch('showToast', {
          message: t('general.errors.unknownError'),
          toastType: 'error',
        })
      })
      .finally(() => commit('hidePageLoader'))
  },
  saveSkendataFulfillment({ commit, dispatch }, payload) {
    return apiClient
      .post('skendata/save_fulfillment', payload)
      .then((response) => {
        setTimeout(function () {
          //commit("hidePageLoader");
        }, 3000)
        return response
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
        return false
      })
  },
  saveSkendataParcels({ commit, dispatch }, payload) {
    return apiClient
      .post('property/save_skendata', payload)
      .then((response) => {
        setTimeout(function () {
          //commit("hidePageLoader");
        }, 3000)
        return response
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
        return false
      })
  },

  resetFormPropertyAddress({ commit }) {
    commit('setFormPropertyAddress', defaultPropertyAddress)
  },
  resetFormPropertyDetails({ commit }) {
    commit('setFormPropertyDetails', defaultPropertyDetails)
  },
  resetFormPropertyMoreDetails({ commit }) {
    commit('setFormPropertyMoreDetails', defaultPropertyMoreDetails)
  },
  resetFormPropertyBenefits({ commit }) {
    let emptyDefaultPropertyBenefits = JSON.parse(JSON.stringify(defaultPropertyBenefits))
    emptyDefaultPropertyBenefits.unbuilt_real_estate_exemptions = []
    emptyDefaultPropertyBenefits.use_of_definable_parts = []
    emptyDefaultPropertyBenefits.tax_break_conditions = []
    commit('setFormPropertyBenefits', emptyDefaultPropertyBenefits)
  },
  resetFormPropertyCommunity({ commit }) {
    commit('setFormPropertyCommunity', defaultPropertyCommunity)
  },
  resetFormPropertyOwnership({ commit }) {
    let emptyDefaultPropertyOwnership = JSON.parse(JSON.stringify(defaultPropertyOwnership))
    emptyDefaultPropertyOwnership.owners = []
    commit('setFormPropertyOwnership', emptyDefaultPropertyOwnership)
  },
  resetFormPropertyBuildings({ commit }) {
    let emptyDefaultPropertyBuildings = JSON.parse(JSON.stringify(defaultPropertyBuildings))
    emptyDefaultPropertyBuildings.buildings = []
    commit('setFormPropertyBuildings', emptyDefaultPropertyBuildings)
  },
  resetFormPropertyResidential({ commit }) {
    let emptyDefaultPropertyResidential = JSON.parse(JSON.stringify(defaultPropertyResidential))
    emptyDefaultPropertyResidential.residentials = []
    commit('setFormPropertyResidential', emptyDefaultPropertyResidential)
  },
  resetFormPropertyNonResidential({ commit }) {
    let emptyDefaultPropertyNonResidential = JSON.parse(JSON.stringify(defaultPropertyNonResidential))
    emptyDefaultPropertyNonResidential.nonResidentials = []
    commit('setFormPropertyNonResidential', emptyDefaultPropertyNonResidential)
  },
  resetFormPropertyLeasehold({ commit }) {
    let emptyDefaultPropertyLeasehold = JSON.parse(JSON.stringify(defaultPropertyLeasehold))
    emptyDefaultPropertyLeasehold.leaseholds = []
    commit('setFormPropertyLeasehold', emptyDefaultPropertyLeasehold)
  },
  resetFormPropertyAgriculture({ commit }) {
    let emptyDefaultPropertyAgriculture = JSON.parse(JSON.stringify(defaultPropertyAgriculture))
    emptyDefaultPropertyAgriculture.gemeindes = []
    commit('setFormPropertyAgriculture', emptyDefaultPropertyAgriculture)
  },
  resetFormPropertyLivestock({ commit }) {
    commit('setFormPropertyLivestock', defaultPropertyLivestock)
  },
  deleteProperty({ dispatch }, records) {
    apiClient
      .post('property/delete', { records: records })
      .then((response) => {
        if (response.data.success) {
          dispatch('showToast', {
            message: t('notify.title.success'),
            toastType: 'success',
          })
          dispatch('apiGetProperties')
        } else {
          dispatch('showToast', {
            message: response.data.message,
            toastType: 'error',
          })
        }
      })
      .catch((error) => {
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
      })
  },
  shareProperty({ commit, dispatch }, payload) {
    //structure: payload.id; payload.client_comment
    commit('showPageLoader')
    apiClient
      .post('property/shareWithTc', payload)
      .then((response) => {
        commit('hidePageLoader')
        if (response.data.success) {
          dispatch('showToast', {
            message: t('notify.title.success'),
            toastType: 'success',
          })
          dispatch('apiGetProperties')
        } else {
          dispatch('showToast', {
            message: response.data.message,
            toastType: 'error',
          })
        }
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
      })
  },
  dataRequestCompleted({ commit, dispatch }, record_uid) {
    commit('showPageLoader')
    apiClient
      .post('property/dataRequestCompleted', { id: record_uid })
      .then((response) => {
        commit('hidePageLoader')
        if (response.data.success) {
          dispatch('showToast', {
            message: t('notify.title.success'),
            toastType: 'success',
          })
          dispatch('apiGetProperties')
        } else {
          dispatch('showToast', {
            message: response.data.message,
            toastType: 'error',
          })
        }
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
      })
  },
  apiGetCommunities({ commit }, payload) {
    apiClient
      .post('getCommunityList', { state: payload.federalState })
      .then((response) => {
        if (response.data.success) {
          commit('setArrCommunities', response.data.communities)
          commit('setArrFederalStatesCommunitiesForFederalStateName', {
            federalStateName: payload.federalState,
            arrCommunities: response.data.communities,
          })
        } else {
          commit('setArrCommunities', [])
        }
      })
      .catch(() => {
        commit('setArrCommunities', [])
      })
  },
  apiGetCommunitiesForFederalStatesPoolOnly({ commit }, payload) {
    apiClient
      .post('getCommunityList', { state: payload.federalState })
      .then((response) => {
        if (response.data.success) {
          commit('setArrFederalStatesCommunitiesForFederalStateName', {
            federalStateName: payload.federalState,
            arrCommunities: response.data.communities,
          })
        } else {
          commit('setArrFederalStatesCommunitiesForFederalStateName', {
            federalStateName: payload.federalState,
            arrCommunities: [],
          })
        }
      })
      .catch(() => {
        commit('setArrFederalStatesCommunitiesForFederalStateName', {
          federalStateName: payload.federalState,
          arrCommunities: [],
        })
      })
  },

  apiGetCountries({ commit }) {
    apiClient
      .post('country/list', {})
      .then((response) => {
        if (response.data.success) {
          commit('setArrCountries', {
            countries: response.data.countries,
          })
        } else {
          commit('setArrCountries', {})
        }
      })
      .catch(() => {
        commit('setArrCountries', {})
      })
  },

  apiGetGemarkungen({ commit }, payload) {
    apiClient
      .post('getGemarkungenList', {
        state: payload.federalState,
        gemeinde: payload.community,
      })
      .then((response) => {
        if (response.data.success) {
          commit('setArrGemarkungen', response.data.gemarkungen)
          commit('setArrGemarkungenCollectionForStateAndCommunity', {
            federalStateName: payload.federalState,
            communityName: payload.community,
            arrGemarkungen: response.data.gemarkungen,
          })
        } else {
          commit('setArrGemarkungen', [])
        }
      })
      .catch(() => {
        commit('setArrGemarkungen', [])
      })
  },

  apiGetGemarkungenCollection({ commit }, payload) {
    apiClient
      .post('getGemarkungenList', {
        state: payload.federalState,
        gemeinde: payload.community,
      })
      .then((response) => {
        if (response.data.success) {
          commit('setArrGemarkungenCollectionForStateAndCommunity', {
            federalStateName: payload.federalState,
            communityName: payload.community,
            arrGemarkungen: response.data.gemarkungen,
          })
        } else {
          commit('setArrGemarkungenCollectionForStateAndCommunity', {
            federalStateName: payload.federalState,
            communityName: payload.community,
            arrGemarkungen: [],
          })
        }
      })
      .catch(() => {
        commit('setArrGemarkungenCollectionForStateAndCommunity', {
          federalStateName: payload.federalState,
          communityName: payload.community,
          arrGemarkungen: [],
        })
      })
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
