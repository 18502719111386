import { mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions(['changeInfoTooltip']),
    setInfoTooltip(val) {
      this.changeInfoTooltip(val)
    },
    showHide() {
      document.addEventListener('click', (e) => {
        this.performShowHide(e)
      })
      document.addEventListener('focusin', (e) => {
        this.performShowHide(e)
      })
    },
    performShowHide(e) {
      if (this.infoTooltip == 0) {
        return
      }
      const isIcon = e.target.classList.contains('tool-tip-icon')
      const hoverTooltip = e.target.classList.contains('hover-tooltip')
      const isAutocomplete = e.target.classList.contains('p-autocomplete-input')
      const preventTooltip = e.target.classList.contains('prevent-tooltip')
      const isShowImage = e.target.classList.contains('information-image')

      if (isShowImage) {
        this.$store.commit('setInfoImageUrl', e.target.src)
      }

      if (!isIcon && !hoverTooltip && !isAutocomplete && !preventTooltip) {
        this.performHide()
      }
    },
    performHide() {
      this.setInfoTooltip(0)
      this.$store.commit('setInfoImageUrl', false)
    },
  },
}
