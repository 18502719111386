<template lang="html">
  <div>
    <ul class="tabs__header">
      <li
        v-for="tab in tabs"
        :key="tab.name"
        @click="activate(tab)"
        :class="{ tab__selected: tab.slug === activeTab }"
      >
        <div>{{ tab.name }}</div>
      </li>
    </ul>
    <div>
      <div
        class="tab-pane"
        v-for="(item, index) in tabs"
        :key="index"
        v-show="activeTab === item.slug"
      >
        <slot :name="`tab-pane-${item.slug}`" />
      </div>
    </div>
    <div class="tab-footer">
      <slot name="tab-footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: 'light',
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    selectedTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeTab: '',
      selectedIndex: 0, // the index of the selected tab,
    }
  },
  watch: {
    selectedTab(nv) {
      if (nv) {
        let tab = this.tabs.find((o) => o.slug === nv)
        if (tab && tab.slug) {
          this.activeTab = tab.slug
        }
      }
    },
  },
  mounted() {
    if (this.selectedTab) {
      this.activeTab = this.selectedTab
    } else {
      this.activeTab = this.tabs.length > 0 ? this.tabs[0].slug : ''
    }
  },
  methods: {
    activate(item) {
      this.activeTab = item.slug
      this.$emit('click', item.slug)
    },
  },
}
</script>

<style lang="css">
ul.tabs__header {
  border-bottom: 1px solid #d0d0d0;
  display: flex;
  list-style: none;
  padding: 0;
}

ul.tabs__header > li {
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin-top: 10px;
  width: auto;
}

ul.tabs__header > li.tab__selected {
  background-color: #ffffff;
  border: 2px solid #d0d0d0;
  border-bottom: none;
  border-radius: 5px 5px 0px 0px;
  box-sizing: border-box;
  height: 40px;
  width: auto;
}

ul.tabs__header > li div {
  padding: 10px 20px;
  width: 100%;
}
ul.tabs__header > li.tab__selected div {
  background: #fff;
  font-weight: 600;
}

.tab {
  border-radius: 10px;
  color: black;
  display: inline-block;
  min-height: 400px;
  min-width: 800px;
  padding: 20px;
}
.tab-footer {
  padding: 15px 0 5px 15px;
}
</style>
