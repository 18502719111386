import apiClient from '../../Api'

const state = {
  recordType: '',
  recordId: 0,
  recordTimestamp: 0,
  reloadRecord: false,
  changeInfo: {},
}

const getters = {
  getRecordUpdatedTime: (state) => state.recordTimestamp,
  getRecordChangeInfo: (state) => state.changeInfo,
  triggerRefreshRecord: (state) => state.reloadRecord,
}

const mutations = {
  start: (state, payload) => {
    state.recordType = payload.recordType ? payload.recordType : ''
    state.recordId = payload.recordId ? parseInt(payload.recordId) : 0
    state.recordTimestamp = payload.recordTimestamp ? parseInt(payload.recordTimestamp) : 0
  },
  stop: (state) => {
    state.recordType = ''
    state.recordId = 0
    state.recordTimestamp = 0
    state.changeInfo = {}
    state.reloadRecord = false
  },
  update: (state, payload) => {
    state.reloadRecord = false
    state.changeInfo = payload.changeInfo ? payload.changeInfo : {}
    state.recordTimestamp = payload.recordTimestamp ? parseInt(payload.recordTimestamp) : 0
  },
  refreshRecord: (state) => {
    state.reloadRecord = true
  },
}

const actions = {
  startObserver({ commit, dispatch }, payload) {
    commit('stop')
    commit('start', payload)
    dispatch('trigger')
  },
  stopObserver({ commit }) {
    commit('stop')
  },
  trigger({ commit, dispatch }) {
    if (state.recordType !== '' && state.recordId > 0) {
      apiClient
        .post('property/dataChanges', {
          recordId: state.recordId,
          recordTimestamp: state.recordTimestamp,
        })
        .then((res) => {
          if (res.data.success && res.data.modifiedBy) {
            commit('update', {
              recordTimestamp: res.data.recordTimestamp,
              changeInfo: {
                userName: res.data.modifiedBy,
                updateTime: res.data.modifiedAt,
              },
            })
          }
        })

      setTimeout(() => {
        dispatch('trigger')
      }, 60000)
    }
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
