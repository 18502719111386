<template>
  <!-- Login  Start -->
  <section class="login_form section">
    <div class="container">
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <h4 class="heading_title centertitle">
            {{ $t('LoginNopwd.title') }} <label class="sub mb-10">{{ getVersion }}</label>
          </h4>
          <form>
            <label
              class="label-title"
              for="Benutzername"
              >{{ $t('LoginNopwd.name') }}</label
            >
            <input
              type="text"
              autocomplete="off"
              v-model="userName"
              class="form-control input-field"
              id="Benutzername"
              disabled
              placeholder="M-12345678"
            />
            <button
              @click.stop.prevent="$router.go(-1)"
              type="submit"
              class="button-style button-style-border topspace20"
            >
              {{ $t('LoginNopwd.button') }}
            </button>
          </form>
        </div>
        <div
          class="benutzerkonto"
          v-if="showNote"
        >
          <span class="note_icon"><img src="@/assets/note.svg" /></span>
          {{ $t('LoginNopwd.note') }}
        </div>
      </div>
    </div>
  </section>

  <!-- Login  Start ///////////// -->
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'LoginNoPassword',
  data: () => ({
    username: '',
    showNote: true,
  }),
  created() {
    this.sentEmail(this.userName)
  },
  computed: {
    ...mapGetters(['userName']),

    getVersion() {
      return `${this.$t('login.version')}${process.env.PACKAGE_VERSION}`
    },
  },
  watch: {},
  methods: {
    ...mapActions(['resetPass']),
    sentEmail(val) {
      this.resetPass(val)
    },
  },
}
</script>
<style scoped>
section.section {
  padding: 100px 0 0 0;
}
.topspace60 {
  margin-top: 60px;
}
.topspace20 {
  margin-top: 20px;
}
h4.heading_title {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 40px;
}
input.input-field {
  border: 1px solid #bbbbbb;
  border-radius: 0;
  color: #999999;
  font-size: 15px;
  height: 40px;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
}
button.button-style,
a.button-style {
  background: #229d56;
  border: 0;
  border-radius: 0;
  color: #fff;
  height: 40px;
  max-width: 100%;
  width: 100%;
}
label.label-title {
  display: inline-block;
  padding-bottom: 5px;
  text-align: left;
  width: 100%;
}
.benutzerkonto {
  background-color: rgba(34, 157, 86, 0.25);
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin: 30px auto 0 auto;
  max-width: 520px;
  padding: 10px;
  text-align: left;
  width: 100%;
}
span.note_icon {
  padding-right: 10px;
}
button.button-style.button-style-border.topspace20 {
  background: none;
  border: 1px solid #bbbbbb;
  color: #333333;
}
.sub {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  color: #aeaeae;
  font-family: 'Segoe UI', sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}
</style>
