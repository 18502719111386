<template>
  <section class="Step_SectionPropertyLeasehold">
    <fieldset>
      <div class="Step_SectionPropertyLeasehold">
        <InfoTextHeading
          :headingText="$t('newProperty.propertyLeasehold.headline')"
          :infoText="$t('infoTextDisabledFields')"
        >
        </InfoTextHeading>

        <div
          class="col-md-7"
          @mouseenter="setInfoTooltip(0)"
        >
          <div class="data-form">
            <InfoText
              :text="$t('newProperty.propertyLeasehold.subheading')"
              backgroundColor="rgba(239, 239, 239, 0.3)"
            >
            </InfoText>
          </div>
        </div>

        <div class="form-group">
          <div class="row_setp_main">
            <div
              v-for="(leasehold, index) in form.leaseholds"
              :key="index"
            >
              <!-- Erbbauverpflichtete/Eigentümer des Gebäudes 1 -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="deleteIcon">
                  <h2 class="subheading_title">{{ $t('newProperty.propertyLeasehold.title') }} {{ index + 1 }}</h2>
                  <Svgall
                    @click="handleDelete(index)"
                    :name="'delete'"
                    :color="primaryColor"
                    v-show="!isPropertyReadOnly"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip(0)"
                  />
                </div>
                <p
                  v-show="!isPropertyReadOnly"
                  style="text-align: right"
                >
                  <a
                    style="color: var(--api-background)"
                    href="#"
                    @click.prevent="fillAddressFromClient(index)"
                  >
                    {{ $t('newProperty.from_your_data') }}
                  </a>
                </p>
                <hr class="subheading_devide" />
              </div>
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyLeaseholdFormOfAddress_' + index)"
              >
                <div
                  class="data-form"
                  @mouseenter="setInfoTooltip('propertyLeaseholdFormOfAddress_' + index)"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyLeasehold.salutations') }}
                  </label>
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyLeaseholdFormOfAddress_' + index && formOfAddressDisabled"
                    :isDisabled="formOfAddressDisabled"
                  />
                  <select
                    v-model="form.leaseholds[index].formOfAddress"
                    :disabled="isPropertyReadOnly || formOfAddressDisabled"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip('propertyLeaseholdFormOfAddress_' + index)"
                  >
                    <option
                      v-for="option in form_of_address_options"
                      v-bind:key="option.code"
                      :value="option.code"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyLeaseholdTitleAcademicDegree_' + index)"
              >
                <div
                  class="data-form"
                  @mouseenter="setInfoTooltip('propertyLeaseholdTitleAcademicDegree_' + index)"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyLeasehold.academic_degree') }}
                    <InfoTooltip
                      v-if="
                        infoTooltip === 'propertyLeaseholdTitleAcademicDegree_' + index && titleAcademicDegreeDisabled
                      "
                      :isDisabled="titleAcademicDegreeDisabled"
                    />
                  </label>
                  <input
                    autocomplete="off"
                    v-model="form.leaseholds[index].titleAcademicDegree"
                    :disabled="isPropertyReadOnly || titleAcademicDegreeDisabled"
                    ref="dd"
                    type="text"
                    :maxlength="15"
                    class="input-field hover-tooltip"
                    placeholder=""
                    @focusin="setInfoTooltip('propertyLeaseholdTitleAcademicDegree_' + index)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('leasehold_' + index + '_titleAcademicDegree')"
                  >
                    {{ form.errors().get('leasehold_' + index + '_titleAcademicDegree') }}
                  </span>
                </div>
              </div>
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.fname') }}</label>
                  <input
                    ref="parcelDistrict"
                    :maxlength="25"
                    autocomplete="off"
                    type="text"
                    v-model="form.leaseholds[index].firstName"
                    placeholder=""
                    class="input-field hover-tooltip"
                    :disabled="isPropertyReadOnly"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span v-if="form.errors().has('leasehold_' + index + '_titleAcademicDegree')">
                    {{ form.errors().get('leasehold_' + index + '_titleAcademicDegree') }}
                  </span>
                </div>
              </div>
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.propertyLeasehold.lname') }}</label>

                  <input
                    type="text"
                    autocomplete="off"
                    :maxlength="25"
                    v-model="form.leaseholds[index].lastName"
                    placeholder=""
                    class="input-field hover-tooltip"
                    :disabled="isPropertyReadOnly"
                    @mouseenter="setInfoTooltip(0)"
                  />
                  <span v-if="form.errors().has('leasehold_' + index + '_titleAcademicDegree')">
                    {{ form.errors().get('leasehold_' + index + '_titleAcademicDegree') }}
                  </span>
                </div>
              </div>
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyLeaseholdStreet_' + index)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.street') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyLeaseholdStreet_' + index"
                      :content="$t('newProperty.infoTooltip.street.content')"
                    />
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    :maxlength="25"
                    v-model="form.leaseholds[index].street"
                    placeholder=""
                    class="input-field hover-tooltip"
                    :disabled="isPropertyReadOnly"
                    @focusin="setInfoTooltip('propertyLeaseholdStreet_' + index)"
                  />
                  <span class="help-text float-end"
                    >{{ form.leaseholds[index].street ? form.leaseholds[index].street.length : 0 }}/25</span
                  >
                  <span v-if="form.errors().has('leasehold_' + index + '_titleAcademicDegree')">
                    {{ form.errors().get('leasehold_' + index + '_titleAcademicDegree') }}
                  </span>
                </div>
              </div>
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyLeaseholdHouseNumber_' + index)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.houseno') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyLeaseholdHouseNumber_' + index"
                      :content="$t('newProperty.infoTooltip.street.content')"
                    />
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    :maxlength="39"
                    v-model="form.leaseholds[index].houseNumber"
                    placeholder=""
                    class="input-field hover-tooltip"
                    :disabled="isPropertyReadOnly"
                    @focusin="setInfoTooltip('propertyLeaseholdHouseNumber_' + index)"
                  />
                  <span v-if="form.errors().has('leasehold_' + index + '_titleAcademicDegree')">
                    {{ form.errors().get('leasehold_' + index + '_titleAcademicDegree') }}
                  </span>
                </div>
              </div>
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.plz') }}</label>
                  <input
                    type="number"
                    :maxlength="12"
                    autocomplete="off"
                    v-model="form.leaseholds[index].zip"
                    placeholder=""
                    class="input-field hover-tooltip"
                    :disabled="isPropertyReadOnly"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span v-if="form.errors().has('leasehold_' + index + '_titleAcademicDegree')">
                    {{ form.errors().get('leasehold_' + index + '_titleAcademicDegree') }}
                  </span>
                </div>
              </div>
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.city') }}</label>
                  <input
                    type="text"
                    :maxlength="25"
                    autocomplete="off"
                    v-model="form.leaseholds[index].city"
                    placeholder=""
                    class="input-field hover-tooltip"
                    :disabled="isPropertyReadOnly"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span v-if="form.errors().has('leasehold_' + index + '_titleAcademicDegree')">
                    {{ form.errors().get('leasehold_' + index + '_titleAcademicDegree') }}
                  </span>
                </div>
              </div>

              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.pobox') }}</label>
                  <input
                    type="number"
                    :maxlength="6"
                    autocomplete="off"
                    v-model="form.leaseholds[index].postbox"
                    placeholder=""
                    class="input-field hover-tooltip"
                    :disabled="isPropertyReadOnly"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span v-if="form.errors().has('leasehold_' + index + '_titleAcademicDegree')">
                    {{ form.errors().get('leasehold_' + index + '_titleAcademicDegree') }}
                  </span>
                </div>
              </div>
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.land') }}</label>

                  <select
                    v-model="form.leaseholds[index].countryUid"
                    :disabled="isPropertyReadOnly"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip(0)"
                  >
                    <option
                      :value="null"
                      disabled
                      selected
                    >
                      {{ $t('select.select_any') }}
                    </option>
                    <option
                      v-for="option in $store.state.Properties.arrCountries.countries"
                      v-bind:key="option.code"
                      :value="option.code"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                  <span v-if="form.errors().has('land')">
                    {{ form.errors().get('land') }}
                  </span>
                </div>
              </div>
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyLeaseholdTelephoneNumber_' + index)"
              >
                <div
                  class="data-form"
                  @mouseenter="setInfoTooltip('propertyLeaseholdTelephoneNumber_' + index)"
                >
                  <label class="label-title">
                    {{ $t('newProperty.telefonnummer') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyLeaseholdTelephoneNumber_' + index && telephoneNumberDisabled"
                      :isDisabled="telephoneNumberDisabled"
                    />
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    :disabled="isPropertyReadOnly || telephoneNumberDisabled"
                    v-model="form.leaseholds[index].telephoneNumber"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @focusin="setInfoTooltip('propertyLeaseholdTelephoneNumber_' + index)"
                  />
                  <span class="help-text float-end">{{ $t('newProperty.infoTooltip.optional_help_text') }}</span>
                  <span v-if="form.errors().has('land')">
                    {{ form.errors().get('land') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-md-7"
          @mouseenter="setInfoTooltip(0)"
        >
          <div class="add-field">
            <button
              class="add-field-button hover-tooltip"
              @click="addMore"
              v-show="!isPropertyReadOnly"
              @focusin="setInfoTooltip(0)"
            >
              <Svgall
                :name="'add_more'"
                :color="primaryColor"
              />
              <span>{{ $t('newProperty.addMore') }}</span>
            </button>
          </div>
        </div>
        <div
          class="text_left step-button-section"
          @mouseenter="setInfoTooltip(0)"
        >
          <div>
            <button
              type="button"
              @click.prevent="cancel"
              class="step-button-style border-button-style hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.abort') }}
            </button>
            <button
              type="button"
              @click.prevent="save"
              class="step-button-style border-button-style hover-tooltip"
              v-show="!isPropertyReadOnly"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.save') }}
            </button>
            <button
              type="button"
              v-show="back_button_activated"
              @click.prevent="callForPrecedingStep"
              class="step-button-style border-button-style"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.back') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              :style="[primaryColor ? { background: primaryColor } : '']"
              class="step-button-style hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              class="step-button-enter hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue_enter') }}
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </section>
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import { mapGetters, mapActions } from 'vuex'
import InfoTooltip from '@/components/helper/InfoTooltip'
import ShowHideTooltip from '@/mixins/ShowHideTooltip'
import InfoTextHeading from '@/components/helper/InfoTextHeading'
import InfoText from '@/components/helper/InfoText'
import { defaultPropertyLeasehold } from '@/store/defaults/properties/DefaultPropertyLeasehold'

export default {
  name: 'PropertyLeasehold',
  components: { InfoText, InfoTextHeading, InfoTooltip },
  mixins: [ShowHideTooltip],
  emits: ['callForNextStep', 'callForPrecedingStep', 'callForSaveProperty', 'cancelPropVisible'],
  data() {
    return {
      primaryColor: '',
      fontColor: '',
      formOfAddressDisabled: false,
      titleAcademicDegreeDisabled: false,
      telephoneNumberDisabled: false,
      form_of_address_options: [],
      form: form({
        leaseholds: [
          {
            formOfAddress: '0',
            titleAcademicDegree: '',
            telephoneNumber: '',
            firstName: '',
            lastName: '',
            street: '',
            id: 0,
            houseNumber: '',
            zip: '',
            city: '',
            postbox: '',
            countryUid: '0',
          },
        ],
      }),
      countries: '',
      salutationMatches: {
        mr: '2',
        mrs: '3',
        '-': '1',
        company: '7',
        joint_venture: '8',
        working_group: '9',
        property_association: '10',
        civil_law_partnership: '11',
        law_firm: '12',
        joint_practice: '13',
        farming: '14',
        homeowners: '15',
        partnership: '16',
        shipping: '17',
      },
      back_button_activated: false,
    }
  },
  created() {
    this.apiGetCountries()
  },
  mounted() {
    this.initForm()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : ''
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.primaryColor : ''
    this.showHide()
  },
  beforeUnmount() {
    this.saveForm()
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getFormPropertyLeasehold',
      'getCountries',
      'getObjPropertyDetails',
      'isPropertyReadOnly',
      'infoTooltip',
    ]),

    isNewProperty() {
      return this.$parent.isNewProperty
    },

    isShowNextStepButton() {
      let isShow = true
      if (this.$parent.step === this.$parent.maxStep && this.isPropertyReadOnly) {
        isShow = false
      }
      return isShow
    },
  },
  watch: {
    getFormPropertyLeasehold() {
      this.initForm()
    },
  },
  methods: {
    ...mapActions(['apiGetCountries', 'resetFormPropertyLeasehold', 'changeInfoTooltip']),

    setInfoTooltip(val) {
      this.changeInfoTooltip(val)
    },

    initForm() {
      this.initSelectOpts()
      //this.removeFlatFormFields()
      for (const [key, value] of Object.entries(this.$store.state.Properties.formPropertyLeasehold)) {
        if (typeof value !== 'undefined') {
          this.form[key] = JSON.parse(JSON.stringify(value))
        }
      }
    },

    initSelectOpts() {
      let fsu = ''
      if (
        typeof this.$store.state.Properties.objPropertyDetails.step2 === 'object' &&
        typeof this.$store.state.Properties.objPropertyDetails.step2.federalStateUid !== 'undefined'
      ) {
        fsu = this.$store.state.Properties.objPropertyDetails.step2.federalStateUid
      }
      if (fsu === '2' || fsu === '6' || fsu === '9') {
        let resultArray = []
        let options = this.$tm('newProperty.propertyLeasehold.form_of_address_options.2-6-9')
        for (let k of options) {
          resultArray.push({
            code: this.$rt(k.code),
            name: this.$rt(k.name),
          })
        }
        this.form_of_address_options = resultArray
        this.formOfAddressDisabled = false
        this.titleAcademicDegreeDisabled = false
        this.telephoneNumberDisabled = false
      } else {
        this.form_of_address_options = []
        this.formOfAddressDisabled = true
        this.titleAcademicDegreeDisabled = true
        this.telephoneNumberDisabled = true
      }
    },

    saveForm() {
      let myKeys = []
      this.resetFormPropertyLeasehold()
      myKeys = Object.keys(this.$store.state.Properties.formPropertyLeasehold)
      if (typeof myKeys === 'object') {
        myKeys.forEach(function (key) {
          if (typeof this.form[key] !== 'undefined') {
            this.$store.state.Properties.formPropertyLeasehold[key] = JSON.parse(JSON.stringify(this.form[key]))
          }
        }, this)
      }
    },

    cancel() {
      this.$emit('cancelPropVisible')
    },

    callForPrecedingStep() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForPrecedingStep')
      } else {
        this.$emit('callForPrecedingStep')
      }
    },

    callForNextStep() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForNextStep')
      } else {
        this.$emit('callForNextStep')
      }
    },

    save() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForSaveProperty')
      }
    },

    invokeReference(ref) {
      this.$nextTick(() => {
        if (!this.$refs[ref]) return
        this.$refs[ref].focus()
      })
    },

    addMore() {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyLeasehold.leaseholds[0]))
      this.form.leaseholds.push(myObject)
    },
    handleDelete(index) {
      if (typeof index === 'number' && index >= 0 && index < this.form.leaseholds.length) {
        this.form.leaseholds.splice(index, 1)
      }
    },
    removeFlatFormFields() {
      Object.keys(this.form.all()).forEach((key) => {
        if (key.startsWith('owner_')) {
          this.form.forget(key)
        }
      })
      this.form.rules({})
    },
    prepareForValidate() {
      let myFlatFields = {}

      //remove existing flat fields first
      this.removeFlatFormFields()

      //add flat fields
      this.form.leaseholds.forEach(function (leasehold, leaseholdIndex) {
        myFlatFields['leasehold_' + leaseholdIndex.toString() + '_titleAcademicDegree'] = leasehold.titleAcademicDegree
        this.form.validatorInstance.addMessage(
          'leasehold_' + leaseholdIndex.toString() + '_titleAcademicDegree.max',
          this.$t('newProperty.propertyOwnership.messages.title_max'),
        )
        this.form.validatorInstance.addRule(
          'leasehold_' + leaseholdIndex.toString() + '_titleAcademicDegree',
          'max: 15',
        )
      }, this)

      this.form.set(myFlatFields)
    },

    fillAddressFromClient(index) {
      if (
        typeof index === 'number' &&
        index >= 0 &&
        Array.isArray(this.form.leaseholds) &&
        index < this.form.leaseholds.length
      ) {
        if (
          this.formOfAddressDisabled === false &&
          typeof this.getCurrentUser.client.salutation !== 'undefined' &&
          typeof this.salutationMatches[this.getCurrentUser.client.salutation] !== 'undefined'
        ) {
          this.form.leaseholds[index].formOfAddress = this.salutationMatches[this.getCurrentUser.client.salutation]
        }

        if (typeof this.getCurrentUser.client.clientType === 'string') {
          switch (this.getCurrentUser.client.clientType) {
            case 'private':
              if (!this.titleAcademicDegreeDisabled) {
                this.form.leaseholds[index].titleAcademicDegree = this.getCurrentUser.client.clientTitle
              }
              this.form.leaseholds[index].firstName = this.getCurrentUser.client.firstName
              this.form.leaseholds[index].lastName = this.getCurrentUser.client.lastName
              break
            case 'business':
              this.form.leaseholds[index].lastName = this.getCurrentUser.client.companyName
              break
          }
        }
        this.form.leaseholds[index].street = this.getCurrentUser.client.contact.street
        this.form.leaseholds[index].houseNumber = this.getCurrentUser.client.contact.houseNumber
        if (!this.telephoneNumberDisabled) {
          this.form.leaseholds[index].telephoneNumber = this.getCurrentUser.client.contact.phone
        }
        this.form.leaseholds[index].zip = this.getCurrentUser.client.contact.zipCode
        this.form.leaseholds[index].postbox = this.getCurrentUser.client.contact.poBox
        this.form.leaseholds[index].city = this.getCurrentUser.client.contact.city
        this.form.leaseholds[index].countryUid = this.getCurrentUser.client.contact.countryUid
      }
    },
  },
}
</script>

<style type="text/css">
textarea.textarea-field {
  border: 1px solid #bbbbbb;
  height: 150px;
  width: 100%;
}

.Step_SectionPropertyLeasehold .tooltip-icon img {
  height: 18px;
  margin-left: 5px;
}

label.label-title.tooltip-icon {
  position: relative;
}

.ToolTipTextBox {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: none;
  max-width: 600px;
  padding: 20px;
  padding: 15px !important;
  position: absolute;
  right: 0;
  top: 35px;
  transform: translate3d(0px, 40px, 0px) !important;
  z-index: 1;
}

/*.Step_SectionPropertyLeasehold .tooltip-icon img:hover + .ToolTipTextBox {
    display: block;
}*/
select:disabled {
  background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
  cursor: no-drop;
}
</style>
