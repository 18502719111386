<template>
  <section class="Step_SectionPropertyMoreDetails">
    <fieldset>
      <div class="Step_SectionPropertyDetails">
        <InfoTextHeading
          :headingText="$t('newProperty.propertyMoreDetails.headline1')"
          :infoText="$t('infoTextDisabledFields')"
        >
        </InfoTextHeading>

        <div class="form-group">
          <div class="row_setp_main">
            <!-- Eigentumsverhältnisse -->
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyMoreDetailsOwnershipStructure')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyMoreDetails.ownership_structure') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyMoreDetailsOwnershipStructure'"
                    :content="$t('newProperty.infoTooltip.moreDetails.ownershipStructure.content')"
                    :url="$t('newProperty.infoTooltip.moreDetails.ownershipStructure.url')"
                  />
                </label>
                <select
                  :disabled="isPropertyReadOnly"
                  v-model.number="form.ownership_structure"
                  @change="onChangeOwnershipStructure"
                  class="hover-tooltip"
                  @focusin="setInfoTooltip('propertyMoreDetailsOwnershipStructure')"
                >
                  <option
                    :value="''"
                    disabled
                    selected
                  >
                    {{ $t('select.select_any') }}
                  </option>
                  <option
                    v-for="option in ownership_structures"
                    v-bind:key="option.value"
                    :value="option.value"
                  >
                    {{ $t('ownership_structure.' + option.value) }}
                  </option>
                </select>

                <span
                  class="error"
                  v-if="form.errors().has('ownership_structure')"
                >
                  {{ form.errors().get('ownership_structure') }}
                </span>
              </div>
            </div>

            <!-- Art des Grundstücks -->
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyMoreDetailsPropertyType')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyMoreDetails.property_type') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyMoreDetailsPropertyType'"
                    :content="$t('newProperty.infoTooltip.moreDetails.propertyType.content')"
                    :url="$t('newProperty.infoTooltip.moreDetails.propertyType.url')"
                    :isDisabled="!property_type_activated"
                  />
                </label>
                <div
                  class="hover-tooltip"
                  @mouseenter="setInfoTooltip('propertyMoreDetailsPropertyType')"
                >
                  <select
                    :disabled="isPropertyReadOnly || !property_type_activated"
                    v-model.number="form.property_type"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip('propertyMoreDetailsPropertyType')"
                  >
                    <option
                      v-for="option in property_type_options"
                      v-bind:key="option.code"
                      :value="option.code"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>

                <span
                  class="error"
                  v-if="form.errors().has('property_type')"
                >
                  {{ form.errors().get('property_type') }}
                </span>
              </div>
            </div>

            <!-- Bei unbebauten Grundstücken gegebenenfalls abweichender Entwicklungszustand -->
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyMoreDetailsDevelopmentState')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyMoreDetails.development_state') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyMoreDetailsDevelopmentState'"
                    :content="$t('newProperty.infoTooltip.moreDetails.developmentState.content')"
                    :isDisabled="!development_state_activated"
                    :numberOfLinesOfLabel="2"
                  />
                </label>
                <div
                  class="hover-tooltip"
                  @mouseenter="setInfoTooltip('propertyMoreDetailsDevelopmentState')"
                >
                  <select
                    :disabled="isPropertyReadOnly || !development_state_activated"
                    v-model.number="form.development_state"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip('propertyMoreDetailsDevelopmentState')"
                  >
                    <option
                      :value="''"
                      disabled
                      selected
                    >
                      {{ $t('select.select_any') }}
                    </option>
                    <option
                      v-for="option in development_states"
                      v-bind:key="option.value"
                      :value="option.value"
                    >
                      {{ $t('development_state.' + option.value) }}
                    </option>
                  </select>
                </div>

                <span
                  class="error"
                  v-if="form.errors().has('development_state')"
                >
                  {{ form.errors().get('development_state') }}
                </span>
              </div>
            </div>

            <!-- Erstreckt sich das Gründstück über mehrere hebeberechtigte Gemeinden -->
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyMoreDetailsMultiCommunities')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyMoreDetails.multi_communities') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyMoreDetailsMultiCommunities'"
                    :content="$t('newProperty.infoTooltip.moreDetails.multiCommunities.content')"
                    :url="$t('newProperty.infoTooltip.moreDetails.multiCommunities.url')"
                  />
                </label>
                <div class="gdr_plots">
                  <div class="gdr_plots_inner">
                    <input
                      type="radio"
                      :disabled="isPropertyReadOnly"
                      class="radio-button hover-tooltip"
                      :value="1"
                      name="multi_communities_toggle"
                      v-model="form.multi_communities"
                      @focusin="setInfoTooltip('propertyMoreDetailsMultiCommunities')"
                    />
                    <span class="grd_radio01 grd_radio">{{ $t('newProperty.yes') }}</span>
                  </div>
                  <div class="gdr_plots_inner">
                    <input
                      type="radio"
                      :disabled="isPropertyReadOnly"
                      class="radio-button hover-tooltip"
                      :value="0"
                      name="multi_communities_toggle"
                      v-model="form.multi_communities"
                      @focusin="setInfoTooltip('propertyMoreDetailsMultiCommunities')"
                    />
                    <span class="grd_radio01 grd_radio">{{ $t('newProperty.no') }}</span>
                  </div>
                </div>
                <span
                  class="error"
                  v-if="form.errors().has('multi_communities')"
                >
                  {{ form.errors().get('multi_communities') }}
                </span>
              </div>
            </div>

            <!-- Ist auf dem Grundstück ein Erbbaurecht bestellt? -->
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyMoreDetailsLeasehold')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyMoreDetails.leasehold') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyMoreDetailsLeasehold'"
                    :content="$t('newProperty.infoTooltip.moreDetails.leasehold.content')"
                    :url="$t('newProperty.infoTooltip.moreDetails.leasehold.url')"
                    :isDisabled="!leasehold_activated"
                  />
                </label>
                <div class="gdr_plots">
                  <div
                    class="hover-tooltip"
                    @mouseenter="setInfoTooltip('propertyMoreDetailsLeasehold')"
                  >
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly || !leasehold_activated"
                        class="radio-button hover-tooltip"
                        :value="1"
                        name="leasehold_toggle"
                        v-model="form.leasehold"
                        @focusin="setInfoTooltip('propertyMoreDetailsLeasehold')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.yes') }}</span>
                    </div>
                  </div>
                  <div
                    class="hover-tooltip"
                    @mouseenter="setInfoTooltip('propertyMoreDetailsLeasehold')"
                  >
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly || !leasehold_activated"
                        class="radio-button hover-tooltip"
                        :value="0"
                        name="leasehold_toggle"
                        v-model="form.leasehold"
                        @focusin="setInfoTooltip('propertyMoreDetailsLeasehold')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.no') }}</span>
                    </div>
                  </div>
                </div>
                <span
                  class="error"
                  v-if="form.errors().has('leasehold')"
                >
                  {{ form.errors().get('leasehold') }}
                </span>
              </div>
            </div>

            <!-- Gebäude auf fremden Grund und Boden -->
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyMoreDetailsBuildingOnForeignGround')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyMoreDetails.building_on_foreign_ground') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyMoreDetailsBuildingOnForeignGround'"
                    :content="$t('newProperty.infoTooltip.moreDetails.buildingOnForeignGround.content')"
                    :url="$t('newProperty.infoTooltip.moreDetails.buildingOnForeignGround.url')"
                    :isDisabled="!building_on_foreign_ground_activated"
                    :number-of-lines-of-label="2"
                  />
                </label>
                <div class="gdr_plots">
                  <div
                    class="hover-tooltip"
                    @mouseenter="setInfoTooltip('propertyMoreDetailsBuildingOnForeignGround')"
                  >
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly || !building_on_foreign_ground_activated"
                        class="radio-button hover-tooltip"
                        :value="1"
                        name="building_on_foreign_ground_toggle"
                        v-model="form.building_on_foreign_ground"
                        @focusin="setInfoTooltip('propertyMoreDetailsBuildingOnForeignGround')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.yes') }}</span>
                    </div>
                  </div>
                  <div
                    class="hover-tooltip"
                    @mouseenter="setInfoTooltip('propertyMoreDetailsBuildingOnForeignGround')"
                  >
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly || !building_on_foreign_ground_activated"
                        class="radio-button hover-tooltip"
                        :value="0"
                        name="building_on_foreign_ground_toggle"
                        v-model="form.building_on_foreign_ground"
                        @focusin="setInfoTooltip('propertyMoreDetailsBuildingOnForeignGround')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.no') }}</span>
                    </div>
                  </div>
                </div>
                <span
                  class="error"
                  v-if="form.errors().has('building_on_foreign_ground')"
                >
                  {{ form.errors().get('building_on_foreign_ground') }}
                </span>
              </div>
            </div>

            <!-- Ist das Grundstück bebaut und wird überwiegend zu Wohnzwecken genutzt? -->
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyMoreDetailsResidentialPurposes')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyMoreDetails.property_residential_purposes') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyMoreDetailsResidentialPurposes'"
                    :content="$t('newProperty.infoTooltip.moreDetails.residentialPurposes.content')"
                    :url="$t('newProperty.infoTooltip.moreDetails.residentialPurposes.url')"
                    :isDisabled="!property_residential_purposes_activated"
                  />
                </label>
                <div class="gdr_plots">
                  <div
                    class="hover-tooltip"
                    @mouseenter="setInfoTooltip('propertyMoreDetailsResidentialPurposes')"
                  >
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly || !property_residential_purposes_activated"
                        class="radio-button hover-tooltip"
                        :value="1"
                        name="property_residential_purposes_toggle"
                        v-model="form.property_residential_purposes"
                        @focusin="setInfoTooltip('propertyMoreDetailsResidentialPurposes')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.yes') }}</span>
                    </div>
                  </div>
                  <div
                    class="hover-tooltip"
                    @mouseenter="setInfoTooltip('propertyMoreDetailsResidentialPurposes')"
                  >
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly || !property_residential_purposes_activated"
                        class="radio-button hover-tooltip"
                        :value="0"
                        name="property_residential_purposes_toggle"
                        v-model="form.property_residential_purposes"
                        @focusin="setInfoTooltip('propertyMoreDetailsResidentialPurposes')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.no') }}</span>
                    </div>
                  </div>
                </div>
                <span
                  class="error"
                  v-if="form.errors().has('property_residential_purposes')"
                >
                  {{ form.errors().get('property_residential_purposes') }}
                </span>
              </div>
            </div>

            <!-- Ihr(e) Gebäude wurde(n) auf fremden Grund und Boden errichtet? -->
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyMoreDetailsBuildingsOnThirdPartyOwners')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyMoreDetails.buildings_on_third_party_owners') }}
                  <InfoTooltip
                    v-if="
                      infoTooltip === 'propertyMoreDetailsBuildingsOnThirdPartyOwners' &&
                      !buildings_on_third_party_owners_activated
                    "
                    :isDisabled="!buildings_on_third_party_owners_activated"
                  />
                </label>
                <div class="gdr_plots">
                  <div
                    class="hover-tooltip"
                    @mouseenter="setInfoTooltip('propertyMoreDetailsBuildingsOnThirdPartyOwners')"
                  >
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly || !buildings_on_third_party_owners_activated"
                        class="radio-button hover-tooltip"
                        :value="1"
                        name="buildings_on_third_party_owners_toogle"
                        v-model="form.buildings_on_third_party_owners"
                        @focusin="setInfoTooltip('propertyMoreDetailsBuildingsOnThirdPartyOwners')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.yes') }}</span>
                    </div>
                  </div>
                  <div
                    class="hover-tooltip"
                    @mouseenter="setInfoTooltip('propertyMoreDetailsBuildingsOnThirdPartyOwners')"
                  >
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly || !buildings_on_third_party_owners_activated"
                        class="radio-button hover-tooltip"
                        :value="0"
                        name="buildings_on_third_party_owners_toggle"
                        v-model="form.buildings_on_third_party_owners"
                        @focusin="setInfoTooltip('propertyMoreDetailsBuildingsOnThirdPartyOwners')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.no') }}</span>
                    </div>
                  </div>
                </div>
                <span
                  class="error"
                  v-if="form.errors().has('buildings_on_third_party_owners')"
                >
                  {{ form.errors().get('buildings_on_third_party_owners') }}
                </span>
              </div>
            </div>

            <!-- Antrag auf Neueintragung wurde beim Grundbuchamt eingereicht am -->
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyMoreDetailsRegistrationLandRegistry')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyMoreDetails.registration_land_registry') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyMoreDetailsRegistrationLandRegistry'"
                    :content="$t('newProperty.infoTooltip.moreDetails.registrationLandRegistry.content')"
                    :isDisabled="!registration_land_registry_activated"
                  />
                </label>
                <div
                  class="input-icon hover-tooltip"
                  @mouseenter="setInfoTooltip('propertyMoreDetailsRegistrationLandRegistry')"
                >
                  <input
                    type="date"
                    autocomplete="off"
                    :disabled="isPropertyReadOnly || !registration_land_registry_activated"
                    v-model="form.registration_land_registry"
                    placeholder=""
                    class="input-field hover-tooltip"
                    ref="registration_land_registry"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyMoreDetailsRegistrationLandRegistry')"
                  />
                  <!-- <img src="../../assets/calendar.svg" /> -->
                  <Svgall
                    :name="'calendar'"
                    :color="primaryColor"
                    class="date-icon-firefox-fix"
                  />
                </div>
                <span
                  class="error"
                  v-if="form.errors().has('registration_land_registry')"
                >
                  {{ form.errors().get('registration_land_registry') }}
                </span>
              </div>
            </div>

            <!-- Zusätzliche Angabe bei mehr als 10.000 m² Grund und Bodenfläche -->
            <div class="col-md-7">
              <h2
                class="subheading_title"
                @mouseover="setInfoTooltip(0)"
              >
                {{ $t('newProperty.propertyMoreDetails.total_property_part_title') }}
              </h2>
              <div
                class="data-form"
                @mouseover="setInfoTooltip('propertyMoreDetailsTotalPropertyPart')"
              >
                <label class="label-title">
                  {{ $t('newProperty.propertyMoreDetails.total_property_part') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyMoreDetailsTotalPropertyPart'"
                    :content="$t('newProperty.infoTooltip.moreDetails.totalPropertyPart.content')"
                    :url="$t('newProperty.infoTooltip.moreDetails.totalPropertyPart.url')"
                    :isDisabled="!total_property_part_activated"
                    :number-of-lines-of-label="2"
                  />
                </label>
                <input
                  type="number"
                  @keypress="onlyInteger"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly || !total_property_part_activated"
                  v-model="form.total_property_part"
                  placeholder=""
                  class="input-field hover-tooltip"
                  ref="total_property_part"
                  @keyup.esc="cancel"
                  @keyup.enter="callForNextStep"
                  @focusin="setInfoTooltip('propertyMoreDetailsTotalPropertyPart')"
                />
                <span
                  class="error"
                  v-if="form.errors().has('total_property_part')"
                >
                  {{ form.errors().get('total_property_part') }}
                </span>
              </div>
            </div>

            <!-- Flächenangaben des Grundstücks -->
            <div
              class="col-md-7"
              v-show="area_of_the_land_value_1_activated === true"
            >
              <h2
                class="subheading_title"
                @mouseover="setInfoTooltip(0)"
              >
                {{ $t('newProperty.propertyMoreDetails.area_of_the_land_title') }}
              </h2>

              <!-- Fläche des Grundstücks in m² -->
              <div
                class="data-form"
                @mouseover="setInfoTooltip('propertyMoreDetailsAreaOfTheLand1')"
              >
                <label class="label-title">
                  {{ $t('newProperty.propertyMoreDetails.area_of_the_land_1') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyMoreDetailsAreaOfTheLand1'"
                    :content="$t('newProperty.infoTooltip.moreDetails.areaOfTheLand.content')"
                    :url="$t('newProperty.infoTooltip.moreDetails.areaOfTheLand.url')"
                    :isDisabled="!area_of_the_land_value_1_activated"
                  />
                </label>
                <input
                  type="number"
                  @keypress="onlyNumber"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly || !area_of_the_land_value_1_activated"
                  v-model="form.area_of_the_land_1"
                  placeholder=""
                  class="input-field hover-tooltip"
                  ref="area_of_the_land_1"
                  @keyup.esc="cancel"
                  @keyup.enter="callForNextStep"
                  @focusin="setInfoTooltip('propertyMoreDetailsAreaOfTheLand1')"
                />
                <span
                  class="error"
                  v-if="form.errors().has('area_of_the_land_1')"
                >
                  {{ form.errors().get('area_of_the_land_1') }}
                </span>
              </div>

              <!-- Bodenrichtwert pro m² -->
              <div
                class="data-form"
                @mouseover="setInfoTooltip('propertyMoreDetailsAreaOfTheLandValue1')"
              >
                <label class="label-title">
                  {{ $t('newProperty.propertyMoreDetails.area_of_the_land_value_1') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyMoreDetailsAreaOfTheLandValue1'"
                    :content="$t('newProperty.infoTooltip.moreDetails.areaOfTheLandValue.content')"
                    :url="$t('newProperty.infoTooltip.moreDetails.areaOfTheLandValue.url')"
                    :isDisabled="!area_of_the_land_value_1_activated"
                  />
                </label>
                <input
                  type="text"
                  @keypress="onlyNumberInputText"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly || !area_of_the_land_value_1_activated"
                  v-model="form.area_of_the_land_value_1"
                  placeholder=""
                  class="input-field hover-tooltip"
                  ref="area_of_the_land_value_1"
                  @keyup.esc="cancel"
                  @keyup.enter="callForNextStep"
                  @focusin="setInfoTooltip('propertyMoreDetailsAreaOfTheLandValue1')"
                />
                <span
                  class="error"
                  v-if="form.errors().has('area_of_the_land_value_1')"
                >
                  {{ form.errors().get('area_of_the_land_value_1') }}
                </span>
              </div>

              <!-- Befindet sich dieses Grundstück im Außenbereich? -->
              <div
                v-show="located_in_outdoor_area_activated"
                class="col-md-7"
              >
                <div
                  class="data-form"
                  @mouseover="setInfoTooltip('propertyMoreDetailsLocatedInOutdoorArea1')"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyMoreDetails.located_in_outdoor_area') }}
                    <InfoTooltip
                      v-if="
                        infoTooltip === 'propertyMoreDetailsLocatedInOutdoorArea1' &&
                        !area_of_the_land_value_1_activated
                      "
                      :isDisabled="!area_of_the_land_value_1_activated"
                    />
                  </label>
                  <div class="gdr_plots">
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly || !area_of_the_land_value_1_activated"
                        class="radio-button hover-tooltip"
                        :value="1"
                        name="located_in_outdoor_area_1_toogle"
                        v-model="form.located_in_outdoor_area_1"
                        @focusin="setInfoTooltip('propertyMoreDetailsLocatedInOutdoorArea1')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.yes') }}</span>
                    </div>
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly || !area_of_the_land_value_1_activated"
                        class="radio-button hover-tooltip"
                        :value="0"
                        name="located_in_outdoor_area_1_toogle"
                        v-model="form.located_in_outdoor_area_1"
                        @focusin="setInfoTooltip('propertyMoreDetailsLocatedInOutdoorArea1')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.no') }}</span>
                    </div>
                  </div>
                  <span
                    class="error"
                    v-if="form.errors().has('located_in_outdoor_area_1')"
                  >
                    {{ form.errors().get('located_in_outdoor_area_1') }}
                  </span>
                </div>
              </div>
            </div>

            <!-- Weitere Fläche hinzufügen -->
            <div
              class="col-md-7"
              v-show="secondAreaInfoShow === false && area_of_the_land_value_1_activated === true"
            >
              <div class="add-field">
                <button
                  class="add-field-button"
                  @click="addArea"
                >
                  <!-- <img src="@/assets/newproperty/plus_green.svg" /> -->
                  <Svgall
                    :name="'add_more'"
                    :color="primaryColor"
                  />
                  <span>{{ $t('newProperty.propertyMoreDetails.add_area') }}</span>
                </button>
              </div>
              <div><br /><br /></div>
            </div>

            <!-- Second area row -->
            <div
              class="col-md-7"
              v-show="secondAreaInfoShow === true && area_of_the_land_value_1_activated === true"
            >
              <!-- Fläche des Grundstücks in m² -->
              <div
                class="data-form"
                @mouseover="setInfoTooltip('propertyMoreDetailsAreaOfTheLand2')"
              >
                <label class="label-title">
                  {{ $t('newProperty.propertyMoreDetails.area_of_the_land_2') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyMoreDetailsAreaOfTheLand2'"
                    :content="$t('newProperty.infoTooltip.moreDetails.areaOfTheLand.content')"
                    :url="$t('newProperty.infoTooltip.moreDetails.areaOfTheLand.url')"
                    :isDisabled="!area_of_the_land_value_1_activated"
                  />
                </label>
                <input
                  type="text"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly || !area_of_the_land_value_1_activated"
                  v-model="form.area_of_the_land_2"
                  placeholder=""
                  class="input-field hover-tooltip"
                  ref="area_of_the_land_2"
                  @keyup.esc="cancel"
                  @keyup.enter="callForNextStep"
                  @focusin="setInfoTooltip('propertyMoreDetailsAreaOfTheLand2')"
                />
                <span
                  class="error"
                  v-if="form.errors().has('area_of_the_land_2')"
                >
                  {{ form.errors().get('area_of_the_land_2') }}
                </span>
              </div>

              <!-- Bodenrichtwert pro m² -->
              <div
                class="data-form"
                @mouseover="setInfoTooltip('propertyMoreDetailsAreaOfTheLandValue2')"
              >
                <label class="label-title">
                  {{ $t('newProperty.propertyMoreDetails.area_of_the_land_value_2') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyMoreDetailsAreaOfTheLandValue2'"
                    :content="$t('newProperty.infoTooltip.moreDetails.areaOfTheLandValue.content')"
                    :url="$t('newProperty.infoTooltip.moreDetails.areaOfTheLandValue.url')"
                    :isDisabled="!area_of_the_land_value_1_activated"
                  />
                </label>
                <input
                  type="text"
                  @keypress="onlyNumberInputText"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly || !area_of_the_land_value_1_activated"
                  v-model="form.area_of_the_land_value_2"
                  placeholder=""
                  class="input-field hover-tooltip"
                  ref="area_of_the_land_value_2"
                  @keyup.esc="cancel"
                  @keyup.enter="callForNextStep"
                  @focusin="setInfoTooltip('propertyMoreDetailsAreaOfTheLandValue2')"
                />
                <span
                  class="error"
                  v-if="form.errors().has('area_of_the_land_value_2')"
                >
                  {{ form.errors().get('area_of_the_land_value_2') }}
                </span>
              </div>

              <!-- Befindet sich dieses Grundstück im Außenbereich? -->
              <div
                v-show="located_in_outdoor_area_activated"
                class="col-md-7"
              >
                <div
                  class="data-form"
                  @mouseover="setInfoTooltip('propertyMoreDetailsLocatedInOutdoorArea2')"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyMoreDetails.located_in_outdoor_area') }}
                    <InfoTooltip
                      v-if="
                        infoTooltip === 'propertyMoreDetailsLocatedInOutdoorArea2' &&
                        !area_of_the_land_value_1_activated
                      "
                      :isDisabled="!area_of_the_land_value_1_activated"
                    />
                  </label>
                  <div class="gdr_plots">
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly || !area_of_the_land_value_1_activated"
                        class="radio-button hover-tooltip"
                        :value="1"
                        name="located_in_outdoor_area_2_toogle"
                        v-model="form.located_in_outdoor_area_2"
                        @focusin="setInfoTooltip('propertyMoreDetailsLocatedInOutdoorArea2')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.yes') }}</span>
                    </div>
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly || !area_of_the_land_value_1_activated"
                        class="radio-button hover-tooltip"
                        :value="0"
                        name="located_in_outdoor_area_2_toogle"
                        v-model="form.located_in_outdoor_area_2"
                        @focusin="setInfoTooltip('propertyMoreDetailsLocatedInOutdoorArea2')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.no') }}</span>
                    </div>
                  </div>
                  <span
                    class="error"
                    v-if="form.errors().has('located_in_outdoor_area_2')"
                  >
                    {{ form.errors().get('located_in_outdoor_area_2') }}
                  </span>
                </div>
              </div>
            </div>

            <!-- Remove second area row and reset 2nd area data -->
            <div
              class="col-md-7"
              v-show="secondAreaInfoShow === true && area_of_the_land_value_1_activated === true"
            >
              <div class="add-field reverse-border deleteIcon">
                <button
                  class="add-field-button"
                  @click="removeArea"
                >
                  <!-- <img src="@/assets/delete-green.svg" /> -->
                  <Svgall
                    :name="'delete'"
                    :color="primaryColor"
                  />
                  <span></span>
                </button>
              </div>
            </div>

            <!-- Auf diesem Grund und Boden befindet sich eine selbstständig nutzbare Teilfläche -->
            <div
              v-show="sub_area_section_activated"
              class="col-md-7"
            >
              <div
                class="data-form mb_35"
                @mouseover="setInfoTooltip(0)"
              >
                <span class="checkbox_input">
                  <input
                    class="form-check-input hover-tooltip"
                    type="checkbox"
                    v-model="form.showSubAreaSection"
                    :disabled="isPropertyReadOnly"
                    @change="showSubAreaSectionChanged"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span class="checkbox_title">{{ $t('newProperty.propertyMoreDetails.sub_area.title') }}</span>
                </span>
              </div>
            </div>

            <div v-show="form.showSubAreaSection">
              <!-- InfoText -->
              <div class="col-md-7">
                <div class="data-form">
                  <InfoText
                    :text="$t('newProperty.propertyMoreDetails.sub_area.info')"
                    :htmlMode="true"
                  />
                </div>
              </div>

              <!-- Fläche der gesamten Teilfläche in m² -->
              <div class="col-md-7">
                <div
                  class="data-form"
                  @mouseover="setInfoTooltip('propertyMoreDetailsAreaOfTotalSubarea')"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyMoreDetails.sub_area.area_of_total_subarea') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyMoreDetailsAreaOfTotalSubarea'"
                      :content="$t('newProperty.infoTooltip.moreDetails.areaOfTotalSubarea.content')"
                      :url="$t('newProperty.infoTooltip.moreDetails.areaOfTotalSubarea.url')"
                    />
                  </label>
                  <input
                    type="text"
                    @keypress="onlyNumberInputText"
                    autocomplete="off"
                    :disabled="isPropertyReadOnly"
                    v-model="form.subArea.areaOfTotalSubarea"
                    placeholder=""
                    class="input-field hover-tooltip"
                    ref="areaOfTotalSubarea"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyMoreDetailsAreaOfTotalSubarea')"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('area_of_total_subarea')"
                  >
                    {{ form.errors().get('area_of_total_subarea') }}
                  </span>
                </div>
              </div>

              <!-- Bodenrichtwert der Teilfläche -->
              <div class="col-md-7">
                <div
                  class="data-form"
                  @mouseover="setInfoTooltip('propertyMoreDetailsLandValueOfSubarea')"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyMoreDetails.sub_area.land_value_of_subarea') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyMoreDetailsLandValueOfSubarea'"
                      :content="$t('newProperty.infoTooltip.moreDetails.landValueOfSubarea.content')"
                      :url="$t('newProperty.infoTooltip.moreDetails.landValueOfSubarea.url')"
                    />
                  </label>
                  <input
                    type="text"
                    @keypress="onlyNumberInputText"
                    autocomplete="off"
                    :disabled="isPropertyReadOnly"
                    v-model="form.subArea.landValueOfSubarea"
                    placeholder=""
                    class="input-field hover-tooltip"
                    ref="areaOfTotalSubarea"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyMoreDetailsLandValueOfSubarea')"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('land_value_of_subarea')"
                  >
                    {{ form.errors().get('land_value_of_subarea') }}
                  </span>
                </div>
              </div>

              <!-- Enthalten in welcher Grundstücksfläche -->
              <div class="col-md-7">
                <div
                  class="data-form"
                  @mouseover="setInfoTooltip('propertyMoreDetailsIncludedInWhichArea')"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyMoreDetails.sub_area.included_in_which_area') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyMoreDetailsIncludedInWhichArea'"
                      :content="$t('newProperty.infoTooltip.moreDetails.includedInWhichArea.content')"
                      :url="$t('newProperty.infoTooltip.moreDetails.includedInWhichArea.url')"
                    />
                  </label>
                  <select
                    :disabled="isPropertyReadOnly"
                    v-model.number="form.subArea.includedInWhichArea"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip('propertyMoreDetailsIncludedInWhichArea')"
                  >
                    <option
                      v-for="option in included_in_which_area_options"
                      v-bind:key="option.value"
                      :value="option.value"
                    >
                      {{
                        $t('newProperty.propertyMoreDetails.sub_area.included_in_which_area_options.' + option.value)
                      }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Ist die Teilfläche bebaubar? -->
              <div class="col-md-7">
                <div
                  class="data-form"
                  @mouseover="setInfoTooltip('propertyMoreDetailsSubAreaBuildable')"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyMoreDetails.sub_area.buildable') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyMoreDetailsSubAreaBuildable'"
                      :content="$t('newProperty.infoTooltip.moreDetails.subAreaBuildable.content')"
                      :url="$t('newProperty.infoTooltip.moreDetails.subAreaBuildable.url')"
                    />
                  </label>
                  <div class="gdr_plots">
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly"
                        class="radio-button hover-tooltip"
                        :value="1"
                        name="subarea_buildable_toggle"
                        v-model="form.subArea.buildable"
                        @focusin="setInfoTooltip('propertyMoreDetailsSubAreaBuildable')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.yes') }}</span>
                    </div>
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly"
                        class="radio-button hover-tooltip"
                        :value="0"
                        name="subarea_buildable_toggle"
                        v-model="form.subArea.buildable"
                        @focusin="setInfoTooltip('propertyMoreDetailsSubAreaBuildable')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.no') }}</span>
                    </div>
                  </div>
                  <span
                    class="error"
                    v-if="form.errors().has('buildable')"
                  >
                    {{ form.errors().get('buildable') }}
                  </span>
                </div>
              </div>

              <!-- Ist der angegebene BRW für Bauflächen? -->
              <div class="col-md-7">
                <div
                  class="data-form"
                  @mouseover="setInfoTooltip('propertyMoreDetailsLandValueForBuildingLand')"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyMoreDetails.sub_area.land_value_for_building_land') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyMoreDetailsLandValueForBuildingLand'"
                      :content="$t('newProperty.infoTooltip.moreDetails.landValueForBuildingLand.content')"
                      :url="$t('newProperty.infoTooltip.moreDetails.landValueForBuildingLand.url')"
                    />
                  </label>
                  <div class="gdr_plots">
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly"
                        class="radio-button hover-tooltip"
                        :value="1"
                        name="subarea_land_value_for_building_land_toggle"
                        v-model="form.subArea.landValueForBuildingLand"
                        @focusin="setInfoTooltip('propertyMoreDetailsLandValueForBuildingLand')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.yes') }}</span>
                    </div>
                    <div class="gdr_plots_inner">
                      <input
                        type="radio"
                        :disabled="isPropertyReadOnly"
                        class="radio-button hover-tooltip"
                        :value="0"
                        name="subarea_land_value_for_building_land_toggle"
                        v-model="form.subArea.landValueForBuildingLand"
                        @focusin="setInfoTooltip('propertyMoreDetailsLandValueForBuildingLand')"
                      />
                      <span class="grd_radio01 grd_radio">{{ $t('newProperty.no') }}</span>
                    </div>
                  </div>
                  <span
                    class="error"
                    v-if="form.errors().has('land_value_for_building_land')"
                  >
                    {{ form.errors().get('land_value_for_building_land') }}
                  </span>
                </div>
              </div>
              <div><br /><br /></div>
            </div>

            <!-- Gemarkung(en) und Flurstück(e) -->
            <div v-show="parcels_activated">
              <div
                class="col-md-10"
                @mouseover="setInfoTooltip(0)"
              >
                <hr
                  class="subheading_devide"
                  v-show="!area_of_the_land_value_1_activated"
                />
                <div class="row relative">
                  <div class="subheading_title parcel-heading">
                    <h2>
                      {{ $t('newProperty.propertyMoreDetails.parcels.headline') }}
                    </h2>
                  </div>
                  <Paginator
                    class="parcel-pagination-top"
                    :totalRecords="form.parcels.length"
                    :rows="1"
                    :alwaysShow="false"
                    :first="currentParcelIndex"
                    template="FirstPageLink PrevPageLink NextPageLink LastPageLink"
                    :currentPageReportTemplate="
                      $t('general.grid.page_info', { currentPage: '{currentPage}', totalPages: '{totalPages}' })
                    "
                    @page="parcelPageChange"
                    @focusin="setInfoTooltip(0)"
                  />
                </div>
                <hr class="subheading_devide" />
              </div>

              <!-- InfoText for rule_G_1360 -->
              <div
                class="col-md-7"
                v-show="rule_G_1360_helpInfo !== ''"
                @mouseover="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title"> </label>
                  <InfoText :text="rule_G_1360_helpInfo"> </InfoText>
                </div>
              </div>

              <div ref="parcel_block">
                <!-- Gemarkung 1 -->
                <div
                  class="col-md-7"
                  @mouseover="setInfoTooltip(0)"
                >
                  <div
                    class="subheading_title input_button"
                    v-if="currentParcelIndex === 0"
                  >
                    <h5>
                      {{ $t('newProperty.propertyMoreDetails.parcels.itemheadline') }} {{ currentParcelIndex + 1 }}
                    </h5>
                    <button
                      v-show="toggleDataAcquisitionClientPortal === true"
                      v-on:click="showProvisioningModalDialog"
                      :style="[primaryColor ? { background: primaryColor } : '']"
                      class="step-button-style data_provisioning"
                      type="button"
                    >
                      {{ $t('newProperty.propertyMoreDetails.parcels.data_provisioning') }}
                    </button>
                  </div>

                  <div
                    class="subheading_title input_button deleteIcon"
                    v-else
                  >
                    <h5>
                      {{ $t('newProperty.propertyMoreDetails.parcels.itemheadline') }} {{ currentParcelIndex + 1 }}
                    </h5>
                    <Svgall
                      @click="removeParcel(currentParcelIndex)"
                      :name="'delete'"
                      :color="primaryColor"
                    />
                  </div>
                </div>

                <!-- Gemeinde -->
                <div class="col-md-7">
                  <div
                    class="data-form"
                    @mouseover="setInfoTooltip('propertyMoreDetailsCommunity_' + currentParcelIndex)"
                  >
                    <label class="label-title">
                      {{ $t('newProperty.propertyMoreDetails.parcels.community') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyMoreDetailsCommunity_' + currentParcelIndex"
                        :content="$t('newProperty.infoTooltip.moreDetails.community.content')"
                        :url="$t('newProperty.infoTooltip.moreDetails.community.url')"
                      />
                    </label>
                    <AutoComplete
                      v-model="form.parcels[currentParcelIndex].community"
                      :multiple="false"
                      :suggestions="parcelsHelper[currentParcelIndex].filteredCommunityNames"
                      :auto-highlight="true"
                      :disabled="isPropertyReadOnly"
                      inputClass="input-field"
                      class="hover-tooltip"
                      :minLength="1"
                      @item-select="onItemSelectCommunity(currentParcelIndex)"
                      @complete="searchCommunity($event, currentParcelIndex)"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyMoreDetailsCommunity_' + currentParcelIndex)"
                    />
                  </div>
                </div>

                <!-- Gemarkung -->
                <div class="col-md-7">
                  <div
                    class="data-form"
                    @mouseover="setInfoTooltip('propertyMoreDetailsParcel_' + currentParcelIndex)"
                  >
                    <label class="label-title">
                      {{ $t('newProperty.propertyMoreDetails.parcels.parcel') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyMoreDetailsParcel_' + currentParcelIndex"
                        :content="$t('newProperty.infoTooltip.moreDetails.parcel.content')"
                        :url="$t('newProperty.infoTooltip.moreDetails.parcel.url')"
                        :imagePath="require('@/assets/img/infotooltip/property-more-details-parcel.png')"
                      />
                    </label>
                    <AutoComplete
                      v-model="form.parcels[currentParcelIndex].parcel"
                      :multiple="false"
                      :suggestions="suggestionsGemarkungenName(currentParcelIndex)"
                      :auto-highlight="true"
                      :disabled="isPropertyReadOnly"
                      inputClass="input-field"
                      class="hover-tooltip"
                      :completeOnFocus="true"
                      @complete="searchGemarkungenName($event, currentParcelIndex)"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyMoreDetailsParcel_' + currentParcelIndex)"
                    >
                      <template #item="slotProps">
                        <div>
                          {{ slotProps.item }} ({{
                            findGemarkungNumberForGemarkungName(slotProps.item, currentParcelIndex)
                          }})
                        </div>
                      </template>
                    </AutoComplete>
                  </div>
                </div>

                <!-- Grundbuchblatt -->
                <div class="col-md-7">
                  <div
                    class="data-form"
                    @mouseover="setInfoTooltip('propertyMoreDetailsLandRegisterSheet_' + currentParcelIndex)"
                  >
                    <label class="label-title">
                      {{ $t('newProperty.propertyMoreDetails.parcels.land_register_sheet') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyMoreDetailsLandRegisterSheet_' + currentParcelIndex"
                        :content="$t('newProperty.infoTooltip.moreDetails.landRegisterSheet.content')"
                        :url="$t('newProperty.infoTooltip.moreDetails.landRegisterSheet.url')"
                        :imagePath="require('@/assets/img/infotooltip/property-more-details-lrs.png')"
                        :isDisabled="!land_register_sheet_activated"
                      />
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      :disabled="isPropertyReadOnly || !land_register_sheet_activated"
                      v-model="form.parcels[currentParcelIndex].land_register_sheet"
                      placeholder=""
                      class="input-field hover-tooltip"
                      ref="parcels_land_register_sheet_{{currentParcelIndex}}"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyMoreDetailsLandRegisterSheet_' + currentParcelIndex)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('parcels_' + currentParcelIndex + '_land_register_sheet')"
                    >
                      {{ form.errors().get('parcels_' + currentParcelIndex + '_land_register_sheet') }}
                    </span>
                  </div>
                </div>

                <!-- Flur -->
                <div class="col-md-7">
                  <div
                    class="data-form"
                    @mouseover="setInfoTooltip('propertyMoreDetailsCorridor_' + currentParcelIndex)"
                  >
                    <label class="label-title">
                      {{ $t('newProperty.propertyMoreDetails.parcels.corridor') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyMoreDetailsCorridor_' + currentParcelIndex"
                        :content="$t('newProperty.infoTooltip.moreDetails.corridor.content')"
                        :url="$t('newProperty.infoTooltip.moreDetails.corridor.url')"
                        :imagePath="require('@/assets/img/infotooltip/property-more-details-corridor.png')"
                        :isDisabled="!corridor_activated"
                      />
                    </label>
                    <input
                      type="number"
                      @keypress="onlyNumber"
                      autocomplete="off"
                      :disabled="isPropertyReadOnly || !corridor_activated"
                      v-model="form.parcels[currentParcelIndex].corridor"
                      placeholder=""
                      class="input-field hover-tooltip"
                      ref="parcels_corridor_{{currentParcelIndex}}"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyMoreDetailsCorridor_' + currentParcelIndex)"
                    />
                  </div>
                </div>

                <!-- Fläche in m² -->
                <div class="col-md-7">
                  <div
                    class="data-form"
                    @mouseover="setInfoTooltip('propertyMoreDetailsAreaOfTheLand_' + currentParcelIndex)"
                  >
                    <label class="label-title">
                      {{ $t('newProperty.propertyMoreDetails.parcels.area_of_the_land') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyMoreDetailsAreaOfTheLand_' + currentParcelIndex"
                        :content="$t('newProperty.infoTooltip.moreDetails.parcelAreaOfTheLand.content')"
                        :url="$t('newProperty.infoTooltip.moreDetails.parcelAreaOfTheLand.url')"
                        :imagePath="require('@/assets/img/infotooltip/property-more-details-aotl.png')"
                      />
                    </label>
                    <input
                      type="number"
                      @keypress="onlyNumber"
                      autocomplete="off"
                      :disabled="isPropertyReadOnly"
                      v-model="form.parcels[currentParcelIndex].area_of_the_land"
                      placeholder=""
                      class="input-field hover-tooltip"
                      ref="parcels_area_of_the_land_{{currentParcelIndex}}"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyMoreDetailsAreaOfTheLand_' + currentParcelIndex)"
                    />
                  </div>
                </div>

                <!-- Flurstück: Zähler -->
                <div class="col-md-7">
                  <div
                    class="data-form"
                    @mouseover="setInfoTooltip('propertyMoreDetailsParcelDataCounter_' + currentParcelIndex)"
                  >
                    <label class="label-title">
                      {{ $t('newProperty.propertyMoreDetails.parcels.parcel_data_counter') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyMoreDetailsParcelDataCounter_' + currentParcelIndex"
                        :content="$t('newProperty.infoTooltip.moreDetails.parcelDataCounter.content')"
                        :url="$t('newProperty.infoTooltip.moreDetails.parcelDataCounter.url')"
                        :imagePath="require('@/assets/img/infotooltip/property-more-details-counter.png')"
                        maxHeight="630px"
                      />
                    </label>
                    <input
                      type="number"
                      @keypress="onlyNumber"
                      autocomplete="off"
                      :disabled="isPropertyReadOnly"
                      v-model="form.parcels[currentParcelIndex].parcel_data_counter"
                      placeholder=""
                      class="input-field hover-tooltip"
                      ref="parcels_parcel_data_counter_{{currentParcelIndex}}"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyMoreDetailsParcelDataCounter_' + currentParcelIndex)"
                    />
                  </div>
                </div>

                <!-- Flurstück: Nenner -->
                <div class="col-md-7">
                  <div
                    class="data-form"
                    @mouseover="setInfoTooltip('propertyMoreDetailsParcelDataDenominator_' + currentParcelIndex)"
                  >
                    <label class="label-title">
                      {{ $t('newProperty.propertyMoreDetails.parcels.parcel_data_denominator') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyMoreDetailsParcelDataDenominator_' + currentParcelIndex"
                        :content="$t('newProperty.infoTooltip.moreDetails.parcelDataDenominator.content')"
                        :url="$t('newProperty.infoTooltip.moreDetails.parcelDataDenominator.url')"
                        :imagePath="require('@/assets/img/infotooltip/property-more-details-counter.png')"
                        maxHeight="550px"
                      />
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      :disabled="isPropertyReadOnly"
                      v-model="form.parcels[currentParcelIndex].parcel_data_denominator"
                      placeholder=""
                      class="input-field hover-tooltip"
                      ref="parcels_parcel_data_denominator_{{currentParcelIndex}}"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyMoreDetailsParcelDataDenominator_' + currentParcelIndex)"
                    />
                  </div>
                </div>

                <!-- Zur wirtschaftlichen Einheit gehörender Anteil: Zähler -->
                <div class="col-md-7">
                  <div
                    class="data-form"
                    @mouseover="
                      setInfoTooltip('propertyMoreDetailsParcelShareOfOwnershipCounter_' + currentParcelIndex)
                    "
                  >
                    <label class="label-title">
                      {{ $t('newProperty.propertyMoreDetails.parcels.share_of_ownership_counter') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyMoreDetailsParcelShareOfOwnershipCounter_' + currentParcelIndex"
                        :content="$t('newProperty.infoTooltip.moreDetails.shareOfOwnershipCounter.content')"
                        :url="$t('newProperty.infoTooltip.moreDetails.shareOfOwnershipCounter.url')"
                        :imagePath="require('@/assets/img/infotooltip/property-more-details-soo.png')"
                        maxHeight="470px"
                      />
                    </label>
                    <input
                      type="number"
                      @keypress="onlyNumber"
                      autocomplete="off"
                      :disabled="isPropertyReadOnly"
                      v-model="form.parcels[currentParcelIndex].share_of_ownership_counter"
                      placeholder=""
                      class="input-field hover-tooltip"
                      ref="parcels_share_of_ownership_counter_{{currentParcelIndex}}"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="
                        setInfoTooltip('propertyMoreDetailsParcelShareOfOwnershipCounter_' + currentParcelIndex)
                      "
                    />
                  </div>
                </div>

                <!-- Zur wirtschaftlichen Einheit gehörender Anteil: Nenner -->
                <div class="col-md-7">
                  <div
                    class="data-form"
                    @mouseover="
                      setInfoTooltip('propertyMoreDetailsParcelShareOfOwnershipDenominator_' + currentParcelIndex)
                    "
                  >
                    <label class="label-title">
                      {{ $t('newProperty.propertyMoreDetails.parcels.share_of_ownership_denominator') }}
                      <InfoTooltip
                        v-if="
                          infoTooltip === 'propertyMoreDetailsParcelShareOfOwnershipDenominator_' + currentParcelIndex
                        "
                        :content="$t('newProperty.infoTooltip.moreDetails.shareOfOwnershipDenominator.content')"
                        :url="$t('newProperty.infoTooltip.moreDetails.shareOfOwnershipDenominator.url')"
                        :imagePath="require('@/assets/img/infotooltip/property-more-details-soo.png')"
                        maxHeight="385px"
                      />
                    </label>
                    <input
                      type="number"
                      @keypress="onlyNumber"
                      autocomplete="off"
                      :disabled="isPropertyReadOnly"
                      v-model="form.parcels[currentParcelIndex].share_of_ownership_denominator"
                      placeholder=""
                      class="input-field hover-tooltip"
                      ref="parcels_share_of_ownership_denominator_{{currentParcelIndex}}"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="
                        setInfoTooltip('propertyMoreDetailsParcelShareOfOwnershipDenominator_' + currentParcelIndex)
                      "
                    />
                  </div>
                </div>

                <!-- Enthalten in welcher Fläche (welcher Bodenrichtwert zutreffend)? -->
                <div class="col-md-7">
                  <div
                    class="data-form"
                    @mouseover="setInfoTooltip('propertyMoreDetailsParcelContainedInArea_' + currentParcelIndex)"
                  >
                    <label class="label-title">
                      {{ $t('newProperty.propertyMoreDetails.parcels.contained_in_area') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyMoreDetailsParcelContainedInArea_' + currentParcelIndex"
                        :content="$t('newProperty.infoTooltip.moreDetails.containedInArea.content')"
                        :url="$t('newProperty.infoTooltip.moreDetails.containedInArea.url')"
                        maxHeight="290px"
                      />
                    </label>
                    <select
                      :disabled="isPropertyReadOnly"
                      v-model.number="form.parcels[currentParcelIndex].contained_in_area"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip('propertyMoreDetailsParcelContainedInArea_' + currentParcelIndex)"
                    >
                      <option
                        v-for="option in contained_in_areas"
                        v-bind:key="option.value"
                        :value="option.value"
                      >
                        {{ $t('contained_in_area_option.' + option.value) }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- Weitere hinzufügen -->
              <div
                class="col-md-7"
                @mouseover="setInfoTooltip(0)"
              >
                <div class="add-field">
                  <Paginator
                    class="parcel-pagination"
                    :totalRecords="form.parcels.length"
                    :rows="1"
                    :alwaysShow="false"
                    :first="currentParcelIndex"
                    template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    :currentPageReportTemplate="
                      $t('general.grid.page_info', { currentPage: '{currentPage}', totalPages: '{totalPages}' })
                    "
                    @page="parcelPageChange"
                    @focusin="setInfoTooltip(0)"
                  />
                  <button
                    class="add-field-button"
                    v-if="!isPropertyReadOnly"
                    @click="addParcel"
                    @focusin="setInfoTooltip(0)"
                  >
                    <Svgall
                      :name="'add_more'"
                      :color="primaryColor"
                    />
                    <span>
                      {{ $t('newProperty.propertyMoreDetails.parcels.add_parcel') }}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="text_left step-button-section"
          @mouseover="setInfoTooltip(0)"
        >
          <div>
            <button
              type="button"
              @click.prevent="cancel"
              class="step-button-style border-button-style"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.abort') }}
            </button>
            <button
              type="button"
              @click.prevent="save"
              class="step-button-style border-button-style"
              v-show="!isPropertyReadOnly"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.save') }}
            </button>
            <button
              type="button"
              v-show="back_button_activated"
              @click.prevent="callForPrecedingStep"
              class="step-button-style border-button-style"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.back') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              class="step-button-style"
              :style="[primaryColor ? { background: primaryColor } : '']"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              class="step-button-enter"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue_enter') }}
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </section>
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import i18n from '@/i18n'
import InfoTooltip from '@/components/helper/InfoTooltip'
import ShowHideTooltip from '@/mixins/ShowHideTooltip'
import DateIconFirefoxFix from '@/mixins/DateIconFirefoxFix'
import InfoText from '@/components/helper/InfoText.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AutoComplete from 'primevue/autocomplete'
import InfoTextHeading from '@/components/helper/InfoTextHeading'
import Paginator from 'primevue/paginator'
const { t } = i18n.global
const { defaultPropertyMoreDetails } = require('@//store/defaults/properties/DefaultPropertyMoreDetails')

export default {
  name: 'PropertyMoreDetails',
  components: { InfoText, InfoTextHeading, InfoTooltip, AutoComplete, Paginator },
  mixins: [ShowHideTooltip, DateIconFirefoxFix],
  emits: [
    'callForNextStep',
    'callForPrecedingStep',
    'callForSaveProperty',
    'cancelPropVisible',
    'showProvisioningModalDialog',
  ],
  data() {
    return {
      primaryColor: '',
      fontColor: '',
      communityNames: [],
      parcelsHelper: [],
      currentParcelIndex: 0,
      form: form({
        ownership_structure: '', //Eigentumsverhältnisse
        property_type: '', //Art des Grundstücks
        development_state: '', //Bei unbebauten Grundstücken ggf. abweichender Entwicklungszustand
        multi_communities: 0, //Erstreckt sich ... über mehrere ... Gemeinden?
        leasehold: 0, //Ist auf dem Grundstück ein Erbbaurecht bestellt?
        building_on_foreign_ground: 0, //Gebäude auf fremdem Grund und Boden?
        property_residential_purposes: '0', //Ist das Grundstück bebaut und wird überwiegend zu Wohnzwecken genutzt?
        buildings_on_third_party_owners: 0, //Ihr(e) Gebäude wurde(n) auf fremden Grund und Boden errichtet?
        lifting_rate: '', //Hebesatz (numerisch)
        registration_land_registry: '', //Antrag auf Neueintragung wurde beim Grundbuchamt eingereicht am (Datum)
        total_property_part: '', //Zusätzliche Angabe bei mehr als 10.000... , Fläche des gesamten zur wirtsch. Einheit geh- Anteils... (numerisch)
        area_of_the_land_1: '', //Fläche des Grundstücks in m²
        area_of_the_land_value_1: '', //Bodenrichtwert pro m²
        located_in_outdoor_area_1: 0, //Befindet sich dieses Grundstück im Außenbereich?
        area_of_the_land_2: '', //Fläche des 2. Grundstücks in m²
        area_of_the_land_value_2: '', //Bodenrichtwert des 2. Grundstücks in m²
        located_in_outdoor_area_2: 0, //Befindet sich dieses Grundstück im Außenbereich?
        parcels: [
          {
            community: '',
            parcel: '',
            land_register_sheet: '',
            corridor: '',
            area_of_the_land: '',
            parcel_data_counter: '',
            parcel_data_denominator: '',
            share_of_ownership_counter: '',
            share_of_ownership_denominator: '',
            contained_in_area: '0',
          },
        ],
        showSubAreaSection: false,
        subArea: {
          id: 0, //internal data, no form edit
          areaOfTotalSubarea: '', //Fläche der gesamten Teilfläche in m²
          landValueOfSubarea: '', //Bodenrichtwert der Teilfläche
          includedInWhichArea: '0', //Enthalten in welcher Grundstücksfläche
          buildable: '0', //Ist die Teilfläche bebaubar?
          landValueForBuildingLand: '0', //Ist der angegebene BRW für Bauflächen?
        },
      }), //rules and message in methode prepareForValidate
      secondAreaInfoShow: false,
      ownership_structures: [
        { value: 0 },
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
      ],
      property_types: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
      ], //deprecated
      taxModels: [
        {
          model: 'Bundesmodell',
          stateCodes: ['3', '4', '5', '8', '10', '11', '12', '13', '15', '16'],
        },
        {
          model: 'Modifiziertes Bodenwertmodell',
          stateCodes: ['1'],
        },
        {
          model: 'Flächen-Modell',
          stateCodes: ['2'],
        },
        {
          model: 'Wohnlagen-Modell',
          stateCodes: ['6'],
        },
        {
          model: 'Flächen-Faktor-Modell',
          stateCodes: ['7'],
        },
        {
          model: 'Flächen-Lage-Modell',
          stateCodes: ['9'],
        },
        {
          model: 'Modifiziertes Bodenwertmodell',
          stateCodes: ['14'],
        },
      ],
      development_states: [{ value: 1 }, { value: 2 }],
      contained_in_areas: [{ value: '1' }, { value: '2' }, { value: '3' }],
      included_in_which_area_options: [{ value: '0' }, { value: '1' }, { value: '2' }, { value: '3' }],
      parcelToDelete: [],
      property_type_activated: true,
      development_state_activated: true,
      total_property_part_activated: true,
      property_residential_purposes_activated: true,
      area_of_the_land_activated: true,
      area_of_the_land_value_1_activated: true,
      leasehold_activated: true,
      building_on_foreign_ground_activated: true,
      buildings_on_third_party_owners_activated: true,
      corridor_activated: true,
      registration_land_registry_activated: true,
      land_register_sheet_activated: true,
      parcels_activated: true,
      sub_area_section_activated: true,
      located_in_outdoor_area_activated: true,
      toggleDataAcquisitionClientPortal: false,
      back_button_activated: false,
    }
  },
  created() {
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#43ac6f'
    this.initParcelsHelper()
  },
  mounted() {
    this.initForm()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : ''
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.fontColor : ''

    // check if data Acquisition for Client Portal active
    if (
      this.getCurrentUser.dataAcquisition !== undefined &&
      this.getCurrentUser.dataAcquisition.dataAcquisitionClientPortal !== undefined
    ) {
      this.toggleDataAcquisitionClientPortal = Boolean(this.getCurrentUser.dataAcquisition.dataAcquisitionClientPortal)
    } else {
      this.toggleDataAcquisitionClientPortal = false
    }

    this.showHide()
    this.invokeReference('lifting_rate')
  },
  beforeUnmount() {
    this.saveForm()
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getFormPropertyMoreDetails',
      'isPropertyReadOnly',
      'getFormPropertyAddress',
      'getArrCommunities',
      'getArrGemarkungenCollection',
      'infoTooltip',
    ]),

    isNewProperty() {
      return this.$parent.isNewProperty
    },

    rule_G_1360_helpInfo() {
      let myValue = ''
      if (typeof this.$store.state.Properties.formPropertyAddress.federal_state_uid !== 'undefined') {
        switch (this.$store.state.Properties.formPropertyAddress.federal_state_uid.toString()) {
          case '2':
            myValue = t('newProperty.propertyMoreDetails.help_info.bayern')
            break
          case '6':
            myValue = t('newProperty.propertyMoreDetails.help_info.hamburg')
            break
          case '9':
            myValue = t('newProperty.propertyMoreDetails.help_info.niedersachsen')
            break
        }
      }
      return myValue
    },

    property_type_options() {
      const rule_5_federal_state_uids = ['1', '3', '4', '5', '8', '10', '11', '12', '13', '14', '15', '16']
      let myPropertyTypeOptions = []
      let resultArray = []
      let myFederalStateUid = ''
      let myEconomicEntityType = ''
      let myTaxModelObj = []

      if (typeof this.$store.state.Properties.formPropertyAddress.federal_state_uid !== 'undefined') {
        myFederalStateUid = this.$store.state.Properties.formPropertyAddress.federal_state_uid.toString()
      }

      // Get State Tax Model
      myTaxModelObj = this.taxModels.filter((item) => {
        if (item.stateCodes.indexOf(myFederalStateUid) != -1) {
          return item
        }
      })

      myPropertyTypeOptions = this.$tm('property_type_options.Bundesmodell')
      if (typeof myTaxModelObj[0] !== 'undefined' && typeof myTaxModelObj[0].model !== 'undefined') {
        if (myTaxModelObj[0].model !== '') {
          myPropertyTypeOptions = this.$tm('property_type_options.' + myTaxModelObj[0].model)
        }
      }

      if (typeof this.$store.state.Properties.formPropertyDetails.economic_entity_type !== 'undefined') {
        myEconomicEntityType = this.$store.state.Properties.formPropertyDetails.economic_entity_type.toString()
      }

      for (let k of myPropertyTypeOptions) {
        //rule_G_1532.5 => filter out option 1 "unbebautes Grundstück"
        if (
          !(
            rule_5_federal_state_uids.includes(myFederalStateUid) &&
            myEconomicEntityType === '2' &&
            this.$rt(k.code) === '1'
          )
        ) {
          resultArray.push({
            code: this.$rt(k.code),
            name: this.$rt(k.name),
          })
        }
      }

      return resultArray
    },

    isShowNextStepButton() {
      let isShow = true
      if (this.$parent.step === this.$parent.maxStep && this.isPropertyReadOnly) {
        isShow = false
      }
      return isShow
    },
  },
  watch: {
    getArrCommunities() {
      this.updateCommunityNames()
      this.initParcelsHelper()
    },

    getArrGemarkungenCollection: {
      handler() {
        this.updateGemarkungen()
      },
      deep: true,
    },

    getFormPropertyMoreDetails() {
      this.initForm()
    },

    buildings_on_third_party_owners_activated(newValue) {
      if (newValue === false) {
        this.form.buildings_on_third_party_owners = 0
      }
    },

    property_residential_purposes_activated(newValue) {
      if (newValue === false) {
        this.form.property_residential_purposes = '0'
      }
    },

    area_of_the_land_value_1_activated(newValue) {
      if (newValue === false) {
        this.form.area_of_the_land_1 = ''
        this.form.area_of_the_land_value_1 = ''
        this.form.located_in_outdoor_area_1 = 0
        this.form.area_of_the_land_2 = ''
        this.form.area_of_the_land_value_2 = ''
        this.form.located_in_outdoor_area_2 = 0
      }
    },

    located_in_outdoor_area_activated(newValue) {
      if (newValue === false) {
        this.form.located_in_outdoor_area_1 = 0
        this.form.located_in_outdoor_area_2 = 0
      }
    },

    sub_area_section_activated(newValue) {
      if (newValue === false) {
        this.form.showSubAreaSection = false
      }
    },

    'form.ownership_structure'() {
      //rule_G_1620
      this.$parent.showHideTabs()
    },

    'form.building_on_foreign_ground'() {
      //rule_G_1624
      this.$parent.showHideTabs()
    },

    'form.buildings_on_third_party_owners'() {
      //rule_G_2615
      this.$parent.showHideTabs()
    },

    'form.leasehold'() {
      //rule_G_1624
      this.$parent.showHideTabs()
    },

    //rule_G_1619: empty field if set to disabled
    registration_land_registry_activated(newValue) {
      if (newValue === false) {
        this.form.registration_land_registry = ''
      }
    },
    'form.property_type'() {
      this.$parent.showHideTabs()
    },
  },
  methods: {
    ...mapMutations(['setFormPropertyMoreDetails']),
    ...mapActions([
      'resetFormPropertyMoreDetails',
      'deletePropertyItems',
      'apiGetGemarkungenCollection',
      'changeInfoTooltip',
    ]),
    ...mapGetters(['getObjPropertyDetails']),

    setInfoTooltip(val) {
      this.changeInfoTooltip(val)
    },

    selectedFederalStateName() {
      let federalStateName = ''

      if (
        typeof this.$parent !== 'undefined' &&
        typeof this.$parent.$refs !== 'undefined' &&
        typeof this.$parent.$refs.childPropertyAddress !== 'undefined' &&
        typeof this.$parent.$refs.childPropertyAddress.form !== 'undefined' &&
        typeof this.$parent.$refs.childPropertyAddress.form.federal_state_uid !== 'undefined'
      ) {
        let federalStateUid = this.$parent.$refs.childPropertyAddress.form.federal_state_uid.toString().trim()
        if (federalStateUid !== '' && federalStateUid !== '0') {
          federalStateName = this.$t('federal_states.' + federalStateUid)
        }
      }

      return federalStateName
    },

    patternMaxNonDecimalPlacesForValue(blnUsingThousandsSeparator) {
      let result = ''
      let patternGeneral = '[0-9]{1,6}'
      let patternBW = '[0-9]{1,9}'
      let patternSeparatorGeneral = '[0-9]{1,3}(\\' + t('locale.separator.thousands') + '[0-9]{3}){0,1}'
      let patternSeparatorBW = '[0-9]{1,3}(\\' + t('locale.separator.thousands') + '[0-9]{3}){0,2}'
      if (blnUsingThousandsSeparator === false) {
        result = patternGeneral
      } else {
        result = patternSeparatorGeneral
      }
      if (this.selectedFederalStateName === t('federal_states.1')) {
        if (blnUsingThousandsSeparator === false) {
          result = patternBW
        } else {
          result = patternSeparatorBW
        }
      }
      return result
    },

    patternMaxTotalLength(blnUsingThousandsSeparator) {
      let result = 0
      let maxLengthGeneral = 9
      let maxLengthBW = 12
      let maxLengthSeparatorGeneral = 10
      let maxLengthSeparatorBW = 14
      if (blnUsingThousandsSeparator === false) {
        result = maxLengthGeneral
      } else {
        result = maxLengthSeparatorGeneral
      }
      if (this.selectedFederalStateName() === t('federal_states.1')) {
        if (blnUsingThousandsSeparator === false) {
          result = maxLengthBW
        } else {
          result = maxLengthSeparatorBW
        }
      }
      return result
    },

    suggestionsGemarkungenName(parcelsIndex) {
      let myResult = []

      if (
        typeof parcelsIndex === 'number' &&
        parcelsIndex >= 0 &&
        Array.isArray(this.form.parcels) &&
        parcelsIndex < this.form.parcels.length &&
        Array.isArray(this.parcelsHelper) &&
        parcelsIndex < this.parcelsHelper.length
      ) {
        myResult = this.parcelsHelper[parcelsIndex].gemarkungenFilteredByName
      }

      return myResult
    },

    searchGemarkungenName(event, parcelsIndex) {
      if (!event.query.trim().length) {
        this.parcelsHelper[parcelsIndex].gemarkungenFilteredByName = this.parcelsHelper[parcelsIndex].gemarkungen.map(
          function (objGemarkung) {
            return objGemarkung.name
          },
        )
      } else {
        this.parcelsHelper[parcelsIndex].gemarkungenFilteredByName = this.parcelsHelper[parcelsIndex].gemarkungen
          .filter(function (objGemarkung) {
            return objGemarkung.name.toLowerCase().includes(event.query.toLowerCase())
          })
          .map(function (objGemarkung) {
            return objGemarkung.name
          })
      }
    },

    findGemarkungNumberForGemarkungName(gemarkungName, parcelsIndex) {
      let gemarkungNumber = ''

      if (
        typeof parcelsIndex === 'number' &&
        parcelsIndex >= 0 &&
        Array.isArray(this.form.parcels) &&
        parcelsIndex < this.form.parcels.length &&
        Array.isArray(this.parcelsHelper) &&
        parcelsIndex < this.parcelsHelper.length
      ) {
        gemarkungName = gemarkungName.toString().trim()
        let objGemarkung = this.parcelsHelper[parcelsIndex].gemarkungen.find(function (objGemarkung) {
          return objGemarkung.name === gemarkungName
        }, this)
        if (typeof objGemarkung === 'object' && typeof objGemarkung.code !== 'undefined') {
          gemarkungNumber = objGemarkung.code.toString()
        }
        if (gemarkungNumber !== '') {
          let parsedGemarkungNumber = parseInt(gemarkungNumber)
          if (isNaN(parsedGemarkungNumber)) {
            gemarkungNumber = ''
          }
        }
      }
      return gemarkungNumber
    },

    communitySuggestions(parcelsIndex) {
      let myResult = []

      if (
        typeof parcelsIndex === 'number' &&
        parcelsIndex >= 0 &&
        Array.isArray(this.form.parcels) &&
        parcelsIndex < this.form.parcels.length &&
        Array.isArray(this.parcelsHelper) &&
        parcelsIndex < this.parcelsHelper.length
      ) {
        myResult = this.parcelsHelper[parcelsIndex].filteredCommunityNames
      }
      return myResult
    },

    searchCommunity(event, parcelsIndex) {
      if (!event.query.trim().length) {
        this.parcelsHelper[parcelsIndex].filteredCommunityNames = [...this.communityNames]
      } else {
        this.parcelsHelper[parcelsIndex].filteredCommunityNames = this.communityNames.filter((o) => {
          return o.toLowerCase().includes(event.query.toLowerCase())
        })
      }
    },

    updateCommunityNames() {
      this.communityNames = []
      this.getArrCommunities.forEach(function (objCommunity) {
        if (typeof objCommunity.name === 'string') {
          this.communityNames.push(objCommunity.name)
        }
      }, this)
    },

    updateGemarkungen() {
      if (Array.isArray(this.parcelsHelper) && this.parcelsHelper.length > 0) {
        this.parcelsHelper.forEach(function (objParcel) {
          if (
            typeof objParcel.federalStateName === 'string' &&
            typeof objParcel.communityName === 'string' &&
            Array.isArray(objParcel.gemarkungen) &&
            objParcel.gemarkungen.length === 0
          ) {
            let arrGemarkungen = this.gemarkungenForStateAndCommunity(
              objParcel.federalStateName,
              objParcel.communityName,
            )
            if (Array.isArray(arrGemarkungen) && arrGemarkungen.length > 0) {
              arrGemarkungen.forEach(function (objGemarkung) {
                if (typeof objGemarkung.name === 'string' && typeof objGemarkung.code === 'string') {
                  objParcel.gemarkungen.push(objGemarkung)
                }
              }, this)
            }
          }
        }, this)
      }
    },

    initParcelsHelper() {
      let myFederalStateName = this.selectedFederalStateName()
      this.parcelsHelper = []
      if (Array.isArray(this.form.parcels) && this.form.parcels.length > 0) {
        this.form.parcels.forEach(function (parcel) {
          let objHelper = {
            federalStateName: myFederalStateName,
            communityName: typeof parcel.community === 'string' ? parcel.community.trim() : '',
            filteredCommunityNames: [],
            gemarkungen: [],
            gemarkungenFilteredByName: [],
          }
          if (objHelper.federalStateName !== '' && objHelper.communityName !== '') {
            let arrGemarkungen = this.gemarkungenForStateAndCommunity(
              objHelper.federalStateName,
              objHelper.communityName,
            )
            if (Array.isArray(arrGemarkungen) && arrGemarkungen.length > 0) {
              arrGemarkungen.forEach(function (objGemarkung) {
                if (typeof objGemarkung.name === 'string' && typeof objGemarkung.code === 'string') {
                  objHelper.gemarkungen.push(objGemarkung)
                }
              }, this)
            }
          }
          this.parcelsHelper.push(objHelper)
        }, this)
      }
    },

    updateParcelsHelperOnSelect(parcelIndex) {
      if (
        typeof parcelIndex === 'number' &&
        Array.isArray(this.parcelsHelper) &&
        this.parcelsHelper.length > parcelIndex &&
        typeof this.parcelsHelper[parcelIndex] === 'object' &&
        Array.isArray(this.form.parcels) &&
        this.form.parcels.length > parcelIndex
      ) {
        this.parcelsHelper[parcelIndex].communityName = this.form.parcels[parcelIndex].community
        this.parcelsHelper[parcelIndex].gemarkungen = []
        if (
          typeof this.parcelsHelper[parcelIndex].federalStateName === 'string' &&
          this.parcelsHelper[parcelIndex].federalStateName !== '' &&
          typeof this.parcelsHelper[parcelIndex].communityName === 'string' &&
          this.parcelsHelper[parcelIndex].communityName !== ''
        ) {
          let arrGemarkungen = this.gemarkungenForStateAndCommunity(
            this.parcelsHelper[parcelIndex].federalStateName,
            this.parcelsHelper[parcelIndex].communityName,
          )
          if (Array.isArray(arrGemarkungen) && arrGemarkungen.length > 0) {
            arrGemarkungen.forEach(function (objGemarkung) {
              if (typeof objGemarkung.name === 'string' && typeof objGemarkung.code === 'string') {
                this.parcelsHelper[parcelIndex].gemarkungen.push(objGemarkung)
              }
            }, this)
          }
        }
      }
    },

    gemarkungenForStateAndCommunity(federalStateName, communityName) {
      let found = false
      let resultArray = []
      if (typeof this.getArrGemarkungenCollection === 'object' && this.getArrGemarkungenCollection.length > 0) {
        let foundIndex = this.getArrGemarkungenCollection.findIndex(function (objGemarkungen) {
          if (objGemarkungen.federalStateName === federalStateName && objGemarkungen.communityName === communityName) {
            return true
          }
        })
        if (foundIndex !== -1) {
          found = true
          let objGemarkungen = Object.values(this.getArrGemarkungenCollection[foundIndex].arrGemarkungen)
          objGemarkungen.forEach(function (objGemarkung) {
            resultArray.push(objGemarkung)
          }, this)
        }
      }
      if (!found) {
        this.apiGetGemarkungenCollection({
          federalState: federalStateName,
          community: communityName,
        })
      }
      return resultArray
    },

    onItemSelectCommunity(parcelIndex) {
      this.updateParcelsHelperOnSelect(parcelIndex)
    },

    initForm() {
      for (const [key, value] of Object.entries(this.$store.state.Properties.formPropertyMoreDetails)) {
        if (typeof value !== 'undefined') {
          this.form[key] = JSON.parse(JSON.stringify(value))
        }
      }
      this.initParcelsHelper()
      this.secondAreaInfoShow = !(
        (typeof this.form.area_of_the_land_2 === 'undefined' ||
          this.form.area_of_the_land_2 == '' ||
          this.form.area_of_the_land_2 === 0) &&
        (typeof this.form.area_of_the_land_value_2 === 'undefined' ||
          this.form.area_of_the_land_value_2 == '' ||
          this.form.area_of_the_land_value_2 === '0') &&
        (typeof this.form.located_in_outdoor_area_2 === 'undefined' ||
          this.form.located_in_outdoor_area_2.toString(10) === '0')
      )
    },

    saveForm() {
      let myKeys = []
      this.resetFormPropertyMoreDetails()
      myKeys = Object.keys(this.$store.state.Properties.formPropertyMoreDetails)
      if (typeof myKeys === 'object') {
        myKeys.forEach(function (key) {
          if (typeof this.form[key] !== 'undefined') {
            this.$store.state.Properties.formPropertyMoreDetails[key] = JSON.parse(JSON.stringify(this.form[key]))
          }
        }, this)
      }
    },

    cancel() {
      this.$emit('cancelPropVisible')
    },

    callForPrecedingStep() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }

        if (this.parcelToDelete.length > 0) {
          this.deletePropertyItems({
            property_uid: this.getObjPropertyDetails().id,
            item_key: 'parcel',
            item_uids: this.parcelToDelete,
          }).then((res) => {
            if (res) {
              this.saveForm()
              this.$emit('callForPrecedingStep')
            }
          })
        } else {
          this.saveForm()
          this.$emit('callForPrecedingStep')
        }
      } else {
        this.$emit('callForPrecedingStep')
      }
    },

    callForNextStep() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }

        if (this.parcelToDelete.length > 0) {
          this.deletePropertyItems({
            property_uid: this.getObjPropertyDetails().id,
            item_key: 'parcel',
            item_uids: this.parcelToDelete,
          }).then((res) => {
            if (res) {
              this.saveForm()
              this.$emit('callForNextStep')
            }
          })
        } else {
          this.saveForm()
          this.$emit('callForNextStep')
        }
      } else {
        this.$emit('callForNextStep')
      }
    },

    save() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }

        if (this.parcelToDelete.length > 0) {
          this.deletePropertyItems({
            property_uid: this.getObjPropertyDetails().id,
            item_key: 'parcel',
            item_uids: this.parcelToDelete,
          }).then((res) => {
            if (res) {
              this.saveForm()
              this.$emit('callForSaveProperty')
            }
          })
        } else {
          this.saveForm()
          this.$emit('callForSaveProperty')
        }
      }
    },

    invokeReference(ref) {
      this.$nextTick(() => {
        if (!this.$refs[ref]) return
        this.$refs[ref].focus()
      })
    },

    removeArea() {
      if (this.secondAreaInfoShow === true) {
        this.secondAreaInfoShow = false
        this.form.area_of_the_land_2 = defaultPropertyMoreDetails.area_of_the_land_2
        this.form.area_of_the_land_value_2 = defaultPropertyMoreDetails.area_of_the_land_value_2
        this.form.located_in_outdoor_area_2 = defaultPropertyMoreDetails.located_in_outdoor_area_2
      }
    },

    addArea() {
      this.secondAreaInfoShow = true
    },
    parcelPageChange(e) {
      if (typeof e.page !== 'undefined') {
        this.currentParcelIndex = parseInt(e.page)
        window.scroll(0, this.$refs.parcel_block.offsetTop)
      }
    },
    addParcel() {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyMoreDetails.parcels[0]))
      this.form.parcels.push(myObject)
      this.initParcelsHelper()
      this.currentParcelIndex++
      window.scroll(0, this.$refs.parcel_block.offsetTop)
    },

    removeParcel(index) {
      //decrease paginators index if last item will be deleted to avoid rendering crash
      if (this.currentParcelIndex === this.form.parcels.length - 1) {
        this.currentParcelIndex--
      }
      if (typeof index === 'number' && index > 0 && index < this.form.parcels.length) {
        if (this.form.parcels[index].id) {
          let parcelUid = parseInt(this.form.parcels[index].id)
          if (parcelUid > 0) {
            this.parcelToDelete.push(parcelUid)
          }
        }
        this.form.parcels.splice(index, 1)
        this.form.errors().forget()
        this.initParcelsHelper()
      }
    },

    showProvisioningModalDialog() {
      if (!this.isPropertyReadOnly) {
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }

        if (this.parcelToDelete.length > 0) {
          this.deletePropertyItems({
            property_uid: this.getObjPropertyDetails().id,
            item_key: 'parcel',
            item_uids: this.parcelToDelete,
          }).then((res) => {
            if (res) {
              this.saveForm()
              this.$emit('showProvisioningModalDialog')
            }
          })
        } else {
          this.saveForm()
          this.$emit('showProvisioningModalDialog')
        }
      }
    },

    onChangeOwnershipStructure() {
      if (this.form.ownership_structure.toString() !== '0') {
        if (this.$parent.$refs.childPropertyDetails.form.saveDeviatingOwner) {
          this.$parent.$refs.childPropertyDetails.form.saveDeviatingOwner = false
          if (typeof this.$store.state.Properties.formPropertyDetails.saveDeviatingOwner !== 'undefined') {
            this.$store.state.Properties.formPropertyDetails.saveDeviatingOwner = false
          }
        }
      }
    },
    onlyNumber($event) {
      let code = $event.code ? $event.code : $event.which
      let key = $event.key
      if (code === 'KeyE' || key === 'e' || key === 'E') {
        $event.preventDefault()
      }
    },

    onlyInteger($event) {
      let key = $event.key
      let charsAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      if (charsAllowed.indexOf(key) === -1) {
        $event.preventDefault()
      }
    },

    onlyNumberInputText($event) {
      let charsAllowed = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        t('locale.separator.thousands'),
        t('locale.separator.decimal'),
      ]
      let key = $event.key
      if (charsAllowed.indexOf(key) === -1) {
        $event.preventDefault()
      }
    },

    removeFormValidations() {
      Object.keys(this.form.all()).forEach((key) => {
        this.form.forget(key)
      })
      this.form.rules({})
    },

    prepareForValidate() {
      let myValidations = {}
      this.removeFormValidations()

      //static validation rules
      this.form.validatorInstance.addMessage(
        'area_of_the_land_1.regex',
        t('newProperty.propertyMoreDetails.messages.area_of_the_land_regex'),
      )
      this.form.validatorInstance.addRule('area_of_the_land_1', ['regex:^(?!0)[0-9]{0,9}$'])
      myValidations['area_of_the_land_1'] = this.form.area_of_the_land_1

      this.form.validatorInstance.addMessage(
        'area_of_the_land_2.regex',
        t('newProperty.propertyMoreDetails.messages.area_of_the_land_regex'),
      )
      this.form.validatorInstance.addRule('area_of_the_land_2', ['regex:^(?!0)[0-9]{0,9}$'])
      myValidations['area_of_the_land_2'] = this.form.area_of_the_land_2

      this.form.validatorInstance.addMessage(
        'total_property_part.integer',
        t('newProperty.propertyMoreDetails.messages.total_property_part_integer'),
      )
      this.form.validatorInstance.addRule('total_property_part', ['integer'])
      myValidations['total_property_part'] = this.form.total_property_part

      //static validation rules for land_register_sheet in parcels array
      this.form.parcels.forEach(function (parcel, parcelIndex) {
        //is only null when parcel was added over parcel provisioning import
        if (parcel.land_register_sheet !== null) {
          this.form.validatorInstance.addMessage(
            'parcels_' + parcelIndex + '_land_register_sheet.regex',
            t('newProperty.propertyMoreDetails.messages.land_register_sheet_regex'),
          )
          this.form.validatorInstance.addRule('parcels_' + parcelIndex + '_land_register_sheet', [
            'regex:(^$)|(^[0-9]{1,5}[A-Za-z0-9]{0,1}$)',
          ])
          myValidations['parcels_' + parcelIndex + '_land_register_sheet'] = parcel.land_register_sheet
        }
      }, this)

      //dynamic validation rules
      let isBW = this.selectedFederalStateName() === t('federal_states.1')
      let patternMaxTotalLengthWithThousandsSeparatorAndTwoDecimalPlaces = this.patternMaxTotalLength(true)
      let patternMaxTotalLengthWithoutThousandsSeparatorAndTwoDecimalPlaces = this.patternMaxTotalLength(false)

      let errorMsg = isBW
        ? t('newProperty.propertyMoreDetails.messages.area_of_the_land_value_bw_regex')
        : t('newProperty.propertyMoreDetails.messages.area_of_the_land_value_regex')
      let rule = [
        'regex:(^$)|(^0$)|' +
          //no thousands separator and 2 decimal places
          '(^(?=.{4,' +
          patternMaxTotalLengthWithoutThousandsSeparatorAndTwoDecimalPlaces.toString() +
          '}$)(?!0[0-9])' +
          this.patternMaxNonDecimalPlacesForValue(false) +
          '(\\' +
          t('locale.separator.decimal') +
          '[0-9]{2,2})$)' +
          '|' +
          //no thousands separator and 1 decimal place
          '(^(?=.{3,' +
          (patternMaxTotalLengthWithoutThousandsSeparatorAndTwoDecimalPlaces - 1).toString() +
          '}$)(?!0[0-9])' +
          this.patternMaxNonDecimalPlacesForValue(false) +
          '(\\' +
          t('locale.separator.decimal') +
          '[0-9]{1,1})$)' +
          '|' +
          //no thousands separator and no decimal places
          '(^(?=.{1,' +
          (patternMaxTotalLengthWithoutThousandsSeparatorAndTwoDecimalPlaces - 3).toString() +
          '}$)(?!0[0-9])' +
          this.patternMaxNonDecimalPlacesForValue(false) +
          '$)' +
          '|' +
          //with thousands separator and 2 decimal places
          '(^(?=.{4,' +
          patternMaxTotalLengthWithThousandsSeparatorAndTwoDecimalPlaces.toString() +
          '}$)(?!0[0-9])' +
          this.patternMaxNonDecimalPlacesForValue(true) +
          '\\' +
          t('locale.separator.thousands') +
          '[0-9]{3}(\\' +
          t('locale.separator.decimal') +
          '[0-9]{2,2})$)' +
          '|' +
          //with thousands separator and 1 decimal place
          '(^(?=.{3,' +
          (patternMaxTotalLengthWithThousandsSeparatorAndTwoDecimalPlaces - 1).toString() +
          '}$)(?!0[0-9])' +
          this.patternMaxNonDecimalPlacesForValue(true) +
          '\\' +
          t('locale.separator.thousands') +
          '[0-9]{3}(\\' +
          t('locale.separator.decimal') +
          '[0-9]{1,1})$)' +
          '|' +
          //with thousands separator and no decimal places
          '(^(?=.{1,' +
          patternMaxTotalLengthWithThousandsSeparatorAndTwoDecimalPlaces.toString() +
          '}$)(?!0[0-9])' +
          this.patternMaxNonDecimalPlacesForValue(true) +
          '$)',
      ]

      this.form.validatorInstance.addMessage('area_of_the_land_value_1.regex', errorMsg)
      this.form.validatorInstance.addRule('area_of_the_land_value_1', rule)
      myValidations['area_of_the_land_value_1'] = this.form.area_of_the_land_value_1

      this.form.validatorInstance.addMessage('area_of_the_land_value_2.regex', errorMsg)
      this.form.validatorInstance.addRule('area_of_the_land_value_2', rule)
      myValidations['area_of_the_land_value_2'] = this.form.area_of_the_land_value_2

      this.form.set(myValidations)
    },

    showSubAreaSectionChanged() {},
  },
}
</script>

<style type="text/css" scoped>
textarea.textarea-field {
  border: 1px solid #bbbbbb;
  height: 150px;
  width: 100%;
}

.Step_SectionPropertyMoreDetails .tooltip-icon img {
  height: 18px;
  margin-left: 5px;
}

label.label-title.tooltip-icon {
  position: relative;
}

.ToolTipTextBox {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: none;
  max-width: 600px;
  padding: 20px;
  padding: 15px !important;
  position: absolute;
  right: 0;
  top: 35px;
  transform: translate3d(0px, 40px, 0px) !important;
  z-index: 1;
}

.add-field .add-field-button {
  max-width: 100% !important;
}

.reverse-border {
  border-bottom: 1px solid #dfdfdf !important;
  border-top: 0px !important;
  margin-bottom: 40px;
  margin-top: 0px !important;
  padding-bottom: 25px;
  padding-top: 25px !important;
}

.data_provisioning {
  margin-left: auto;
  max-width: 250px !important;
}

.hidden {
  display: none;
  height: 0px;
  width: 0px;
}

/*.Step_SectionPropertyMoreDetails .tooltip-icon img:hover + .ToolTipTextBox {
    display: block;
}*/
.parcel-pagination {
  justify-content: flex-start;
  padding-left: 0 !important;
  width: 70%;
}
.parcel-pagination-top {
  justify-content: flex-end;
  padding-bottom: 0 !important;
  padding-right: 0px !important;
  padding-top: 0 !important;
  position: absolute;
  right: 5px;
  width: 39% !important;
}

.parcel-pagination .p-paginator-current,
.parcel-pagination-top .p-paginator-current {
  position: relative !important;
}
.parcel-heading {
  width: fit-content !important;
}
.relative {
  position: relative !important;
}

.mb_35 {
  margin-bottom: 35px !important;
}

@media only screen and (min-width: 100px) and (max-width: 767px) {
  .checkbox_input input,
  .document_img {
    display: block;
  }
}
</style>
