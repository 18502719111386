<template>
  <transition name="modal-fade">
    <div class="modal-backdrop __Modal_Popup">
      <div class="modal">
        <div class="Modal_content_Area">
          <header class="modal-header">
            <img
              src="@/assets/btn_close_gray.svg"
              class="btn-close"
              @click="close"
            />
          </header>

          <div class="modal-inside">
            <div class="popup_title b_btm">
              <h2>
                <img
                  src="@/assets/roometric/roometric_logo.png"
                  class="logo"
                />
                {{ $t('roometric.modalTitle') }}
              </h2>
            </div>
            <div class="flex-left-right">
              <div class="flex-left">
                <img
                  src="@/assets/roometric/roometric_screen.svg"
                  class="screen"
                />
              </div>
              <div class="flex-right">
                <div class="flex-right-upper-half">
                  <div class="textfileformating">
                    <div class="filemin_cont cancel-popup">
                      <div class="modal-text">
                        <p>{{ getText1() }}</p>
                        <p>{{ $t('roometric.modalText2') }}</p>
                        <p>{{ $t('roometric.modalText3') }}</p>
                        <p>{{ $t('roometric.modalText4') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-right-lower-half">
                  <div class="btn_170 btn_cancel w-100">
                    <button
                      class="button-no-highlight flex-button"
                      @click="close"
                    >
                      {{ $t('roometric.modalButtonClose') }}
                    </button>
                    <button
                      class="modal-button flex-button"
                      :style="[primaryColor ? { background: primaryColor } : '']"
                      @click="openRoometricWebsite"
                    >
                      {{ $t('roometric.modalButtonSubmit') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import { RoometricPrimaryColor, getRoometricUrl } from '@/helper/RoometricHelper'

export default {
  name: 'RoometricDialog',

  props: {
    isNonResidentialVariant: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  created() {
    this.primaryColor = RoometricPrimaryColor
  },

  computed: {
    ...mapGetters(['getCurrentUser']),
  },

  methods: {
    getText1() {
      return this.isNonResidentialVariant === true
        ? this.$t('roometric.modalText1_nonResidential')
        : this.$t('roometric.modalText1')
    },

    close() {
      this.$emit('closeRoometricDialog')
    },

    openRoometricWebsite() {
      this.close()
      window.open(getRoometricUrl(), '_blank')
    },
  },
}
</script>

<style scoped lang="scss">
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

.__Modal_Popup .modal {
  background: white;
  display: flex;
  flex-direction: row;
  height: auto; //281px;
  justify-content: center;
  left: 40%;
  margin: 0 auto;
  max-width: 600px !important;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  right: 40%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 100%;
}

.__Modal_Popup .modal-header {
  border: 0;
  border-radius: 5px;
  box-shadow: 0 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 1000;
}
.modal-backdrop .modal {
  border-top: 3px solid #229d56;
  padding-bottom: 25px;
}

.button-no-highlight {
  background: none;
  border: 1px solid #333333;
  border-radius: 2px;
  color: #333333;
  height: 40px;
  margin: 0 10px;
  max-width: 170px;
  width: 100%;
}

.button-no-highlight:hover {
  background: rgba(204, 204, 204, 1);
  border-color: rgba(204, 204, 204, 1);
}

.logo {
  box-sizing: border-box;
  height: 24px;
  line-height: normal;
  text-align: left;
  width: 24px;
}

.screen {
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  height: 334px;
  width: 165px;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}

.flex-left-right {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
}

.flex-left {
  display: flex;
}

.flex-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 15px;
}

.flex-right-upper-half {
  display: flex;
}

.flex-right-lower-half {
  display: flex;
  justify-content: flex-end;
}

.flex-button {
  align-items: center;
  display: flex;
  justify-content: center;
}

.w-100 {
  width: 100%;
}
</style>
