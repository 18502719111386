<template>
  <section class="Step_SectionPropertyNonResidential">
    <fieldset>
      <div class="Step_SectionPropertyNonResidential">
        <InfoTextHeading
          :headingText="$t('newProperty.propertyNonResidential.headline')"
          :infoText="$t('infoTextDisabledFields')"
        >
        </InfoTextHeading>

        <div class="form-group">
          <div class="row_setp_main">
            <div
              v-for="(nonResidential, nonResidentialIndex) in form.nonResidentials"
              :key="nonResidentialIndex"
            >
              <!-- Nichtwohngrundstück 1 -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="deleteIcon delete_">
                  <h5 class="subheading_title">
                    {{ $t('newProperty.propertyNonResidential.group1_title') }} {{ nonResidentialIndex + 1 }}
                  </h5>
                  <div class="flex-button-icon">
                    <button
                      v-if="nonResidentialIndex === 0 && isRoometricActive"
                      v-on:click.stop.prevent="openRoometricDialog"
                      :style="[primaryColor ? { background: primaryColor } : '']"
                      class="button-style-for-title hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    >
                      <img
                        class="max_20"
                        src="@/assets/roometric/metrics_service.svg"
                        alt=""
                      />
                      {{ $t('roometric.buttonLabel') }}
                    </button>
                    <Svgall
                      @click="removeNonResidential(nonResidentialIndex)"
                      :name="'delete'"
                      :color="primaryColor"
                      v-show="!isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    />
                  </div>
                </div>
                <hr class="subheading_devide" />
              </div>

              <!-- (Lageplan-)Nummer -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyNonResidentialLocationPlanNumber_' + nonResidentialIndex)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyNonResidential.location_plan_number') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyNonResidentialLocationPlanNumber_' + nonResidentialIndex"
                      :content="$t('newProperty.infoTooltip.nonResidential.locationPlanNumber.content')"
                    />
                  </label>
                  <input
                    type="number"
                    autocomplete="off"
                    v-model="form.nonResidentials[nonResidentialIndex].locationPlanNumber"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    :ref="calcIdAndRef('location_plan_number_', nonResidentialIndex)"
                    @keypress="onlyNumber"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyNonResidentialLocationPlanNumber_' + nonResidentialIndex)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('non_residentials_' + nonResidentialIndex + '_locationPlanNumber')"
                  >
                    {{ form.errors().get('non_residentials_' + nonResidentialIndex + '_locationPlanNumber') }}
                  </span>
                </div>
              </div>

              <!-- Gebäudeart -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyNonResidentialBuildingType_' + nonResidentialIndex)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyNonResidential.buildingType') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyNonResidentialBuildingType_' + nonResidentialIndex"
                      :content="$t('newProperty.infoTooltip.nonResidential.buildingType.content')"
                      :url="$t('newProperty.infoTooltip.nonResidential.buildingType.url')"
                    />
                  </label>
                  <select
                    v-model="form.nonResidentials[nonResidentialIndex].buildingType"
                    :disabled="isPropertyReadOnly"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip('propertyNonResidentialBuildingType_' + nonResidentialIndex)"
                  >
                    <option
                      v-for="option in building_type_options"
                      v-bind:key="option.code"
                      :value="option.code"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Baujahr -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyNonResidentialYearOfConstruction_' + nonResidentialIndex)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyNonResidential.year_of_construction') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyNonResidentialYearOfConstruction_' + nonResidentialIndex"
                      :content="$t('newProperty.infoTooltip.nonResidential.yearOfConstruction.content')"
                      :url="$t('newProperty.infoTooltip.nonResidential.yearOfConstruction.url')"
                    />
                  </label>
                  <input
                    type="number"
                    autocomplete="off"
                    v-model="form.nonResidentials[nonResidentialIndex].yearOfConstruction"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    :ref="calcIdAndRef('year_of_construction_', nonResidentialIndex)"
                    @keypress="onlyNumber"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyNonResidentialYearOfConstruction_' + nonResidentialIndex)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('non_residentials_' + nonResidentialIndex + '_yearOfConstruction')"
                  >
                    {{ form.errors().get('non_residentials_' + nonResidentialIndex + '_yearOfConstruction') }}
                  </span>
                </div>
              </div>

              <!-- Es erfolgte eine Kernsanierung, die abgeschlossen wurde im Jahr -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyNonResidentialCoreRenovationYear_' + nonResidentialIndex)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyNonResidential.core_renovation_year') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyNonResidentialCoreRenovationYear_' + nonResidentialIndex"
                      :content="$t('newProperty.infoTooltip.nonResidential.coreRenovationYear.content')"
                      :url="$t('newProperty.infoTooltip.nonResidential.coreRenovationYear.url')"
                    />
                  </label>
                  <input
                    type="number"
                    autocomplete="off"
                    v-model="form.nonResidentials[nonResidentialIndex].coreRenovationYear"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    :ref="calcIdAndRef('core_renovation_year_', nonResidentialIndex)"
                    @keypress="onlyNumber"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyNonResidentialCoreRenovationYear_' + nonResidentialIndex)"
                  />
                  <span class="help-text float-end">{{ $t('newProperty.infoTooltip.applicable_help_text') }}</span>
                  <span
                    class="error"
                    v-if="form.errors().has('non_residentials_' + nonResidentialIndex + '_coreRenovationYear')"
                  >
                    {{ form.errors().get('non_residentials_' + nonResidentialIndex + '_coreRenovationYear') }}
                  </span>
                </div>
              </div>

              <!-- Es besteht eine Abbruchverpflichtung im Jahr -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyNonResidentialDemolitionObligationYear_' + nonResidentialIndex)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyNonResidential.demolition_obligation_year') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyNonResidentialDemolitionObligationYear_' + nonResidentialIndex"
                      :content="$t('newProperty.infoTooltip.nonResidential.demolitionObligationYear.content')"
                      :url="$t('newProperty.infoTooltip.nonResidential.demolitionObligationYear.url')"
                    />
                  </label>
                  <input
                    type="number"
                    autocomplete="off"
                    v-model="form.nonResidentials[nonResidentialIndex].demolitionObligationYear"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    :ref="calcIdAndRef('demolition_obligation_year_', nonResidentialIndex)"
                    @keypress="onlyNumber"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyNonResidentialDemolitionObligationYear_' + nonResidentialIndex)"
                  />
                  <span class="help-text float-end">{{ $t('newProperty.infoTooltip.applicable_help_text') }}</span>
                  <span
                    class="error"
                    v-if="form.errors().has('non_residentials_' + nonResidentialIndex + '_demolitionObligationYear')"
                  >
                    {{ form.errors().get('non_residentials_' + nonResidentialIndex + '_demolitionObligationYear') }}
                  </span>
                </div>
              </div>

              <!-- Bruttogrundfläche in m² -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyNonResidential.areaOfTheLand') }}
                  </label>
                  <input
                    type="number"
                    autocomplete="off"
                    v-model="form.nonResidentials[nonResidentialIndex].areaOfTheLand"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keypress="onlyNumber"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('non_residentials_' + nonResidentialIndex + '_areaOfTheLand')"
                  >
                    {{ form.errors().get('non_residentials_' + nonResidentialIndex + '_areaOfTheLand') }}
                  </span>
                </div>
              </div>

              <!-- Davon für den Zivilschutz in m² -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyNonResidential.areaOfTheLandCivilDefense') }}
                  </label>
                  <input
                    type="number"
                    autocomplete="off"
                    v-model="form.nonResidentials[nonResidentialIndex].areaOfTheLandCivilDefense"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    @keypress="onlyNumber"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                  <div
                    class="info-text my-2"
                    v-show="civil_defense_info_text_activated"
                  >
                    {{ $t('newProperty.propertyNonResidential.civilDefenseInfo') }}
                  </div>
                  <div>
                    <span class="help-text-no-float help-text">{{
                      $t('newProperty.infoTooltip.applicable_help_text')
                    }}</span>
                    <span
                      class="error"
                      v-if="form.errors().has('non_residentials_' + nonResidentialIndex + '_areaOfTheLandCivilDefense')"
                    >
                      {{ form.errors().get('non_residentials_' + nonResidentialIndex + '_areaOfTheLandCivilDefense') }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- v-for begin exemption -->
              <div
                v-for="(exemption, exemptionIndex) in form.nonResidentials[nonResidentialIndex].exemptions"
                :key="exemptionIndex"
              >
                <!-- Befreiung 1 -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <br />
                  <hr class="subheading_devide" />
                  <div class="deleteIcon delete_">
                    <h5 class="subheading_title_inner tooltip-icon">
                      {{ $t('newProperty.propertyNonResidential.exemption') }} {{ exemptionIndex + 1 }} &nbsp;
                      <div class="tool_tip_box">
                        <Svgall
                          class="tool_tip_svg hover-tooltip"
                          data-bs-toggle="dropdown"
                          :data-target="calcIdAndRef('exemption_', nonResidentialIndex, exemptionIndex)"
                          :name="'tooltip_icon'"
                          :color="primaryColor"
                          @focusin="setInfoTooltip(0)"
                        />
                        <div
                          class="label-title dropdown-menu ToolTipTextBox"
                          :id="calcIdAndRef('exemption_', nonResidentialIndex, exemptionIndex)"
                        >
                          {{ $t('newProperty.propertyNonResidential.exemption_tooltip1') }}
                        </div>
                      </div>
                    </h5>
                    <Svgall
                      @click="removeExemption(nonResidentialIndex, exemptionIndex)"
                      :name="'delete'"
                      :color="primaryColor"
                      v-show="!isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    />
                  </div>
                </div>

                <!-- Lage der Räume / Bezeichnung -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyNonResidential.location_rooms_designation') }}
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      v-model="
                        form.nonResidentials[nonResidentialIndex].exemptions[exemptionIndex]
                          .locationOfTheRoomsDesignation
                      "
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="
                        form
                          .errors()
                          .has(
                            'non_residentials_' +
                              nonResidentialIndex +
                              '_' +
                              exemptionIndex +
                              '_exemption_locationOfTheRoomsDesignation',
                          )
                      "
                    >
                      {{
                        form
                          .errors()
                          .get(
                            'non_residentials_' +
                              nonResidentialIndex +
                              '_' +
                              exemptionIndex +
                              '_exemption_locationOfTheRoomsDesignation',
                          )
                      }}
                    </span>
                  </div>
                </div>

                <!-- Befreiung -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyNonResidential.exemption') }}
                    </label>
                    <select
                      v-model="form.nonResidentials[nonResidentialIndex].exemptions[exemptionIndex].exemption"
                      :disabled="isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    >
                      <option
                        v-for="option in exemption_options"
                        v-bind:key="option.code"
                        :value="option.code"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- Steuerfreie Fläche in m² -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyNonResidential.tax_free_area') }}
                    </label>
                    <input
                      type="number"
                      autocomplete="off"
                      v-model="form.nonResidentials[nonResidentialIndex].exemptions[exemptionIndex].taxFreeArea"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keypress="onlyNumber"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="
                        form
                          .errors()
                          .has(
                            'non_residentials_' + nonResidentialIndex + '_' + exemptionIndex + '_exemption_taxFreeArea',
                          )
                      "
                    >
                      {{
                        form
                          .errors()
                          .get(
                            'non_residentials_' + nonResidentialIndex + '_' + exemptionIndex + '_exemption_taxFreeArea',
                          )
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <!-- v-for end exemption -->

              <div
                class="col-md-7"
                v-show="exemption_activated"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="add-field no_space">
                  <button
                    class="add-field-button hover-tooltip"
                    :disabled="isPropertyReadOnly"
                    @click="addExemption(nonResidentialIndex)"
                    @focusin="setInfoTooltip(0)"
                  >
                    <Svgall
                      :name="'add_more'"
                      :color="primaryColor"
                    />
                    <span>{{ $t('newProperty.propertyNonResidential.add_more_exemption') }}</span>
                  </button>
                </div>
              </div>

              <!-- v-for begin benefit -->
              <div
                v-for="(benefit, benefitIndex) in form.nonResidentials[nonResidentialIndex].benefits"
                :key="benefitIndex"
              >
                <!-- Vergünstigung 1 -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <br />
                  <hr class="subheading_devide" />
                  <div class="deleteIcon delete_">
                    <h5 class="subheading_title_inner tooltip-icon">
                      {{ $t('newProperty.propertyNonResidential.benefit') }} {{ benefitIndex + 1 }} &nbsp;
                      <div class="tool_tip_box">
                        <Svgall
                          class="tool_tip_svg hover-tooltip"
                          data-bs-toggle="dropdown"
                          :data-target="calcIdAndRef('benefit_', nonResidentialIndex, benefitIndex)"
                          :name="'tooltip_icon'"
                          :color="primaryColor"
                          @focusin="setInfoTooltip(0)"
                        />
                        <div
                          class="label-title dropdown-menu ToolTipTextBox"
                          :id="calcIdAndRef('benefit_', nonResidentialIndex, benefitIndex)"
                        >
                          {{ $t('newProperty.propertyNonResidential.benefit_tooltip1') }}
                        </div>
                      </div>
                    </h5>
                    <Svgall
                      @click="removeBenefit(nonResidentialIndex, benefitIndex)"
                      :name="'delete'"
                      :color="primaryColor"
                      v-show="!isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    />
                  </div>
                </div>

                <!-- Lage der Räume / Bezeichnung -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyNonResidential.location_rooms_designation') }}
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      v-model="
                        form.nonResidentials[nonResidentialIndex].benefits[benefitIndex].locationOfTheRoomsDesignation
                      "
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="
                        form
                          .errors()
                          .has(
                            'non_residentials_' +
                              nonResidentialIndex +
                              '_' +
                              benefitIndex +
                              '_benefit_locationOfTheRoomsDesignation',
                          )
                      "
                    >
                      {{
                        form
                          .errors()
                          .get(
                            'non_residentials_' +
                              nonResidentialIndex +
                              '_' +
                              benefitIndex +
                              '_benefit_locationOfTheRoomsDesignation',
                          )
                      }}
                    </span>
                  </div>
                </div>

                <!-- Vergünstigung -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyNonResidential.benefit') }}
                    </label>
                    <select
                      v-model="form.nonResidentials[nonResidentialIndex].benefits[benefitIndex].benefit"
                      :disabled="isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    >
                      <option
                        v-for="option in benefit_options"
                        v-bind:key="option.code"
                        :value="option.code"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- Vergünstigte Fläche in m² -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyNonResidential.discounted_area') }}
                    </label>
                    <input
                      type="number"
                      autocomplete="off"
                      v-model="form.nonResidentials[nonResidentialIndex].benefits[benefitIndex].discountedArea"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keypress="onlyNumber"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="
                        form
                          .errors()
                          .has(
                            'non_residentials_' + nonResidentialIndex + '_' + benefitIndex + '_benefit_discountedArea',
                          )
                      "
                    >
                      {{
                        form
                          .errors()
                          .get(
                            'non_residentials_' + nonResidentialIndex + '_' + benefitIndex + '_benefit_discountedArea',
                          )
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <!-- v-for end benefit -->

              <div
                class="col-md-7"
                v-show="exemption_activated"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="add-field no_space">
                  <button
                    class="add-field-button hover-tooltip"
                    :disabled="isPropertyReadOnly"
                    @click="addBenefit(nonResidentialIndex)"
                    @focusin="setInfoTooltip(0)"
                  >
                    <Svgall
                      :name="'add_more'"
                      :color="primaryColor"
                    />
                    <span>{{ $t('newProperty.propertyNonResidential.add_more_benefit') }}</span>
                  </button>
                </div>
              </div>
            </div>
            <!-- v-for end nonResidentialIndex -->

            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="add-field __space">
                <button
                  class="add-field-button hover-tooltip"
                  :disabled="isPropertyReadOnly"
                  @click="addNonResidential"
                  @focusin="setInfoTooltip(0)"
                >
                  <Svgall
                    :name="'add_more'"
                    :color="primaryColor"
                  />
                  <span>{{ $t('newProperty.propertyNonResidential.add_more_non_residential') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="text_left step-button-section"
          @mouseenter="setInfoTooltip(0)"
        >
          <div>
            <button
              type="button"
              @click.prevent="cancel"
              class="step-button-style border-button-style hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.abort') }}
            </button>
            <button
              type="button"
              @click.prevent="save"
              class="step-button-style border-button-style hover-tooltip"
              v-show="!isPropertyReadOnly"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.save') }}
            </button>
            <button
              type="button"
              v-show="back_button_activated"
              @click.prevent="callForPrecedingStep"
              class="step-button-style border-button-style"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.back') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              class="step-button-style hover-tooltip"
              :style="[primaryColor ? { background: primaryColor } : '']"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              class="step-button-enter hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue_enter') }}
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </section>
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import i18n from '@/i18n'
const { t } = i18n.global
import InfoTooltip from '@/components/helper/InfoTooltip'
import ShowHideTooltip from '@/mixins/ShowHideTooltip'
import { mapGetters, mapActions } from 'vuex'
import { defaultPropertyNonResidential } from '@/store/defaults/properties/DefaultPropertyNonResidential'
import { evaluateBenefitOptionsNonResidential } from '@/helper/PropertyHelper'
import InfoTextHeading from '@/components/helper/InfoTextHeading'
import { isRoometricActive } from '@/helper/RoometricHelper'

export default {
  name: 'PropertyNonResidential',
  components: { InfoTextHeading, InfoTooltip },
  mixins: [ShowHideTooltip],
  emits: ['callForNextStep', 'callForPrecedingStep', 'callForSaveProperty', 'cancelPropVisible', 'showRoometricDialog'],
  data() {
    return {
      primaryColor: '',
      fontColor: '',
      form: form({
        nonResidentials: [
          {
            id: 0,
            locationPlanNumber: '', //(Lageplan-)Nummer
            buildingType: '', //Gebäudeart
            yearOfConstruction: '', //Baujahr
            coreRenovationYear: '', //Es erfolgte eine Kernsanierung, die abgeschlossen wurde im Jahr
            demolitionObligationYear: '', //Es besteht eine Abbruchverpflichtung im Jahr
            areaOfTheLand: '', //Bruttogrundfläche in m²
            areaOfTheLandCivilDefense: '', //Davon für den Zivilschutz in m²
            exemptions: [
              {
                //Befreiungen
                id: 0,
                locationOfTheRoomsDesignation: '', //Lage der Räume / Bezeichnung
                exemption: '0', //Befreiung
                taxFreeArea: '', //Steuerfreie Fläche in m²
              },
            ],
            benefits: [
              {
                //Vergünstigungen
                id: 0,
                locationOfTheRoomsDesignation: '', //Lage der Räume / Bezeichnung
                benefit: '0', //Vergünstigung
                discountedArea: '', //Vergünstigte Fläche in m²
              },
            ],
          },
        ],
      }), // set form rules and messages in method "prepareForValidate()"
      exemption_activated: true,
      back_button_activated: false,
      civil_defense_info_text_activated: true,
    }
  },
  mounted() {
    this.initForm()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : ''
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.primaryColor : ''
    this.addOneOfTwoRequiredRule()
    this.addPositiveRule()
    this.showHide()
    if (typeof this.form.nonResidentials === 'object' && this.form.nonResidentials.length > 0) {
      this.invokeReference(this.calcIdAndRef('location_plan_number_', 0))
    }
  },
  beforeUnmount() {
    this.saveForm()
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getFormPropertyNonResidential',
      'getFormPropertyAddress',
      'isPropertyReadOnly',
      'infoTooltip',
    ]),

    isNewProperty() {
      return this.$parent.isNewProperty
    },

    building_type_options() {
      let myRawOptions = this.$tm('newProperty.propertyNonResidential.building_type_options')
      let myOptions = []
      for (let k of myRawOptions) {
        myOptions.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }
      return myOptions
    },

    exemption_options() {
      let myRawOptions = this.$tm('newProperty.propertyNonResidential.exemption_options')
      let myOptions = []
      for (let k of myRawOptions) {
        myOptions.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }
      return myOptions
    },

    benefit_options() {
      let myOptions = []
      if (
        typeof this.getFormPropertyAddress === 'object' &&
        typeof this.getFormPropertyAddress.federal_state_uid !== 'undefined'
      ) {
        myOptions = evaluateBenefitOptionsNonResidential(this.getFormPropertyAddress.federal_state_uid)
      }
      return myOptions
    },

    isRoometricActive() {
      return isRoometricActive()
    },

    isShowNextStepButton() {
      let isShow = true
      if (this.$parent.step === this.$parent.maxStep && this.isPropertyReadOnly) {
        isShow = false
      }
      return isShow
    },
  },
  watch: {
    getFormPropertyNonResidential() {
      this.initForm()
    },

    exemption_activated(newValue) {
      if (newValue === false) {
        this.removeAllBenefits()
        this.removeAllExemptions()
      }
    },
  },
  methods: {
    ...mapActions(['resetFormPropertyNonResidential', 'changeInfoTooltip']),

    setInfoTooltip(val) {
      this.changeInfoTooltip(val)
    },

    calcIdAndRef(prefix, nonResidentialIndex, secondIndex) {
      let result = ''
      if (typeof prefix === 'string' && typeof nonResidentialIndex === 'number') {
        result = prefix + nonResidentialIndex.toString()
        if (typeof secondIndex === 'number') {
          result = result + '_' + secondIndex.toString()
        }
      }
      return result
    },

    initForm() {
      for (const [key, value] of Object.entries(this.$store.state.Properties.formPropertyNonResidential)) {
        if (typeof value !== 'undefined') {
          this.form[key] = JSON.parse(JSON.stringify(value))
        }
      }
    },

    resetForm() {
      this.form.nonResidentials = []
      this.initForm()
    },

    saveForm() {
      let myKeys = []
      this.resetFormPropertyNonResidential()
      myKeys = Object.keys(this.$store.state.Properties.formPropertyNonResidential)
      if (typeof myKeys === 'object') {
        myKeys.forEach(function (key) {
          if (typeof this.form[key] !== 'undefined') {
            this.$store.state.Properties.formPropertyNonResidential[key] = JSON.parse(JSON.stringify(this.form[key]))
          }
        }, this)
      }
    },

    cancel() {
      this.$emit('cancelPropVisible')
    },

    callForPrecedingStep() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForPrecedingStep')
      } else {
        this.$emit('callForPrecedingStep')
      }
    },

    callForNextStep() {
      if (this.isPropertyReadOnly) {
        this.$emit('callForNextStep')
      } else {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForNextStep')
      }
    },

    save() {
      if (this.isPropertyReadOnly) {
        return
      }

      this.prepareForValidate()
      if (this.form.validate().errors().any()) {
        this.$store.dispatch('showToast', {
          message: this.$t('newProperty.form_validation_text'),
          toastType: 'error',
        })
        return
      }
      this.saveForm()
      this.$emit('callForSaveProperty')
    },

    addFlatField(
      objFlatFields, // must be an object
      rawFieldName, // must be a string
      value, // must be a simple value, no objects
      residentialIndex, // must be a number
      secondIndex, // optional: either undefined or number
      maxRuleValue, // must be a number. 0 => no max rule will be set
      maxRuleMsg, // must be a string. "" => no max custom message will be set
      requiredValue, // must be true/false. false => no required rule will be set
      requiredMsg, // must be a string. "" => no required custom message will be set
      lessThanValue, // must be a number. 0 => no less_than rule will be set
      lessThanMsg, // must be a string. "" => no less_than custom message will be set
      oneOfTwoRequiredValue, // must be either a string or "undefined". undefined => no one_of_two_required rule will be set
      oneOfTwoRequiredMsg, //must be a string. "" => no one_of_two_required message will be set
      positiveValue, // must be true/false. false => no positive rule will be set
      positiveMsg, // must be a string. "" => no positive custom message will be set
    ) {
      const prefix = 'non_residentials_'
      let hasSecondIndex = typeof secondIndex === 'number'
      let flatFieldName = ''
      let fieldRules = []

      if (value === null) value = ''
      if (oneOfTwoRequiredValue === null) oneOfTwoRequiredValue = ''

      if (
        typeof objFlatFields === 'object' &&
        typeof rawFieldName === 'string' &&
        typeof value !== 'undefined' &&
        typeof value !== 'object' &&
        typeof residentialIndex === 'number' &&
        typeof maxRuleValue === 'number' &&
        typeof maxRuleMsg === 'string' &&
        typeof requiredValue === 'boolean' &&
        typeof requiredMsg === 'string' &&
        typeof lessThanValue === 'number' &&
        typeof lessThanMsg === 'string' &&
        typeof oneOfTwoRequiredMsg === 'string' &&
        typeof positiveValue === 'boolean' &&
        typeof positiveMsg === 'string'
      ) {
        //build flat field name
        flatFieldName = prefix + residentialIndex.toString() + '_'
        if (hasSecondIndex) {
          flatFieldName = flatFieldName + secondIndex.toString() + '_'
        }
        flatFieldName = flatFieldName + rawFieldName

        //add flat field to object
        objFlatFields[flatFieldName] = value

        //initialize field rules
        fieldRules = []

        //handle max rule
        if (maxRuleValue > 0) {
          if (maxRuleMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.max', maxRuleMsg)
          }
          fieldRules.push('max: ' + maxRuleValue.toString())
        }

        //handle required rule
        if (requiredValue) {
          if (requiredMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.required', requiredMsg)
          }
          fieldRules.push('required')
        }

        //handle less_than rule
        if (lessThanValue > 0) {
          if (lessThanMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.less_than', lessThanMsg)
          }
          fieldRules.push('less_than: ' + lessThanValue.toString())
        }

        //handle one_of_two_required rule
        if (typeof oneOfTwoRequiredValue !== 'undefined') {
          if (oneOfTwoRequiredMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.one_of_two_required', oneOfTwoRequiredMsg)
          }
          fieldRules.push('one_of_two_required: ' + oneOfTwoRequiredValue.toString())
        }

        //handle positive rule
        if (positiveValue) {
          if (positiveMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.positive', positiveMsg)
          }
          fieldRules.push('positive')
        }

        //add combined field rules
        this.form.validatorInstance.addRule(flatFieldName, fieldRules)
      }
    },

    addOneOfTwoRequiredRule() {
      this.form.validatorInstance.extend({
        one_of_two_required: [
          ':attribute is required',
          ({ value, parameters }) =>
            (value.toString().trim() !== '' && value.toString().trim() !== '0') ||
            (parameters[0].toString().trim() !== '' && parameters[0].toString().trim() !== '0'),
        ],
      })
    },

    addPositiveRule() {
      this.form.validatorInstance.extend({
        positive: [
          ':attribute must not be negative',
          function (value) {
            let result = true
            let myStringValue = value.value.toString().trim()
            let myFloatValue
            if (myStringValue !== '') {
              myFloatValue = parseFloat(myStringValue)
              if (!isNaN(myFloatValue)) {
                result = myFloatValue >= 0
              }
            }
            return result
          },
        ],
      })
    },

    prepareForValidate() {
      let myFlatFields = {}

      //remove existing flat fields first
      this.removeFlatFormFields()

      //add flat fields
      this.form.nonResidentials.forEach(function (nonResidential, nonResidentialIndex) {
        this.addFlatField(
          myFlatFields,
          'locationPlanNumber',
          nonResidential.locationPlanNumber,
          nonResidentialIndex,
          undefined,
          0,
          '',
          false,
          '',
          10000000,
          t('newProperty.propertyNonResidential.messages.locationPlanNumber_less_than'),
          undefined,
          '',
          false,
          '',
        )
        this.addFlatField(
          myFlatFields,
          'yearOfConstruction',
          nonResidential.yearOfConstruction,
          nonResidentialIndex,
          undefined,
          0,
          '',
          false,
          '',
          10000,
          t('newProperty.propertyNonResidential.messages.yearOfConstruction_less_than'),
          undefined,
          '',
          true,
          t('newProperty.propertyNonResidential.messages.yearOfConstruction_positive'),
        )
        this.addFlatField(
          myFlatFields,
          'coreRenovationYear',
          nonResidential.coreRenovationYear,
          nonResidentialIndex,
          undefined,
          0,
          '',
          false,
          '',
          10000,
          t('newProperty.propertyNonResidential.messages.coreRenovationYear_less_than'),
          undefined,
          '',
          true,
          t('newProperty.propertyNonResidential.messages.coreRenovationYear_positive'),
        )
        this.addFlatField(
          myFlatFields,
          'demolitionObligationYear',
          nonResidential.demolitionObligationYear,
          nonResidentialIndex,
          undefined,
          0,
          '',
          false,
          '',
          10000,
          t('newProperty.propertyNonResidential.messages.demolitionObligationYear_less_than'),
          undefined,
          '',
          true,
          t('newProperty.propertyNonResidential.messages.demolitionObligationYear_positive'),
        )
        this.addFlatField(
          myFlatFields,
          'areaOfTheLand',
          nonResidential.areaOfTheLand,
          nonResidentialIndex,
          undefined,
          0,
          '',
          false,
          '',
          1000000,
          t('newProperty.propertyNonResidential.messages.areaOfTheLand_less_than'),
          undefined,
          '',
          false,
          '',
        )
        this.addFlatField(
          myFlatFields,
          'areaOfTheLandCivilDefense',
          nonResidential.areaOfTheLandCivilDefense,
          nonResidentialIndex,
          undefined,
          0,
          '',
          false,
          '',
          1000000,
          t('newProperty.propertyNonResidential.messages.areaOfTheLandCivilDefense_less_than'),
          undefined,
          '',
          false,
          '',
        )

        nonResidential.exemptions.forEach(function (exemption, exemptionIndex) {
          this.addFlatField(
            myFlatFields,
            'exemption_locationOfTheRoomsDesignation',
            exemption.locationOfTheRoomsDesignation,
            nonResidentialIndex,
            exemptionIndex,
            25,
            t('newProperty.propertyNonResidential.messages.locationOfTheRoomsDesignation_max'),
            false,
            '',
            0,
            '',
            undefined,
            '',
            false,
            '',
          )
          this.addFlatField(
            myFlatFields,
            'exemption_taxFreeArea',
            exemption.taxFreeArea,
            nonResidentialIndex,
            exemptionIndex,
            0,
            '',
            false,
            '',
            1000000,
            t('newProperty.propertyNonResidential.messages.taxFreeArea_less_than'),
            undefined,
            '',
            false,
            '',
          )
        }, this)
        nonResidential.benefits.forEach(function (benefit, benefitIndex) {
          this.addFlatField(
            myFlatFields,
            'benefit_locationOfTheRoomsDesignation',
            benefit.locationOfTheRoomsDesignation,
            nonResidentialIndex,
            benefitIndex,
            25,
            t('newProperty.propertyNonResidential.messages.locationOfTheRoomsDesignation_max'),
            false,
            '',
            0,
            '',
            undefined,
            '',
            false,
            '',
          )
          this.addFlatField(
            myFlatFields,
            'benefit_discountedArea',
            benefit.discountedArea,
            nonResidentialIndex,
            benefitIndex,
            0,
            '',
            false,
            '',
            1000000,
            t('newProperty.propertyNonResidential.messages.discountedArea_less_than'),
            undefined,
            '',
            false,
            '',
          )
        }, this)
      }, this)

      this.form.set(myFlatFields)
    },

    removeFlatFormFields() {
      Object.keys(this.form.all()).forEach((key) => {
        if (key.startsWith('non_residentials_')) {
          this.form.forget(key)
        }
      })
      this.form.rules({})
    },

    invokeReference(ref) {
      this.$nextTick(() => {
        if (!this.$refs[ref]) return
        if (
          typeof this.$refs !== 'undefined' &&
          typeof this.$refs[ref] !== 'undefined' &&
          typeof this.$refs[ref].focus === 'function'
        ) {
          this.$refs[ref].focus()
        }
      })
    },

    removeNonResidential(index) {
      let newIndex
      if (typeof index === 'number' && index >= 0 && index < this.form.nonResidentials.length) {
        this.form.nonResidentials.splice(index, 1)

        newIndex = index
        if (newIndex > this.form.nonResidentials.length - 1) {
          newIndex--
        }
        if (newIndex >= 0) {
          this.invokeReference(this.calcIdAndRef('year_of_construction_', newIndex))
        }
      }
    },

    addNonResidential() {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyNonResidential.nonResidentials[0]))
      myObject.exemptions = []
      myObject.benefits = []
      this.form.nonResidentials.push(myObject)
      this.invokeReference(this.calcIdAndRef('location_plan_number_', this.form.nonResidentials.length - 1))
    },

    removeExemption(nonResidentialIndex, exemptionIndex) {
      if (
        typeof nonResidentialIndex === 'number' &&
        nonResidentialIndex >= 0 &&
        nonResidentialIndex < this.form.nonResidentials.length &&
        typeof exemptionIndex === 'number' &&
        exemptionIndex >= 0 &&
        exemptionIndex < this.form.nonResidentials[nonResidentialIndex].exemptions.length
      ) {
        this.form.nonResidentials[nonResidentialIndex].exemptions.splice(exemptionIndex, 1)
      }
    },

    addExemption(nonResidentialIndex) {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyNonResidential.nonResidentials[0].exemptions[0]))
      if (
        typeof nonResidentialIndex === 'number' &&
        nonResidentialIndex >= 0 &&
        nonResidentialIndex < this.form.nonResidentials.length
      ) {
        this.form.nonResidentials[nonResidentialIndex].exemptions.push(myObject)
      }
    },

    removeBenefit(nonResidentialIndex, benefitIndex) {
      if (
        typeof nonResidentialIndex === 'number' &&
        nonResidentialIndex >= 0 &&
        nonResidentialIndex < this.form.nonResidentials.length &&
        typeof benefitIndex === 'number' &&
        benefitIndex >= 0 &&
        benefitIndex < this.form.nonResidentials[nonResidentialIndex].benefits.length
      ) {
        this.form.nonResidentials[nonResidentialIndex].benefits.splice(benefitIndex, 1)
      }
    },

    addBenefit(nonResidentialIndex) {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyNonResidential.nonResidentials[0].benefits[0]))
      if (
        typeof nonResidentialIndex === 'number' &&
        nonResidentialIndex >= 0 &&
        nonResidentialIndex < this.form.nonResidentials.length
      ) {
        this.form.nonResidentials[nonResidentialIndex].benefits.push(myObject)
      }
    },

    removeAllExemptions() {
      this.form.nonResidentials.forEach(function (nonResidential) {
        nonResidential.exemptions = []
      }, this)
    },

    removeAllBenefits() {
      this.form.nonResidentials.forEach(function (nonResidential) {
        nonResidential.benefits = []
      }, this)
    },

    onlyNumber($event) {
      let code = $event.code ? $event.code : $event.which
      let key = $event.key
      if (code === 'KeyE' || key === 'e' || key === 'E') {
        $event.preventDefault()
      }
    },

    openRoometricDialog() {
      if (this.isRoometricActive) {
        this.$emit('showRoometricDialog')
      }
    },
  },
}
</script>

<style type="text/css">
textarea.textarea-field {
  border: 1px solid #bbbbbb;
  height: 150px;
  width: 100%;
}

.Step_SectionPropertyNonResidential .tooltip-icon img {
  height: 18px;
  margin-left: 5px;
}

label.label-title.tooltip-icon {
  position: relative;
}

.ToolTipTextBox {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: none;
  max-width: 600px;
  padding: 20px;
  padding: 15px !important;
  position: absolute;
  right: 0;
  top: 35px;
  transform: translate3d(0px, 40px, 0px) !important;
  z-index: 1;
}

h2.subheading_title.no_top_margin {
  margin-top: 0px;
}

h5.subheading_title_inner.tooltip-icon {
  display: flex;
  position: relative;
}

.dropdown-menu.ToolTipTextBox.show {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

/*.Step_SectionPropertyNonResidential .tooltip-icon img:hover + .ToolTipTextBox {
    display: block;
}*/

.flex-button-icon {
  align-items: center;
  display: flex;
}

.info-text {
  background-color: #f2f2f2;
  float: left;
  font-size: 12px;
  line-height: normal;
  padding: 8px;
  text-align: left;
}

.help-text-no-float {
  display: block;
  text-align: right;
}
</style>
