<template>
  <section class="Step_SectionPropertyAddress">
    <fieldset>
      <div class="Step_SectionPropertyAddress">
        <InfoTextHeading
          :headingText="$t('newProperty.propertyAddress.headline')"
          :infoText="$t('infoTextDisabledFields')"
        >
        </InfoTextHeading>

        <div class="form-group">
          <div class="row_setp_main">
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyAddressFederalState')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyAddress.state') }}<span class="nf_starcolor">*</span>
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyAddressFederalState'"
                    :content="$t('newProperty.infoTooltip.address.federalState.content')"
                    :url="$t('newProperty.infoTooltip.address.federalState.url')"
                  />
                </label>
                <select
                  ref="state"
                  :disabled="isPropertyReadOnly"
                  class="hover-tooltip"
                  @change="onChangeFederalState($event)"
                  v-model="form.federal_state_uid"
                  @focusin="setInfoTooltip('propertyAddressFederalState')"
                >
                  <option
                    value=""
                    disabled
                    selected
                  >
                    {{ $t('select.select_any') }}
                  </option>
                  <option
                    v-for="option in federal_states"
                    v-bind:key="option.value"
                    :value="option.value"
                  >
                    {{ $t('federal_states.' + option.value) }}
                  </option>
                </select>
                <span
                  class="error"
                  v-if="form.errors().has('federal_state_uid')"
                >
                  {{ form.errors().get('federal_state_uid') }}
                </span>
              </div>
            </div>
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyAddressCommunity')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyAddress.commune') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyAddressCommunity'"
                    :content="$t('newProperty.infoTooltip.address.community.content')"
                  />
                </label>
                <AutoComplete
                  v-model="form.community"
                  ref="community"
                  :multiple="false"
                  :suggestions="filteredGemeindes"
                  :auto-highlight="true"
                  :disabled="isPropertyReadOnly || isFederalStateEmpty"
                  inputClass="input-field"
                  :minLength="1"
                  @complete="searchCommunity($event)"
                  @keyup.esc="cancel"
                  @keyup.enter="callForNextStep"
                  class="hover-tooltip"
                  @focusin="setInfoTooltip('propertyAddressCommunity')"
                />
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyAddressStreet')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyAddress.street')
                  }}<!--<span class="nf_starcolor">*</span>-->
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyAddressStreet'"
                    :content="$t('newProperty.infoTooltip.street.content')"
                  />
                </label>
                <input
                  type="text"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly"
                  v-model="form.street"
                  placeholder=""
                  class="input-field hover-tooltip"
                  ref="street"
                  @keyup.esc="cancel"
                  @keyup.enter="callForNextStep"
                  @focusin="setInfoTooltip('propertyAddressStreet')"
                />
                <span class="help-text float-end">{{ form.street ? form.street.length : 0 }}/25</span>
                <span
                  class="error"
                  v-if="form.errors().has('street')"
                >
                  {{ form.errors().get('street') }}
                </span>
              </div>
            </div>
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyAddressHouseNumber')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyAddress.houseno')
                  }}<!--<span class="nf_starcolor">*</span>-->
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyAddressHouseNumber'"
                    :content="$t('newProperty.infoTooltip.houseNumber.content')"
                  />
                </label>
                <input
                  type="text"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly"
                  v-model="form.house_number"
                  placeholder=""
                  class="input-field hover-tooltip"
                  ref="house_number"
                  @keyup.esc="cancel"
                  @keyup.enter="callForNextStep"
                  @focusin="setInfoTooltip('propertyAddressHouseNumber')"
                />
                <span
                  class="error"
                  v-if="form.errors().has('house_number')"
                >
                  {{ form.errors().get('house_number') }}
                </span>
              </div>
            </div>
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyAddress.plz')
                  }}<!--<span class="nf_starcolor">*</span>-->
                </label>
                <input
                  type="text"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly"
                  v-model="form.zip"
                  placeholder=""
                  class="input-field hover-tooltip"
                  ref="zip"
                  @blur="searchCity"
                  @keyup.esc="cancel"
                  @keyup.enter="callForNextStep"
                  @focusin="setInfoTooltip(0)"
                />
                <span
                  class="error"
                  v-if="form.errors().has('zip')"
                >
                  {{ form.errors().get('zip') }}
                </span>
              </div>
            </div>
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title"
                  >{{ $t('newProperty.propertyAddress.ort')
                  }}<!--<span class="nf_starcolor">*</span>--></label
                >
                <input
                  type="text"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly"
                  v-model="form.city"
                  placeholder=""
                  class="input-field hover-tooltip"
                  ref="city"
                  @keyup.esc="cancel"
                  @keyup.enter="callForNextStep"
                  @focusin="setInfoTooltip(0)"
                />
                <span
                  class="error"
                  v-if="form.errors().has('city')"
                >
                  {{ form.errors().get('city') }}
                </span>
              </div>
            </div>
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip(0)"
            >
              <div class="data-form">
                <label class="label-title"
                  >{{ $t('newProperty.propertyAddress.additional_information')
                  }}<!--<span class="nf_starcolor">*</span>--></label
                >
                <input
                  type="text"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly"
                  v-model="form.additional_information"
                  placeholder=""
                  class="input-field hover-tooltip"
                  ref="city"
                  @keyup.esc="cancel"
                  @keyup.enter="callForNextStep"
                  @focusin="setInfoTooltip(0)"
                />
                <span class="help-text float-end">{{ $t('newProperty.infoTooltip.optional_help_text') }}</span>
                <span
                  class="error"
                  v-if="form.errors().has('additional_information')"
                >
                  {{ form.errors().get('additional_information') }}
                </span>
              </div>
            </div>

            <!-- InfoText for rule_G_1333 -->
            <div
              class="col-md-7"
              v-show="rule_G_1333_helpInfo !== ''"
            >
              <div class="data-form">
                <label class="label-title"> </label>
                <InfoText :text="rule_G_1333_helpInfo"> </InfoText>
              </div>
            </div>
          </div>
        </div>

        <div
          class="text_left step-button-section"
          @mouseover="setInfoTooltip(0)"
        >
          <div>
            <button
              type="button"
              @click.prevent="cancel"
              class="step-button-style border-button-style"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.abort') }}
            </button>
            <button
              type="button"
              @click.prevent="save"
              class="step-button-style border-button-style"
              v-show="!isPropertyReadOnly"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.save') }}
            </button>
            <button
              type="button"
              @click.prevent="callForNextStep"
              class="step-button-style"
              :style="[primaryColor ? { background: primaryColor } : '']"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue') }}
            </button>
            <button
              type="button"
              @click.prevent="callForNextStep"
              class="step-button-enter"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue_enter') }}
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </section>
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import i18n from '@/i18n'
import InfoTooltip from '@/components/helper/InfoTooltip'
import ShowHideTooltip from '@/mixins/ShowHideTooltip'
import InfoText from '@/components/helper/InfoText.vue'
import AutoComplete from 'primevue/autocomplete'
import InfoTextHeading from '@/components/helper/InfoTextHeading'
import { mapActions, mapGetters } from 'vuex'
const { t } = i18n.global

export default {
  name: 'PropertyAddress',
  components: { InfoTooltip, InfoText, InfoTextHeading, AutoComplete },
  mixins: [ShowHideTooltip],
  emits: ['callForNextStep', 'callForSaveProperty', 'cancelPropVisible', 'didChangeFederalState'],
  data() {
    return {
      selectedFederalStateName: '',
      toggle: false,
      clearSearch: '',
      primaryColor: '',
      fontColor: '',
      federal_states: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
        { value: 10 },
        { value: 11 },
        { value: 12 },
        { value: 13 },
        { value: 14 },
        { value: 15 },
        { value: 16 },
      ],
      gemeindes: [],
      filteredGemeindes: [],
      old_federal_state_uid: '',
      form: form({
        federal_state_uid: '',
        community: '',
        street: '',
        house_number: '',
        zip: '',
        city: '',
        additional_information: '',
      })
        .rules({
          federal_state_uid: 'required',
          street: 'max: 25',
          house_number: 'max: 39',
          zip: ['max: 5', 'integer'],
          city: 'max: 25',
          additional_information: 'max: 25',
        })
        .messages({
          'federal_state_uid.required': t('newProperty.propertyAddress.messages.federal_state_uid_required'),
          'street.max': t('newProperty.propertyAddress.messages.street_max'),
          'house_number.max': t('newProperty.propertyAddress.messages.house_number_max'),
          'zip.max': t('newProperty.propertyAddress.messages.zip_max'),
          'zip.integer': t('newProperty.propertyAddress.messages.zip_integer'),
          'city.max': t('newProperty.propertyAddress.messages.city_max'),
          'additional_information.max': t('newProperty.propertyAddress.messages.additional_information_max'),
        }),
    }
  },
  mounted() {
    this.initForm()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : ''
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.fontColor : ''
    this.showHide()
    this.invokeReference('community')
  },
  beforeUnmount() {
    this.saveForm()
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getArrCommunities',
      'getFormPropertyAddress',
      'isPropertyReadOnly',
      'infoTooltip',
    ]),

    rule_G_1333_helpInfo() {
      let myValue = ''
      switch (this.form.federal_state_uid.toString()) {
        case '2':
          myValue = t('newProperty.propertyAddress.help_info.bayern')
          break
        case '6':
          myValue = t('newProperty.propertyAddress.help_info.hamburg')
          break
        case '9':
          myValue = t('newProperty.propertyAddress.help_info.niedersachsen')
          break
      }
      return myValue
    },

    isNewProperty() {
      return this.$parent.isNewProperty
    },

    isFederalStateEmpty() {
      let myResult = true

      if (typeof this.form.federal_state_uid !== 'undefined') {
        myResult = this.form.federal_state_uid.toString() === '' || this.form.federal_state_uid.toString() === '0'
      }

      return myResult
    },
  },
  watch: {
    getArrCommunities() {
      this.gemeindes = []
      this.getArrCommunities.forEach(function (objCommunity) {
        if (typeof objCommunity.name === 'string') {
          this.gemeindes.push(objCommunity.name)
        }
      }, this)
    },

    getFormPropertyAddress() {
      this.initForm()
    },

    //watch for rule_G_1515
    'form.federal_state_uid': {
      handler(newValue) {
        this.old_federal_state_uid = newValue
      },
    },

    'form.street': {
      handler() {
        //rule_G_2611
        this.$parent.showHideTabs()
      },
    },

    'form.house_number': {
      handler() {
        //rule_G_2611
        this.$parent.showHideTabs()
      },
    },
  },
  methods: {
    ...mapActions(['apiGetCommunities', 'resetFormPropertyAddress', 'cityLookup', 'changeInfoTooltip']),

    setInfoTooltip(val) {
      this.changeInfoTooltip(val)
    },

    initForm() {
      for (const [key, value] of Object.entries(this.$store.state.Properties.formPropertyAddress)) {
        if (typeof value !== 'undefined') {
          this.form[key] = JSON.parse(JSON.stringify(value))
        }
      }
    },

    saveForm() {
      let myKeys = []
      this.resetFormPropertyAddress()
      myKeys = Object.keys(this.$store.state.Properties.formPropertyAddress)
      if (typeof myKeys === 'object') {
        myKeys.forEach(function (key) {
          if (typeof this.form[key] !== 'undefined') {
            this.$store.state.Properties.formPropertyAddress[key] = JSON.parse(JSON.stringify(this.form[key]))
          }
        }, this)
      }
    },

    searchCommunity(event) {
      if (!event.query.trim().length) {
        this.filteredGemeindes = [...this.gemeindes]
      } else {
        this.filteredGemeindes = this.gemeindes.filter((o) => {
          return o.toLowerCase().includes(event.query.toLowerCase())
        })
      }
    },

    onChangeFederalState(event) {
      this.selectedFederalStateName = event.target.options[event.target.options.selectedIndex].text
      this.form.community = ''
      this.$store.state.Properties.arrCommunities = []
      this.apiGetCommunities({ federalState: this.selectedFederalStateName, name: '' })

      //emit event for getting rules applied by Property.vue
      this.$emit('didChangeFederalState', {
        oldFederalStateUid: this.old_federal_state_uid,
        newFederalStateUid: this.form.federal_state_uid,
      })
    },

    cancel() {
      this.$emit('cancelPropVisible')
    },

    blurClear() {
      this.toggle = false
      this.clearSearch = ''
    },

    invokeReference(ref) {
      this.$nextTick(() => {
        if (!this.$refs[ref]) return
        if (
          typeof this.$refs !== 'undefined' &&
          typeof this.$refs[ref] !== 'undefined' &&
          typeof this.$refs[ref].focus === 'function'
        ) {
          this.$refs[ref].focus()
        }
      })
    },

    handleInputSearch(InputEvent) {
      const search = InputEvent.target?.value
      if (search !== '') {
        this.gemeindes = this.getArrCommunities.filter((community) =>
          community.name.toLowerCase().includes(search.toLowerCase()),
        )
      } else {
        this.gemeindes = this.getArrCommunities
      }
    },

    communitySelected(val) {
      this.form.community = val
      this.clearSearch = ''
    },
    searchCity() {
      this.cityLookup(this.form.zip).then((cities) => {
        if (Object.keys(cities).length > 0) {
          this.form.city = cities[0].name
        }
      })
    },
    callForNextStep() {
      if (!this.isPropertyReadOnly) {
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForNextStep')
      } else {
        this.$emit('callForNextStep')
      }
    },

    save() {
      if (!this.isPropertyReadOnly) {
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForSaveProperty')
      }
    },
  },
}
</script>
