import i18n from '@/i18n'
const { tm, rt } = i18n.global

const ph_taxModels = [
  {
    model: 'Bundesmodell',
    stateCodes: ['3', '4', '5', '8', '10', '11', '12', '13', '15', '16'],
  },
  {
    model: 'Modifiziertes Bodenwertmodell',
    stateCodes: ['1'],
  },
  {
    model: 'Flächen-Modell',
    stateCodes: ['2'],
  },
  {
    model: 'Wohnlagen-Modell',
    stateCodes: ['6'],
  },
  {
    model: 'Flächen-Faktor-Modell',
    stateCodes: ['7'],
  },
  {
    model: 'Flächen-Lage-Modell',
    stateCodes: ['9'],
  },
  {
    model: 'Modifiziertes Bodenwertmodell',
    stateCodes: ['14'],
  },
]

function evaluatePropertyTypeName(federalStateUid, propertyTypeCode) {
  let myPropertyTypeName = ''
  let myTaxModelObj = []
  let myPropertyTypeOptions = []
  let myPropertyTypes = []
  let myProperty = []

  if (
    typeof federalStateUid !== 'undefined' &&
    federalStateUid.toString() !== '' &&
    typeof propertyTypeCode !== 'undefined' &&
    propertyTypeCode.toString() !== ''
  ) {
    federalStateUid = federalStateUid.toString()
    propertyTypeCode = propertyTypeCode.toString()

    // Get State Tax Model
    myTaxModelObj = ph_taxModels.filter((item) => {
      if (item.stateCodes.indexOf(federalStateUid) != -1) {
        return item
      }
    })

    myPropertyTypeOptions = tm('property_type_options.Bundesmodell')
    if (typeof myTaxModelObj[0] !== 'undefined' && typeof myTaxModelObj[0].model !== 'undefined') {
      if (myTaxModelObj[0].model !== '') {
        myPropertyTypeOptions = tm('property_type_options.' + myTaxModelObj[0].model)
      }
    }

    for (let k of myPropertyTypeOptions) {
      myPropertyTypes.push({
        code: rt(k.code),
        name: rt(k.name),
      })
    }

    myProperty = myPropertyTypes.filter((item) => {
      if (item.code === propertyTypeCode) {
        return item
      }
    })
    if (typeof myProperty[0] !== 'undefined' && typeof myProperty[0].name !== 'undefined') {
      myPropertyTypeName = myProperty[0].name
    }
  }

  return myPropertyTypeName
}

function evaluateBenefitOptions(federalStateUid, filterMonument = false) {
  let arrBenefitOptions = []
  let myTaxModelObj = []
  let myBenefitOptions = []

  if (typeof federalStateUid !== 'undefined' && federalStateUid.toString() !== '') {
    federalStateUid = federalStateUid.toString()

    // Get State Tax Model
    myTaxModelObj = ph_taxModels.filter((item) => {
      if (item.stateCodes.indexOf(federalStateUid) != -1) {
        return item
      }
    })

    myBenefitOptions = tm('benefit_options.Bundesmodell')
    if (typeof myTaxModelObj[0] !== 'undefined' && typeof myTaxModelObj[0].model !== 'undefined') {
      if (myTaxModelObj[0].model !== '') {
        myBenefitOptions = tm('benefit_options.' + myTaxModelObj[0].model)
      }
    }

    for (let k of myBenefitOptions) {
      if (filterMonument && rt(k.code) === '6') {
        continue
      }
      arrBenefitOptions.push({
        code: rt(k.code),
        name: rt(k.name),
      })
    }
  }

  return arrBenefitOptions
}

function evaluateHasMonumentIdentification(federalStateUid) {
  return ph_taxModels
    .reduce((initValue, current) => {
      if (['Bundesmodell', 'Modifiziertes Bundesmodell', 'Modifiziertes Bodenwertmodell'].includes(current['model'])) {
        initValue.push(...current['stateCodes'])
      }
      return initValue
    }, [])
    .includes(federalStateUid)
}

function evaluateBenefitOptionsNonResidential(federalStateUid) {
  let arrBenefitOptions = []
  let myTaxModelObj = []
  let myBenefitOptions = []

  if (typeof federalStateUid !== 'undefined' && federalStateUid.toString() !== '') {
    federalStateUid = federalStateUid.toString()

    // Get State Tax Model
    myTaxModelObj = ph_taxModels.filter((item) => {
      if (item.stateCodes.indexOf(federalStateUid) != -1) {
        return item
      }
    })

    myBenefitOptions = tm('benefit_options.' + 'Bundesmodell Nichtwohngrundstück')
    if (typeof myTaxModelObj[0] !== 'undefined' && typeof myTaxModelObj[0].model !== 'undefined') {
      if (myTaxModelObj[0].model !== '' && myTaxModelObj[0].model !== 'Bundesmodell') {
        myBenefitOptions = tm('benefit_options.' + myTaxModelObj[0].model)
      }
    }

    for (let k of myBenefitOptions) {
      arrBenefitOptions.push({
        code: rt(k.code),
        name: rt(k.name),
      })
    }
  }

  return arrBenefitOptions
}

function evaluateOwnershipSalutationOptions(federalStateUid) {
  let arrSalutationOptions = []
  let myTaxModelObj = []
  let mySalutationOptions = []

  if (typeof federalStateUid !== 'undefined' && federalStateUid.toString() !== '') {
    federalStateUid = federalStateUid.toString()

    // Get State Tax Model
    myTaxModelObj = ph_taxModels.filter((item) => {
      if (item.stateCodes.indexOf(federalStateUid) != -1) {
        return item
      }
    })

    mySalutationOptions = tm('ownership_salutation_options.Bundesmodell')
    if (typeof myTaxModelObj[0] !== 'undefined' && typeof myTaxModelObj[0].model !== 'undefined') {
      if (myTaxModelObj[0].model !== '') {
        mySalutationOptions = tm('ownership_salutation_options.' + myTaxModelObj[0].model)
      }
    }

    for (let k of mySalutationOptions) {
      arrSalutationOptions.push({
        code: rt(k.code),
        name: rt(k.name),
      })
    }
  }

  return arrSalutationOptions
}

function evaluateBuildingsExemptionOptions() {
  let arrExemptionOptions = []
  let myExemptionOptions = []

  myExemptionOptions = tm('newProperty.propertyBuildings.fixed_exemption_options')

  for (let k of myExemptionOptions) {
    arrExemptionOptions.push({
      code: rt(k.code),
      name: rt(k.name),
    })
  }

  return arrExemptionOptions
}

export {
  evaluatePropertyTypeName,
  evaluateOwnershipSalutationOptions,
  evaluateBuildingsExemptionOptions,
  evaluateBenefitOptions,
  evaluateBenefitOptionsNonResidential,
  evaluateHasMonumentIdentification,
}
