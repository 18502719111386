<template>
  <section class="Step_SectionPropertyBenefits">
    <fieldset>
      <div class="Step_SectionPropertyBenefits">
        <InfoTextHeading
          :headingText="$t('newProperty.propertyBenefits.headline')"
          :infoText="$t('infoTextDisabledFields')"
        >
        </InfoTextHeading>

        <div class="form-group">
          <div class="row_setp_main">
            <!-- Steuerbefreiungen und Steuervergünstigungen -->
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip(0)"
            >
              <h2 class="subheading_title">{{ $t('newProperty.propertyBenefits.subheadline1') }}</h2>
              <hr class="subheading_devide" />
            </div>

            <!-- Steuerbefreiung -->
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyBenefitsTaxExemption')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyBenefits.tax_exemption') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyBenefitsTaxExemption'"
                    :content="$t('newProperty.infoTooltip.benefits.taxExemption.content')"
                    :url="$t('newProperty.infoTooltip.benefits.taxExemption.url')"
                  />
                </label>
                <select
                  v-model.number="form.tax_exemption"
                  :disabled="isPropertyReadOnly"
                  ref="tax_exemption"
                  class="hover-tooltip"
                  @focusin="setInfoTooltip('propertyBenefitsTaxExemption')"
                >
                  <option
                    :value="0"
                    selected
                  >
                    {{ $t('select.select_any') }}
                  </option>
                  <option
                    v-for="option in usage_type_options"
                    v-bind:key="option.value"
                    :value="option.value"
                  >
                    {{ $t('usage_type_options.' + option.value) }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Vergünstigung -->
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyBenefitsBenefit')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyBenefits.benefit') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyBenefitsBenefit'"
                    :content="$t('newProperty.infoTooltip.benefits.benefit.content')"
                    :url="$t('newProperty.infoTooltip.benefits.benefit.url')"
                  />
                </label>
                <select
                  v-model.number="form.benefit"
                  :disabled="isPropertyReadOnly"
                  class="hover-tooltip"
                  @focusin="setInfoTooltip('propertyBenefitsBenefit')"
                >
                  <option
                    v-for="option in benefit_options_non_property()"
                    v-bind:key="option.code"
                    :value="option.code"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Ist das Grundstück durch denkmalgeschützte Gebäude oder Gebäudeteile (§ 15 Absatz 5 GrStG) begünstigt? -->
            <div
              v-show="hasMonumentCheckBox()"
              class="col-md-7"
            >
              <div
                class="data-form"
                @mouseover="setInfoTooltip(0)"
              >
                <label class="label-title tooltip-icon">
                  {{ $t('newProperty.propertyBenefits.monumentIdentification') }}
                </label>
                <div class="gdr_plots">
                  <div class="gdr_plots_inner">
                    <input
                      type="radio"
                      :disabled="isPropertyReadOnly"
                      class="radio-button hover-tooltip"
                      :value="1"
                      name="monument_identification_toggle"
                      v-model="form.monument_identification"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span class="grd_radio01 grd_radio">{{ $t('newProperty.yes') }}</span>
                  </div>
                  <div class="gdr_plots_inner">
                    <input
                      type="radio"
                      :disabled="isPropertyReadOnly"
                      class="radio-button hover-tooltip"
                      :value="0"
                      name="monument_identification_toggle"
                      v-model="form.monument_identification"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span class="grd_radio01 grd_radio">{{ $t('newProperty.no') }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Ein räumlich nicht abgrenzbarer Teil des Grundbesitzes unterliegt -->
            <!-- einer Grundsteuerbefreiung (steuerfreie Nutzung überwiegt)        -->
            <div class="col-md-7">
              <div
                class="data-form"
                @mouseenter="setInfoTooltip('propertyBenefitsNonSeparablePartRealEstate')"
              >
                <label class="label-title">
                  {{ $t('newProperty.propertyBenefits.non_separable_part_real_estate') }}
                  <InfoTooltip
                    v-if="
                      infoTooltip === 'propertyBenefitsNonSeparablePartRealEstate' &&
                      !non_separable_part_real_estate_activated
                    "
                    numberOfLinesOfLabel="2"
                    :isDisabled="!non_separable_part_real_estate_activated"
                  />
                </label>
                <select
                  v-model.number="form.non_separable_part_real_estate"
                  :disabled="isPropertyReadOnly || !non_separable_part_real_estate_activated"
                  class="hover-tooltip"
                  @focusin="setInfoTooltip('propertyBenefitsNonSeparablePartRealEstate')"
                >
                  <option
                    :value="0"
                    selected
                  >
                    {{ $t('select.select_any') }}
                  </option>
                  <option
                    v-for="option in usage_type_options"
                    v-bind:key="option.value"
                    :value="option.value"
                  >
                    {{ $t('usage_type_options.' + option.value) }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Art des Grundstücks -->
            <div class="col-md-7">
              <div
                class="data-form"
                @mouseenter="setInfoTooltip('propertyBenefitsPropertyExemptionBenefitType')"
              >
                <label class="label-title">
                  {{ $t('newProperty.propertyBenefits.benefits_property_type') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyBenefitsPropertyExemptionBenefitType'"
                    :content="$t('newProperty.infoTooltip.benefits.propertyExemptionBenefitType.content')"
                    :url="$t('newProperty.infoTooltip.benefits.propertyExemptionBenefitType.url')"
                    :isDisabled="!property_exemption_benefit_type_activated"
                  />
                </label>
                <select
                  :disabled="isPropertyReadOnly || !property_exemption_benefit_type_activated"
                  v-model.number="form.property_exemption_benefit_type"
                  class="hover-tooltip"
                  @focusin="setInfoTooltip('propertyBenefitsPropertyExemptionBenefitType')"
                >
                  <option
                    v-for="option in property_exemption_benefit_type_options"
                    v-bind:key="option.code"
                    :value="option.code"
                  >
                    {{ option.name }}
                  </option>
                </select>

                <span
                  class="error"
                  v-if="form.errors().has('benefit_property_type')"
                >
                  {{ form.errors().get('benefit_property_type') }}
                </span>
              </div>
            </div>

            <!-- Zusätzliche Angaben, wenn die Grundsteuervergünstigung "Wohnraumförderung" vorliegt -->
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip(0)"
            >
              <h2 class="subheading_title">{{ $t('newProperty.propertyBenefits.subheadline2') }}</h2>
              <hr class="subheading_devide" />
            </div>

            <!-- Ablaufdatum des zuerst auslaufenden Förderungszeitraum -->
            <div class="col-md-7">
              <div
                class="data-form"
                @mouseenter="setInfoTooltip('propertyBenefitsExpirationFirstGrantPeriod')"
              >
                <label class="label-title">
                  {{ $t('newProperty.propertyBenefits.expiration_first_grant_period') }}
                  <InfoTooltip
                    v-if="
                      infoTooltip === 'propertyBenefitsExpirationFirstGrantPeriod' &&
                      !expiration_first_grant_period_activated
                    "
                    :isDisabled="!expiration_first_grant_period_activated"
                  />
                </label>
                <div class="input-icon">
                  <input
                    type="date"
                    autocomplete="off"
                    v-model="form.expiration_first_grant_period"
                    :disabled="isPropertyReadOnly || !expiration_first_grant_period_activated"
                    placeholder=""
                    class="input-field hover-tooltip"
                    ref="expiration_first_grant_period"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyBenefitsExpirationFirstGrantPeriod')"
                  />
                  <Svgall
                    :name="'calendar'"
                    :color="primaryColor"
                    class="date-icon-firefox-fix"
                  />
                </div>
              </div>
            </div>

            <!-- Ablaufdatum des zuletzt auslaufenden Förderungszeitraum -->
            <div class="col-md-7">
              <div
                class="data-form"
                @mouseenter="setInfoTooltip('propertyBenefitsExpirationLastGrantPeriod')"
              >
                <label class="label-title">
                  {{ $t('newProperty.propertyBenefits.expiration_last_grant_period') }}
                  <InfoTooltip
                    v-if="
                      infoTooltip === 'propertyBenefitsExpirationLastGrantPeriod' &&
                      !expiration_last_grant_period_activated
                    "
                    :isDisabled="!expiration_last_grant_period_activated"
                  />
                </label>
                <div class="input-icon">
                  <input
                    type="date"
                    autocomplete="off"
                    v-model="form.expiration_last_grant_period"
                    :disabled="isPropertyReadOnly || !expiration_last_grant_period_activated"
                    placeholder=""
                    class="input-field hover-tooltip"
                    ref="expiration_last_grant_period"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyBenefitsExpirationLastGrantPeriod')"
                  />
                  <Svgall
                    :name="'calendar'"
                    :color="primaryColor"
                    class="date-icon-firefox-fix"
                  />
                </div>
              </div>
            </div>

            <!-- Zusätzliche Angaben, wenn ein Grundstück für steuerbefreite Zwecke hergerichtet wird -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <h2 class="subheading_title">{{ $t('newProperty.propertyBenefits.subheadline3') }}</h2>
              <hr class="subheading_devide" />
            </div>

            <!-- Voraussichtliche Verwendung für steuerbegünstigte Zwecke in % -->
            <div class="col-md-7">
              <div
                class="data-form"
                @mouseenter="setInfoTooltip('propertyBenefitsExpectedExemptPurposes')"
              >
                <label class="label-title">
                  {{ $t('newProperty.propertyBenefits.expected_exempt_purposes') }}
                  <InfoTooltip
                    v-if="
                      infoTooltip === 'propertyBenefitsExpectedExemptPurposes' && !expected_exempt_purposes_activated
                    "
                    :isDisabled="!expected_exempt_purposes_activated"
                  />
                </label>
                <input
                  type="text"
                  autocomplete="off"
                  v-model="form.expected_exempt_purposes"
                  :disabled="isPropertyReadOnly || !expected_exempt_purposes_activated"
                  placeholder=""
                  class="input-field hover-tooltip"
                  ref="expected_exempt_purposes"
                  @keyup.esc="cancel"
                  @keyup.enter="callForNextStep"
                  @focusin="setInfoTooltip('propertyBenefitsExpectedExemptPurposes')"
                />
              </div>
            </div>

            <!-- Steuerbefreiung -->
            <div class="col-md-7">
              <div
                class="data-form"
                @mouseenter="setInfoTooltip('propertyBenefitsTaxPrivilegedPurposes')"
              >
                <label class="label-title">
                  {{ $t('newProperty.propertyBenefits.tax_privileged_purposes') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyBenefitsTaxPrivilegedPurposes' && !tax_privileged_purposes_activated"
                    :isDisabled="!tax_privileged_purposes_activated"
                  />
                </label>
                <select
                  v-model.number="form.tax_privileged_purposes"
                  :disabled="isPropertyReadOnly || !tax_privileged_purposes_activated"
                  class="hover-tooltip"
                  @focusin="setInfoTooltip('propertyBenefitsTaxPrivilegedPurposes')"
                >
                  <option
                    :value="0"
                    disabled
                    selected
                  >
                    {{ $t('select.select_any') }}
                  </option>
                  <option
                    v-for="option in usage_type_options"
                    v-bind:key="option.value"
                    :value="option.value"
                  >
                    {{ $t('usage_type_options.' + option.value) }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Bei unbebauten Grundstücken mit teilweiser Grundsteuerbefreiung -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <h2 class="subheading_title">{{ $t('newProperty.propertyBenefits.group1.groupheadline') }}</h2>
              <hr class="subheading_devide" />
            </div>

            <div
              v-for="(object, index) in form.unbuilt_real_estate_exemptions"
              :key="index"
            >
              <!-- Befreiung 1 -->
              <div class="col-md-7">
                <div
                  class="deleteIcon delete_"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <h5 class="subheading_title_inner">
                    {{ $t('newProperty.propertyBenefits.group1.itemheadline') }} {{ index + 1 }}
                  </h5>
                  <Svgall
                    @click="removeUnbuiltRealEstateExemption(index)"
                    :name="'delete'"
                    :color="primaryColor"
                    v-show="!isPropertyReadOnly"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip(0)"
                  />
                </div>

                <!-- Bezeichnung -->
                <div
                  class="data-form"
                  @mouseenter="setInfoTooltip('propertyBenefitsUnbuiltRealEstateExemptionDescription_' + index)"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyBenefits.group1.unbuilt_real_estate_exemption_description') }}
                    <InfoTooltip
                      v-if="
                        infoTooltip === 'propertyBenefitsUnbuiltRealEstateExemptionDescription_' + index &&
                        !unbuilt_real_estate_exemption_description_activated
                      "
                      :isDisabled="!unbuilt_real_estate_exemption_description_activated"
                    />
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.unbuilt_real_estate_exemptions[index].unbuilt_real_estate_exemption_description"
                    :disabled="isPropertyReadOnly || !unbuilt_real_estate_exemption_description_activated"
                    placeholder=""
                    class="input-field hover-tooltip"
                    ref="unbuilt_real_estate_exemptions_{{index}}"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @mouseenter="setInfoTooltip('propertyBenefitsUnbuiltRealEstateExemptionDescription_' + index)"
                  />
                </div>
              </div>

              <!-- Steuerfreie Fläche in m² -->
              <div class="col-md-7">
                <div
                  class="data-form"
                  @mouseenter="setInfoTooltip('propertyBenefitsUnbuiltRealEstateExemptionTaxFreeArea_' + index)"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyBenefits.group1.tax_free_area') }}
                    <InfoTooltip
                      v-if="
                        infoTooltip === 'propertyBenefitsUnbuiltRealEstateExemptionTaxFreeArea_' + index &&
                        !tax_free_area_activated
                      "
                      :isDisabled="!tax_free_area_activated"
                    />
                  </label>
                  <input
                    type="number"
                    @keypress="onlyNumber"
                    autocomplete="off"
                    v-model="form.unbuilt_real_estate_exemptions[index].tax_free_area"
                    :disabled="isPropertyReadOnly || !tax_free_area_activated"
                    placeholder=""
                    class="input-field hover-tooltip"
                    ref="group1_tax_free_area_{{index}}"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyBenefitsUnbuiltRealEstateExemptionTaxFreeArea_' + index)"
                  />
                </div>
              </div>

              <!-- Steuerbefreiung -->
              <div class="col-md-7">
                <div
                  class="data-form"
                  @mouseenter="setInfoTooltip('propertyBenefitsUnbuiltRealEstateExemptionTaxExemption_' + index)"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyBenefits.group1.unbuilt_real_estate_tax_exemption') }}
                    <InfoTooltip
                      v-if="
                        infoTooltip === 'propertyBenefitsUnbuiltRealEstateExemptionTaxExemption_' + index &&
                        !unbuilt_real_estate_tax_exemption_activated
                      "
                      :isDisabled="!unbuilt_real_estate_tax_exemption_activated"
                    />
                  </label>
                  <select
                    v-model.number="form.unbuilt_real_estate_exemptions[index].unbuilt_real_estate_tax_exemption"
                    :disabled="isPropertyReadOnly || !unbuilt_real_estate_tax_exemption_activated"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip('propertyBenefitsUnbuiltRealEstateExemptionTaxExemption_' + index)"
                  >
                    <option
                      :value="0"
                      disabled
                      selected
                    >
                      {{ $t('select.select_any') }}
                    </option>
                    <option
                      v-for="option in usage_type_options"
                      v-bind:key="option.value"
                      :value="option.value"
                    >
                      {{ $t('usage_type_options.' + option.value) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Weitere Befreiung hinzufügen -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="add-field no_space">
                <button
                  class="add-field-button"
                  :disabled="isPropertyReadOnly"
                  @click="addUnbuiltRealEstateExemption"
                >
                  <Svgall
                    :name="'add_more'"
                    :color="primaryColor"
                  />
                  <span>
                    {{
                      $t('newProperty.propertyBenefits.group1.add_undeveloped_land_with_partial_property_tax_exemption')
                    }}
                  </span>
                </button>
              </div>
            </div>

            <!-- Verwendung eines räumlich abgrenzbaren Teils des Grund und Bodens für steuerbefreite Zwecke -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <h2 class="subheading_title">{{ $t('newProperty.propertyBenefits.group2.groupheadline') }}</h2>
              <hr class="subheading_devide" />
            </div>

            <div
              v-for="(object, index) in form.use_of_definable_parts"
              :key="index"
            >
              <!-- Befreiung 1 -->
              <div class="col-md-7">
                <div
                  class="deleteIcon delete_"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <h5 class="subheading_title_inner">
                    {{ $t('newProperty.propertyBenefits.group2.itemheadline') }} {{ index + 1 }}
                  </h5>
                  <Svgall
                    @click="removeUseOfDefinableParts(index)"
                    :name="'delete'"
                    :color="primaryColor"
                    v-show="!isPropertyReadOnly"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip(0)"
                  />
                </div>

                <!-- Bezeichnung -->
                <div
                  class="data-form"
                  @mouseenter="setInfoTooltip('propertyBenefitsUseOfDefinablePartsSpatiallyDescription_' + index)"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyBenefits.group2.spatially_definable_exemption_part_description') }}
                    <InfoTooltip
                      v-if="
                        infoTooltip === 'propertyBenefitsUseOfDefinablePartsSpatiallyDescription_' + index &&
                        !spatially_definable_exemption_part_description_activated
                      "
                      :isDisabled="!spatially_definable_exemption_part_description_activated"
                    />
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.use_of_definable_parts[index].spatially_definable_exemption_part_description"
                    :disabled="isPropertyReadOnly || !spatially_definable_exemption_part_description_activated"
                    placeholder=""
                    class="input-field hover-tooltip"
                    ref="use_of_definable_parts_{{index}}"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyBenefitsUseOfDefinablePartsSpatiallyDescription_' + index)"
                  />
                </div>
              </div>

              <!-- Steuerfreie Fläche in m² -->
              <div class="col-md-7">
                <div
                  class="data-form"
                  @mouseenter="setInfoTooltip('propertyBenefitsUseOfDefinablePartsSpatiallyArea_' + index)"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyBenefits.group2.spatially_definable_exemption_area') }}
                    <InfoTooltip
                      v-if="
                        infoTooltip === 'propertyBenefitsUseOfDefinablePartsSpatiallyArea_' + index &&
                        !spatially_definable_exemption_area_activated
                      "
                      :isDisabled="!spatially_definable_exemption_area_activated"
                    />
                  </label>
                  <input
                    type="number"
                    @keypress="onlyNumber"
                    autocomplete="off"
                    v-model="form.use_of_definable_parts[index].spatially_definable_exemption_area"
                    :disabled="isPropertyReadOnly || !spatially_definable_exemption_area_activated"
                    placeholder=""
                    class="input-field hover-tooltip"
                    ref="use_of_definable_parts_{{index}}"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyBenefitsUseOfDefinablePartsSpatiallyArea_' + index)"
                  />
                </div>
              </div>

              <!-- Steuerbefreiung -->
              <div class="col-md-7">
                <div
                  class="data-form"
                  @mouseenter="setInfoTooltip('propertyBenefitsUseOfDefinablePartsSpatiallyPart_' + index)"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyBenefits.group2.spatially_definable_tax_exemption_part') }}
                    <InfoTooltip
                      v-if="
                        infoTooltip === 'propertyBenefitsUseOfDefinablePartsSpatiallyPart_' + index &&
                        !spatially_definable_tax_exemption_part_activated
                      "
                      :isDisabled="!spatially_definable_tax_exemption_part_activated"
                    />
                  </label>
                  <select
                    v-model.number="form.use_of_definable_parts[index].spatially_definable_tax_exemption_part"
                    :disabled="isPropertyReadOnly || !spatially_definable_tax_exemption_part_activated"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip('propertyBenefitsUseOfDefinablePartsSpatiallyPart_' + index)"
                  >
                    <option
                      :value="0"
                      disabled
                      selected
                    >
                      {{ $t('select.select_any') }}
                    </option>
                    <option
                      v-for="option in usage_type_options"
                      v-bind:key="option.value"
                      :value="option.value"
                    >
                      {{ $t('usage_type_options.' + option.value) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Weitere Befreiung hinzufügen -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="add-field no_space">
                <button
                  class="add-field-button hover-tooltip"
                  :disabled="isPropertyReadOnly"
                  @click="addUseOfDefinableParts"
                  @focusin="setInfoTooltip(0)"
                >
                  <Svgall
                    :name="'add_more'"
                    :color="primaryColor"
                  />
                  <span>
                    {{ $t('newProperty.propertyBenefits.group2.add_definable_part_of_the_land_for_exempt_purposes') }}
                  </span>
                </button>
              </div>
            </div>

            <!-- Für Teile des Grundbesitzes liegen die Voraussetzungen für eine Steuervergünstigung vor -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <h2 class="subheading_title">{{ $t('newProperty.propertyBenefits.group3.groupheadline') }}</h2>
              <hr class="subheading_devide" />
            </div>

            <div
              v-for="(object, index) in form.tax_break_conditions"
              :key="index"
            >
              <!-- Vergünstigung 1 -->
              <div class="col-md-7">
                <div
                  class="deleteIcon delete_"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <h5 class="subheading_title_inner">
                    {{ $t('newProperty.propertyBenefits.group3.itemheadline') }} {{ index + 1 }}
                  </h5>
                  <Svgall
                    @click="removeTaxBreakCondition(index)"
                    v-show="!isPropertyReadOnly"
                    :name="'delete'"
                    :color="primaryColor"
                    class="hover-tooltip"
                    @focusin="setInfoTooltip(0)"
                  />
                </div>

                <!-- Bezeichnung -->
                <div
                  class="data-form"
                  @mouseenter="setInfoTooltip('propertyBenefitsTaxBreakConditionsDescription_' + index)"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyBenefits.group3.spatially_definable_exemption_part_description') }}
                    <InfoTooltip
                      v-if="
                        infoTooltip === 'propertyBenefitsTaxBreakConditionsDescription_' + index &&
                        !tax_break_conditions_spatially_definable_exemption_part_description_activated
                      "
                      :isDisabled="!tax_break_conditions_spatially_definable_exemption_part_description_activated"
                    />
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    v-model="form.tax_break_conditions[index].spatially_definable_exemption_part_description"
                    :disabled="
                      isPropertyReadOnly ||
                      !tax_break_conditions_spatially_definable_exemption_part_description_activated
                    "
                    placeholder=""
                    class="input-field hover-tooltip"
                    ref="group3_spatially_definable_exemption_part_description_{{index}}"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyBenefitsTaxBreakConditionsDescription_' + index)"
                  />
                </div>
              </div>

              <!-- Vergünstigte Fläche in m² -->
              <div class="col-md-7">
                <div
                  class="data-form"
                  @mouseenter="setInfoTooltip('propertyBenefitsTaxBreakConditionsArea_' + index)"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyBenefits.group3.spatially_definable_exemption_area') }}
                    <InfoTooltip
                      v-if="
                        infoTooltip === 'propertyBenefitsTaxBreakConditionsArea_' + index &&
                        !tax_break_conditions_spatially_definable_exemption_area_activated
                      "
                      :isDisabled="!tax_break_conditions_spatially_definable_exemption_area_activated"
                    />
                  </label>
                  <input
                    type="number"
                    @keypress="onlyNumber"
                    autocomplete="off"
                    v-model="form.tax_break_conditions[index].spatially_definable_exemption_area"
                    :disabled="isPropertyReadOnly || !tax_break_conditions_spatially_definable_exemption_area_activated"
                    placeholder=""
                    class="input-field hover-tooltip"
                    ref="group3_spatially_definable_exemption_area_{{index}}"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyBenefitsTaxBreakConditionsArea_' + index)"
                  />
                </div>
              </div>

              <!-- Vergünstigung -->
              <div class="col-md-7">
                <div
                  class="data-form"
                  @mouseenter="setInfoTooltip('propertyBenefitsTaxBreakConditionsPart_' + index)"
                >
                  <label class="label-title">
                    {{ $t('newProperty.propertyBenefits.group3.spatially_definable_tax_exemption_part') }}
                    <InfoTooltip
                      v-if="
                        infoTooltip === 'propertyBenefitsTaxBreakConditionsPart_' + index &&
                        !tax_break_conditions_spatially_definable_tax_exemption_part_activated
                      "
                      :isDisabled="!tax_break_conditions_spatially_definable_tax_exemption_part_activated"
                    />
                  </label>
                  <select
                    v-model.number="form.tax_break_conditions[index].spatially_definable_tax_exemption_part"
                    :disabled="
                      isPropertyReadOnly || !tax_break_conditions_spatially_definable_tax_exemption_part_activated
                    "
                    class="hover-tooltip"
                    @focusin="setInfoTooltip('propertyBenefitsTaxBreakConditionsPart_' + index)"
                  >
                    <option
                      v-for="option in benefit_options()"
                      v-bind:key="option.code"
                      :value="option.code"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Weitere Vergünstigung hinzufügen -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="add-field no_space">
                <button
                  class="add-field-button hover-tooltip"
                  :disabled="isPropertyReadOnly"
                  @click="addTaxBreakCondition"
                  @focusin="setInfoTooltip(0)"
                >
                  <Svgall
                    :name="'add_more'"
                    :color="primaryColor"
                  />
                  <span>
                    {{ $t('newProperty.propertyBenefits.group3.add_tax_relief_for_parts_of_the_property') }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="text_left step-button-section"
          @mouseenter="setInfoTooltip(0)"
        >
          <div>
            <button
              type="button"
              @click.prevent="cancel"
              class="step-button-style border-button-style hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.abort') }}
            </button>
            <button
              type="button"
              @click.prevent="save"
              class="step-button-style border-button-style hover-tooltip"
              v-show="!isPropertyReadOnly"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.save') }}
            </button>
            <button
              type="button"
              v-show="back_button_activated"
              @click.prevent="callForPrecedingStep"
              class="step-button-style border-button-style"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.back') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              class="step-button-style hover-tooltip"
              :style="[primaryColor ? { background: primaryColor } : '']"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              class="step-button-enter hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue_enter') }}
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </section>
</template>

<script type="text/javascript">
import form from 'vuejs-form'
//import i18n from "@/i18n"
//const {t} = i18n.global
import InfoTooltip from '@/components/helper/InfoTooltip'
import ShowHideTooltip from '@/mixins/ShowHideTooltip'
import DateIconFirefoxFix from '@/mixins/DateIconFirefoxFix'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { defaultPropertyBenefits } from '../../store/defaults/properties/DefaultPropertyBenefits'
import { evaluateBenefitOptions, evaluateHasMonumentIdentification } from '@/helper/PropertyHelper'
//import apiClient from "../../Api";
import InfoTextHeading from '@/components/helper/InfoTextHeading'

export default {
  name: 'PropertyBenefits',
  components: { InfoTextHeading, InfoTooltip },
  mixins: [ShowHideTooltip, DateIconFirefoxFix],
  emits: ['callForNextStep', 'callForPrecedingStep', 'callForSaveProperty', 'cancelPropVisible'],
  data() {
    return {
      primaryColor: '',
      fontColor: '',
      form: form({
        //Steuerbefreiungen und Steuervergünstigungen
        tax_exemption: '0', // -1-15
        benefit: '0', // 1-6
        monument_identification: '0',
        non_separable_part_real_estate: '0', // 1-15 same list as usage_type_options
        property_exemption_benefit_type: '0', // Art des Grundstücks

        //Zusätzliche Angaben, wenn die Grundsteuervergünstigung "Wohnraumförderung" vorliegt
        expiration_first_grant_period: '', //Ablaufdatum des zuerst auslaufenden Förderungszeitraum
        expiration_last_grant_period: '', //Ablaufdatum des zuletzt auslaufenden Förderungszeitraum

        //Zusätzliche Angaben, wenn ein Grundstück für steuerbefreite Zwecke hergerichtet wird
        expected_exempt_purposes: '', //Voraussichtliche Verwendung für steuerbegünstigte Zwecke in %
        tax_privileged_purposes: '0', //Steuerbefreiung; 1-15 same list as usage_type_options

        //Bei unbebauten Grundstücken mit teilweiser Grundsteuerbefreiung
        unbuilt_real_estate_exemptions: [
          {
            id: 0,
            unbuilt_real_estate_exemption_description: '', //Bezeichnung
            tax_free_area: '', //Steuerfreie Fläche in m²
            unbuilt_real_estate_tax_exemption: '0', //Steuerbefreiung; 1-15 same list as usage_type_options
          },
        ],

        //Verwendung eines räumlich abgrenzbaren Teils des Grund und Bodens für steuerbefreite Zwecke
        use_of_definable_parts: [
          {
            id: 0, //internal data, no form edit
            spatially_definable_exemption_part_description: '', //Bezeichnung
            spatially_definable_exemption_area: '', //Steuerfreie Fläche in m²
            spatially_definable_tax_exemption_part: '0', //Steuerbefreiung; 1-15 same list as usage_type_options
          },
        ],

        //Für Teile des Grundbesitzes liegen die Voraussetzungen für eine Steuervergünstigung vor
        tax_break_conditions: [
          {
            id: 0, //internal data, no form edit
            spatially_definable_exemption_part_description: '', //Bezeichnung
            spatially_definable_exemption_area: '', //Steuerfreie Fläche in m²
            spatially_definable_tax_exemption_part: '0', //Steuerbefreiung; 1-15 same list as usage_type_options
          },
        ],
      })
        .rules({})
        .messages({}),
      usage_type_options: [
        { value: '1' },
        { value: '2' },
        { value: '3' },
        { value: '4' },
        { value: '5' },
        { value: '6' },
        { value: '7' },
        { value: '8' },
        { value: '9' },
        { value: '10' },
        { value: '11' },
        { value: '12' },
        { value: '13' },
        { value: '14' },
        { value: '15' },
      ],
      non_separable_part_real_estate_activated: true,
      expiration_first_grant_period_activated: true,
      expiration_last_grant_period_activated: true,
      expected_exempt_purposes_activated: true,
      tax_privileged_purposes_activated: true,
      unbuilt_real_estate_exemption_description_activated: true,
      tax_free_area_activated: true,
      unbuilt_real_estate_tax_exemption_activated: true,
      spatially_definable_exemption_part_description_activated: true,
      spatially_definable_exemption_area_activated: true,
      spatially_definable_tax_exemption_part_activated: true,
      tax_break_conditions_spatially_definable_exemption_part_description_activated: true,
      tax_break_conditions_spatially_definable_exemption_area_activated: true,
      tax_break_conditions_spatially_definable_tax_exemption_part_activated: true,
      property_exemption_benefit_type_activated: true,
      monument_identification: false,
      back_button_activated: false,
    }
  },
  created() {
    //this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor :"#43ac6f"
  },
  mounted() {
    this.initForm()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : ''
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.fontColor : ''

    this.showHide()
    this.invokeReference('expiration_first_grant_period')
  },
  beforeUnmount() {
    this.saveForm()
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'isPropertyReadOnly', 'infoTooltip', 'getFormPropertyBenefits']),

    property_exemption_benefit_type_options() {
      let resultArray = []
      let myOptions = this.$tm('newProperty.propertyBenefits.property_exemption_benefit_type_options')
      for (let k of myOptions) {
        resultArray.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }
      return resultArray
    },

    isNewProperty() {
      return this.$parent.isNewProperty
    },

    isShowNextStepButton() {
      let isShow = true
      if (this.$parent.step === this.$parent.maxStep && this.isPropertyReadOnly) {
        isShow = false
      }
      return isShow
    },
  },
  watch: {
    getFormPropertyBenefits() {
      this.initForm()
    },

    'form.tax_exemption'(newValue) {
      if (newValue === '') {
        this.form.tax_exemption = '0'
      }
    },

    'form.benefit'(newValue) {
      if (newValue === '') {
        this.form.benefit = '0'
      }
    },

    'form.non_separable_part_real_estate'(newValue) {
      if (newValue === '') {
        this.form.non_separable_part_real_estate = '0'
      }
    },

    'form.tax_privileged_purposes'(newValue) {
      if (newValue === '') {
        this.form.tax_privileged_purposes = '0'
      }
    },

    non_separable_part_real_estate_activated(newValue) {
      if (newValue === false) {
        this.form.non_separable_part_real_estate = '0'
      }
    },

    expiration_first_grant_period_activated(newValue) {
      if (newValue === false) {
        this.form.expiration_first_grant_period = ''
      }
    },

    expiration_last_grant_period_activated(newValue) {
      if (newValue === false) {
        this.form.expiration_last_grant_period = ''
      }
    },

    expected_exempt_purposes_activated(newValue) {
      if (newValue === false) {
        this.form.expected_exempt_purposes = ''
      }
    },

    tax_privileged_purposes_activated(newValue) {
      if (newValue === false) {
        this.form.tax_privileged_purposes = '0'
      }
    },

    property_exemption_benefit_type_activated(newValue) {
      if (newValue === false) {
        this.form.property_exemption_benefit_type = '0'
      }
    },
  },
  methods: {
    ...mapMutations(['setFormPropertyBenefits']),
    ...mapActions(['resetFormPropertyBenefits', 'changeInfoTooltip']),

    benefit_options_non_property() {
      let myOptions = []

      if (
        typeof this.$store.state.Properties.formPropertyAddress === 'object' &&
        typeof this.$store.state.Properties.formPropertyAddress.federal_state_uid !== 'undefined'
      ) {
        myOptions = evaluateBenefitOptions(
          this.$store.state.Properties.formPropertyAddress.federal_state_uid,
          evaluateHasMonumentIdentification(this.$store.state.Properties.formPropertyAddress.federal_state_uid),
        )
      }
      return myOptions
    },

    benefit_options(filter_monument) {
      let myOptions = []
      if (
        typeof this.$store.state.Properties.formPropertyAddress === 'object' &&
        typeof this.$store.state.Properties.formPropertyAddress.federal_state_uid !== 'undefined'
      ) {
        myOptions = evaluateBenefitOptions(
          this.$store.state.Properties.formPropertyAddress.federal_state_uid,
          filter_monument,
        )
      }
      return myOptions
    },

    hasMonumentCheckBox() {
      let myResult = false
      if (
        typeof this.$store.state.Properties.formPropertyAddress === 'object' &&
        typeof this.$store.state.Properties.formPropertyAddress.federal_state_uid !== 'undefined'
      ) {
        myResult = evaluateHasMonumentIdentification(this.$store.state.Properties.formPropertyAddress.federal_state_uid)
      }
      return myResult
    },

    setInfoTooltip(val) {
      this.changeInfoTooltip(val)
    },

    initForm() {
      for (const [key, value] of Object.entries(this.$store.state.Properties.formPropertyBenefits)) {
        if (typeof value !== 'undefined') {
          this.form[key] = JSON.parse(JSON.stringify(value))
        }
      }
    },

    saveForm() {
      let myKeys = []
      this.resetFormPropertyBenefits()
      myKeys = Object.keys(this.$store.state.Properties.formPropertyBenefits)
      if (typeof myKeys === 'object') {
        myKeys.forEach(function (key) {
          if (typeof this.form[key] !== 'undefined') {
            this.$store.state.Properties.formPropertyBenefits[key] = JSON.parse(JSON.stringify(this.form[key]))
          }
        }, this)
      }
    },

    cancel() {
      this.$emit('cancelPropVisible')
    },

    callForPrecedingStep() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForPrecedingStep')
      } else {
        this.$emit('callForPrecedingStep')
      }
    },

    callForNextStep() {
      if (this.isPropertyReadOnly) {
        this.$emit('callForNextStep')
      } else {
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForNextStep')
      }
    },

    save() {
      if (this.isPropertyReadOnly) {
        return
      }

      if (this.form.validate().errors().any()) {
        this.$store.dispatch('showToast', {
          message: this.$t('newProperty.form_validation_text'),
          toastType: 'error',
        })
        return
      }
      this.saveForm()
      this.$emit('callForSaveProperty')
    },

    invokeReference(ref) {
      this.$nextTick(() => {
        if (!this.$refs[ref]) return
        if (
          typeof this.$refs !== 'undefined' &&
          typeof this.$refs[ref] !== 'undefined' &&
          typeof this.$refs[ref].focus === 'function'
        ) {
          this.$refs[ref].focus()
        }
      })
    },

    isOptionSelected(checkMe, zeroValueIsASelection) {
      let myResult = false
      let myNumber

      if (typeof zeroValueIsASelection !== 'boolean') {
        zeroValueIsASelection = false
      }

      if (typeof checkMe === 'string' || typeof checkMe === 'number') {
        if (typeof checkMe === 'string') {
          myNumber = parseInt(checkMe)
        } else {
          myNumber = checkMe
        }

        if (!isNaN(myNumber)) {
          if (myNumber === 0) {
            myResult = zeroValueIsASelection
          } else {
            myResult = true
          }
        }
      }
      return myResult
    },

    addUnbuiltRealEstateExemption() {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyBenefits.unbuilt_real_estate_exemptions[0]))
      this.form.unbuilt_real_estate_exemptions.push(myObject)
    },

    removeUnbuiltRealEstateExemption(index) {
      if (typeof index === 'number' && index >= 0 && index < this.form.unbuilt_real_estate_exemptions.length) {
        this.form.unbuilt_real_estate_exemptions.splice(index, 1)
      }
    },

    addUseOfDefinableParts() {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyBenefits.use_of_definable_parts[0]))
      this.form.use_of_definable_parts.push(myObject)
    },

    removeUseOfDefinableParts(index) {
      if (typeof index === 'number' && index >= 0 && index < this.form.use_of_definable_parts.length) {
        this.form.use_of_definable_parts.splice(index, 1)
      }
    },

    addTaxBreakCondition() {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyBenefits.tax_break_conditions[0]))
      this.form.tax_break_conditions.push(myObject)
    },

    removeTaxBreakCondition(index) {
      if (typeof index === 'number' && index >= 0 && index < this.form.tax_break_conditions.length) {
        this.form.tax_break_conditions.splice(index, 1)
      }
    },
    onlyNumber($event) {
      let code = $event.code ? $event.code : $event.which
      let key = $event.key
      if (code === 'KeyE' || key === 'e' || key === 'E') {
        $event.preventDefault()
      }
    },
  },
}
</script>

<style type="text/css">
textarea.textarea-field {
  border: 1px solid #bbbbbb;
  height: 150px;
  width: 100%;
}

.Step_SectionPropertyBenefits .tooltip-icon img {
  height: 18px;
  margin-left: 5px;
}

label.label-title.tooltip-icon {
  position: relative;
}

.ToolTipTextBox {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: none;
  max-width: 600px;
  padding: 20px;
  padding: 15px !important;
  position: absolute;
  right: 0;
  top: 35px;
  transform: translate3d(0px, 40px, 0px) !important;
  z-index: 1;
}

/*.Step_SectionPropertyBenefits .tooltip-icon img:hover + .ToolTipTextBox {
    display: block;
}*/
</style>
