<template>
  <div class="header">
    <div
      class="main_top"
      :style="[primaryColor ? { background: primaryColor } : '']"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-6 text-left">
            <h4
              class="top-title"
              style="text-align: left"
            >
              <a href="#dashboard">{{ $t('header.title') }}</a>
            </h4>
          </div>
          <div
            class="col-md-6 col-sm-6 text-right"
            v-if="profilemenu"
          >
            <div class="user-profile">
              <div
                class="notify_list"
                v-if="notify"
              >
                <div class="dropdown">
                  <button
                    class="dropdown-toggle"
                    type="button"
                    id="noti_1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span class="noti_number">3</span>
                    <i class="bi bi-bell"></i>
                  </button>
                  <div
                    class="noti_drop dropdown-menu"
                    aria-labelledby="noti_1"
                  >
                    <label>{{ $t('header.notifications') }}</label>
                    <ul class="not_ul">
                      <li class="act_li li_grn">
                        <div class="l_icon"><i class="bi bi-check2-circle"></i></div>
                        <div class="l_text">
                          <label>Bescheid geprüft & erledigt</label>
                          <span>01.11.2022 - Schulstraße 1, 25482 Appen</span>
                        </div>
                      </li>
                      <li class="act_li">
                        <div class="l_icon"><i class="bi bi-house-door"></i></div>
                        <div class="l_text">
                          <label>Ihre Feststellungserklärung wurde an das Finanzamt übermittelt</label>
                          <span>01.11.2022 - Schulstraße 1, 25482 Appen</span>
                        </div>
                      </li>
                      <li class="act_li">
                        <div class="l_icon"><i class="bi bi-journal-check"></i></div>
                        <div class="l_text">
                          <label>Ihre Feststellungserklärung wurde an das Finanzamt übermittelt</label>
                          <span>01.11.2022 - Schulstraße 1, 25482 Appen</span>
                        </div>
                      </li>
                      <li class="act_li li_grn">
                        <div class="l_icon"><i class="bi bi-file-earmark-text"></i></div>
                        <div class="l_text">
                          <label>Bitte prüfen Sie die Grundstücksdaten</label>
                          <span>01.11.2022 - Schulstraße 1, 25482 Appen</span>
                        </div>
                      </li>
                      <li class="act_li">
                        <div class="l_icon"><i class="bi bi-file-earmark-text"></i></div>
                        <div class="l_text">
                          <label>Bescheid geprüft & erledigt</label>
                          <span>01.11.2022 - Schulstraße 1, 25482 Appen</span>
                        </div>
                      </li>
                      <li class="act_li li_grn">
                        <div class="l_icon"><i class="bi bi-file-earmark-text"></i></div>
                        <div class="l_text">
                          <label>Bitte prüfen Sie die Grundstücksdaten</label>
                          <span>01.11.2022 - Schulstraße 1, 25482 Appen</span>
                        </div>
                      </li>
                      <li class="act_li">
                        <div class="l_icon"><i class="bi bi-file-earmark-text"></i></div>
                        <div class="l_text">
                          <label>Bescheid geprüft & erledigt</label>
                          <span>01.11.2022 - Schulstraße 1, 25482 Appen</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="select-user">
                <h4
                  class="top-title"
                  style="text-align: right"
                >
                  <span
                    v-if="clientCompanyName !== ''"
                    class="top-title opacity-75"
                    >{{ clientCompanyName }} /
                  </span>
                  <span class="top-title">{{ clientName }}</span>
                </h4>
                <div class="top-notify_list notify_list">
                  <span class="user-icon-img">
                    <i class="bi bi-person-circle person"></i>
                  </span>
                  <button
                    class="dropdown-toggle"
                    type="button"
                    id="noti_11"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="bi bi-chevron-down chevron"></i>
                  </button>

                  <div
                    class="dropdown-menu dropdown-menu_new"
                    aria-labelledby="noti_11"
                  >
                    <ul class="user-selector">
                      <!-- <li><a href="#mydata"  >{{$t('header.mydata')}}</a></li> -->
                      <li>
                        <router-link to="/mydata">{{ $t('header.mydata') }}</router-link>
                      </li>
                      <li v-if="userMgmtMenu">
                        <router-link to="/benutzerverwaltung">{{ $t('header.useradmin') }}</router-link>
                      </li>
                      <li>
                        <a
                          class="logout"
                          @click="doLogout"
                          >{{ $t('header.signout') }}</a
                        >
                      </li>
                      <li class="line default-cursor">
                        <hr />
                      </li>
                      <li class="sub default-cursor mb-10">{{ getVersion }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
//import login from "../store/modules/login"

export default {
  name: 'Header',
  data() {
    return {
      clientName: '',
      clientCompanyName: '',
      menu: false,
      primaryColor: '',
      fontColor: '',
      notify: false,
    }
  },
  created() {
    this.clientName = (this.getCurrentUser.firstName + ' ' + this.getCurrentUser.lastName).trim()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : ''
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.fontColor : ''

    if (
      typeof this.getCurrentUser.client === 'object' &&
      typeof this.getCurrentUser.client.clientType === 'string' &&
      typeof this.getCurrentUser.client.firstName === 'string' &&
      typeof this.getCurrentUser.client.lastName === 'string' &&
      typeof this.getCurrentUser.client.companyName === 'string'
    ) {
      this.clientCompanyName = this.$t('header.client') + ': '
      if (this.getCurrentUser.client.clientType === 'private') {
        let rawName = (
          this.getCurrentUser.client.firstName.trim() +
          ' ' +
          this.getCurrentUser.client.lastName.trim()
        ).trim()
        if (rawName !== this.clientName) {
          this.clientCompanyName += rawName
        } else {
          this.clientCompanyName = ''
        }
      } else {
        if (this.getCurrentUser.client.companyName.trim() !== this.clientName) {
          this.clientCompanyName += this.getCurrentUser.client.companyName.trim()
        } else {
          this.clientCompanyName = ''
        }
      }
    }
  },

  computed: {
    ...mapGetters(['getCurrentUser', 'getAccountInfo', 'getClientData']),
    ...mapMutations(['setUserName']),

    profilemenu() {
      if (this.$route.name == 'Einladung') {
        return false
      }
      return true
    },

    userMgmtMenu() {
      return this.getCurrentUser.permissions && Object.values(this.getCurrentUser.permissions).includes('MANAGE_USERS')
    },

    getVersion() {
      let version = 'v' + process.env.PACKAGE_VERSION
      if (process.env.PACKAGE_REV != null) {
        version = version + '-' + process.env.PACKAGE_REV.substring(0, 8)
      }
      return version
    },
  },
  watch: {},

  methods: {
    ...mapActions(['logout']),
    menutoggle() {
      this.menu = !this.menu
    },
    doLogout() {
      localStorage.removeItem('client_logged_in')
      this.logout()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
.logout:hover {
  text-decoration: underline;
}

ul.user-selector {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0;
  max-width: 180px;
  padding: 10px;
  position: absolute;
  right: 0;
  text-align: left;
  width: 100%;
  z-index: 10;
}

ul.user-selector li {
  font-size: 14px;
  list-style: none;
  padding: 5px 0;
}

ul.user-selector li a {
  font-size: 14px;
  list-style: none;
  text-decoration: none;
}

span.user-icon-img {
  padding-right: 10px;
}

.select-user {
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-left: 5px;
}

.select-user span > i.chevron {
  font-size: 15px;
  font-weight: 800;
}

.select-user span > i.person {
  font-size: 20px;
  padding-left: 10px;
}

.select-user span > i {
  color: #fff;
}

.user-profile {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.main_top {
  background: #229d56;
  /* height: 45px; */
}

.main_top img {
  margin: 9px 0;
  max-width: 180px;
  width: 100%;
}

h4.top-title {
  color: #fff !important;
  font-size: 15px;
  font-weight: 400;
  line-height: 35px;
  margin: 0;
}

span.top-title {
  color: #fff !important;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

.top-title a {
  color: white;
  text-decoration: none;
}

.line {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  color: #dfdfdf;
  margin-bottom: -10px;
  margin-top: -10px;
  padding: 2px 2px 2px 2px;
}

.sub {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  color: #aeaeae;
  font-family: 'Segoe UI', sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

.default-cursor {
  cursor: default;
}
.top-notify_list {
  margin-top: 5px;
  min-width: 80px;
  text-align: right;
}
.header {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.notify_list button {
  margin-right: 0;
  padding-right: 0;
}
</style>
