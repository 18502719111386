<template>
  <!-- Property  Start -->
  <section class="login_form section property_change-sec">
    <div class="container">
      <div
        v-show="step === 1"
        class="row"
      >
        <div class="col-md-12 text-left">
          <div class="sec-heading">
            <h4 class="heading_title heading_title_left dynamicColor frm_margin_on_mobile">
              {{ $t('property_change.title') }}
            </h4>
            <p class="p-content dynamicColor frm_margin_on_mobile">
              {{ $t('property_change.info') }}<br />
              <br />
              {{ $t('property_change.info1') }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-show="step === 1"
        class="row"
      >
        <div
          class="col-md-12 mt-4"
          v-show="askForMfaCode"
        >
          <label>{{ $t('property_change.mfa_code_sent') }}</label>
          <div class="w-100 mt-2">
            <input
              type="text"
              autocomplete="off"
              v-model="signatureMfaCode"
              placeholder=""
              ref="signature-mfa-input"
              class="input-field w-25"
              @keyup.enter="validateMfa"
            />
          </div>
          <button
            class="step-button-style mt-2"
            @click="validateMfa"
          >
            {{ $t('property_change.buttons.continue') }}
          </button>
        </div>
        <div
          class="col-md-12 mt-4"
          v-show="!askForMfaCode"
        >
          <button
            class="step-button-style"
            @click="startRelease"
          >
            {{ $t('property_change.buttons.start') }}
          </button>
          <button
            type="button"
            @click="goToHome"
            class="step-button-style border-button-style mx-3"
          >
            {{ $t('general.buttons.cancel') }}
          </button>
        </div>
      </div>
      <div
        v-show="step === 2"
        class="row"
      >
        <div class="row">
          <div class="col-md-12 text-left">
            <div class="sec-heading">
              <h4
                class="heading_title heading_title_left dynamicColor"
                v-if="!propertyNoChange"
              >
                {{ $t('property_change.title_prefix') }}{{ clientName }}{{ $t('property_change.title_postfix') }}
              </h4>
              <p
                class="p-content dynamicColor"
                v-if="!propertyNoChange"
              >
                {{ $t('property_change.subline1') }}
              </p>
              <p
                class="p-content dynamicColor"
                v-if="propertyNoChange"
              >
                {{ $t('property_change.property_change_status_no1') }}<br />
                <br />
                {{ $t('property_change.property_change_status_no2') }}<br />
                <br />
                {{ $t('property_change.property_change_status_no3') }}
              </p>
            </div>
          </div>
        </div>

        <!-- Standard view if properties are available -->
        <div
          class="dashbord-content topspace60 frm_margin_on_mobile"
          v-show="hasProperties && !propertyNoChange"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="column-right">
                <div class="Search_Titzle Search_Titzle02 Titzle02_mob">
                  <h4 class="mobilehide">{{ $t('dashboard.plots') }}</h4>
                  <div class="btn_row">
                    <input
                      type="text"
                      autocomplete="off"
                      class="input-search"
                      v-model.trim="search"
                      @keyup.enter="filterAllproperty"
                      placeholder="Suchen..."
                    />
                  </div>
                </div>
              </div>
              <div v-if="hasProperties">
                <div class="new-notifications notify-mob">
                  <table class="style_min mobilehide table">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t('property_change.property_number') }}</th>
                        <th scope="col">{{ $t('property_change.property_type') }}</th>
                        <th scope="col">{{ $t('property_change.property_name') }}</th>
                        <th scope="col">{{ $t('property_change.property_has_change') }}</th>
                        <th scope="col">{{ $t('property_change.property_change_status') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="property in allProperties"
                        :key="property.prim_uid"
                      >
                        <td>{{ property.uid }}</td>
                        <td>
                          <span v-if="property.property_type != ''">{{
                            $t('property_type.' + property.property_type)
                          }}</span>
                        </td>
                        <td>{{ property.name }}</td>
                        <td>
                          <select
                            :disabled="property.change_status == 2"
                            v-model="property.hasChanges"
                            @change="onPropertyHasChange(property)"
                          >
                            <option
                              v-for="option in propertyHasChangeOptions"
                              v-bind:key="option.value"
                              :value="option.value"
                              :disabled="option.disabled"
                            >
                              {{ $t('property_change.property_has_change_options.' + option.value) }}
                            </option>
                          </select>
                        </td>
                        <td>
                          <span
                            v-if="property.hasChanges === 1"
                            class="cursor-pointer"
                          >
                            <p
                              class="px-1.5 py-1.5 text-center text-white"
                              :class="$t('property_change.property_has_change_options_color.' + property.change_status)"
                              @click="handleColClick(property)"
                            >
                              {{ $t('property_change.property_change_status_option.' + property.change_status) }}
                            </p>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalDialog
      v-show="isModalChangesQueryVisible"
      @close="handleModalChangeQueryClose"
    >
      <template v-slot:body>
        <div class="popup_title b_btm">
          <h2>{{ $t('property_change.modal_header_text') }}</h2>
        </div>
        <div class="textfileformating textfileformating02">
          <div class="filemin_cont2 cancel-popup">
            <textarea
              type="text"
              autocomplete="off"
              v-model="statusChangeQueryNote"
              placeholder=""
              class="textarea-field"
              rows="22"
            />
          </div>
          <div class="btn_170 btn_cancel btn_cancel_indent save_confirmation">
            <button
              class="border-button-style"
              @click="isModalChangesQueryVisible = false"
            >
              {{ $t('general.buttons.cancel') }}
            </button>
            <button
              class="modal-button"
              @click="submitChangeQueryStatus(3)"
            >
              {{ $t('general.buttons.save_draft') }}
            </button>
            <button
              class="modal-button padding-right-zero"
              @click="submitChangeQueryStatus(2)"
            >
              {{ $t('general.buttons.send') }}
            </button>
          </div>
        </div>
      </template>
    </ModalDialog>
  </section>
  <!-- Property  End ///////////// -->
</template>

<script type="text/javascript">
import ModalDialog from '@/components/ModalDialog.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import apiClient from '@/Api'

export default {
  name: 'PropertyChangeSection',
  components: {
    ModalDialog,
  },
  data() {
    return {
      hasProperties: true,
      allProperties: [],
      clientName: '',
      propertyHasChangeOptions: [
        {
          value: '-',
          change_status: [1],
          disabled: true,
        },
        {
          value: 0,
          change_status: [4],
          disabled: false,
        },
        {
          value: 1,
          change_status: [2, 3],
          disabled: false,
        },
      ],
      isModalChangesQueryVisible: false,
      statusChangeQuery: '0',
      statusChangeQueryOptions: [],
      statusChangeQueryNote: '',
      isReadOnly: false,
      propertyUid: 0,
      step: 1,
      code: '',
      askForMfaCode: false,
      signatureMfaCode: '',
      mfaValidated: false,
      codeValidated: false,
      propertyNoChange: false,
      userInfo: [],
      search: '',
    }
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getArrProperties',
      'getCountries',
      'getDeleteProp',
      'getArrDeclarations',
      'getDashboardListLayout',
      'getDashboardPropertySearch',
      'getDashboardSelectedFilterKey',
    ]),
  },
  mounted() {
    if (this.mfaValidated) {
      this.code = this.$route.query.code
      this.fetchProperties()
    } else {
      this.code = ''
      if (this.$route.query.code) {
        this.showPageLoader()

        apiClient.post('propertyChange/validateCode', { code: this.$route.query.code }).then((response) => {
          if (response.data.success && response.data.userInfo !== 'undefined') {
            this.code = this.$route.query.code
            this.setInvitationInfo(response.data.userInfo)

            if (typeof response.data.codeValidated !== 'undefined') {
              this.codeValidated = response.data.codeValidated
            }

            if (!this.mfaValidated && typeof response.data.mfaValidated !== 'undefined') {
              this.mfaValidated = response.data.mfaValidated
            }

            if (typeof response.data.propertyNoChange !== 'undefined') {
              this.propertyNoChange = response.data.propertyNoChange
            }

            if (this.codeValidated) {
              this.step = 1
              this.startRelease()
            }
          } else {
            this.hidePageLoader()

            if (response.data.message) {
              this.showToast({
                message: response.data.message,
                toastType: 'error',
              })
            }

            this.$router.push('/')
          }
        })
      } else {
        this.$router.push('/dashboard')
      }
    }
  },
  methods: {
    ...mapActions([
      'apiGetProperties',
      'shareProperty',
      'countries',
      'deleteProperty',
      'showToast',
      'apiDuplicateProperty',
      'dataRequestCompleted',
      'apiGetCompletedDeclarations',
    ]),
    ...mapMutations([
      'showPageLoader',
      'hidePageLoader',
      'setInvitationInfo',
      'setDashboardListLayout',
      'setDashboardPropertySearch',
      'setDashboardSelectedFilterKey',
    ]),
    ...mapGetters(['getInvitationInfo']),
    fetchProperties() {
      this.showPageLoader()

      let payload = {
        code: this.code,
        search: this.search,
      }

      apiClient.post('propertyChange/get', payload).then((response) => {
        if (response.data.success && response.data.propertyData) {
          this.step = 2

          if (response.data.propertyData.total_records > 0) {
            this.clientName = response.data.propertyData.records[0].client_name_asc
          }

          this.allProperties = JSON.parse(JSON.stringify(response.data.propertyData.records))

          Object.entries(this.allProperties).forEach((entry) => {
            var [, property] = entry

            property.hasChanges = this.hasPropertyChanges(property)

            if (this.propertyNoChange === true) {
              this.submitChangeQueryStatus(4, property.prim_uid)
            }
          })

          this.hidePageLoader()
        } else if (response.data.message) {
          this.hidePageLoader()

          this.showToast({
            message: response.data.message,
            toastType: 'error',
          })
        }
      })
    },
    hasPropertyChanges(property) {
      let hasChanges = 0

      this.propertyHasChangeOptions.forEach((option) => {
        if (!option.change_status.includes(parseInt(property.change_status))) {
          return
        }

        hasChanges = option.value
      })

      return hasChanges
    },
    propertyHasAddress(property) {
      let myResult = false
      if (typeof property === 'object') {
        myResult =
          (typeof property.street === 'string' && property.street.trim() !== '') ||
          (typeof property.house_number === 'string' && property.house_number.trim() !== '') ||
          (typeof property.zip === 'string' && property.zip.trim() !== '') ||
          (typeof property.city === 'string' && property.city.trim() !== '')
      }

      return myResult
    },
    propertyAddressLine1(property) {
      let separator = ', '
      let myAddressLine1 = ''
      let myStreet = ''
      let myHouseNumber = ''
      let myZip = ''
      let myStreetComplete = ''

      if (this.propertyHasAddress(property)) {
        if (typeof property.street === 'string' && property.street.trim() !== '') {
          myStreet = property.street.trim()
        }

        if (typeof property.house_number === 'string' && property.house_number.trim() !== '') {
          myHouseNumber = property.house_number.trim()
        }

        if (typeof property.zip === 'string' && property.zip.trim() !== '') {
          myZip = property.zip.trim()
        }

        myStreetComplete = (myStreet + ' ' + myHouseNumber).trim()

        if (myStreetComplete === '' || myZip === '') {
          separator = ''
        }

        myAddressLine1 = myStreetComplete + separator + myZip
      }

      return myAddressLine1
    },
    propertyAddressLine2(property, separator) {
      let myAddressLine2 = ''
      let myCity = ''
      let myFederalStateName = ''
      let mySeparator = ' '

      if (typeof property === 'object') {
        if (typeof property.city === 'string' && property.city.trim() !== '') {
          myCity = property.city.trim()
        }

        if (typeof property.federal_state_name === 'string' && property.federal_state_name.trim() !== '') {
          myFederalStateName = property.federal_state_name.trim()
        }

        if (typeof separator === 'string') {
          mySeparator = separator
        }

        if (myCity === '' || myFederalStateName === '') {
          mySeparator = ''
        }

        myAddressLine2 = (myCity + mySeparator + myFederalStateName).trim()
      }

      return myAddressLine2
    },
    propertyAddressLine(property) {
      return (this.propertyAddressLine1(property) + ' ' + this.propertyAddressLine2(property, ', ')).trim()
    },
    goToHome() {
      this.$router.push('/dashboard')
    },
    validateMfa() {
      if (this.code && !this.mfaValidated) {
        this.showPageLoader()

        apiClient
          .post('propertyChange/validateMfaCode', {
            code: this.code,
            mfaCode: this.signatureMfaCode,
          })
          .then((response) => {
            if (response.data.success) {
              if (typeof response.data.mfaValidated !== 'undefined') {
                this.mfaValidated = response.data.mfaValidated
              }
              if (this.mfaValidated) {
                this.step = 2
                this.fetchProperties()
              } else {
                this.triggerApprovalMfa()
              }
            } else {
              this.hidePageLoader()

              if (response.data.message) {
                this.showToast({
                  message: response.data.message,
                  toastType: 'error',
                })
              }
            }
          })
      }
    },
    triggerApprovalMfa() {
      this.showPageLoader()

      apiClient.post('propertyChange/sendMfaCode', { code: this.code }).then((response) => {
        this.hidePageLoader()

        if (response.data.success) {
          this.askForMfaCode = true
          this.$nextTick(() => this.$refs['signature-mfa-input'].focus())
        } else {
          if (response.data.message) {
            this.showToast({
              message: response.data.message,
              toastType: 'error',
            })
          }
        }
      })
    },
    startRelease() {
      if (this.code && !this.mfaValidated) {
        this.triggerApprovalMfa()
        return
      }
      this.step = 2
      this.fetchProperties()
    },
    onPropertyHasChange(property) {
      if (property.hasChanges === 0) {
        this.submitChangeQueryStatus(4, property.prim_uid)
        return
      }

      if (property.hasChanges === 1 || property.change_status == 4) {
        property.change_status = '1'
        return
      }
    },
    handleColClick(property) {
      this.statusChangeQuery = parseInt(property.change_status)
      if (this.statusChangeQuery > 0 && parseInt(property.prim_uid) > 0) {
        if (this.statusChangeQuery == 1 || this.statusChangeQuery == 3) {
          this.propertyUid = property.prim_uid
          this.statusChangeQueryOptions = property.change_status
          this.statusChangeQueryNote = property.change_note
          this.isModalChangesQueryVisible = true
        } else {
          this.isModalChangesQueryVisible = false
        }
      }
    },
    handleModalChangeQueryClose() {
      this.isModalChangesQueryVisible = false
    },
    submitChangeQueryStatus(statusChangeQuery, propertyUid) {
      this.showPageLoader()

      if (typeof propertyUid === 'undefined') {
        propertyUid = this.propertyUid
      }

      let payload = {
        code: this.code,
        property_uid: propertyUid,
        change_status: statusChangeQuery,
        change_note: this.statusChangeQueryNote,
      }

      apiClient.post('propertyChange/saveChangeStatus', payload).then((response) => {
        if (!response.data.success && response.data.message) {
          this.showToast({
            message: response.data.message,
            toastType: 'error',
          })
        }

        this.isModalChangesQueryVisible = false
        this.step = 2

        this.fetchProperties()
        this.hidePageLoader()

        this.statusChangeQueryNote = ''
      })
    },
    filterAllproperty() {
      this.fetchProperties()
    },
  },
}
</script>
<style scoped lang="css">
@import './assets/custom.css';
@import './assets/form_style.css';

:root {
  --api-background: #43ac6f;
}

.isDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.topspace60 {
  margin-top: 20px;
}

h4.heading_title {
  padding-bottom: 20px;
}

a.button-style-new,
button.button-style-new {
  background: #229d56;
  border: 0;
  border-radius: 0;
  color: #fff;
  display: block;
  height: 40px;
  line-height: 40px;
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.modal-backdrop .modal {
  border-top: 5px solid #229d56;
}

.__Modal_Popup .modal {
  height: auto !important;
}

.popup_title h2 {
  color: #333;
  font-size: 26px;
}

.tab-border-home p {
  font-size: 16px;
}

.tab-border-home {
  border: none;
}

.select2_input a button:hover {
  background: #229d56;
  color: #fff;
  font-weight: 700;
}

.select2_input a button,
.select2_input button {
  background: #fff;
  border: 1px solid #ccc;
  font-size: 15px;
  margin: 5px 5px 5px;
  padding: 10px 15px;
}

.select2_input {
  text-align: left;
}

.select2_input + .button-style01:hover {
  background: #ccc;
  border-color: #ccc;
}

.select2_input + .button-style01 {
  background: none;
  border-color: #333;
  color: #333;
  line-height: 20px;
  margin-left: auto;
  margin-top: 30px;
  padding: 10px 0;
  width: 280px;
}

.tab-border-home.tab-top-height {
  height: auto;
}

.tab-border-home.tab-top-height.textformatting {
  padding: 0 0 0 0 !important;
}

.btn_170 {
  display: flex;
  justify-content: flex-end;
  margin-left: auto !important;
  margin-right: -15px;
}

button.button-style:first-child {
  border: none !important;
}

button.button-style.red_btn:hover {
  background: #d55959 !important;
  border-color: #d55959;
  color: #fff;
  filter: brightness(0.8);
}

button.button-style.btn_line:hover {
  background: #bbbbbb;
  border-color: #bbb;
}

.Search_Titzle.Search_Titzle02.Titzle02_mob {
  padding-left: 0;
}

section.section {
  margin-bottom: 100px;
  min-height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Search_Titzle input[type='text'] {
    margin-left: 20px !important;
  }

  .footer-space {
    min-height: 100px !important;
  }

  .icon-div {
    width: 30% !important;
  }

  .new-notifications .col-lg-6 {
    width: 100%;
  }
}

@media only screen and (min-width: 100px) and (max-width: 768px) {
  .icon-div {
    width: 20%;
  }

  .icon-cnt-div {
    width: 80%;
  }

  .footer-space {
    min-height: 100px !important;
  }

  .Search_Titzle input[type='text'] {
    margin-left: 5px !important;
  }

  .mb_300 {
    max-width: 100% !important;
  }

  .mb_300 img {
    margin-right: 20px !important;
    max-width: 22px !important;
  }
}

.spiner_center_over.dashbord-spiner {
  background: #ffffff;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 11;
}

.dashbord-spiner .spiner_center {
  height: auto;
  left: 50%;
  margin: 0 auto;
  position: absolute !important;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.new-notifications {
  position: relative;
}

.dashbord-spiner:before {
  display: none;
}

.emptyDashboardImage {
  max-height: 250px;
  max-width: 250px;
}

.modal-content {
  align-content: center;
  align-items: center;
  background: #f2f2f2;
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 14px;
  padding: 5px;
}

.modal-button-row {
  align-items: stretch;
  border: none;
  display: flex;
  font-size: 14px;
  padding: 5px;
}

.modal-button-left {
  align-self: flex-start;
  margin-left: 0 !important;
}

.modal-button-right {
  margin-left: auto;
}

.modal-content-div {
  padding: 5px;
}

.modal-content-headline {
  font-weight: 600;
}

.modal-icon {
  padding: 10px;
}

.modal-img {
  height: 18px;
  width: 18px;
}

.modal-button {
  background: none;
  border-color: #333;
  color: #333;
  line-height: 20px;
  margin-left: auto;
  margin-top: 30px;
  padding: 10px 0;
  width: 280px;
}

.modal-button:hover {
  background: #ccc;
  border-color: #ccc;
  color: #333;
}

button.btnactive {
  border: 1px solid var(--api-background);
}

.btn_cancel button.border-button-style {
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 170px;
  width: 100%;
}

.check-icon-text {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  font-weight: 400;
  padding-top: 5px;
}

.info_detail_set {
  display: flex;
  width: 100%;
  grid-gap: 50px;
  background-color: #f2f2f2;
  padding: 10px;
}

.property-info {
  align-items: center;
  display: flex;
  margin-top: 15px;
  text-align: left;
  width: 100%;
}

.property-info img {
  max-width: 20px;
  width: 100%;
}

.property-info .heading {
  font-weight: 600;
}

.property-info .info_detail_set .icon-text:last-child {
  border-right: 0;
}

.property-info .property-name {
  white-space: nowrap;
}

.property-info .info_detail_set .icon-text:first-child {
  width: 400px;
}

.button {
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  margin-top: 10px;
  padding: 5px 15px;
  width: auto;
}

.btn-cancel {
  background: #fff;
  border: 1px solid #000;
  color: #000;
  padding-bottom: 4px;
  padding-top: 4px;
}

.time-set {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 15px 0 0;
}

.time-set img {
  width: 20px;
}

.time-set span {
  color: #4b4b4b;
  font-size: 15px;
  padding-left: 10px;
}

.rectangle {
  background-color: #797979;
  color: #fff;
  font-weight: 600;
  height: 56px;
  padding: 2px 2px 2px 2px;
  width: 100%;
}

.icon img {
  cursor: pointer;
  height: 24px;
  margin-left: 20px;
  width: 24px;
}

.icon {
  padding-right: 30px;
}

.pdf-header {
  position: sticky;
  top: 45px;
  z-index: 100;
}

.pdf-container {
  background: #f4f4f4;
  border: 2px solid #c2cad8;
  padding: 10px 20px;
  width: 100%;
}

.pdf-container .pdf-page {
  margin-top: 10px;
  position: relative;
}

:deep(.modal_large .modal) {
  height: auto;
  max-width: 50% !important;
}

@media only screen and (min-width: 100px) and (max-width: 767px) {
  :deep(.modal_large .modal) {
    height: auto;
    max-width: 100% !important;
  }

  .col_view {
    align-items: end !important;
    display: flex !important;
    flex-direction: column;
    justify-content: end;
    /*justify-content-between align-items-center*/
  }

  .col_view_input {
    flex: 1 1 auto !important;
    margin-left: 1rem !important;
    margin-right: 0 !important;
  }

  .button {
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    margin-top: 10px !important;
    padding: 5px 15px;
    width: 55%;
  }
}

.tab-content {
  background-color: #ffffff;
  border: 2px solid #d0d0d0;
  border-radius: 5px;
  box-sizing: border-box;
  height: 300px;
  padding: 10px;
  width: 100%;
}

.uploader-tab {
  height: 300px;
}

.tab-container {
  padding: 10px 15px;
}

.Modal_content_Area input {
  height: auto !important;
  padding: 5px 2px;
}

.newsignature {
  height: 85%;
}

.signature-container {
  margin-left: -10px;
}

span.clear-link {
  color: #4b4b4b !important;
  cursor: pointer;
  float: right;
  text-decoration: underline;
}

.txt-signature {
  max-height: 100%;
}

.col_view {
  align-items: center;
  display: flex;
  justify-content: space-between;
  /*justify-content-between align-items-center*/
}

.col_view_input {
  margin-left: 1rem;
  margin-right: 1rem;
  width: 68%;
}
.col_view_input input {
  height: 40px !important;
}
.w-auto {
  max-width: unset;
}
.document_icon {
  margin-right: 10px;
  width: 20px;
}

.orange_data_query {
  background-color: rgba(196, 98, 33, 0.996);
}
.yellow_data_query {
  background-color: rgba(195, 183, 14, 0.996);
}
.green_data_query {
  background-color: rgba(34, 157, 86, 0.996);
}
textarea.textarea-field {
  border: 1px solid #bbbbbb;
  height: auto;
  width: 100%;
}
</style>
