<template>
  <section
    class="Step_SectionPropertyDocument"
    :class="{ active: active }"
    id="propertyDokumenteTemplate"
  >
    <fieldset>
      <div class="Step_SectionPropertyDocument">
        <h2 class="step_heading">{{ $t('newProperty.Dokumente.headline') }}</h2>
        <hr class="subheading_devide dk_subheading_devide" />
        <div class="Search_Titzle Search_Titzle02 Titzle02_mob">
          <div class="action_row dk_search_row">
            <div class="dk_search">
              <div class="action_row mobilehide dk_search_icon">
                <a
                  @click="showSelectedDoc"
                  :class="{ isDisabled: selectedDocs.length !== 1 }"
                >
                  <Svgall
                    class="list_edit_delete"
                    :name="'view_upload'"
                    :color="primaryColor"
                  />
                  {{ $t('newProperty.Dokumente.document_anzeigen') }}
                </a>
                <a
                  @click="uploadDoc"
                  :class="{ isDisabled: !canEditProperty }"
                >
                  <Svgall
                    class="list_edit_delete"
                    :name="'upload_icon'"
                    :color="primaryColor"
                  />
                  {{ $t('newProperty.Dokumente.document_ablegen') }}
                </a>
                <a
                  @click="openConfirmDeleteDialog"
                  :class="{ isDisabled: isDeleteButtonDisabled }"
                >
                  <Svgall
                    class="list_edit_delete"
                    :name="'delete'"
                    :color="primaryColor"
                  />
                  {{ $t('newProperty.Dokumente.document_delete') }}
                </a>
              </div>
              <div class="dk_search_input">
                <input
                  type="text"
                  class="input-search"
                  v-model.trim="searchTxt"
                  placeholder=""
                  @keypress.enter="loadDocuments"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="new-notifications notify-mob dk_desktop_view">
          <Spinner
            v-show="show_spiner"
            class="dashbord-spiner"
          />
          <DataTable
            :value="documentList"
            dataKey="id"
            :loading="loadingData"
            :paginator="true"
            loading-icon="pi pi-spinner text-primary"
            :rows="perPage"
            :rowsPerPageOptions="rowsPerPageOptions"
            :totalRecords="totalRecords"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
            :currentPageReportTemplate="
              $t('general.grid.page_info1') +
              ' {first}. ' +
              $t('general.grid.page_info2') +
              ' {last}. ' +
              $t('general.grid.page_info3') +
              ' {totalRecords} ' +
              $t('general.grid.page_info4')
            "
            v-model:selection="selectedDocs"
          >
            <template #empty>
              <div class="-ml-3">{{ $t('general.grid.no_records_found') }}</div>
            </template>
            <Column selectionMode="multiple"></Column>
            <Column
              field="filename"
              :header="$t('newProperty.Dokumente.description')"
              :sortable="true"
            >
              <template #body="slotProps">
                <Svgall
                  class="list_edit_delete clickable"
                  :name="'view_upload'"
                  :color="primaryColor"
                  @click="showDocument(slotProps.data)"
                />
                <img
                  class="document_img"
                  :src="getIcon(slotProps.data.file_ext)"
                />
                <span class="checkbox_title">
                  <a
                    @click="downloadDocument(slotProps.data)"
                    class="hoverColor"
                    >{{ slotProps.data.filename }}</a
                  >
                </span>
              </template>
            </Column>
            <Column
              field="created_by"
              :header="$t('newProperty.Dokumente.created_by')"
              :sortable="true"
            ></Column>
            <Column
              field="uploaded_at"
              :header="$t('newProperty.Dokumente.upload_at')"
              :sortable="true"
            ></Column>
            <Column
              field="file_size"
              :header="$t('newProperty.Dokumente.file_size')"
              :sortable="true"
              body-style="text-align:right;"
              header-style="float:right;"
            ></Column>
          </DataTable>
        </div>
      </div>
    </fieldset>
    <div class="text_left step-button-section">
      <div>
        <button
          type="button"
          @click.prevent="cancel"
          class="step-button-style border-button-style"
        >
          {{ $t('newProperty.abort') }}
        </button>
        <button
          type="button"
          @click.prevent="save"
          class="step-button-style border-button-style"
          v-show="!isPropertyReadOnly"
        >
          {{ $t('newProperty.save') }}
        </button>
        <button
          type="button"
          v-show="back_button_activated"
          @click.prevent="callForPrecedingStep"
          class="step-button-style border-button-style"
          @focusin="setInfoTooltip(0)"
        >
          {{ $t('newProperty.back') }}
        </button>
        <button
          type="button"
          @click.prevent="callForNextStep"
          class="step-button-style"
          :style="[primaryColor ? { background: primaryColor } : '']"
          v-show="isShowNextStepButton"
        >
          {{ $t('newProperty.continue') }}
        </button>
      </div>
    </div>

    <!-- Document upload modal -->
    <Teleport to="body">
      <ModalDialog
        class="modal_wide modal_backdrop_document"
        v-show="showUploader"
        @close="closeUploader"
      >
        <template
          v-slot:body
          class="modal-inside"
        >
          <div class="popup_title b_btm">
            <h2>{{ $t('newProperty.Dokumente.document_ablegen') }}</h2>
          </div>
          <div class="textfileformating textfileformating02">
            <div class="uploadDoc_div mt-3">
              <InputUpload
                ref="uploader"
                :showDragAndDrop="true"
                :multiple="true"
                :showInstructions="false"
                accept=".pdf,.xlsx,.docx,.pptx,.txt,.xml,.jpg,.jpeg,.png,.tiff"
                :buttonStyle="[primaryColor ? { background: primaryColor } : '']"
              >
                <template #emptyBody>
                  <img
                    class="icon"
                    src="@/assets/upload-icon-new.svg"
                  />
                  <label
                    class="label_inst"
                    style="margin-top: 20px"
                    >{{ $t('newProperty.Dokumente.uploader_label') }}</label
                  >
                  <button
                    :style="[primaryColor ? { background: primaryColor } : '']"
                    @click="chooseFile"
                    class="upload-button-style mt-3"
                  >
                    {{ $t('newProperty.upload') }}
                  </button>
                </template>
              </InputUpload>
            </div>
            <div class="btn_170 btn_cancel mt-4">
              <button
                class="border-button-style"
                @click="closeUploader"
              >
                {{ $t('dashboard.abort') }}
              </button>

              <button
                class="modal-button"
                :style="[primaryColor ? { background: primaryColor } : '']"
                @click="uploadFiles"
              >
                {{ $t('dashboard.Save') }}
              </button>
            </div>
          </div>
        </template>
      </ModalDialog>
    </Teleport>

    <!-- Document view modal -->
    <Teleport to="body">
      <ModalDialog
        class="modal_wide modal_document"
        v-show="showDoc"
        @close="hideDoc"
      >
        <template
          v-slot:body
          class="modal-inside"
        >
          <div class="popup_title b_btm">
            <h2>{{ viewer.fileName }}</h2>
          </div>
          <div class="textfileformating textfileformating02">
            <div class="filemin_cont cancel-popup">
              <div v-if="viewer.fileExt === 'pdf'">
                <VuePdfEmbed
                  :source="viewer.source"
                  :disableTextLayer="true"
                  :width="800"
                />
              </div>
              <img
                v-else
                :src="viewer.source.url"
                class="image-preview"
              />
            </div>

            <div class="btn_170 btn_cancel">
              <button
                class="border-button-style"
                @click="hideDoc"
              >
                {{ $t('dashboard.abort') }}
              </button>
            </div>
          </div>
        </template>
      </ModalDialog>
    </Teleport>

    <Teleport to="body">
      <DeleteModalDialog
        v-show="isDeleteVisible"
        @close="handleModalClose"
        class="modal_wide"
      >
        <template
          v-slot:body
          class="modal-inside"
          style="max-height: 330px"
        >
          <div>
            <div class="popup_title b_btm">
              <h2>{{ $t('newProperty.Dokumente.confirm_delete_title') }}</h2>
            </div>
            <div class="tab-border-home tab-top-height textformatting">
              <div class="min_cont">
                <p>{{ $t('newProperty.Dokumente.confirm_text') }}</p>
              </div>
              <div class="benutzerverwaltung btn_170">
                <button
                  class="button-style btn_line"
                  @click="handleModalClose"
                >
                  {{ $t('newProperty.Dokumente.confirm_cancel') }}
                </button>
                <button
                  class="button-style red_btn"
                  @click="deleteSelectedDocuments"
                >
                  {{ $t('newProperty.Dokumente.confirm_delete') }}
                </button>
              </div>
            </div>
          </div>
        </template>
      </DeleteModalDialog>
    </Teleport>

    <!-- add client_comment dialog before document upload -->
    <Teleport to="body">
      <ModalDialog
        class="modal_wide modal_wide_cancel"
        v-show="addCommentVisible"
        @close="uploadFiles"
      >
        <template
          v-slot:body
          class="modal-inside"
        >
          <div class="popup_title b_btm b_btm_indent">
            <h2>{{ $t('newProperty.Dokumente.document_ablegen') }}</h2>
          </div>
          <div class="textfileformating textfileformating02">
            <div class="filemin_cont2 cancel-popup">
              <div class="modal-text">
                <span>{{ $t('newProperty.ModalClientComment.body') }} </span>
              </div>
              <div class="form-group">
                <div class="row_setp_main">
                  <div class="data-form">
                    <h2 class="subheading_title comment_field_label">
                      {{ $t('newProperty.ModalClientComment.label_client_comment') }}
                    </h2>
                    <textarea
                      type="text"
                      autocomplete="off"
                      v-model="clientComment"
                      placeholder=""
                      class="input-field comment-area"
                      ref="clientComment"
                      @keyup.esc="uploadFiles"
                      @keyup.enter="uploadFiles"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="btn_170 btn_170_right">
              <button
                class="modal-button"
                :style="[primaryColor ? { background: primaryColor } : '']"
                @click="uploadFiles"
              >
                {{ $t('newProperty.ModalClientComment.button_continue') }}
              </button>
            </div>
          </div>
        </template>
      </ModalDialog>
    </Teleport>
  </section>
</template>

<script type="text/javascript">
import Spinner from '@/components/SpinnerLoader'
import ModalDialog from '@/components/ModalDialog.vue'
import DeleteModalDialog from '@/components/DeleteModalDialog.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import apiClient from '@/Api'
import VuePdfEmbed from 'vue-pdf-embed'
import InputUpload from '@/components/InputUpload'
import { GlobalWorkerOptions } from 'vue-pdf-embed/dist/index.essential.mjs'
import PdfWorker from 'pdfjs-dist/build/pdf.worker.js?url'

GlobalWorkerOptions.workerSrc = PdfWorker

export default {
  name: 'Document',
  components: { Spinner, ModalDialog, DeleteModalDialog, DataTable, Column, VuePdfEmbed, InputUpload },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['callForNextStep', 'callForPrecedingStep', 'callForSaveProperty', 'cancelPropVisible'],
  data() {
    return {
      isOpen: false,
      show_spiner: false,
      primaryColor: '',
      files: null,
      totalRecords: 0,
      rowsPerPageOptions: [5, 10, 25, 50, 100, 250],
      perPage: 5,
      loadingData: true,
      documentList: [],
      searchTxt: '',
      showDoc: false,
      showUploader: false,
      viewer: {
        allowedTypes: ['pdf', 'jpg', 'jpeg', 'gif', 'png', 'rtf'],
        numPages: 0,
        fileName: '',
        fileExt: '',
        source: {
          url: '',
          isEvalSupported: false,
        },
      },
      selectedDocs: [],
      clientComment: '',
      isDeleteVisible: false,
      back_button_activated: false,
      addCommentVisible: false,
    }
  },
  mounted() {
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : ''
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getObjPropertyDetails', 'isPropertyReadOnly']),

    isNewProperty() {
      return this.$parent.isNewProperty
    },

    canEditProperty() {
      return this.getCurrentUser.permissions && Object.values(this.getCurrentUser.permissions).includes('EDIT_PROPERTY')
    },
    canAddProperty() {
      return this.getCurrentUser.permissions && Object.values(this.getCurrentUser.permissions).includes('ADD_PROPERTY')
    },

    currentUserFullName() {
      return this.getCurrentUser.firstName + ' ' + this.getCurrentUser.lastName
    },

    isDeleteButtonDisabled() {
      let isDisabled = true
      let shouldSkip = this.isPropertyReadOnly

      this.selectedDocs.forEach((document) => {
        if (shouldSkip) {
          return
        }
        isDisabled = !this.canDeleteDocument(document)
        shouldSkip = isDisabled
      }, this)

      return isDisabled
    },

    isShowNextStepButton() {
      let isShow = true
      if (this.$parent.step === this.$parent.maxStep && this.isPropertyReadOnly) {
        isShow = false
      }
      return isShow
    },
  },
  watch: {
    getObjPropertyDetails: {
      deep: true,
      handler: function () {
        if (this.getObjPropertyDetails.id) {
          this.loadDocuments()
        }
      },
    },

    addCommentVisible(newValue) {
      if (newValue === true) {
        this.invokeReference('clientComment')
      }
    },
  },
  methods: {
    ...mapMutations(['showPageLoader', 'hidePageLoader']),
    ...mapActions(['showToast', 'changeInfoTooltip']),

    setInfoTooltip(val) {
      this.changeInfoTooltip(val)
    },

    handleModalClose() {
      this.isDeleteVisible = false
    },

    handleModalCommentClose() {
      this.addCommentVisible = false
    },

    openConfirmDeleteDialog() {
      this.isDeleteVisible = true
    },

    openModalCommentDialog() {
      this.clientComment = ''
      this.addCommentVisible = true
    },

    invokeReference(ref) {
      this.$nextTick(() => {
        if (
          typeof this.$refs !== 'undefined' &&
          typeof this.$refs[ref] !== 'undefined' &&
          typeof this.$refs[ref].focus === 'function'
        ) {
          this.$refs[ref].focus()
        }
      })
    },

    loadDocuments() {
      this.loadingData = true
      let params = {
        parentType: 'property',
        parentId: this.getObjPropertyDetails.id,
        filters: {
          txt_filter: this.searchTxt,
        },
      }
      if (this.getObjPropertyDetails.freezedDeclaration) {
        params.parentType = 'declaration'
        params.parentId = this.getObjPropertyDetails.freezedDeclaration
      }
      apiClient.post('documents/list', params).then((response) => {
        this.loadingData = false
        if (response.data.success) {
          this.documentList = this.parse_object_data(response.data.records)
          this.totalRecords = response.data.total_records
        }
      })
    },
    parse_object_data(data) {
      const parsed_data = []
      const data_length = Object.keys(data).length
      if (data_length > 0) {
        for (let i = 0; i < data_length; i++) {
          parsed_data.push(data[i])
        }
      }
      return parsed_data
    },
    getIcon(file_ext) {
      switch (file_ext) {
        case 'xls,xlsx':
          return require('@/assets/newproperty/xlx.svg')
        case 'doc,docx':
          return require('@/assets/newproperty/doc.svg')
        case 'pdf':
          return require('@/assets/newproperty/pdf.svg')
        default:
          return require('@/assets/newproperty/document_generic.svg')
      }
    },
    deleteSelectedDocuments() {
      this.isDeleteVisible = false
      this.loadingData = true
      let selectedDocsCount = this.selectedDocs.length
      let params = {
        parentType: 'property',
        parentId: this.getObjPropertyDetails.id,
        records: [],
      }
      if (selectedDocsCount > 0) {
        this.selectedDocs.forEach((document) => {
          let myRecord = {}
          myRecord['prim_uid'] = document.id
          params.records.push(myRecord)
        }, this)
        apiClient.post('documents/delete', params).then((response) => {
          this.loadingData = false
          if (response.data.success) {
            this.showToast({
              message:
                selectedDocsCount === 1
                  ? this.$t('newProperty.Dokumente.delete_success_single')
                  : this.$t('newProperty.Dokumente.delete_success_multiple'),
              toastType: 'success',
            })
            this.selectedDocs = []
            this.loadDocuments()
          } else {
            this.showToast({
              message: response.data.message,
              toastType: 'error',
            })
          }
        })
      }
    },
    downloadDocument(document) {
      if (document.id) {
        this.showPageLoader()
        apiClient
          .post('documents/get', {
            parentType: document.parent_type,
            parentId: document.parent_uid,
            uid: document.id,
            download: 1,
          })
          .then((response) => {
            this.hidePageLoader()
            if (response.data.success) {
              location.href = 'download'
            } else {
              this.showToast({
                message: response.data.message,
                toastType: 'error',
              })
            }
          })
      }
    },
    uploadDoc() {
      if (this.canEditProperty) {
        this.$refs.uploader.clear()
        this.showUploader = true
      }
    },
    chooseFile() {
      this.$refs.uploader.choose()
    },
    showSelectedDoc() {
      //shows document from header click for selectedDocs[0]
      if (Array.isArray(this.selectedDocs) && this.selectedDocs.length === 1) {
        this.showDocument(this.selectedDocs[0])
      }
    },
    showDocument(document) {
      if (document.id) {
        this.viewer.numPages = 0
        this.viewer.fileName = document.filename
        this.viewer.fileExt = document.file_ext
        this.viewer.source.url = ''
        if (this.viewer.allowedTypes.includes(document.file_ext)) {
          this.showPageLoader()
          apiClient
            .post('documents/get', {
              parentType: document.parent_type,
              parentId: document.parent_uid,
              uid: document.id,
            })
            .then((response) => {
              this.hidePageLoader()
              if (response.data.success) {
                if (
                  response.data.document.mime_type &&
                  this.viewer.allowedTypes.includes(response.data.document.file_ext)
                ) {
                  let docUrl = 'data:' + response.data.document.mime_type + ';base64,' + response.data.document.content
                  this.viewer.source.url = docUrl
                  this.showDoc = true
                }
              } else {
                this.showToast({
                  message: response.data.message,
                  toastType: 'error',
                })
              }
            })
        } else {
          this.downloadDocument(document)
        }
      }
    },
    hideDoc() {
      this.showDoc = false
    },
    closeUploader() {
      this.showUploader = false
    },
    uploadFiles() {
      if (this.$refs.uploader.files.length > 0) {
        if (!this.addCommentVisible && this.isPropertyReadOnly) {
          this.openModalCommentDialog()
          return
        }

        this.handleModalCommentClose()
        let localClientComment = this.clientComment
        this.showUploader = false
        this.showPageLoader()

        let promises = []

        let timestamp = 0
        apiClient
          .post('getServerTime', null, {
            headers: {},
          })
          .then((response) => {
            if (
              response.data !== 'undefined' &&
              response.data.success === true &&
              response.data.timestamp !== 'undefined'
            ) {
              timestamp = response.data.timestamp
            }
            for (let file of this.$refs.uploader.files) {
              let formData = new FormData()
              formData.append('file', file)
              formData.append('fileUuid', file.uuid)
              formData.append('parentType', 'property')
              formData.append('parentId', this.getObjPropertyDetails.id)
              if (timestamp !== 0) {
                formData.append('timestamp', '' + timestamp)
              }
              promises.push(
                apiClient.post('documents/upload', formData, {
                  headers: {
                    'content-type': 'multipart/form-data',
                  },
                }),
              )
            }
            Promise.all(promises).then((responses) => {
              let uploadedCount = 0
              let failedUpload = 0
              let uploadResult = []
              for (let response of responses) {
                if (response.data.success) {
                  uploadedCount++
                  if (response.data.fileUuid) {
                    uploadResult.push({
                      fileUuid: response.data.fileUuid,
                      uploaded: true,
                    })
                  }
                } else {
                  failedUpload++
                  if (response.data.fileUuid && response.data.message) {
                    uploadResult.push({
                      fileUuid: response.data.fileUuid,
                      errorMessage: response.data.message,
                    })
                  }
                }
              }

              this.hidePageLoader()
              if (failedUpload > 1) {
                this.showToast({
                  message: this.$t('general.uploader.x_docs_upload_failed', {
                    count: failedUpload,
                  }),
                  toastType: 'error',
                })
              } else if (failedUpload === 1) {
                let failedRes = uploadResult.filter((o) => {
                  if (o.errorMessage) {
                    return true
                  }
                })

                if (failedRes.length > 0) {
                  this.showToast({
                    message: failedRes[0].errorMessage,
                    toastType: 'error',
                  })
                } else {
                  this.showToast({
                    message: this.$t('general.errors.unknownError'),
                    toastType: 'error',
                  })
                }
              }

              if (uploadedCount > 0) {
                if (localClientComment.trim() !== '') {
                  apiClient.post('property/clientComment', {
                    id: this.getObjPropertyDetails.id,
                    client_comment: localClientComment,
                  })
                }
                this.showToast({
                  message: this.$t('newProperty.Dokumente.x_docs_uploaded', {
                    count: uploadedCount,
                  }),
                  toastType: 'success',
                })
              }

              this.loadDocuments()
            })
          })
      }
    },

    canDeleteDocument(document) {
      let canDelete = false

      if (!this.isPropertyReadOnly && this.canAddProperty && this.canEditProperty) {
        if (document.id) {
          if (
            typeof document.created_by === 'string' &&
            this.currentUserFullName === document.created_by &&
            typeof document.created_by_id === 'string' &&
            document.created_by_id === '0'
          ) {
            canDelete = true
          }
        }
      }
      return canDelete
    },

    cancel() {
      this.$emit('cancelPropVisible')
    },

    save() {
      if (!this.isPropertyReadOnly) {
        this.$emit('callForSaveProperty')
      }
    },

    callForPrecedingStep() {
      this.$emit('callForPrecedingStep')
    },

    callForNextStep() {
      this.$emit('callForNextStep')
    },
  },
}
</script>

<style scoped lang="css">
@import './../assets/custom.css';
@import './../assets/form_style.css';

:deep(.p-checkbox .p-checkbox-box.p-highlight) {
  background: var(--api-background);
  border-color: var(--api-background);
}
:deep(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover) {
  background: var(--api-background);
  border-color: var(--api-background);
}
:deep(.p-checkbox .p-checkbox-box .p-checkbox-icon) {
  color: #ffffff !important;
}
:deep(.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled.p-paginator-rpp-options) {
  width: 70px;
}

.document_img {
  margin-right: 10px;
  width: 20px;
}

.dk_search {
  align-items: center;
  display: grid;
  grid-template-columns: 70% 30%;
  width: 100%;
}

.dk_search_input input.input-search {
  float: right;
  margin: 0 !important;
  max-width: 228px;
}

.dk_search_icon {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
}

.action_row.dk_search_row {
  width: 100%;
}

hr.dk_subheading_devide {
  margin-bottom: 0;
}

.dk_file_name p.dk_wiegth {
  font-weight: normal;
}

.dk_file_name p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 7px;
}

.dk_file_name.gap {
  padding-top: 7px;
}

.box-content.ov_visible.dk_mobile_list {
  justify-content: space-between;
}

.dk_desktop_view td .checkbox_input span.checkbox_title {
  padding-left: 10px;
}

.checkbox_input input[type='checkbox'] {
  border: 1px solid #bbbbbb;
  border-radius: 0;
  box-shadow: 0 0 0;
  height: 20px;
  margin-right: 10px;
  width: 20px;
}

.modal_backdrop_document :deep(.modal) {
  height: auto !important;
  max-width: 800px !important;
  padding-bottom: 20px;
}

.modal_backdrop_document {
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

label input {
  display: none;
}

button.upload-doc-files {
  background-color: #229d56;
  border: 0;
  border-radius: 2px;
  color: white;
  height: 32px;
  margin: 0 10px;
  max-width: 130px;
  width: 100%;
}

.filename img {
  max-width: 15px;
}

.filename {
  align-items: center;
  display: flex;
  gap: 8px;
  padding-top: 5px;
}

.progress-bar-main .progress-bar {
  background-color: #229d56;
  border-radius: 30px;
}

.progress-bar-main .progress-bar {
  border-radius: 30px;
}

.progress-bar-main {
  margin: 15px 0 50px;
  text-align: left;
}

.progress-bar-main .progress {
  height: 10px;
}

.modal_document .btn_170.btn_cancel {
  padding: 30px 0;
}

.modal_document .filemin_cont.cancel-popup img {
  width: 100%;
}

@media only screen and (min-width: 100px) and (max-width: 768px) {
  .dk_search[data-v-51f11cb5] {
    gap: 20px;
    grid-template-columns: 100%;
  }

  .grid-list-view.mobilelistview.dk_mobile_view {
    padding-top: 15px;
  }

  .dk_search_icon a {
    font-size: 14px;
  }

  .dk_search_input input.input-search {
    max-width: 100%;
  }

  .dk_file_name p a.dk_file_un {
    text-decoration: underline !important;
  }
}

.uploadDoc_div .icon {
  height: 43px;
  width: 43px;
}

.uploadDoc_div :deep(.container) {
  height: 250px;
}

.uploadDoc_div :deep(.info_box) {
  height: 95%;
}

.upload-button-style {
  background-color: #229d56;
  border-color: transparent;
  border-radius: 2px;
  color: #ffffff;
  font-family: 'Segoe UI Regular', 'Segoe UI', sans-serif;
  height: 40px;
  line-height: normal;
  text-align: center;
  width: 200px;
}

.isDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.modal-backdrop .modal {
  border-top: 5px solid #229d56;
}

.popup_title h2 {
  color: #333;
  font-size: 26px;
}

.tab-border-home p {
  font-size: 16px;
}

.tab-top-height {
  height: 200px;
}

.btn_170 {
  display: flex;
  justify-content: flex-end;
  margin-left: auto !important;
  margin-right: -15px;
}

button.button-style:first-child {
  border: none !important;
}

button.button-style.red_btn:hover {
  background: #d55959 !important;
  border-color: #d55959;
  color: #fff;
  filter: brightness(0.8);
}

button.button-style.btn_line:hover {
  background: #bbbbbb;
  border-color: #bbb;
}

.tab-border-home {
  border: none;
}

.min_cont {
  padding-left: 15px;
}
</style>
