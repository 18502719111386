export const defaultPropertyMoreDetails = {
  ownership_structure: '',
  property_type: '0', //Art des Grundstücks
  development_state: '', //bei unbebauten Grundstücken ggf. abw. Entwicklungszustand
  multi_communities: 0,
  leasehold: 0, //Ist auf dem Grundstück ein Erbbaurecht bestellt?
  building_on_foreign_ground: 0, //Gebäude auf fremdem Grund und Boden?
  property_residential_purposes: '0', //ist das Grundstück bebaut und wird überwiegend zu Wohnnzwecken genutzt?
  buildings_on_third_party_owners: 0,
  lifting_rate: '', //hidden!
  registration_land_registry: '',
  total_property_part: '', //Fläche des gesamten zur wirtschaftlichen Einheit gehörenden Anteils Grund und Bodens in m², die bebaut oder befestigt ist
  area_of_the_land_1: '',
  area_of_the_land_value_1: '', //Bodenrichtwert 1
  located_in_outdoor_area_1: 0, //Befindet sich dieses Grundstück im Außenbereich?
  area_of_the_land_2: '',
  area_of_the_land_value_2: '', //Bodenrichtwert 2
  located_in_outdoor_area_2: 0, //Befindet sich dieses Grundstück im Außenbereich?
  parcels: [
    {
      id: 0, //internal data, no form edit
      community: '', //Gemeinde
      parcel: '', //Gemarkung
      land_register_sheet: '', //Grundbuchblatt
      corridor: '', //Flur
      area_of_the_land: '', //Fläche in m²
      parcel_data_counter: '', //Flurstück: Zähler
      parcel_data_denominator: '', //Flurstück: Nenner
      share_of_ownership_counter: '', //"Zur wirtschaftlichen Einheit gehörender Anteil: Zähler"
      share_of_ownership_denominator: '', //"Zur wirtschaftlichen Einheit gehörender Anteil: Nenner",
      contained_in_area: '0', //Auswahlliste 1-3, "Enthalten in welcher Fläche (welcher Bodenrichtwert zutreffend)?"
    },
  ],
  showSubAreaSection: false,
  subArea: {
    id: 0, //internal data, no form edit
    areaOfTotalSubarea: '', //Fläche der gesamten Teilfläche in m²
    landValueOfSubarea: '', //Bodenrichtwert der Teilfläche
    includedInWhichArea: '0', //Enthalten in welcher Grundstücksfläche
    buildable: '0', //Ist die Teilfläche bebaubar?
    landValueForBuildingLand: '0', //Ist der angegebene BRW für Bauflächen?
  },
}
