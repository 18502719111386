export const propertyDataStructure = {
  step2: {},
  step3: {},
  step3a: {},
  step4: {},
  step5: {},
  step6: {},
  step7: {},
  step9: {},
  step10: {},
  subArea: {},
}

export const defaultPropertyAddress = {
  federal_state_uid: '',
  community: '',
  street: '',
  house_number: '',
  zip: '',
  city: '',
  additional_information: '',
}
