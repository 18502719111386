<template>
  <!-- Login  Start -->
  <section class="login_form section">
    <div class="container">
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <h4 class="heading_title centertitle">
            {{ $t('LoginNoMobPwdVhan.title') }} <label class="sub mb-10">{{ getVersion }}</label>
          </h4>
          <form>
            <label
              class="label-title"
              for="Benutzername"
              >{{ $t('login_start.user') }}</label
            >
            <input
              type="text"
              autocomplete="off"
              class="form-control input-field"
              v-model="form.userName"
              disabled
              id="Benutzername"
              :placeholder="$t('LoginNoMobPwdVhan.name_placeholder')"
            />
            <span
              class="error"
              v-if="form.errors().has('userName')"
            >
              {{ form.errors().get('userName') }}
            </span>
            <label
              class="label-title"
              for="Passwort"
              >{{ $t('LoginNoMobPwdVhan.pwd') }}</label
            >
            <input
              ref="password"
              autocomplete="off"
              type="password"
              class="form-control input-field"
              v-model="form.password"
              id="Passwort"
              :disabled="mfaMode !== ''"
              :placeholder="$t('LoginNoMobPwdVhan.pwd_placeholder')"
            />
            <span
              class="error"
              v-if="form.errors().has('password')"
              v-text="form.errors().get('password')"
            ></span>
            <router-link
              to=""
              class="link-style a-style"
              style="text-align: right"
              @click="sendResetPwdMail(form.userName)"
              >{{ $t('LoginNoMobPwdVhan.forgotpwd') }}
            </router-link>

            <div v-show="mfaMode">
              <label
                class="label-title"
                for="Code"
                >{{ $t('LoginNoMobPwdVhan.mfa_code') }}*</label
              >
              <InfoText
                :text="$t('InvitationTC.infoAlreadySent')"
                class="mb-4"
              ></InfoText>
              <input
                ref="code"
                type="text"
                autocomplete="off"
                class="form-control input-field"
                id="Code"
                v-model="form.mfa_code"
                :placeholder="$t('LoginNoMobPwdVhan.mfa_code')"
              />
              <span
                class="red_txt float-start"
                v-show="codeLifetime > 0"
                >{{ $t('LoginNoMobPwdVhan.expires_in') }} {{ getExpiryTime }}</span
              >
              <a
                href="javascript:void(0)"
                v-show="!mfaCodeSent"
                class="a-style"
                @click="sendLoginMfaCode()"
                >{{ $t('LoginNoMobPwdVhan.send_sms') }}</a
              >
              <a
                href="javascript:void(0)"
                v-show="mfaCodeSent && codeLifetime > 0"
                class="a-style"
                @click="sendLoginMfaCode()"
                >{{ $t('LoginNoMobPwdVhan.send_sms_again') }}</a
              >
            </div>

            <div class="return-further">
              <button
                type="button"
                @click="$router.push('/')"
                class="button-style"
              >
                {{ $t('LoginNoMobPwdVhan.return') }}
              </button>
              <button
                type="submit"
                class="button-style"
                @click.stop.prevent="submit"
              >
                {{ $t('LoginNoMobPwdVhan.further') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

  <!-- Login  Start ///////////// -->
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import i18n from '@/i18n'
const { t } = i18n.global
import { mapActions, mapGetters } from 'vuex'
import InfoText from '@/components/helper/InfoText'

export default {
  components: { InfoText },
  data: () => ({
    apiError: '',
    mfaCodeSent: false,
    mfaMode: '',
    codeLifetime: 0,
    form: form({
      userName: '',
      password: '',
      mfa_code: '',
    })
      .rules({
        userName: 'required',
        password: 'required',
      })
      .messages({
        'userName.required': t('LoginNoMobPwdVhan.msg_name_required'),
        'password.required': t('LoginNoMobPwdVhan.msg_pwd_required'),
      }),
  }),
  created() {
    this.form.userName = this.userName
    this.invokeReference('password')
    this.mfaMode = ''
    if (this.form.userName === '') {
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'userName', 'emailSent']),
    getVersion() {
      return `${t('login.version')}${process.env.PACKAGE_VERSION}`
    },
    getExpiryTime() {
      if (this.codeLifetime) {
        let d = new Date(0)
        d.setSeconds(this.codeLifetime)
        return d.toISOString().substr(14, 5)
      } else {
        return ''
      }
    },
  },
  methods: {
    ...mapActions(['login', 'resetPass', 'encrypt', 'sendMfaCode', 'showToast']),
    async submit() {
      if (this.form.validate().errors().any()) return
      let loginData = { ...this.form.all() }
      loginData.userName = await this.encrypt(loginData.userName)
      loginData.password = await this.encrypt(loginData.password)
      this.login(loginData).then((response) => {
        if (response.data.success && response.data.mfa_mode) {
          this.form.mfa_code = ''
          this.mfaMode = response.data.mfa_mode

          if (response.data.expires_in) {
            // MFA code is sent by default
            if (response.data.mfa_mode === 'SMS') {
              this.showToast({
                message: t('LoginNoMobPwdVhan.sms_code_sent').replace('%num%', response.data.phone_last_4),
                toastType: 'success',
              })
            } else {
              this.showToast({
                message: t('LoginNoMobPwdVhan.mfa_code_sent'),
                toastType: 'success',
              })
            }
            this.mfaCodeSent = true
            this.codeLifetime = response.data.expires_in
            this.mfaCodeTimer()
          }
        }
      })
    },
    async sendResetPwdMail(val) {
      let userNameEnc = await this.encrypt(val)
      this.resetPass(userNameEnc)
    },
    async sendLoginMfaCode() {
      let loginData = { ...this.form.all() }
      loginData.userName = await this.encrypt(loginData.userName)
      loginData.password = await this.encrypt(loginData.password)
      loginData.resend = this.mfaCodeSent ? 1 : 0
      this.sendMfaCode(loginData).then((response) => {
        if (response && response.data.success) {
          let message =
            response.data.mfa_mode === 'SMS'
              ? t('LoginNoMobPwdVhan.sms_code_sent').replace('%num%', response.data.phone_last_4)
              : t('LoginNoMobPwdVhan.mfa_code_sent')
          this.showToast({
            message: message,
            toastType: 'success',
          })
          this.mfaCodeSent = true
          if (response.data.expires_in && this.codeLifetime === 0) {
            this.codeLifetime = response.data.expires_in
            this.mfaCodeTimer()
          }
        }
      })
    },
    mfaCodeTimer() {
      if (this.codeLifetime > 0) {
        setTimeout(() => {
          this.codeLifetime -= 1
          this.mfaCodeTimer()
        }, 1000)
      }
    },
    invokeReference(ref) {
      this.$nextTick(() => {
        if (!this.$refs[ref]) return
        if (
          typeof this.$refs !== 'undefined' &&
          typeof this.$refs[ref] !== 'undefined' &&
          typeof this.$refs[ref].focus === 'function'
        ) {
          this.$refs[ref].focus()
        }
      })
    },
  },
}
</script>
<style scoped>
section.section {
  padding: 50px 0 0 0;
}

.topspace60 {
  margin-top: 60px;
}

h4.heading_title {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 40px;
}

input.input-field {
  border: 1px solid #bbbbbb;
  border-radius: 0;
  color: #999999;
  font-size: 15px;
  height: 40px;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
}

button.button-style,
a.button-style {
  background: #229d56;
  border: 0;
  border-radius: 0;
  color: #fff;
  height: 40px;
  max-width: 100%;
  width: 100%;
}

label.label-title {
  display: inline-block;
  padding: 10px 0 5px;
  text-align: left;
  width: 100%;
}

.benutzerkonto {
  background-color: rgba(34, 157, 86, 0.25);
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin: 30px auto 0 auto;
  max-width: 520px;
  padding: 10px;
  text-align: left;
  width: 100%;
}

span.note_icon {
  padding-right: 10px;
}

.return-further {
  display: flex;
  grid-gap: 10px;
  margin-top: 40px;
}

button.button-style:first-child {
  background: none;
  border: 1px solid #bbbbbb;
  color: #333333;
}

a.a-style {
  color: #4b4b4b;
  display: block;
  font-size: 15px;
  text-align: right;
  width: 100%;
}

a.link-style.a-style {
  color: #bbb;
  text-decoration: none;
}

.sub {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  color: #aeaeae;
  font-family: 'Segoe UI', sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}
</style>
