<template>
  <!-- Login  Start -->
  <section class="login_form section">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 offset-lg-4 col-md-8 offset-md-2">
          <h4 class="heading_title centertitle">
            {{ $t('login_start.title') }} <label class="sub mb-10">{{ getVersion }}</label>
          </h4>
          <form class="frm_margin_on_mobile">
            <label
              class="label-title"
              for="Benutzername"
              >{{ $t('login_start.user') }}</label
            >
            <input
              ref="benutzername"
              autocomplete="off"
              type="text"
              :class="form.errors().has('benutzername') ? ' field_error' : ''"
              class="form-control input-field"
              v-model="form.benutzername"
              id="Benutzername"
              placeholder="Benutzername im Format M-12345678 eingeben"
            />

            <span
              class="error"
              v-if="form.errors().has('benutzername') || pattern != ''"
            >
              {{ form.errors().get('benutzername') }}
              {{ pattern }}
            </span>

            <button
              type="submit"
              @click.stop.prevent="postLogin"
              class="button-style topspace60"
            >
              {{ $t('login_start.button') }}
            </button>

            <div class="row mb-3 mt-3">
              <div class="col-md-12 text-center">
                <p>{{ $t('general.uploader.or') }}</p>
                <img
                  src="@/assets/datev_login.png"
                  class="clickable datev_login"
                  @click="startDatevLogin()"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

  <!-- Login  Start ///////////// -->
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { resetAbortController } from '@/Api'

export default {
  data: () => ({
    info: null,
    errored: null,
    pattern: '',
    form: form({
      benutzername: '',
    })
      .rules({
        benutzername: 'required',
      })
      .messages({
        'benutzername.required': 'Bitte geben Sie den Benutzernamen ein',
      }),
  }),
  created() {
    resetAbortController()
    this.form.benutzername = this.$store.state.login.userName
    this.invokeReference('benutzername')
    if (this.$route.params.autoLogin === '1') {
      this.postLogin()
    }
  },
  computed: {
    ...mapGetters(['isUserExist', 'getToken']),
    getVersion() {
      return `${this.$t('login.version')}${process.env.PACKAGE_VERSION}`
    },
  },
  watch: {
    isUserExist(val) {
      this.loginScreen(val)
    },
    userName(val) {
      this.form.benutzername = val
    },
  },
  methods: {
    ...mapActions(['userExist', 'encrypt', 'datevLogin']),
    ...mapMutations(['showPageLoader', 'hidePageLoader', 'setUserName']),
    loginScreen(val) {
      this.hidePageLoader()
      if (val.success) {
        if (val.authMethod === 'PASSWORD_MOBILE') {
          //this.$router.push('/LoginVorhandenPwdMobil'); needs rework; deactivated for release 1.0.0
          this.$router.push('/LoginNoMobPasswortVorhanden')
        } else if (val.authMethod === 'PASSWORD') {
          this.$router.push('/LoginNoMobPasswortVorhanden')
        } else if (val.authMethod === 'MOBILE') {
          //this.$router.push('/LoginMobilvorhanden'); needs rework; deactivated for release 1.0.0
          this.$router.push('/LoginNoMobPasswortVorhanden')
        } else {
          //this.$router.push('/LoginNoPassword'); needs rework; deactivated for release 1.0.0
          this.$router.push('/LoginNoMobPasswortVorhanden')
        }
      }
    },
    validateUsername(username) {
      const email_reg = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i
      if (/^M-\d{8}$/.test(username) || email_reg.test(username)) {
        return false
      } else {
        this.pattern = this.$t('general.errors.user_not_found')
        return true
      }
    },
    invokeReference(ref) {
      this.$nextTick(() => {
        if (!this.$refs[ref]) return
        if (
          typeof this.$refs !== 'undefined' &&
          typeof this.$refs[ref] !== 'undefined' &&
          typeof this.$refs[ref].focus === 'function'
        ) {
          this.$refs[ref].focus()
        }
      })
    },
    async postLogin() {
      if (this.form.validate().errors().any()) return
      if (!this.validateUsername(this.form.benutzername)) {
        this.showPageLoader()
        this.setUserName(this.form.benutzername)
        let userName = await this.encrypt(this.form.benutzername)
        this.userExist(userName)
      }
    },
    startDatevLogin() {
      this.datevLogin({
        process: 'login',
      })
    },
  },
}
</script>
<style scoped>
section.section {
  padding: 100px 0 0 0;
}

.topspace60 {
  margin-top: 60px;
}

h4.heading_title {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 40px;
}

input.input-field {
  border: 1px solid #bbbbbb;
  border-radius: 0;
  color: #4b4b4b;
  font-size: 15px;
  height: 40px;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
}

button.button-style,
a.button-style {
  background: #229d56;
  border: 0;
  color: #fff;
  height: 40px;
  max-width: 100%;
  width: 100%;
}

label.label-title {
  display: inline-block;
  padding-bottom: 5px;
  text-align: left;
  width: 100%;
}

.sub {
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  color: #aeaeae;
  font-family: 'Segoe UI', sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

/*hr:not([size]) {
  height: 1px;
  width: 100%;
  max-width: 415px;
  margin: 40px auto;
}
*/
.datev_login {
  height: 42px;
  width: 136px;
}
</style>
