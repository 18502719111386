export const defaultPropertyCommunity = {
  id: 0,
  salutation: '0', //Anrede - Auswahl
  name: '', //Name der Gemeinschaft
  street: '', //Straße
  house_number: '', //Hausnummer
  zip: '', //PLZ
  postbox: '', //Postfach
  city: '', //Ort
  country_uid: '0', //Land
}
