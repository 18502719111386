<template>
  <Header />
  <div class="login_start">
    <Profileinfo />
    <Dashboard />
  </div>
  <Copyright />
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Profileinfo from '@/components/Profileinfo.vue'
import Dashboard from '@/components/Dashboard.vue'
import Copyright from '@/components/copyright.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Profileinfo,
    Dashboard,
    Copyright,
  },
}
</script>
