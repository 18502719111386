import apiClient from '../../Api'
import router from '../../router'
import i18n from '@/i18n'

const { t } = i18n.global
const state = {
  allUsers: '',
  currentUser: {},
}
const getters = {
  getAllUsers: (state) => state.allUsers,
  getCurrentUser: (state) => state.currentUser,
}

const mutations = {
  setAllUsers: (state, val) => {
    if (typeof val === 'object') {
      state.allUsers = JSON.parse(JSON.stringify(val))
    } else {
      state.allUsers = val
    }
  },
  setCurrentUser: (state, user) => {
    state.currentUser = user
  },
}

const actions = {
  fetchCurrentUser({ commit }, payload) {
    return apiClient
      .post('getCurrentUser', payload.data ? payload.data : null, {
        headers: payload.headers ? payload.headers : {},
      })
      .then((response) => {
        if (response.data.success) {
          commit('setCurrentUser', response.data.userInfo)
        }
        return response
      })
  },
  updateUserInfo({ commit, dispatch }, payload) {
    commit('showPageLoader')
    apiClient
      .post('updateUserData', payload)
      .then((response) => {
        commit('hidePageLoader')
        if (response.data.success) {
          dispatch('showToast', {
            message: t('notify.title.success'),
          })
          router.push('/dashboard')
        } else {
          dispatch('showToast', {
            message: response.data.message,
            toastType: 'error',
          })
        }
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: error.response.data.message,
            toastType: 'error',
          })
        }
      })
  },

  getUsers({ commit }, payload) {
    commit('showPageLoader')
    apiClient
      .post('client/get', { uid: payload })
      .then((response) => {
        commit('hidePageLoader')
        if (
          typeof response.data !== 'undefined' &&
          response.data.success &&
          typeof response.data.clientData === 'object' &&
          typeof response.data.clientData.users === 'object'
        ) {
          commit('setAllUsers', response.data.clientData.users)
        } else {
          commit('setAllUsers', '')
        }
      })
      .catch(() => {
        commit('hidePageLoader')
        commit('setAllUsers', '')
      })
  },

  saveUser({ commit, dispatch }, payload) {
    let editMode =
      typeof payload.prim_uid !== 'undefined' &&
      !Number.isNaN(parseInt(payload.prim_uid, 10)) &&
      parseInt(payload.prim_uid, 10) > 0

    commit('showPageLoader')
    apiClient
      .post('/client/save_user', payload)
      .then((response) => {
        commit('hidePageLoader')
        if (response.data.success) {
          dispatch('getUsers', payload.client_uid)
          dispatch('showToast', {
            message: editMode ? t('admin.editUser_success') : t('admin.addUser_success'),
            toastType: 'success',
          })
        } else {
          dispatch('showToast', {
            message: response.data.message,
            toastType: 'error',
          })
        }
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
      })
  },

  deleteUserapi({ commit, dispatch }, payload) {
    commit('showPageLoader')
    apiClient
      .post('client/delete_user', payload)
      .then((response) => {
        commit('hidePageLoader')
        if (response.data.success) {
          dispatch('getUsers', payload.client_uid)
          dispatch('showToast', {
            message: t('admin.deleteUser_success'),
            toastType: 'success',
          })
        } else {
          dispatch('showToast', {
            message: response.data.message,
            toastType: 'error',
          })
        }
      })
      .catch((error) => {
        commit('hidePageLoader')
        if (error.message !== 'canceled') {
          dispatch('showToast', {
            message: t('general.errors.unknownError'),
            toastType: 'error',
          })
        }
      })
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
