export const defaultPropertyBuildings = {
  buildings: [
    {
      id: 0,
      livingSpaceDescription: '',
      livingSpaceArea: '',
      usableSpaceArea: '',
      areaOfTheLandCivilDefense: '',
      benefits: [
        {
          id: 0,
          benefitDescription: '',
          benefitLivingSpaceArea: '',
          benefitUsableSpaceArea: '',
          benefit: '0',
        },
      ],
      exemptions: [
        {
          id: 0,
          exemptionDescription: '',
          exemptionLivingSpaceArea: '',
          exemptionUsableSpaceArea: '',
          exemption: '0',
        },
      ],
    },
  ],
}
