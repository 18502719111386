<template>
  <section class="Step_SectionPropertyDetails">
    <fieldset>
      <div class="Step_SectionPropertyDetails">
        <InfoTextHeading
          :headingText="$t('newProperty.propertyDetails.headline')"
          :infoText="$t('infoTextDisabledFields')"
        >
        </InfoTextHeading>

        <div class="form-group">
          <div class="row_setp_main">
            <!-- <div :class="[infoTooltip==='propertyDetailsReference' ? 'col-md-7' : 'col-md-10']"> -->
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyDetailsReference')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyDetails.reference') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyDetailsReference'"
                    :content="$t('newProperty.infoTooltip.details.reference.content')"
                    :url="$t('newProperty.infoTooltip.details.reference.url')"
                    :imagePath="require('@/assets/img/infotooltip/property-details-reference.png')"
                  />
                </label>
                <input
                  type="text"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly"
                  v-model="form.reference"
                  placeholder=""
                  class="input-field hover-tooltip"
                  ref="reference"
                  @keyup.esc="cancel"
                  @keyup.enter="callForNextStep"
                  @focusin="setInfoTooltip('propertyDetailsReference')"
                />
                <span
                  class="error"
                  v-if="form.errors().has('details_reference')"
                >
                  {{ form.errors().get('details_reference') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyDetailsName')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyDetails.name') }}<span class="nf_starcolor">*</span>
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyDetailsName'"
                    :content="$t('newProperty.infoTooltip.details.name.content')"
                  />
                </label>
                <input
                  type="text"
                  autocomplete="off"
                  :disabled="isPropertyReadOnly"
                  v-model="form.name"
                  placeholder=""
                  class="input-field hover-tooltip"
                  ref="name"
                  @keyup.esc="cancel"
                  @keyup.enter="callForNextStep"
                  @focusin="setInfoTooltip('propertyDetailsName')"
                />
                <span
                  class="error"
                  v-if="form.errors().has('details_name')"
                >
                  {{ form.errors().get('details_name') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyDetailsEconomicEntityType')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyDetails.economic_entity_type') }}<span class="nf_starcolor">*</span>
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyDetailsEconomicEntityType'"
                    :content="$t('newProperty.infoTooltip.details.economicEntityType.content')"
                    :url="$t('newProperty.infoTooltip.details.economicEntityType.url')"
                  />
                </label>
                <select
                  :disabled="isPropertyReadOnly"
                  class="hover-tooltip"
                  v-model.number="form.economic_entity_type"
                  @change="onChangeEconomicEntityType()"
                  @focusin="setInfoTooltip('propertyDetailsEconomicEntityType')"
                >
                  <option
                    :value="0"
                    disabled
                    selected
                  >
                    {{ $t('select.select_any') }}
                  </option>
                  <option
                    v-for="option in economic_entity_types"
                    v-bind:key="option.code"
                    :value="option.code"
                  >
                    {{ option.name }}
                  </option>
                </select>

                <span
                  class="error"
                  v-if="form.errors().has('details_economic_entity_type')"
                >
                  {{ form.errors().get('details_economic_entity_type') }}
                </span>
              </div>
            </div>

            <!-- Liegt eine Befreiung/Vergünstigung vor? -->
            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyDetailsExemption')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyDetails.exemption') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyDetailsExemption'"
                    :content="$t('newProperty.infoTooltip.details.exemption.content')"
                    :url="$t('newProperty.infoTooltip.details.exemption.url')"
                  />
                </label>
                <div class="gdr_plots">
                  <div class="gdr_plots_inner">
                    <input
                      type="radio"
                      :disabled="isPropertyReadOnly"
                      class="radio-button hover-tooltip"
                      :value="1"
                      name="exemption_toggle"
                      v-model="form.exemption"
                      @focusin="setInfoTooltip('propertyDetailsExemption')"
                    />
                    <span class="grd_radio01 grd_radio">{{ $t('newProperty.yes') }}</span>
                  </div>
                  <div class="gdr_plots_inner">
                    <input
                      type="radio"
                      :disabled="isPropertyReadOnly"
                      class="radio-button hover-tooltip"
                      :value="0"
                      name="exemption_toggle"
                      v-model="form.exemption"
                      @focusin="setInfoTooltip('propertyDetailsExemption')"
                    />
                    <span class="grd_radio01 grd_radio">{{ $t('newProperty.no') }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseover="setInfoTooltip('propertyDetailsExtraDetails')"
            >
              <div class="data-form">
                <label class="label-title">
                  {{ $t('newProperty.propertyDetails.extra_details') }}
                  <InfoTooltip
                    v-if="infoTooltip === 'propertyDetailsExtraDetails'"
                    :content="$t('newProperty.infoTooltip.details.extraDetails.content')"
                    diffY="10.5em"
                  />
                </label>
                <textarea
                  v-model="form.extra_details"
                  :disabled="isPropertyReadOnly"
                  class="textarea-field hover-tooltip"
                  ref="extra_details"
                  autocomplete="off"
                  @keyup.esc="cancel"
                  @focusin="setInfoTooltip('propertyDetailsExtraDetails')"
                />
                <span class="help-text float-end">{{ $t('newProperty.infoTooltip.optional_help_text') }}</span>
                <span
                  class="error"
                  v-if="form.errors().has('extra_details')"
                >
                  {{ form.errors().get('extra_details') }}
                </span>
              </div>
            </div>

            <div
              class="col-md-7"
              @mouseover="setInfoTooltip(0)"
            >
              <div class="data-form">
                <span class="checkbox_input">
                  <input
                    class="form-check-input hover-tooltip"
                    type="checkbox"
                    v-model="form.saveDeviatingOwner"
                    :disabled="isPropertyReadOnly"
                    @change="deviatingOwnerChecked"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span class="checkbox_title">{{ $t('deviatingOwner.check') }}</span>
                </span>
              </div>
            </div>
            <div
              v-show="form.saveDeviatingOwner"
              @mouseover="setInfoTooltip(0)"
            >
              <div class="col-md-10">
                <h2 class="subheading_title">{{ $t('deviatingOwner.title') }}</h2>
                <hr class="subheading_devide" />
              </div>

              <div class="col-md-7">
                <div class="info_box">
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="note_icon"><img src="@/assets/note.svg" /></span>
                    {{ $t('deviatingOwner.info') }}
                  </div>
                </div>
              </div>

              <div class="col-md-7">
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyOwnership.salutation') }}<span class="nf_starcolor">*</span>
                  </label>
                  <select
                    v-model="form.deviatingOwner.salutation"
                    :disabled="isPropertyReadOnly"
                    @focusin="setInfoTooltip(0)"
                  >
                    <option
                      v-for="option in $tm('general.salutation_with_none')"
                      v-bind:key="$rt(option.code)"
                      :value="$rt(option.code)"
                    >
                      {{ $rt(option.name) }}
                    </option>
                  </select>
                  <span
                    class="error"
                    v-if="form.errors().has('details_deviatingOwner_salutation')"
                  >
                    {{ form.errors().get('details_deviatingOwner_salutation') }}
                  </span>
                </div>
              </div>

              <div class="col-md-7">
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.propertyOwnership.title') }}</label>
                  <input
                    v-model="form.deviatingOwner.title"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                </div>
              </div>

              <div class="col-md-7">
                <div class="data-form">
                  <label class="label-title"
                    >{{ $t('deviatingOwner.firstName') }}<span class="nf_starcolor">*</span></label
                  >
                  <input
                    v-model="form.deviatingOwner.firstName"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('details_deviatingOwner_first_name')"
                  >
                    {{ form.errors().get('details_deviatingOwner_first_name') }}
                  </span>
                </div>
              </div>

              <div class="col-md-7">
                <div class="data-form">
                  <label class="label-title"
                    >{{ $t('deviatingOwner.lastName') }}<span class="nf_starcolor">*</span></label
                  >
                  <input
                    v-model="form.deviatingOwner.lastName"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('details_deviatingOwner_last_name')"
                  >
                    {{ form.errors().get('details_deviatingOwner_last_name') }}
                  </span>
                </div>
              </div>

              <div class="col-md-7">
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.propertyOwnership.street') }}</label>
                  <input
                    v-model="form.deviatingOwner.street"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                </div>
              </div>

              <div class="col-md-7">
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.propertyOwnership.houseNumber') }}</label>
                  <input
                    v-model="form.deviatingOwner.houseNumber"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                </div>
              </div>

              <div class="col-md-7">
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.propertyOwnership.postbox') }}</label>
                  <input
                    v-model="form.deviatingOwner.poBox"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                </div>
              </div>

              <div class="col-md-7">
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.propertyOwnership.zip') }}</label>
                  <input
                    v-model="form.deviatingOwner.zipCode"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field"
                    @blur="searchCity"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                </div>
              </div>

              <div class="col-md-7">
                <div class="data-form">
                  <label class="label-title">{{ $t('mydata.ort') }}</label>
                  <input
                    v-model="form.deviatingOwner.city"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                </div>
              </div>

              <div class="col-md-7">
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.propertyOwnership.country') }}</label>
                  <select
                    v-model="form.deviatingOwner.countryUid"
                    :disabled="isPropertyReadOnly"
                    @focusin="setInfoTooltip(0)"
                  >
                    <option
                      value="0"
                      disabled
                      selected
                    >
                      {{ $t('select.select_any') }}
                    </option>
                    <option
                      v-for="option in getArrCountries.countries"
                      v-bind:key="option.code"
                      :value="option.code"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-7">
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.propertyOwnership.taxNumber') }}</label>
                  <input
                    v-model="form.deviatingOwner.taxNumber"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span class="help-text float-end">{{ $t('newProperty.infoTooltip.available_help_text') }}</span>
                </div>
              </div>

              <div class="col-md-7">
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.propertyOwnership.taxIdentificationNumber') }}</label>
                  <input
                    v-model="form.deviatingOwner.identificationNumber"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip(0)"
                  />
                  <span class="help-text float-end">{{ $t('newProperty.infoTooltip.available_help_text') }}</span>
                </div>
              </div>

              <div class="col-md-7">
                <div class="data-form">
                  <label class="label-title">{{ $t('newProperty.propertyOwnership.dob') }}</label>
                  <div class="input-icon">
                    <input
                      type="date"
                      v-model="form.deviatingOwner.dob"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <Svgall
                      :name="'calendar'"
                      :color="primaryColor"
                      class="date-icon-firefox-fix"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="text_left step-button-section"
          @mouseover="setInfoTooltip(0)"
        >
          <div>
            <button
              type="button"
              @click.prevent="cancel"
              class="step-button-style border-button-style"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.abort') }}
            </button>
            <button
              type="button"
              @click.prevent="save"
              class="step-button-style border-button-style"
              v-show="!isPropertyReadOnly"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.save') }}
            </button>
            <button
              type="button"
              v-show="back_button_activated"
              @click.prevent="callForPrecedingStep"
              class="step-button-style border-button-style"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.back') }}
            </button>
            <button
              type="button"
              @click.prevent="callForNextStep"
              class="step-button-style"
              :style="[primaryColor ? { background: primaryColor } : '']"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue') }}
            </button>
            <button
              type="button"
              @click.prevent="callForNextStep"
              class="step-button-enter"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue_enter') }}
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </section>
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import i18n from '@/i18n'
import InfoTooltip from '@/components/helper/InfoTooltip'
import ShowHideTooltip from '@/mixins/ShowHideTooltip'
import DateIconFirefoxFix from '@/mixins/DateIconFirefoxFix'
import { mapGetters, mapActions } from 'vuex'
import InfoTextHeading from '@/components/helper/InfoTextHeading'
const { t } = i18n.global

export default {
  name: 'PropertyDetails',
  components: { InfoTooltip, InfoTextHeading },
  mixins: [ShowHideTooltip, DateIconFirefoxFix],
  emits: [
    'callForNextStep',
    'callForPrecedingStep',
    'callForSaveProperty',
    'cancelPropVisible',
    'didChangeEconomicEntityType',
  ],
  data() {
    return {
      primaryColor: '',
      fontColor: '',
      economic_entity_types: [],
      form: form({
        reference: '',
        name: '',
        economic_entity_type: '0',
        exemption: 0,
        extra_details: '',
        saveDeviatingOwner: false,
        deviatingOwner: {
          id: 0,
          salutation: '',
          title: '',
          firstName: '',
          lastName: '',
          street: '',
          houseNumber: '',
          poBox: '',
          zipCode: '',
          city: '',
          countryUid: '',
          taxNumber: '',
          identificationNumber: '',
          dob: '',
        },
      }), // set form rules and messages in method "prepareForValidate()"
      back_button_activated: false,
    }
  },
  created() {
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#43ac6f'
  },
  mounted() {
    this.initForm()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : ''
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.fontColor : ''
    this.showHide()
    this.invokeReference('reference')
  },
  beforeUnmount() {
    this.saveForm()
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getFormPropertyDetails',
      'isPropertyReadOnly',
      'getFormPropertyAddress',
      'getArrCountries',
      'infoTooltip',
    ]),

    isNewProperty() {
      return this.$parent.isNewProperty
    },
    salutation_options() {
      let myRawOptions = this.$tm('general.salutation_with_none')
      let myOptions = []
      for (let k of myRawOptions) {
        myOptions.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }
      return myOptions
    },
  },
  watch: {
    getFormPropertyDetails() {
      this.initForm()
    },
    getFormPropertyAddress() {
      //rule_G_1515
      this.setEconomicEntityTypeOptions()
    },
    'form.exemption'(newValue) {
      if (newValue === 0) {
        this.$parent.clearFormPropertyBenefits()
      }
      this.$parent.showHideTabs()
    },
  },
  methods: {
    ...mapActions(['resetFormPropertyDetails', 'changeInfoTooltip', 'cityLookup']),

    setInfoTooltip(val) {
      this.changeInfoTooltip(val)
    },
    initForm() {
      for (const [key, value] of Object.entries(this.$store.state.Properties.formPropertyDetails)) {
        if (typeof value !== 'undefined') {
          this.form[key] = JSON.parse(JSON.stringify(value))
        }
      }
      this.setEconomicEntityTypeOptions()
    },
    setEconomicEntityTypeOptions() {
      this.economic_entity_types = []
      let options = []
      if (
        typeof this.$store.state.Properties.formPropertyAddress.federal_state_uid !== 'undefined' &&
        this.$store.state.Properties.formPropertyAddress.federal_state_uid.toString() === '1'
      ) {
        options = this.$tm('economic_entity_types.Baden-Württemberg')
      } else {
        options = this.$tm('economic_entity_types.default')
      }

      for (let k of options) {
        this.economic_entity_types.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }
    },
    saveForm() {
      let myKeys = []
      this.resetFormPropertyDetails()
      myKeys = Object.keys(this.$store.state.Properties.formPropertyDetails)
      if (typeof myKeys === 'object') {
        myKeys.forEach(function (key) {
          if (typeof this.form[key] !== 'undefined') {
            this.$store.state.Properties.formPropertyDetails[key] = JSON.parse(JSON.stringify(this.form[key]))
          }
        }, this)
      }
    },

    setNameField(value) {
      this.form.name = value
    },

    cancel() {
      this.$emit('cancelPropVisible')
    },

    isNumber(value) {
      return typeof value === 'number' && isFinite(value)
    },

    callForPrecedingStep() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForPrecedingStep')
      } else {
        this.$emit('callForPrecedingStep')
      }
    },

    callForNextStep() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForNextStep')
      } else {
        this.$emit('callForNextStep')
      }
    },

    addFlatField(
      objFlatFields, // must be an object
      rawFieldName, // must be a string
      value, // must be a simple value, no objects
      maxRuleValue, // must be a number. 0 => no max rule will be set
      maxRuleMsg, // must be a string. "" => no max custom message will be set
      requiredValue, // must be true/false. false => no required rule will be set
      requiredMsg, // must be a string. "" => no required custom message will be set
      greaterThanValue, // must be a number or undefined. undefined => no greater_than rule will be set
      greaterThanMsg, // must be a string. "" => no greater_than custom message will be set
    ) {
      const prefix = 'details_'
      let flatFieldName = ''
      let fieldRules = []

      if (value === null) value = ''

      if (
        typeof objFlatFields === 'object' &&
        typeof rawFieldName === 'string' &&
        typeof value !== 'undefined' &&
        typeof value !== 'object' &&
        typeof maxRuleValue === 'number' &&
        typeof maxRuleMsg === 'string' &&
        typeof requiredValue === 'boolean' &&
        typeof requiredMsg === 'string' &&
        typeof greaterThanMsg === 'string'
      ) {
        //build flat field name
        flatFieldName = prefix + rawFieldName

        //add flat field to object
        objFlatFields[flatFieldName] = value

        //initialize field rules
        fieldRules = []

        //handle max rule
        if (maxRuleValue > 0) {
          if (maxRuleMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.max', maxRuleMsg)
          }
          fieldRules.push('max: ' + maxRuleValue.toString())
        }

        //handle required rule
        if (requiredValue) {
          if (requiredMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.required', requiredMsg)
          }
          fieldRules.push('required')
        }

        //handle greater_than rule
        if (typeof greaterThanValue === 'number') {
          if (greaterThanMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.greater_than', greaterThanMsg)
          }
          fieldRules.push('greater_than: ' + greaterThanValue.toString())
        }

        //add combined field rules
        this.form.validatorInstance.addRule(flatFieldName, fieldRules)
      }
    },

    prepareForValidate() {
      let myFlatFields = {}
      this.removeFlatFormFields()

      this.addFlatField(
        myFlatFields,
        'reference',
        this.form.reference,
        30,
        t('newProperty.propertyDetails.messages.reference_max'),
        false,
        '',
        undefined,
        '',
      )
      this.addFlatField(
        myFlatFields,
        'name',
        this.form.name,
        0,
        '',
        true,
        t('newProperty.propertyDetails.messages.name_required'),
        undefined,
        '',
      )
      this.addFlatField(
        myFlatFields,
        'economic_entity_type',
        this.form.economic_entity_type,
        0,
        '',
        true,
        t('newProperty.propertyDetails.messages.economic_entity_type_required'),
        0,
        t('newProperty.propertyDetails.messages.economic_entity_type_required'),
      )

      if (this.form.saveDeviatingOwner === true) {
        this.addFlatField(
          myFlatFields,
          'deviatingOwner_salutation',
          this.form.deviatingOwner.salutation,
          0,
          '',
          true,
          t('newProperty.propertyDetails.messages.deviating_owner_salutation_required'),
          undefined,
          '',
        )
        this.addFlatField(
          myFlatFields,
          'deviatingOwner_first_name',
          this.form.deviatingOwner.firstName,
          0,
          '',
          true,
          t('newProperty.propertyDetails.messages.deviating_owner_first_name_required'),
          undefined,
          '',
        )
        this.addFlatField(
          myFlatFields,
          'deviatingOwner_last_name',
          this.form.deviatingOwner.lastName,
          0,
          '',
          true,
          t('newProperty.propertyDetails.messages.deviating_owner_last_name_required'),
          undefined,
          '',
        )
      }

      this.form.set(myFlatFields)
    },

    removeFlatFormFields() {
      Object.keys(this.form.all()).forEach((key) => {
        if (key.startsWith('details_')) {
          this.form.forget(key)
        }
      })
      this.form.rules({})
    },

    save() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForSaveProperty')
      }
    },

    invokeReference(ref) {
      this.$nextTick(() => {
        if (!this.$refs[ref]) return
        this.$refs[ref].focus()
      })
    },

    onChangeEconomicEntityType() {
      this.$emit('didChangeEconomicEntityType')
    },

    searchCity() {
      this.cityLookup(this.form.deviatingOwner.zipCode).then((cities) => {
        if (Object.keys(cities).length > 0) {
          this.form.deviatingOwner.city = cities[0].name
        }
      })
    },

    deviatingOwnerChecked() {
      if (this.form.saveDeviatingOwner) {
        if (this.isNewProperty) {
          //set value for ownership_structure
          this.$parent.$refs.childPropertyMoreDetails.form.ownership_structure = 0
          this.$store.dispatch('showToast', {
            message: t('newProperty.propertyDetails.info_ownership_structure_1'),
            toastType: 'info',
          })
        } else {
          //check if allowed depending on ownership_structure
          let ownership_structure = this.$parent.$refs.childPropertyMoreDetails.form.ownership_structure
          if (ownership_structure.toString() !== '0') {
            this.form.saveDeviatingOwner = false
            this.$store.dispatch('showToast', {
              message: t('newProperty.propertyDetails.info_ownership_structure_2'),
              toastType: 'info',
            })
          }
        }
      }
    },
  },
}
</script>

<style type="text/css">
textarea.textarea-field {
  border: 1px solid #bbbbbb;
  height: 150px;
  width: 100%;
}

.Step_SectionPropertyDetails .tooltip-icon img {
  height: 18px;
  margin-left: 5px;
}

label.label-title.tooltip-icon {
  position: relative;
}

.ToolTipTextBox {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: none;
  max-width: 600px;
  padding: 20px;
  padding: 15px !important;
  position: absolute;
  right: 0;
  top: 35px;
  transform: translate3d(0px, 40px, 0px) !important;
  z-index: 1;
}
.info_box {
  background: #f2f2f2;
  padding: 15px 10px;
}
.info_box .note_icon {
  margin-right: 10px;
}

/*.Step_SectionPropertyDetails .tooltip-icon img:hover + .ToolTipTextBox {
    display: block;
}*/
</style>
