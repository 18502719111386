<template>
  <section class="Step_SectionPropertyResidential">
    <fieldset>
      <div class="Step_SectionPropertyResidential">
        <InfoTextHeading
          :headingText="$t('newProperty.propertyResidential.headline')"
          :infoText="$t('infoTextDisabledFields')"
        >
        </InfoTextHeading>

        <div class="form-group">
          <div class="row_setp_main">
            <div
              v-for="(residential, residentialIndex) in form.residentials"
              :key="residentialIndex"
            >
              <!-- Wohngrundstück 1 -->
              <div
                class="col-md-7"
                @mouseover="setInfoTooltip(0)"
              >
                <hr class="subheading_devide" />
                <div class="deleteIcon delete_">
                  <h5 class="subheading_title">
                    {{ $t('newProperty.propertyResidential.group1_title') }} {{ residentialIndex + 1 }}
                  </h5>
                  <div class="flex-button-icon">
                    <button
                      v-if="residentialIndex === 0 && isRoometricActive"
                      v-on:click.stop.prevent="openRoometricDialog"
                      :style="[primaryColor ? { background: primaryColor } : '']"
                      class="button-style-for-title hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    >
                      <img
                        class="max_20"
                        src="@/assets/roometric/metrics_service.svg"
                        alt=""
                      />
                      {{ $t('roometric.buttonLabel') }}
                    </button>
                    <Svgall
                      @click="removeResidential(residentialIndex)"
                      :name="'delete'"
                      :color="primaryColor"
                      v-show="!isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    />
                  </div>
                </div>
              </div>

              <!-- Allgemeine Angaben -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <h2 class="subheading_title no_top_margin">
                  {{ $t('newProperty.propertyResidential.general_information') }}
                </h2>
                <hr class="subheading_devide" />
              </div>

              <!-- Baujahr des Gebäudes (Zeitpunkt der Bezugsfertigkeit) -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyResidentialYearOfConstruction_' + residentialIndex)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyResidential.year_of_construction') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyResidentialYearOfConstruction_' + residentialIndex"
                      :content="$t('newProperty.infoTooltip.residential.yearOfConstruction.content')"
                      :url="$t('newProperty.infoTooltip.residential.yearOfConstruction.url')"
                    />
                  </label>
                  <input
                    type="number"
                    autocomplete="off"
                    v-model="form.residentials[residentialIndex].residential.yearOfConstruction"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    :ref="calcIdAndRef('year_of_construction_', residentialIndex)"
                    @keypress="onlyNumber"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyResidentialYearOfConstruction_' + residentialIndex)"
                  />
                  <span
                    class="error"
                    v-if="form.errors().has('residentials_' + residentialIndex + '_residential_yearOfConstruction')"
                  >
                    {{ form.errors().get('residentials_' + residentialIndex + '_residential_yearOfConstruction') }}
                  </span>
                </div>
              </div>

              <!-- Es erfolgte eine Kernsanierung, die abgeschlossen wurde im Jahr -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyResidentialCoreRenovationYear_' + residentialIndex)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyResidential.core_renovation_year') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyResidentialCoreRenovationYear_' + residentialIndex"
                      :content="$t('newProperty.infoTooltip.residential.coreRenovationYear.content')"
                      :url="$t('newProperty.infoTooltip.residential.coreRenovationYear.url')"
                    />
                  </label>
                  <input
                    type="number"
                    autocomplete="off"
                    v-model="form.residentials[residentialIndex].residential.coreRenovationYear"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    :ref="calcIdAndRef('core_renovation_year_', residentialIndex)"
                    @keypress="onlyNumber"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyResidentialCoreRenovationYear_' + residentialIndex)"
                  />
                  <span class="help-text float-end">{{ $t('newProperty.infoTooltip.applicable_help_text') }}</span>
                  <span
                    class="error"
                    v-if="form.errors().has('residentials_' + residentialIndex + '_residential_coreRenovationYear')"
                  >
                    {{ form.errors().get('residentials_' + residentialIndex + '_residential_coreRenovationYear') }}
                  </span>
                </div>
              </div>

              <!-- Es besteht eine Abbruchverpflichtung im Jahr -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip('propertyResidentialDemolitionObligationYear_' + residentialIndex)"
              >
                <div class="data-form">
                  <label class="label-title">
                    {{ $t('newProperty.propertyResidential.demolition_obligation_year') }}
                    <InfoTooltip
                      v-if="infoTooltip === 'propertyResidentialDemolitionObligationYear_' + residentialIndex"
                      :content="$t('newProperty.infoTooltip.residential.demolitionObligationYear.content')"
                      :url="$t('newProperty.infoTooltip.residential.demolitionObligationYear.url')"
                    />
                  </label>
                  <input
                    type="number"
                    autocomplete="off"
                    v-model="form.residentials[residentialIndex].residential.demolitionObligationYear"
                    :disabled="isPropertyReadOnly"
                    placeholder=""
                    class="input-field hover-tooltip"
                    :ref="calcIdAndRef('demolition_obligation_year_', residentialIndex)"
                    @keypress="onlyNumber"
                    @keyup.esc="cancel"
                    @keyup.enter="callForNextStep"
                    @focusin="setInfoTooltip('propertyResidentialDemolitionObligationYear_' + residentialIndex)"
                  />
                  <span class="help-text float-end">{{ $t('newProperty.infoTooltip.applicable_help_text') }}</span>
                  <span
                    class="error"
                    v-if="
                      form.errors().has('residentials_' + residentialIndex + '_residential_demolitionObligationYear')
                    "
                  >
                    {{
                      form.errors().get('residentials_' + residentialIndex + '_residential_demolitionObligationYear')
                    }}
                  </span>
                </div>
              </div>

              <!-- Sections Auswahl -->
              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <h2 class="subheading_title">
                  {{ $t('newProperty.propertyResidential.sectionsTitle') }}
                </h2>
                <hr class="subheading_devide" />
              </div>

              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form data-form-flex">
                  <div class="switch mb-2">
                    <input
                      type="checkbox"
                      v-model="form.residentials[residentialIndex].isSectionGarage"
                      true-value="1"
                      false-value="0"
                      :disabled="isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="slider round"
                      @click.prevent="sectionGarageChanged(residentialIndex)"
                    ></span>
                  </div>
                  <span class="checkbox-label-bold">
                    {{ $t('newProperty.propertyResidential.sectionGarage') }}
                  </span>
                </div>
              </div>

              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form data-form-flex">
                  <div class="switch mb-2">
                    <input
                      type="checkbox"
                      v-model="form.residentials[residentialIndex].isSectionBelow60"
                      true-value="1"
                      false-value="0"
                      :disabled="isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="slider round"
                      @click.prevent="sectionBelow60Changed(residentialIndex)"
                    ></span>
                  </div>
                  <span class="checkbox-label-bold">
                    {{ $t('newProperty.propertyResidential.sectionBelow60') }}
                  </span>
                </div>
              </div>

              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form data-form-flex">
                  <div class="switch mb-2">
                    <input
                      type="checkbox"
                      v-model="form.residentials[residentialIndex].isSectionBetween60100"
                      true-value="1"
                      false-value="0"
                      :disabled="isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="slider round"
                      @click.prevent="sectionBetween60100Changed(residentialIndex)"
                    ></span>
                  </div>
                  <span class="checkbox-label-bold">
                    {{ $t('newProperty.propertyResidential.sectionBetween60100') }}
                  </span>
                </div>
              </div>

              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form data-form-flex">
                  <div class="switch mb-2">
                    <input
                      type="checkbox"
                      v-model="form.residentials[residentialIndex].isSectionAbove100"
                      true-value="1"
                      false-value="0"
                      :disabled="isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="slider round"
                      @click.prevent="sectionAbove100Changed(residentialIndex)"
                    ></span>
                  </div>
                  <span class="checkbox-label-bold">
                    {{ $t('newProperty.propertyResidential.sectionAbove100') }}
                  </span>
                </div>
              </div>

              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form data-form-flex">
                  <div class="switch mb-2">
                    <input
                      type="checkbox"
                      v-model="form.residentials[residentialIndex].isSectionOther"
                      true-value="1"
                      false-value="0"
                      :disabled="isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="slider round"
                      @click.prevent="sectionOtherChanged(residentialIndex)"
                    ></span>
                  </div>
                  <span class="checkbox-label-bold">
                    {{ $t('newProperty.propertyResidential.sectionOther') }}
                  </span>
                </div>
              </div>

              <div
                class="col-md-7"
                @mouseenter="setInfoTooltip(0)"
              >
                <div class="data-form data-form-flex">
                  <div class="switch mb-2">
                    <input
                      type="checkbox"
                      v-model="form.residentials[residentialIndex].isSectionUsableSpaces"
                      true-value="1"
                      false-value="0"
                      :disabled="isPropertyReadOnly"
                      class="hover-tooltip"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="slider round"
                      @click.prevent="sectionUsableSpacesChanged(residentialIndex)"
                    ></span>
                  </div>
                  <span class="checkbox-label-bold">
                    {{ $t('newProperty.propertyResidential.sectionUsableSpaces') }}
                  </span>
                </div>
              </div>

              <!-- BEGIN Garagen- und Tiefgaragenstellplätze -->
              <div v-show="form.residentials[residentialIndex].isSectionGarage === '1'">
                <!-- Garagen- und Tiefgaragenstellplätze -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <h2 class="subheading_title">
                    {{ $t('newProperty.propertyResidential.garage_underground_title') }}
                  </h2>
                  <hr class="subheading_devide" />
                </div>

                <!-- Anzahl der Garagen-/Tiefgaragenstellplätze -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip('propertyResidentialAmountGarages_' + residentialIndex)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyResidential.amount_garages') }}
                      <InfoTooltip
                        v-if="infoTooltip === 'propertyResidentialAmountGarages_' + residentialIndex"
                        :content="$t('newProperty.infoTooltip.residential.amountGarages.content')"
                        :url="$t('newProperty.infoTooltip.residential.amountGarages.url')"
                      />
                    </label>
                    <input
                      type="number"
                      autocomplete="off"
                      v-model="form.residentials[residentialIndex].garageUnderground.amountGarages"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      :ref="calcIdAndRef('amount_garages_', residentialIndex)"
                      @keypress="onlyNumber"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip('propertyResidentialAmountGarages_' + residentialIndex)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('residentials_' + residentialIndex + '_garageUnderground_amountGarages')"
                    >
                      {{ form.errors().get('residentials_' + residentialIndex + '_garageUnderground_amountGarages') }}
                    </span>
                  </div>
                </div>

                <!-- v-for begin garageUndergroundExemption -->
                <div
                  v-for="(garageUndergroundExemption, garageUndergroundExemptionIndex) in form.residentials[
                    residentialIndex
                  ].garageUnderground.garageUndergroundExemption"
                  :key="garageUndergroundExemptionIndex"
                >
                  <!-- Befreiung 1 -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <br v-show="garageUndergroundExemptionIndex > 0" />
                    <hr
                      class="subheading_devide"
                      v-show="garageUndergroundExemptionIndex > 0"
                    />
                    <div class="deleteIcon delete_">
                      <h5 class="subheading_title_inner tooltip-icon">
                        {{ $t('newProperty.propertyResidential.exemption') }}
                        {{ garageUndergroundExemptionIndex + 1 }} &nbsp;
                        <div class="tool_tip_box">
                          <Svgall
                            class="tool_tip_svg hover-tooltip"
                            data-bs-toggle="dropdown"
                            :data-target="
                              calcIdAndRef(
                                'garage_underground_exemption_',
                                residentialIndex,
                                garageUndergroundExemptionIndex,
                              )
                            "
                            :name="'tooltip_icon'"
                            :color="primaryColor"
                            @focusin="setInfoTooltip(0)"
                          />
                          <div
                            class="label-title dropdown-menu ToolTipTextBox"
                            :id="
                              calcIdAndRef(
                                'garage_underground_exemption_',
                                residentialIndex,
                                garageUndergroundExemptionIndex,
                              )
                            "
                          >
                            {{ $t('newProperty.propertyResidential.exemption_tooltip1') }}
                          </div>
                        </div>
                      </h5>
                      <Svgall
                        @click="removeGarageUndergroundExemption(residentialIndex, garageUndergroundExemptionIndex)"
                        :name="'delete'"
                        :color="primaryColor"
                        v-show="!isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      />
                    </div>
                  </div>

                  <!-- Lage der Räume / Bezeichnung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.location_rooms_designation') }}
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].garageUnderground.garageUndergroundExemption[
                            garageUndergroundExemptionIndex
                          ].locationOfTheRoomsDesignation
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                garageUndergroundExemptionIndex +
                                '_garageUndergroundExemption_locationOfTheRoomsDesignation',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                garageUndergroundExemptionIndex +
                                '_garageUndergroundExemption_locationOfTheRoomsDesignation',
                            )
                        }}
                      </span>
                    </div>
                  </div>

                  <!-- Befreiung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.exemption') }}
                      </label>
                      <select
                        v-model="
                          form.residentials[residentialIndex].garageUnderground.garageUndergroundExemption[
                            garageUndergroundExemptionIndex
                          ].exemption
                        "
                        :disabled="isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      >
                        <option
                          v-for="option in exemption_options"
                          v-bind:key="option.code"
                          :value="option.code"
                        >
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- Steuerfreie Fläche in m² -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.tax_free_area') }}
                      </label>
                      <input
                        type="number"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].garageUnderground.garageUndergroundExemption[
                            garageUndergroundExemptionIndex
                          ].taxFreeArea
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keypress="onlyNumber"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                garageUndergroundExemptionIndex +
                                '_garageUndergroundExemption_taxFreeArea',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                garageUndergroundExemptionIndex +
                                '_garageUndergroundExemption_taxFreeArea',
                            )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- v-for end garageUndergroundExemption -->

                <div
                  class="col-md-7"
                  v-show="exemption_activated"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="add-field no_space">
                    <button
                      class="add-field-button hover-tooltip"
                      :disabled="isPropertyReadOnly"
                      @click="addGarageUndergroundExemption(residentialIndex)"
                      @focusin="setInfoTooltip(0)"
                    >
                      <Svgall
                        :name="'add_more'"
                        :color="primaryColor"
                      />
                      <span>{{ $t('newProperty.propertyResidential.add_more_exemption') }}</span>
                    </button>
                  </div>
                </div>

                <!-- v-for begin garageUndergroundBenefit -->
                <div
                  v-for="(garageUndergroundBenefit, garageUndergroundBenefitIndex) in form.residentials[
                    residentialIndex
                  ].garageUnderground.garageUndergroundBenefit"
                  :key="garageUndergroundBenefitIndex"
                >
                  <!-- Vergünstigung 1 -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <br v-show="garageUndergroundBenefitIndex > 0" />
                    <hr
                      class="subheading_devide"
                      v-show="garageUndergroundBenefitIndex > 0"
                    />
                    <div class="deleteIcon delete_">
                      <h5 class="subheading_title_inner tooltip-icon">
                        {{ $t('newProperty.propertyResidential.garage_underground_benefit') }}
                        {{ garageUndergroundBenefitIndex + 1 }} &nbsp;
                        <div class="tool_tip_box">
                          <Svgall
                            class="tool_tip_svg hover-tooltip"
                            data-bs-toggle="dropdown"
                            :data-target="
                              calcIdAndRef(
                                'garage_underground_benefit_',
                                residentialIndex,
                                garageUndergroundBenefitIndex,
                              )
                            "
                            :name="'tooltip_icon'"
                            :color="primaryColor"
                            @focusin="setInfoTooltip(0)"
                          />
                          <div
                            class="label-title dropdown-menu ToolTipTextBox"
                            :id="
                              calcIdAndRef(
                                'garage_underground_benefit_',
                                residentialIndex,
                                garageUndergroundBenefitIndex,
                              )
                            "
                          >
                            {{ $t('newProperty.propertyResidential.benefit_tooltip1') }}
                          </div>
                        </div>
                      </h5>
                      <Svgall
                        @click="removeGarageUndergroundBenefit(residentialIndex, garageUndergroundBenefitIndex)"
                        :name="'delete'"
                        :color="primaryColor"
                        v-show="!isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      />
                    </div>
                  </div>

                  <!-- Lage der Räume / Bezeichnung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.location_rooms_designation') }}
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].garageUnderground.garageUndergroundBenefit[
                            garageUndergroundBenefitIndex
                          ].locationOfTheRoomsDesignation
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                garageUndergroundBenefitIndex +
                                '_garageUndergroundBenefit_locationOfTheRoomsDesignation',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                garageUndergroundBenefitIndex +
                                '_garageUndergroundBenefit_locationOfTheRoomsDesignation',
                            )
                        }}
                      </span>
                    </div>
                  </div>

                  <!-- Vergünstigung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.garage_underground_benefit') }}
                      </label>
                      <select
                        v-model="
                          form.residentials[residentialIndex].garageUnderground.garageUndergroundBenefit[
                            garageUndergroundBenefitIndex
                          ].benefit
                        "
                        :disabled="isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      >
                        <option
                          v-for="option in benefit_options"
                          v-bind:key="option.code"
                          :value="option.code"
                        >
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- Vergünstigte Fläche in m² -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.discounted_area') }}
                      </label>
                      <input
                        type="number"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].garageUnderground.garageUndergroundBenefit[
                            garageUndergroundBenefitIndex
                          ].discountedArea
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keypress="onlyNumber"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                garageUndergroundBenefitIndex +
                                '_garageUndergroundBenefit_discountedArea',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                garageUndergroundBenefitIndex +
                                '_garageUndergroundBenefit_discountedArea',
                            )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- v-for end garageUndergroundBenefit -->

                <div
                  class="col-md-7"
                  v-show="exemption_activated"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="add-field no_space">
                    <button
                      class="add-field-button hover-tooltip"
                      :disabled="isPropertyReadOnly"
                      @click="addGarageUndergroundBenefit(residentialIndex)"
                      @focusin="setInfoTooltip(0)"
                    >
                      <Svgall
                        :name="'add_more'"
                        :color="primaryColor"
                      />
                      <span>{{ $t('newProperty.propertyResidential.add_more_benefit') }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- END Garagen- und Tiefgaragenstellplätze -->

              <!-- BEGIN Wohnungen unter 60 m² -->
              <div v-show="form.residentials[residentialIndex].isSectionBelow60 === '1'">
                <!-- Wohnungen unter 60 m² -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <h2 class="subheading_title">
                    {{ $t('newProperty.propertyResidential.apartments_below_60_title') }}
                  </h2>
                  <hr class="subheading_devide" />
                </div>

                <!-- Anzahl Wohnungen mit Wohnfläche unter 60 m² -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyResidential.apartments_below_60_counter') }}
                    </label>
                    <input
                      type="number"
                      autocomplete="off"
                      v-model="form.residentials[residentialIndex].apartmentsBelow60.counter"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keypress="onlyNumber"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('residentials_' + residentialIndex + '_apartmentsBelow60_counter')"
                    >
                      {{ form.errors().get('residentials_' + residentialIndex + '_apartmentsBelow60_counter') }}
                    </span>
                  </div>
                </div>

                <!-- Gesamtgröße der Wohnungen mit Wohnfläche unter 60 m² -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyResidential.apartments_below_60_living_space') }}
                    </label>
                    <input
                      type="number"
                      autocomplete="off"
                      v-model="form.residentials[residentialIndex].apartmentsBelow60.totalLivingSpace"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keypress="onlyNumber"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="
                        form.errors().has('residentials_' + residentialIndex + '_apartmentsBelow60_totalLivingSpace')
                      "
                    >
                      {{
                        form.errors().get('residentials_' + residentialIndex + '_apartmentsBelow60_totalLivingSpace')
                      }}
                    </span>
                  </div>
                </div>

                <!-- v-for begin apartmentsBelow60Exemption -->
                <div
                  v-for="(apartmentsBelow60Exemption, apartmentsBelow60ExemptionIndex) in form.residentials[
                    residentialIndex
                  ].apartmentsBelow60.apartmentsBelow60Exemption"
                  :key="apartmentsBelow60ExemptionIndex"
                >
                  <!-- Befreiung 1 -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <br v-show="apartmentsBelow60ExemptionIndex > 0" />
                    <hr
                      class="subheading_devide"
                      v-show="apartmentsBelow60ExemptionIndex > 0"
                    />
                    <div class="deleteIcon delete_">
                      <h5 class="subheading_title_inner">
                        {{ $t('newProperty.propertyResidential.exemption') }} {{ apartmentsBelow60ExemptionIndex + 1 }}
                      </h5>
                      <Svgall
                        @click="removeApartmentsBelow60Exemption(residentialIndex, apartmentsBelow60ExemptionIndex)"
                        :name="'delete'"
                        :color="primaryColor"
                        v-show="!isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      />
                    </div>
                  </div>

                  <!-- Lage der Räume / Bezeichnung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.location_rooms_designation') }}
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsBelow60.apartmentsBelow60Exemption[
                            apartmentsBelow60ExemptionIndex
                          ].locationOfTheRoomsDesignation
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBelow60ExemptionIndex +
                                '_apartmentsBelow60Exemption_locationOfTheRoomsDesignation',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBelow60ExemptionIndex +
                                '_apartmentsBelow60Exemption_locationOfTheRoomsDesignation',
                            )
                        }}
                      </span>
                    </div>
                  </div>

                  <!-- Befreiung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.exemption') }}
                      </label>
                      <select
                        v-model="
                          form.residentials[residentialIndex].apartmentsBelow60.apartmentsBelow60Exemption[
                            apartmentsBelow60ExemptionIndex
                          ].exemption
                        "
                        :disabled="isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      >
                        <option
                          v-for="option in exemption_options"
                          v-bind:key="option.code"
                          :value="option.code"
                        >
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- Steuerfreie Fläche in m² -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.tax_free_area') }}
                      </label>
                      <input
                        type="number"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsBelow60.apartmentsBelow60Exemption[
                            apartmentsBelow60ExemptionIndex
                          ].taxFreeArea
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keypress="onlyNumber"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBelow60ExemptionIndex +
                                '_apartmentsBelow60Exemption_taxFreeArea',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBelow60ExemptionIndex +
                                '_apartmentsBelow60Exemption_taxFreeArea',
                            )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- v-for end apartmentsBelow60Exemption -->

                <div
                  class="col-md-7"
                  v-show="exemption_activated"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="add-field no_space">
                    <button
                      class="add-field-button hover-tooltip"
                      :disabled="isPropertyReadOnly"
                      @click="addApartmentsBelow60Exemption(residentialIndex)"
                      @focusin="setInfoTooltip(0)"
                    >
                      <Svgall
                        :name="'add_more'"
                        :color="primaryColor"
                      />
                      <span>{{ $t('newProperty.propertyResidential.add_more_exemption') }}</span>
                    </button>
                  </div>
                </div>

                <!-- v-for begin apartmentsBelow60Benefit -->
                <div
                  v-for="(apartmentsBelow60Benefit, apartmentsBelow60BenefitIndex) in form.residentials[
                    residentialIndex
                  ].apartmentsBelow60.apartmentsBelow60Benefit"
                  :key="apartmentsBelow60BenefitIndex"
                >
                  <!-- Vergünstigung 1 -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <br v-show="apartmentsBelow60BenefitIndex > 0" />
                    <hr
                      class="subheading_devide"
                      v-show="apartmentsBelow60BenefitIndex > 0"
                    />
                    <div class="deleteIcon delete_">
                      <h5 class="subheading_title_inner">
                        {{ $t('newProperty.propertyResidential.benefit') }} {{ apartmentsBelow60BenefitIndex + 1 }}
                      </h5>
                      <Svgall
                        @click="removeApartmentsBelow60Benefit(residentialIndex, apartmentsBelow60BenefitIndex)"
                        :name="'delete'"
                        :color="primaryColor"
                        v-show="!isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      />
                    </div>
                  </div>

                  <!-- Lage der Räume / Bezeichnung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.location_rooms_designation') }}
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsBelow60.apartmentsBelow60Benefit[
                            apartmentsBelow60BenefitIndex
                          ].locationOfTheRoomsDesignation
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBelow60BenefitIndex +
                                '_apartmentsBelow60Benefit_locationOfTheRoomsDesignation',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBelow60BenefitIndex +
                                '_apartmentsBelow60Benefit_locationOfTheRoomsDesignation',
                            )
                        }}
                      </span>
                    </div>
                  </div>

                  <!-- Vergünstigung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.benefit') }}
                      </label>
                      <select
                        v-model="
                          form.residentials[residentialIndex].apartmentsBelow60.apartmentsBelow60Benefit[
                            apartmentsBelow60BenefitIndex
                          ].benefit
                        "
                        :disabled="isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      >
                        <option
                          v-for="option in benefit_options"
                          v-bind:key="option.code"
                          :value="option.code"
                        >
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- Vergünstigte Fläche in m² -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.discounted_area') }}
                      </label>
                      <input
                        type="number"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsBelow60.apartmentsBelow60Benefit[
                            apartmentsBelow60BenefitIndex
                          ].discountedArea
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keypress="onlyNumber"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBelow60BenefitIndex +
                                '_apartmentsBelow60Benefit_discountedArea',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBelow60BenefitIndex +
                                '_apartmentsBelow60Benefit_discountedArea',
                            )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- v-for end apartmentsBelow60Benefit -->

                <div
                  class="col-md-7"
                  v-show="exemption_activated"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="add-field no_space">
                    <button
                      class="add-field-button hover-tooltip"
                      :disabled="isPropertyReadOnly"
                      @click="addApartmentsBelow60Benefit(residentialIndex)"
                      @focusin="setInfoTooltip(0)"
                    >
                      <Svgall
                        :name="'add_more'"
                        :color="primaryColor"
                      />
                      <span>{{ $t('newProperty.propertyResidential.add_more_benefit') }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- END Wohnungen unter 60 m² -->

              <!-- BEGIN Wohnungen mit einer Wohnfläche von 60 m² bis unter 100 m² -->
              <div v-show="form.residentials[residentialIndex].isSectionBetween60100 === '1'">
                <!-- Wohnungen mit einer Wohnfläche von 60 m² bis unter 100 m² -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <h2 class="subheading_title">
                    {{ $t('newProperty.propertyResidential.apartments_between_60100_title') }}
                  </h2>
                  <hr class="subheading_devide" />
                </div>

                <!-- Anzahl Wohnungen mit einer Wohnfläche von 60 m² bis unter 100 m² -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyResidential.apartments_between_60100_counter') }}
                    </label>
                    <input
                      type="number"
                      autocomplete="off"
                      v-model="form.residentials[residentialIndex].apartmentsBetween60100.counter"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keypress="onlyNumber"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('residentials_' + residentialIndex + '_apartmentsBetween60100_counter')"
                    >
                      {{ form.errors().get('residentials_' + residentialIndex + '_apartmentsBetween60100_counter') }}
                    </span>
                  </div>
                </div>

                <!-- Gesamtgröße der Wohnungen mit einer Wohnfläche von 60 m² bis unter 100 m² -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyResidential.apartments_between_60100_living_space') }}
                    </label>
                    <input
                      type="number"
                      autocomplete="off"
                      v-model="form.residentials[residentialIndex].apartmentsBetween60100.totalLivingSpace"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keypress="onlyNumber"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="
                        form
                          .errors()
                          .has('residentials_' + residentialIndex + '_apartmentsBetween60100_totalLivingSpace')
                      "
                    >
                      {{
                        form
                          .errors()
                          .get('residentials_' + residentialIndex + '_apartmentsBetween60100_totalLivingSpace')
                      }}
                    </span>
                  </div>
                </div>

                <!-- v-for begin apartmentsBetween60100Exemption -->
                <div
                  v-for="(apartmentsBetween60100Exemption, apartmentsBetween60100ExemptionIndex) in form.residentials[
                    residentialIndex
                  ].apartmentsBetween60100.apartmentsBetween60100Exemption"
                  :key="apartmentsBetween60100ExemptionIndex"
                >
                  <!-- Befreiung 1 -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <br v-show="apartmentsBetween60100ExemptionIndex > 0" />
                    <hr
                      class="subheading_devide"
                      v-show="apartmentsBetween60100ExemptionIndex > 0"
                    />
                    <div class="deleteIcon delete_">
                      <h5 class="subheading_title_inner">
                        {{ $t('newProperty.propertyResidential.exemption') }}
                        {{ apartmentsBetween60100ExemptionIndex + 1 }}
                      </h5>
                      <Svgall
                        @click="
                          removeApartmentsBetween60100Exemption(residentialIndex, apartmentsBetween60100ExemptionIndex)
                        "
                        :name="'delete'"
                        :color="primaryColor"
                        v-show="!isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      />
                    </div>
                  </div>

                  <!-- Lage der Räume / Bezeichnung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.location_rooms_designation') }}
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsBetween60100.apartmentsBetween60100Exemption[
                            apartmentsBetween60100ExemptionIndex
                          ].locationOfTheRoomsDesignation
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @mouseenter="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBetween60100ExemptionIndex +
                                '_apartmentsBetween60100Exemption_locationOfTheRoomsDesignation',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBetween60100ExemptionIndex +
                                '_apartmentsBetween60100Exemption_locationOfTheRoomsDesignation',
                            )
                        }}
                      </span>
                    </div>
                  </div>

                  <!-- Befreiung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.exemption') }}
                      </label>
                      <select
                        v-model="
                          form.residentials[residentialIndex].apartmentsBetween60100.apartmentsBetween60100Exemption[
                            apartmentsBetween60100ExemptionIndex
                          ].exemption
                        "
                        :disabled="isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      >
                        <option
                          v-for="option in exemption_options"
                          v-bind:key="option.code"
                          :value="option.code"
                        >
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- Steuerfreie Fläche in m² -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.tax_free_area') }}
                      </label>
                      <input
                        type="number"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsBetween60100.apartmentsBetween60100Exemption[
                            apartmentsBetween60100ExemptionIndex
                          ].taxFreeArea
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keypress="onlyNumber"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBetween60100ExemptionIndex +
                                '_apartmentsBetween60100Exemption_taxFreeArea',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBetween60100ExemptionIndex +
                                '_apartmentsBetween60100Exemption_taxFreeArea',
                            )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- v-for end apartmentsBetween60100Exemption -->

                <div
                  class="col-md-7"
                  v-show="exemption_activated"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="add-field no_space">
                    <button
                      class="add-field-button hover-tooltip"
                      :disabled="isPropertyReadOnly"
                      @click="addApartmentsBetween60100Exemption(residentialIndex)"
                      @focusin="setInfoTooltip(0)"
                    >
                      <Svgall
                        :name="'add_more'"
                        :color="primaryColor"
                      />
                      <span>{{ $t('newProperty.propertyResidential.add_more_exemption') }}</span>
                    </button>
                  </div>
                </div>

                <!-- v-for begin apartmentsBetween60100Benefit -->
                <div
                  v-for="(apartmentsBetween60100Benefit, apartmentsBetween60100BenefitIndex) in form.residentials[
                    residentialIndex
                  ].apartmentsBetween60100.apartmentsBetween60100Benefit"
                  :key="apartmentsBetween60100BenefitIndex"
                >
                  <!-- Vergünstigung 1 -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <br v-show="apartmentsBetween60100BenefitIndex > 0" />
                    <hr
                      class="subheading_devide"
                      v-show="apartmentsBetween60100BenefitIndex > 0"
                    />
                    <div class="deleteIcon delete_">
                      <h5 class="subheading_title_inner">
                        {{ $t('newProperty.propertyResidential.benefit') }} {{ apartmentsBetween60100BenefitIndex + 1 }}
                      </h5>
                      <Svgall
                        @click="
                          removeApartmentsBetween60100Benefit(residentialIndex, apartmentsBetween60100BenefitIndex)
                        "
                        :name="'delete'"
                        :color="primaryColor"
                        v-show="!isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      />
                    </div>
                  </div>

                  <!-- Lage der Räume / Bezeichnung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.location_rooms_designation') }}
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsBetween60100.apartmentsBetween60100Benefit[
                            apartmentsBetween60100BenefitIndex
                          ].locationOfTheRoomsDesignation
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBetween60100BenefitIndex +
                                '_apartmentsBetween60100Benefit_locationOfTheRoomsDesignation',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBetween60100BenefitIndex +
                                '_apartmentsBetween60100Benefit_locationOfTheRoomsDesignation',
                            )
                        }}
                      </span>
                    </div>
                  </div>

                  <!-- Vergünstigung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.benefit') }}
                      </label>
                      <select
                        v-model="
                          form.residentials[residentialIndex].apartmentsBetween60100.apartmentsBetween60100Benefit[
                            apartmentsBetween60100BenefitIndex
                          ].benefit
                        "
                        :disabled="isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      >
                        <option
                          v-for="option in benefit_options"
                          v-bind:key="option.code"
                          :value="option.code"
                        >
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- Vergünstigte Fläche in m² -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.discounted_area') }}
                      </label>
                      <input
                        type="number"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsBetween60100.apartmentsBetween60100Benefit[
                            apartmentsBetween60100BenefitIndex
                          ].discountedArea
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keypress="onlyNumber"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBetween60100BenefitIndex +
                                '_apartmentsBetween60100Benefit_discountedArea',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsBetween60100BenefitIndex +
                                '_apartmentsBetween60100Benefit_discountedArea',
                            )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- v-for end apartmentsBetween60100Benefit -->

                <div
                  class="col-md-7"
                  v-show="exemption_activated"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="add-field no_space">
                    <button
                      class="add-field-button hover-tooltip"
                      :disabled="isPropertyReadOnly"
                      @click="addApartmentsBetween60100Benefit(residentialIndex)"
                      @focusin="setInfoTooltip(0)"
                    >
                      <Svgall
                        :name="'add_more'"
                        :color="primaryColor"
                      />
                      <span>{{ $t('newProperty.propertyResidential.add_more_benefit') }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- END Wohnungen mit einer Wohnfläche von 60 m² bis unter 100 m² -->

              <!-- BEGIN Wohnungen mit einer Wohnfläche von 100 m² und mehr -->
              <div v-show="form.residentials[residentialIndex].isSectionAbove100 === '1'">
                <!-- Wohnungen mit einer Wohnfläche von 100 m² und mehr -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <h2 class="subheading_title">
                    {{ $t('newProperty.propertyResidential.apartments_above_100_title') }}
                  </h2>
                  <hr class="subheading_devide" />
                </div>

                <!-- Anzahl Wohnungen mit einer Wohnfläche von 100 m² und mehr -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyResidential.apartments_above_100_counter') }}
                    </label>
                    <input
                      type="number"
                      autocomplete="off"
                      v-model="form.residentials[residentialIndex].apartmentsAbove100.counter"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keypress="onlyNumber"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('residentials_' + residentialIndex + '_apartmentsAbove100_counter')"
                    >
                      {{ form.errors().get('residentials_' + residentialIndex + '_apartmentsAbove100_counter') }}
                    </span>
                  </div>
                </div>

                <!-- Gesamtgröße der Wohnungen mit einer Wohnfläche von 100 m² und mehr -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyResidential.apartments_above_100_living_space') }}
                    </label>
                    <input
                      type="number"
                      autocomplete="off"
                      v-model="form.residentials[residentialIndex].apartmentsAbove100.totalLivingSpace"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keypress="onlyNumber"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="
                        form.errors().has('residentials_' + residentialIndex + '_apartmentsAbove100_totalLivingSpace')
                      "
                    >
                      {{
                        form.errors().get('residentials_' + residentialIndex + '_apartmentsAbove100_totalLivingSpace')
                      }}
                    </span>
                  </div>
                </div>

                <!-- v-for begin apartmentsAbove100Exemption -->
                <div
                  v-for="(apartmentsAbove100Exemption, apartmentsAbove100ExemptionIndex) in form.residentials[
                    residentialIndex
                  ].apartmentsAbove100.apartmentsAbove100Exemption"
                  :key="apartmentsAbove100ExemptionIndex"
                >
                  <!-- Befreiung 1 -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <br v-show="apartmentsAbove100ExemptionIndex > 0" />
                    <hr
                      class="subheading_devide"
                      v-show="apartmentsAbove100ExemptionIndex > 0"
                    />
                    <div class="deleteIcon delete_">
                      <h5 class="subheading_title_inner">
                        {{ $t('newProperty.propertyResidential.exemption') }} {{ apartmentsAbove100ExemptionIndex + 1 }}
                      </h5>
                      <Svgall
                        @click="removeApartmentsAbove100Exemption(residentialIndex, apartmentsAbove100ExemptionIndex)"
                        :name="'delete'"
                        :color="primaryColor"
                        v-show="!isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      />
                    </div>
                  </div>

                  <!-- Lage der Räume / Bezeichnung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.location_rooms_designation') }}
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsAbove100.apartmentsAbove100Exemption[
                            apartmentsAbove100ExemptionIndex
                          ].locationOfTheRoomsDesignation
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsAbove100ExemptionIndex +
                                '_apartmentsAbove100Exemption_locationOfTheRoomsDesignation',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsAbove100ExemptionIndex +
                                '_apartmentsAbove100Exemption_locationOfTheRoomsDesignation',
                            )
                        }}
                      </span>
                    </div>
                  </div>

                  <!-- Befreiung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.exemption') }}
                      </label>
                      <select
                        v-model="
                          form.residentials[residentialIndex].apartmentsAbove100.apartmentsAbove100Exemption[
                            apartmentsAbove100ExemptionIndex
                          ].exemption
                        "
                        :disabled="isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      >
                        <option
                          v-for="option in exemption_options"
                          v-bind:key="option.code"
                          :value="option.code"
                        >
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- Steuerfreie Fläche in m² -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.tax_free_area') }}
                      </label>
                      <input
                        type="number"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsAbove100.apartmentsAbove100Exemption[
                            apartmentsAbove100ExemptionIndex
                          ].taxFreeArea
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keypress="onlyNumber"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsAbove100ExemptionIndex +
                                '_apartmentsAbove100Exemption_taxFreeArea',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsAbove100ExemptionIndex +
                                '_apartmentsAbove100Exemption_taxFreeArea',
                            )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- v-for end apartmentsAbove100Exemption -->

                <div
                  class="col-md-7"
                  v-show="exemption_activated"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="add-field no_space">
                    <button
                      class="add-field-button hover-tooltip"
                      :disabled="isPropertyReadOnly"
                      @click="addApartmentsAbove100Exemption(residentialIndex)"
                      @focusin="setInfoTooltip(0)"
                    >
                      <Svgall
                        :name="'add_more'"
                        :color="primaryColor"
                      />
                      <span>{{ $t('newProperty.propertyResidential.add_more_exemption') }}</span>
                    </button>
                  </div>
                </div>

                <!-- v-for begin apartmentsAbove100Benefit -->
                <div
                  v-for="(apartmentsAbove100Benefit, apartmentsAbove100BenefitIndex) in form.residentials[
                    residentialIndex
                  ].apartmentsAbove100.apartmentsAbove100Benefit"
                  :key="apartmentsAbove100BenefitIndex"
                >
                  <!-- Vergünstigung 1 -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <br v-show="apartmentsAbove100BenefitIndex > 0" />
                    <hr
                      class="subheading_devide"
                      v-show="apartmentsAbove100BenefitIndex > 0"
                    />
                    <div class="deleteIcon delete_">
                      <h5 class="subheading_title_inner">
                        {{ $t('newProperty.propertyResidential.benefit') }} {{ apartmentsAbove100BenefitIndex + 1 }}
                      </h5>
                      <Svgall
                        @click="removeApartmentsAbove100Benefit(residentialIndex, apartmentsAbove100BenefitIndex)"
                        :name="'delete'"
                        :color="primaryColor"
                        v-show="!isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      />
                    </div>
                  </div>

                  <!-- Lage der Räume / Bezeichnung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.location_rooms_designation') }}
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsAbove100.apartmentsAbove100Benefit[
                            apartmentsAbove100BenefitIndex
                          ].locationOfTheRoomsDesignation
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsAbove100BenefitIndex +
                                '_apartmentsAbove100Benefit_locationOfTheRoomsDesignation',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsAbove100BenefitIndex +
                                '_apartmentsAbove100Benefit_locationOfTheRoomsDesignation',
                            )
                        }}
                      </span>
                    </div>
                  </div>

                  <!-- Vergünstigung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.benefit') }}
                      </label>
                      <select
                        v-model="
                          form.residentials[residentialIndex].apartmentsAbove100.apartmentsAbove100Benefit[
                            apartmentsAbove100BenefitIndex
                          ].benefit
                        "
                        :disabled="isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      >
                        <option
                          v-for="option in benefit_options"
                          v-bind:key="option.code"
                          :value="option.code"
                        >
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- Vergünstigte Fläche in m² -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.discounted_area') }}
                      </label>
                      <input
                        type="number"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsAbove100.apartmentsAbove100Benefit[
                            apartmentsAbove100BenefitIndex
                          ].discountedArea
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keypress="onlyNumber"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsAbove100BenefitIndex +
                                '_apartmentsAbove100Benefit_discountedArea',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsAbove100BenefitIndex +
                                '_apartmentsAbove100Benefit_discountedArea',
                            )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- v-for end apartmentsAbove100Benefit -->

                <div
                  class="col-md-7"
                  v-show="exemption_activated"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="add-field no_space">
                    <button
                      class="add-field-button hover-tooltip"
                      :disabled="isPropertyReadOnly"
                      @click="addApartmentsAbove100Benefit(residentialIndex)"
                      @focusin="setInfoTooltip(0)"
                    >
                      <Svgall
                        :name="'add_more'"
                        :color="primaryColor"
                      />
                      <span>{{ $t('newProperty.propertyResidential.add_more_benefit') }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- END Wohnungen mit einer Wohnfläche von 100 m² und mehr -->

              <!-- BEGIN weitere Wohnungen -->
              <div v-show="form.residentials[residentialIndex].isSectionOther === '1'">
                <!-- weitere Wohnungen -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <h2 class="subheading_title">
                    {{ $t('newProperty.propertyResidential.apartments_other_title') }}
                  </h2>
                  <hr class="subheading_devide" />
                </div>

                <!-- Anzahl Wohnungen, die nicht in anderen Feldern enthalten sind -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyResidential.apartments_other_counter') }}
                    </label>
                    <input
                      type="number"
                      autocomplete="off"
                      v-model="form.residentials[residentialIndex].apartmentsOther.counter"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keypress="onlyNumber"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('residentials_' + residentialIndex + '_apartmentsOther_counter')"
                    >
                      {{ form.errors().get('residentials_' + residentialIndex + '_apartmentsOther_counter') }}
                    </span>
                  </div>
                </div>

                <!-- Gesamtgröße der Wohnungen, die nicht in anderen Feldern enthalten sind -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="data-form">
                    <label class="label-title">
                      {{ $t('newProperty.propertyResidential.apartments_other_living_space') }}
                    </label>
                    <input
                      type="number"
                      autocomplete="off"
                      v-model="form.residentials[residentialIndex].apartmentsOther.totalLivingSpace"
                      :disabled="isPropertyReadOnly"
                      placeholder=""
                      class="input-field hover-tooltip"
                      @keypress="onlyNumber"
                      @keyup.esc="cancel"
                      @keyup.enter="callForNextStep"
                      @focusin="setInfoTooltip(0)"
                    />
                    <span
                      class="error"
                      v-if="form.errors().has('residentials_' + residentialIndex + '_apartmentsOther_totalLivingSpace')"
                    >
                      {{ form.errors().get('residentials_' + residentialIndex + '_apartmentsOther_totalLivingSpace') }}
                    </span>
                  </div>
                </div>

                <!-- v-for begin apartmentsOtherExemption -->
                <div
                  v-for="(apartmentsOtherExemption, apartmentsOtherExemptionIndex) in form.residentials[
                    residentialIndex
                  ].apartmentsOther.apartmentsOtherExemption"
                  :key="apartmentsOtherExemptionIndex"
                >
                  <!-- Befreiung 1 -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <br v-show="apartmentsOtherExemptionIndex > 0" />
                    <hr
                      class="subheading_devide"
                      v-show="apartmentsOtherExemptionIndex > 0"
                    />
                    <div class="deleteIcon delete_">
                      <h5 class="subheading_title_inner">
                        {{ $t('newProperty.propertyResidential.exemption') }} {{ apartmentsOtherExemptionIndex + 1 }}
                      </h5>
                      <Svgall
                        @click="removeApartmentsOtherExemption(residentialIndex, apartmentsOtherExemptionIndex)"
                        :name="'delete'"
                        :color="primaryColor"
                        v-show="!isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      />
                    </div>
                  </div>

                  <!-- Lage der Räume / Bezeichnung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.location_rooms_designation') }}
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsOther.apartmentsOtherExemption[
                            apartmentsOtherExemptionIndex
                          ].locationOfTheRoomsDesignation
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsOtherExemptionIndex +
                                '_apartmentsOtherExemption_locationOfTheRoomsDesignation',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsOtherExemptionIndex +
                                '_apartmentsOtherExemption_locationOfTheRoomsDesignation',
                            )
                        }}
                      </span>
                    </div>
                  </div>

                  <!-- Befreiung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.exemption') }}
                      </label>
                      <select
                        v-model="
                          form.residentials[residentialIndex].apartmentsOther.apartmentsOtherExemption[
                            apartmentsOtherExemptionIndex
                          ].exemption
                        "
                        :disabled="isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      >
                        <option
                          v-for="option in exemption_options"
                          v-bind:key="option.code"
                          :value="option.code"
                        >
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- Steuerfreie Fläche in m² -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.tax_free_area') }}
                      </label>
                      <input
                        type="number"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsOther.apartmentsOtherExemption[
                            apartmentsOtherExemptionIndex
                          ].taxFreeArea
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keypress="onlyNumber"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsOtherExemptionIndex +
                                '_apartmentsOtherExemption_taxFreeArea',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsOtherExemptionIndex +
                                '_apartmentsOtherExemption_taxFreeArea',
                            )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- v-for end apartmentsOtherExemption -->

                <div
                  class="col-md-7"
                  v-show="exemption_activated"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="add-field no_space">
                    <button
                      class="add-field-button hover-tooltip"
                      :disabled="isPropertyReadOnly"
                      @click="addApartmentsOtherExemption(residentialIndex)"
                      @focusin="setInfoTooltip(0)"
                    >
                      <Svgall
                        :name="'add_more'"
                        :color="primaryColor"
                      />
                      <span>{{ $t('newProperty.propertyResidential.add_more_exemption') }}</span>
                    </button>
                  </div>
                </div>

                <!-- v-for begin apartmentsOtherBenefit -->
                <div
                  v-for="(apartmentsOtherBenefit, apartmentsOtherBenefitIndex) in form.residentials[residentialIndex]
                    .apartmentsOther.apartmentsOtherBenefit"
                  :key="apartmentsOtherBenefitIndex"
                >
                  <!-- Vergünstigung 1 -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <br v-show="apartmentsOtherBenefitIndex > 0" />
                    <hr
                      class="subheading_devide"
                      v-show="apartmentsOtherBenefitIndex > 0"
                    />
                    <div class="deleteIcon delete_">
                      <h5 class="subheading_title_inner">
                        {{ $t('newProperty.propertyResidential.benefit') }} {{ apartmentsOtherBenefitIndex + 1 }}
                      </h5>
                      <Svgall
                        @click="removeApartmentsOtherBenefit(residentialIndex, apartmentsOtherBenefitIndex)"
                        :name="'delete'"
                        :color="primaryColor"
                        v-show="!isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      />
                    </div>
                  </div>

                  <!-- Lage der Räume / Bezeichnung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.location_rooms_designation') }}
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsOther.apartmentsOtherBenefit[
                            apartmentsOtherBenefitIndex
                          ].locationOfTheRoomsDesignation
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsOtherBenefitIndex +
                                '_apartmentsOtherBenefit_locationOfTheRoomsDesignation',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsOtherBenefitIndex +
                                '_apartmentsOtherBenefit_locationOfTheRoomsDesignation',
                            )
                        }}
                      </span>
                    </div>
                  </div>

                  <!-- Vergünstigung -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.benefit') }}
                      </label>
                      <select
                        v-model="
                          form.residentials[residentialIndex].apartmentsOther.apartmentsOtherBenefit[
                            apartmentsOtherBenefitIndex
                          ].benefit
                        "
                        :disabled="isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      >
                        <option
                          v-for="option in benefit_options"
                          v-bind:key="option.code"
                          :value="option.code"
                        >
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- Vergünstigte Fläche in m² -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="data-form">
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.discounted_area') }}
                      </label>
                      <input
                        type="number"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].apartmentsOther.apartmentsOtherBenefit[
                            apartmentsOtherBenefitIndex
                          ].discountedArea
                        "
                        :disabled="isPropertyReadOnly"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keypress="onlyNumber"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="setInfoTooltip(0)"
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsOtherBenefitIndex +
                                '_apartmentsOtherBenefit_discountedArea',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                apartmentsOtherBenefitIndex +
                                '_apartmentsOtherBenefit_discountedArea',
                            )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- v-for end apartmentsOtherBenefit -->

                <div
                  class="col-md-7"
                  v-show="exemption_activated"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="add-field no_space">
                    <button
                      class="add-field-button hover-tooltip"
                      :disabled="isPropertyReadOnly"
                      @click="addApartmentsOtherBenefit(residentialIndex)"
                      @focusin="setInfoTooltip(0)"
                    >
                      <Svgall
                        :name="'add_more'"
                        :color="primaryColor"
                      />
                      <span>{{ $t('newProperty.propertyResidential.add_more_benefit') }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- END weitere Wohnungen -->

              <!-- BEGIN Weitere Nutzflächen, die keine Wohnflächen sind (ohne Zubehörräume wie zum Beispiel Keller, Waschküche) -->
              <div v-show="form.residentials[residentialIndex].isSectionUsableSpaces === '1'">
                <!-- Weitere Nutzflächen, die keine Wohnflächen sind (ohne Zubehörräume wie zum Beispiel Keller, Waschküche) -->
                <div
                  class="col-md-7"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <h2 class="subheading_title">
                    {{ $t('newProperty.propertyResidential.usable_spaces_title') }}
                  </h2>
                  <hr class="subheading_devide" />
                </div>

                <!-- v-for begin usableSpaces -->
                <div
                  v-for="(usableSpace, usableSpacesIndex) in form.residentials[residentialIndex].usableSpaces"
                  :key="usableSpacesIndex"
                >
                  <!-- Nutzfläche 1 -->
                  <div
                    class="col-md-7"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <hr
                      class="subheading_devide"
                      v-show="usableSpacesIndex > 0"
                    />
                    <div class="deleteIcon delete_">
                      <h5 class="subheading_title_inner">
                        {{ $t('newProperty.propertyResidential.usable_spaces_headline') }} {{ usableSpacesIndex + 1 }}
                      </h5>
                      <Svgall
                        @click="removeUsableSpace(residentialIndex, usableSpacesIndex)"
                        :name="'delete'"
                        :color="primaryColor"
                        v-show="!isPropertyReadOnly"
                        class="hover-tooltip"
                        @focusin="setInfoTooltip(0)"
                      />
                    </div>
                  </div>

                  <!-- Lage der Räume / Bezeichnung -->
                  <div
                    class="col-md-7"
                    @mouseenter="
                      setInfoTooltip(
                        'propertyResidentialUsableSpacesLocationOfTheRoomDesignation_' +
                          residentialIndex +
                          '_' +
                          usableSpacesIndex,
                      )
                    "
                  >
                    <div
                      class="data-form"
                      @mouseenter="
                        setInfoTooltip(
                          'propertyResidentialUsableSpacesLocationOfTheRoomDesignation_' +
                            residentialIndex +
                            '_' +
                            usableSpacesIndex,
                        )
                      "
                    >
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.location_rooms_designation') }}
                        <InfoTooltip
                          v-if="
                            infoTooltip ===
                              'propertyResidentialUsableSpacesLocationOfTheRoomDesignation_' +
                                residentialIndex +
                                '_' +
                                usableSpacesIndex && !usableSpaces_activated
                          "
                          :isDisabled="!usableSpaces_activated"
                        />
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        v-model="
                          form.residentials[residentialIndex].usableSpaces[usableSpacesIndex]
                            .locationOfTheRoomsDesignation
                        "
                        :disabled="isPropertyReadOnly || !usableSpaces_activated"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="
                          setInfoTooltip(
                            'propertyResidentialUsableSpacesLocationOfTheRoomDesignation_' +
                              residentialIndex +
                              '_' +
                              usableSpacesIndex,
                          )
                        "
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                usableSpacesIndex +
                                '_usableSpaces_locationOfTheRoomsDesignation',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' +
                                residentialIndex +
                                '_' +
                                usableSpacesIndex +
                                '_usableSpaces_locationOfTheRoomsDesignation',
                            )
                        }}
                      </span>
                    </div>
                  </div>

                  <!-- Nutzung -->
                  <div
                    class="col-md-7"
                    @mouseenter="
                      setInfoTooltip(
                        'propertyResidentialUsableSpacesUsageType_' + residentialIndex + '_' + usableSpacesIndex,
                      )
                    "
                  >
                    <div
                      class="data-form"
                      @mouseenter="
                        setInfoTooltip(
                          'propertyResidentialUsableSpacesUsageType_' + residentialIndex + '_' + usableSpacesIndex,
                        )
                      "
                    >
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.usable_spaces_usage_type') }}
                        <InfoTooltip
                          v-if="
                            infoTooltip ===
                              'propertyResidentialUsableSpacesUsageType_' +
                                residentialIndex +
                                '_' +
                                usableSpacesIndex && !usableSpaces_activated
                          "
                          :isDisabled="!usableSpaces_activated"
                        />
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        v-model="form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usageType"
                        :disabled="isPropertyReadOnly || !usableSpaces_activated"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="
                          setInfoTooltip(
                            'propertyResidentialUsableSpacesUsageType_' + residentialIndex + '_' + usableSpacesIndex,
                          )
                        "
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' + residentialIndex + '_' + usableSpacesIndex + '_usableSpaces_usageType',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' + residentialIndex + '_' + usableSpacesIndex + '_usableSpaces_usageType',
                            )
                        }}
                      </span>
                    </div>
                  </div>

                  <!-- Nutzfläche in m² -->
                  <div
                    class="col-md-7"
                    @mouseenter="
                      setInfoTooltip(
                        'propertyResidentialUsableSpacesTotalSpace_' + residentialIndex + '_' + usableSpacesIndex,
                      )
                    "
                  >
                    <div
                      class="data-form"
                      @mouseenter="
                        setInfoTooltip(
                          'propertyResidentialUsableSpacesTotalSpace_' + residentialIndex + '_' + usableSpacesIndex,
                        )
                      "
                    >
                      <label class="label-title">
                        {{ $t('newProperty.propertyResidential.usable_spaces_total_spaces') }}
                        <InfoTooltip
                          v-if="
                            infoTooltip ===
                              'propertyResidentialUsableSpacesTotalSpace_' +
                                residentialIndex +
                                '_' +
                                usableSpacesIndex && !usableSpaces_activated
                          "
                          :isDisabled="!usableSpaces_activated"
                        />
                      </label>
                      <input
                        type="number"
                        autocomplete="off"
                        v-model="form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].totalSpace"
                        :disabled="isPropertyReadOnly || !usableSpaces_activated"
                        placeholder=""
                        class="input-field hover-tooltip"
                        @keypress="onlyNumber"
                        @keyup.esc="cancel"
                        @keyup.enter="callForNextStep"
                        @focusin="
                          setInfoTooltip(
                            'propertyResidentialUsableSpacesTotalSpace_' + residentialIndex + '_' + usableSpacesIndex,
                          )
                        "
                      />
                      <span
                        class="error"
                        v-if="
                          form
                            .errors()
                            .has(
                              'residentials_' + residentialIndex + '_' + usableSpacesIndex + '_usableSpaces_totalSpace',
                            )
                        "
                      >
                        {{
                          form
                            .errors()
                            .get(
                              'residentials_' + residentialIndex + '_' + usableSpacesIndex + '_usableSpaces_totalSpace',
                            )
                        }}
                      </span>
                    </div>
                  </div>

                  <!-- v-for begin usableSpacesExemption -->
                  <div
                    v-for="(usableSpacesExemption, usableSpacesExemptionIndex) in form.residentials[residentialIndex]
                      .usableSpaces[usableSpacesIndex].usableSpacesExemption"
                    :key="usableSpacesExemptionIndex"
                  >
                    <!-- Befreiung 1 -->
                    <div
                      class="col-md-7"
                      @mouseenter="setInfoTooltip(0)"
                    >
                      <br v-show="usableSpacesExemptionIndex > 0" />
                      <hr
                        class="subheading_devide"
                        v-show="usableSpacesExemptionIndex > 0"
                      />
                      <div class="deleteIcon delete_">
                        <h5 class="subheading_title_inner">
                          {{ $t('newProperty.propertyResidential.exemption') }} {{ usableSpacesExemptionIndex + 1 }}
                        </h5>
                        <Svgall
                          @click="
                            removeUsableSpacesExemption(residentialIndex, usableSpacesIndex, usableSpacesExemptionIndex)
                          "
                          :name="'delete'"
                          :color="primaryColor"
                          :disabled="isPropertyReadOnly || !usableSpaces_activated"
                          v-show="!isPropertyReadOnly"
                          class="hover-tooltip"
                          @focusin="setInfoTooltip(0)"
                        />
                      </div>
                    </div>

                    <!-- Befreiung -->
                    <div
                      class="col-md-7"
                      @mouseenter="
                        setInfoTooltip(
                          'propertyResidentialUsableSpacesExemption_' +
                            residentialIndex +
                            '_' +
                            usableSpacesIndex +
                            '_' +
                            usableSpacesExemptionIndex,
                        )
                      "
                    >
                      <div
                        class="data-form"
                        @mouseenter="
                          setInfoTooltip(
                            'propertyResidentialUsableSpacesExemption_' +
                              residentialIndex +
                              '_' +
                              usableSpacesIndex +
                              '_' +
                              usableSpacesExemptionIndex,
                          )
                        "
                      >
                        <label class="label-title">
                          {{ $t('newProperty.propertyResidential.exemption') }}
                          <InfoTooltip
                            v-if="
                              infoTooltip ===
                                'propertyResidentialUsableSpacesExemption_' +
                                  residentialIndex +
                                  '_' +
                                  usableSpacesIndex +
                                  '_' +
                                  usableSpacesExemptionIndex && !usableSpaces_activated
                            "
                            :isDisabled="!usableSpaces_activated"
                          />
                        </label>
                        <select
                          v-model="
                            form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesExemption[
                              usableSpacesExemptionIndex
                            ].exemption
                          "
                          :disabled="isPropertyReadOnly || !usableSpaces_activated"
                          class="hover-tooltip"
                          @focusin="
                            setInfoTooltip(
                              'propertyResidentialUsableSpacesExemption_' +
                                residentialIndex +
                                '_' +
                                usableSpacesIndex +
                                '_' +
                                usableSpacesExemptionIndex,
                            )
                          "
                        >
                          <option
                            v-for="option in exemption_options"
                            v-bind:key="option.code"
                            :value="option.code"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- Steuerfreie Fläche in m² -->
                    <div
                      class="col-md-7"
                      @mouseenter="
                        setInfoTooltip(
                          'propertyResidentialUsableSpacesExemptionTaxFreeArea_' +
                            residentialIndex +
                            '_' +
                            usableSpacesIndex +
                            '_' +
                            usableSpacesExemptionIndex,
                        )
                      "
                    >
                      <div
                        class="data-form"
                        @mouseenter="
                          setInfoTooltip(
                            'propertyResidentialUsableSpacesExemptionTaxFreeArea_' +
                              residentialIndex +
                              '_' +
                              usableSpacesIndex +
                              '_' +
                              usableSpacesExemptionIndex,
                          )
                        "
                      >
                        <label class="label-title">
                          {{ $t('newProperty.propertyResidential.tax_free_area') }}
                          <InfoTooltip
                            v-if="
                              infoTooltip ===
                                'propertyResidentialUsableSpacesExemptionTaxFreeArea_' +
                                  residentialIndex +
                                  '_' +
                                  usableSpacesIndex +
                                  '_' +
                                  usableSpacesExemptionIndex && !usableSpaces_activated
                            "
                            :isDisabled="!usableSpaces_activated"
                          />
                        </label>
                        <input
                          type="number"
                          autocomplete="off"
                          v-model="
                            form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesExemption[
                              usableSpacesExemptionIndex
                            ].taxFreeArea
                          "
                          :disabled="isPropertyReadOnly || !usableSpaces_activated"
                          placeholder=""
                          class="input-field hover-tooltip"
                          @keypress="onlyNumber"
                          @keyup.esc="cancel"
                          @keyup.enter="callForNextStep"
                          @focusin="
                            setInfoTooltip(
                              'propertyResidentialUsableSpacesExemptionTaxFreeArea_' +
                                residentialIndex +
                                '_' +
                                usableSpacesIndex +
                                '_' +
                                usableSpacesExemptionIndex,
                            )
                          "
                        />
                        <span
                          class="error"
                          v-if="
                            form
                              .errors()
                              .has(
                                'residentials_' +
                                  residentialIndex +
                                  '_' +
                                  usableSpacesIndex +
                                  '_' +
                                  usableSpacesExemptionIndex +
                                  '_usableSpacesExemption_taxFreeArea',
                              )
                          "
                        >
                          {{
                            form
                              .errors()
                              .get(
                                'residentials_' +
                                  residentialIndex +
                                  '_' +
                                  usableSpacesIndex +
                                  '_' +
                                  usableSpacesExemptionIndex +
                                  '_usableSpacesExemption_taxFreeArea',
                              )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- v-for end usableSpacesExemption -->

                  <div
                    class="col-md-7"
                    v-show="!isPropertyReadOnly && exemption_activated"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="add-field no_space">
                      <button
                        class="add-field-button hover-tooltip"
                        :disabled="!usableSpaces_activated"
                        @click="addUsableSpacesExemption(residentialIndex, usableSpacesIndex)"
                        @focusin="setInfoTooltip(0)"
                      >
                        <Svgall
                          :name="'add_more'"
                          :color="primaryColor"
                        />
                        <span>{{ $t('newProperty.propertyResidential.add_more_exemption') }}</span>
                      </button>
                    </div>
                  </div>

                  <!-- v-for begin usableSpacesBenefit -->
                  <div
                    v-for="(usableSpacesBenefit, usableSpacesBenefitIndex) in form.residentials[residentialIndex]
                      .usableSpaces[usableSpacesIndex].usableSpacesBenefit"
                    :key="usableSpacesBenefitIndex"
                  >
                    <!-- Vergünstigung 1 -->
                    <div
                      class="col-md-7"
                      @mouseenter="setInfoTooltip(0)"
                    >
                      <br v-show="usableSpacesBenefitIndex > 0" />
                      <hr
                        class="subheading_devide"
                        v-show="usableSpacesBenefitIndex > 0"
                      />
                      <div class="deleteIcon delete_">
                        <h5 class="subheading_title_inner">
                          {{ $t('newProperty.propertyResidential.benefit') }} {{ usableSpacesBenefitIndex + 1 }}
                        </h5>
                        <Svgall
                          @click="
                            removeUsableSpacesBenefit(residentialIndex, usableSpacesIndex, usableSpacesBenefitIndex)
                          "
                          :name="'delete'"
                          :color="primaryColor"
                          :disabled="isPropertyReadOnly || !usableSpaces_activated"
                          v-show="!isPropertyReadOnly"
                          class="hover-tooltip"
                          @focusin="setInfoTooltip(0)"
                        />
                      </div>
                    </div>

                    <!-- Vergünstigung -->
                    <div
                      class="col-md-7"
                      @mouseenter="
                        setInfoTooltip(
                          'propertyResidentialUsableSpacesBenefit_' +
                            residentialIndex +
                            '_' +
                            usableSpacesIndex +
                            '_' +
                            usableSpacesBenefitIndex,
                        )
                      "
                    >
                      <div
                        class="data-form"
                        @mouseenter="
                          setInfoTooltip(
                            'propertyResidentialUsableSpacesBenefit_' +
                              residentialIndex +
                              '_' +
                              usableSpacesIndex +
                              '_' +
                              usableSpacesBenefitIndex,
                          )
                        "
                      >
                        <label class="label-title">
                          {{ $t('newProperty.propertyResidential.benefit') }}
                          <InfoTooltip
                            v-if="
                              infoTooltip ===
                                'propertyResidentialUsableSpacesBenefit_' +
                                  residentialIndex +
                                  '_' +
                                  usableSpacesIndex +
                                  '_' +
                                  usableSpacesBenefitIndex && !usableSpaces_activated
                            "
                            :isDisabled="!usableSpaces_activated"
                          />
                        </label>
                        <select
                          v-model="
                            form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesBenefit[
                              usableSpacesBenefitIndex
                            ].benefit
                          "
                          :disabled="isPropertyReadOnly || !usableSpaces_activated"
                          class="hover-tooltip"
                          @focusin="
                            setInfoTooltip(
                              'propertyResidentialUsableSpacesBenefit_' +
                                residentialIndex +
                                '_' +
                                usableSpacesIndex +
                                '_' +
                                usableSpacesBenefitIndex,
                            )
                          "
                        >
                          <option
                            v-for="option in benefit_options"
                            v-bind:key="option.code"
                            :value="option.code"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- Vergünstigte Fläche in m² -->
                    <div
                      class="col-md-7"
                      @mouseenter="
                        setInfoTooltip(
                          'propertyResidentialUsableSpacesBenefitBenefitDiscountedArea_' +
                            residentialIndex +
                            '_' +
                            usableSpacesIndex +
                            '_' +
                            usableSpacesBenefitIndex,
                        )
                      "
                    >
                      <div
                        class="data-form"
                        @mouseenter="
                          setInfoTooltip(
                            'propertyResidentialUsableSpacesBenefitBenefitDiscountedArea_' +
                              residentialIndex +
                              '_' +
                              usableSpacesIndex +
                              '_' +
                              usableSpacesBenefitIndex,
                          )
                        "
                      >
                        <label class="label-title">
                          {{ $t('newProperty.propertyResidential.discounted_area') }}
                          <InfoTooltip
                            v-if="
                              infoTooltip ===
                                'propertyResidentialUsableSpacesBenefitBenefitDiscountedArea_' +
                                  residentialIndex +
                                  '_' +
                                  usableSpacesIndex +
                                  '_' +
                                  usableSpacesBenefitIndex && !usableSpaces_activated
                            "
                            :isDisabled="!usableSpaces_activated"
                          />
                        </label>
                        <input
                          type="number"
                          autocomplete="off"
                          v-model="
                            form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesBenefit[
                              usableSpacesBenefitIndex
                            ].discountedArea
                          "
                          :disabled="isPropertyReadOnly || !usableSpaces_activated"
                          placeholder=""
                          class="input-field hover-tooltip"
                          @keypress="onlyNumber"
                          @keyup.esc="cancel"
                          @keyup.enter="callForNextStep"
                          @focusin="
                            setInfoTooltip(
                              'propertyResidentialUsableSpacesBenefitBenefitDiscountedArea_' +
                                residentialIndex +
                                '_' +
                                usableSpacesIndex +
                                '_' +
                                usableSpacesBenefitIndex,
                            )
                          "
                        />
                        <span
                          class="error"
                          v-if="
                            form
                              .errors()
                              .has(
                                'residentials_' +
                                  residentialIndex +
                                  '_' +
                                  usableSpacesIndex +
                                  '_' +
                                  usableSpacesBenefitIndex +
                                  '_usableSpacesBenefit_discountedArea',
                              )
                          "
                        >
                          {{
                            form
                              .errors()
                              .get(
                                'residentials_' +
                                  residentialIndex +
                                  '_' +
                                  usableSpacesIndex +
                                  '_' +
                                  usableSpacesBenefitIndex +
                                  '_usableSpacesBenefit_discountedArea',
                              )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- v-for end usableSpacesBenefit -->

                  <div
                    class="col-md-7"
                    v-show="!isPropertyReadOnly && exemption_activated"
                    @mouseenter="setInfoTooltip(0)"
                  >
                    <div class="add-field no_space">
                      <button
                        class="add-field-button hover-tooltip"
                        :disabled="!usableSpaces_activated"
                        @click="addUsableSpacesBenefit(residentialIndex, usableSpacesIndex)"
                        @focusin="setInfoTooltip(0)"
                      >
                        <Svgall
                          :name="'add_more'"
                          :color="primaryColor"
                        />
                        <span>{{ $t('newProperty.propertyResidential.add_more_benefit') }}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <!-- v-for end usableSpaces -->

                <div
                  class="col-md-7"
                  v-show="!isPropertyReadOnly"
                  @mouseenter="setInfoTooltip(0)"
                >
                  <div class="add-field no_space">
                    <button
                      class="add-field-button hover-tooltip"
                      :disabled="isPropertyReadOnly"
                      @click="addUsableSpace(residentialIndex)"
                      @focusin="setInfoTooltip(0)"
                    >
                      <Svgall
                        :name="'add_more'"
                        :color="primaryColor"
                      />
                      <span>{{ $t('newProperty.propertyResidential.add_more_usable_space') }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- END Weitere Nutzflächen, die keine Wohnflächen sind (ohne Zubehörräume wie zum Beispiel Keller, Waschküche) -->
            </div>
            <!-- v-for end residentialIndex -->
            <div
              class="col-md-7"
              @mouseenter="setInfoTooltip(0)"
            >
              <div class="add-field __space">
                <button
                  class="add-field-button hover-tooltip"
                  :disabled="isPropertyReadOnly"
                  @click="addResidential"
                  @focusin="setInfoTooltip(0)"
                >
                  <Svgall
                    :name="'add_more'"
                    :color="primaryColor"
                  />
                  <span>{{ $t('newProperty.propertyResidential.add_more_residential') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="text_left step-button-section"
          @mouseenter="setInfoTooltip(0)"
        >
          <div>
            <button
              type="button"
              @click.prevent="cancel"
              class="step-button-style border-button-style hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.abort') }}
            </button>
            <button
              type="button"
              @click.prevent="save"
              class="step-button-style border-button-style hover-tooltip"
              v-show="!isPropertyReadOnly"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.save') }}
            </button>
            <button
              type="button"
              v-show="back_button_activated"
              @click.prevent="callForPrecedingStep"
              class="step-button-style border-button-style"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.back') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              class="step-button-style hover-tooltip"
              :style="[primaryColor ? { background: primaryColor } : '']"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue') }}
            </button>
            <button
              type="button"
              v-show="isShowNextStepButton"
              @click.prevent="callForNextStep"
              class="step-button-enter hover-tooltip"
              @focusin="setInfoTooltip(0)"
            >
              {{ $t('newProperty.continue_enter') }}
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </section>
</template>

<script type="text/javascript">
import form from 'vuejs-form'
import i18n from '@/i18n'
const { t } = i18n.global
import InfoTooltip from '@/components/helper/InfoTooltip'
import ShowHideTooltip from '@/mixins/ShowHideTooltip'
import { mapGetters, mapActions } from 'vuex'
import { defaultPropertyResidential } from '@/store/defaults/properties/DefaultPropertyResidential'
import InfoTextHeading from '@/components/helper/InfoTextHeading'
import { isRoometricActive } from '@/helper/RoometricHelper'

export default {
  name: 'PropertyResidential',
  components: { InfoTextHeading, InfoTooltip },
  mixins: [ShowHideTooltip],
  emits: ['callForNextStep', 'callForPrecedingStep', 'callForSaveProperty', 'cancelPropVisible', 'showRoometricDialog'],
  data() {
    return {
      primaryColor: '',
      fontColor: '',
      form: form({
        residentials: [
          {
            id: 0,
            isSectionGarage: '0',
            isSectionBelow60: '0',
            isSectionBetween60100: '0',
            isSectionAbove100: '0',
            isSectionOther: '0',
            isSectionUsableSpaces: '0',
            residential: {
              yearOfConstruction: '',
              coreRenovationYear: '',
              demolitionObligationYear: '',
            },
            garageUnderground: {
              id: 0,
              amountGarages: '',
              garageUndergroundExemption: [
                {
                  id: 0,
                  locationOfTheRoomsDesignation: '',
                  exemption: '0',
                  taxFreeArea: '',
                },
              ],
              garageUndergroundBenefit: [
                {
                  id: 0,
                  locationOfTheRoomsDesignation: '',
                  benefit: '0',
                  discountedArea: '',
                },
              ],
            },
            apartmentsBelow60: {
              id: 0,
              counter: '',
              totalLivingSpace: '',
              apartmentsBelow60Exemption: [
                {
                  id: 0,
                  locationOfTheRoomsDesignation: '',
                  exemption: '0',
                  taxFreeArea: '',
                },
              ],
              apartmentsBelow60Benefit: [
                {
                  id: 0,
                  locationOfTheRoomsDesignation: '',
                  benefit: '0',
                  discountedArea: '',
                },
              ],
            },
            apartmentsBetween60100: {
              id: 0,
              counter: '',
              totalLivingSpace: '',
              apartmentsBetween60100Exemption: [
                {
                  id: 0,
                  locationOfTheRoomsDesignation: '',
                  exemption: '0',
                  taxFreeArea: '',
                },
              ],
              apartmentsBetween60100Benefit: [
                {
                  id: 0,
                  locationOfTheRoomsDesignation: '',
                  benefit: '0',
                  discountedArea: '',
                },
              ],
            },
            apartmentsAbove100: {
              id: 0,
              counter: '',
              totalLivingSpace: '',
              apartmentsAbove100Exemption: [
                {
                  id: 0,
                  locationOfTheRoomsDesignation: '',
                  exemption: '0',
                  taxFreeArea: '',
                },
              ],
              apartmentsAbove100Benefit: [
                {
                  id: 0,
                  locationOfTheRoomsDesignation: '',
                  benefit: '0',
                  discountedArea: '',
                },
              ],
            },
            apartmentsOther: {
              id: 0,
              counter: '',
              totalLivingSpace: '',
              apartmentsOtherExemption: [
                {
                  id: 0,
                  locationOfTheRoomsDesignation: '',
                  exemption: '0',
                  taxFreeArea: '',
                },
              ],
              apartmentsOtherBenefit: [
                {
                  id: 0,
                  locationOfTheRoomsDesignation: '',
                  benefit: '0',
                  discountedArea: '',
                },
              ],
            },
            usableSpaces: [
              {
                id: 0,
                usageType: '',
                totalSpace: '',
                locationOfTheRoomsDesignation: '',
                usableSpacesExemption: [
                  {
                    id: 0,
                    exemption: '0',
                    taxFreeArea: '',
                  },
                ],
                usableSpacesBenefit: [
                  {
                    id: 0,
                    benefit: '0',
                    discountedArea: '',
                  },
                ],
              },
            ],
          },
        ],
      }), // set form rules and messages in method "prepareForValidate()"
      usableSpaces_activated: true,
      exemption_activated: true,
      back_button_activated: false,
    }
  },
  mounted() {
    this.initForm()
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : ''
    this.fontColor = this.getCurrentUser.fontColor ? this.getCurrentUser.primaryColor : ''
    this.addOneOfTwoRequiredRule()
    this.addPositiveRule()
    this.showHide()
  },
  beforeUnmount() {
    this.saveForm()
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getFormPropertyResidential', 'isPropertyReadOnly', 'infoTooltip']),

    isNewProperty() {
      return this.$parent.isNewProperty
    },

    exemption_options() {
      let myRawOptions = this.$tm('newProperty.propertyResidential.exemption_options')
      let myOptions = []
      for (let k of myRawOptions) {
        myOptions.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }
      return myOptions
    },

    benefit_options() {
      let myRawOptions = this.$tm('newProperty.propertyResidential.benefit_options')
      let myOptions = []
      for (let k of myRawOptions) {
        myOptions.push({
          code: this.$rt(k.code),
          name: this.$rt(k.name),
        })
      }
      return myOptions
    },

    isRoometricActive() {
      return isRoometricActive()
    },

    isShowNextStepButton() {
      let isShow = true
      if (this.$parent.step === this.$parent.maxStep && this.isPropertyReadOnly) {
        isShow = false
      }
      return isShow
    },
  },
  watch: {
    getFormPropertyResidential() {
      this.initForm()
    },

    exemption_activated(newValue) {
      if (newValue === false) {
        this.removeAllBenefits()
        this.removeAllExemptions()
      }
    },
  },
  methods: {
    ...mapActions(['resetFormPropertyResidential', 'changeInfoTooltip']),

    setInfoTooltip(val) {
      this.changeInfoTooltip(val)
    },

    calcIdAndRef(prefix, residentialIndex, secondIndex, thirdIndex) {
      let result = ''
      if (typeof prefix === 'string' && typeof residentialIndex === 'number') {
        result = prefix + residentialIndex.toString()
        if (typeof secondIndex === 'number') {
          result = result + '_' + secondIndex.toString()
          if (typeof thirdIndex === 'number') {
            result = result + '_' + thirdIndex.toString()
          }
        }
      }
      return result
    },

    initForm() {
      for (const [key, value] of Object.entries(this.$store.state.Properties.formPropertyResidential)) {
        if (typeof value !== 'undefined') {
          this.form[key] = JSON.parse(JSON.stringify(value))
        }
      }
    },

    saveForm() {
      let myKeys = []
      this.resetFormPropertyResidential()
      myKeys = Object.keys(this.$store.state.Properties.formPropertyResidential)
      if (typeof myKeys === 'object') {
        myKeys.forEach(function (key) {
          if (typeof this.form[key] !== 'undefined') {
            this.$store.state.Properties.formPropertyResidential[key] = JSON.parse(JSON.stringify(this.form[key]))
          }
        }, this)
      }
    },

    cancel() {
      this.$emit('cancelPropVisible')
    },

    callForPrecedingStep() {
      if (!this.isPropertyReadOnly) {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForPrecedingStep')
      } else {
        this.$emit('callForPrecedingStep')
      }
    },

    callForNextStep() {
      if (this.isPropertyReadOnly) {
        this.$emit('callForNextStep')
      } else {
        this.prepareForValidate()
        if (this.form.validate().errors().any()) {
          this.$store.dispatch('showToast', {
            message: this.$t('newProperty.form_validation_text'),
            toastType: 'error',
          })
          return
        }
        this.saveForm()
        this.$emit('callForNextStep')
      }
    },

    save() {
      if (this.isPropertyReadOnly) {
        return
      }

      this.prepareForValidate()
      if (this.form.validate().errors().any()) {
        this.$store.dispatch('showToast', {
          message: this.$t('newProperty.form_validation_text'),
          toastType: 'error',
        })
        return
      }
      this.saveForm()
      this.$emit('callForSaveProperty')
    },

    resetFormErrors() {
      if (this.form.validate().errors().any()) {
        this.form.errors().forget()
      }
    },

    addFlatField(
      objFlatFields, // must be an object
      rawFieldName, // must be a string
      value, // must be a simple value, no objects
      residentialIndex, // must be a number
      secondIndex, // optional: either undefined or number
      thirdIndex, // optional: either undefined or number
      maxRuleValue, // must be a number. 0 => no max rule will be set
      maxRuleMsg, // must be a string. "" => no max custom message will be set
      requiredValue, // must be true/false. false => no required rule will be set
      requiredMsg, // must be a string. "" => no required custom message will be set
      lessThanValue, // must be a number. 0 => no less_than rule will be set
      lessThanMsg, // must be a string. "" => no less_than custom message will be set
      oneOfTwoRequiredValue, // must be either a string or "undefined". undefined => no one_of_two_required rule will be set
      oneOfTwoRequiredMsg, //must be a string. "" => no one_of_two_required message will be set
      positiveValue, // must be true/false. false => no positive rule will be set
      positiveMsg, // must be a string. "" => no positive custom message will be set
    ) {
      const prefix = 'residentials_'
      let hasSecondIndex = typeof secondIndex === 'number'
      let hasThirdIndex = hasSecondIndex && typeof thirdIndex === 'number'
      let flatFieldName = ''
      let fieldRules = []

      if (value === null) value = ''
      if (oneOfTwoRequiredValue === null) oneOfTwoRequiredValue = ''

      if (
        typeof objFlatFields === 'object' &&
        typeof rawFieldName === 'string' &&
        typeof value !== 'undefined' &&
        typeof value !== 'object' &&
        typeof residentialIndex === 'number' &&
        typeof maxRuleValue === 'number' &&
        typeof maxRuleMsg === 'string' &&
        typeof requiredValue === 'boolean' &&
        typeof requiredMsg === 'string' &&
        typeof lessThanValue === 'number' &&
        typeof lessThanMsg === 'string' &&
        typeof oneOfTwoRequiredMsg === 'string' &&
        typeof positiveValue === 'boolean' &&
        typeof positiveMsg === 'string'
      ) {
        //build flat field name
        flatFieldName = prefix + residentialIndex.toString() + '_'
        if (hasSecondIndex) {
          flatFieldName = flatFieldName + secondIndex.toString() + '_'
          if (hasThirdIndex) {
            flatFieldName = flatFieldName + thirdIndex.toString() + '_'
          }
        }
        flatFieldName = flatFieldName + rawFieldName

        //add flat field to object
        objFlatFields[flatFieldName] = value

        //initialize field rules
        fieldRules = []

        //handle max rule
        if (maxRuleValue > 0) {
          if (maxRuleMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.max', maxRuleMsg)
          }
          fieldRules.push('max: ' + maxRuleValue.toString())
        }

        //handle required rule
        if (requiredValue) {
          if (requiredMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.required', requiredMsg)
          }
          fieldRules.push('required')
        }

        //handle less_than rule
        if (lessThanValue > 0) {
          if (lessThanMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.less_than', lessThanMsg)
          }
          fieldRules.push('less_than: ' + lessThanValue.toString())
        }

        //handle one_of_two_required rule
        if (typeof oneOfTwoRequiredValue !== 'undefined') {
          if (oneOfTwoRequiredMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.one_of_two_required', oneOfTwoRequiredMsg)
          }
          fieldRules.push('one_of_two_required: ' + oneOfTwoRequiredValue.toString())
        }

        //handle positive rule
        if (positiveValue) {
          if (positiveMsg !== '') {
            this.form.validatorInstance.addMessage(flatFieldName + '.positive', positiveMsg)
          }
          fieldRules.push('positive')
        }

        //add combined field rules
        this.form.validatorInstance.addRule(flatFieldName, fieldRules)
      }
    },

    addOneOfTwoRequiredRule() {
      this.form.validatorInstance.extend({
        one_of_two_required: [
          ':attribute is required',
          ({ value, parameters }) =>
            (value.toString().trim() !== '' && value.toString().trim() !== '0') ||
            (parameters[0].toString().trim() !== '' && parameters[0].toString().trim() !== '0'),
        ],
      })
    },

    addPositiveRule() {
      this.form.validatorInstance.extend({
        positive: [
          ':attribute must not be negative',
          function (value) {
            let result = true
            let myStringValue = value.value.toString().trim()
            let myFloatValue
            if (myStringValue !== '') {
              myFloatValue = parseFloat(myStringValue)
              if (!isNaN(myFloatValue)) {
                result = myFloatValue >= 0
              }
            }
            return result
          },
        ],
      })
    },

    prepareForValidate() {
      let myFlatFields = {}

      //remove existing flat fields first
      this.removeFlatFormFields()

      //add flat fields
      this.form.residentials.forEach(function (residential, residentialIndex) {
        this.addFlatField(
          myFlatFields,
          'residential_yearOfConstruction',
          residential.residential.yearOfConstruction,
          residentialIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          10000,
          t('newProperty.propertyResidential.messages.residential_yearOfConstruction_less_than'),
          undefined,
          '',
          true,
          t('newProperty.propertyResidential.messages.residential_yearOfConstruction_positive'),
        )
        this.addFlatField(
          myFlatFields,
          'residential_coreRenovationYear',
          residential.residential.coreRenovationYear,
          residentialIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          10000,
          t('newProperty.propertyResidential.messages.residential_coreRenovationYear_less_than'),
          undefined,
          '',
          true,
          t('newProperty.propertyResidential.messages.residential_coreRenovationYear_positive'),
        )
        this.addFlatField(
          myFlatFields,
          'residential_demolitionObligationYear',
          residential.residential.demolitionObligationYear,
          residentialIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          10000,
          t('newProperty.propertyResidential.messages.residential_demolitionObligationYear_less_than'),
          undefined,
          '',
          true,
          t('newProperty.propertyResidential.messages.residential_demolitionObligationYear_positive'),
        )
        this.addFlatField(
          myFlatFields,
          'garageUnderground_amountGarages',
          residential.garageUnderground.amountGarages,
          residentialIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          10000,
          t('newProperty.propertyResidential.messages.garageUnderground_amountGarages_less_than'),
          undefined,
          '',
          true,
          t('newProperty.propertyResidential.messages.garageUnderground_amountGarages_positive'),
        )
        residential.garageUnderground.garageUndergroundExemption.forEach(function (exemption, exemptionIndex) {
          this.addFlatField(
            myFlatFields,
            'garageUndergroundExemption_locationOfTheRoomsDesignation',
            exemption.locationOfTheRoomsDesignation,
            residentialIndex,
            exemptionIndex,
            undefined,
            25,
            t('newProperty.propertyResidential.messages.locationOfTheRoomsDesignation_max'),
            false,
            '',
            0,
            '',
            undefined,
            '',
            false,
            '',
          )
          this.addFlatField(
            myFlatFields,
            'garageUndergroundExemption_taxFreeArea',
            exemption.taxFreeArea,
            residentialIndex,
            exemptionIndex,
            undefined,
            0,
            '',
            false,
            '',
            1000000,
            t('newProperty.propertyResidential.messages.taxFreeArea_less_than'),
            undefined,
            '',
            false,
            '',
          )
        }, this)
        residential.garageUnderground.garageUndergroundBenefit.forEach(function (benefit, benefitIndex) {
          this.addFlatField(
            myFlatFields,
            'garageUndergroundBenefit_locationOfTheRoomsDesignation',
            benefit.locationOfTheRoomsDesignation,
            residentialIndex,
            benefitIndex,
            undefined,
            25,
            t('newProperty.propertyResidential.messages.locationOfTheRoomsDesignation_max'),
            false,
            '',
            0,
            '',
            undefined,
            '',
            false,
            '',
          )
          this.addFlatField(
            myFlatFields,
            'garageUndergroundBenefit_discountedArea',
            benefit.discountedArea,
            residentialIndex,
            benefitIndex,
            undefined,
            0,
            '',
            false,
            '',
            1000000,
            t('newProperty.propertyResidential.messages.discountedArea_less_than'),
            undefined,
            '',
            false,
            '',
          )
        }, this)

        this.addFlatField(
          myFlatFields,
          'apartmentsBelow60_counter',
          residential.apartmentsBelow60.counter,
          residentialIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          1000,
          t('newProperty.propertyResidential.messages.counter_less_than'),
          undefined,
          '',
          false,
          '',
        )
        this.addFlatField(
          myFlatFields,
          'apartmentsBelow60_totalLivingSpace',
          residential.apartmentsBelow60.totalLivingSpace,
          residentialIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          1000000,
          t('newProperty.propertyResidential.messages.totalLivingSpace_less_than'),
          undefined,
          '',
          false,
          '',
        )
        residential.apartmentsBelow60.apartmentsBelow60Exemption.forEach(function (exemption, exemptionIndex) {
          this.addFlatField(
            myFlatFields,
            'apartmentsBelow60Exemption_locationOfTheRoomsDesignation',
            exemption.locationOfTheRoomsDesignation,
            residentialIndex,
            exemptionIndex,
            undefined,
            25,
            t('newProperty.propertyResidential.messages.locationOfTheRoomsDesignation_max'),
            false,
            '',
            0,
            '',
            undefined,
            '',
            false,
            '',
          )
          this.addFlatField(
            myFlatFields,
            'apartmentsBelow60Exemption_taxFreeArea',
            exemption.taxFreeArea,
            residentialIndex,
            exemptionIndex,
            undefined,
            0,
            '',
            false,
            '',
            1000000,
            t('newProperty.propertyResidential.messages.taxFreeArea_less_than'),
            undefined,
            '',
            false,
            '',
          )
        }, this)
        residential.apartmentsBelow60.apartmentsBelow60Benefit.forEach(function (benefit, benefitIndex) {
          this.addFlatField(
            myFlatFields,
            'apartmentsBelow60Benefit_locationOfTheRoomsDesignation',
            benefit.locationOfTheRoomsDesignation,
            residentialIndex,
            benefitIndex,
            undefined,
            25,
            t('newProperty.propertyResidential.messages.locationOfTheRoomsDesignation_max'),
            false,
            '',
            0,
            '',
            undefined,
            '',
            false,
            '',
          )
          this.addFlatField(
            myFlatFields,
            'apartmentsBelow60Benefit_discountedArea',
            benefit.discountedArea,
            residentialIndex,
            benefitIndex,
            undefined,
            0,
            '',
            false,
            '',
            1000000,
            t('newProperty.propertyResidential.messages.discountedArea_less_than'),
            undefined,
            '',
            false,
            '',
          )
        }, this)

        this.addFlatField(
          myFlatFields,
          'apartmentsBetween60100_counter',
          residential.apartmentsBetween60100.counter,
          residentialIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          1000,
          t('newProperty.propertyResidential.messages.counter_less_than'),
          undefined,
          '',
          false,
          '',
        )
        this.addFlatField(
          myFlatFields,
          'apartmentsBetween60100_totalLivingSpace',
          residential.apartmentsBetween60100.totalLivingSpace,
          residentialIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          1000000,
          t('newProperty.propertyResidential.messages.totalLivingSpace_less_than'),
          undefined,
          '',
          false,
          '',
        )
        residential.apartmentsBetween60100.apartmentsBetween60100Exemption.forEach(function (
          exemption,
          exemptionIndex,
        ) {
          this.addFlatField(
            myFlatFields,
            'apartmentsBetween60100Exemption_locationOfTheRoomsDesignation',
            exemption.locationOfTheRoomsDesignation,
            residentialIndex,
            exemptionIndex,
            undefined,
            25,
            t('newProperty.propertyResidential.messages.locationOfTheRoomsDesignation_max'),
            false,
            '',
            0,
            '',
            undefined,
            '',
            false,
            '',
          )
          this.addFlatField(
            myFlatFields,
            'apartmentsBetween60100Exemption_taxFreeArea',
            exemption.taxFreeArea,
            residentialIndex,
            exemptionIndex,
            undefined,
            0,
            '',
            false,
            '',
            1000000,
            t('newProperty.propertyResidential.messages.taxFreeArea_less_than'),
            undefined,
            '',
            false,
            '',
          )
        }, this)
        residential.apartmentsBetween60100.apartmentsBetween60100Benefit.forEach(function (benefit, benefitIndex) {
          this.addFlatField(
            myFlatFields,
            'apartmentsBetween60100Benefit_locationOfTheRoomsDesignation',
            benefit.locationOfTheRoomsDesignation,
            residentialIndex,
            benefitIndex,
            undefined,
            25,
            t('newProperty.propertyResidential.messages.locationOfTheRoomsDesignation_max'),
            false,
            '',
            0,
            '',
            undefined,
            '',
            false,
            '',
          )
          this.addFlatField(
            myFlatFields,
            'apartmentsBetween60100Benefit_discountedArea',
            benefit.discountedArea,
            residentialIndex,
            benefitIndex,
            undefined,
            0,
            '',
            false,
            '',
            1000000,
            t('newProperty.propertyResidential.messages.discountedArea_less_than'),
            undefined,
            '',
            false,
            '',
          )
        }, this)

        this.addFlatField(
          myFlatFields,
          'apartmentsAbove100_counter',
          residential.apartmentsAbove100.counter,
          residentialIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          1000,
          t('newProperty.propertyResidential.messages.counter_less_than'),
          undefined,
          '',
          false,
          '',
        )
        this.addFlatField(
          myFlatFields,
          'apartmentsAbove100_totalLivingSpace',
          residential.apartmentsAbove100.totalLivingSpace,
          residentialIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          1000000,
          t('newProperty.propertyResidential.messages.totalLivingSpace_less_than'),
          undefined,
          '',
          false,
          '',
        )
        residential.apartmentsAbove100.apartmentsAbove100Exemption.forEach(function (exemption, exemptionIndex) {
          this.addFlatField(
            myFlatFields,
            'apartmentsAbove100Exemption_locationOfTheRoomsDesignation',
            exemption.locationOfTheRoomsDesignation,
            residentialIndex,
            exemptionIndex,
            undefined,
            25,
            t('newProperty.propertyResidential.messages.locationOfTheRoomsDesignation_max'),
            false,
            '',
            0,
            '',
            undefined,
            '',
            false,
            '',
          )
          this.addFlatField(
            myFlatFields,
            'apartmentsAbove100Exemption_taxFreeArea',
            exemption.taxFreeArea,
            residentialIndex,
            exemptionIndex,
            undefined,
            0,
            '',
            false,
            '',
            1000000,
            t('newProperty.propertyResidential.messages.taxFreeArea_less_than'),
            undefined,
            '',
            false,
            '',
          )
        }, this)
        residential.apartmentsAbove100.apartmentsAbove100Benefit.forEach(function (benefit, benefitIndex) {
          this.addFlatField(
            myFlatFields,
            'apartmentsAbove100Benefit_locationOfTheRoomsDesignation',
            benefit.locationOfTheRoomsDesignation,
            residentialIndex,
            benefitIndex,
            undefined,
            25,
            t('newProperty.propertyResidential.messages.locationOfTheRoomsDesignation_max'),
            false,
            '',
            0,
            '',
            undefined,
            '',
            false,
            '',
          )
          this.addFlatField(
            myFlatFields,
            'apartmentsAbove100Benefit_discountedArea',
            benefit.discountedArea,
            residentialIndex,
            benefitIndex,
            undefined,
            0,
            '',
            false,
            '',
            1000000,
            t('newProperty.propertyResidential.messages.discountedArea_less_than'),
            undefined,
            '',
            false,
            '',
          )
        }, this)

        this.addFlatField(
          myFlatFields,
          'apartmentsOther_counter',
          residential.apartmentsOther.counter,
          residentialIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          1000,
          t('newProperty.propertyResidential.messages.counter_less_than'),
          undefined,
          '',
          false,
          '',
        )
        this.addFlatField(
          myFlatFields,
          'apartmentsOther_totalLivingSpace',
          residential.apartmentsOther.totalLivingSpace,
          residentialIndex,
          undefined,
          undefined,
          0,
          '',
          false,
          '',
          1000000,
          t('newProperty.propertyResidential.messages.totalLivingSpace_less_than'),
          undefined,
          '',
          false,
          '',
        )
        residential.apartmentsOther.apartmentsOtherExemption.forEach(function (exemption, exemptionIndex) {
          this.addFlatField(
            myFlatFields,
            'apartmentsOtherExemption_locationOfTheRoomsDesignation',
            exemption.locationOfTheRoomsDesignation,
            residentialIndex,
            exemptionIndex,
            undefined,
            25,
            t('newProperty.propertyResidential.messages.locationOfTheRoomsDesignation_max'),
            false,
            '',
            0,
            '',
            undefined,
            '',
            false,
            '',
          )
          this.addFlatField(
            myFlatFields,
            'apartmentsOtherExemption_taxFreeArea',
            exemption.taxFreeArea,
            residentialIndex,
            exemptionIndex,
            undefined,
            0,
            '',
            false,
            '',
            1000000,
            t('newProperty.propertyResidential.messages.taxFreeArea_less_than'),
            undefined,
            '',
            false,
            '',
          )
        }, this)
        residential.apartmentsOther.apartmentsOtherBenefit.forEach(function (benefit, benefitIndex) {
          this.addFlatField(
            myFlatFields,
            'apartmentsOtherBenefit_locationOfTheRoomsDesignation',
            benefit.locationOfTheRoomsDesignation,
            residentialIndex,
            benefitIndex,
            undefined,
            25,
            t('newProperty.propertyResidential.messages.locationOfTheRoomsDesignation_max'),
            false,
            '',
            0,
            '',
            undefined,
            '',
            false,
            '',
          )
          this.addFlatField(
            myFlatFields,
            'apartmentsOtherBenefit_discountedArea',
            benefit.discountedArea,
            residentialIndex,
            benefitIndex,
            undefined,
            0,
            '',
            false,
            '',
            1000000,
            t('newProperty.propertyResidential.messages.discountedArea_less_than'),
            undefined,
            '',
            false,
            '',
          )
        }, this)

        residential.usableSpaces.forEach(function (usableSpace, usableSpacesIndex) {
          this.addFlatField(
            myFlatFields,
            'usableSpaces_usageType',
            usableSpace.usageType,
            residentialIndex,
            usableSpacesIndex,
            undefined,
            25,
            t('newProperty.propertyResidential.messages.usageType_max'),
            false,
            '',
            0,
            '',
            undefined,
            '',
            false,
            '',
          )
          this.addFlatField(
            myFlatFields,
            'usableSpaces_totalSpace',
            usableSpace.totalSpace,
            residentialIndex,
            usableSpacesIndex,
            undefined,
            0,
            '',
            false,
            '',
            100000,
            t('newProperty.propertyResidential.messages.totalLivingSpace_less_than'),
            undefined,
            '',
            false,
            '',
          )
          this.addFlatField(
            myFlatFields,
            'usableSpaces_locationOfTheRoomsDesignation',
            usableSpace.locationOfTheRoomsDesignation,
            residentialIndex,
            usableSpacesIndex,
            undefined,
            25,
            t('newProperty.propertyResidential.messages.locationOfTheRoomsDesignation_max'),
            false,
            '',
            0,
            '',
            undefined,
            '',
            false,
            '',
          )
          usableSpace.usableSpacesExemption.forEach(function (exemption, exemptionIndex) {
            this.addFlatField(
              myFlatFields,
              'usableSpacesExemption_taxFreeArea',
              exemption.taxFreeArea,
              residentialIndex,
              usableSpacesIndex,
              exemptionIndex,
              0,
              '',
              false,
              '',
              1000000,
              t('newProperty.propertyResidential.messages.taxFreeArea_less_than'),
              undefined,
              '',
              false,
              '',
            )
          }, this)
          usableSpace.usableSpacesBenefit.forEach(function (benefit, benefitIndex) {
            this.addFlatField(
              myFlatFields,
              'usableSpacesBenefit_discountedArea',
              benefit.discountedArea,
              residentialIndex,
              usableSpacesIndex,
              benefitIndex,
              0,
              '',
              false,
              '',
              1000000,
              t('newProperty.propertyResidential.messages.discountedArea_less_than'),
              undefined,
              '',
              false,
              '',
            )
          }, this)
        }, this)
      }, this)

      this.form.set(myFlatFields)
    },

    removeFlatFormFields() {
      Object.keys(this.form.all()).forEach((key) => {
        if (key.startsWith('residentials_')) {
          this.form.forget(key)
        }
      })
      this.form.rules({})
    },

    invokeReference(ref) {
      this.$nextTick(() => {
        if (!this.$refs[ref]) return
        if (typeof this.$refs[ref].focus === 'function') {
          this.$refs[ref].focus()
        }
      })
    },

    removeResidential(index) {
      let newIndex
      if (typeof index === 'number' && index >= 0 && index < this.form.residentials.length) {
        this.form.residentials.splice(index, 1)
        this.resetFormErrors()

        newIndex = index
        if (newIndex > this.form.residentials.length - 1) {
          newIndex--
        }
        if (newIndex >= 0) {
          this.invokeReference(this.calcIdAndRef('year_of_construction_', newIndex))
        }
      }
    },

    addResidential() {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyResidential.residentials[0]))
      myObject.garageUnderground.garageUndergroundExemption = []
      myObject.garageUnderground.garageUndergroundBenefit = []
      myObject.apartmentsBelow60.apartmentsBelow60Exemption = []
      myObject.apartmentsBelow60.apartmentsBelow60Benefit = []
      myObject.apartmentsBetween60100.apartmentsBetween60100Exemption = []
      myObject.apartmentsBetween60100.apartmentsBetween60100Benefit = []
      myObject.apartmentsAbove100.apartmentsAbove100Exemption = []
      myObject.apartmentsAbove100.apartmentsAbove100Benefit = []
      myObject.apartmentsOther.apartmentsOtherExemption = []
      myObject.apartmentsOther.apartmentsOtherBenefit = []
      myObject.usableSpaces = []
      this.form.residentials.push(myObject)
      this.resetFormErrors()
      this.invokeReference(this.calcIdAndRef('year_of_construction_', this.form.residentials.length - 1))
    },

    removeGarageUndergroundExemption(residentialIndex, garageUndergroundExemptionIndex) {
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length &&
        typeof garageUndergroundExemptionIndex === 'number' &&
        garageUndergroundExemptionIndex >= 0 &&
        garageUndergroundExemptionIndex <
          this.form.residentials[residentialIndex].garageUnderground.garageUndergroundExemption.length
      ) {
        this.form.residentials[residentialIndex].garageUnderground.garageUndergroundExemption.splice(
          garageUndergroundExemptionIndex,
          1,
        )
        this.resetFormErrors()
      }
    },

    addGarageUndergroundExemption(residentialIndex) {
      let myObject = JSON.parse(
        JSON.stringify(defaultPropertyResidential.residentials[0].garageUnderground.garageUndergroundExemption[0]),
      )
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length
      ) {
        this.form.residentials[residentialIndex].garageUnderground.garageUndergroundExemption.push(myObject)
        this.resetFormErrors()
      }
    },

    removeGarageUndergroundBenefit(residentialIndex, garageUndergroundBenefitIndex) {
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length &&
        typeof garageUndergroundBenefitIndex === 'number' &&
        garageUndergroundBenefitIndex >= 0 &&
        garageUndergroundBenefitIndex <
          this.form.residentials[residentialIndex].garageUnderground.garageUndergroundBenefit.length
      ) {
        this.form.residentials[residentialIndex].garageUnderground.garageUndergroundBenefit.splice(
          garageUndergroundBenefitIndex,
          1,
        )
        this.resetFormErrors()
      }
    },

    addGarageUndergroundBenefit(residentialIndex) {
      let myObject = JSON.parse(
        JSON.stringify(defaultPropertyResidential.residentials[0].garageUnderground.garageUndergroundBenefit[0]),
      )
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length
      ) {
        this.form.residentials[residentialIndex].garageUnderground.garageUndergroundBenefit.push(myObject)
        this.resetFormErrors()
      }
    },

    removeApartmentsBelow60Exemption(residentialIndex, apartmentsBelow60ExemptionIndex) {
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length &&
        typeof apartmentsBelow60ExemptionIndex === 'number' &&
        apartmentsBelow60ExemptionIndex >= 0 &&
        apartmentsBelow60ExemptionIndex <
          this.form.residentials[residentialIndex].apartmentsBelow60.apartmentsBelow60Exemption.length
      ) {
        this.form.residentials[residentialIndex].apartmentsBelow60.apartmentsBelow60Exemption.splice(
          apartmentsBelow60ExemptionIndex,
          1,
        )
        this.resetFormErrors()
      }
    },

    addApartmentsBelow60Exemption(residentialIndex) {
      let myObject = JSON.parse(
        JSON.stringify(defaultPropertyResidential.residentials[0].apartmentsBelow60.apartmentsBelow60Exemption[0]),
      )
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length
      ) {
        this.form.residentials[residentialIndex].apartmentsBelow60.apartmentsBelow60Exemption.push(myObject)
        this.resetFormErrors()
      }
    },

    removeApartmentsBelow60Benefit(residentialIndex, apartmentsBelow60BenefitIndex) {
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length &&
        typeof apartmentsBelow60BenefitIndex === 'number' &&
        apartmentsBelow60BenefitIndex >= 0 &&
        apartmentsBelow60BenefitIndex <
          this.form.residentials[residentialIndex].apartmentsBelow60.apartmentsBelow60Benefit.length
      ) {
        this.form.residentials[residentialIndex].apartmentsBelow60.apartmentsBelow60Benefit.splice(
          apartmentsBelow60BenefitIndex,
          1,
        )
        this.resetFormErrors()
      }
    },

    addApartmentsBelow60Benefit(residentialIndex) {
      let myObject = JSON.parse(
        JSON.stringify(defaultPropertyResidential.residentials[0].apartmentsBelow60.apartmentsBelow60Benefit[0]),
      )
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length
      ) {
        this.form.residentials[residentialIndex].apartmentsBelow60.apartmentsBelow60Benefit.push(myObject)
        this.resetFormErrors()
      }
    },

    removeApartmentsBetween60100Exemption(residentialIndex, apartmentsBetween60100ExemptionIndex) {
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length &&
        typeof apartmentsBetween60100ExemptionIndex === 'number' &&
        apartmentsBetween60100ExemptionIndex >= 0 &&
        apartmentsBetween60100ExemptionIndex <
          this.form.residentials[residentialIndex].apartmentsBetween60100.apartmentsBetween60100Exemption.length
      ) {
        this.form.residentials[residentialIndex].apartmentsBetween60100.apartmentsBetween60100Exemption.splice(
          apartmentsBetween60100ExemptionIndex,
          1,
        )
        this.resetFormErrors()
      }
    },

    addApartmentsBetween60100Exemption(residentialIndex) {
      let myObject = JSON.parse(
        JSON.stringify(
          defaultPropertyResidential.residentials[0].apartmentsBetween60100.apartmentsBetween60100Exemption[0],
        ),
      )
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length
      ) {
        this.form.residentials[residentialIndex].apartmentsBetween60100.apartmentsBetween60100Exemption.push(myObject)
        this.resetFormErrors()
      }
    },

    removeApartmentsBetween60100Benefit(residentialIndex, apartmentsBetween60100BenefitIndex) {
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length &&
        typeof apartmentsBetween60100BenefitIndex === 'number' &&
        apartmentsBetween60100BenefitIndex >= 0 &&
        apartmentsBetween60100BenefitIndex <
          this.form.residentials[residentialIndex].apartmentsBetween60100.apartmentsBetween60100Benefit.length
      ) {
        this.form.residentials[residentialIndex].apartmentsBetween60100.apartmentsBetween60100Benefit.splice(
          apartmentsBetween60100BenefitIndex,
          1,
        )
        this.resetFormErrors()
      }
    },

    addApartmentsBetween60100Benefit(residentialIndex) {
      let myObject = JSON.parse(
        JSON.stringify(
          defaultPropertyResidential.residentials[0].apartmentsBetween60100.apartmentsBetween60100Benefit[0],
        ),
      )
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length
      ) {
        this.form.residentials[residentialIndex].apartmentsBetween60100.apartmentsBetween60100Benefit.push(myObject)
        this.resetFormErrors()
      }
    },

    removeApartmentsAbove100Exemption(residentialIndex, apartmentsAbove100ExemptionIndex) {
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length &&
        typeof apartmentsAbove100ExemptionIndex === 'number' &&
        apartmentsAbove100ExemptionIndex >= 0 &&
        apartmentsAbove100ExemptionIndex <
          this.form.residentials[residentialIndex].apartmentsAbove100.apartmentsAbove100Exemption.length
      ) {
        this.form.residentials[residentialIndex].apartmentsAbove100.apartmentsAbove100Exemption.splice(
          apartmentsAbove100ExemptionIndex,
          1,
        )
        this.resetFormErrors()
      }
    },

    addApartmentsAbove100Exemption(residentialIndex) {
      let myObject = JSON.parse(
        JSON.stringify(defaultPropertyResidential.residentials[0].apartmentsAbove100.apartmentsAbove100Exemption[0]),
      )
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length
      ) {
        this.form.residentials[residentialIndex].apartmentsAbove100.apartmentsAbove100Exemption.push(myObject)
        this.resetFormErrors()
      }
    },

    removeApartmentsAbove100Benefit(residentialIndex, apartmentsAbove100BenefitIndex) {
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length &&
        typeof apartmentsAbove100BenefitIndex === 'number' &&
        apartmentsAbove100BenefitIndex >= 0 &&
        apartmentsAbove100BenefitIndex <
          this.form.residentials[residentialIndex].apartmentsAbove100.apartmentsAbove100Benefit.length
      ) {
        this.form.residentials[residentialIndex].apartmentsAbove100.apartmentsAbove100Benefit.splice(
          apartmentsAbove100BenefitIndex,
          1,
        )
        this.resetFormErrors()
      }
    },

    addApartmentsAbove100Benefit(residentialIndex) {
      let myObject = JSON.parse(
        JSON.stringify(defaultPropertyResidential.residentials[0].apartmentsAbove100.apartmentsAbove100Benefit[0]),
      )
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length
      ) {
        this.form.residentials[residentialIndex].apartmentsAbove100.apartmentsAbove100Benefit.push(myObject)
        this.resetFormErrors()
      }
    },

    removeApartmentsOtherExemption(residentialIndex, apartmentsOtherExemptionIndex) {
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length &&
        typeof apartmentsOtherExemptionIndex === 'number' &&
        apartmentsOtherExemptionIndex >= 0 &&
        apartmentsOtherExemptionIndex <
          this.form.residentials[residentialIndex].apartmentsOther.apartmentsOtherExemption.length
      ) {
        this.form.residentials[residentialIndex].apartmentsOther.apartmentsOtherExemption.splice(
          apartmentsOtherExemptionIndex,
          1,
        )
        this.resetFormErrors()
      }
    },

    addApartmentsOtherExemption(residentialIndex) {
      let myObject = JSON.parse(
        JSON.stringify(defaultPropertyResidential.residentials[0].apartmentsOther.apartmentsOtherExemption[0]),
      )
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length
      ) {
        this.form.residentials[residentialIndex].apartmentsOther.apartmentsOtherExemption.push(myObject)
        this.resetFormErrors()
      }
    },

    removeApartmentsOtherBenefit(residentialIndex, apartmentsOtherBenefitIndex) {
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length &&
        typeof apartmentsOtherBenefitIndex === 'number' &&
        apartmentsOtherBenefitIndex >= 0 &&
        apartmentsOtherBenefitIndex <
          this.form.residentials[residentialIndex].apartmentsOther.apartmentsOtherBenefit.length
      ) {
        this.form.residentials[residentialIndex].apartmentsOther.apartmentsOtherBenefit.splice(
          apartmentsOtherBenefitIndex,
          1,
        )
        this.resetFormErrors()
      }
    },

    addApartmentsOtherBenefit(residentialIndex) {
      let myObject = JSON.parse(
        JSON.stringify(defaultPropertyResidential.residentials[0].apartmentsOther.apartmentsOtherBenefit[0]),
      )
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length
      ) {
        this.form.residentials[residentialIndex].apartmentsOther.apartmentsOtherBenefit.push(myObject)
        this.resetFormErrors()
      }
    },

    addUsableSpace(residentialIndex) {
      let myObject = JSON.parse(JSON.stringify(defaultPropertyResidential.residentials[0].usableSpaces[0]))
      myObject.usableSpacesExemption = []
      myObject.usableSpacesBenefit = []
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length
      ) {
        this.form.residentials[residentialIndex].usableSpaces.push(myObject)
        this.resetFormErrors()
      }
    },

    removeUsableSpace(residentialIndex, usableSpacesIndex) {
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length &&
        typeof usableSpacesIndex === 'number' &&
        usableSpacesIndex >= 0 &&
        Array.isArray(this.form.residentials[residentialIndex].usableSpaces) &&
        usableSpacesIndex < this.form.residentials[residentialIndex].usableSpaces.length
      ) {
        this.form.residentials[residentialIndex].usableSpaces.splice(usableSpacesIndex, 1)
        this.resetFormErrors()
      }
    },

    removeUsableSpacesExemption(residentialIndex, usableSpacesIndex, usableSpacesExemptionIndex) {
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length &&
        typeof usableSpacesIndex === 'number' &&
        usableSpacesIndex >= 0 &&
        Array.isArray(this.form.residentials[residentialIndex].usableSpaces) &&
        usableSpacesIndex < this.form.residentials[residentialIndex].usableSpaces.length &&
        typeof usableSpacesExemptionIndex === 'number' &&
        usableSpacesExemptionIndex >= 0 &&
        Array.isArray(this.form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesExemption) &&
        usableSpacesExemptionIndex <
          this.form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesExemption.length
      ) {
        this.form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesExemption.splice(
          usableSpacesExemptionIndex,
          1,
        )
        this.resetFormErrors()
      }
    },

    addUsableSpacesExemption(residentialIndex, usableSpacesIndex) {
      let myObject = JSON.parse(
        JSON.stringify(defaultPropertyResidential.residentials[0].usableSpaces[0].usableSpacesExemption[0]),
      )
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length &&
        typeof usableSpacesIndex === 'number' &&
        usableSpacesIndex >= 0 &&
        Array.isArray(this.form.residentials[residentialIndex].usableSpaces) &&
        usableSpacesIndex < this.form.residentials[residentialIndex].usableSpaces.length
      ) {
        if (
          !Array.isArray(this.form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesExemption)
        ) {
          this.form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesExemption = []
        }
        this.form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesExemption.push(myObject)
        this.resetFormErrors()
      }
    },

    removeUsableSpacesBenefit(residentialIndex, usableSpacesIndex, usableSpacesBenefitIndex) {
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length &&
        typeof usableSpacesIndex === 'number' &&
        usableSpacesIndex >= 0 &&
        Array.isArray(this.form.residentials[residentialIndex].usableSpaces) &&
        usableSpacesIndex < this.form.residentials[residentialIndex].usableSpaces.length &&
        typeof usableSpacesBenefitIndex === 'number' &&
        usableSpacesBenefitIndex >= 0 &&
        Array.isArray(this.form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesBenefit) &&
        usableSpacesBenefitIndex <
          this.form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesBenefit.length
      ) {
        this.form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesBenefit.splice(
          usableSpacesBenefitIndex,
          1,
        )
        this.resetFormErrors()
      }
    },

    addUsableSpacesBenefit(residentialIndex, usableSpacesIndex) {
      let myObject = JSON.parse(
        JSON.stringify(defaultPropertyResidential.residentials[0].usableSpaces[0].usableSpacesBenefit[0]),
      )
      if (
        typeof residentialIndex === 'number' &&
        residentialIndex >= 0 &&
        residentialIndex < this.form.residentials.length &&
        typeof usableSpacesIndex === 'number' &&
        usableSpacesIndex >= 0 &&
        Array.isArray(this.form.residentials[residentialIndex].usableSpaces) &&
        usableSpacesIndex < this.form.residentials[residentialIndex].usableSpaces.length
      ) {
        if (
          !Array.isArray(this.form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesBenefit)
        ) {
          this.form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesBenefit = []
        }
        this.form.residentials[residentialIndex].usableSpaces[usableSpacesIndex].usableSpacesBenefit.push(myObject)
        this.resetFormErrors()
      }
    },

    removeAllExemptions() {
      this.form.residentials.forEach(function (residential) {
        residential.garageUnderground.garageUndergroundExemption = []
        residential.apartmentsBelow60.apartmentsBelow60Exemption = []
        residential.apartmentsBetween60100.apartmentsBetween60100Exemption = []
        residential.apartmentsAbove100.apartmentsAbove100Exemption = []
        residential.apartmentsOther.apartmentsOtherExemption = []
        residential.usableSpaces.forEach(function (usableSpace) {
          usableSpace.usableSpacesExemption = []
        }, this)
      }, this)
      this.resetFormErrors()
    },

    removeAllBenefits() {
      this.form.residentials.forEach(function (residential) {
        residential.garageUnderground.garageUndergroundBenefit = []
        residential.apartmentsBelow60.apartmentsBelow60Benefit = []
        residential.apartmentsBetween60100.apartmentsBetween60100Benefit = []
        residential.apartmentsAbove100.apartmentsAbove100Benefit = []
        residential.apartmentsOther.apartmentsOtherBenefit = []
        residential.usableSpaces.forEach(function (usableSpace) {
          usableSpace.usableSpacesBenefit = []
        }, this)
      }, this)
      this.resetFormErrors()
    },

    onlyNumber($event) {
      let code = $event.code ? $event.code : $event.which
      let key = $event.key
      if (code === 'KeyE' || key === 'e' || key === 'E') {
        $event.preventDefault()
      }
    },

    openRoometricDialog() {
      if (this.isRoometricActive) {
        this.$emit('showRoometricDialog')
      }
    },

    sectionGarageChanged(residentialIndex) {
      this.form.residentials[residentialIndex].isSectionGarage =
        this.form.residentials[residentialIndex].isSectionGarage === '1' ? '0' : '1'
    },
    sectionBelow60Changed(residentialIndex) {
      this.form.residentials[residentialIndex].isSectionBelow60 =
        this.form.residentials[residentialIndex].isSectionBelow60 === '1' ? '0' : '1'
    },
    sectionBetween60100Changed(residentialIndex) {
      this.form.residentials[residentialIndex].isSectionBetween60100 =
        this.form.residentials[residentialIndex].isSectionBetween60100 === '1' ? '0' : '1'
    },
    sectionAbove100Changed(residentialIndex) {
      this.form.residentials[residentialIndex].isSectionAbove100 =
        this.form.residentials[residentialIndex].isSectionAbove100 === '1' ? '0' : '1'
    },
    sectionOtherChanged(residentialIndex) {
      this.form.residentials[residentialIndex].isSectionOther =
        this.form.residentials[residentialIndex].isSectionOther === '1' ? '0' : '1'
    },
    sectionUsableSpacesChanged(residentialIndex) {
      this.form.residentials[residentialIndex].isSectionUsableSpaces =
        this.form.residentials[residentialIndex].isSectionUsableSpaces === '1' ? '0' : '1'
    },
  },
}
</script>

<style type="text/css">
textarea.textarea-field {
  border: 1px solid #bbbbbb;
  height: 150px;
  width: 100%;
}

.Step_SectionPropertyResidential .tooltip-icon img {
  height: 18px;
  margin-left: 5px;
}

label.label-title.tooltip-icon {
  position: relative;
}

.ToolTipTextBox {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: none;
  max-width: 600px;
  padding: 20px;
  padding: 15px !important;
  position: absolute;
  right: 0;
  top: 35px;
  transform: translate3d(0px, 40px, 0px) !important;
  z-index: 1;
}

h2.subheading_title.no_top_margin {
  margin-top: 0px;
}

h5.subheading_title_inner.tooltip-icon {
  display: flex;
  position: relative;
}

.dropdown-menu.ToolTipTextBox.show {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

/*.Step_SectionPropertyResidential .tooltip-icon img:hover + .ToolTipTextBox {
    display: block;
}*/

.flex-button-icon {
  align-items: center;
  display: flex;
}

.switch {
  border-radius: 20px;

  box-sizing: border-box;
  color: #333333;
  display: inline-block;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  height: 33px;
  line-height: normal;
  margin-right: 15px;
  margin-top: 10px;
  min-width: 69px;
  position: relative;
  text-align: center;

  width: 69px;
}

.switch input {
  height: 0;
  opacity: 0;
  width: 0;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  background-color: white;
  bottom: 4px;
  content: '';
  height: 26px;
  left: 4px;
  position: absolute;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 26px;
}

input:checked + .slider {
  background-color: var(--api-background);
}

input:checked + .slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.data-form-flex {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

.checkbox-label-bold {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}
</style>
