<template>
  <InvitationHeader />
  <div class="login_start">
    <InvitationProfileinfo />
    <InvitationTC />
  </div>
  <Copyright />
</template>

<script>
// @ is an alias to /src
import InvitationHeader from '@/components/InvitationHeader.vue'
import InvitationProfileinfo from '@/components/InvitationProfileinfo.vue'
import InvitationTC from '@/components/InvitationTC.vue'
import Copyright from '@/components/copyright.vue'

export default {
  name: 'Home',
  components: {
    InvitationHeader,
    InvitationProfileinfo,
    InvitationTC,
    Copyright,
  },
}
</script>
