<template>
  <Header />
  <div class="login_start">
    <Profileinfo />
    <Benutzerverwaltung />
  </div>
  <Copyright />
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Profileinfo from '@/components/Profileinfo.vue'
import Benutzerverwaltung from '@/components/Benutzerverwaltung.vue'
import Copyright from '@/components/copyright.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Profileinfo,
    Benutzerverwaltung,
    Copyright,
  },
}
</script>
