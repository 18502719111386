import { createRouter, createWebHashHistory } from 'vue-router'
import Login_start from '../views/Login_start.vue'
import LoginNoPassword from '../views/LoginNoPassword.vue'
//import LoginSetPassword from '../views/LoginSetPassword.vue'
//import LoginMobilvorhanden from '../views/LoginMobilvorhanden.vue'
//import LoginSmscodesent from '../views/LoginSmscodesent.vue'
import LoginNoMobPasswortVorhanden from '../views/LoginNoMobPasswortVorhanden.vue'
//import LoginVorhandenPwdMobil from '../views/LoginVorhandenPwdMobil.vue'
import Dashboard from '../views/Dashboard.vue'
import MyData from '../views/MyData.vue'
//import Invitation from '../views/Invitation.vue'
import InvitationTC from '../views/LoginTCInvitation'
import Benutzerverwaltung from '../views/Benutzerverwaltung.vue'
import Property from '../views/Property.vue'
import Declaration from '@/views/Declaration'
import signDeclaration from '@/views/signDeclaration'
import PropertyChange from '@/views/PropertyChange'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login_start,
  },
  {
    path: '/LoginNoPassword',
    name: 'Login - Kein Passwort , keine Mobilnummer',
    component: LoginNoPassword,
  },
  /*
    {
      path: "/LoginSetPassword/:token?",
      alias: "/setPassword/:token?",
      name: "Login - Passwort setzen",
      component: LoginSetPassword,
      meta: {
      }
    },
   */
  {
    path: '/resetPassword/:code?',
    name: 'Einladung TC',
    component: InvitationTC,
  },
  {
    path: '/propertyChange/:code?',
    name: 'PropertyChange',
    component: PropertyChange,
  },
  {
    path: '/propertyNoChange/:code?',
    name: 'PropertyNoChange',
    component: PropertyChange,
  },
  /*
    {
      path: "/LoginMobilvorhanden",
      name: "Login - Kein Passwort Mobilnummer vorhanden",
      component: LoginMobilvorhanden
    },
    {
      path: "/LoginSmscodesent",
      name: "Login - Mobilnummer vorhanden, SMS Code gesendet",
      component: LoginSmscodesent
    },
   */
  {
    path: '/LoginNoMobPasswortVorhanden',
    name: 'Login - Passwort vorhanden, keine Mobilnummer',
    component: LoginNoMobPasswortVorhanden,
  },
  /*
    {
      path: "/LoginVorhandenPwdMobil",
      name: "Login - Passwort vorhanden, Mobilnummer vorhanden",
      component: LoginVorhandenPwdMobil
    },
   */
  {
    path: '/property/:id?',
    name: 'Grundstück anlegen oder bearbeiten',
    component: Property,
    alias: '/new_property/:id?',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/declaration/:uid?',
    name: 'Declaration',
    component: Declaration,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/signDeclaration/',
    name: 'signDeclaration',
    component: signDeclaration,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/mydata',
    name: 'Meine Daten',
    component: MyData,
    meta: {
      requiresAuth: true,
    },
  },

  /*
    {
      path: "/invitation/:token?",
      name: "Einladung",
      component: Invitation,
      meta: {
      }
    },
  */
  {
    path: '/benutzerverwaltung',
    name: 'Benutzerverwaltung',
    component: Benutzerverwaltung,
    meta: {
      requiresAuth: true,
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

// Meta Handling
router.beforeEach((to, from, next) => {
  let loggedIn = localStorage.getItem('client_logged_in')
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!loggedIn) {
      sessionStorage.setItem('deepLink', to.path)
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
