<template>
  <!-- Header Section -->
  <footer class="app-footer">
    <div class="container">
      <div class="row">
        <div class="col-6">
          <div class="copyright">
            {{ copyright }}
          </div>
        </div>
        <div class="col-6">
          <span class="footer-link">
            <a
              href="//grundsteuer-digital.de/impressum/"
              target="_blank"
              >{{ $t('copyright.impressum') }}</a
            >
            <a
              href="//grundsteuer-digital.de/datenschutz/"
              target="_blank"
              >{{ $t('copyright.datenschutz') }}</a
            >
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>
<!-- Header Section ///////////// -->
<script type="text/javascript">
export default {
  name: 'copyright',
  data() {
    return {}
  },
  computed: {
    copyright() {
      const d = new Date()
      return '© ' + d.getFullYear() + ' fino taxtech GmbH'
    },
  },
}
</script>
<style scoped></style>
