<template>
  <!-- Header Section -->
  <header
    class="app-head section"
    v-show="tc_uid > 0"
  >
    <div class="container">
      <div class="men_row frm_margin_on_mobile">
        <div class="row">
          <div class="col-md-2 d-flex justify-content-strat align-items-center">
            <div class="comp_logo">
              <img
                :src="comp_logo_image"
                v-if="comp_logo_image"
              />
            </div>
          </div>
          <div class="col-md-3 p-0">
            <div
              class="comp_user"
              v-if="profile_pic_url"
            >
              <!-- <img src="@/assets/user.png"> -->
              <img :src="profile_pic_url" />
            </div>
          </div>

          <div
            v-show="showInfo"
            class="col-md-7 p-0"
          >
            <div class="comp_info">
              <div class="comp_user_data">
                <div class="info_inline f_60">
                  <div class="abso_div_large">
                    <label>
                      <span>
                        <Svgall
                          class="profiles_svg"
                          :name="'profile_building'"
                          :color="primaryColor"
                        />
                      </span>
                      {{ $t('profile.taxofc') }}:
                    </label>
                    <span class="dynamicColor">{{ steuerkanzlei }}</span>
                  </div>
                </div>

                <div class="info_inline f_40 pos_rel">
                  <div class="abso_div">
                    <label>
                      <span>
                        <Svgall
                          class="profiles_svg"
                          :name="'profile_location'"
                          :color="primaryColor"
                        />
                      </span>
                      {{ $t('profile.address') }}:
                    </label>
                    <span class="dynamicColor">{{ adresse }}<br />{{ adresse_2 }}</span>
                  </div>
                </div>

                <div
                  class="info_inline f_60"
                  v-if="email !== ''"
                >
                  <div class="abso_div_large">
                    <label>
                      <span>
                        <Svgall
                          class="profiles_svg"
                          :name="'profile_email'"
                          :color="primaryColor"
                        />
                      </span>
                      {{ $t('profile.email') }}:
                    </label>
                    <span
                      ><a
                        class="hoverColor"
                        :href="'mailto:' + email"
                        >{{ email }}</a
                      ></span
                    >
                  </div>
                </div>

                <div
                  class="info_inline f_40"
                  v-if="telefon !== ''"
                >
                  <div class="abso_div">
                    <label>
                      <span>
                        <!-- <img class="max_18" src="@/assets/phone.svg"> -->
                        <Svgall
                          class="profiles_svg"
                          :name="'profile_phone'"
                          :color="primaryColor"
                        /> </span
                      >{{ $t('profile.tel') }}:</label
                    >
                    <span
                      ><a
                        class="hoverColor"
                        :href="'tel:' + telefon"
                        >{{ telefon }}</a
                      ></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<!-- Header Section ///////////// -->

<script type="text/javascript">
import { mapGetters } from 'vuex'

export default {
  name: 'InvitationProfileinfo',
  data() {
    return {
      tc_uid: 0,
      steuerkanzlei: '',
      email: '',
      adresse: '',
      adresse_2: '',
      telefon: '',
      comp_logo_image: '',
      profile_pic_url: '',
      primaryColor: '#464747',
      showInfo: true,
    }
  },
  computed: {
    ...mapGetters(['getInvitationInfo']),
    profilepic() {
      return false
    },
  },
  watch: {
    getInvitationInfo(val) {
      this.setProfileData(val)
    },
  },
  methods: {
    setProfileData(val) {
      if (val.taxConsultant.id) {
        //colors
        let bodyStyle = document.body.style
        this.primaryColor = val.primaryColor ? val.primaryColor : '#464747'
        bodyStyle.setProperty('--api-background', this.primaryColor)
        if (val.fontColor) {
          bodyStyle.setProperty('--api-font', val.fontColor)
        }

        //data
        this.tc_uid = val.taxConsultant.id
        if (val.taxConsultant.showBranchInfo) {
          if (val.branch) {
            this.steuerkanzlei = val.branch.name
            this.email = val.branch.email
            this.adresse = val.branch.street
            this.adresse_2 = (val.branch.zip + ' ' + val.branch.city).trim()
            this.telefon = val.branch.phone
          } else {
            this.showInfo = false
          }
        } else {
          this.steuerkanzlei = val.taxConsultant.companyName
            ? val.taxConsultant.companyName
            : val.taxConsultant.firstName + ' ' + val.taxConsultant.lastName
          this.adresse = val.taxConsultant.street
          if (val.taxConsultant.houseNumber !== '') {
            this.adresse = this.adresse + ' ' + val.taxConsultant.houseNumber
          }
          this.adresse_2 = val.taxConsultant.zip + ' ' + val.taxConsultant.city
          this.telefon = val.taxConsultant.phone
          this.email = val.taxConsultant.email
        }
        this.comp_logo_image = val.taxConsultant.companyLogoPic
      }
    },
  },
}
</script>
<style>
body {
  background: #ffff !important;
  margin: 0;
  padding: 0;
}

.comp_user img {
  width: 110px;
}

.info_inline a {
  color: #4b4b4b;
}

header.app-head.section {
  font-family: 'Segoe UI', sans-serif !important;
}

.max_18 {
  margin-right: 5px !important;
}

.container_max {
  margin: 0 auto !important;
  max-width: 1200px;
}

.info_inline img {
  margin-right: 10px;
  max-height: 20px;
  max-width: 20px;
}

.comp_user {
  text-align: right;
}

.comp_info {
  align-items: center;
  display: flex;
  width: 100%;
}

.comp_user_data {
  color: #4b4b4b;
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  justify-content: space-between;
  margin-left: auto;
  max-width: 664px;
  padding-left: 15px;
  text-align: left;
}

.men_row {
  border: 2px solid #dfdfdf;
  border-radius: 5px;
  margin-top: 25px;
  padding: 10px;
}

.comp_user_data label {
  font-weight: 600;
}

.comp_logo {
  text-align: center;
}

.info_inline {
  margin: 10px 0;
}

.men_row {
  align-items: center;
}

.info_inline.f_60 {
  max-width: 58%;
  width: 100%;
}

.info_inline.f_40 {
  max-width: 42%;
  width: 100%;
}

.abso_div {
  align-items: flex-start;
  display: flex;
}

.pos_rel {
  position: relative;
}

.info_inline.f_60 > label {
  display: inline-block;
  min-width: 150px;
}

.abso_div > label {
  align-items: center;
  display: flex;
  min-width: 100px;
}

ul.user-selector li a {
  color: #333;
}

.Login_SMS_Code {
  padding-top: 8px;
}

a.link-style:hover {
  color: #333 !important;
  text-decoration: underline !important;
}

.hoverColor:hover {
  color: #229d56;
}

.content-heading p {
  color: #4b4b4b;
  font-size: 20px;
  line-height: 25px;
  margin: 15px 0 20px;
}

.headign-title {
  color: #4b4b4b;
  font-size: 26px;
  font-weight: 700;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 1090px) {
  .comp_logo {
    margin-left: 0;
  }

  .comp_info {
    max-width: 75%;
  }

  .info_inline.f_60,
  .info_inline.f_40 {
    max-width: 100%;
  }

  .info_inline.f_60 > label,
  .abso_div > label {
    min-width: 200px;
    padding-right: 0px;
  }

  .button-set button.start-button {
    font-size: 22px;
  }

  .content-heading p {
    font-size: 18px;
  }

  .expired-section-inner {
    margin: 100px auto 100px auto !important;
  }

  footer.app-footer {
    position: relative !important;
  }
}
</style>
