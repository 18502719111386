<template>
  <Header />
  <div class="login_start">
    <Profileinfo />
    <Property />
  </div>
  <Copyright />
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Profileinfo from '@/components/Profileinfo.vue'
import Property from '@/components/Property.vue'
import Copyright from '@/components/copyright.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Home',
  components: {
    Header,
    Profileinfo,
    Property,
    Copyright,
  },
  computed: {
    ...mapGetters(['getCurrentUser']),
  },
  mounted() {
    this.setPropertyReadOnly(false)
    if (this.getCurrentUser.permissions) {
      if (this.$route.params.id) {
        this.setPropertyReadOnly(!Object.values(this.getCurrentUser.permissions).includes('EDIT_PROPERTY'))
      }
      if (this.$route.path === '/property' && Object.values(this.getCurrentUser.permissions).includes('ADD_PROPERTY')) {
        return
      } else if (this.$route.params.id && Object.values(this.getCurrentUser.permissions).includes('VIEW_PROPERTY')) {
        return
      }
    } else {
      this.setPropertyReadOnly(true)
    }

    this.$router.push('/dashboard')
  },
  methods: {
    ...mapMutations(['setPropertyReadOnly']),
  },
}
</script>
