<template>
  <div v-show="visible">
    <div class="modal-backdrop-confirmation">
      <div
        class="modal-confirmation"
        ref="draggableContainer"
        id="draggable-container"
      >
        <div
          class=""
          v-on-clickaway="onClickOutside"
          style="width: 100%"
        >
          <header
            class="modal-header"
            @mousedown="dragMouseDown"
          >
            <label class="dheader cursor-move">{{ headerText }}</label>
            <img
              src="@/assets/close_thin.png"
              class="btn-close cursor-pointer"
              @click="hide"
              v-if="showClose"
            />
          </header>
          <div class="modal-body ml-4 flex flex-col justify-start">
            <label class="title text-left">{{ title }}</label>
            <label class="dtext text-left">{{ text }}</label>
            <div class="mr-4 mt-8 flex justify-around space-x-10">
              <div class="invisible w-full" />
              <div
                class=""
                style="width: 97%; display: flex; justify-content: end; justify-items: flex-end; margin: 10px"
              >
                <button
                  type="button"
                  class="step-button-style border-button-style"
                  @click="hide"
                  v-show="showCancel"
                  style="margin-left: 10px"
                >
                  {{ buttons.cancel }}
                </button>
                <button
                  type="button"
                  @click="confirm"
                  class="step-button-style ok-button"
                  :style="[primaryColor ? { background: primaryColor } : '']"
                  style="margin-left: 10px"
                >
                  {{ buttons.ok }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// we must import our Modal plugin instance
// because it contains reference to our Eventbus
// import {Modal} from '@/plugins/modal-plugin';
// import $bus from '../plugins/events';
import { directive } from 'vue3-click-away'
import { mapGetters } from 'vuex'

export default {
  directives: {
    onClickaway: directive,
  },

  data() {
    return {
      primaryColor: '',
      visible: false,
      title: '',
      text: '',
      showClose: true,
      headerText: 'Grundstück löschen',
      canEscape: true,
      canCloseOutsideClick: true,
      showCancel: true,
      buttons: {
        ok: 'Delete',
        cancel: 'cancel',
      },
      // adding callback function variable
      onConfirm: {},
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
      clickedOutSideCount: 0,
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser']),
  },
  methods: {
    close() {
      this.$emit('close')
    },
    dragMouseDown: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top =
        this.$refs.draggableContainer.offsetTop - this.positions.movementY + 'px'
      this.$refs.draggableContainer.style.left =
        this.$refs.draggableContainer.offsetLeft - this.positions.movementX + 'px'
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    },
    onClickOutside() {
      // this.clickedOutSideCount++;
      // if (this.clickedOutSideCount > 1) {
      if (this.canCloseOutsideClick) {
        this.hide()
      }
      // }
    },
    hide() {
      this.visible = false
    },
    confirm() {
      // we must check if this.onConfirm is function
      if (typeof this.onConfirm === 'function') {
        // run passed function and then close the modal
        this.onConfirm()
        this.hide()
      } else {
        // we only close the modal
        this.hide()
      }
    },
    show(params) {
      // making modal visible
      this.visible = true
      // setting title and text
      this.title = params.title
      this.text = params.text
      this.showClose = params.showClose
      this.headerText = params.headerText
      this.canEscape = params.canEscape
      this.canCloseOutsideClick = params.canCloseOutsideClick

      if (typeof params.buttons !== 'undefined') {
        if (typeof params.buttons.ok !== 'undefined') {
          this.buttons.ok = params.buttons.ok
        }
        if (typeof params.buttons.cancel !== 'undefined') {
          this.buttons.cancel = params.buttons.cancel
        }
      }

      if (typeof params.showCancel !== 'undefined') {
        this.showCancel = params.showCancel
      }

      // setting callback function
      this.onConfirm = params.onConfirm
    },
  },
  beforeMount() {
    //   // here we need to listen for emited events
    //   // we declared those events inside our plugin
  },
  created() {
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#43ac6f'
  },
  mounted() {
    // Close modal with 'esc' key
    // here we need to listen for emited events
    // we declared those events inside our plugin

    this.$bus.on('show', (params) => {
      //console.log("zzbb")
      //console.log(params)
      this.show(params)
    })
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 27) {
        if (this.canEscape) {
          this.hide()
        }
      }
    })
  },
}
</script>

<style scoped lang="scss">
.title {
  color: #4b4b4b;
  font-family: 'Segoe UI', sans-serif;
  font-size: 15px;
  text-align: left;
}

.dtext {
  box-sizing: border-box;
  color: #4b4b4b;
  font-family: 'Segoe UI', sans-serif;
  font-size: 15px;
  line-height: 40px;
  margin-bottom: 20px;
  margin-left: 18px;
  text-align: left;
}

.modal-buttons {
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
}

.modal-button {
  flex-grow: 1;
}

.modal-backdrop-confirmation {
  align-items: space-between;
  background-color: rgba(0, 0, 0, 0.6) !important;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1001;
}

.modal-confirmation {
  align-items: center;
  align-self: center;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  // box-shadow: 2px 2px 20px 1px;
  overflow-x: none;
  width: 500px;
}

.modal-header {
  cursor: move;
  display: flex;

  justify-content: space-between;
  padding: 15px;
}

.modal-header {
  color: #333333;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  padding: 20px 10px;
  position: relative;
}

.btn-close {
  box-sizing: border-box;
  color: #333333;
  cursor: pointer;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  height: 15px;
  line-height: normal;
  margin: 0 !important;
  text-align: center;
  width: 15px;
}

.btn-green {
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
  color: white;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

#draggable-container {
  position: absolute;
  z-index: 9;
}

#draggable-header {
  z-index: 10;
}

.dheader {
  color: #4b4b4b;

  font-family: 'Segoe UI Bold', 'Segoe UI Regular', 'Segoe UI', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;

  //padding: 0 20px;
  max-width: 70%;
  text-align: left;
}
.modal-small {
  max-width: 35% !important;
  width: 35% !important;
}
.modal-body {
  padding: 20px 17px;
}
.modal-confirmation button {
  max-width: unset;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  width: auto;
}
.ok-button {
  min-width: 100px;
}
</style>
